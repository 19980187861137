import { Pivot, PivotItem, Spinner, Stack, useTheme } from "@fluentui/react";

import React, { useMemo } from "react";

import chroma from "chroma-js";

import { DataSet } from "../../../../model";
import FossilyticsChart, { FossilyticsChartAxis, FossilyticsChartSeries } from "../../../../components/FossilyticsChart";
import FossilyticsGrid, { FossilyticsGridColumn, FossilyticsGridColumnGroup } from "../../../../components/FossilyticsGrid";
import { ModuleTahkForecastOutput } from "./model";

interface ModuleTahkForecastGasOilOutputViewProps {
  isLoading: boolean;
  dataSet: DataSet;
  output: ModuleTahkForecastOutput | undefined;
}

export function ModuleTahkForecastGasOilOutputView({ isLoading, output }: Readonly<ModuleTahkForecastGasOilOutputViewProps>) {
  const theme = useTheme();

  const profilesTableGroups = useMemo<FossilyticsGridColumnGroup[]>(() => {
    if (!output) return [];
    return [
      { header: "", colspan: 1 },
      ...output.rates_mmscf_d.map((_, i) => ({
        header: `Profile ${i + 1}`,
        colspan: 2,
      })),
    ];
  }, [output]);

  const profilesTableColumns = useMemo<FossilyticsGridColumn[]>(() => {
    if (!output) return [];
    return [
      { key: "day", type: "number" as "number", header: "Day", width: 50, editable: false },
      ...output.rates_mmscf_d.flatMap((p, i) => [
        {
          key: `profile-${i}-q`,
          type: "number" as "number",
          header: "Rate (MMscf/d)",
          width: 125,
          editable: false,
        },
        {
          key: `profile-${i}-Q`,
          type: "number" as "number",
          header: "Cum. prod (MMscf)",
          width: 125,
          editable: false,
        },
      ]),
    ];
  }, [output]);
  const profilesTableItems = useMemo<number[][]>(() => {
    if (!output) return [];
    return output.days.map((d, j) => [d, ...output.rates_mmscf_d.flatMap((rates, i) => [rates[j], output.cumes_mmscf[i][j]])]);
  }, [output]);
  const profilesXAxes = useMemo<FossilyticsChartAxis[]>(() => [{ name: "Days", type: "value", color: theme.palette.black, min: 1 }], [theme]);
  const profilesYAxes = useMemo<FossilyticsChartAxis[]>(() => [{ name: "Gas rate (MMscf/day)", type: "value", color: theme.palette.black }], [theme]);
  const profilesSeries = useMemo<FossilyticsChartSeries[]>(
    () =>
      output
        ? output.rates_mmscf_d.map((p, i) => ({
            name: `Profile ${i + 1}`,
            type: "line",
            hideSymbol: true,
            lineWidth: !output.indices || output.indices.indexOf(i) >= 0 ? 3 : 1,
            color: !output.indices || output.indices.indexOf(i) >= 0 ? theme.palette.red : chroma(theme.palette.red).alpha(0.2).hex(),
            z: !output.indices || output.indices.indexOf(i) >= 0 ? 2 : 1,
            data: output.days.map((d, j) => [d, p[j]]),
          }))
        : [],
    [theme, output]
  );

  return (
    <Stack className="PivotWrapper" grow={1}>
      <Pivot styles={{ itemContainer: { flex: "1" } }}>
        <PivotItem headerText="Profiles">
          <FossilyticsChart
            id="koldun_profiles"
            isLoading={isLoading}
            hideLegend
            xAxes={profilesXAxes}
            yAxes={profilesYAxes}
            series={profilesSeries}
          />
        </PivotItem>

        <PivotItem headerText={"Profiles table"} style={{ position: "relative" }}>
          {isLoading ? (
            <Spinner
              label="Loading..."
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            />
          ) : (
            <FossilyticsGrid columnGroups={profilesTableGroups} columns={profilesTableColumns} data={profilesTableItems} />
          )}
        </PivotItem>
      </Pivot>
    </Stack>
  );
}

export default ModuleTahkForecastGasOilOutputView;
