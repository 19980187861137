import { useMemo, useState } from "react";
import { IDropdownOption } from "@fluentui/react";

import { ModuleAutoRTAYaxisField, DimensionlessRateFieldName } from "../../model";
import { FossilyticsChartAxis, FossilyticsChartSeries } from "@/components/FossilyticsChart";

import useThemeStyling from "@/utils/useThemeStyling";
import { AnalysisCalculation, DimensionlessRateArrays, DimensionlessTimeArrayModel } from "@/models/gaz/autoRta";

type NormalizedRateProps = {
  analysisCalculation: AnalysisCalculation;
};

const useGazAutoRtaAnalysisNormalizedRate = ({ analysisCalculation }: NormalizedRateProps) => {
  const { theme } = useThemeStyling();
  const [selectedXaxis, setSelectedXaxis] = useState<IDropdownOption>({ key: "decline_time", text: "tDd" });

  const xAxes = useMemo<FossilyticsChartAxis[]>(
    () => [
      {
        name: selectedXaxis.text,
        type: "log",
        color: theme.palette.black,
      },
    ],
    [theme, selectedXaxis]
  );

  const yAxes = useMemo<FossilyticsChartAxis[]>(
    () => [
      {
        name: "qD",
        type: "log",
        color: theme.palette.black,
        show: true,
      },
    ],
    [theme]
  );
  const moduleDimensionlessRateField = useMemo<{ [k in keyof DimensionlessRateArrays]?: ModuleAutoRTAYaxisField }>(
    () => ({
      normalized_rate: {
        name: "qD",
        yAxis: "qD",
        color: theme.palette.red,
        defaultDisabled: true,
      },
      decline_rate: {
        name: "qDd",
        yAxis: "qDd",
        color: theme.palette.blueMid,
        defaultDisabled: false,
      },
      decline_rate_integral: {
        name: "qDdi",
        yAxis: "qDdi",
        color: theme.palette.green,
        defaultDisabled: false,
      },
      decline_rate_integral_derivative: {
        name: "qDdid",
        yAxis: "qDdid",
        color: theme.palette.orangeLighter,
        defaultDisabled: false,
      },
    }),
    [theme]
  );

  const dotRateSeries = useMemo<FossilyticsChartSeries[]>(
    () =>
      Object.keys(moduleDimensionlessRateField)
        .map((v) => v as keyof DimensionlessRateArrays)
        .map((f) => ({
          name: `${DimensionlessRateFieldName(f)}`,
          type: "scatter",
          symbolSize: 5,
          lineWidth: 4,
          color: moduleDimensionlessRateField[f]!.color,
          data: analysisCalculation.transient_analysis_curves_data.dimensionless_rate_arrays
            ? analysisCalculation.transient_analysis_curves_data.dimensionless_time_arrays[
                selectedXaxis.key as keyof DimensionlessTimeArrayModel
              ].map((d, i) => [d, analysisCalculation.transient_analysis_curves_data.dimensionless_rate_arrays[f][i]])
            : [],
          defaultDisabled: moduleDimensionlessRateField[f]?.defaultDisabled,
        })),
    [moduleDimensionlessRateField, analysisCalculation, selectedXaxis]
  );

  const lineRateSeries = useMemo<FossilyticsChartSeries[]>(
    () =>
      Object.keys(moduleDimensionlessRateField)
        .map((v) => v as keyof DimensionlessRateArrays)
        .map((f) => ({
          name: `${DimensionlessRateFieldName(f)} Line`,
          type: "line",
          lineWidth: 2,
          symbolSize: 0.1,
          color: moduleDimensionlessRateField[f]!.color,
          data: analysisCalculation.transient_analysis_curves_model.dimensionless_rate_arrays
            ? analysisCalculation.transient_analysis_curves_model.dimensionless_time_arrays[
                selectedXaxis.key as keyof DimensionlessTimeArrayModel
              ].map((d, i) => [d, analysisCalculation.transient_analysis_curves_model.dimensionless_rate_arrays[f][i]])
            : [],
          defaultDisabled: moduleDimensionlessRateField[f]?.defaultDisabled,
        })),
    [moduleDimensionlessRateField, analysisCalculation, selectedXaxis]
  );

  const logRateFlowSeries = useMemo<FossilyticsChartSeries[]>(
    () => [
      ...dotRateSeries.map((dotItems, i) => ({
        ...dotItems,
      })),
      ...lineRateSeries.map((lineItems, i) => ({
        ...lineItems,
      })),
    ],
    [dotRateSeries, lineRateSeries]
  );

  return {
    id: "normalized_rate",
    xAxes,
    yAxes,
    series: logRateFlowSeries,
    onChangeDropdownOption: (val: IDropdownOption) => setSelectedXaxis(val),
    selectedOption: selectedXaxis,
  };
};

export default useGazAutoRtaAnalysisNormalizedRate;
