import { useCallback, useEffect, useMemo, useState, useRef } from "react";
import { useImmer } from "use-immer";
import _ from "lodash";
import { useQuery, useQueryClient } from "@tanstack/react-query";

import { exportSpadDeclineAnalysis } from "@/constants/apiUrl";

import {
  ModuleSpadDeclineExportOptions,
  ModuleSpadDeclineArpParams,
  backCalculateDeclineRate,
  ModuleSpadDeclineCaseParams,
  SpadDeclineAnalysis,
} from "@/features/modules/spad/decline";
import { DataSet, Group, Project, ModuleId, DataResponse } from "@/model";
import { useSettingState } from "@/SettingsState";
import { calculateAnalysis, CalculateSpadDeclineRequestPayload } from "@/models/spad";

import { SpadAnalysisModule, SpadAnalysisRequest, SpadAnalysisInputOptions } from "../index.types";
import { parseOldAnalysisDataToAnalysisInput, getSubModulePath, parseParamToInput, getAnalysisParameter } from "../index.utils";
import { saveBlob } from "@/util";
import { PostRequest } from "@/features/app/app.types";
import { convertDateToUtcTimeZoneIsoString } from "@/utils/dateTime";

import { analysisOptionDefault } from "../constants";
import { ErrorValidationDetail } from "@/models/ErrorInputValidation";

type UseSpadAnalysisProps = {
  postRequest: PostRequest;
  dataSets: DataSet[];
  group?: Group;
  project?: Project;
  forecastStartDate?: string;
  forecastEndDate?: string;
  currentModule?: ModuleId;
  tabIndex: number;
  cleaned?: DataResponse;
  setCleaned: (cleaned: undefined) => void;
};

const useSpadDeclineAnalysisV2 = ({
  postRequest,
  project,
  dataSets,
  group,
  forecastStartDate,
  forecastEndDate,
  currentModule,
  tabIndex,
  cleaned,
  setCleaned,
}: UseSpadAnalysisProps) => {
  const [errorInputValidation, setErrorInputValidation] = useState<ErrorValidationDetail[]>([]);

  // IMPORTANT! don't set this state except when calling the API
  // we can't change much of the data structure because be need this exact type
  // for user input state we will update other state below
  const [analysis, setAnalysis] = useImmer<SpadDeclineAnalysis | undefined>(undefined);
  const [latestRequest, setLatestRequest] = useImmer<SpadAnalysisRequest | null>(null);

  const [paramsOps, setParamsOps] = useSettingState<ModuleSpadDeclineCaseParams>(`${currentModule}_params_ops`, false);
  const [paramsProfile, setParamsProfile] = useSettingState<ModuleSpadDeclineCaseParams>(`${currentModule}_params_profile`, false);

  const [options, setOptions] = useSettingState<SpadAnalysisInputOptions>(`${currentModule}_options`, false, {
    default: analysisOptionDefault,
  });

  const [xAxesState, setXAxesState] = useState<boolean>(false);

  const unit = currentModule === ModuleId.SPAD_DECLINE_GAS ? "MMscf" : "bbl";

  const [selectedDeclineTypePivotKey, setSelectedDeclineTypePivotKey] = useState<"ops" | "profile">("profile");
  const [finishCalculation, setFinishCalculation] = useState(false);

  const isChangeDraggable = useRef(false);
  const isBEResponse = useRef(false);

  const haveInitialize = useRef(false);
  const cache = useQueryClient();

  const requestData = useMemo(() => {
    if (project?.id) {
      const cleanForecastStartDate = forecastStartDate ? convertDateToUtcTimeZoneIsoString(new Date(forecastStartDate)) : null;
      const analysisIdentity: SpadAnalysisModule = {
        project_id: project.id,
        group_id: group?.id ?? project.groups[0]?.id,
        data_set_ids: dataSets?.map((d) => d.id),
        project_setting: {
          forecast_start_date: cleanForecastStartDate ?? null,
        },
      };
      if (forecastEndDate) analysisIdentity.project_setting.forecast_end_date = convertDateToUtcTimeZoneIsoString(new Date(forecastEndDate));

      const calculationPayload: CalculateSpadDeclineRequestPayload = {
        analysis_identity: analysisIdentity,
      };

      // need to check remap and add data from another state
      if (analysis && paramsOps && paramsProfile) {
        const parsedAnalysisInput = parseOldAnalysisDataToAnalysisInput(analysis, paramsOps, paramsProfile);
        // check if some field is undefined
        if (parsedAnalysisInput) {
          calculationPayload.analysis_option = {
            ...parsedAnalysisInput,
            ...options,
          } as CalculateSpadDeclineRequestPayload["analysis_option"];
        }
      }
      return calculationPayload;
    }
    return false;
  }, [analysis, dataSets, forecastEndDate, forecastStartDate, group?.id, paramsOps, paramsProfile, project?.groups, project?.id, options]);

  const { refetch, isFetching, isError, isLoading } = useQuery({
    queryKey: ["calculate-spad-analysis"],
    queryFn: async () => {
      return calculateAnalysis({
        module: getSubModulePath(currentModule!),
        ...(requestData as CalculateSpadDeclineRequestPayload),
      });
    },
    refetchOnWindowFocus: false,
    enabled: false,
  });

  const onCallCalculateAnalysis = useCallback(
    async (payload?: CalculateSpadDeclineRequestPayload) => {
      if (!requestData || !cleaned || !currentModule) return;
      try {
        const apiPayloadFinal = { ...(payload ?? requestData), cleanedData: cleaned };
        setErrorInputValidation([]);
        setLatestRequest(apiPayloadFinal);
        setFinishCalculation(false);

        const apiFetch = payload
          ? await calculateAnalysis({
              module: getSubModulePath(currentModule),
              ...payload,
            })
          : await refetch();

        const response: any = payload ? { data: apiFetch } : apiFetch;
        if (response.data && !response.isError) {
          const calculationResult = response.data.data;
          // save main state
          if (calculationResult) setAnalysis({ ...calculationResult });
          // below state is where user can adjust using GUI
          setOptions({
            ...parseParamToInput(calculationResult),
            // this means only populate smart analysis start day on query / intialize
            analysis_start_day: calculationResult?.ops?.pssStartDay,
          });
          if (calculationResult?.ops?.params) setParamsOps({ ...calculationResult.ops.params });
          if (calculationResult?.profile?.params) setParamsProfile({ ...calculationResult.profile.params });
          isBEResponse.current = true;
        } else if (response.isError) {
          const errorRes: any = response.error;

          if (response.isError && errorRes.code === 422 && errorRes.detail?.length !== 0) {
            // parse error message and data, set to state
            setErrorInputValidation(errorRes.detail as ErrorValidationDetail[]);
          }
          setAnalysis((prev) => {
            if (!prev)
              return {
                autoUpdateSmartFit: true,
                autoUpdateClusterStartDay: true,
              };
            return prev;
          });
          setOptions((prev) => {
            if (!prev) return { ...analysisOptionDefault, economic_cutoff: 0.1 };
            return prev;
          });
          setParamsOps((prev) => {
            if (!prev) return {};
            return prev;
          });
          setParamsProfile((prev) => {
            if (!prev) return {};
            return prev;
          });
          setXAxesState(false);
        }
      } catch (error) {
        console.log(error);
      } finally {
        isChangeDraggable.current = false;
        setFinishCalculation(true);
      }
    },
    [cleaned, currentModule, refetch, requestData, setAnalysis, setLatestRequest, setOptions, setParamsOps, setParamsProfile]
  );

  const cleanUpPageState = useCallback(() => {
    setAnalysis(undefined);
    setLatestRequest(null);
    setParamsOps(undefined);
    setParamsProfile(undefined);
    setXAxesState(false);
    setOptions(analysisOptionDefault);
    haveInitialize.current = false;
    isBEResponse.current = false;
    cache.clear();
  }, [setAnalysis, setLatestRequest, setOptions, setParamsOps, setParamsProfile, cache]);

  // clean up state when project / data set change
  // need to be the first hook that run
  useEffect(() => {
    const dataSetIdList = dataSets?.map((d) => d.id);
    if (
      (!_.isEqual(dataSetIdList, latestRequest?.analysis_identity.data_set_ids) || project?.id !== latestRequest?.analysis_identity.project_id) &&
      haveInitialize.current &&
      latestRequest?.analysis_identity.data_set_ids &&
      latestRequest?.analysis_identity.project_id
    ) {
      cleanUpPageState();
      setCleaned(undefined);
    }
  }, [
    cleanUpPageState,
    dataSets,
    latestRequest?.analysis_identity.data_set_ids,
    latestRequest?.analysis_identity.project_id,
    project?.id,
    setCleaned,
  ]);

  useEffect(() => {
    return () => {
      cleanUpPageState();
      setCleaned(undefined);
    };
  }, [cleanUpPageState, setCleaned]);

  // initialize on first load
  useEffect(() => {
    if (
      project?.id &&
      tabIndex === 1 &&
      requestData &&
      !_.isEqual(requestData?.analysis_identity, latestRequest?.analysis_identity) &&
      dataSets &&
      // force wait for module view api to run before running this effect
      // this happen when user switch well and open this tab immediately
      cleaned &&
      !latestRequest &&
      !haveInitialize.current
    ) {
      onCallCalculateAnalysis();
      haveInitialize.current = true;
    }
  }, [latestRequest, onCallCalculateAnalysis, requestData, dataSets, project?.id, tabIndex, cleaned]);

  // on every analysis / user input update
  useEffect(() => {
    const nonErrorConditional = !isError && (!latestRequest?.analysis_identity || !haveInitialize.current);
    // since after initialize there will be changes on analysis
    if (nonErrorConditional || !requestData || !cleaned || tabIndex !== 1 || isChangeDraggable.current) return;

    const updateConditional = !isError
      ? (!_.isEqual(latestRequest?.analysis_option, requestData?.analysis_option) && !isBEResponse.current) ||
        (!_.isEqual(latestRequest?.cleanedData, cleaned) && !isBEResponse.current) ||
        (!_.isEqual(requestData?.analysis_identity, latestRequest?.analysis_identity) && isBEResponse.current)
      : !_.isEqual(latestRequest?.analysis_option, requestData?.analysis_option) && requestData?.analysis_option;

    if (updateConditional) {
      // we need logic below to make sure skip the first changes, and listen to ONLY user input update to trigger this effect
      if (isError || (!isError && requestData?.analysis_option?.ops_forecast && requestData?.analysis_option?.profile_forecast)) {
        onCallCalculateAnalysis();
      }
    }
  }, [
    requestData,
    latestRequest?.analysis_option,
    latestRequest?.analysis_identity,
    options,
    cleaned,
    tabIndex,
    setLatestRequest,
    latestRequest?.cleanedData,
    onCallCalculateAnalysis,
    isBEResponse,
    latestRequest,
    isError,
  ]);

  const downloadExport = async (freq: "daily" | "monthly" | "yearly") => {
    if (!dataSets || !analysis?.profile?.arps || !analysis.profile?.params || !currentModule) return;

    try {
      const response = await postRequest(
        exportSpadDeclineAnalysis(getSubModulePath(currentModule)),
        {
          freq,
          data_set_ids: dataSets.map((d) => d.id),
        },
        {
          cumProd: analysis.profile.params?.cumProd,
          declineArps: analysis.profile.arps,
          lowParams: analysis.profile.params?.low,
          midParams: analysis.profile.params?.mid,
          highParams: analysis.profile.params?.high,
        } as ModuleSpadDeclineExportOptions,
        "blob"
      );

      const fileName = `export-${Date.now()}.csv`;
      saveBlob(response, fileName);
    } catch (error) {
      console.error(error);
    }
  };

  // Update inputs when line changes
  const onDeclineArpChange = useCallback(
    (l: number[][] | [string, number][], i: number) => {
      if (l.length === 0 || i > 5) return;

      if (xAxesState) {
        let fistPointDay = Math.abs(new Date(l[0][0]).getTime() - new Date(l[2][0]).getTime());
        let secondPointDay = Math.abs(new Date(l[2][0]).getTime() - new Date(l[1][0]).getTime());
        l[0][0] = Math.ceil(fistPointDay / (1000 * 3600 * 24));
        l[1][0] = Math.ceil(secondPointDay / (1000 * 3600 * 24));
      }

      //in this code the point start change wrong x-axis
      const setParams = i < 3 ? setParamsOps : setParamsProfile;
      const param = i < 3 ? paramsOps : paramsProfile;
      let newParam = { ...param };
      if (param) {
        const setParam = (prev: ModuleSpadDeclineArpParams): ModuleSpadDeclineArpParams | undefined => ({
          b: prev.b,
          x: l[0][0] as number,
          qi: l[0][1],
          m: Math.abs(backCalculateDeclineRate(l[0][1], l[1][1], (l[1][0] as number) - (l[0][0] as number), prev?.b ?? 0)),
        });

        if (i % 3 === 0) {
          const newParamLow = param?.low?.b !== undefined ? { ...param, low: setParam(param.low) } : param;
          newParam = newParamLow;
          setParams(newParamLow);
        } else if (i % 3 === 1) {
          const newParamMid = param?.mid?.b !== undefined ? { ...param, mid: setParam(param.mid) } : param;
          newParam = newParamMid;
          setParams(newParamMid);
        } else if (i % 3 === 2) {
          const newParamHigh = param?.high?.b !== undefined ? { ...param, high: setParam(param.high) } : param;
          newParam = newParamHigh;
          setParams(newParamHigh);
        }
        if (options) {
          setOptions({
            ...options,
            auto_update_smart_fit: false,
          });
        }
        isBEResponse.current = false;
        isChangeDraggable.current = true;

        // fetch immediately to minimize wait time
        if (requestData) {
          const analysisOpt = requestData?.analysis_option;
          onCallCalculateAnalysis({
            ...requestData,
            analysis_option: analysisOpt
              ? {
                  ...analysisOpt,
                  auto_update_smart_fit: false,
                  [i < 3 ? "ops_forecast" : "profile_forecast"]: getAnalysisParameter(newParam),
                }
              : undefined,
          });
        }
      }
    },
    [xAxesState, setParamsOps, setParamsProfile, paramsOps, paramsProfile, options, requestData, setOptions, onCallCalculateAnalysis]
  );

  const changeClusterStartDayFromChart = useCallback(
    (value: any) => {
      setOptions({
        ...options,
        analysis_start_day: value,
        auto_update_cluster_start_day: false,
      });
      isChangeDraggable.current = true;

      // fetch immediately to minimize wait time
      if (requestData) {
        const analysisOpt = requestData?.analysis_option;
        onCallCalculateAnalysis({
          ...requestData,
          analysis_option: analysisOpt
            ? {
                ...analysisOpt,
                auto_update_cluster_start_day: false,
                analysis_start_day: value,
              }
            : undefined,
        });
      }
    },
    [onCallCalculateAnalysis, options, requestData, setOptions]
  );

  const onChangeOption = useCallback(
    (value: any, key: keyof SpadAnalysisInputOptions) => {
      setOptions({
        ...options,
        [key]: value,
      });
      isBEResponse.current = false;
    },
    [options, setOptions]
  );

  const handleXAxesStateFromChild = useCallback(() => {
    setXAxesState((prev) => !prev);
  }, [setXAxesState]);

  const setIsFromBeFlag = () => {
    isBEResponse.current = false;
  };

  return {
    analysis,
    unit,
    options,
    onChangeOption,
    paramsOps,
    paramsProfile,
    xAxesState,
    handleXAxesStateFromChild,
    onDeclineArpChange,
    downloadExport,
    setParamsOps,
    setParamsProfile,
    selectedDeclineTypePivotKey,
    setSelectedDeclineTypePivotKey,
    cleanUpPageState,
    setIsFromBeFlag,
    isFetching: isFetching || isLoading,
    errorInputValidation,
    finishCalculation,
    setFinishCalculation,
    changeClusterStartDayFromChart,
  };
};

export default useSpadDeclineAnalysisV2;
