import { Stack } from "@fluentui/react";

import { MonteCarloInputApplication, MonteCarloInputProps, UncertainParamLogNorm } from "@/models/koldun";
import FossilyticsNumberField from "@/components/fields/FossilyticsNumberField";

import { generateKeyFieldForLayer } from "../../../../utils";

const LogNormalInput = ({ units, output, updateUncertainList, measureNum, uncertainKey, keyField, errorInputValidation }: MonteCarloInputProps) => {
  const application = output.measures[measureNum].measure_layers[0].uncertain_list[uncertainKey].application;

  const renderLayerFields = () => {
    return output.measures[measureNum].measure_layers.map((measureLayer, index) => {
      return (
        <Stack
          key={`layer-field-${measureLayer.layer_num}`}
          enableScopedSelectors
          horizontal
          disableShrink
          tokens={{ childrenGap: 20 }}
          style={{ width: 500 }}
        >
          {index === 0 ? (
            <span
              style={{
                width: 50,
                fontWeight: 600,
                marginTop: 35,
              }}
            >
              Layer {index + 1}{" "}
            </span>
          ) : (
            <span
              style={{
                width: 50,
                fontWeight: 600,
                marginTop: 8,
              }}
            >
              Layer {index + 1}{" "}
            </span>
          )}
          <Stack grow={1} className="equal-size">
            <FossilyticsNumberField
              label={index === 0 ? "Mean" : ""}
              suffix={units}
              debounceDelay={1000}
              styles={{ maxWidth: "190px !important " }}
              value={(measureLayer.uncertain_list[uncertainKey] as UncertainParamLogNorm).mean}
              onChange={(v) => updateUncertainList(index, "mean", v)}
              errors={errorInputValidation}
              keyField={generateKeyFieldForLayer(index, `${keyField}.mean`)}
            />
          </Stack>
          <Stack grow={1} className="equal-size">
            <FossilyticsNumberField
              label={index === 0 ? "Std" : ""}
              suffix={units}
              debounceDelay={1000}
              styles={{ maxWidth: "190px !important " }}
              value={(measureLayer.uncertain_list[uncertainKey] as UncertainParamLogNorm).sigma}
              onChange={(v) => updateUncertainList(index, "sigma", v)}
              keyField={generateKeyFieldForLayer(index, `${keyField}.sigma`)}
              errors={errorInputValidation}
            />
          </Stack>
        </Stack>
      );
    });
  };

  const renderMeasureField = () => (
    <Stack horizontal enableScopedSelectors tokens={{ childrenGap: 20 }} style={{ width: 450 }}>
      <Stack grow={1} className="equal-size">
        <FossilyticsNumberField
          label={`Mean`}
          debounceDelay={1000}
          suffix={units}
          styles={{ maxWidth: "190px !important" }}
          value={(output.measures[measureNum].measure_layers[0].uncertain_list[uncertainKey] as UncertainParamLogNorm).mean}
          onChange={(v) => updateUncertainList(0, "mean", v)}
          keyField={`${keyField}.mean`}
          errors={errorInputValidation}
        />
      </Stack>
      <Stack grow={1} className="equal-size">
        <FossilyticsNumberField
          label={`Std`}
          debounceDelay={1000}
          suffix={units}
          styles={{ maxWidth: "190px !important" }}
          value={(output.measures[measureNum].measure_layers[0].uncertain_list[uncertainKey] as UncertainParamLogNorm).sigma}
          onChange={(v) => updateUncertainList(0, "sigma", v)}
          keyField={`${keyField}.sigma`}
          errors={errorInputValidation}
        />
      </Stack>
    </Stack>
  );

  return (
    <Stack horizontal tokens={{ childrenGap: 20 }}>
      <Stack grow={0.5} tokens={{ childrenGap: 10 }} className="equal-size">
        {application === MonteCarloInputApplication.LAYER ? renderLayerFields() : renderMeasureField()}
      </Stack>
    </Stack>
  );
};

export default LogNormalInput;
