import useTahkCsgWellboreInput, { TahkCsgWellboreInputProps } from "../../../hooks/input/charts/useTahkCsgWellboreInput";
import FossilyticsDropdown from "@/components/fields/FossilyticsDropdown";
import dictionary from "@/constants/dictionary";
import { TahkSelectedFlowPressureTypeEnum } from "@/models/tahk";
import FossilyticsNumberField from "@/components/fields/FossilyticsNumberField";
import { ErrorValidationDetail } from "@/models/ErrorInputValidation";
import helpLinkUrl from "@/constants/helpLinkUrl";

type WellboreInputProps = {
  errorInputValidation: ErrorValidationDetail[];
} & TahkCsgWellboreInputProps;

const WellboreInput = (props: WellboreInputProps) => {
  const { loadingState, inputs, errorInputValidation } = props;

  const { onChangeWellboreInput, flowPressureTypeOptions, onChangeWellboreInputParameter } = useTahkCsgWellboreInput(props);

  return (
    <div>
      <FossilyticsDropdown
        label={dictionary.tahk.flowPressureType}
        options={flowPressureTypeOptions}
        disabled={loadingState}
        selectedKey={inputs?.wellbore_inputs.selected_flow_pressure_type}
        onChange={(v) => {
          onChangeWellboreInput(v?.key, "selected_flow_pressure_type");
        }}
        errors={errorInputValidation}
        keyField="input_options.wellbore_inputs.selected_flow_pressure_type"
        styles={{
          root: {
            width: 250,
          },
        }}
        helpUrl={helpLinkUrl.tahk.wellboreInput}
      />

      {inputs?.wellbore_inputs.selected_flow_pressure_type === TahkSelectedFlowPressureTypeEnum.TubingHeadPressure && (
        <>
          <FossilyticsNumberField
            keyField="inputs.wellbore_inputs.parameters.pump_depth"
            label={dictionary.tahk.pumpDepth}
            disabled={loadingState}
            value={inputs?.wellbore_inputs.parameters.pump_depth}
            onChange={(v) => onChangeWellboreInputParameter(v, "pump_depth")}
            min={1}
            suffix="ft"
            errors={errorInputValidation}
            helpUrl={helpLinkUrl.tahk.wellboreInput}
            styles={{
              root: {
                width: 250,
              },
            }}
          />
          <FossilyticsNumberField
            keyField="inputs.wellbore_inputs.parameters.minimum_pump_head"
            label={dictionary.tahk.minPumpHead}
            disabled={loadingState}
            value={inputs?.wellbore_inputs.parameters.minimum_pump_head}
            onChange={(v) => onChangeWellboreInputParameter(v, "minimum_pump_head")}
            min={1}
            suffix="ft"
            errors={errorInputValidation}
            helpUrl={helpLinkUrl.tahk.wellboreInput}
            styles={{
              root: {
                width: 250,
              },
            }}
          />
          <FossilyticsNumberField
            keyField="inputs.wellbore_inputs.parameters.pump_draw_down_rate"
            label={dictionary.tahk.pumpDownRate}
            disabled={loadingState}
            value={inputs?.wellbore_inputs.parameters.pump_draw_down_rate}
            onChange={(v) => onChangeWellboreInputParameter(v, "pump_draw_down_rate")}
            min={1}
            suffix="ft/month"
            errors={errorInputValidation}
            helpUrl={helpLinkUrl.tahk.wellboreInput}
            styles={{
              root: {
                width: 250,
              },
            }}
          />
        </>
      )}
    </div>
  );
};

export default WellboreInput;
