import React, { useMemo } from "react";
import { Dropdown, IDropdownOption } from "@fluentui/react";

import FossilyticsNumberField from "@/components/fields/FossilyticsNumberField";
import FossilyticsChart from "@/components/FossilyticsChart";
import CustomDataTable from "@/components/CustomTable";
import Tabs from "@/components/Tabs";

import "../../ModuleGasAutoRTA.css";

import useGAZAutoRtaPvtDataTable from "../../hooks/rtaInputGrid/useGAZAutoRtaPvtDataTable";
import useGazAutoRtaOilPvtCharts from "../../hooks/rtaInputGrid/useGazAutoRtaOilPvtCharts";
import { InputGridCalculation, GazAutoRtaInputGrid, OilPvt } from "@/models/gaz/autoRta";

import { oilDensityCorrelationOptions, oilViscosityCorrelationOptions, solutionGasOilRatioCorrelationOptions } from "../../constants";

interface OilPvtParamProps {
  value: GazAutoRtaInputGrid;
  calculateValue: InputGridCalculation;
  isLoading: boolean;
  updateOilPvtInputField: (v: number | undefined, key: keyof OilPvt) => void;
  updateOilPvtDropdownField: (v: IDropdownOption | undefined, key: keyof OilPvt) => void;
}

const OilPvtContainer = ({ value, calculateValue, isLoading, updateOilPvtInputField, updateOilPvtDropdownField }: OilPvtParamProps) => {
  const { oilPvtBo, oilPvtMu, oilPvtpo, oilPvtRs, oilPvtSigma, oilPvtCo } = useGazAutoRtaOilPvtCharts(calculateValue);

  const { oilPvtDataTableRows, oilPvtDataTableColumns } = useGAZAutoRtaPvtDataTable(calculateValue);

  const tabList = useMemo(() => {
    return [
      {
        label: (
          <span style={{ fontSize: 16 }}>
            μ<sub>{"o"}</sub>
          </span>
        ),
        key: "μo",
        content: (
          <div className="chartWrapper">
            <FossilyticsChart
              id="oil_pvt_mo"
              isLoading={isLoading}
              xAxes={oilPvtMu.xAxes}
              yAxes={oilPvtMu.yAxes}
              series={oilPvtMu.series}
              hideLegend
            />
          </div>
        ),
      },
      {
        label: (
          <span style={{ fontSize: 16 }}>
            p<sub>{"o"}</sub>
          </span>
        ),
        key: "po",
        content: (
          <div className="chartWrapper">
            <FossilyticsChart
              id="Oil_pvt_po"
              isLoading={isLoading}
              xAxes={oilPvtpo.xAxes}
              yAxes={oilPvtpo.yAxes}
              series={oilPvtpo.series}
              hideLegend
            />
          </div>
        ),
      },
      {
        label: (
          <span style={{ fontSize: 16 }}>
            B<sub>{"o"}</sub>
          </span>
        ),
        key: "Bo",
        content: (
          <div className="chartWrapper">
            <FossilyticsChart
              id="oil_pvt_bo"
              isLoading={isLoading}
              xAxes={oilPvtBo.xAxes}
              yAxes={oilPvtBo.yAxes}
              series={oilPvtBo.series}
              hideLegend
            />
          </div>
        ),
      },
      {
        label: (
          <span style={{ fontSize: 16 }}>
            R<sub>{"s"}</sub>
          </span>
        ),
        key: "Ro",
        content: (
          <div className="chartWrapper">
            <FossilyticsChart
              id="Oil_pvt_po"
              isLoading={isLoading}
              xAxes={oilPvtRs.xAxes}
              yAxes={oilPvtRs.yAxes}
              series={oilPvtRs.series}
              hideLegend
            />
          </div>
        ),
      },
      {
        label: (
          <span style={{ fontSize: 16 }}>
            c<sub>{"o"}</sub>
          </span>
        ),
        key: "co",
        content: (
          <div className="chartWrapper">
            <FossilyticsChart id="Oil_pvt_co" isLoading={false} xAxes={oilPvtCo.xAxes} yAxes={oilPvtCo.yAxes} series={oilPvtCo.series} hideLegend />
          </div>
        ),
      },
      {
        label: (
          <span style={{ fontSize: 16 }}>
            σ<sub>{"o"}</sub>
          </span>
        ),
        key: "oo",
        content: (
          <div className="chartWrapper">
            <FossilyticsChart
              id="Oil_pvt_sigma"
              isLoading={false}
              xAxes={oilPvtSigma.xAxes}
              yAxes={oilPvtSigma.yAxes}
              series={oilPvtSigma.series}
              hideLegend
            />
          </div>
        ),
      },
      {
        label: <span style={{ fontSize: 16 }}>Data Table</span>,
        key: "data table oil",
        content: (
          <div className="chartWrapper">
            <CustomDataTable rows={oilPvtDataTableRows} columns={oilPvtDataTableColumns} isLoading={isLoading} />
          </div>
        ),
      },
    ];
  }, [
    isLoading,
    oilPvtBo.series,
    oilPvtBo.xAxes,
    oilPvtBo.yAxes,
    oilPvtCo.series,
    oilPvtCo.xAxes,
    oilPvtCo.yAxes,
    oilPvtDataTableColumns,
    oilPvtDataTableRows,
    oilPvtMu.series,
    oilPvtMu.xAxes,
    oilPvtMu.yAxes,
    oilPvtRs.series,
    oilPvtRs.xAxes,
    oilPvtRs.yAxes,
    oilPvtSigma.series,
    oilPvtSigma.xAxes,
    oilPvtSigma.yAxes,
    oilPvtpo.series,
    oilPvtpo.xAxes,
    oilPvtpo.yAxes,
  ]);

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "1fr 2.8fr",
      }}
    >
      <div
        style={{
          minWidth: 180,
          maxWidth: 297,
        }}
      >
        <FossilyticsNumberField
          label="Oil gravity API"
          suffix="°"
          debounceDelay={1000}
          value={value.oil_pvt.oil_gravity_api}
          onChange={(v) => updateOilPvtInputField(v, "oil_gravity_api")}
          disabled={isLoading}
        />
        <FossilyticsNumberField
          label="Oil bubble pressure"
          suffix="psia"
          debounceDelay={1000}
          value={value.oil_pvt.bubble_pressure}
          onChange={(v) => updateOilPvtInputField(v, "bubble_pressure")}
          disabled={isLoading}
        />
        <FossilyticsNumberField
          label="Solution Gas specific gravity"
          suffix="dim"
          debounceDelay={1000}
          value={value.oil_pvt.solution_gas_specific_gravity}
          onChange={(v) => updateOilPvtInputField(v, "solution_gas_specific_gravity")}
          disabled={isLoading}
        />
        <Dropdown
          label="Oil density correlation"
          options={oilDensityCorrelationOptions}
          selectedKey={value.oil_pvt.selected_oil_density_correlation}
          onChange={(_, v) => updateOilPvtDropdownField(v, "selected_oil_density_correlation")}
          disabled={isLoading}
        />
        <Dropdown
          label="Oil viscosity correlation"
          options={oilViscosityCorrelationOptions}
          selectedKey={value.oil_pvt.selected_oil_viscosity_correlation}
          onChange={(_, v) => updateOilPvtDropdownField(v, "selected_oil_viscosity_correlation")}
          disabled={isLoading}
        />
        <Dropdown
          label="Solution gas/oil ratio correlation"
          options={solutionGasOilRatioCorrelationOptions}
          selectedKey={value.oil_pvt.selected_solution_gas_oil_correlation}
          onChange={(_, v) => updateOilPvtDropdownField(v, "selected_solution_gas_oil_correlation")}
          disabled={isLoading}
        />
      </div>
      <Tabs tabList={tabList} centered />
    </div>
  );
};

export default OilPvtContainer;
