import FossilyticsChart from "@/components/FossilyticsChart";
import { Pivot, PivotItem } from "@fluentui/react";
import { ReactGrid } from "@silevis/reactgrid";

import { DataViewCalculation } from "@/models/gaz/autoRta";

import useGazAutoRtaDataViewChart from "../../hooks/rtaDataView/useGazAutoRtaDataViewChart";
import useGazAutoRtaDataViewTable from "../../hooks/rtaDataView/useGazAutoRtaDataViewTable";

export type DataviewChartProps = {
  dataViewCalculate: DataViewCalculation;
  isLoading: boolean;
};

const DataViewCalculationContainer = ({ dataViewCalculate, isLoading }: DataviewChartProps) => {
  const { moduleFields, xAxes, yAxes, allSeries } = useGazAutoRtaDataViewChart(dataViewCalculate);
  const { cleanDataTableCols, cleanDataTableRows, rawDataTableCols, rawDataTableRows } = useGazAutoRtaDataViewTable(dataViewCalculate);

  return (
    <Pivot styles={{ itemContainer: { flex: "1" } }}>
      {Object.keys(moduleFields).length > 1 && (
        <PivotItem headerText="Chart">
          <FossilyticsChart id="data_view_chart" isLoading={isLoading} xAxes={xAxes} yAxes={yAxes} series={allSeries} />
        </PivotItem>
      )}

      <PivotItem headerText="Raw Data" className="dataViewTableTab">
        <div className="dataViewTableStyle">
          <ReactGrid columns={rawDataTableCols} rows={rawDataTableRows} enableRangeSelection enableColumnSelection stickyTopRows={2} />
        </div>
      </PivotItem>

      <PivotItem headerText="Clean Data" className="dataViewTableTab">
        <div className="dataViewTableStyle">
          <ReactGrid columns={cleanDataTableCols} rows={cleanDataTableRows} enableRangeSelection enableColumnSelection stickyTopRows={2} />
        </div>
      </PivotItem>
    </Pivot>
  );
};

export default DataViewCalculationContainer;
