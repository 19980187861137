import { calculateTahkCsgForecast } from "@/constants/apiUrl";

import { createPoll } from "@/utils/apiFetcher";
import { TahkCsgForecastPayload, TahkCsgForecastResponse } from "../forecast";

export const pollCalculateTahkCsgForecast = (payload: TahkCsgForecastPayload) => {
  return createPoll<TahkCsgForecastResponse, TahkCsgForecastPayload>({
    path: calculateTahkCsgForecast,
    body: payload,
    type: "post",
  });
};
