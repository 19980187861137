import _ from "lodash";
import {
  CsgUncertainList,
  UncertainKeys,
  UncertainParamFixed,
  UncertainParamKDependence,
  UncertainParamLogNorm,
  UncertainParamLorenz,
  UncertainParamNorm,
  UncertainParamTriangular,
  UncertainParamUniform,
  UncertainParamDistribution,
  Output,
  UncertainParamType,
  MonteCarloInputApplication,
  ForecastResponse,
} from "@/models/koldun";
import { DataSet, Project } from "@/model";

export const constructPreparedMscOutput = (data: Output) => {
  let result: Output = { ...data };

  const firstMeasureLayer = result.measures[0].measure_layers[0];

  // Flatten measure_layers to layers
  result.measures.forEach((measure) => {
    const firstLayer = measure.measure_layers[0];

    // Destructuring the properties that need to be copied
    const { pvt, rel_perm, skin, uncertain_list, geomechanics } = firstLayer;

    measure.measure_layers = measure.measure_layers.map((layer, index) => {
      if (index === 0) return layer; // If it's the first element, keep it unchanged

      // Create a new uncertain_list to hold selectively copied items
      const newUncertainList: Record<UncertainKeys, UncertainParamType> = {
        ...layer.uncertain_list,
      };

      // Iterate through uncertain_list, selectively copying items based on the 'application' property
      for (const key in uncertain_list) {
        const typedKey = key as UncertainKeys;
        if (uncertain_list[typedKey].application === MonteCarloInputApplication.MEASURE) {
          newUncertainList[typedKey] = uncertain_list[typedKey]; // Copy if 'application' is 'measure'
        }
      }

      // Copy the geo-mechanics method from the first measure layer
      geomechanics.method = firstMeasureLayer.geomechanics.method;

      return { ...layer, pvt, rel_perm, skin, geomechanics, uncertain_list: newUncertainList };
    });
  });

  result.layers = result.measures.flatMap((measure) => measure.measure_layers);
  return result;
};

export const getNewUncertainList = (prevOutput: CsgUncertainList, attribute: UncertainKeys, key: string, value: any) => {
  const csgUncertainList = _.cloneDeep(prevOutput);

  const targetUncertain = csgUncertainList[attribute];
  const parsedValue = !value ? null : value;
  switch (targetUncertain.distribution as UncertainParamDistribution) {
    case UncertainParamDistribution.FIXED:
      if (key === "value") (targetUncertain as UncertainParamFixed).value = parsedValue;
      break;
    case UncertainParamDistribution.UNIFORM:
      if (key === "low" || key === "high") (targetUncertain as UncertainParamUniform)[key] = parsedValue;
      break;
    case UncertainParamDistribution.TRIANGULAR:
      if (key === "left" || key === "mode" || key === "right") (targetUncertain as UncertainParamTriangular)[key] = parsedValue;
      break;
    case UncertainParamDistribution.LOG_NORMAL:
      if (key === "mean" || key === "sigma") (targetUncertain as UncertainParamLogNorm)[key] = parsedValue;
      break;
    case UncertainParamDistribution.NORMAL:
      if (key === "mean" || key === "std") (targetUncertain as UncertainParamNorm)[key] = parsedValue;
      break;
    case UncertainParamDistribution.KDEPENDENCE:
      if (key === "min" || key === "max" || key === "perm_at_min_porosity") (targetUncertain as UncertainParamKDependence)[key] = parsedValue;
      break;
    case UncertainParamDistribution.LORENZ:
      if (key === "net_thickness" || key === "lorentz_factor") (targetUncertain as UncertainParamLorenz)[key] = parsedValue;
      break;
  }

  return csgUncertainList;
};

// insert measure layer index to key field for error input validation mapping
export const generateKeyFieldForLayer = (layerIndex: number, keyField: string = "") => {
  // example key field format: `measures.0.measure_layers.0.uncertain_list.permeability_md`
  // always set measure layer index to 0 when it is per measure application type

  const splittedKeyField = keyField.split(".");

  // find measure layers index
  const measureLayerIndex = splittedKeyField.indexOf("measure_layers");

  if (measureLayerIndex === -1) return keyField;
  splittedKeyField.splice(measureLayerIndex + 1, 1, String(layerIndex));

  return splittedKeyField.join(".");
};

export const generateForecastCsvData = ({
  forecastOutput,
  project,
  selectedDataSets,
}: {
  forecastOutput: ForecastResponse;
  project?: Project;
  selectedDataSets?: DataSet;
}) => {
  let m1 = new Date().toLocaleDateString();

  // Export to CSV

  let meanDays: number[] = [];
  forecastOutput?.uncertainty_cases.pmean.CSG_Total_layered.days.map((d, i) => meanDays.push(d));

  let meanGasRate: number[] = [];
  forecastOutput?.uncertainty_cases.pmean.CSG_Total_layered.total_rates_mmscf_d.map((g, j) => meanGasRate.push(g));

  let meanCumGas: number[] = [];
  forecastOutput?.uncertainty_cases.pmean.CSG_Total_layered.cum_total_rates_mmscf.map((cg, k) => meanCumGas.push(cg));

  let meanWaterRate: number[] = [];
  forecastOutput?.uncertainty_cases.pmean.CSG_Total_layered.total_rates_w_bbl.map((w, l) => meanWaterRate.push(w));

  let meanCumWater: number[] = [];
  forecastOutput?.uncertainty_cases.pmean.CSG_Total_layered.cum_total_rates_w_bbl.map((cw, m) => meanCumWater.push(cw));

  // p10
  let p10meanDays: number[] = [];
  forecastOutput?.uncertainty_cases.p10.CSG_Total_layered.days.map((d, i) => p10meanDays.push(d));

  let p10GasRate: number[] = [];
  forecastOutput?.uncertainty_cases.p10.CSG_Total_layered.total_rates_mmscf_d.map((g, j) => p10GasRate.push(g));

  let p10CumGas: number[] = [];
  forecastOutput?.uncertainty_cases.p10.CSG_Total_layered.cum_total_rates_mmscf.map((cg, k) => p10CumGas.push(cg));

  let p10WaterRate: number[] = [];
  forecastOutput?.uncertainty_cases.p10.CSG_Total_layered.total_rates_w_bbl.map((w, l) => p10WaterRate.push(w));

  let p10CumWater: number[] = [];
  forecastOutput?.uncertainty_cases.p10.CSG_Total_layered.cum_total_rates_w_bbl.map((cw, m) => p10CumWater.push(cw));

  // p50
  let p50meanDays: number[] = [];
  forecastOutput?.uncertainty_cases.p50.CSG_Total_layered.days.map((d) => p50meanDays.push(d));

  let p50GasRate: number[] = [];
  forecastOutput?.uncertainty_cases.p50.CSG_Total_layered.total_rates_mmscf_d.map((g) => p50GasRate.push(g));

  let p50CumGas: number[] = [];
  forecastOutput?.uncertainty_cases.p50.CSG_Total_layered.cum_total_rates_mmscf.map((cg) => p50CumGas.push(cg));

  let p50WaterRate: number[] = [];
  forecastOutput?.uncertainty_cases.p50.CSG_Total_layered.total_rates_w_bbl.map((w) => p50WaterRate.push(w));

  let p50CumWater: number[] = [];
  forecastOutput?.uncertainty_cases.p50.CSG_Total_layered.cum_total_rates_w_bbl.map((cw) => p50CumWater.push(cw));

  // p90
  let p90meanDays: number[] = [];
  forecastOutput?.uncertainty_cases.p90.CSG_Total_layered.days.map((d) => p90meanDays.push(d));

  let p90GasRate: number[] = [];
  forecastOutput?.uncertainty_cases.p90.CSG_Total_layered.total_rates_mmscf_d.map((g) => p90GasRate.push(g));

  let p90CumGas: number[] = [];
  forecastOutput?.uncertainty_cases.p90.CSG_Total_layered.cum_total_rates_mmscf.map((cg) => p90CumGas.push(cg));

  let p90WaterRate: number[] = [];
  forecastOutput?.uncertainty_cases.p90.CSG_Total_layered.total_rates_w_bbl.map((w) => p90WaterRate.push(w));

  let p90CumWater: number[] = [];
  forecastOutput?.uncertainty_cases.p90.CSG_Total_layered.cum_total_rates_w_bbl.map((cw) => p90CumWater.push(cw));

  let csvData = "Mean, , , , , P10, , , , , P50, , ,,, P90, , , , \n";

  csvData +=
    "time, gas rate, cum gas, water rate, cum water, time, gas rate, cum gas, water rate, cum water, time, gas rate, cum gas, water rate, cum water, time, gas rate, cum gas, water rate, cum water, \n";

  meanDays.forEach((_, i) => {
    csvData += ` ${meanDays[i]}, ${meanGasRate[i]}, ${meanCumGas[i]}, ${meanWaterRate[i]}, ${meanCumWater[i]}, ${p10meanDays[i]}, ${p10GasRate[i]}, ${p10CumGas[i]}, ${p10WaterRate[i]}, ${p10CumWater[i]}, ${p50meanDays[i]}, ${p50GasRate[i]}, ${p50CumGas[i]}, ${p50WaterRate[i]}, ${p50CumWater[i]}, ${p90meanDays[i]}, ${p90GasRate[i]}, ${p90CumGas[i]}, ${p90WaterRate[i]}, ${p90CumWater[i]}`;
    csvData += "\n";
  });

  const csvFileStructure = `${project?.name}_${(selectedDataSets as DataSet)?.name}_${m1}.csv`;
  const fileName = csvFileStructure.replace(/\s+/g, "_");

  return {
    data: csvData,
    fileName,
  };
};
