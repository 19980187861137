import { Stack, TooltipHost, Icon } from "@fluentui/react";

import { HelpLinkButtonProps } from "./help-link.types";
import { generateWikiLink } from "./help-link.utils";

import styles from "./help-link.module.css";

export const HelpLinkButton = ({ helpLink, children }: HelpLinkButtonProps) => (
  <Stack className={styles.labelContainer} verticalAlign="center" horizontal>
    <TooltipHost className={styles.tooltip} content={helpLink.name}>
      <a href={generateWikiLink(helpLink)} target="_blank" className={styles.link} rel="noreferrer">
        {children}{" "}
        <Icon
          iconName="info"
          styles={{
            root: {
              fontSize: 12,
            },
          }}
        />
      </a>
    </TooltipHost>
  </Stack>
);
