import { useCallback, useEffect, useState } from "react";
import _ from "lodash";
import { useQuery } from "@tanstack/react-query";

import { ErrorValidationDetail } from "@/models/ErrorInputValidation";

import { ApiError } from "@/models/APIGeneric";
import { SummaryCard } from "@/models/Generic";

import {
  AnalysisCalculation,
  GazAutoRta,
  GazAutoRtaAnalysis,
  GazAutoRtaInputGrid,
  pollCalculateAnalysisGazAutoRta,
  pollValidateAnalysisGazAutoRta,
} from "@/models/gaz/autoRta";

import { useGazAutoRtaState } from "../GazAutoRtaContext";

export type GasAutoRtaAnalysisProps = {
  currentTab: number;
  isLoading: boolean;
};

const useGazAutoRtaAnalysis = () => {
  const { gazAutoRTAState, setGazAutoRTAState, setApiError, dataSet } = useGazAutoRtaState();

  const [latestValidState, setLatestValidState] = useState<GazAutoRtaAnalysis>();
  const [analysisSummaryCardParameter, setAnalysisSummaryCardParameter] = useState<SummaryCard[]>();
  const [errorInputValidation, setErrorInputValidation] = useState<ErrorValidationDetail[]>([]);
  const [analysisCalculation, setAnalysisCalculation] = useState<AnalysisCalculation>();

  const [loadCalculation, setLoadCalculation] = useState(false);

  const {
    refetch: validateToApi,
    isFetching,
    isLoading,
  } = useQuery({
    queryKey: ["auto-rta-analysis-validation", gazAutoRTAState?.inputs, gazAutoRTAState?.analysis, dataSet?.id],
    queryFn: async () => {
      if (gazAutoRTAState?.inputs && gazAutoRTAState?.analysis) {
        return pollValidateAnalysisGazAutoRta(
          {
            inputs: { ...gazAutoRTAState?.inputs } as GazAutoRtaInputGrid,
            analysis: { ...gazAutoRTAState?.analysis } as GazAutoRtaAnalysis,
          },
          {
            data_set_ids: dataSet?.id ? [dataSet?.id] : [],
          }
        );
      }
      return {};
    },
    refetchOnWindowFocus: false,
    enabled: false,
  });

  // previous key will always be available
  const onChangeAnalysisInput = (value: any, firstKey: string, secKey?: string, thirdKey?: string, fourthKey?: string) => {
    setGazAutoRTAState((prev) => {
      if (!prev) return prev;
      const newState: { [key: string]: any } = _.cloneDeep(prev.analysis);
      if (fourthKey) {
        newState[firstKey ?? ""][secKey ?? ""][thirdKey ?? ""][fourthKey] = value;
      } else if (thirdKey) {
        newState[firstKey ?? ""][secKey ?? ""][thirdKey ?? ""] = value;
      } else if (secKey) {
        newState[firstKey ?? ""][secKey ?? ""] = value;
      } else {
        newState[firstKey] = value;
      }
      return {
        ...prev,
        analysis: newState as GazAutoRtaAnalysis,
      } as GazAutoRta;
    });
  };

  const onCalculateAutoRtaAnalysis = useCallback(
    async (latesInputState: GazAutoRtaAnalysis) => {
      if (!dataSet) return;
      try {
        setLoadCalculation(true);
        const calculateRes = await pollCalculateAnalysisGazAutoRta(
          {
            inputs: { ...gazAutoRTAState?.inputs } as GazAutoRtaInputGrid,
            analysis: { ...latesInputState } as GazAutoRtaAnalysis,
          },
          {
            data_set_ids: [dataSet.id],
          }
        );
        if (calculateRes.data) {
          setAnalysisCalculation(calculateRes.data);
          setAnalysisSummaryCardParameter(calculateRes?.data?.summary_card);
        }
      } catch (error) {
        setApiError(error as ApiError);
        console.log(error);
      } finally {
        setLoadCalculation(false);
      }
    },
    [dataSet, gazAutoRTAState?.inputs, setApiError]
  );

  const validateAnalysisInput = useCallback(async () => {
    try {
      setErrorInputValidation([]);
      if (!_.isEqual(gazAutoRTAState?.analysis, latestValidState)) {
        const response = await validateToApi();
        const data = response?.data?.data;
        if (data) {
          if (!_.isEqual(_.cloneDeep(data), _.cloneDeep(latestValidState))) {
            setGazAutoRTAState((prev) => {
              if (!prev) return prev;
              return {
                ...prev,
                analysis: _.cloneDeep(data),
              };
            });
            setLatestValidState(_.cloneDeep(data));
          }
          onCalculateAutoRtaAnalysis(_.cloneDeep(data));
        }
      }
    } catch (error: any) {
      // this block is to determine error validation on input
      const responseError: any = error.response;
      if (responseError && responseError.status === 422 && responseError.data.detail?.length !== 0) {
        // parse error message and data, set to state
        setErrorInputValidation(responseError.data.detail as ErrorValidationDetail[]);
      } else {
        console.error(error);
      }
    }
  }, [gazAutoRTAState?.analysis, latestValidState, validateToApi, onCalculateAutoRtaAnalysis, setGazAutoRTAState]);

  useEffect(() => {
    validateAnalysisInput();
  }, [validateAnalysisInput]);

  return {
    analysisInputState: gazAutoRTAState?.analysis,
    errorInputValidation,
    analysisCalculation,
    analysisSummaryCardParameter,
    onChangeAnalysisInput,
    loadAnalysis: isFetching || isLoading || loadCalculation,
  };
};

export default useGazAutoRtaAnalysis;
