type IndividuaItemProps = {
  name: string;
  error?: string;
  success?: string;
};

const IndividualItem = ({ name, error, success }: IndividuaItemProps) => {
  return (
    <div className="file-item">
      <div>
        <h4 style={{ margin: 0 }}>{name}</h4>
        {error && <span className="error-mes">{error}</span>}
        {success && <span className="success-mes">{success}</span>}
      </div>
    </div>
  );
};

export default IndividualItem;
