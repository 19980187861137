import { calculateRullon } from "@/constants/apiUrl";

import { createPoll } from "@/utils/apiFetcher";
import { RulonChangeItem, RulonGroupRunResponse } from "../State";

export const postCalculateRulonGroupRun = (projectId: string, payload: RulonChangeItem[]) => {
  return createPoll<RulonGroupRunResponse, RulonChangeItem[]>({
    path: calculateRullon(projectId),
    body: payload,
    type: "post",
  });
};
