import { spadDeclineAnalysisChartDrag } from "@/constants/apiUrl";

import { fetchApi } from "@/utils/apiFetcher";
import { SpadDeclineCalculation, SpadDeclineType, SpadDeclinePayload, SpadDeclineCoordinate } from "../State";

type SpadDeclineChartDragPayload = SpadDeclinePayload & {
  handlebar: SpadDeclineCoordinate;
};

export const postDragSpadDecline = (body: SpadDeclineChartDragPayload, type: SpadDeclineType) => {
  return fetchApi<SpadDeclineCalculation, SpadDeclineChartDragPayload>({
    path: spadDeclineAnalysisChartDrag(type),
    body,
    type: "post",
  });
};
