import { DataSet, Group, Project } from "@/model";

export type TreeNode = {
  key: string;
  title: string;
  children?: TreeNode[];
  type: "project" | "group" | "dataset";
  level: number;
  parents: string[];
  data_set_ids?: string[];
  childIdList?: string[];
  childKeyList?: string[];
  id: string;
  dataSet?: DataSet;
};

export function transformProjectsToTreeNodes(projects: Project[], dataSets: DataSet[]): TreeNode[] {
  return projects.map((project) => transformProjectToTreeNode(project, 1, dataSets));
}

function transformProjectToTreeNode(project: Project, level: number, dataSets: DataSet[]): TreeNode {
  const treeNode: TreeNode = {
    key: project.id,
    id: project.id,
    title: project.name,
    children: transformGroupsToTreeNodes(project.groups, level + 1, [project.id], dataSets),
    type: "project",
    level: 1,
    parents: [],
  };
  treeNode.childIdList = collectAllChildIds(treeNode.children);
  treeNode.childKeyList = collectAllChildKeys(treeNode.children);
  return treeNode;
}

function transformGroupsToTreeNodes(groups: Group[], level: number, parents: string[], dataSets: DataSet[]): TreeNode[] {
  return groups.map((group) => transformGroupToTreeNode(group, level, [...parents], dataSets));
}

function transformGroupToTreeNode(group: Group, level: number, parents: string[], dataSets: DataSet[]): TreeNode {
  const parentIds = [...parents, group.id];
  const childrenNodes: TreeNode[] = transformGroupsToTreeNodes(group.groups, level + 1, parentIds, dataSets);

  // Add data_set_ids as TreeNode children if they exist
  if (group.data_set_ids) {
    const dataSetNodes: TreeNode[] = group.data_set_ids.map((dataSetId) => {
      const currDataSet = dataSets?.find((data) => data.id.toLowerCase() === dataSetId.toLowerCase());
      return {
        key: `${dataSetId};${group.id}`,
        title: dataSetId,
        type: "dataset",
        level: level + 1,
        parents: parentIds,
        id: dataSetId,
        dataSet: currDataSet,
      };
    });
    childrenNodes.push(...dataSetNodes);
  }

  const treeNode: TreeNode = {
    key: group.id,
    id: group.id,
    title: group.name,
    type: "group",
    children: childrenNodes.length > 0 ? childrenNodes : undefined,
    level,
    parents,
    data_set_ids: group.data_set_ids,
    childIdList: collectAllChildIds(childrenNodes),
    childKeyList: collectAllChildKeys(childrenNodes),
  };
  return treeNode;
}

function collectAllChildIds(children?: TreeNode[]): string[] {
  if (!children) return [];
  let allChildIds: string[] = [];
  for (const child of children) {
    allChildIds.push(child.id);
    if (child.children) {
      allChildIds.push(...collectAllChildIds(child.children));
    }
  }
  return allChildIds;
}
function collectAllChildKeys(children?: TreeNode[]): string[] {
  if (!children) return [];
  let allChildKeys: string[] = [];
  for (const child of children) {
    allChildKeys.push(child.key);
    if (child.children) {
      allChildKeys.push(...collectAllChildKeys(child.children));
    }
  }
  return allChildKeys;
}
