import { spadDeclineAnalysisValidate } from "@/constants/apiUrl";

import { fetchApi } from "@/utils/apiFetcher";
import { AnalysisOption, SpadDeclineType, SpadDeclinePayload } from "../State";

export const postValidateSpadDecline = (body: SpadDeclinePayload, type: SpadDeclineType) => {
  return fetchApi<AnalysisOption, SpadDeclinePayload>({
    path: spadDeclineAnalysisValidate(type),
    body,
    type: "post",
  });
};
