import React, { useCallback, useEffect, useMemo, useState } from "react";
import { shallow } from "zustand/shallow";
import { Checkbox, DetailsList, Dropdown, IColumn, Icon, IDropdownOption, PrimaryButton, SelectionMode, Stack, Text } from "@fluentui/react";
import Tabs from "@/components/Tabs";
import useThemeStyling from "@/utils/useThemeStyling";
import styled from "@emotion/styled";

import ModulePage from "../ModulePage";
import { isDataSet, isDataSets, ModuleId } from "../../model";

import { ModuleRollupExportInterval, ModuleRollupPlotResponse, ModuleRollupValidateResponse } from "./model";
import { useSettings } from "../../SettingsState";
import { saveBlob } from "../../util";
import ModuleRollupPlots from "./ModuleRollupPlots";
import { useAppStore } from "@/features/app";
import { calculateRollup, validateRollup } from "@/constants/apiUrl";

const exportIntervalDropdownOptions = [
  { key: ModuleRollupExportInterval.DAILY, text: "Daily" },
  { key: ModuleRollupExportInterval.MONTHLY, text: "Monthly" },
  { key: ModuleRollupExportInterval.YEARLY, text: "Yearly" },
] as IDropdownOption[];

export function getSubmodulePath(moduleId: ModuleId): string {
  return moduleId === ModuleId.SPAD_DECLINE_GAS ? "gas" : "oil";
}

const StyledTabContainer = styled.div`
  .MuiContainer-root {
    padding-left: 0px;
    padding-right: 0px;
  }
`;

function ModuleRollup() {
  const { isLoading, project, group, selectedDataSets, getRequest, pollRequest, user } = useAppStore(
    (state) => ({
      isLoading: state.isLoading,
      project: state.project,
      group: state.group,
      selectedDataSets: state.selectedDataSets,
      getRequest: state.getRequest,
      pollRequest: state.pollRequest,
      user: state.user,
    }),
    shallow
  );

  const { saveSetting } = useSettings();

  const [tabIndex, setTabIndex] = useState(0);
  const [moduleId, setModuleId] = useState<ModuleId>();
  const [plotInitData, setPlotInitData] = useState<ModuleRollupPlotResponse>();
  const selected = useMemo(() => {
    if (isDataSets(selectedDataSets)) return [...selectedDataSets];
    if (isDataSet(selectedDataSets)) return [selectedDataSets];
  }, [selectedDataSets]);
  const [validateResponse, setValidateResponse] = useState<ModuleRollupValidateResponse>();
  const [exportSeparately, setExportSeparately] = useState(false);
  const [saveToRemote, setSaveToRemote] = useState(false);
  const [exportInterval, setExportInterval] = useState(ModuleRollupExportInterval.DAILY);
  const exportName = useMemo(() => group?.name ?? project?.name, [group, project]);
  const [displayPlot, setDisplayPlot] = useState(false);
  const { theme, elevatedCard } = useThemeStyling();
  const [plotLoading, setPlotLoading] = useState(false);
  const [taskId, setTaskId] = useState<string>();

  const moduleDropdownOptions = useMemo<IDropdownOption[]>(
    () => [
      {
        key: ModuleId.SPAD_DECLINE_GAS,
        text: "SPAD: Gas Decline",
        disabled: !selected?.every((ds) => ds.modules_available.includes(ModuleId.SPAD_DECLINE_GAS)),
      },
      {
        key: ModuleId.SPAD_DECLINE_OIL,
        text: "SPAD: Oil Decline",
        disabled: !selected?.every((ds) => ds.modules_available.includes(ModuleId.SPAD_DECLINE_OIL)),
      },
    ],
    [selected]
  );

  const validationItems = useMemo<any[]>(() => {
    if (!selected) return [];
    return selected.map((ds, i) => ({
      name: ds.name,
      valid:
        validateResponse &&
        !validateResponse.missing_analyses.includes(ds.id) &&
        validateResponse.max_days[i] > -1 &&
        validateResponse.max_days[i] <= 20000,
      maxDays: validateResponse && validateResponse.max_days[i] > -1 && validateResponse.max_days[i] > 20000,
      missing: validateResponse && validateResponse.missing_analyses.includes(ds.id),
    }));
  }, [selected, validateResponse]);

  const validate = useCallback(async () => {
    if (!moduleId || !project || !selected) return;

    try {
      const response: ModuleRollupValidateResponse = await pollRequest(
        validateRollup,
        {
          module_id: moduleId,
          project_id: project.id,
        },
        {
          data_set_ids: selected.map((ds) => ds.id),
        },
        undefined,
        undefined,
        true
      );

      setValidateResponse(response);

      if (
        response.missing_analyses.length === 0 &&
        !response.max_days.some((days) => days > 20000) &&
        !response.max_days.some((days) => days <= -1)
      ) {
        // go to next step if all valid
        setTabIndex(1);
      }
    } catch (e) {
      console.error(e);
    }
  }, [moduleId, project, selected, pollRequest]);

  useEffect(() => {
    (async () => await validate())();
  }, [validate]);

  const calculate = useCallback(async () => {
    if (!moduleId || !project || !selected) return;

    try {
      await pollRequest(
        calculateRollup,
        {
          module_id: moduleId,
          project_id: project.id,
        },
        {
          data_set_ids: selected.map((ds) => ds.id),
        },
        undefined,
        undefined,
        true
      );
      await validate();
    } catch (e) {
      console.error(e);
    }
  }, [moduleId, project, selected, pollRequest, validate]);

  const download = useCallback(async () => {
    if (!moduleId || !project || !selected) return;

    try {
      let fileResponse: any = undefined;
      if (exportSeparately) {
        fileResponse = await getRequest(
          `/modules/rollup/export/separate`,
          {
            module_id: moduleId,
            project_id: project.id,
            data_set_ids: selected.map((ds) => ds.id),
            export_interval: exportInterval,
          },
          "blob"
        );
      } else {
        let response;
        if (taskId === undefined) {
          response = await pollRequest(
            `/modules/rollup/export`,
            {
              module_id: moduleId,
              project_id: project.id,
              data_set_ids: selected.map((ds) => ds.id),
              export_interval: exportInterval,
              export_name: exportName,
            },
            undefined,
            true
          );
          setTaskId(response.task_id);
        }

        if ((response && response.task_status === "SUCCESS") || taskId) {
          if (moduleId === ModuleId.SPAD_DECLINE_GAS) {
            fileResponse = await getRequest(
              "/modules/rollup/gas/export/result",
              {
                task_id: taskId ?? response.task_id,
              },
              "blob"
            );
          }
          if (moduleId === ModuleId.SPAD_DECLINE_OIL) {
            fileResponse = await getRequest(
              "/modules/rollup/oil/export/result",
              {
                task_id: taskId ?? response.task_id,
              },
              "blob"
            );
          }
        }
      }

      if (fileResponse) {
        const fileName = `${exportName}-${Date.now()}.${exportSeparately ? "zip" : "csv"}`;
        saveBlob(fileResponse, fileName);
      }
    } catch (error) {
      console.error(error);
    }
  }, [moduleId, project, pollRequest, selected, exportSeparately, exportInterval, exportName, getRequest, taskId]);

  const downloadJson = useCallback(async () => {
    if (!moduleId || !project || !selected) return;

    try {
      let fileResponse: any = undefined;

      const response = await pollRequest(
        `/modules/rollup/export_project_json`,
        {
          module_id: moduleId,
          project_id: project.id,
          export_interval: exportInterval,
          save_to_remote: saveToRemote,
        },
        undefined,
        true
      );

      if (response && response.task_status === "SUCCESS") {
        fileResponse = await getRequest(
          "/modules/rollup/export_project_json/result",
          {
            task_id: response.task_id,
          },
          "blob"
        );
      }

      if (fileResponse) {
        const partName = user.username.split("@")[0].replace(/\./g, "-");
        const fileName = `${partName}-${exportName}-${Date.now()}-${moduleId}-${exportInterval}.zip`;
        if (!saveToRemote) saveBlob(fileResponse, fileName);
      }
    } catch (error) {
      console.error(error);
    }
  }, [moduleId, project, selected, pollRequest, exportInterval, exportName, getRequest, saveToRemote, user.username]);

  const renderWellValidationItem = useCallback(
    (item: { name: string; valid: boolean; maxDays: boolean; missing: boolean }) => {
      if (item.valid)
        return (
          <>
            <Icon iconName="CheckMark" styles={{ root: { color: theme.palette.green, fontSize: 18 } }} />
            Analysis validated.
          </>
        );
      if (item.maxDays)
        return (
          <>
            <Icon iconName="StatusCircleExclamation" styles={{ root: { color: theme.palette.orange, fontSize: 18, padding: 0 } }} />
            Long calculation time.
          </>
        );
      if (!item.valid && !item.maxDays && !item.missing && validateResponse)
        return (
          <>
            <Icon iconName="Cancel" styles={{ root: { color: theme.palette.red } }} />
            Automatic analysis failure
          </>
        );
      if (item.missing)
        return (
          <>
            <Icon iconName="Cancel" styles={{ root: { color: theme.palette.red } }} />
            Found missing analysis.
          </>
        );
    },
    [theme.palette.green, theme.palette.orange, theme.palette.red, validateResponse]
  );

  const validationColumns = useMemo<IColumn[]>(
    () => [
      { key: "name", fieldName: "name", name: "", minWidth: 0 },
      {
        key: "valid",
        name: "",
        minWidth: 200,
        onRender: (item) => (
          <Stack horizontal verticalAlign="center">
            {isLoading ? (
              <>
                <Icon iconName="HourGlass" styles={{ root: { color: theme.palette.neutralSecondary, fontSize: 18 } }} />
                Validating analysis...
              </>
            ) : (
              renderWellValidationItem(item)
            )}
          </Stack>
        ),
      },
    ],
    [isLoading, theme.palette.neutralSecondary, renderWellValidationItem]
  );

  const generateSpadPlots = useCallback(async () => {
    if (!moduleId || !project || !selected) return;
    setDisplayPlot(true);
    setPlotLoading(true);
    try {
      let response;
      if (taskId === undefined) {
        response = await pollRequest(
          `/modules/rollup/export`,
          {
            module_id: moduleId,
            project_id: project.id,
            data_set_ids: selected.map((ds) => ds.id),
            export_interval: exportInterval,
            export_name: exportName,
          },
          undefined,
          true
        );
        setTaskId(response.task_id);
      }
      if ((response && response.task_status === "SUCCESS") || taskId) {
        const plotResponse = await getRequest(`/modules/rollup/${getSubmodulePath(moduleId)}/export/rollup_charts`, {
          task_id: taskId ?? response.task_id,
        });
        setPlotInitData(plotResponse);
        setPlotLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  }, [moduleId, project, selected, taskId, exportInterval, exportName, getRequest, pollRequest]);

  const tabList = useMemo(() => {
    const validItems = validationItems.filter((item) => item.valid);
    const longCalculationItems = validationItems.filter((item) => item.maxDays);
    const autoAnalysisFailure = validationItems.filter((item) => !item.maxDays && !item.valid && !item.missing);
    const missingItems = validationItems.filter((item) => item.missing);

    const res = [];
    if (missingItems.length > 0)
      res.push({
        label: <span>Missing Analyses</span>,
        key: "validated",
        content: <DetailsList columns={validationColumns} items={missingItems} selectionMode={SelectionMode.none} isHeaderVisible={false} />,
      });

    if (longCalculationItems.length > 0)
      res.push({
        label: <span>Long Calculation Time Analyses</span>,
        key: "validated",
        content: <DetailsList columns={validationColumns} items={longCalculationItems} selectionMode={SelectionMode.none} isHeaderVisible={false} />,
      });
    if (validItems.length > 0)
      res.push({
        label: <span>Validated Analyses</span>,
        key: "validated",
        content: <DetailsList columns={validationColumns} items={validItems} selectionMode={SelectionMode.none} isHeaderVisible={false} />,
      });
    if (autoAnalysisFailure.length > 0 && validateResponse)
      res.push({
        label: <span>Automatic Analyses Failure</span>,
        key: "validated",
        content: <DetailsList columns={validationColumns} items={autoAnalysisFailure} selectionMode={SelectionMode.none} isHeaderVisible={false} />,
      });

    return res;
  }, [validateResponse, validationColumns, validationItems]);

  return (
    <ModulePage
      title={"ROLLUP: Forecast Export"}
      tabIndex={tabIndex}
      onTabChange={setTabIndex}
      tabs={
        selected
          ? [
              {
                headerText: "Validation",
                itemIcon: "DocumentApproval",
                disabled: isLoading,
                disableHideSidebar: true,
                content: (
                  <Stack style={{ height: "100%" }} tokens={{ padding: 20, childrenGap: 20 }}>
                    <Stack style={elevatedCard} tokens={{ childrenGap: 10 }}>
                      <Text styles={{ root: { color: theme.palette.themePrimary } }}>Module select</Text>

                      <span>Select a module to export forecasts.</span>

                      <Dropdown
                        placeholder="Select a module..."
                        disabled={isLoading}
                        options={moduleDropdownOptions}
                        selectedKey={moduleId}
                        onChange={(_, v) => setModuleId(v?.key as ModuleId)}
                      />
                    </Stack>

                    <Stack style={elevatedCard} tokens={{ childrenGap: 10 }}>
                      <Text styles={{ root: { color: theme.palette.themePrimary } }}>Save project</Text>

                      <span>The current project must be saved in order to export forecasts.</span>

                      <PrimaryButton onClick={() => saveSetting()} disabled={isLoading}>
                        Save project
                      </PrimaryButton>
                    </Stack>

                    <Stack style={elevatedCard} tokens={{ childrenGap: 10 }}>
                      <Text styles={{ root: { color: theme.palette.themePrimary } }}>Validate analyses</Text>
                      <PrimaryButton
                        onClick={() => calculate()}
                        disabled={isLoading || !moduleId || (validateResponse && validateResponse.missing_analyses.length === 0)}
                      >
                        Calculate missing analyses
                      </PrimaryButton>

                      {validateResponse && tabList.length > 0 ? (
                        <StyledTabContainer>
                          <Tabs tabList={tabList} />
                        </StyledTabContainer>
                      ) : (
                        <DetailsList columns={validationColumns} items={validationItems} selectionMode={SelectionMode.none} isHeaderVisible={false} />
                      )}
                    </Stack>
                  </Stack>
                ),
              },
              {
                headerText: "Export",
                itemIcon: "DownloadDocument",
                disabled: isLoading || !validateResponse || validateResponse.missing_analyses.length > 0,
                disableHideSidebar: true,
                content: (
                  <Stack style={{ height: "100%" }} tokens={{ padding: 20, childrenGap: 20 }}>
                    <Stack style={elevatedCard} tokens={{ childrenGap: 10 }}>
                      <Text styles={{ root: { color: theme.palette.themePrimary } }}>Export analyses</Text>

                      <Checkbox label="Export separately" checked={exportSeparately} onChange={(_, v) => setExportSeparately(!!v)} />

                      <Checkbox
                        label="Save to remote"
                        checked={saveToRemote}
                        disabled={project?.groups.length === 1 ? false : group !== undefined}
                        onChange={(_, v) => setSaveToRemote(!!v)}
                      />
                      <Dropdown
                        label="Export interval"
                        disabled={isLoading}
                        options={exportIntervalDropdownOptions}
                        selectedKey={exportInterval}
                        onChange={(_, v) => {
                          setExportInterval(v?.key as ModuleRollupExportInterval);
                          setTaskId(undefined);
                        }}
                      />

                      <PrimaryButton onClick={download}>Export "{exportName}" CSV</PrimaryButton>
                      <PrimaryButton onClick={generateSpadPlots}>Generate Plots</PrimaryButton>
                      <PrimaryButton disabled={project?.groups.length === 1 ? false : group !== undefined} onClick={downloadJson}>
                        Export "{exportName}" JSON
                      </PrimaryButton>
                    </Stack>

                    {displayPlot && <ModuleRollupPlots moduleId={moduleId} plotInitData={plotInitData} plotLoading={plotLoading}></ModuleRollupPlots>}
                  </Stack>
                ),
              },
            ]
          : []
      }
    />
  );
}

export default ModuleRollup;
