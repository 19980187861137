import React, { useCallback } from "react";
import { Dropdown, Stack, Text, useTheme } from "@fluentui/react";
import _ from "lodash";

import CsgBaseInputs from "./Components/CsgBaseInputs";
import MeasurePVTInputs from "./Components/MeasurePVTInputs";
import MeasureRelPermTable from "./Components/MeasureRelPermTable";
import MonteCarloInputCard from "./Components/MonteCarloInputCard";
import KodunCsgFlowParametersInputs from "./Components/KoldunCsgFlowParametersInputs";
import DataInputs from "./Components/DataInput";
import { GeomechanicsOptions } from "./Components/model";
import MatrixShrinkageTable, { MatrixShrinkageFieldsMap } from "./Components/MatrixShrinkageTable";
import { Output } from "@/models/koldun";
import { HelpLinkButton, THEORETICAL_HELP_LINKS } from "@/features/help-link";

import { ErrorValidationDetail } from "@/models/ErrorInputValidation";

interface ModuleKoldunMcsimCsgInputGridTabProps {
  value: Output;
  onChange: React.Dispatch<React.SetStateAction<Output | null>>;
  errorInputValidation?: ErrorValidationDetail[];
}

const ModuleKoldunMcsimCsgInputGridTab = ({ value, onChange, errorInputValidation }: ModuleKoldunMcsimCsgInputGridTabProps) => {
  const theme = useTheme();
  const measureNum = 0;

  // TODO: Centralize this
  const itemStyle = {
    boxShadow: theme.effects.elevation4,
    padding: 15,
    borderRadius: theme.effects.roundedCorner4,
  } as React.CSSProperties;

  const handleChanges = useCallback(
    (newValue: any) => {
      onChange((prev) => {
        if (!prev) return prev;
        const prevInputs: Output = { ...prev };

        const key = Object.keys(newValue).find((k) => k !== "id" && k !== "measure_num" && k !== "measure_layers");

        const value = newValue[key as string];

        if (key) {
          prevInputs.measures[measureNum].measure_layers[0].geomechanics.method = value;
        }
        return _.cloneDeep(prevInputs);
      });
    },
    [measureNum, onChange]
  );

  return (
    <Stack tokens={{ padding: 20, childrenGap: 20 }}>
      <Stack.Item style={itemStyle}>
        <Text styles={{ root: { color: theme.palette.themePrimary, fontWeight: 600 } }}>Inputs</Text>
        <CsgBaseInputs errorInputValidation={errorInputValidation} value={value} onChange={onChange} />
      </Stack.Item>

      <Stack.Item style={itemStyle}>
        <Stack.Item>
          <Text
            styles={{
              root: {
                color: theme.palette.themePrimary,
                position: "relative",
                top: -4,
                right: 0,
                fontWeight: 600,
              },
            }}
          >
            <HelpLinkButton helpLink={THEORETICAL_HELP_LINKS.gasPvtCorrelations}>Measure PVT Inputs</HelpLinkButton>
          </Text>
          <MeasurePVTInputs errorInputValidation={errorInputValidation} value={value} onChange={onChange} />
        </Stack.Item>
      </Stack.Item>
      <Stack.Item style={itemStyle}>
        <Stack.Item>
          <Text
            styles={{
              root: {
                color: theme.palette.themePrimary,
                position: "relative",
                top: -4,
                right: 0,
                fontWeight: 600,
              },
            }}
          >
            <HelpLinkButton helpLink={THEORETICAL_HELP_LINKS.relativePermeability}>Measure Rel Perm Curves</HelpLinkButton>
          </Text>
          <MeasureRelPermTable errorInputValidation={errorInputValidation} value={value} onChange={onChange} />
        </Stack.Item>
      </Stack.Item>

      <Stack.Item style={itemStyle}>
        <Stack.Item>
          <Text
            styles={{
              root: {
                color: theme.palette.themePrimary,
                position: "relative",
                top: -4,
                right: 0,
                fontWeight: 600,
              },
            }}
          >
            Matrix Shrinkage
          </Text>
          <Stack horizontal tokens={{ childrenGap: 20 }}>
            <Stack grow={1} className="equal-size" tokens={{ childrenGap: 5 }}>
              <Dropdown
                label="Geomechanics"
                options={GeomechanicsOptions}
                style={{ width: 200 }}
                selectedKey={value.measures[measureNum].measure_layers[0].geomechanics.method}
                onChange={(_, v) => handleChanges(v)}
              />
              <MatrixShrinkageTable
                value={value}
                field={MatrixShrinkageFieldsMap[value.measures[measureNum].measure_layers[0].geomechanics.method]}
                onChange={onChange}
                errorInputValidation={errorInputValidation}
              />
            </Stack>
          </Stack>
        </Stack.Item>
      </Stack.Item>

      <Stack.Item style={itemStyle}>
        <Text styles={{ root: { color: theme.palette.themePrimary, fontWeight: 600 } }}>Montecarlo Inputs</Text>

        <Stack tokens={{ childrenGap: 5 }}>
          <Stack horizontal={false}>{<MonteCarloInputCard errorInputValidation={errorInputValidation} value={value} onChange={onChange} />}</Stack>
        </Stack>
      </Stack.Item>
      <Stack style={itemStyle} tokens={{ childrenGap: 5 }}>
        <Text styles={{ root: { color: theme.palette.themePrimary, fontWeight: 600 } }}>Data</Text>
        <DataInputs value={value} onChange={onChange} />
      </Stack>

      <Stack style={itemStyle} tokens={{ childrenGap: 5 }}>
        <Text styles={{ root: { color: theme.palette.themePrimary, fontWeight: 600 } }}>Flow Parameters</Text>
        <KodunCsgFlowParametersInputs errorInputValidation={errorInputValidation} value={value} onChange={onChange} />
      </Stack>
    </Stack>
  );
};

export default ModuleKoldunMcsimCsgInputGridTab;
