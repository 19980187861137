import React from "react";
import { Stack } from "@fluentui/react";

// utils
import useGazAutoRtaForecast, { GazAutoRtaForecastProps } from "../../hooks/useGazAutoRtaForecast";

// component
import AutoRtaForecastSetting from "./AutoRtaForecastSetting";
import AutoRtaForecastCalculation from "./AutoRtaForecastCalculation";
import LoadingIndicator from "@/components/LoadingIndicator";

const AutoRtaForecastContainer = (props: GazAutoRtaForecastProps) => {
  const {
    errorInputValidation,
    forecastInputState,
    onChangeForecastInput,
    onChangeForecastInputTable,
    forecastEventsRow,
    forecastCalculationDataTableRow,
    forecastXAxes,
    forecastYAxes,
    forecastCalcSeries,
    autoRtaForecastParameter,
    cumulativeGasChartSeries,
    cumulativeGasYAxes,
    loadForecast,
  } = useGazAutoRtaForecast(props);

  const loadingState = loadForecast || props.isLoading;

  // don't display anything if no forecastInputState
  if (!forecastInputState) return <></>;

  return (
    <Stack horizontal style={{ height: "100%" }} tokens={{ padding: 20, childrenGap: 20 }}>
      <AutoRtaForecastSetting
        onChangeInput={onChangeForecastInput}
        forecastInput={forecastInputState}
        onChangeTableInput={onChangeForecastInputTable}
        errorInputValidation={errorInputValidation}
        forecastEventsRow={forecastEventsRow}
        autoRtaForecastParameter={autoRtaForecastParameter}
        isLoading={loadingState}
      />
      <AutoRtaForecastCalculation
        forecastXAxes={forecastXAxes}
        forecastYAxes={forecastYAxes}
        dataTableRow={forecastCalculationDataTableRow}
        isLoading={loadingState}
        forecastCalcSeries={forecastCalcSeries}
        cumulativeGasChartSeries={cumulativeGasChartSeries}
        cumulativeGasYAxes={cumulativeGasYAxes}
      />
    </Stack>
  );
};

const AutoRtaForecastContainerWrapper = (props: GazAutoRtaForecastProps) => {
  // this force effect hooks to not update if user don't open forecast tab
  // since current module logic doesn't have any lazy rendering
  if (props.currentTab !== 3 || props.isLoading) return <LoadingIndicator />;
  return <AutoRtaForecastContainer {...props} />;
};

export default AutoRtaForecastContainerWrapper;
