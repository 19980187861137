import React, { PropsWithChildren } from "react";
import { Stack, Text, useTheme } from "@fluentui/react";

import Announcement from "../Announcement";
import ModuleLink from "./ModuleLink";
import Page from "../../Page";
import useModuleList, { ModuleGridGroup } from "./hooks/useModuleList";

export interface IAddDataButtonProps {
  // These are set based on the toggles shown above the examples (not needed in real code)
  disabled?: boolean;
  checked?: boolean;
}

function ModuleGroup({ children, isSolo }: Readonly<PropsWithChildren<{ isSolo: boolean }>>) {
  const theme = useTheme();
  return (
    <div style={{ position: "relative" }}>
      {!isSolo && (
        <div
          style={{
            position: "absolute",
            top: 60,
            left: 65,
            right: 65,
            height: 25,
            background: theme.palette.neutralLight,
            opacity: 0.7,
          }}
        />
      )}

      <Stack horizontal>{children}</Stack>
    </div>
  );
}

interface ModuleRowProps {
  offset?: boolean;
  hasMulti?: boolean;
}

function ModuleRow({ children, offset, hasMulti }: PropsWithChildren<ModuleRowProps>) {
  return (
    <Stack horizontal>
      {offset ? <div style={{ inlineSize: 65 }}></div> : undefined}
      {children}
      {!offset ? <div style={{ inlineSize: 65 }}></div> : undefined}
    </Stack>
  );
}

function Modules() {
  const { hasDataSet, theme, modules, onClickModule, hasMulti } = useModuleList();

  return (
    <Page title="Modules">
      <Announcement />

      <Stack
        horizontalAlign="center"
        verticalAlign="center"
        style={{ height: "100%", paddingBottom: 20, paddingLeft: 20, paddingRight: 20, marginTop: -30 }}
      >
        <Text variant="large" block>
          Welcome to
        </Text>
        <Text variant="xxLarge" block>
          <span style={{ color: theme.palette.themePrimary }}>predico</span> Advanced Flow Analytics
        </Text>
        {!hasDataSet && (
          <>
            <br />
            <Text block>Choose a well or group to get started.</Text>
          </>
        )}

        <Stack
          style={{
            marginTop: 10,
          }}
          horizontalAlign="center"
        >
          {modules.map((moduleRow, moduleRowIndex: number) => {
            return (
              <ModuleRow hasMulti={hasMulti} offset={moduleRowIndex === 2} key={`row-${moduleRow[0].section}`}>
                {moduleRow.map((moduleGroup: ModuleGridGroup) => {
                  const isSolo = moduleGroup.items.length === 1;

                  return (
                    <ModuleGroup isSolo={isSolo} key={moduleGroup.section}>
                      {moduleGroup.items.map((item) => {
                        return (
                          <ModuleLink
                            key={item.key}
                            onClickModule={() => onClickModule(item.key)}
                            text={moduleGroup.section}
                            secondaryText={item.label}
                            to={item.route}
                            disabled={item.disabled}
                          />
                        );
                      })}
                    </ModuleGroup>
                  );
                })}
              </ModuleRow>
            );
          })}
        </Stack>
      </Stack>
    </Page>
  );
}

export default Modules;
