import { AnalysisResult } from "@/models/spad/decline";
import { useMemo } from "react";
import { tableHeaderNotationStyle, tableHeaderStyle, tableRowStyle } from "../../constants";
import { Column } from "@silevis/reactgrid";

type UseAnalysisSumaryProps = {
  analysisResult?: AnalysisResult | null;
  unit: string;
  dataSets?: string[];
};

const analysisTableColumn = [
  { columnId: "case", width: 100 },
  { columnId: "eur", justifyContent: "center", width: 130 },
  { columnId: "remainingRecoverable", justifyContent: "center", width: 160 },
  { columnId: "qx", justifyContent: "center", width: 180 },
  { columnId: "Dx", justifyContent: "center", width: 190 },
] as Column[];

const useAnalysisSumary = ({ analysisResult, unit, dataSets }: UseAnalysisSumaryProps) => {
  const tableHeader = useMemo(() => {
    return [
      {
        text: "Case",
        type: "text",
        style: tableHeaderStyle,
      },
      { text: `EUR `, type: "text", style: tableHeaderStyle },
      { text: `Remaining Recoverable `, type: "text", style: tableHeaderStyle },
      { text: `Forecast start rate `, type: "text", style: tableHeaderStyle },
      { text: `Forecast start Decline rate `, type: "text", style: tableHeaderStyle },
    ];
  }, []);

  const tableNotation = useMemo(() => {
    const unitCell = { text: unit, type: "text", style: tableHeaderNotationStyle, nonEditable: true };

    const tableHeaderNotation = [
      {
        text: "",
        type: "text",
        style: tableHeaderNotationStyle,
      },
      unitCell,
      unitCell,
      { text: `${unit}/D`, type: "text", style: tableHeaderNotationStyle },
      { text: `1/D`, type: "text", style: tableHeaderNotationStyle },
    ];
    return tableHeaderNotation;
  }, [unit]);

  const tableRows = useMemo(() => {
    if (!analysisResult?.operational_result || !analysisResult?.profile_result) return [];
    const { low, high, mid } = analysisResult.operational_result;
    const { low: lowProf, mid: midProf, high: highProf } = analysisResult.profile_result;

    return [
      [
        "OPS Low",
        low.decline_volume_result.estimated_ultimate_recovery,
        low.decline_volume_result.remaining_reserve,
        low.decline_parameter_result.forecast_start_rate,
        low.decline_parameter_result.forecast_start_decline_rate,
      ],
      [
        "OPS Mid",
        mid.decline_volume_result.estimated_ultimate_recovery,
        mid.decline_volume_result.remaining_reserve,
        mid.decline_parameter_result.forecast_start_rate,
        mid.decline_parameter_result.forecast_start_decline_rate,
      ],
      [
        "OPS High",
        high.decline_volume_result.estimated_ultimate_recovery,
        high.decline_volume_result.remaining_reserve,
        high.decline_parameter_result.forecast_start_rate,
        high.decline_parameter_result.forecast_start_decline_rate,
      ],
      [
        "Profile Low",
        lowProf.decline_volume_result.estimated_ultimate_recovery,
        lowProf.decline_volume_result.remaining_reserve,
        lowProf.decline_parameter_result.forecast_start_rate,
        lowProf.decline_parameter_result.forecast_start_decline_rate,
      ],
      [
        "Profile Mid",
        midProf.decline_volume_result.estimated_ultimate_recovery,
        midProf.decline_volume_result.remaining_reserve,
        midProf.decline_parameter_result.forecast_start_rate,
        midProf.decline_parameter_result.forecast_start_decline_rate,
      ],
      [
        "Profile High",
        highProf.decline_volume_result.estimated_ultimate_recovery,
        highProf.decline_volume_result.remaining_reserve,
        highProf.decline_parameter_result.forecast_start_rate,
        highProf.decline_parameter_result.forecast_start_decline_rate,
      ],
    ];
  }, [analysisResult?.operational_result, analysisResult?.profile_result]);

  const analysisTable = useMemo(() => {
    const headerSection = [
      {
        rowId: "header",
        cells: tableHeader,
      },
      {
        rowId: "notation",
        cells: tableNotation,
      },
    ];

    return [
      ...headerSection,
      ...tableRows.map((row, indexRow) => {
        return {
          rowId: indexRow,
          cells: row.map((cell, cellIndex) => {
            if (cellIndex === 0)
              return {
                type: "text",
                text: cell,
                nonEditable: true,
                style: tableRowStyle,
              };
            const isExponent = Number(cell) > 1000 || Number(cell) < 0.01;

            return {
              type: "text",
              text: isExponent ? `${Number(cell).toExponential(2)}` : Number(cell).toPrecision(3),
              nonEditable: true,
              style: tableRowStyle,
            };
          }),
        };
      }),
    ];
  }, [tableHeader, tableNotation, tableRows]);

  const tableCsv = {
    fileName: `${dataSets?.join(", ")} Analysis Table.csv`,
    data: [
      //header
      tableHeader
        .map((header, index) => {
          return `${header.text} ${tableNotation[index].text}`;
        })
        .join(","),
      // row:
      ...tableRows.map((row) => row.join(",")),
    ].join("\n"),
    isTableCsv: true,
  };

  return { analysisTable, analysisTableColumn, tableCsv };
};

export default useAnalysisSumary;
