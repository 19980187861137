import { useAppStore } from "@/features/app";
import ModuleKoldunMcsimCsgContent from "./ModuleKoldunMcsimCsgContent";
import { shallow } from "zustand/shallow";
import { DataSet } from "@/model";
import { useState } from "react";

const ModuleKoldunMcsimCsg = () => {
  const [currentTab, setCurrentTab] = useState(0);

  const { project, selectedDataSets } = useAppStore(
    (state) => ({
      project: state.project,
      selectedDataSets: state.selectedDataSets,
    }),
    shallow
  );

  return (
    <ModuleKoldunMcsimCsgContent
      // to make sure we retain latest user tab
      currentTab={currentTab}
      setCurrentTab={setCurrentTab}
      // make sure we have clean slate
      key={`${project?.id}-${(selectedDataSets as DataSet)?.id}`}
      title="KOLDUN: CSG Monte-Carlo Simulation"
    />
  );
};

export default ModuleKoldunMcsimCsg;
