import { Theme } from "@fluentui/react";

import { ModuleId, Field } from "@/model";
import { ModuleSpadDeclineArpParams, SpadAnalysisInput, SpadDeclineAnalysis, ForecastParameter, ModuleSpadDeclineCaseParams } from "./index.types";

export const backCalculateDeclineRate = (qi_mmscf_d_new: number, qi_mmscf_d_test: number, day_test: number, b: number): number => {
  if (b === 0) {
    // exponential
    return Math.log(qi_mmscf_d_test / qi_mmscf_d_new) / day_test;
  } else {
    // hyperbolic
    const temp1 = qi_mmscf_d_test ** -b - qi_mmscf_d_new ** -b;
    return (temp1 / b / day_test) * qi_mmscf_d_new ** b;
  }
};

// Recalculates q exit, plus anything else that needs to be calculated in future
export const calculateParams = (prev?: ModuleSpadDeclineArpParams): ModuleSpadDeclineArpParams | undefined => {
  return {
    ...prev,
  };
};

export const getSubModulePath = (moduleId: ModuleId): string => (moduleId === ModuleId.SPAD_DECLINE_GAS ? "gas" : "oil");

export const getOilDataFields = (theme: Theme) => ({
  [Field.OIL_RATE]: {
    name: "Oil rate",
    yAxis: "Rate (bbl/d)",
    color: theme.palette.green,
  },
  [Field.WATER_RATE]: {
    name: "Water rate",
    yAxis: "Rate (bbl/d)",
    color: theme.palette.blueLight,
    onlyCombined: true,
    defaultDisabled: true,
  },
  [Field.TOTAL_LIQUID]: {
    name: "Total liquid",
    yAxis: "Rate (bbl/d)",
    color: theme.palette.purpleDark,
    onlyCombined: true,
  },
  [Field.WATER_CUT]: {
    name: "Water cut",
    yAxis: "Oil/Water cut ratio",
    color: theme.palette.blue,
    onlyCombined: true,
    defaultDisabled: true,
  },
  [Field.OIL_CUT]: {
    name: "Oil cut",
    yAxis: "Oil/Water cut ratio",
    color: theme.palette.greenDark,
    defaultDisabled: true,
    onlyCombined: true,
  },
});

export const getGasDataFields = (theme: Theme) => ({
  [Field.GAS_RATE]: {
    name: "Gas rate",
    yAxis: "Rate (MMscf/d)",
    color: theme.palette.red,
  },
});

const paramMapping = ["low", "mid", "high"];

export const getAnalysisParameter = (param?: ModuleSpadDeclineCaseParams): ForecastParameter => {
  let res: ForecastParameter = {} as ForecastParameter;
  if (!param) return res;

  for (const key of paramMapping) {
    if (param[key as keyof ModuleSpadDeclineCaseParams]) {
      const singleArpParam = param[key as keyof ModuleSpadDeclineCaseParams] as { [key: string]: number | undefined };
      if (Object.keys(singleArpParam).length < 4) return {} as ForecastParameter;

      res[key as keyof ForecastParameter] = {
        initial_rate: singleArpParam?.qi,
        arps_exponent: singleArpParam?.b,
        initial_decline_rate: singleArpParam?.m,
        initial_day: singleArpParam?.x,
      };
    }
  }

  return res;
};

export const parseOldAnalysisDataToAnalysisInput = (
  analysis: SpadDeclineAnalysis,
  paramsOps: ModuleSpadDeclineCaseParams,
  paramsProfile: ModuleSpadDeclineCaseParams
): SpadAnalysisInput | null => {
  const opsForecast = getAnalysisParameter(paramsOps);
  const profileForecast = getAnalysisParameter(paramsProfile);

  if (Object.keys(opsForecast).length !== 3 || Object.keys(profileForecast).length !== 3) return null;
  return {
    ...parseParamToInput(analysis),
    ops_forecast: opsForecast,
    profile_forecast: profileForecast,
  };
};

export const parseParamToInput = (analysis?: SpadDeclineAnalysis) => {
  return {
    economic_cutoff: Number(analysis?.ops?.economicCutoff),
    recovery_factor: Number(analysis?.ops?.recFactorPerc),
    analysis_start_day: Number(analysis?.ops?.pssStartDay),
    auto_update_cluster_start_day: analysis?.autoUpdateClusterStartDay,
    auto_update_smart_fit: analysis?.autoUpdateSmartFit,
  } as SpadAnalysisInput;
};
