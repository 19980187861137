import FossilyticsNumberField from "@/components/fields/FossilyticsNumberField";
import { Checkbox, Dropdown, IDropdownOption, Stack, useTheme } from "@fluentui/react";

import { GasPvt, GasPvtRecombination, GazAutoRtaInputGrid } from "@/models/gaz/autoRta";

import { gasViscMethodOptions, zMethodOptions } from "../../constants";

export type GasPvtInputFieldProps = {
  value: GazAutoRtaInputGrid;
  gasRecombination: boolean | undefined;
  onCheckboxGasRecombination: (v: boolean | undefined) => void;
  updateGasPvtInputField: (v: number | undefined, key: keyof GasPvt) => void;
  updateGasRecombinationInput: (v: number | undefined, key: keyof Omit<GasPvtRecombination, "use_gas_recombination">) => void;
  updateGasPvtSelection: (v: IDropdownOption | undefined, key: keyof GasPvt) => void;
  isLoading: boolean;
};

const GasPvtInputField = ({
  value,
  updateGasPvtInputField,
  gasRecombination,
  updateGasPvtSelection,
  onCheckboxGasRecombination,
  updateGasRecombinationInput,
  isLoading,
}: GasPvtInputFieldProps) => {
  const theme = useTheme();
  return (
    <div style={{ minWidth: 180, maxWidth: 297 }}>
      <FossilyticsNumberField
        label="Specific gravity"
        suffix="dim"
        debounceDelay={1000}
        value={value.gas_pvt.specific_gravity}
        onChange={(v) => updateGasPvtInputField(v, "specific_gravity")}
        disabled={isLoading}
      />
      <FossilyticsNumberField
        label="N₂"
        suffix="%"
        min={0}
        max={100}
        debounceDelay={1000}
        value={value.gas_pvt.nitrogen}
        onChange={(v) => updateGasPvtInputField(v, "nitrogen")}
        disabled={isLoading}
      />
      <FossilyticsNumberField
        label="CO₂"
        suffix="%"
        min={0}
        max={100}
        debounceDelay={1000}
        value={value.gas_pvt.carbon_dioxide}
        onChange={(v) => updateGasPvtInputField(v, "carbon_dioxide")}
        disabled={isLoading}
      />
      <FossilyticsNumberField
        label="H₂S"
        suffix="%"
        min={0}
        max={100}
        debounceDelay={1000}
        value={value.gas_pvt.hydrogen_sulphide}
        onChange={(v) => updateGasPvtInputField(v, "hydrogen_sulphide")}
        disabled={isLoading}
      />
      <Dropdown
        label="Gas viscosity correlation"
        options={gasViscMethodOptions}
        selectedKey={value.gas_pvt.selected_gas_viscosity_correlation}
        onChange={(_, v) => updateGasPvtSelection(v, "selected_gas_viscosity_correlation")}
        disabled={isLoading}
      />
      <Dropdown
        label="Z correlation"
        options={zMethodOptions}
        selectedKey={value.gas_pvt.selected_z_correlation}
        onChange={(_, v) => updateGasPvtSelection(v, "selected_z_correlation")}
        disabled={isLoading}
      />

      <Stack tokens={{ childrenGap: 20 }} style={{ marginTop: 30 }}>
        <Checkbox
          label="Gas Recombination"
          styles={{ text: { color: theme.palette.themePrimary } }}
          checked={gasRecombination}
          onChange={(_, v) => onCheckboxGasRecombination(v)}
          disabled={isLoading}
        />

        {gasRecombination && value?.gas_pvt.section_gas_recombination && (
          <Stack horizontal tokens={{ childrenGap: 20 }}>
            <Stack grow={1} className="equal-size" tokens={{ childrenGap: 5 }}>
              <FossilyticsNumberField
                label="Condensate Gas Ratio (CGR)"
                suffix="stb/MMscf"
                debounceDelay={1000}
                value={value.gas_pvt.section_gas_recombination.condensate_gas_ratio}
                onChange={(v) => updateGasRecombinationInput(v, "condensate_gas_ratio")}
                disabled={isLoading}
              />
              <FossilyticsNumberField
                label="Gas gravity Separator"
                suffix="dim"
                debounceDelay={1000}
                value={value.gas_pvt.section_gas_recombination.specific_gravity_separator}
                disabled={isLoading}
                onChange={(v) => updateGasRecombinationInput(v, "specific_gravity_separator")}
              />
              <FossilyticsNumberField
                label="Condensate API"
                suffix="API"
                debounceDelay={1000}
                value={value.gas_pvt.section_gas_recombination.condensate_API}
                disabled={isLoading}
                onChange={(v) => updateGasRecombinationInput(v, "condensate_API")}
              />
            </Stack>
          </Stack>
        )}
      </Stack>
    </div>
  );
};

export default GasPvtInputField;
