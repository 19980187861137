import { Checkbox, Stack, useTheme } from "@fluentui/react";

import FossilyticsNumberField from "@/components/fields/FossilyticsNumberField";

import { GazAutoRtaInputGrid, WellboreModel } from "@/models/gaz/autoRta";

export interface WellboreModelProps {
  value: GazAutoRtaInputGrid;
  updateWellboreModelInputField: (v: number | undefined, key: keyof WellboreModel) => void;
  wellboreModelCheckBox: boolean | undefined;
  onCheckWellboreModel: (v: boolean | undefined) => void;
  isLoading: boolean;
}

const WellboreModelContainer = ({
  value,
  updateWellboreModelInputField,
  wellboreModelCheckBox,
  onCheckWellboreModel,
  isLoading,
}: WellboreModelProps) => {
  const theme = useTheme();

  return (
    <Stack tokens={{ childrenGap: 20 }} style={{ marginTop: 30, width: 300 }}>
      <Checkbox
        label="Use Wellbore Model"
        styles={{ text: { color: theme.palette.themePrimary }, root: { margin: "10px 0" } }}
        checked={wellboreModelCheckBox}
        onChange={(_, v) => onCheckWellboreModel(v)}
        disabled={isLoading}
      />

      {wellboreModelCheckBox && (
        <Stack horizontal tokens={{ childrenGap: 20 }}>
          <Stack grow={1} className="equal-size" tokens={{ childrenGap: 5 }}>
            <FossilyticsNumberField
              label="Tubing diameter"
              suffix="in"
              debounceDelay={1000}
              value={value.wellbore_model.tubing_diameter}
              onChange={(v) => updateWellboreModelInputField(v, "tubing_diameter")}
              disabled={isLoading}
            />
            <FossilyticsNumberField
              label="Tubing roughness"
              suffix="in"
              debounceDelay={1000}
              value={value.wellbore_model.tubing_roughness}
              onChange={(v) => updateWellboreModelInputField(v, "tubing_roughness")}
              disabled={isLoading}
            />
            <FossilyticsNumberField
              label="Tubing depth"
              suffix="ft"
              debounceDelay={1000}
              value={value.wellbore_model.tubing_depth}
              onChange={(v) => updateWellboreModelInputField(v, "tubing_depth")}
              disabled={isLoading}
            />
            <FossilyticsNumberField
              label="Wellhead temperature"
              suffix="°F"
              debounceDelay={1000}
              value={value.wellbore_model.wellhead_temperature}
              onChange={(v) => updateWellboreModelInputField(v, "wellhead_temperature")}
              disabled={isLoading}
            />
            <FossilyticsNumberField
              label="Well angle"
              suffix="°"
              debounceDelay={1000}
              value={value.wellbore_model.well_angle}
              onChange={(v) => updateWellboreModelInputField(v, "well_angle")}
              disabled={isLoading}
            />
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};

export default WellboreModelContainer;
