import { validateGazAutoRtaForecast, calculateGazAutoRtaForecast } from "@/constants/apiUrl";

import { createPoll } from "@/utils/apiFetcher";
import { AutoRtaForecastCalculation, GazAutoRtaForecast } from "../Forecast";
import { GazAutoRta } from "../State";

export const pollValidateForecastGazAutoRta = (payload: GazAutoRta, dataSetIds: string[]) => {
  return createPoll<GazAutoRtaForecast, GazAutoRta>({
    path: validateGazAutoRtaForecast,
    body: payload,
    queryStringParameters: {
      data_set_ids: dataSetIds,
    },
  });
};
export const pollCalculateForecastGazAutoRta = (payload: GazAutoRta, projectId: string) => {
  return createPoll<AutoRtaForecastCalculation, GazAutoRta>({
    path: calculateGazAutoRtaForecast,
    body: payload,
    queryStringParameters: {
      project_id: projectId,
    },
  });
};
