import { DialogType } from "@fluentui/react";

export type projectSettingsDialogContentType = {
  type: DialogType;
  title: string;
  description: string;
};

export const modalProps = {
  isBlocking: false,
  styles: { main: { maxWidth: 450 } },
};

export const newProjectDialogContentProps = {
  type: DialogType.largeHeader,
  title: "New Project",
  subText:
    "Please enter a name for the new project. You can optionally copy groups and settings from the currently selected project.",
};

export const deleteProjectDialogContentProps = {
  type: DialogType.largeHeader,
  title: "Delete Project",
  subText: "Warning: This operation cannot be undone!",
};

export const newSubgroupDialogContentProps = {
  type: DialogType.largeHeader,
  title: "New Subgroups",
  subText: "Please enter a name for the new subgroups.",
};


export const newGroupDialogContentProps = {
  type: DialogType.largeHeader,
  title: "New Groups",
  subText: "Please enter a name for the new groups.",
};

export const editProjectDialogContentProps = {
  type: DialogType.largeHeader,
  title: "Edit Project",
  subText: "You can update the project name below. Click save when you are done with this project.",
};
export const editGroupDialogContentProps = {
  type: DialogType.largeHeader,
  title: "Edit Group",
  subText: "You can update the group name below. Click save when you are done with this group.",
};

export const deleteGroupDialogContentProps = {
  type: DialogType.largeHeader,
  title: "Delete Group",
  subText: "Warning: This operation cannot be undone!",
};


export const projectSettingsDialogContentProps = {
  type: DialogType.largeHeader,
  title: "Project Settings",
  subText: "Edit project settings below. Click save when you are done.",
};