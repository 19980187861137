import React from "react";
import Alert from "@mui/material/Alert";

type ErrorAlertProps = {
  message?: string;
};

const ErrorAlert = ({ message }: ErrorAlertProps) => {
  if (!message) return <></>;
  return (
    <Alert severity={"error"}>
      <span>{message}</span>
    </Alert>
  );
};

export default ErrorAlert;
