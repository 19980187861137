import {
  FormationCompressibility,
  RockType,
  TankType,
  ZMethod,
  WaterViscosityMethod,
  GasViscosityMethod,
  FlowPressureType,
  SkinCorrelationType,
  ParamInputType,
  LayeringType,
} from "@/model";

import { ErrorValidationDetail } from "@/models/ErrorInputValidation";

import { IDropdownOption } from "@fluentui/react";

export type KoldunCsgQuery = {
  data_set_ids: string[];
  project_id: string;
  group_id: string;
};

export enum UncertainParamDistribution {
  FIXED = "fixed",
  UNIFORM = "uniform",
  TRIANGULAR = "triangular",
  LOG_NORMAL = "lognormal",
  NORMAL = "normal",
  KDEPENDENCE = "kdependence",
  LORENZ = "lorenz",
}

export enum MonteCarloInputApplication {
  MEASURE = "measure",
  LAYER = "layer",
}

export const SkinCorrelationOptions = [
  { key: SkinCorrelationType.Constant, text: "Constant Skin" },
  { key: SkinCorrelationType.Santos, text: "Perm Skin (Power Law)" },
] as IDropdownOption[];

export const FlowingPressureOptions = [
  { key: FlowPressureType.BH, text: "Bottomhole Pressure" },
  { key: FlowPressureType.TH, text: "Tubing Head Pressures" },
] as IDropdownOption[];

export type MultiRelPermType = {
  id: number;
  krg_max_csg: number;
  krw_max_csg: number;
  ng: number;
  nw: number;
  connate_gas_saturation: number;
  connate_water_saturation: number;
};

export type MultiShrinageBurgoyneType = {
  id: number;
  shrinkage_factor_dim: number;
  max_pim: number;
};

export type MultiShrinageYilmaznurType = {
  id: number;
  perm_modulus: number;
};

export type MultiShrinagePalmermansooriType = {
  id: number;
  poissons_ratio_dim: number;
  grain_compres_psi: number;
  shrinkage_factor_dim: number;
  lang_vol_strain: number;
  youngs_modulus: number;
  n_exponent: number;
};

export type ModuleKoldunMcsimResProp = {
  permeability_xaxis_md: number;
  permeability_yaxis_md: number;
  permeability_zaxis_md: number;
  well_position_xaxis_ft: number;
  well_position_yaxis_ft: number;
  well_position_zaxis_ft: number;
  res_width_xaxis_ft: number;
  res_length_yaxis_ft: number;
  res_height_zaxis_ft: number;
  horizontal_well_length_yaxis_ft: number;
};

export type ModuleKoldunMcsimOutput = {
  days: number[];
  rates_mmscf_d: number[][];
  cumes_mmscf: number[][];
  cases: ModuleKoldunMcsimCase[];
  indices?: [number, number, number];
  synthetic_dist?: number[];
  krg: number[];
  krw: number[];
  sw: number[];
};

export type ModuleKoldunMcsimCsgOptions = {
  skin_flag: SkinCorrelationType;
  num_days: number;
  tank_type: TankType;
  layer_number: number;
  layer_type: LayeringType;
  form_temp_of: number;
  gas_gravity: number;
  n2_perc: number;
  co2_perc: number;
  h2s_perc: number;
  z_method: ZMethod;
  form_compressibility_method: FormationCompressibility;
  rock_type: RockType;

  num_of_measures: number;
  //CSG parameters

  area_acres: number;
  krg_max_csg: number;
  krw_max_csg: number;
  ng: number;
  nw: number;
  connate_gas_saturation: number;
  connate_water_saturation: number;
  salinity_ppm: number;

  drainage_radius_ft?: number;
  wellbore_radius_ft: number;

  z_correlation?: string;

  num_simulations: number;
  ogip_mmscf: UncertainParamType;
  water_sat_perc: UncertainParamType;
  porosity_perc: UncertainParamType;
  permeability_md: UncertainParamType;
  net_pay_ft: UncertainParamType;
  initial_pressure_psia: UncertainParamType;
  langmuir_v: UncertainParamType;
  langmuir_p: UncertainParamType;
  coal_density: UncertainParamType;
  desorbtion_press: UncertainParamType;
  under_saturation_perc: UncertainParamType;
  gas_content_scf_ton: UncertainParamType;

  matrix_shrinkage: MatrixShrinkage;
  res_prop: ModuleKoldunMcsimResProp;

  water_viscosity_method: WaterViscosityMethod;
  gas_viscosity_method: GasViscosityMethod;
  formation_compressibility: number;
  type_flag: string;
  contract_rate_type_flag: string;
  // Days, Flowing BH pressures, Skins, contract_rate_mmscf_d
  multi_flow_inputs: [number, number, number][];

  multi_flow_temp_inputs: [number, number, number][];

  inputs: [number, number, number][];
  temp_inputs: [number, number, number][];
  flowing_press_flag: FlowPressureType;
  multi_skin_inputs: [number][];
  multi_santos_skin_inputs: [number, number][];
  skin_length: number;

  pvt: MultiPvtType[];
  rel_perm: MultiRelPermType[];

  well_press_grad: number;

  //Multi gas inputs
  multi_burgoyne: MultiShrinageBurgoyneType[];
  multi_yilmaznur: MultiShrinageYilmaznurType[];
  multi_palmermansoori: MultiShrinagePalmermansooriType[];

  horizontal_well_props: MultiHorizontalWellType[];

  uncertain_list: MultiUncertainParamType[];

  //forecast
  ddown_rate_ft_month: number;
  pump_depth_ft: number;
  min_pump_head_ft: number;
  param_input_mode: ParamInputType;
  well_water_grad: number;
  gas_contract_rate: number[];
};

export type ModuleKoldunMcsimCase = {
  ogip_mmscf: number;
  water_sat_perc: number;
  porosity_perc: number;
  permeability_md: number;
  net_pay_ft: number;
  initial_pressure_psia: number;
};

export type CsgOutPutDiagnostic = {
  Bg: number[];
  Bw: number[];
  Krg: number[];
  Krw: number[];
  Mug: number[];
  Pr: number[];
  Pwf: number[];
  Qg: number[];
  Qw: number[];
  Sw: number[];
  Uw: number[];
  days: number[];
  k_ki_ratio: number[];
  pim: number[];
  pseudo_Pr: number[];
  pseudo_Pwf: number[];
  qg: number[];
  qw: number[];
  z_factor: number[];
};

export type ModuleKoldunMcsimCsgOutput = {
  days: number[];
  rates_mmscf_d: number[][];
  cumes_mmscf: number[][];
  cases: ModuleKoldunMcsimCase[];
  qw: number[][];
  diagnostic: CsgOutPutDiagnostic;
};

export type MultiTotalLayer = {
  all_cases: [];
  days: number[];
  total_rates_mmscf_d: number[];
  total_rates_w_bbl: number[];
  cum_total_rates_mmscf: number[];
  cum_total_rates_w_bbl: number[];
  uncertainty_cases: null;
};

export type MultiLayerDiagnostics = {
  Bg: number[];
  Bw: number[];
  Krg: number[];
  Krw: number[];
  Pr: number[];
  Pwf: number[];
  Qg: number[];
  Qw: number[];
  Sw: number[];
  Ug: number[];
  Uw: number[];
  days: number[];
  k_ki_ratio: number[];
  p_res: number[];
  pim: number[];
  pseudo_BH_press: number[];
  pseudo_p_res: number[];
  qg: number[];
  qw: number[];
  zfactor: number[];
  layer_num: number;
};

export type MultiLayer = {
  CSG_Total_layered: MultiTotalLayer;
};

export type ForecastResponse = {
  all_cases: MultiLayer[];
  days: number[];
  uncertainty_cases: {
    p10: MultiLayer;
    p50: MultiLayer;
    p90: MultiLayer;
    pmean: MultiLayer;
  };
};

export type CSGMeasure = {
  measure_num: number;
  measure_layers: CsgMultiLayer[];
  numOfLayer?: number;
};

export type RelPermType = {
  krg_max_csg: number;
  ng: number;
  krw_max_csg: number;
  nw: number;
  connate_gas_saturation: number;
  connate_water_saturation: number;
};

export type MultiPvtType = {
  measure_num: number;
  id: number;
  co2_perc: number;
  depth_ft: number;
  form_temp_of: number;
  gas_gravity: number;
  h2s_perc: number;
  n2_perc: number;
  salinity_ppm: number;
  area_acres: number;
};

export type MultiUncertainParamType = {
  water_sat_perc: number;
  porosity_perc: number;
  permeability_md: number;
  net_pay_ft: number;
  initial_pressure_psia: number;
  langmuir_v: number;
  langmuir_p: number;
  coal_density: number;
  desorbtion_pressure: number;
  ogip_mmscf: number;
  under_saturation_perc: number;
  gas_content_scf_ton: number;
};

export interface UncertainParam {
  distribution: UncertainParamDistribution;
  application: MonteCarloInputApplication;
}

export interface UncertainParamFixed extends UncertainParam {
  distribution: UncertainParamDistribution.FIXED;
  value: number | null;
}

export interface UncertainParamTriangular extends UncertainParam {
  distribution: UncertainParamDistribution.TRIANGULAR;
  left: number | null;
  mode: number | null;
  right: number | null;
}

export interface UncertainParamUniform extends UncertainParam {
  distribution: UncertainParamDistribution.UNIFORM;
  low: number | null;
  high: number | null;
}

export interface UncertainParamLogNorm extends UncertainParam {
  distribution: UncertainParamDistribution.LOG_NORMAL;
  mean: number | null;
  sigma: number | null;
}

export interface UncertainParamNorm extends UncertainParam {
  distribution: UncertainParamDistribution.NORMAL;
  mean: number | null;
  std: number | null;
}

export interface UncertainParamKDependence extends UncertainParam {
  distribution: UncertainParamDistribution.KDEPENDENCE;
  min: number | null;
  max: number | null;
  perm_at_min_porosity: number | null;
}

export interface UncertainParamLorenz extends UncertainParam {
  distribution: UncertainParamDistribution.LORENZ;
  net_thickness: number | null;
  lorentz_factor: number | null;
}

export type UncertainParamType =
  | UncertainParamFixed
  | UncertainParamTriangular
  | UncertainParamUniform
  | UncertainParamLogNorm
  | UncertainParamNorm
  | UncertainParamKDependence
  | UncertainParamLorenz;

export type MultiHorizontalWellType = {
  permeability_xaxis_md: number;
  permeability_yaxis_md: number;
  permeability_zaxis_md: number;
  well_position_xaxis_ft: number;
  well_position_yaxis_ft: number;
  well_position_zaxis_ft: number;
  res_width_xaxis_ft: number;
  res_length_yaxis_ft: number;
  res_height_zaxis_ft: number;
  horizontal_well_length_yaxis_ft: number;
  wellbore_radius_ft: number;
};

export type MatrixShrinkage = {
  method: string;
  perm_modulus: number;
  shrinkage_factor_dim: number;
  cleat_compress: number;
  poissons_ratio_dim: number;
  axial_modulus_psi: number;
  grain_compres_psi: number;
  lang_vol_strain: number;
  n_exponent: number;
  youngs_modulus: number;
  max_pim: number;
};

export type CsgUncertainList = {
  water_sat_perc: UncertainParamType;
  porosity_perc: UncertainParamType;
  permeability_md: UncertainParamType;
  net_pay_ft: UncertainParamType;
  initial_pressure_psia: UncertainParamType;
  langmuir_v: UncertainParamType;
  langmuir_p: UncertainParamType;
  coal_density: UncertainParamType;
  desorbtion_press: UncertainParamType;
  ogip_mmscf: UncertainParamType;
  under_saturation_perc: UncertainParamType;
  gas_content_scf_ton: UncertainParamType;
};

export type CsgMultiLayer = {
  layer_num: number;
  rel_perm: RelPermType;
  pvt: MultiPvtType;
  uncertain_params: MultiUncertainParamType;
  uncertain_list: CsgUncertainList;
  skin: number;
  area_acres: number;
  drainage_radius_ft: number;
  Qw_at_desorbtion: number;
  horizontal_well_props: MultiHorizontalWellType[];
  z_table: number;
  pseudo_press_table: number;
  flowing_bh_press_psi: [];
  geomechanics: MatrixShrinkage;
};

export type SkinBase = {
  skin_flag: SkinCorrelationType;
  perm_array: number[];
  skin_array: number[];
};

export type CSGLayersBaseInputs = {
  tank_type: string | null;
  wellbore_radius_ft: number | null;
  z_correlation: string | null;
  water_viscosity_method: string | null;
  gas_viscosity_method: string | null;
  formation_compressibility: number | null;
  num_days: number | null;
  day_arr: number[] | null;
  ddown_rate_ft_month: number | null;
  pump_depth_ft: number | null;
  min_pump_head_ft: number | null;
  flowing_press_flag: string | null;
  param_input_mode: string | null;
  contract_rate_type_flag: string | null;
  inputs: [number, number, number][];
  flowing_press: number[] | null;
  well_press_grad: number | null;
  well_water_grad: number | null;
  gas_contract_rate: (number | null)[] | null;
  water_contract_rate: (number | null)[] | null;
  skin_base: SkinBase;
};

export type Output = {
  num_simulations: number;
  num_of_measures: number;
  measure_layers: number[];
  measures: CSGMeasure[];
  base_inputs: CSGLayersBaseInputs;
  layers: CsgMultiLayer[];
  // only for fe flag
  isFromInitialize?: boolean;
};

export interface MonteCarloInputProps {
  units: string;
  output: Output;
  updateUncertainList: (layer: number, key: string, value: any) => void;
  measureNum: number;
  uncertainKey: UncertainKeys;
  errorInputValidation?: ErrorValidationDetail[];
  keyField?: string;
}

export type UncertainKeys =
  | "water_sat_perc"
  | "porosity_perc"
  | "permeability_md"
  | "net_pay_ft"
  | "initial_pressure_psia"
  | "langmuir_v"
  | "langmuir_p"
  | "coal_density"
  | "desorbtion_press"
  | "ogip_mmscf"
  | "under_saturation_perc"
  | "gas_content_scf_ton";

export type BaseInputsKeys = "num_days" | "well_water_grad" | "pump_depth_ft" | "min_pump_head_ft" | "ddown_rate_ft_month";

export type MatrixShrinkageKeys =
  | "perm_modulus"
  | "shrinkage_factor_dim"
  | "cleat_compress"
  | "poissons_ratio_dim"
  | "axial_modulus_psi"
  | "grain_compres_psi"
  | "lang_vol_strain"
  | "n_exponent"
  | "youngs_modulus"
  | "max_pim";
