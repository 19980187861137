import { useMemo } from "react";

import { shallow } from "zustand/shallow";
import { useAppStore } from "@/features/app";
import useThemeStyling from "@/utils/useThemeStyling";

import { DataSet, isDataSet, isDataSets, ModuleId } from "@/model";

import {
  moduleMapping,
  moduleGridSortedSectionSingle,
  moduleGridSortedSectionMulti,
  moduleDictionary,
  ModuleSection,
  moduleMappingMulti,
} from "../constants";
import routeUrlMap from "@/constants/routeUrl";

export type ModuleGridGroup = {
  section: ModuleSection;
  items: ModuleItem[];
};

type ModuleItem = {
  key: ModuleId;
  label: string;
  route: string;
  disabled: boolean;
};

const mapModuleItems = (itemList: ModuleId[], selectedDataSets?: DataSet | DataSet[]): ModuleItem[] => {
  return itemList.map((item: ModuleId) => {
    let disabled = true;

    if (isDataSet(selectedDataSets)) {
      disabled = selectedDataSets.modules_available.indexOf(item) < 0;
    } else if (isDataSets(selectedDataSets)) {
      disabled = selectedDataSets.some((d) => d.modules_available.indexOf(item) < 0);
    }

    return {
      key: item,
      label: moduleDictionary[item],
      route: routeUrlMap[item],
      // TODO REMOVE THIS BEFORE RELEASE
      disabled: item === ModuleId.RULON ? false : disabled,
    };
  });
};

const useModuleList = () => {
  const { selectedDataSets, setCurrentModule, setCsvData } = useAppStore(
    (state) => ({
      selectedDataSets: state.selectedDataSets,
      setCurrentModule: state.setCurrentModule,
      setCsvData: state.setCsvData,
    }),
    shallow
  );

  const { theme } = useThemeStyling();

  const hasDataSet = !!selectedDataSets;
  const hasSingle = hasDataSet && !Array.isArray(selectedDataSets);
  const hasMulti = hasDataSet && Array.isArray(selectedDataSets) && selectedDataSets.length !== 0;

  const modules = useMemo(() => {
    const singleModuleList = moduleGridSortedSectionSingle.map((moduleRow: ModuleSection[]) => {
      return moduleRow.map((module: ModuleSection) => {
        return {
          section: module,
          items: mapModuleItems(moduleMapping[module], selectedDataSets),
        };
      });
    });

    const multiModuleList = moduleGridSortedSectionMulti.map((moduleRow: ModuleSection[]) => {
      return moduleRow.map((module: ModuleSection) => {
        return {
          section: module,
          items: mapModuleItems(moduleMappingMulti[module], selectedDataSets),
        };
      });
    });

    if (hasSingle) {
      return singleModuleList;
    } else if (hasMulti) {
      return multiModuleList;
    }
    return [];
  }, [hasMulti, hasSingle, selectedDataSets]);

  const onClickModule = (moduleId: ModuleId) => {
    setCurrentModule(moduleId);
    setCsvData();
  };

  return {
    theme,
    hasDataSet,
    modules,
    onClickModule,
    hasMulti,
  };
};

export default useModuleList;
