import { ReactElement, useState } from "react";
import { useQuery } from "@tanstack/react-query";

import { ProjectDataSet } from "@/util";
import { ModuleSpadDeclineAnalysis, getSubModulePath } from "@/features/modules/spad/decline";
import { DataSetDropdown } from "@/components/DataSetDropdown";
import { ModuleId } from "@/model";
import { getSavedAnalysis } from "@/models/spad";

interface UseDeclineCompareOutput {
  compareAnalysis?: ModuleSpadDeclineAnalysis;
  compareName?: string;
  compareDropdown: ReactElement;
}

type UseCompareProps = {
  currentModule?: ModuleId;
};

const useCompareSpadAnalysis = ({ currentModule }: UseCompareProps): UseDeclineCompareOutput => {
  const [compareName, setCompareName] = useState<string>();
  const [projectDataSet, setProjectDataSet] = useState<ProjectDataSet>();

  const compareDropdown = <DataSetDropdown setCompareName={setCompareName} setCompareDataSet={setProjectDataSet} />;

  const { isError, data } = useQuery({
    queryKey: ["compare-analysis-spad", compareName, projectDataSet],
    queryFn: async () => {
      return getSavedAnalysis({
        module: getSubModulePath(currentModule!),
        queryStringParameters: {
          project_id: projectDataSet?.projectId,
          data_set_ids: projectDataSet?.dataSetIds,
        },
      });
    },
    refetchOnWindowFocus: false,
    enabled: !!(projectDataSet && currentModule),
  });

  return {
    compareAnalysis: !isError && data?.data?.exists ? data.data.analysis : undefined,
    compareName,
    compareDropdown,
  };
};

export default useCompareSpadAnalysis;
