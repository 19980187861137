import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTheme } from "@mui/material/styles";
import { Row } from "@silevis/reactgrid";
import _ from "lodash";

import { ModuleIdentity } from "@/models/Generic";
import {
  LayerItem,
  TahkCsgLangmuirIsothermEnum,
  TahkCsgStateResponse,
  TahkCsgInputState,
  TahkCsgAnalysisCalculateResponse,
  TahkAnalysisChartData,
  pollCalculateTahkCsgAnalysis,
  pollValidateTahkCsgAnalysis,
} from "@/models/tahk";
import { ErrorValidationDetail } from "@/models/ErrorInputValidation";

import dictionary from "@/constants/dictionary";
import { FossilyticsChartSeries } from "@/components/FossilyticsChart";
import { camelToSnakeCase, formatToScientific } from "@/utils/general";

import { dataTableNotation, getDataTableHeader } from "../../utils";
import { cumulativeSeriesKey, dataSeriesKey, dataTableHeaderStyles, seriesKey } from "../../constants/grid";
import { CsvData } from "@/features/app/app.types";
import { transformRowToString } from "@/utils/csvProcessing";

type UseTahkCsgAnalysisProps = {
  isLoading: boolean;
  tabIndex: number;
  analysisIdentity?: ModuleIdentity;
  setTahkCsgState: React.Dispatch<React.SetStateAction<TahkCsgStateResponse | null | undefined>>;
  tahkCsgState?: TahkCsgStateResponse | null;
  setApiError: ({ message }: { message: string }) => void;
  setCsvData: (csvData?: CsvData[]) => void;
};

const safeTahkDictionary: {
  [key: string]: string;
} = dictionary.tahk;

const useTahkCsgAnalysis = ({
  setCsvData,
  setApiError,
  isLoading,
  setTahkCsgState,
  tahkCsgState,
  analysisIdentity,
  tabIndex,
}: UseTahkCsgAnalysisProps) => {
  const { palette } = useTheme();

  const [selectedLayer, setSelectedLayer] = useState(0);
  const [selectedDataTableLayer, setSelectedDataTableLayer] = useState(0);

  const [errorInputValidation, setErrorInputValidation] = useState<ErrorValidationDetail[]>([]);

  const [calculation, setCalculation] = useState<TahkCsgAnalysisCalculateResponse | null>(null);
  const [loadingState, setLoadingState] = useState(false);

  const lastCalculationReq = useRef<any>(null);

  const analysis = useMemo(() => {
    if (!tahkCsgState?.analysis) return null;
    return tahkCsgState.analysis;
  }, [tahkCsgState?.analysis]);

  const dataview = useMemo(() => {
    if (!tahkCsgState?.dataview) return null;
    return tahkCsgState.dataview;
  }, [tahkCsgState?.dataview]);

  const layers = useMemo(() => {
    if (!analysis) return [];
    return analysis.layers;
  }, [analysis]);

  const layerOption = useMemo(() => {
    return Array.from(Array(layers?.length).keys()).map((_, index) => {
      return {
        key: index,
        text: `${dictionary.tahk.layer} ${index + 1}`,
      };
    });
  }, [layers?.length]);

  const dataTableLayerOption = useMemo(() => {
    return Array.from(Array(calculation?.data_table?.length).keys()).map((_, index) => {
      return {
        key: index,
        text: `${dictionary.tahk.layer} ${index + 1}`,
      };
    });
  }, [calculation?.data_table?.length]);

  const currentLayer = useMemo(() => {
    if (!tahkCsgState || !analysis) return null;
    // check if volumetric or ogip
    try {
      const layerInputType = tahkCsgState.inputs?.layers?.[selectedLayer]?.selected_langmuir_inputs;

      if (layerInputType === TahkCsgLangmuirIsothermEnum.Volumetric) {
        return {
          labelOrder: [
            "initialPressure",
            "langmuirPressure",
            "langmuirVolume",
            "desorptionPressure",
            "porosity",
            "area",
            "permeabilityXAxis",
            "permeabilityYAxis",
            "ng",
            "nw",
            "skin",
            "formationCompressibility",
          ],
          data: analysis.layers[selectedLayer] as {
            [key: string]: LayerItem;
          },
        };
      }
      return {
        labelOrder: [
          "initialPressure",
          "langmuirPressure",
          "ogip",
          "undersaturated",
          "initialGasContent",
          "porosity",
          "permeabilityXAxis",
          "permeabilityYAxis",
          "ng",
          "nw",
          "skin",
          "formationCompressibility",
          "shrinkageFactor",
        ],
        data: analysis.layers[selectedLayer] as {
          [key: string]: LayerItem;
        },
      };
    } catch (error) {
      return null;
    }
  }, [analysis, selectedLayer, tahkCsgState]);

  const onCalculateAnalysis = useCallback(
    async (input: TahkCsgInputState) => {
      if (!analysisIdentity || !dataview) return;
      setLoadingState(true);
      setErrorInputValidation([]);
      try {
        const payload = {
          data_options: {
            analysis_identity: {
              ...analysisIdentity,
            },
            dataview,
          },
          options: input,
        };
        lastCalculationReq.current = JSON.parse(JSON.stringify(payload));
        const calculation = await pollCalculateTahkCsgAnalysis(payload);
        if (calculation.data) setCalculation(calculation.data);
      } catch (error: any) {
        if (error && error.code === 422 && error.detail?.length !== 0) {
          setErrorInputValidation(error.detail as ErrorValidationDetail[]);
          console.log(error);
        } else {
          setApiError({
            message: error.detail?.[0].msg ?? "",
          });
        }
      } finally {
        setLoadingState(false);
      }
    },
    [analysisIdentity, dataview, setApiError]
  );

  const onValidateAnalysis = useCallback(async () => {
    if (dataview && analysisIdentity && analysis && tahkCsgState?.inputs) {
      try {
        setErrorInputValidation([]);
        setLoadingState(true);
        const validationResponse = await pollValidateTahkCsgAnalysis({
          data_options: {
            analysis_identity: analysisIdentity,
            dataview: dataview,
          },
          options: {
            analysis_options: analysis,
            input_options: tahkCsgState?.inputs,
          },
        });

        if (validationResponse.data) {
          const inputEqual = _.isEqual(tahkCsgState.inputs, validationResponse.data.input_options);
          const analysisEqual = _.isEqual(tahkCsgState.analysis, validationResponse.data.analysis_options);
          if (!analysisEqual || !inputEqual) {
            setTahkCsgState((prev) => {
              if (!prev) return prev;
              const newState = { ...prev };
              if (!analysisEqual) {
                newState.analysis = validationResponse?.data?.analysis_options ?? prev?.analysis;
              }
              if (!inputEqual) {
                newState.inputs = validationResponse?.data?.input_options ?? prev?.inputs;
              }
              return newState;
            });
          }
          onCalculateAnalysis(validationResponse.data.input_options);
        }
      } catch (error: any) {
        setLoadingState(false);
        if (error && error.code === 422 && error.detail?.length !== 0) {
          setErrorInputValidation(error.detail as ErrorValidationDetail[]);
        } else {
          setApiError({
            message: error.detail?.[0].msg ?? "",
          });
        }
      }
    }
  }, [analysis, analysisIdentity, dataview, onCalculateAnalysis, setApiError, setTahkCsgState, tahkCsgState?.analysis, tahkCsgState?.inputs]);

  const onChangeAnalysisInput = useCallback(
    (val: any, key: string) => {
      setTahkCsgState((prev) => {
        if (!prev) return prev;

        return {
          ...prev,
          analysis: {
            ...prev.analysis,
            [key]: val,
          },
        };
      });
    },
    [setTahkCsgState]
  );

  const onChangeAnalysisInputLayer = useCallback(
    (val: any, key: string, option: keyof LayerItem) => {
      setTahkCsgState((prev) => {
        if (!prev) return prev;
        const newLayers = [...prev.analysis.layers];

        const currLayer: { [key: string]: LayerItem } = newLayers[selectedLayer];

        currLayer[key] = {
          ...currLayer[key],
          [option]: val,
        };

        return {
          ...prev,
          analysis: {
            ...prev.analysis,
            layers: newLayers,
          },
        };
      });
    },
    [selectedLayer, setTahkCsgState]
  );

  const chartXAxes = useMemo(() => {
    return [{ name: dictionary.dataView.date, type: "time", color: palette.grey[900] }];
  }, [palette.grey]);

  const chartYAxes = useMemo(() => {
    return [
      {
        name: `${dictionary.tahk.pressure} ${dictionary.tahkUnits.pressure}`,
        type: "value",
        color: palette.grey[900],
        min: 0,
        position: "left",
        offset: 75,
        nameGap: 30,
      },
      {
        name: `${dictionary.tahk.gasRate} ${dictionary.tahkUnits.gasRate}`,
        type: "value",
        color: palette.error.main,
        min: 0,
        position: "left",
        offset: 0,
        nameGap: 30,
      },
      {
        name: `${dictionary.tahk.waterRate} ${dictionary.tahkUnits.waterRate}`,
        type: "value",
        color: palette.info.main,
        min: 0,
        position: "right",
        offset: 5,
        nameGap: 45,
      },
    ];
  }, [palette.error.main, palette.grey, palette.info.main]);

  const getSeriesColor = useCallback(
    (key: string) => {
      if (key.toLowerCase().includes("gas")) return palette.error.main;
      if (key.toLowerCase().includes("water")) return palette.info.main;

      return palette.common.black;
    },
    [palette]
  );

  const getYAxisIndex = (key: string) => {
    if (key.toLowerCase().includes("gas")) return 1;
    if (key.toLowerCase().includes("water")) return 2;
    return 0;
  };

  const chartSeries = useMemo(() => {
    if (!calculation) return [];
    const curveModel: FossilyticsChartSeries[] = [];
    seriesKey.forEach((key) => {
      if (calculation.tahk_analysis_curve_model[camelToSnakeCase(key) as keyof TahkAnalysisChartData]) {
        curveModel.push({
          name: `${dictionary.tahk.model} ${safeTahkDictionary[key]}`,
          type: "line",
          color: getSeriesColor(key),
          data: calculation.tahk_analysis_curve_model
            ? calculation.tahk_analysis_curve_model.dates.map((d, i) => [
                d,
                calculation.tahk_analysis_curve_model?.[camelToSnakeCase(key) as keyof TahkAnalysisChartData][i],
              ])
            : [],
          yAxisIndex: getYAxisIndex(key),
          hideSymbol: true,
          lineWidth: 2,
        });
      }
    });
    const curveData: FossilyticsChartSeries[] = [];
    dataSeriesKey.forEach((key) => {
      if (calculation.tahk_analysis_curve_data[camelToSnakeCase(key) as keyof TahkAnalysisChartData]) {
        curveModel.push({
          name: `${dictionary.tahk.data} ${safeTahkDictionary[key]}`,
          type: "line",
          color: getSeriesColor(key),
          data: calculation.tahk_analysis_curve_data
            ? calculation.tahk_analysis_curve_data.dates.map((d, i) => [
                d,
                calculation.tahk_analysis_curve_data?.[camelToSnakeCase(key) as keyof TahkAnalysisChartData][i],
              ])
            : [],
          yAxisIndex: getYAxisIndex(key),
        });
      }
    });

    return [...curveModel, ...curveData];
  }, [calculation, getSeriesColor]);

  const dataTableRows: Row<any>[] = useMemo(() => {
    if (!calculation) return [];
    return [
      getDataTableHeader(dictionary.tahkDataTable),
      dataTableNotation(),
      ...calculation.data_table[selectedDataTableLayer].cumulative_gas_production.map((_, index) => {
        const safeParam = calculation.data_table[selectedDataTableLayer] as { [key: string]: any };
        return {
          rowId: index,
          cells: Object.keys(dictionary.tahkDataTable).map((key) => {
            const val = safeParam?.[camelToSnakeCase(key)][index] ?? "-";
            return {
              type: "text",
              text: formatToScientific(val),

              style: { display: "flex", justifyContent: "center", zIndex: 0 },
            };
          }),
        };
      }),
    ];
  }, [calculation, selectedDataTableLayer]);

  const parameterTableRows: Row<any>[] = useMemo(() => {
    if (!calculation) return [];
    return [
      {
        rowId: "header",
        cells: [
          {
            type: "header",
            text: dictionary.tahk.parameters,
            style: {
              background: "#fff",
              display: "flex",
              justifyContent: "flex-start",
              fontWeight: "bold",
              zIndex: 1,
            },
          },
          ...Array.from(Array(calculation?.table_of_final_parameters?.length ?? 0).keys()).map((_, index) => {
            return {
              type: "header",
              text: `${dictionary.tahk.layer} ${index + 1}`,
              style: dataTableHeaderStyles,
            };
          }),
        ],
      },
      ...Object.keys(dictionary.tahkParameterTable).reduce((array: any[], param: any) => {
        const calTable: { [key: string]: any } = calculation.table_of_final_parameters?.[0];

        if (calTable[camelToSnakeCase(param)]) {
          array.push({
            rowId: param,
            editable: false,
            cells: [
              {
                type: "custom",
                text: dictionary.tahkParameterTable[param].text,
                sub: dictionary.tahkParameterTable[param].sub,
                style: { display: "flex", justifyContent: "flex-start" },
                editable: false,
              },
              ...Array.from(Array(calculation?.table_of_final_parameters?.length ?? 0).keys()).map((_, index) => {
                const calTable: { [key: string]: any } = calculation.table_of_final_parameters?.[index];
                const val = calTable?.[camelToSnakeCase(param)] ?? "-";
                return {
                  type: "custom",
                  text: formatToScientific(val),
                  style: { display: "flex", justifyContent: "center" },
                  editable: false,
                };
              }),
            ],
          });
        }

        return array;
      }, []),
    ];
  }, [calculation]);

  const parameterTableColumn = useMemo(() => {
    if (!calculation) return [];
    return [
      {
        columnId: "parameterKey",
        width: 180,
        justifyContent: "flex-start",
      },
      ...Array.from(Array(calculation?.table_of_final_parameters?.length ?? 0).keys()).map((_, index) => {
        return {
          columnId: index,
          width: 100,
          justifyContent: "center",
        };
      }),
    ];
  }, [calculation]);

  const cumulativeYAxes = useMemo(() => {
    return [
      {
        name: `${dictionary.tahk.gasRate} ${dictionary.tahkUnits.gasRate}`,
        type: "value",
        color: palette.error.main,
        min: 0,
        position: "left",
        offset: 0,
        nameGap: 30,
      },
      {
        name: `${dictionary.tahk.waterRate} ${dictionary.tahkUnits.waterRate}`,
        type: "value",
        color: palette.info.main,
        min: 0,
        position: "right",
        offset: 5,
        nameGap: 45,
      },
    ];
  }, [palette.error.main, palette.info.main]);

  const cumulativeChartSeries = useMemo(() => {
    if (!calculation) return [];
    const curveModel: FossilyticsChartSeries[] = [];
    cumulativeSeriesKey.forEach((key) => {
      if (calculation.tahk_analysis_curve_model[camelToSnakeCase(key) as keyof TahkAnalysisChartData]) {
        curveModel.push({
          name: `${dictionary.tahk.model} ${safeTahkDictionary[key]}`,
          type: "line",
          color: getSeriesColor(key),
          data: calculation.tahk_analysis_curve_model
            ? calculation.tahk_analysis_curve_model.dates.map((d, i) => [
                d,
                calculation.tahk_analysis_curve_model?.[camelToSnakeCase(key) as keyof TahkAnalysisChartData][i] ?? 0,
              ])
            : [],
          yAxisIndex: getYAxisIndex(key) - 1,
          hideSymbol: true,
          lineWidth: 2,
        });
      }
    });
    const curveData: FossilyticsChartSeries[] = [];
    cumulativeSeriesKey.forEach((key) => {
      if (calculation.tahk_analysis_curve_data[camelToSnakeCase(key) as keyof TahkAnalysisChartData]) {
        curveModel.push({
          name: `${dictionary.tahk.data} ${safeTahkDictionary[key]}`,
          type: "line",
          color: getSeriesColor(key),
          data: calculation.tahk_analysis_curve_data
            ? calculation.tahk_analysis_curve_data.dates.map((d, i) => [
                d,
                calculation.tahk_analysis_curve_data?.[camelToSnakeCase(key) as keyof TahkAnalysisChartData][i] ?? 0,
              ])
            : [],
          yAxisIndex: getYAxisIndex(key) - 1,
        });
      }
    });

    return [...curveModel, ...curveData];
  }, [calculation, getSeriesColor]);

  useEffect(() => {
    const payload = {
      data_options: {
        analysis_identity: analysisIdentity,
        dataview,
      },
      options: tahkCsgState?.inputs,
    };
    if (tabIndex === 2 && !isLoading && tahkCsgState?.inputs && !_.isEqual(payload, lastCalculationReq.current))
      onCalculateAnalysis(tahkCsgState?.inputs);
  }, [analysisIdentity, calculation, dataview, isLoading, onCalculateAnalysis, tabIndex, tahkCsgState?.inputs]);

  const loadingStateAll = isLoading || loadingState;
  const transformDataTableToCsv = useCallback(() => {
    if (!analysisIdentity) return;
    const { data_set_ids } = analysisIdentity;

    const tableCsv = {
      fileName: `${data_set_ids.join(", ")} Analysis Tahk Table.csv`,
      data: transformRowToString(dataTableRows),
      isTableCsv: true,
    };
    setCsvData([tableCsv]);
  }, [analysisIdentity, dataTableRows, setCsvData]);

  useEffect(() => {
    if (analysisIdentity && !loadingStateAll && dataTableRows.length > 0) {
      transformDataTableToCsv();
    }
  }, [analysisIdentity, dataTableRows.length, loadingStateAll, transformDataTableToCsv]);

  return {
    layerOption,
    loadingState: loadingStateAll,
    selectedLayer,
    setSelectedLayer,
    currentLayer,
    onChangeAnalysisInput,
    analysis,
    onChangeAnalysisInputLayer,
    onValidateAnalysis,
    errorInputValidation,
    dataTableRows,
    chartXAxes,
    chartYAxes,
    chartSeries,
    calculation,
    parameterTableColumn,
    parameterTableRows,
    dataTableLayerOption,
    selectedDataTableLayer,
    setSelectedDataTableLayer,
    cumulativeYAxes,
    cumulativeChartSeries,
  };
};

export default useTahkCsgAnalysis;
