import React, { useCallback, useMemo } from "react";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { cloneDeep } from "lodash";

import MultiLayerTable, { MultiLayerProps } from "@/components/MultiLayerTable";
import { Output, CSGMeasure, MultiPvtType } from "@/models/koldun";
import { HelpLinkButton, THEORETICAL_HELP_LINKS } from "@/features/help-link";
import { ErrorValidationDetail } from "@/models/ErrorInputValidation";
import { renderCell } from "@/utils/cellRendering";

const measurePvtColumns = [
  {
    field: "measure_num",
    headerName: "Measure",
    headerUnits: "",
    width: 90,
    type: "number",
    renderCell: (params: GridRenderCellParams<CSGMeasure>) => renderCell({ row: { value: params.row.measure_num } }),
  },
  {
    field: "layer",
    headerName: "Number of layers",
    headerUnits: "",
    width: 125,
    type: "number",
    renderCell: (params: GridRenderCellParams<CSGMeasure>) => renderCell({ row: { value: params.row.numOfLayer } }),
  },
  {
    field: "area_acres",
    renderHeader: () => <HelpLinkButton helpLink={THEORETICAL_HELP_LINKS.csgWellSpacingAndCompletions}>Area</HelpLinkButton>,
    headerUnits: "(acres)",
    width: 100,
    type: "number",
    renderCell: (params: GridRenderCellParams<CSGMeasure>) => renderCell({ row: { value: params.row.measure_layers[0]?.pvt?.area_acres } }),
  },
  {
    field: "gas_gravity",
    headerName: "Gas gravity",
    headerUnits: "(dim)",
    width: 100,
    type: "number",
    renderCell: (params: GridRenderCellParams<CSGMeasure>) => renderCell({ row: { value: params.row.measure_layers[0]?.pvt?.gas_gravity } }),
  },
  {
    field: "n2_perc",
    headerName: "N₂",
    headerUnits: "(%)",
    width: 80,
    type: "number",
    renderCell: (params: GridRenderCellParams<CSGMeasure>) => renderCell({ row: { value: params.row.measure_layers[0]?.pvt?.n2_perc } }),
  },
  {
    field: "co2_perc",
    headerName: "CO₂",
    headerUnits: "(%)",
    width: 80,
    type: "number",
    renderCell: (params: GridRenderCellParams<CSGMeasure>) => renderCell({ row: { value: params.row.measure_layers[0]?.pvt?.co2_perc } }),
  },
  {
    field: "h2s_perc",
    headerName: "H₂S",
    headerUnits: "(%)",
    width: 80,
    type: "number",
    renderCell: (params: GridRenderCellParams<CSGMeasure>) => renderCell({ row: { value: params.row.measure_layers[0]?.pvt?.h2s_perc } }),
  },
  {
    field: "form_temp_of",
    headerName: "Formation temperature",
    headerUnits: "(°F)",
    width: 180,
    type: "number",
    renderCell: (params: GridRenderCellParams<CSGMeasure>) => renderCell({ row: { value: params.row.measure_layers[0]?.pvt?.form_temp_of } }),
  },
  {
    field: "salinity_ppm",
    headerName: "Water salinity",
    headerUnits: "(PPM)",
    width: 120,
    type: "number",
    renderCell: (params: GridRenderCellParams<CSGMeasure>) => renderCell({ row: { value: params.row.measure_layers[0]?.pvt?.salinity_ppm } }),
  },
  {
    field: "depth_ft",
    headerName: "Measure depth",
    headerUnits: "(ft)",
    width: 120,
    type: "number",
    renderCell: (params: GridRenderCellParams<CSGMeasure>) => renderCell({ row: { value: params.row.measure_layers[0]?.pvt?.depth_ft } }),
  },
] as MultiLayerProps[];

interface MultiLayerPvtInputsProps {
  value: Output;
  onChange: React.Dispatch<React.SetStateAction<Output | null>>;
  errorInputValidation?: ErrorValidationDetail[];
}

const MeasurePVTInputs = ({ value, onChange, errorInputValidation }: MultiLayerPvtInputsProps) => {
  const handleChanges = useCallback(
    (newValue: any) => {
      onChange((prev) => {
        if (!prev) return prev;
        const prevInputs: Output = cloneDeep({ ...prev });
        if ("layer" in newValue) {
          prevInputs.measure_layers[newValue.id] = newValue.layer;
          return { ...prevInputs };
        }

        const key = Object.keys(newValue).find((k) => k !== "id" && k !== "measure_num" && k !== "measure_layers" && k !== "numOfLayer");
        const value = newValue[key as string];

        if (key && Object(prevInputs.measures[newValue.measure_num - 1].measure_layers[0]?.pvt)) {
          // weird aws amplify deleting object key for undefined but somehow accept null
          prevInputs.measures[newValue.measure_num - 1].measure_layers[0].pvt[key as keyof MultiPvtType] = !value ? null : value;
        }

        return { ...prevInputs };
      });
    },
    [onChange]
  );

  const modelWithIds = value.measures.map((item, index) => ({
    id: index,
    ...item,
    numOfLayer: value.measure_layers[index],
  }));

  const measureLayerError = useMemo(() => {
    if (errorInputValidation && errorInputValidation.length > 0) {
      const pvtErrorIndex = errorInputValidation.findIndex((error) => error.loc.includes("pvt"));
      if (pvtErrorIndex > -1) return errorInputValidation?.[pvtErrorIndex].msg;
      const measureLayerError = errorInputValidation.findIndex((error) => error.loc.includes("measure_layers"));
      if (measureLayerError > -1) return errorInputValidation?.[measureLayerError].msg;
      return null;
    }
    return null;
  }, [errorInputValidation]);

  return (
    <>
      <div style={{ position: "relative", maxWidth: 1075, height: 300 }}>
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
          }}
        >
          {measureLayerError && <span style={{ color: "red" }}>{measureLayerError}</span>}
          <MultiLayerTable columns={measurePvtColumns} rows={modelWithIds} handleChanges={handleChanges} />
        </div>
      </div>
    </>
  );
};
export default MeasurePVTInputs;
