import { useCallback, useState } from "react";
import { Pivot, PivotItem } from "@fluentui/react";

import MonteCarloInputs from "./MonteCarloInputs";
import NetPayMcs from "./NetPayMcs";
import PLDistribution from "./PLDistribution";
import SwDistribution from "./SwDistribution";
import PiDistribution from "./PiDistribution";
import OGIPDistribution from "./OGIPDistribution";
import UnderSatDistribution from "./UnderSatDistribution";
import GCIDistribution from "./GCIDistribution";
import VLDistribution from "./VLDistribution";
import DesorptionPressureDist from "./DesorptionPressureDist";
import CoalDensityDist from "./CoalDensityDist";
import { ParamInputType } from "@/model";
import { Output, UncertainKeys, UncertainParamType } from "@/models/koldun";
import PorosityDistribution from "./PorosityDistribution";

import { ErrorValidationDetail } from "@/models/ErrorInputValidation";

interface MonteCarloInputCardProps {
  value: Output;
  onChange: React.Dispatch<React.SetStateAction<Output | null>>;
  errorInputValidation?: ErrorValidationDetail[];
}

const MonteCarloInputCard = ({ value, onChange, errorInputValidation }: MonteCarloInputCardProps) => {
  const [measureNum, setMeasureNum] = useState<number>(0);
  const UpdateData = useCallback(
    (v: UncertainParamType, key: UncertainKeys) => {
      onChange((prev) => {
        if (!prev) return prev;
        const prevInputs: Output = { ...prev };

        prevInputs.measures[measureNum].measure_layers[0].uncertain_list[key] = v;

        return { ...prevInputs };
      });
    },
    [measureNum, onChange]
  );

  const UpdateInputMode = useCallback(
    (v: PivotItem | undefined) => {
      if (!v) return;
      onChange((prev) => {
        if (!prev) return prev;
        const prevInputs: Output = { ...prev };

        prevInputs.base_inputs.param_input_mode = v.props.itemKey as ParamInputType;

        return { ...prevInputs };
      });
    },
    [onChange]
  );

  return (
    <Pivot
      styles={{
        link: {
          padding: 0,
          marginRight: 20,
        },
      }}
      defaultSelectedKey={value.base_inputs.param_input_mode ?? undefined}
      onLinkClick={UpdateInputMode}
    >
      <PivotItem
        headerText="Volumetric Inputs"
        headerButtonProps={{
          "data-order": 1,
        }}
        itemKey={ParamInputType.VL}
      >
        <MonteCarloInputs
          key="Permeability"
          name="Permeability"
          units="mD"
          onChange={(v) => UpdateData(v, "permeability_md")}
          output={value}
          setOutput={onChange}
          measureNum={measureNum}
          setMeasureNum={setMeasureNum}
          errorInputValidation={errorInputValidation}
          // measure layers will always be 0
          keyField={`measures.${measureNum}.measure_layers.0.uncertain_list.permeability_md`}
        />
        <NetPayMcs
          key="Net pay"
          name="Net pay"
          units="ft"
          output={value}
          setOutput={onChange}
          onChange={(v) => UpdateData(v, "net_pay_ft")}
          measureNum={measureNum}
          errorInputValidation={errorInputValidation}
          keyField={`measures.${measureNum}.measure_layers.0.uncertain_list.net_pay_ft`}
        />
        <PLDistribution
          key="PL"
          name="PL"
          units="psia"
          output={value}
          setOutput={onChange}
          onChange={(v) => UpdateData(v, "langmuir_p")}
          measureNum={measureNum}
          errorInputValidation={errorInputValidation}
          keyField={`measures.${measureNum}.measure_layers.0.uncertain_list.langmuir_p`}
        />
        <VLDistribution
          key="VL"
          name="VL"
          units="scf/ton"
          output={value}
          setOutput={onChange}
          onChange={(v) => UpdateData(v, "langmuir_v")}
          measureNum={measureNum}
          errorInputValidation={errorInputValidation}
          keyField={`measures.${measureNum}.measure_layers.0.uncertain_list.langmuir_v`}
        />
        <DesorptionPressureDist
          key="Desorption Pressure"
          name="Desorption Pressure"
          units="psia"
          output={value}
          setOutput={onChange}
          onChange={(v) => UpdateData(v, "desorbtion_press")}
          measureNum={measureNum}
          errorInputValidation={errorInputValidation}
          keyField={`measures.${measureNum}.measure_layers.0.uncertain_list.desorbtion_press`}
        />
        <CoalDensityDist
          key="Coal density"
          name="Coal density"
          units="gr/cc"
          output={value}
          setOutput={onChange}
          onChange={(v) => UpdateData(v, "coal_density")}
          measureNum={measureNum}
          errorInputValidation={errorInputValidation}
          keyField={`measures.${measureNum}.measure_layers.0.uncertain_list.coal_density`}
        />
        <SwDistribution
          key="Sw"
          name="Sw"
          units="%"
          output={value}
          setOutput={onChange}
          onChange={(v) => UpdateData(v, "water_sat_perc")}
          measureNum={measureNum}
          errorInputValidation={errorInputValidation}
          keyField={`measures.${measureNum}.measure_layers.0.uncertain_list.water_sat_perc`}
        />
        <PiDistribution
          key="Pi"
          name="Pi"
          units="psia"
          output={value}
          setOutput={onChange}
          onChange={(v) => UpdateData(v, "initial_pressure_psia")}
          measureNum={measureNum}
          errorInputValidation={errorInputValidation}
          keyField={`measures.${measureNum}.measure_layers.0.uncertain_list.initial_pressure_psia`}
        />
        <PorosityDistribution
          key="Porosity"
          name="Porosity"
          units="%"
          output={value}
          setOutput={onChange}
          onChange={(v) => UpdateData(v, "porosity_perc")}
          measureNum={measureNum}
          errorInputValidation={errorInputValidation}
          keyField={`measures.${measureNum}.measure_layers.0.uncertain_list.porosity_perc`}
        />
      </PivotItem>
      <PivotItem
        headerText="OGIP Inputs"
        headerButtonProps={{
          "data-order": 2,
        }}
        itemKey={ParamInputType.OG}
      >
        <MonteCarloInputs
          key="Permeability"
          name="Permeability"
          units="mD"
          onChange={(v) => UpdateData(v, "permeability_md")}
          output={value}
          setOutput={onChange}
          measureNum={measureNum}
          setMeasureNum={setMeasureNum}
          errorInputValidation={errorInputValidation}
          keyField={`measures.${measureNum}.measure_layers.0.uncertain_list.permeability_md`}
        />
        <NetPayMcs
          key="Net pay"
          name="Net pay"
          units="ft"
          output={value}
          setOutput={onChange}
          onChange={(v) => UpdateData(v, "net_pay_ft")}
          measureNum={measureNum}
          errorInputValidation={errorInputValidation}
          keyField={`measures.${measureNum}.measure_layers.0.uncertain_list.net_pay_ft`}
        />
        <PLDistribution
          key="PL"
          name="PL"
          units="psia"
          output={value}
          setOutput={onChange}
          onChange={(v) => UpdateData(v, "langmuir_p")}
          measureNum={measureNum}
          errorInputValidation={errorInputValidation}
          keyField={`measures.${measureNum}.measure_layers.0.uncertain_list.langmuir_p`}
        />
        <SwDistribution
          key="Sw"
          name="Sw"
          units="%"
          output={value}
          setOutput={onChange}
          onChange={(v) => UpdateData(v, "water_sat_perc")}
          measureNum={measureNum}
          errorInputValidation={errorInputValidation}
          keyField={`measures.${measureNum}.measure_layers.0.uncertain_list.water_sat_perc`}
        />
        <PiDistribution
          key="Pi"
          name="Pi"
          units="psia"
          output={value}
          setOutput={onChange}
          onChange={(v) => UpdateData(v, "initial_pressure_psia")}
          measureNum={measureNum}
          errorInputValidation={errorInputValidation}
          keyField={`measures.${measureNum}.measure_layers.0.uncertain_list.initial_pressure_psia`}
        />
        <PorosityDistribution
          key="Porosity"
          name="Porosity"
          units="%"
          output={value}
          setOutput={onChange}
          onChange={(v) => UpdateData(v, "porosity_perc")}
          measureNum={measureNum}
          errorInputValidation={errorInputValidation}
          keyField={`measures.${measureNum}.measure_layers.0.uncertain_list.porosity_perc`}
        />
        <OGIPDistribution
          key="OGIP"
          name="OGIP"
          units="MMscf"
          output={value}
          setOutput={onChange}
          onChange={(v) => UpdateData(v, "ogip_mmscf")}
          measureNum={measureNum}
          errorInputValidation={errorInputValidation}
          keyField={`measures.${measureNum}.measure_layers.0.uncertain_list.ogip_mmscf`}
        />
        <UnderSatDistribution
          key="Under Saturation"
          name="Under Saturation"
          units="%"
          output={value}
          setOutput={onChange}
          onChange={(v) => UpdateData(v, "under_saturation_perc")}
          measureNum={measureNum}
          errorInputValidation={errorInputValidation}
          keyField={`measures.${measureNum}.measure_layers.0.uncertain_list.under_saturation_perc`}
        />
        <GCIDistribution
          key="GCI"
          name="GCI"
          units="sci/ton"
          output={value}
          setOutput={onChange}
          onChange={(v) => UpdateData(v, "gas_content_scf_ton")}
          measureNum={measureNum}
          errorInputValidation={errorInputValidation}
          keyField={`measures.${measureNum}.measure_layers.0.uncertain_list.gas_content_scf_ton`}
        />
      </PivotItem>
    </Pivot>
  );
};

export default MonteCarloInputCard;
