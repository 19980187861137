import { useEffect, useState } from "react";
import { Stack, useTheme, ScrollablePane } from "@fluentui/react";

import { boldText } from "@/features/text/bold-text";
import { IReleaseDetailLists } from "@/features/settings";
import { replaceAsterisksWithBullets } from "@/features/text/bold-text/bold-text";

export const ReleaseDetailsList = () => {
  const [releaseDetailsList, setReleaseDetailsList] = useState<IReleaseDetailLists[]>([]);
  const theme = useTheme();

  useEffect(() => {
    fetch(process.env.PUBLIC_URL + "/releases.json")
      .then((response) => response.json())
      .then((data) => setReleaseDetailsList(data))
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const itemStyle = {
    boxShadow: theme.effects.elevation4,
    padding: 15,
    marginBottom: 15,
    borderRadius: theme.effects.roundedCorner4,
  } as React.CSSProperties;
  const scrollablePaneStyle = {
    marginTop: "150px",
  };

  const parentStyle = {
    padding: 15,
  } as React.CSSProperties;

  return (
    <ScrollablePane style={scrollablePaneStyle}>
      <Stack style={parentStyle}>
        {releaseDetailsList.map((release) => (
          <Stack.Item style={itemStyle} key={release.version}>
            <h2 style={{ marginBottom: 0 }}> Version: {release.version}</h2>
            <p style={{ marginTop: 0 }}>{release.releasedate}</p>
            <p
              className="br-format"
              style={{ whiteSpace: "pre-wrap" }}
              dangerouslySetInnerHTML={{
                __html: boldText(replaceAsterisksWithBullets(release.summary)).split("\n\n").join("<br>"),
              }}
            />

            {release.issues.map((issue: { [x: string]: any[] }) => (
              <ul key={JSON.stringify(issue)}>
                {Object.keys(issue).map((type) => (
                  <div key={type}>
                    <h3>{type}</h3>
                    {issue[type]?.map((item: any) => (
                      <li
                        key={item.key}
                        dangerouslySetInnerHTML={{
                          __html: boldText(`${item.key} - ${item.summary}`),
                        }}
                      />
                    ))}
                  </div>
                ))}
              </ul>
            ))}
          </Stack.Item>
        ))}
      </Stack>
    </ScrollablePane>
  );
};
