import React from "react";
import { shallow } from "zustand/shallow";

import { useAppStore } from "@/features/app";
import ModulePage from "@/modules/ModulePage";

import TahkCsgProvider, { useTahkCsgState } from "./context/TahkCsgContext";
import dictionary from "@/constants/dictionary";

// components
import TahkCsgInput from "./components/Input";
import DataviewContainer from "./components/Dataview";
import TahkCsgForecast from "./components/Forecast";
import TahkCsgAnalysis from "./components/Analysis";

const ModuleTahkCsg = () => {
  const { tabIndex, onChangeTab, dataSets, isLoading } = useTahkCsgState();

  return (
    <ModulePage
      title={dictionary.tahk.title}
      onTabChange={onChangeTab}
      tabIndex={tabIndex}
      tabs={
        dataSets && [
          {
            headerText: "Data View",
            itemIcon: "ScatterChart",
            disabled: isLoading,
            content: <DataviewContainer />,
            canSaveAsImg: true,
          },
          {
            headerText: "Input",
            itemIcon: "LineChart",
            disabled: isLoading,
            content: <TahkCsgInput />,
            canSaveAsImg: true,
          },
          {
            headerText: "Analysis",
            itemIcon: "NumberField",
            disabled: isLoading,
            content: <TahkCsgAnalysis />,
            canSaveAsImg: true,
          },
          {
            headerText: "Forecast",
            itemIcon: "LineChart",
            disabled: isLoading,
            content: <TahkCsgForecast />,
            canSaveAsImg: true,
          },
        ]
      }
    />
  );
};

const ModuleTahkCsgWrapper = () => {
  const { selectedDataSets, isLoading, group, project, setApiError, setCsvData } = useAppStore(
    (state) => ({
      selectedDataSets: state.selectedDataSets,
      isLoading: state.isLoading,
      group: state.group,
      project: state.project,
      setApiError: state.setApiError,
      setCsvData: state.setCsvData,
    }),
    shallow
  );
  return (
    <TahkCsgProvider
      setCsvData={setCsvData}
      setApiError={setApiError}
      group={group}
      project={project}
      selectedDataSets={selectedDataSets}
      isLoading={isLoading}
    >
      <ModuleTahkCsg />
    </TahkCsgProvider>
  );
};

export default ModuleTahkCsgWrapper;
