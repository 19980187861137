import React from "react";
import Popover from "@mui/material/Popover";

import styled from "@emotion/styled";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

export type FieldLabelProps = {
  calloutContent?: string | React.ReactNode;
  label: string;
  helpUrl?: string;
  ariaLabel?: string;
};

const StyledLabel = styled.label`
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  padding: 5px 0px;
`;

const StyledLink = styled.a`
  color: black;
  text-decoration: none;

  display: flex;
  align-items: center;

  &:hover {
    span {
      color: #f28f1d;
    }
    label {
      color: #f28f1d;
    }
  }
`;
const FieldLabel = ({ calloutContent, label, helpUrl, ariaLabel }: FieldLabelProps) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  if (calloutContent) {
    return (
      <>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "center",
            horizontal: "left",
          }}
        >
          {calloutContent}
        </Popover>
        <div
          onKeyDown={() => {
            /* no-op*/
          }}
          onClick={(e) => handleClick(e)}
        >
          <StyledLabel htmlFor={label}>{label}</StyledLabel>
        </div>
      </>
    );
  }

  if (helpUrl) {
    return (
      <StyledLink target="_blank" rel="noreferrer" href={helpUrl}>
        <StyledLabel htmlFor={label}>{label}</StyledLabel>
        <InfoOutlinedIcon />
      </StyledLink>
    );
  }
  return (
    <StyledLabel htmlFor={label} aria-label={ariaLabel}>
      {label}
    </StyledLabel>
  );
};

export default FieldLabel;
