import React, { useMemo } from "react";

import FossilyticsNumberField from "@/components/fields/FossilyticsNumberField";
import FossilyticsChart from "@/components/FossilyticsChart";
import CustomDataTable from "@/components/CustomTable";
import Tabs from "@/components/Tabs";

import { InputGridCalculation, LangmuirIsotherm, GazAutoRtaInputGrid } from "@/models/gaz/autoRta";

import "../../ModuleGasAutoRTA.css";

import useGAZAutoRtaPvtDataTable from "../../hooks/rtaInputGrid/useGAZAutoRtaPvtDataTable";
import useGazAutoRtaLangmuirIsothermCharts from "../../hooks/rtaInputGrid/useGazAutoRtaLangmuirIsothermCharts";

interface LangmuirIsothermParamProps {
  value: GazAutoRtaInputGrid;
  calculateValue: InputGridCalculation;
  isLoading: boolean;
  updateLangmuirInputField: (v: number | undefined, key: keyof LangmuirIsotherm) => void;
}

const LangmuirIsothermContainer = ({ value, calculateValue, isLoading, updateLangmuirInputField }: LangmuirIsothermParamProps) => {
  const { langmuirIsothermGasContent, langmuirIsothermcd } = useGazAutoRtaLangmuirIsothermCharts(calculateValue);

  const { langmuirIsothermDataTableCols, langmuirIsothermDataTableRows } = useGAZAutoRtaPvtDataTable(calculateValue);

  const tabList = useMemo(() => {
    return [
      {
        label: <span>Gas Content</span>,
        key: "Gas Content",
        content: (
          <div className="chartWrapper">
            <FossilyticsChart
              id="langmuir_isotherm_gas_content"
              isLoading={isLoading}
              xAxes={langmuirIsothermGasContent.xAxes}
              yAxes={langmuirIsothermGasContent.yAxes}
              series={langmuirIsothermGasContent.series}
              hideLegend
            />
          </div>
        ),
      },
      {
        label: (
          <span>
            c<sub>{"d"}</sub>
          </span>
        ),
        key: "Cd",
        content: (
          <div className="chartWrapper">
            <FossilyticsChart
              id="langmuir_isotherm_cg"
              isLoading={false}
              xAxes={langmuirIsothermcd.xAxes}
              yAxes={langmuirIsothermcd.yAxes}
              series={langmuirIsothermcd.series}
              hideLegend
            />
          </div>
        ),
      },
      {
        label: <span>Data Table</span>,
        key: "Langmuir Data table",
        content: (
          <div className="chartWrapper">
            <CustomDataTable rows={langmuirIsothermDataTableRows} columns={langmuirIsothermDataTableCols} isLoading={isLoading} />
          </div>
        ),
      },
    ];
  }, [
    isLoading,
    langmuirIsothermDataTableCols,
    langmuirIsothermDataTableRows,
    langmuirIsothermGasContent.series,
    langmuirIsothermGasContent.xAxes,
    langmuirIsothermGasContent.yAxes,
    langmuirIsothermcd.series,
    langmuirIsothermcd.xAxes,
    langmuirIsothermcd.yAxes,
  ]);

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "1fr 2.8fr",
      }}
    >
      <div
        style={{
          minWidth: 180,
          maxWidth: 297,
        }}
      >
        <FossilyticsNumberField
          label="Desorption Pressure"
          suffix="psia"
          debounceDelay={1000}
          value={value.langmuir_isotherm.desorption_pressure}
          onChange={(v) => updateLangmuirInputField(v, "desorption_pressure")}
          disabled={isLoading}
        />

        <FossilyticsNumberField
          label="Vₗ"
          suffix="scf/ton"
          debounceDelay={1000}
          value={value.langmuir_isotherm.langmuir_volume}
          onChange={(v) => updateLangmuirInputField(v, "langmuir_volume")}
          disabled={isLoading}
        />

        <FossilyticsNumberField
          label="Pₗ"
          suffix="psia"
          debounceDelay={1000}
          value={value.langmuir_isotherm.langmuir_pressure}
          onChange={(v) => updateLangmuirInputField(v, "langmuir_pressure")}
          disabled={isLoading}
        />

        <FossilyticsNumberField
          label="Rock Density"
          suffix="g/cc"
          debounceDelay={1000}
          value={value.langmuir_isotherm.rock_density}
          onChange={(v) => updateLangmuirInputField(v, "rock_density")}
          disabled={isLoading}
        />
      </div>
      <Tabs tabList={tabList} centered />
    </div>
  );
};

export default LangmuirIsothermContainer;
