import React, { useCallback, useMemo } from "react";
import { Dropdown } from "@fluentui/react";
import { CellChange, Column, NumberCell, ReactGrid, Row } from "@silevis/reactgrid";

import { SkinCorrelationType } from "@/model";
import { Output, SkinBase, SkinCorrelationOptions } from "@/models/koldun";
import { THEORETICAL_HELP_LINKS, renderLabelWithHelp } from "@/features/help-link";

const columnWidthCss = "calc((100% - 40px) / 3)";

const constantInputsCols = [
  { columnId: 0, width: 150 },
  { columnId: 1, width: 150 },
] as Column[];
const permSkinInputsCols = [
  { columnId: 0, width: 150 },
  { columnId: 1, width: 150 },
] as Column[];

type PermSkin = {
  skin_flag: SkinCorrelationType;
  skin: Array<{ perm: number; skin: number }>;
};

function convertSkinBase(skinBase: SkinBase): PermSkin {
  return {
    skin_flag: skinBase.skin_flag,
    skin: skinBase.perm_array.map((perm: number, index: number) => {
      return { perm, skin: skinBase.skin_array[index] };
    }),
  };
}

interface DataInputsProps {
  value: Output;
  onChange: React.Dispatch<React.SetStateAction<Output | null>>;
}

const DataInputs = ({ value, onChange }: DataInputsProps) => {
  const baseInput = value.base_inputs;

  const permSkin = convertSkinBase(baseInput.skin_base);

  const constantInputsRows = [
    {
      rowId: "header",
      cells: [
        { type: "header", text: "Measure" },
        { type: "header", text: "Skin(Dim)" },
      ],
    },
    ...value.measures.map((measure, i) => ({
      rowId: i + 1,
      cells: [
        {
          type: "number",
          value: i + 1,
          nonEditable: true,
        },
        {
          type: "number",
          value: measure.measure_layers[0].skin,
        },
      ],
    })),
  ] as Row[];

  const permSkinInputsRows: Row[] = useMemo(
    () => [
      {
        rowId: "header",
        cells: [
          { type: "header", text: "Permeability(mD)" },
          { type: "header", text: "Skin(Dim)" },
        ],
      },
      ...permSkin.skin.map((p, i) => ({
        rowId: i + 1,
        cells: [
          {
            type: "number" as const,
            value: p.perm,
          },
          {
            type: "number" as const,
            value: p.skin,
          },
        ],
      })),
    ],
    [permSkin]
  );

  const handleSkinChanges = (changes: CellChange[]) => {
    // Clone the original data to avoid mutation
    const updatedRows = [...constantInputsRows];

    // Apply the changes to the cloned data
    changes.forEach((change) => {
      let { rowId, columnId, newCell } = change as CellChange<NumberCell>;
      rowId = rowId as number;
      columnId = columnId as number;
      updatedRows[rowId].cells[columnId] = newCell;
    });

    // Skip the first row (header) when extracting skin
    const skins = updatedRows.slice(1).map((row) => (row.cells[1] as NumberCell).value);

    // Update the state using a functional update
    onChange((prev) => {
      if (!prev) return prev;
      // Create a copy of the measures and update the skin value for each measure
      const updatedMeasures = prev.measures.map((measure, i) => ({
        ...measure,
        measure_layers: measure.measure_layers.map((layer, j) => (j === 0 ? { ...layer, skin: skins[i] } : layer)),
      }));
      // Return a new object with the updated measures
      return { ...prev, measures: updatedMeasures };
    });
  };

  const handlePermSkinChanges = useCallback(
    (changes: CellChange[]) => {
      // Clone the original data to avoid mutation
      const updatedRows = [...permSkinInputsRows];

      // Apply the changes to the cloned data
      changes.forEach((change) => {
        let { rowId, columnId, newCell } = change as CellChange<NumberCell>;
        rowId = rowId as number;
        columnId = columnId as number;
        updatedRows[rowId].cells[columnId] = newCell;
      });

      // Skip the first row (header) when extracting perm_array and skin_array
      const perm_array = updatedRows.slice(1).map((row) => (row.cells[0] as NumberCell).value);
      const skin_array = updatedRows.slice(1).map((row) => (row.cells[1] as NumberCell).value);

      // Build the SkinBase object
      const skinBase: SkinBase = {
        skin_flag: SkinCorrelationType.Santos, // Adjust this value as needed
        perm_array,
        skin_array,
      };

      onChange((prev) => {
        if (!prev) return prev;
        const prevInputs = prev;
        prevInputs.base_inputs.skin_base = skinBase;
        return { ...prevInputs };
      });
    },
    [onChange, permSkinInputsRows]
  );

  return (
    <>
      <Dropdown
        label="Skin Correlation"
        ariaLabel="koldun_csg_skin_correlation_dropdown"
        onRenderLabel={(props) => renderLabelWithHelp(THEORETICAL_HELP_LINKS.wellboreSkinAndFormationDamage, props)}
        options={SkinCorrelationOptions}
        selectedKey={baseInput.skin_base.skin_flag}
        styles={{
          root: {
            width: columnWidthCss,
          },
        }}
        onChange={(_, v) =>
          v !== undefined
            ? onChange((prev: any) => ({
                ...prev,
                base_inputs: {
                  ...prev.base_inputs,
                  skin_base: {
                    ...prev.base_inputs.skin_base,
                    skin_flag: v.key,
                  },
                },
              }))
            : null
        }
      />
      {baseInput.skin_base.skin_flag === SkinCorrelationType.Constant ? (
        <div style={{ position: "relative", height: 125, width: 315 }}>
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              overflowX: "hidden",
              overflowY: "scroll",
            }}
          >
            <ReactGrid
              columns={constantInputsCols}
              rows={constantInputsRows}
              enableRangeSelection
              stickyTopRows={1}
              onCellsChanged={handleSkinChanges}
            />
          </div>
        </div>
      ) : (
        <div style={{ position: "relative", height: 125, width: 315 }}>
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              overflowX: "hidden",
              overflowY: "scroll",
            }}
          >
            <ReactGrid
              columns={permSkinInputsCols}
              rows={permSkinInputsRows}
              enableRangeSelection
              stickyTopRows={1}
              onCellsChanged={handlePermSkinChanges}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default DataInputs;
