import React, { useCallback, useMemo } from "react";
import { GridRenderCellParams } from "@mui/x-data-grid";

import MultiLayerTable, { MultiLayerProps } from "../../../../../../../../components/MultiLayerTable";
import { Output, CSGMeasure, RelPermType } from "@/models/koldun";
import { ErrorValidationDetail } from "@/models/ErrorInputValidation";
import { renderCell } from "@/utils/cellRendering";

const measureRelPermColumns = [
  {
    field: "id",
    headerName: "Measure",
    width: 80,
    type: "number",
    headerClassName: "header",
    renderCell: (params: GridRenderCellParams<CSGMeasure>) => renderCell({ row: { value: params.row.measure_num } }),
  },
  {
    field: "krg_max_csg",
    headerName: "KrgMax",
    headerUnits: "(Dim)",
    width: 80,
    type: "number",
    renderCell: (params: GridRenderCellParams<CSGMeasure>) => renderCell({ row: { value: params.row.measure_layers[0].rel_perm.krg_max_csg } }),
  },
  {
    field: "krw_max_csg",
    headerName: "KrwMax",
    headerUnits: "(Dim)",
    width: 100,
    type: "number",
    renderCell: (params: GridRenderCellParams<CSGMeasure>) => renderCell({ row: { value: params.row.measure_layers[0].rel_perm.krw_max_csg } }),
  },
  {
    field: "ng",
    headerName: "ng",
    headerUnits: "(Dim)",
    width: 80,
    type: "number",
    renderCell: (params: GridRenderCellParams<CSGMeasure>) => renderCell({ row: { value: params.row.measure_layers[0].rel_perm.ng } }),
  },
  {
    field: "nw",
    headerName: "nw",
    headerUnits: "(Dim)",
    width: 80,
    type: "number",
    renderCell: (params: GridRenderCellParams<CSGMeasure>) => renderCell({ row: { value: params.row.measure_layers[0].rel_perm.nw } }),
  },
  {
    field: "connate_gas_saturation",
    headerName: "Connate gas saturation",
    headerUnits: "(%)",
    width: 180,
    type: "number",
    renderCell: (params: GridRenderCellParams<CSGMeasure>) =>
      renderCell({ row: { value: params.row.measure_layers[0].rel_perm.connate_gas_saturation } }),
  },
  {
    field: "connate_water_saturation",
    headerName: "Connate water saturation",
    headerUnits: "(%)",
    width: 180,
    type: "number",
    renderCell: (params: GridRenderCellParams<CSGMeasure>) =>
      renderCell({ row: { value: params.row.measure_layers[0].rel_perm.connate_water_saturation } }),
  },
] as MultiLayerProps[];

interface MeasureRelPermTableProps {
  value: Output;
  onChange: React.Dispatch<React.SetStateAction<Output | null>>;
  errorInputValidation?: ErrorValidationDetail[];
}

const MeasureRelPermTable = ({ value, onChange, errorInputValidation }: MeasureRelPermTableProps) => {
  const handleChanges = useCallback(
    (newValue: any) => {
      onChange((prev) => {
        if (!prev) return prev;
        const prevInputs: Output = { ...prev };

        const key = Object.keys(newValue).find((k) => k !== "id" && k !== "measure_num" && k !== "measure_layers" && k !== "numOfLayer");
        const value = newValue[key as string];
        if (key && Object(prevInputs.measures[newValue.measure_num - 1].measure_layers[0]?.rel_perm)) {
          // weird aws amplify deleting object key for undefined but somehow accept null
          prevInputs.measures[newValue.measure_num - 1].measure_layers[0].rel_perm[key as keyof RelPermType] = !value ? null : value;
        }
        return { ...prevInputs };
      });
    },
    [onChange]
  );

  const modelWithIds = value.measures.map((item, index) => ({
    id: index,
    ...item,
  }));

  const errorMessage = useMemo(() => {
    if (errorInputValidation && errorInputValidation.length > 0) {
      const relPermErrorIndex = errorInputValidation.findIndex((error) => error.loc.includes("rel_perm"));
      if (relPermErrorIndex > -1) return errorInputValidation?.[relPermErrorIndex].msg;
      return null;
    }
    return null;
  }, [errorInputValidation]);

  return (
    <>
      <div style={{ position: "relative", maxWidth: 780, height: 300 }}>
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
          }}
        >
          {errorMessage && <span style={{ color: "red" }}>{errorMessage}</span>}

          <MultiLayerTable columns={measureRelPermColumns} rows={modelWithIds} handleChanges={handleChanges} />
        </div>
      </div>
    </>
  );
};

export default MeasureRelPermTable;
