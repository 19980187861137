import { Dispatch, useCallback, useMemo } from "react";
import { DetailsList, IColumn, IconButton, IObjectWithKey, SelectionMode, Stack, Text, TextField } from "@fluentui/react";

import { useAppStore } from "@/features/app";
import { Group } from "../model";

const columns = [{ key: "name", fieldName: "name" }] as IColumn[];

interface TreeViewCreateGroupsInputProps {
  parentGroup?: Group;
  value: string[];
  onChange: Dispatch<string[]>;
  name: string;
  setName: Dispatch<React.SetStateAction<string>>;
}

function TreeViewCreateGroupsInput({ parentGroup, value, onChange, name, setName }: Readonly<TreeViewCreateGroupsInputProps>) {
  const isLoading = useAppStore((state) => state.isLoading);

  const items = useMemo<IObjectWithKey[]>(
    () =>
      value.map((v) => ({
        key: v,
        name: parentGroup ? `${parentGroup.name} / ${v}` : v,
      })),
    [parentGroup, value]
  );

  const isNameValid = useMemo(
    () => (value.length === 1 || !value.includes(name)) && !parentGroup?.groups?.some((g) => g.name === name),
    [parentGroup, value, name]
  );

  const addName = useCallback(() => {
    onChange([...value, name]);
    setName("");
  }, [onChange, value, name, setName]);

  const removeName = useCallback(
    (n: string) => {
      if (!value.includes(n)) return;
      onChange(value.filter((v) => v !== n));
    },
    [onChange, value]
  );

  const renderColumn = useCallback(
    (item?: any) => (
      <Stack horizontal verticalAlign="center">
        <Stack.Item grow>{item.name}</Stack.Item>
        <IconButton disabled={isLoading} iconProps={{ iconName: "Delete" }} onClick={() => removeName(item.key)} />
      </Stack>
    ),
    [isLoading, removeName]
  );

  return (
    <Stack tokens={{ childrenGap: 15 }}>
      {items.length > 0 && (
        <DetailsList
          columns={columns}
          items={items}
          selectionMode={SelectionMode.none}
          onRenderItemColumn={renderColumn}
          compact={true}
          isHeaderVisible={false}
        />
      )}

      <Stack.Item>
        <TextField
          label={parentGroup ? "Subgroup name" : "Group name"}
          disabled={isLoading}
          invalid={!isNameValid}
          errorMessage={!isNameValid ? "Cannot create duplicate groups." : undefined}
          onKeyDown={(event) => event.key === "Enter" && isNameValid && addName()}
          value={name}
          onChange={(_, v) => setName(v ?? "")}
        />
        <Text variant="tiny">Tip: Use the Enter key to create groups quickly.</Text>
      </Stack.Item>
    </Stack>
  );
}

export default TreeViewCreateGroupsInput;
