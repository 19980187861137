import { Stack } from "@fluentui/react";

import { MonteCarloInputApplication, MonteCarloInputProps, UncertainParamTriangular } from "@/models/koldun";
import FossilyticsNumberField from "@/components/fields/FossilyticsNumberField";

import { generateKeyFieldForLayer } from "../../../../utils";

const TriangularInput = ({ units, output, updateUncertainList, measureNum, uncertainKey, keyField, errorInputValidation }: MonteCarloInputProps) => {
  const application = output.measures[measureNum].measure_layers[0].uncertain_list[uncertainKey].application;

  const renderLayerFields = () => {
    return output.measures[measureNum].measure_layers.map((measureLayer, index) => {
      return (
        <Stack
          key={`layer-field-${measureLayer.layer_num}`}
          enableScopedSelectors
          horizontal
          disableShrink
          tokens={{ childrenGap: 20 }}
          style={{ width: 690 }}
        >
          {index === 0 ? (
            <span style={{ width: 50, fontWeight: 600, marginTop: 35 }}>Layer {index + 1} </span>
          ) : (
            <span style={{ width: 50, fontWeight: 600, marginTop: 8 }}>Layer {index + 1} </span>
          )}
          <Stack grow={1} className="equal-size">
            <FossilyticsNumberField
              label={index === 0 ? "Low" : ""}
              suffix={units}
              debounceDelay={1000}
              value={(measureLayer.uncertain_list[uncertainKey] as UncertainParamTriangular).left}
              onChange={(v) => updateUncertainList(index, "left", v)}
              errors={errorInputValidation}
              keyField={generateKeyFieldForLayer(index, `${keyField}.left`)}
            />
          </Stack>
          <Stack grow={1} className="equal-size">
            <FossilyticsNumberField
              label={index === 0 ? "Mode" : ""}
              suffix={units}
              debounceDelay={1000}
              value={(measureLayer.uncertain_list[uncertainKey] as UncertainParamTriangular).mode}
              onChange={(v) => updateUncertainList(index, "mode", v)}
              errors={errorInputValidation}
              keyField={generateKeyFieldForLayer(index, `${keyField}.mode`)}
            />
          </Stack>
          <Stack grow={1} className="equal-size">
            <FossilyticsNumberField
              label={index === 0 ? "High" : ""}
              suffix={units}
              debounceDelay={1000}
              value={(measureLayer.uncertain_list[uncertainKey] as UncertainParamTriangular).right}
              onChange={(v) => updateUncertainList(index, "right", v)}
              keyField={generateKeyFieldForLayer(index, `${keyField}.right`)}
              errors={errorInputValidation}
            />
          </Stack>
        </Stack>
      );
    });
  };
  const renderMeasureField = () => (
    <Stack horizontal enableScopedSelectors tokens={{ childrenGap: 20 }} style={{ width: 680 }}>
      <Stack grow={1} className="equal-size">
        <FossilyticsNumberField
          label={`Low`}
          suffix={units}
          debounceDelay={1000}
          value={(output.measures[measureNum].measure_layers[0].uncertain_list[uncertainKey] as UncertainParamTriangular).left}
          onChange={(v) => updateUncertainList(0, "left", v)}
          keyField={`${keyField}.left`}
          errors={errorInputValidation}
        />
      </Stack>
      <Stack grow={1} className="equal-size">
        <FossilyticsNumberField
          label={`Mode`}
          debounceDelay={1000}
          suffix={units}
          value={(output.measures[measureNum].measure_layers[0].uncertain_list[uncertainKey] as UncertainParamTriangular).mode}
          onChange={(v) => updateUncertainList(0, "mode", v)}
          keyField={`${keyField}.mode`}
          errors={errorInputValidation}
        />
      </Stack>
      <Stack grow={1} className="equal-size">
        <FossilyticsNumberField
          label={`High`}
          suffix={units}
          debounceDelay={1000}
          value={(output.measures[measureNum].measure_layers[0].uncertain_list[uncertainKey] as UncertainParamTriangular).right}
          onChange={(v) => updateUncertainList(0, "right", v)}
          keyField={`${keyField}.right`}
          errors={errorInputValidation}
        />
      </Stack>
    </Stack>
  );
  return (
    <Stack horizontal tokens={{ childrenGap: 20 }}>
      <Stack grow={0.5} tokens={{ childrenGap: 10 }} className="equal-size">
        {application === MonteCarloInputApplication.LAYER ? renderLayerFields() : renderMeasureField()}
      </Stack>
    </Stack>
  );
};

export default TriangularInput;
