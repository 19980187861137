import { Stack } from "@fluentui/react";

import useGasAutoRtaAnalysis, { GasAutoRtaAnalysisProps } from "../../hooks/rtaAnalysis/useGasAutoRtaAnalysis";
import AnalysisSetting from "./AutoRtaAnalysisSetting";
import AnalysisCalculation from "./AnalysisCalculation";
import LoadingIndicator from "@/components/LoadingIndicator";

const AnalysisContainer = (props: GasAutoRtaAnalysisProps) => {
  const { analysisInputState, analysisCalculation, onChangeAnalysisInput, analysisSummaryCardParameter, loadAnalysis } = useGasAutoRtaAnalysis();

  const loadingState = props.isLoading || loadAnalysis;

  // don't display anything if no analysisInputState
  if (!analysisInputState && loadingState) return <LoadingIndicator />;

  return (
    <Stack horizontal style={{ height: "100%" }} tokens={{ padding: 20, childrenGap: 20 }}>
      {analysisInputState && (
        <AnalysisSetting
          onChangeInput={onChangeAnalysisInput}
          analysisInput={analysisInputState}
          isLoading={loadingState}
          analysisSummaryCardParameter={analysisSummaryCardParameter}
        />
      )}

      <AnalysisCalculation isLoading={loadingState} analysisCalculation={analysisCalculation} />
    </Stack>
  );
};

const AutoRtaAnalysisContainerWrapper = (props: GasAutoRtaAnalysisProps) => {
  if (props.currentTab !== 2) return <LoadingIndicator />;
  return <AnalysisContainer {...props} />;
};

export default AutoRtaAnalysisContainerWrapper;
