import { useCallback, useEffect } from "react";
import _ from "lodash";
import { useQuery } from "@tanstack/react-query";
import { IDropdownOption } from "@fluentui/react";

import { useGazAutoRtaState } from "../GazAutoRtaContext";
import { GazAutoRta, GazAutoRtaDataView, pollCalculateDataViewGazAutoRta } from "@/models/gaz/autoRta";

export type useGazAutoRtaDataViewProps = {
  isLoading: boolean;
  currentTab: number;
};

const useGazAutoRtaDataView = () => {
  const { gazAutoRTAState, dataSet, setGazAutoRTAState, dataViewCalculation, setDataViewCalculation } = useGazAutoRtaState();

  const { refetch, isFetching, isLoading } = useQuery({
    queryKey: ["data-view-calculation", gazAutoRTAState?.dataview, dataSet?.id],
    queryFn: async () => {
      if (gazAutoRTAState?.dataview && dataSet?.id) {
        return pollCalculateDataViewGazAutoRta(gazAutoRTAState?.dataview, {
          data_set_ids: [dataSet?.id],
        });
      }
      return {};
    },
    refetchOnWindowFocus: false,
    enabled: false,
  });

  const updateDatViewInputField = useCallback(
    (v: number | undefined, key: keyof GazAutoRtaDataView) => {
      if (v === undefined) return;
      setGazAutoRTAState((prev) => {
        if (!prev) return prev;
        const prevInputs: GazAutoRta = _.cloneDeep(prev);
        return {
          ...prevInputs,
          dataview: {
            ...prevInputs.dataview,
            [key]: v,
          },
        };
      });
    },
    [setGazAutoRTAState]
  );

  const updateDatViewStringVal = useCallback(
    (v: IDropdownOption | undefined) => {
      if (v === undefined) return;
      setGazAutoRTAState((prev) => {
        if (!prev) return prev;
        const prevInputs: GazAutoRta = _.cloneDeep(prev);
        prevInputs.dataview.smoothing_type = v.key as string;
        return { ...prevInputs };
      });
    },
    [setGazAutoRTAState]
  );

  const onCalculateAutoRtaDataView = useCallback(async () => {
    if (!dataSet || !gazAutoRTAState?.dataview) return;
    (async () => {
      try {
        const response = await refetch();
        if (response.data?.data) {
          setDataViewCalculation(response.data.data);
        }
      } catch (error) {
        console.error(error);
      }
    })();
  }, [dataSet, gazAutoRTAState?.dataview, refetch, setDataViewCalculation]);

  useEffect(() => {
    onCalculateAutoRtaDataView();
  }, [gazAutoRTAState?.dataview, onCalculateAutoRtaDataView]);

  return {
    dataViewInput: gazAutoRTAState?.dataview,
    dataViewCalculate: dataViewCalculation,
    updateDatViewInputField,
    updateDatViewStringVal,
    loadDataView: isFetching || isLoading,
  };
};

export default useGazAutoRtaDataView;
