export const APIName = "afa";

export const logoutApi = "/auth/session";
export const getUserCurrentTaskApi = "/tasks/current";
export const getSpadWorAnalysis = "/modules/spad/wor/get_analysis";
export const loadCleanRawData = "/loaders/clean_raw_data";
export const loadRawData = "/loaders/load_raw_data";

export const pollKoldunCsgCalculation = "/modules/koldun/mcsim/csg/calculate";
export const initializeKoldunCsgData = "/modules/koldun/mcsim/csg/validate";

export const calculateSpadDeclineAnalysis = (type: string) => {
  return `/modules/spad/decline/${type}/calculate_v2`;
};

export const exportSpadDeclineAnalysis = (type: string) => {
  return `/modules/spad/decline/${type}/export`;
};

export const savedSpadDeclineAnalysis = (type: string) => {
  return `/modules/spad/decline/${type}/saved_analysis`;
};

// auto rta API
export const validateGazAutoRtaForecast = "/modules/gaz/auto_rta/forecast/validate";
export const calculateGazAutoRtaForecast = "/modules/gaz/auto_rta/forecast/calculate";

export const validateGazAutoRtaAnalysisUrl = "/modules/gaz/auto_rta/analysis/validate";
export const calculateGazAutoRtaAnalysisUrl = "/modules/gaz/auto_rta/analysis/calculate";

export const gazAutoRtaInitialize = "/modules/gaz/auto_rta/initialize";
export const gazAutoRtaDataView = "/modules/gaz/auto_rta/data_view/calculate";
export const gazAutoRtaInputCalculate = "/modules/gaz/auto_rta/input/calculate";

export const calculateGazFmbPVT = "/modules/gaz/fmb/calculate_pvt";
export const calculateGazFmbInit = "/modules/gaz/fmb/calculate_fmb_init";
export const calculateGazFmbUser = "/modules/gaz/fmb/calculate_fmb_user";
export const calculateGazFmbParams = "/modules/gaz/fmb/calculate_fmb_params";
export const calculatePzInit = "/modules/gaz/pz/calculate_pz_init";

// projects settings
export const getAllWellsSettings = "/well_settings";
export const saveLegacyWellSettings = "/well_settings/legacy_modules";
export const saveProjectSetting = "/well_settings/project";

export const addWellToGroup = (projectId: string, groupId: string) => {
  return `/projects/${projectId}/groups/${groupId}/data_sets`;
};

// rollup
export const validateRollup = "/modules/rollup/validate";
export const calculateRollup = "/modules/rollup/calculate";

// TAHK
export const initializeTahkCsg = "/modules/tahk/new_csg/initialize";

// dataview
export const calculateTahkCsgDataview = "/modules/tahk/new_csg/data_view/calculate";

// input
export const validateTahkCsgInput = "/modules/tahk/new_csg/input/validate";

// analysis
export const calculateTahkCsgAnalysis = "/modules/tahk/new_csg/analysis/calculate";
export const validateTahkCsgAnalysis = "/modules/tahk/new_csg/analysis/validate";

// forecast
export const calculateTahkCsgForecast = "/modules/tahk/new_csg/forecast/calculate";

// data set for importing well
export const importWell = "/datasets";
export const importProject = "/datasets/structure";

// spad decline new
export const spadDeclineAnalysisInitialize = (type: string) => {
  return `/modules/spad/decline/${type}/initialise`;
};

export const spadDeclineAnalysisValidate = (type: string) => {
  return `/modules/spad/decline/${type}/validate`;
};

export const spadDeclineAnalysisCalculate = (type: string) => {
  return `/modules/spad/decline/${type}/analysis/calculate`;
};

export const spadDeclineAnalysisExport = (type: string) => {
  return `/modules/spad/decline/${type}/export/calculate`;
};

export const spadDeclineAnalysisChartDrag = (type: string) => {
  return `/modules/spad/decline/${type}/analysis/chart_drag`;
};

// move well api
export const moveWellBetweenGroup = (projectId: string, groupId: string) => {
  return `/projects/${projectId}/groups/${groupId}/move_wells`;
};

// move group api
export const moveGroup = (project_id: string, group_id: string) => {
  // old project and group id
  return `/projects/${project_id}/groups/${group_id}/move`;
};

// rullon

export const initializeRullon = (projectId: string) => {
  return `/projects/${projectId}/modules/rulon/group_run/initialise`;
};

export const validateRullon = (projectId: string) => {
  return `/projects/${projectId}/modules/rulon/group_run/validate`;
};

export const calculateRullon = (projectId: string) => {
  return `/projects/${projectId}/modules/rulon/group_run/calculate`;
};
