import React, { CSSProperties, useMemo } from "react";

import dictionary from "@/constants/dictionary";
import useThemeStyling from "@/utils/useThemeStyling";

import { smoothingTypeDropdown, SmoothingTypeEnum } from "@/models/Generic";

import FossilyticsDropdown from "@/components/fields/FossilyticsDropdown";
import FossilyticsNumberField from "@/components/fields/FossilyticsNumberField";
import LoadingIndicator from "@/components/LoadingIndicator";
import Tabs from "@/components/Tabs";

import { useTahkCsgState } from "../../context/TahkCsgContext";
import useTahkCsgDataview from "../../hooks/dataview/useTahkCsgDataview";

import { Container } from "./style";
import FossilyticsChart, { FossilyticsChartAxis, FossilyticsChartSeries } from "@/components/FossilyticsChart";
import CustomTable from "@/components/CustomTable";
import { Column, Row } from "@silevis/reactgrid";
import { TahkCsgStateResponse } from "@/models/tahk";
import { ErrorValidationDetail } from "@/models/ErrorInputValidation";

type DataviewContainerProps = {
  loadingState: boolean;
  chartXAxes: FossilyticsChartAxis[];
  chartYAxes: FossilyticsChartAxis[];
  chartSeries: FossilyticsChartSeries[];

  dataTableColumn: Column[];
  rawDataRows: Row[];
  cleanDataRows: Row[];
  elevatedCard: CSSProperties;
  tahkCsgState?: TahkCsgStateResponse | null;
  errorInputValidation: ErrorValidationDetail[];
  onChangeDataviewOption: (key: string, value: any) => void;
  numberOfPoints?: number;
  isTest?: boolean;
};
export const DataviewContainer = ({
  loadingState,
  chartSeries,
  chartXAxes,
  chartYAxes,
  dataTableColumn,
  rawDataRows,
  cleanDataRows,
  elevatedCard,
  tahkCsgState,
  errorInputValidation,
  onChangeDataviewOption,
  numberOfPoints,
  isTest,
}: DataviewContainerProps) => {
  const tabList = useMemo(() => {
    return [
      {
        label: <span>{dictionary.dataView.chart}</span>,
        key: dictionary.dataView.chart,
        content:
          loadingState || isTest ? (
            <div className="center-flex h100">
              <LoadingIndicator />
            </div>
          ) : (
            <div
              style={{
                height: 600,
              }}
              className="chartWrapper"
            >
              <FossilyticsChart id="dataview_tahk_csg" isLoading={loadingState} xAxes={chartXAxes} yAxes={chartYAxes} series={chartSeries} />
            </div>
          ),
      },
      {
        label: <span>{dictionary.dataView.rawData}</span>,
        key: dictionary.dataView.rawData,
        content: loadingState ? (
          <div className="center-flex h100">
            <LoadingIndicator />
          </div>
        ) : (
          <div>
            <CustomTable
              style={{
                display: "flex",
                justifyContent: "center",
              }}
              rows={rawDataRows}
              columns={dataTableColumn}
              isLoading={loadingState}
            />
          </div>
        ),
      },
      {
        label: <span>{dictionary.dataView.cleanData}</span>,
        key: dictionary.dataView.cleanData,
        content: loadingState ? (
          <div className="center-flex h100">
            <LoadingIndicator />
          </div>
        ) : (
          <div>
            <CustomTable
              style={{
                display: "flex",
                justifyContent: "center",
              }}
              rows={cleanDataRows}
              columns={dataTableColumn}
              isLoading={loadingState}
            />
          </div>
        ),
      },
    ];
  }, [chartSeries, chartXAxes, chartYAxes, cleanDataRows, dataTableColumn, isTest, loadingState, rawDataRows]);

  return (
    <Container>
      <div style={elevatedCard}>
        <FossilyticsNumberField
          keyField="dataview.atmospheric_correction"
          label={dictionary.tahk.atmosphericCorrection}
          disabled={loadingState}
          value={tahkCsgState?.dataview?.atmospheric_correction}
          onChange={(v) => onChangeDataviewOption("atmospheric_correction", v)}
          min={1}
          errors={errorInputValidation}
        />
        <FossilyticsDropdown
          label={dictionary.tahk.smoothingType}
          options={smoothingTypeDropdown}
          disabled={loadingState}
          selectedKey={tahkCsgState?.dataview?.smoothing_type}
          onChange={(v) => {
            onChangeDataviewOption("smoothing_type", v?.key);
          }}
        />

        {tahkCsgState?.dataview.smoothing_type === SmoothingTypeEnum.interp ? (
          <FossilyticsNumberField
            keyField="dataview.smoothing_points"
            label={dictionary.tahk.smoothingPoints}
            disabled={loadingState}
            value={tahkCsgState?.dataview?.smoothing_points}
            onChange={(v) => onChangeDataviewOption("smoothing_points", v)}
            min={1}
            errors={errorInputValidation}
          />
        ) : (
          <FossilyticsNumberField
            keyField="dataview.moving_avg_window"
            label={dictionary.tahk.movingAvgWindow}
            disabled={loadingState}
            value={tahkCsgState?.dataview?.moving_avg_window}
            onChange={(v) => onChangeDataviewOption("moving_avg_window", v)}
            min={1}
            errors={errorInputValidation}
          />
        )}

        <FossilyticsNumberField label={dictionary.tahk.numberOfPoints} disabled value={numberOfPoints} min={1} errors={errorInputValidation} />
      </div>

      <div style={elevatedCard}>
        <Tabs tabList={tabList} />
      </div>
    </Container>
  );
};

const DataviewContainerWrapper = () => {
  const { tabIndex, isLoading, tahkCsgState, setTahkCsgState, analysisIdentity, setTahkCsgCalculation, tahkCsgCalculation } = useTahkCsgState();

  const { elevatedCard } = useThemeStyling();

  const props = useTahkCsgDataview({
    isLoading,
    tabIndex,
    analysisIdentity,

    dataview: tahkCsgState?.dataview,
    setTahkCsgState,
    setTahkCsgCalculation,
    tahkCsgCalculation,
  });
  return <DataviewContainer {...props} elevatedCard={elevatedCard} tahkCsgState={tahkCsgState} />;
};

export default DataviewContainerWrapper;
