import { calculateTahkCsgAnalysis, validateTahkCsgAnalysis } from "@/constants/apiUrl";

import { createPoll } from "@/utils/apiFetcher";

import { TahkCsgAnalysisCalculateRequestPayload, TahkCsgAnalysisCalculateResponse, TahkCsgAnalysisValidationRequestPayload } from "../analysis";
import { TahkCsgValidationState } from "../State";

export const pollCalculateTahkCsgAnalysis = (payload: TahkCsgAnalysisCalculateRequestPayload) => {
  return createPoll<TahkCsgAnalysisCalculateResponse, TahkCsgAnalysisCalculateRequestPayload>({
    path: calculateTahkCsgAnalysis,
    body: payload,
    type: "post",
  });
};

export const pollValidateTahkCsgAnalysis = (payload: TahkCsgAnalysisValidationRequestPayload) => {
  return createPoll<TahkCsgValidationState, TahkCsgAnalysisValidationRequestPayload>({
    path: validateTahkCsgAnalysis,
    body: payload,
    type: "post",
  });
};
