import { DatePicker, IconButton, Stack } from "@fluentui/react";
import FossilyticsFieldBase, { IFossilyticsFieldBaseProps } from "./FossilyticsFieldBase";

interface FossilyticsDateFieldProps extends IFossilyticsFieldBaseProps {
  minDate?: Date;
  maxDate?: Date;
  label: string;
  value?: Date;
  onChange?: (value?: Date) => void;
}

function FossilyticsDateField({ label, value, onChange, calloutContent, minDate, maxDate }: FossilyticsDateFieldProps) {
  return (
    <FossilyticsFieldBase calloutContent={calloutContent}>
      {(onRenderLabel, baseDisabled) => (
        <Stack horizontal verticalAlign="end">
          <Stack.Item grow={1}>
            <DatePicker
              ariaLabel={`${label}`}
              minDate={minDate}
              maxDate={maxDate}
              label={label}
              disabled={baseDisabled}
              formatDate={(d) => d?.toLocaleDateString() ?? ""}
              textField={{ onRenderLabel }}
              value={value}
              onSelectDate={(d) => onChange && onChange(d ?? undefined)}
            />
          </Stack.Item>
          <IconButton
            ariaLabel={`Clear ${label}`}
            disabled={baseDisabled}
            styles={{ root: { marginBlockEnd: 6 } }}
            iconProps={{ iconName: "Clear" }}
            onClick={() => onChange && onChange()}
          />
        </Stack>
      )}
    </FossilyticsFieldBase>
  );
}

export default FossilyticsDateField;
