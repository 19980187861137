import dictionary from "@/constants/dictionary";
import { Column } from "@silevis/reactgrid";

export const reservoirInputRowsHeader: { [key: string]: string } = {
  layer: dictionary.tahk.layer,
  initialPressure: dictionary.tahk.initialPressure,
  formationTemperature: dictionary.tahk.formationTemperature,
  netPay: dictionary.tahk.netPay,
  layerDepth: dictionary.tahk.layerDepth,

  porosity: dictionary.tahk.porosity,
  selectedFormationCompressibilityCorrelation: dictionary.tahk.selectedFormationCompressibilityCorrelation,
  formationCompressibility: dictionary.tahk.formationCompressibility,
  initialWaterSaturation: dictionary.tahk.initialWaterSaturation,
  reservoirArea: dictionary.tahk.reservoirArea,
  reservoirWidth: dictionary.tahk.reservoirWidth,
  reservoirLength: dictionary.tahk.reservoirLength,
  permeabilityXAxis: dictionary.tahk.permeabilityXAxis,
  permeabilityYAxis: dictionary.tahk.permeabilityYAxis,
  permeabilityZAxis: dictionary.tahk.permeabilityZAxis,
};
export const reservoirInputRowsHeaderWidth: { [key: string]: number } = {
  layer: 50,
  initialPressure: 110,
  formationTemperature: 110,
  netPay: 100,
  layerDepth: 110,
  porosity: 100,
  selectedFormationCompressibilityCorrelation: 110,
  formationCompressibility: 130,
  initialWaterSaturation: 110,
  reservoirArea: 60,
  reservoirWidth: 60,
  reservoirLength: 60,
  permeabilityXAxis: 110,
  permeabilityYAxis: 110,
  permeabilityZAxis: 110,
};

export const tableHeaderStyle = {
  background: "#fbdcb9",
  display: "flex",
  justifyContent: "center",
  fontWeight: "600",
  border: {
    bottom: {
      width: "0px",
    },
  },
  textOverflow: "ellipsis",
  whiteSpace: "break-spaces",
  textAlign: "center",
  padding: 10,
};

export const mergedHeaderLeft = {
  ...tableHeaderStyle,
  border: {
    bottom: {
      width: "0px",
    },
    right: {
      width: "0px",
    },
  },
  justifyContent: "flex-end",
};
export const mergedHeaderRight = {
  ...tableHeaderStyle,
  border: {
    bottom: {
      width: "0px",
    },
  },
  justifyContent: "flex-start",
  paddingLeft: "0px",
};
export const tableHeaderNotationStyle = {
  background: "#fff",
  display: "flex",
  justifyContent: "center",
  textAlign: "center",
  border: {
    bottom: {
      width: "3px",
    },
  },
};

export const tableCellStyle = {
  background: "#fff",
  display: "flex",
  justifyContent: "center",
  textAlign: "center",
};

export const summaryCardTableHeader = [
  {
    key: "parameter",
    label: dictionary.tahk.parameter,
  },
  {
    key: "value",
    label: dictionary.tahk.value,
  },
  {
    key: "unit",
    label: dictionary.tahk.units,
  },
];

export const gasPvtHeader: { [key: string]: string } = {
  layer: dictionary.tahk.layer,
  specificGravity: dictionary.tahk.specificGravity,
  nitrogen: dictionary.tahk.nitrogen,
  carbonDioxide: dictionary.tahk.carbonDioxide,
  hydrogenSulphide: dictionary.tahk.hydrogenSulphide,
  selectedZCorrelation: dictionary.tahk.selectedZCorrelation,
  selectedGasViscosityCorrelation: dictionary.tahk.selectedGasViscosityCorrelation,
};
export const gasPvtWidth: { [key: string]: number } = {
  layer: 50,
  specificGravity: 150,
  nitrogen: 60,
  carbonDioxide: 60,
  hydrogenSulphide: 60,
  selectedZCorrelation: 180,
  selectedGasViscosityCorrelation: 180,
};

export const waterPvtHeader = {
  layer: dictionary.tahk.layer,
  salinity: dictionary.tahk.salinity,
  selectedWaterCompressibilityCorrelation: dictionary.tahk.selectedWaterCompressibilityCorrelation,
  selectedWaterViscosityCorrelation: dictionary.tahk.selectedWaterViscosityCorrelation,
};

export const dataTableHeaderStyles = {
  background: "#fff",
  display: "flex",
  justifyContent: "center",
  fontWeight: "bold",
  zIndex: 1,
};

export const headerDataTableUnitStyles = { background: "#fff", display: "flex", justifyContent: "center", zIndex: 1 };

export const gasPvtDataTableCols = [
  { columnId: "pressure", width: 80 },
  { columnId: "compressibility_factor", width: 80, justifyContent: "center" },
  { columnId: "viscosity", width: 80, justifyContent: "center" },
  { columnId: "density", width: 80, justifyContent: "center" },
  { columnId: "form_vol_factor", width: 80, justifyContent: "center" },
  { columnId: "compressibility", width: 80, justifyContent: "center" },
] as Column[];

export const waterPvtDataTableCols = [
  { columnId: "pressure", width: 80 },
  { columnId: "compressibility", width: 80, justifyContent: "center" },
  { columnId: "density", width: 80, justifyContent: "center" },
  { columnId: "form_vol_factor", width: 80, justifyContent: "center" },
  { columnId: "viscosity", width: 80, justifyContent: "center" },
] as Column[];

export const relativePermeabilityHeader: {
  [key: string]: {
    label: string;
    sub?: string;
  };
} = {
  layer: {
    label: dictionary.tahk.layer,
  },
  selectedRelativePermeabilityCorrelation: {
    label: dictionary.tahk.selectedRelativePermeabilityCorrelation,
  },
  krgMax: {
    label: dictionary.tahk.krgMax,
    sub: "rg | Max",
  },
  krwMax: {
    label: dictionary.tahk.krwMax,
    sub: "rw | Max",
  },
  ng: {
    label: dictionary.tahk.ng,
    sub: "g",
  },
  nw: {
    label: dictionary.tahk.nw,
    sub: "w",
  },
  lamb: {
    label: dictionary.tahk.lamb,
  },
  n: {
    label: dictionary.tahk.n,
  },
  connateWaterSaturation: {
    label: dictionary.tahk.connateWaterSaturation,
    sub: "w,irr",
  },
  residualGasSaturation: {
    label: dictionary.tahk.residualGasSaturation,
    sub: "g,r",
  },
};
export const relativePermeabilityWidth: { [key: string]: number } = {
  layer: 50,
  selectedRelativePermeabilityCorrelation: 150,
  krgMax: 80,
  krwMax: 80,
  ng: 80,
  nw: 80,
  lamb: 80,
  n: 80,
  connateWaterSaturation: 80,
  residualGasSaturation: 80,
};

export const areaWellInputHeader = {
  layer: dictionary.tahk.layer,
  wellboreRadius: dictionary.tahk.wellboreRadius,
  selectedWellType: dictionary.tahk.selectedWellType,
  fractureHalfLength: dictionary.tahk.fractureHalfLength,
  fractureConductivity: dictionary.tahk.fractureConductivity,
  horizontalLength: dictionary.tahk.horizontalLength,
  selectedSkinModel: dictionary.tahk.selectedSkinModel,
  skin: dictionary.tahk.skin,
};
export const dimensionWellInputHeader = {
  layer: dictionary.tahk.layer,
  wellboreRadius: dictionary.tahk.wellboreRadius,
  selectedWellType: dictionary.tahk.selectedWellType,
  fractureHalfLength: dictionary.tahk.fractureHalfLength,
  fractureConductivity: dictionary.tahk.fractureConductivity,
  horizontalLength: dictionary.tahk.horizontalLength,
  wellPositionXAxis: dictionary.tahk.wellPositionXAxis,
  wellPositionYAxis: dictionary.tahk.wellPositionYAxis,
  wellPositionZAxis: dictionary.tahk.wellPositionZAxis,
  selectedSkinModel: dictionary.tahk.selectedSkinModel,
  skin: dictionary.tahk.skin,
};

export const flowingPressureHeader = {
  date: dictionary.tahk.date,
  pressure: dictionary.tahk.flowingPressure,
  rate: dictionary.tahk.rate,
};

export const geomechanicsHeader = {
  layer: dictionary.tahk.layer,
  selectedGeomechanicModel: dictionary.tahk.selectedGeomechanicModel,
  poissonsRatio: dictionary.tahk.poissonsRatio,
  grainCompressibility: dictionary.tahk.grainCompressibility,
  langmuirVolumeStrain: dictionary.tahk.langmuirVolumeStrain,
  youngModule: dictionary.tahk.youngModule,
  shrinkageExponent: dictionary.tahk.shrinkageExponent,
  shrinkageFactor: dictionary.tahk.shrinkageFactor,
  maximumProductivityMultiplier: dictionary.tahk.maximumProductivityMultiplier,
  permeabilityModulus: dictionary.tahk.permeabilityModulus,
};

export const geomechanicHeaderWithLink = ["selectedGeomechanicModel", "poissonsRatio", "langmuirVolumeStrain", "youngModule"];

export const langmuirIsothermVolumetricHeader: { [key: string]: string } = {
  layer: dictionary.tahk.layer,
  desorptionPressure: dictionary.tahk.desorptionPressure,
  langmuirPressure: dictionary.tahk.langmuirPressure,
  langmuirVolume: dictionary.tahk.langmuirVolume,
  rockDensity: dictionary.tahk.rockDensity,
};

export const langmuirIsothermOGIPHeader: { [key: string]: string } = {
  layer: dictionary.tahk.layer,
  underSaturation: dictionary.tahk.underSaturation,
  initialGasContent: dictionary.tahk.initialGasContent,
  langmuirPressure: dictionary.tahk.langmuirPressure,
  ogip: dictionary.tahk.ogip,
};

export const langmuirHeaderWithLink = ["langmuirPressure", "langmuirVolume"];
export const langmuirHeaderWithSub = ["langmuirPressure", "langmuirVolume"];

export const langmuirIsothermDataTable = {
  pressure: { name: "Pressure", unit: "(psia)", sub: "" },
  gas_content: { name: "Gas Content", unit: "(scf/ton)", sub: "" },
  desorption_compressibility: { name: "C", unit: "(psi⁻¹)", sub: "d" },
};

export const langmuirDataTableCols = [
  { columnId: "pressure", width: 80 },
  { columnId: "gas_content", width: 80, justifyContent: "center" },
  { columnId: "desorption_compressibility", width: 80, justifyContent: "center" },
] as Column[];

export const flowingPressureForecastHeader = {
  date: dictionary.tahk.date,
  pressure: dictionary.tahk.flowingPressure,
  rate: `${dictionary.tahk.contractRate} ${dictionary.tahkUnits.contractRate}`,
};

export const dataTableColumn = Object.keys(dictionary.tahkDataTable).map((key) => {
  return {
    columnId: key,
    width: 80,
    justifyContent: "center",
  };
});

export const seriesKey = ["gasRate", "waterRate"];
export const dataSeriesKey = ["gasRate", "waterRate", "casingPressure", "tubingPressure", "bottomholePressure"];

export const cumulativeSeriesKey = ["cumulativeGasProduction", "cumulativeWaterProduction"];
