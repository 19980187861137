import { useCallback, useState } from "react";
import { DialogEnum } from "../types";
import { EditGroup, NewGroups, NewProject, Project, RemoveDataSets } from "@/model";
import { getIdFromActiveKey } from "../utils";
import { useAppStore } from "@/features/app";
import { shallow } from "zustand/shallow";

type UseDialogSectionProps = {
  refreshProjects: (delayStateChange?: boolean) => Promise<Project[] | undefined>;
  activeKey: string | undefined;
  setActiveKey: (val: string) => void;
};

const useDialogSection = ({ refreshProjects, activeKey, setActiveKey }: UseDialogSectionProps) => {
  const { putRequest, deleteRequest, postRequest, setGroup, setProject, setSelectedDataSet, setSelectedKey } = useAppStore(
    (state) => ({
      putRequest: state.putRequest,
      deleteRequest: state.deleteRequest,
      postRequest: state.postRequest,
      setSelectedKey: state.setSelectedKey,
      setSelectedDataSet: state.setSelectedDataSets,
      setProject: state.setProject,
      setGroup: state.setGroup,
    }),
    shallow
  );

  const [activeDialog, setActiveDialog] = useState<DialogEnum | undefined>();
  const [copyFromSelected, setCopyFromSelected] = useState(false);
  const [newGroupsNames, setNewGroupsNames] = useState<string[]>([]);

  const createNewProject = useCallback(
    async (name: string) => {
      if (!name) return;
      setActiveDialog(undefined);

      const newProject: NewProject = {
        name,
      };

      if (copyFromSelected && activeKey) {
        newProject.copy_from = activeKey;
      }

      try {
        await postRequest("/projects", {}, newProject);

        await refreshProjects();

        setCopyFromSelected(false);

        // force page refresh
        // this is a hacky workaround to get the settings to reload
        window.location.reload();
      } catch (e) {
        console.error(e);
      }
    },
    [copyFromSelected, activeKey, postRequest, refreshProjects]
  );

  const resetState = useCallback(async () => {
    // when user edit project or edit group or delete something reset the state so there's no leftover
    await refreshProjects();

    setProject(undefined);
    setGroup(undefined);
    setSelectedDataSet(undefined);
    setSelectedKey(undefined);
    setActiveKey("");
  }, [refreshProjects, setActiveKey, setGroup, setProject, setSelectedDataSet, setSelectedKey]);

  const editExistingProject = useCallback(
    async (name: string) => {
      if (!name) return;
      // Close the dialog
      setActiveDialog(undefined);
      // Get project and group ID
      if (!activeKey) return;
      const { projectId } = getIdFromActiveKey(activeKey);

      try {
        await putRequest(`/projects/${projectId}`, {}, {
          name,
        } as EditGroup);
        resetState();
      } catch (e) {
        console.error(e);
      }
    },
    [activeKey, putRequest, resetState]
  );

  const deleteProject = useCallback(async () => {
    if (!activeKey) return;
    // Get project and group ID
    const { projectId } = getIdFromActiveKey(activeKey);

    try {
      await deleteRequest(`/projects/${projectId}`);
      await refreshProjects();

      // Close the dialog
      setActiveDialog(undefined);

      // force page refresh
      // this is a hacky workaround to get the settings to reload
      // TODO do this without forcing page refresh
      window.location.reload();
    } catch (e) {
      console.error(e);
    }
  }, [activeKey, deleteRequest, refreshProjects]);

  const removeDataSets = useCallback(async () => {
    // Get project and group ID
    if (!activeKey) return;
    const { projectId, dataSet, groupIds } = getIdFromActiveKey(activeKey);

    try {
      await deleteRequest(`/projects/${projectId}/groups/${groupIds[groupIds.length - 1]}/data_sets`, {}, {
        data_set_ids: dataSet,
      } as RemoveDataSets);
      resetState();
      // Close the dialog
      setActiveDialog(undefined);
    } catch (e) {
      console.error(e);
    }
  }, [activeKey, deleteRequest, resetState]);

  const createNewGroups = useCallback(
    async (latestName: string) => {
      if ((newGroupsNames.length < 1 && !latestName) || !activeKey) return;
      // Close the dialog
      setActiveDialog(undefined);

      const { projectId, groupIds } = getIdFromActiveKey(activeKey);
      const nameList = newGroupsNames;

      if (latestName) {
        nameList.push(latestName);
      }
      // New groups
      const newGroups: NewGroups = {
        names: newGroupsNames,
      };
      if (groupIds.length > 0) {
        newGroups.parent_id = groupIds[groupIds.length - 1];
      }
      try {
        await postRequest(`/projects/${projectId}/groups`, {}, newGroups);
        // Close the dialog
        setActiveDialog(undefined);
        setNewGroupsNames([]);
        resetState();
      } catch (e) {
        console.error(e);
      }
    },
    [activeKey, newGroupsNames, postRequest, resetState]
  );

  const editExistingGroup = useCallback(
    async (name: string) => {
      if (!name || !activeKey) return;

      const { projectId, groupIds } = getIdFromActiveKey(activeKey);
      const latestGroupId = groupIds[groupIds.length - 1];
      try {
        await putRequest(`/projects/${projectId}/groups/${latestGroupId}`, {}, {
          name,
        } as EditGroup);
        resetState();
      } catch (e) {
        console.error(e);
      }
    },
    [activeKey, putRequest, resetState]
  );

  return {
    activeDialog,
    setActiveDialog,
    createNewProject,
    editExistingProject,
    copyFromSelected,
    setCopyFromSelected,
    deleteProject,
    removeDataSets,
    createNewGroups,
    editExistingGroup,

    newGroupsNames,
    setNewGroupsNames,
  };
};

export default useDialogSection;
