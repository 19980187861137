import { DataSet } from "@/model";
import { spadReserveTableColumn, tableHeaderNotationStyle, tableHeaderStyle, tableRowStyle, tableTitleHeaderStyle } from "../../constants";
import { ModuleSpadDeclineArps, SpadAnalysisOutput } from "@/features/modules/spad/decline";

interface ReserveTableProps {
  initOutput?: SpadAnalysisOutput;
  arpsShort: ModuleSpadDeclineArps;
  arpsLong: ModuleSpadDeclineArps;
  subChar: string;
  unit: string;
  dataSets: DataSet[];
}

const useReserveTable = ({ arpsLong, arpsShort, subChar, unit, initOutput, dataSets }: ReserveTableProps) => {
  const actualQ = initOutput?.cumProds[initOutput.cumProds.length - 1];
  const QForecastLowShort = arpsShort?.low?.cumProds[0];
  const QForecastMidShort = arpsShort?.mid?.cumProds[0];
  const QForecastHighShort = arpsShort?.high?.cumProds[0];
  const QForecastLowLong = arpsLong?.low?.cumProds[0];
  const QForecastMidLong = arpsLong?.mid?.cumProds[0];
  const QForecastHighLong = arpsLong?.high?.cumProds[0];

  const tableRows = [
    ["OPS Low", actualQ, QForecastLowShort, arpsShort?.low?.eur, arpsShort?.low?.remRec, arpsShort?.low?.ohip],
    ["OPS Mid", actualQ, QForecastMidShort, arpsShort?.mid?.eur, arpsShort?.mid?.remRec, arpsShort?.mid?.ohip],
    ["OPS High", actualQ, QForecastHighShort, arpsShort?.high?.eur, arpsShort?.high?.remRec, arpsShort?.high?.ohip],
    ["Profile Low", actualQ, QForecastLowLong, arpsLong?.low?.eur, arpsLong?.low?.remRec, arpsLong?.low?.ohip],
    ["Profile Mid", actualQ, QForecastMidLong, arpsLong?.mid?.eur, arpsLong?.mid?.remRec, arpsLong?.mid?.ohip],
    ["Profile High", actualQ, QForecastHighLong, arpsLong?.high?.eur, arpsLong?.high?.remRec, arpsLong?.high?.ohip],
  ];

  const spadReserveTableHeader = [
    {
      text: "Case",
      type: "text",
      style: tableHeaderStyle,
    },
    { text: `Q${subChar} Data end `, type: "text", style: tableHeaderStyle },
    { text: `Q${subChar} Forecast start `, type: "text", style: tableHeaderStyle },
    { text: `EUR `, type: "text", style: tableHeaderStyle },
    { text: `Remaining Recoverable `, type: "text", style: tableHeaderStyle },
    { text: `O${subChar.toUpperCase()}IP `, type: "text", style: tableHeaderStyle },
  ];

  const unitCell = { text: unit, type: "text", style: tableHeaderNotationStyle, nonEditable: true };

  const spadReserveTableHeaderNotation = [
    {
      text: "",
      type: "text",
      style: tableHeaderNotationStyle,
      nonEditable: true,
    },
    unitCell,
    unitCell,
    unitCell,
    unitCell,
    unitCell,
  ];

  const reserveTableRow = [
    {
      rowId: "table-name",
      cells: [{ type: "header", colspan: 6, text: "Reserve Table", style: tableTitleHeaderStyle }],
    },
    {
      rowId: "header",
      cells: spadReserveTableHeader,
    },
    {
      rowId: "notation",
      cells: spadReserveTableHeaderNotation,
    },
    ...tableRows.map((row, indexRow) => {
      return {
        rowId: indexRow,
        cells: row.map((cell, cellIndex) => {
          if (cellIndex === 0)
            return {
              type: "text",
              text: cell,
              nonEditable: true,
              style: tableRowStyle,
            };
          const isExponent = Number(cell) > 1000 || Number(cell) < 0.01;

          return {
            type: "text",
            text: isExponent ? `${Number(cell).toExponential(2)}` : Number(cell).toPrecision(3),
            nonEditable: true,
            style: tableRowStyle,
          };
        }),
      };
    }),
  ];
  const reserveTableCsv = {
    fileName: `${dataSets.map((data) => data.name).join(", ")} Reserve Table.csv`,
    data: [
      //header
      spadReserveTableHeader
        .map((header, index) => {
          return `${header.text} ${spadReserveTableHeaderNotation[index].text}`;
        })
        .join(","),
      // row:
      ...tableRows.map((row) => row.join(",")),
    ].join("\n"),
    isTableCsv: true,
  };
  return {
    reserveTableRow,
    spadReserveTableColumn,
    reserveTableCsv,
  };
};

export default useReserveTable;
