import { fetchApi } from "@/utils/apiFetcher";
import { addWellToGroup } from "@/constants/apiUrl";

export const postWellToGroup = (projectId: string, groupId: string, dataSetIds: string[]) => {
  return fetchApi({
    path: addWellToGroup(projectId, groupId),
    type: "post",
    body: { data_set_ids: dataSetIds },
  });
};
