import React, { useCallback, useEffect, useRef, useState } from "react";
import { TextField } from "@fluentui/react";

import FossilyticsFieldBase, { IFossilyticsFieldBaseProps } from "./FossilyticsFieldBase";

import InputErrorWrapper, { InputErrorWrapperProps } from "../Error/InputErrorWrapper/InputErrorWrapper";

interface FossilyticsNumberFieldProps extends IFossilyticsFieldBaseProps, Omit<InputErrorWrapperProps, "children"> {
  label?: string;
  type?: "int" | "float";
  disabled?: boolean;
  required?: boolean;
  styles?: any;
  min?: number;
  max?: number;
  debounceDelay?: number;
  value?: number | null;
  onChange?: (value: number | undefined) => void;
  customValue?: boolean;
  ariaLabel?: string;
}

function FossilyticsNumberField({
  ariaLabel,
  label,
  suffix,
  type = "float",
  disabled,
  required = false,
  styles,
  min,
  max,
  debounceDelay = 1000,
  value,
  onChange,
  calloutContent,
  customValue,
  errors,
  keyField,
  helpUrl,
}: Readonly<FossilyticsNumberFieldProps>) {
  const [strValue, setStrValue] = useState<string | undefined>(value ? String(value) : undefined);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

  const ref = useRef<any>(null);

  const debounceTimerRef = useRef<NodeJS.Timeout | null>(null);

  const getNumValue = useCallback((rawValue: string | undefined) => {
    let numValue = Number(rawValue);
    let zeroCoerceNumValue = Number(rawValue + "0");

    if (rawValue === "") {
      return NaN;
    } else if (
      isNaN(numValue) &&
      !isNaN(zeroCoerceNumValue)
      // && rawValue && /^[-+]?\d[eE][+-]?$|^[-+]$/.test(rawValue)
    ) {
      // Check for special cases
      return zeroCoerceNumValue;
    } else {
      return numValue;
    }
  }, []);

  useEffect(() => {
    setStrValue((prevStrValue) => {
      if (value === undefined) {
        return prevStrValue;
      } else if (value === null) {
        return "";
      } else if (Number(value) !== getNumValue(prevStrValue)) {
        return String(value);
      } else {
        return prevStrValue;
      }
    });
  }, [value, setStrValue, getNumValue]);

  const onChangeWrapper = useCallback(
    (event: React.FormEvent, rawValue: string | undefined) => {
      let numValue: number;
      let newStrValue = rawValue;
      let emittedValue: number | undefined = undefined;

      setErrorMessage(undefined);

      numValue = getNumValue(rawValue);

      if (!isNaN(numValue)) {
        if (type === "int" && !Number.isInteger(numValue)) {
          numValue = Math.trunc(numValue);
          newStrValue = String(numValue);
        }

        if (min !== undefined && numValue < min) {
          if (customValue) {
            setErrorMessage(`Minimum value is ${min}.`);
            return;
          }
          setErrorMessage(`Minimum value is ${min}.`);
        } else if (max !== undefined && numValue > max) {
          if (customValue) {
            setErrorMessage(`Maximum value is ${max}.`);
            return;
          }
          setErrorMessage(`Maximum value is ${max}.`);
        } else {
          emittedValue = numValue;
        }
      } else if (rawValue === "") {
        if (required) {
          setErrorMessage("Required.");
        }
      } else {
        // Input value is NaN, show error message.
        setErrorMessage("Input is not a valid number.");
      }

      if (onChange) {
        if (debounceDelay) {
          if (debounceTimerRef.current) {
            clearTimeout(debounceTimerRef.current);
          }

          debounceTimerRef.current = setTimeout(() => onChange(emittedValue), debounceDelay);
        } else {
          onChange(emittedValue);
        }
      }

      setStrValue(newStrValue);
    },
    [customValue, debounceDelay, getNumValue, max, min, onChange, required, type]
  );

  // return this as a base if we don't have keyfield so old component that haven't implemented error input validation can skip the logic
  if (!keyField) {
    return (
      <FossilyticsFieldBase helpUrl={helpUrl} calloutContent={calloutContent}>
        {(onRenderLabel, baseDisabled) => (
          <TextField
            ariaLabel={ariaLabel}
            type="text"
            label={label}
            suffix={suffix}
            disabled={(!ref.current?.state?.isFocused && baseDisabled) || disabled}
            componentRef={ref}
            required={required}
            value={strValue}
            onChange={(event, v) => onChangeWrapper(event, v)}
            styles={styles}
            onRenderLabel={onRenderLabel}
            errorMessage={errorMessage}
            onRenderInput={(props, defaultRender) => {
              return defaultRender ? defaultRender({ ...props, value: strValue ?? "" }) : <></>;
            }}
            onWheel={(e: any) => e.target.blur()}
          />
        )}
      </FossilyticsFieldBase>
    );
  }

  return (
    <InputErrorWrapper errors={errors} keyField={keyField}>
      {(errorValidation) => (
        <FossilyticsFieldBase helpUrl={helpUrl} calloutContent={calloutContent}>
          {(onRenderLabel, baseDisabled) => (
            <TextField
              onWheel={(e: any) => e.target.blur()}
              ariaLabel={ariaLabel}
              type="number"
              label={label}
              suffix={suffix}
              disabled={(!ref.current?.state?.isFocused && baseDisabled) || disabled}
              componentRef={ref}
              required={required}
              value={strValue}
              onChange={(event, v) => onChangeWrapper(event, v)}
              styles={styles}
              onRenderLabel={onRenderLabel}
              errorMessage={errorMessage ?? errorValidation}
              onRenderInput={(props, defaultRender) => {
                return defaultRender ? defaultRender({ ...props, value: strValue ?? "" }) : <></>;
              }}
            />
          )}
        </FossilyticsFieldBase>
      )}
    </InputErrorWrapper>
  );
}

export default FossilyticsNumberField;
