type CustomErrorParam = {
  code: number;
  detail?: ErrorDetail;
  message?: string;
  severity: "error" | "warning" | "info";
};

export class CustomError extends Error {
  code: number;
  detail?: ErrorDetail;
  message: string;
  severity: "error" | "warning" | "info";
  constructor({ message = "", code, detail, severity }: CustomErrorParam) {
    super(message);
    this.code = code;
    this.message = message;
    this.detail = detail;
    this.severity = severity;
  }
}

type ErrorDetail = {
  module: string;
  issue: string;
};

export type GenericApiParam<Request = any, QueryParams = any> = {
  path: string;
  queryStringParameters?: QueryParams;
  responseType?: string;
  // configure response
  config?: { [k: string]: any };
  type?: "post" | "put" | "get" | "patch" | "del";
  body?: Request;
};

export type GenericApiResponse<Response> = {
  data?: Response;
  error?: CustomError;
  // type is axios headers but not available
  headers?: any;
};
