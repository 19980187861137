import React, { useCallback } from "react";
import { Dropdown, IPivotStyles, IStyleSet, Pivot, PivotItem, Stack } from "@fluentui/react";

import { UncertainParamDistribution, MonteCarloInputApplication, UncertainParamType, Output } from "@/models/koldun";
import { getNewUncertainList } from "../../../utils";
import { DistributionOptions, applicationOptions } from "./model";
import _ from "lodash";
import CommonDistribution from "@/modules/koldun/mcsim/csg/csg/Components/ModuleKoldunMcsimCsgInputGridTab/Components/UncertainParamInputs/CommonDistribution";

import { ErrorValidationDetail } from "@/models/ErrorInputValidation";
import InputErrorWrapper from "@/components/Error/InputErrorWrapper/InputErrorWrapper";

const pivotStyles: Partial<IStyleSet<IPivotStyles>> = {
  link: {
    padding: 0,
    marginRight: 20,
  },
};

interface MonteCarloInputsProps {
  name: string;
  units: string;
  onChange: (value: UncertainParamType) => void;
  output: Output;
  setOutput: React.Dispatch<React.SetStateAction<Output | null>>;
  measureNum: number;
  setMeasureNum: React.Dispatch<React.SetStateAction<number>>;
  errorInputValidation?: ErrorValidationDetail[];
  keyField?: string;
}

const MonteCarloInputs = ({
  name,
  units,
  onChange,
  output,
  setOutput,
  measureNum,
  setMeasureNum,
  errorInputValidation,
  keyField,
}: MonteCarloInputsProps) => {
  const uncertainKey = "permeability_md";
  const value = output.measures[measureNum].measure_layers[0].uncertain_list[uncertainKey];
  const onDistributionChange = useCallback(
    (v: UncertainParamDistribution) => {
      switch (v) {
        case UncertainParamDistribution.FIXED:
          onChange({
            distribution: UncertainParamDistribution.FIXED,
            application: value.application,
            value: 50,
          });
          break;
        case UncertainParamDistribution.UNIFORM:
          onChange({
            distribution: UncertainParamDistribution.UNIFORM,
            application: value.application,
            low: 25,
            high: 50,
          });
          break;
        case UncertainParamDistribution.TRIANGULAR:
          onChange({
            distribution: UncertainParamDistribution.TRIANGULAR,
            application: value.application,
            left: 25,
            mode: 40,
            right: 50,
          });
          break;
        case UncertainParamDistribution.LOG_NORMAL:
          onChange({
            distribution: UncertainParamDistribution.LOG_NORMAL,
            application: value.application,
            mean: 40,
            sigma: 10,
          });
          break;
        case UncertainParamDistribution.NORMAL:
          onChange({
            distribution: UncertainParamDistribution.NORMAL,
            application: value.application,
            mean: 40,
            std: 10,
          });
          break;
      }
    },
    [onChange, value.application]
  );

  const numberArray: number[] = [];
  for (let i = 1; i <= output.num_of_measures; i++) {
    numberArray.push(i);
  }

  const handleApplication = useCallback(
    (v: MonteCarloInputApplication) => {
      onChange({
        ...value,
        application: v,
      });
    },
    [onChange, value]
  );

  const handleOutputChange = (layer: number, key: string, value: any) => {
    setOutput((prev: Output | null) => {
      if (!prev) return prev;
      const prevOutput = _.cloneDeep(prev);
      prevOutput.measures[measureNum].measure_layers[layer].uncertain_list = getNewUncertainList(
        prevOutput.measures[measureNum].measure_layers[layer].uncertain_list,
        uncertainKey,
        key,
        value
      );
      return prevOutput;
    });
  };

  return (
    <Stack horizontal tokens={{ childrenGap: 20 }} style={{ marginBottom: "10px" }}>
      <div>
        <Stack horizontal tokens={{ childrenGap: 20 }} style={{ width: 425 }}>
          <Stack grow={0.5} className="equal-size">
            <InputErrorWrapper errors={errorInputValidation} keyField={keyField}>
              {(validationMsg) => {
                return (
                  <Dropdown
                    label={`${name} distribution`}
                    options={DistributionOptions}
                    selectedKey={value.distribution}
                    errorMessage={validationMsg}
                    onChange={(_, v) => (v !== undefined ? onDistributionChange(v.key as UncertainParamDistribution) : null)}
                  />
                );
              }}
            </InputErrorWrapper>
          </Stack>
          <Stack grow={0.5} className="equal-size">
            <Dropdown
              label="Application"
              options={applicationOptions}
              selectedKey={value.application}
              onChange={(_, v) => (v !== undefined ? handleApplication(v.key as MonteCarloInputApplication) : null)}
            />
          </Stack>
        </Stack>
      </div>
      <div style={{ marginTop: "-45px" }}>
        <Pivot
          styles={pivotStyles}
          selectedKey={measureNum.toString()}
          onLinkClick={(v) => {
            if (v?.props?.itemKey !== undefined) {
              setMeasureNum(parseInt(v.props.itemKey));
            }
          }}
        >
          {numberArray.map((item, i) => {
            return (
              <PivotItem
                key={i.toString()}
                headerText={`Measure ${item}`}
                headerButtonProps={{
                  "data-order": i,
                }}
                itemKey={i.toString()}
              >
                <CommonDistribution
                  value={value}
                  measureNum={measureNum}
                  output={output}
                  units={units}
                  updateUncertainList={handleOutputChange}
                  uncertainKey={uncertainKey}
                  errorInputValidation={errorInputValidation}
                  keyField={keyField}
                />
              </PivotItem>
            );
          })}
        </Pivot>
      </div>
    </Stack>
  );
};

export default MonteCarloInputs;
