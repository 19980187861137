import { useCallback, useEffect, useRef } from "react";
import { shallow } from "zustand/shallow";
import _ from "lodash";
import { useNavigate } from "react-router-dom";

import { useAppStore } from "@/features/app";

// constant
import { getUserCurrentTaskApi } from "@/constants/apiUrl";
import routeUrlMap from "@/constants/routeUrl";

// Model
import { APIResponse } from "@/models/APIGeneric";
import { CurrentUserTask } from "@/models/MultipleTaskRestriction";
import { DataSet, ModuleId, Project } from "@/model";

// utils
import useInterval from "./useInterval";

const defaultInterval = 10000;

// send interval for easier testing
const useMultipleTaskRestriction = (interval: number = defaultInterval) => {
  const navigate = useNavigate();

  const isFetchingCurrent = useRef(false);
  const firstLoadRun = useRef(false);

  const {
    setCurrentUserTask,
    setSelectedDataSets,
    setGroup,
    setProject,
    setCurrentModule,
    syncGetRequest,

    currentModule,
    project,
    group,
    selectedDataSets,
    projects,
    dataSets,
    currentUserTask,

    isLoadingBlocker,
  } = useAppStore(
    (state) => ({
      getRequest: state.getRequest,
      setCurrentUserTask: state.setCurrentUserTask,
      setSelectedDataSets: state.setSelectedDataSets,
      setGroup: state.setGroup,
      setProject: state.setProject,
      setCurrentModule: state.setCurrentModule,
      syncGetRequest: state.syncGetRequest,

      currentModule: state.currentModule,
      group: state.group,
      project: state.project,
      selectedDataSets: state.selectedDataSets,
      isLoading: state.isLoading,
      projects: state.projects,
      dataSets: state.dataSets,
      currentUserTask: state.currentUserTask,
      isLoadingBlocker: state.isLoadingBlocker,
    }),
    shallow
  );

  // compare state data sets with api data sets
  // send selected data set as param to prevent unnecessary rerender, because we call this function on useEffect
  const isDataSetMatch = (dataSetIds: string[], selectedDataSets?: DataSet | DataSet[]) => {
    if (!selectedDataSets) return false;
    if (Array.isArray(selectedDataSets)) {
      const mappedSelectedDataSet = selectedDataSets.filter((dataSet) => dataSet !== undefined).map((dataSet) => dataSet.id);
      return _.isEmpty(_.xor(mappedSelectedDataSet, dataSetIds));
    }
    return dataSetIds.indexOf(selectedDataSets?.id ?? "") > -1;
  };

  const getCurrentTask = useCallback(async () => {
    // this means there's a current polling task, so we don't need to check loading state
    if (isLoadingBlocker) return;
    try {
      isFetchingCurrent.current = true;
      const userCurrentTask = await syncGetRequest<APIResponse<CurrentUserTask>>(getUserCurrentTaskApi, undefined, undefined, {
        // add this so we can get the http status
        response: true,
      });

      // for status 204, the data gonna be empty and we dont need to do anything
      if (userCurrentTask && userCurrentTask.status === 200) {
        const userCurrentTaskData = userCurrentTask.data;

        // only set user current task data if user not on the target modules, project, group, data set,
        // Check if user is already on the correct page.
        const groupId = group?.id ?? project?.groups?.[0].id ?? "";

        const dataSetMatch = isDataSetMatch(userCurrentTaskData.data_set_ids, selectedDataSets);
        if (
          currentModule !== userCurrentTaskData.module ||
          groupId !== userCurrentTaskData.group_id ||
          project?.id !== userCurrentTaskData.project_id ||
          !dataSetMatch
        ) {
          // set data to state so we can access them later
          setCurrentUserTask(userCurrentTaskData);

          // map to find project from state since we need the whole data
          const projectFromState: Project | undefined = projects?.find((proj) => proj.id === userCurrentTaskData.project_id);

          // update to our store
          if (currentModule !== userCurrentTaskData.module) setCurrentModule(userCurrentTaskData.module as ModuleId); // eslint-disable-line react-hooks/exhaustive-deps
          if (projectFromState) {
            if (project?.id !== userCurrentTaskData.project_id) setProject(projectFromState);
            // only set to group if there is more than 1 data set
            if (groupId !== userCurrentTaskData.group_id && userCurrentTaskData.data_set_ids.length > 1) {
              // find group inside the project groups
              const groupFromState = projectFromState.groups.find((group) => group.id === userCurrentTaskData.group_id);
              if (groupFromState) setGroup(groupFromState);
            }
          }

          if (!dataSetMatch) {
            // map data set with full data set from state
            const fullDataSetFromState: DataSet[] = userCurrentTaskData.data_set_ids.map((dataSetId) => {
              const correctDataSet = dataSets?.find((dataSet) => dataSet.id === dataSetId);
              return correctDataSet as DataSet;
            });

            // need to differentiate between array or not because current state logic accept object and array
            if (fullDataSetFromState.length > 1) {
              setSelectedDataSets(fullDataSetFromState);
            } else {
              setSelectedDataSets(fullDataSetFromState[0]);
            }
          }
          const moduleDestinationPage = `/modules/${routeUrlMap[userCurrentTaskData.module as ModuleId]}`;
          // redirect to destination page where user currently have a task, and different module
          navigate(moduleDestinationPage);
        } else {
          setCurrentUserTask(null);
        }
      } else if (currentUserTask) setCurrentUserTask(null);
    } catch (error) {
      console.log(error);
    } finally {
      isFetchingCurrent.current = false;
    }
    // remove the function from dependency array in future because initialy we don't need it
    // or disable react exhaustive deps for line below, since it is not really stable
    // there's also some feedback discussion forum e.g https://github.com/facebook/react/issues/14920
  }, [
    isLoadingBlocker,
    syncGetRequest,
    currentUserTask,
    setCurrentUserTask,
    group?.id,
    project?.groups,
    project?.id,
    selectedDataSets,
    currentModule,
    projects,
    setCurrentModule,
    navigate,
    setProject,
    setGroup,
    dataSets,
    setSelectedDataSets,
  ]);

  // adding this function to call current api immediately on first load the app
  useEffect(() => {
    if (firstLoadRun.current) {
      return;
    }
    // wait for data initialization first before running this api for the first time
    if (projects && dataSets) {
      getCurrentTask();
      firstLoadRun.current = true;
    }
  }, [getCurrentTask, projects, dataSets, group]);

  useInterval(() => {
    if (!isFetchingCurrent.current) getCurrentTask();
  }, interval);

  return {};
};

export default useMultipleTaskRestriction;
