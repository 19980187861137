import { FossilyticsChartAxis, FossilyticsChartCustomDataZoom, FossilyticsChartSeries } from "@/components/FossilyticsChart";
import { useTheme } from "@mui/material/styles";
import { useMemo } from "react";

type UseLogRateProps = {
  logFlowSeries: FossilyticsChartSeries[];
  unit: string;
};

const useLogRate = ({ logFlowSeries, unit }: UseLogRateProps) => {
  const { palette } = useTheme();

  const xAxes = useMemo<FossilyticsChartAxis[]>(() => {
    let name = "Days";
    let type = "value";

    return [{ name: name, type: type, color: palette.common.black, min: 0 }];
  }, [palette.common.black]);

  const flowYAxesLog = useMemo<FossilyticsChartAxis[]>(
    () => [{ name: `Rates (${unit}/d)`, type: "log", color: palette.common.black }],
    [palette.common.black, unit]
  );

  const findLastValueInFlowLinesForlogqgt = useMemo(() => {
    const newLine = logFlowSeries?.filter((line) => line.name === "Economic cutoff")[0]?.data ?? [];
    const logFlowSeriesLastPoint = logFlowSeries?.filter((seriesItem) => seriesItem.name === "Flow rates")[0]?.data ?? [];

    let percentage = 100;
    if (newLine && newLine.length > 0 && logFlowSeriesLastPoint.length > 0) {
      const newLineLastPoint = newLine[1][0] as number;
      const lastNumberInData = logFlowSeriesLastPoint[logFlowSeriesLastPoint.length - 1][0];
      if ((lastNumberInData * 3) / newLineLastPoint >= 1) {
        return;
      }
      if ((lastNumberInData * 3) / newLineLastPoint < 1) {
        percentage = Math.floor(((lastNumberInData * 3) / newLineLastPoint) * 100);
      }
    }
    return percentage;
  }, [logFlowSeries]);

  const logRateFlowCustomZoom = useMemo<FossilyticsChartCustomDataZoom>(() => {
    return {
      customDataZoom: true,
      dataZoomPercentage: findLastValueInFlowLinesForlogqgt as number,
    };
  }, [findLastValueInFlowLinesForlogqgt]);

  return { logRateFlowCustomZoom, xAxes, flowYAxesLog };
};
export default useLogRate;
