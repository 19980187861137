import CircularProgress from "@mui/material/CircularProgress";

const LoadingIndicator = () => {
  return (
    <div style={{ display: "flex", justifyContent: "center", marginTop: 20 }}>
      <CircularProgress size={25} />
    </div>
  );
};

export default LoadingIndicator;
