import { useCallback } from "react";

import { Dropdown, IDropdownOption, IStyle, Stack } from "@fluentui/react";
import FossilyticsNumberField from "./fields/FossilyticsNumberField";
import { ModuleTahkForecastUncertainParamDistribution, ModuleTahkForecastUncertainParamType } from "../modules/tahk/forecast/gasoil/model";

interface TahkUncertainParamInputProps {
  name: string;
  units: string;
  value: ModuleTahkForecastUncertainParamType;
  onChange: (value: ModuleTahkForecastUncertainParamType) => void;
  hideDistributionSelect: boolean;
  disabled?: boolean;
  disableDistributionSelect?: boolean;
  module?: string;
  min?: number;
  max?: number;
  customValue?: boolean;
}
const columnWidthCss = "calc((100% - 40px) / 3)";

const uncertainParamTypeOptions = [
  { key: ModuleTahkForecastUncertainParamDistribution.FIXED, text: "Fixed" },
  { key: ModuleTahkForecastUncertainParamDistribution.UNIFORM, text: "Uniform" },
  { key: ModuleTahkForecastUncertainParamDistribution.TRIANGULAR, text: "Triangular" },
  { key: ModuleTahkForecastUncertainParamDistribution.LOG_NORMAL, text: "Log Normal" },
  { key: ModuleTahkForecastUncertainParamDistribution.NORMAL, text: "Normal" },
] as IDropdownOption[];
const TahkUncertainParamInput = ({
  name,
  units,
  value,
  onChange,
  disabled,
  disableDistributionSelect,
  hideDistributionSelect,
  module,
  min,
  max,
  customValue,
}: TahkUncertainParamInputProps) => {
  const onDistributionChange = useCallback(
    (v: any) => {
      switch (v) {
        case ModuleTahkForecastUncertainParamDistribution.FIXED:
          onChange({ distribution: ModuleTahkForecastUncertainParamDistribution.FIXED, value: 0 });
          break;
        case ModuleTahkForecastUncertainParamDistribution.UNIFORM:
          onChange({ distribution: ModuleTahkForecastUncertainParamDistribution.UNIFORM, low: 0, high: 0 });
          break;
        case ModuleTahkForecastUncertainParamDistribution.TRIANGULAR:
          onChange({
            distribution: ModuleTahkForecastUncertainParamDistribution.TRIANGULAR,
            left: 0,
            mode: 0,
            right: 0,
          });
          break;
        case ModuleTahkForecastUncertainParamDistribution.LOG_NORMAL:
          onChange({ distribution: ModuleTahkForecastUncertainParamDistribution.LOG_NORMAL, mean: 0, sigma: 0 });
          break;
        case ModuleTahkForecastUncertainParamDistribution.NORMAL:
          onChange({ distribution: ModuleTahkForecastUncertainParamDistribution.NORMAL, mean: 0, std: 0 });
          break;
      }
    },
    [onChange]
  );
  const fieldStyle = {
    flex: "1",
    width: 250,
  } as React.CSSProperties;

  return (
    <Stack horizontal tokens={{ childrenGap: 20 }}>
      {!hideDistributionSelect && (
        <Dropdown
          label={`${name} distribution`}
          options={uncertainParamTypeOptions}
          styles={{ root: { width: columnWidthCss } }}
          disabled={disabled || disableDistributionSelect}
          selectedKey={value.distribution}
          onChange={(_, v) => (v !== undefined ? onDistributionChange(v.key) : null)}
        />
      )}

      {value.distribution === ModuleTahkForecastUncertainParamDistribution.FIXED ? (
        <>
          <FossilyticsNumberField
            label={`${name}`}
            suffix={units}
            styles={{ root: fieldStyle as IStyle }}
            disabled={disabled}
            min={min}
            max={max}
            value={value.value}
            customValue={customValue}
            onChange={(v) =>
              v !== undefined
                ? onChange({
                    ...value,
                    value: v,
                  })
                : null
            }
          />
        </>
      ) : value.distribution === ModuleTahkForecastUncertainParamDistribution.UNIFORM ? (
        <>
          <FossilyticsNumberField
            label={`${name} low`}
            suffix={units}
            styles={{ root: fieldStyle as IStyle }}
            disabled={disabled}
            value={value.low}
            onChange={(v) =>
              v !== undefined
                ? onChange({
                    ...value,
                    low: v,
                  })
                : null
            }
          />
          <FossilyticsNumberField
            label={`${name} high`}
            suffix={units}
            styles={{ root: fieldStyle as IStyle }}
            disabled={disabled}
            value={value.high}
            onChange={(v) =>
              v !== undefined
                ? onChange({
                    ...value,
                    high: v,
                  })
                : null
            }
          />
        </>
      ) : value.distribution === ModuleTahkForecastUncertainParamDistribution.TRIANGULAR ? (
        <>
          <FossilyticsNumberField
            label={`${name} left`}
            suffix={units}
            styles={{ root: fieldStyle as IStyle }}
            disabled={disabled}
            value={value.left}
            onChange={(v) =>
              v !== undefined
                ? onChange({
                    ...value,
                    left: v,
                  })
                : null
            }
          />
          <FossilyticsNumberField
            label={`${name} mode`}
            suffix={units}
            styles={{ root: fieldStyle as IStyle }}
            disabled={disabled}
            value={value.mode}
            onChange={(v) =>
              v !== undefined
                ? onChange({
                    ...value,
                    mode: v,
                  })
                : null
            }
          />
          <FossilyticsNumberField
            label={`${name} right`}
            suffix={units}
            styles={{ root: fieldStyle as IStyle }}
            disabled={disabled}
            value={value.right}
            onChange={(v) =>
              v !== undefined
                ? onChange({
                    ...value,
                    right: v,
                  })
                : null
            }
          />
        </>
      ) : value.distribution === ModuleTahkForecastUncertainParamDistribution.LOG_NORMAL ? (
        <>
          <FossilyticsNumberField
            label={`${name} mean`}
            suffix={units}
            styles={{ root: fieldStyle as IStyle }}
            disabled={disabled}
            value={value.mean}
            onChange={(v) =>
              v !== undefined
                ? onChange({
                    ...value,
                    mean: v,
                  })
                : null
            }
          />
          <FossilyticsNumberField
            label={`${name} sigma`}
            suffix={units}
            styles={{ root: fieldStyle as IStyle }}
            disabled={disabled}
            value={value.sigma}
            onChange={(v) =>
              v !== undefined
                ? onChange({
                    ...value,
                    sigma: v,
                  })
                : null
            }
          />
        </>
      ) : value.distribution === ModuleTahkForecastUncertainParamDistribution.NORMAL ? (
        <>
          <FossilyticsNumberField
            label={`${name} mean`}
            suffix={units}
            styles={{ root: fieldStyle as IStyle }}
            disabled={disabled}
            value={value.mean}
            onChange={(v) =>
              v !== undefined
                ? onChange({
                    ...value,
                    mean: v,
                  })
                : null
            }
          />
          <FossilyticsNumberField
            label={`${name} standard deviation`}
            suffix={units}
            styles={{ root: fieldStyle as IStyle }}
            disabled={disabled}
            value={value.std}
            onChange={(v) =>
              v !== undefined
                ? onChange({
                    ...value,
                    std: v,
                  })
                : null
            }
          />
        </>
      ) : null}
    </Stack>
  );
};

export default TahkUncertainParamInput;
