import { Stack } from "@fluentui/react";

import useThemeStyling from "@/utils/useThemeStyling";
import DataViewInputSetting from "./DataViewInputSetting";
import DataViewCalculation from "./DataViewCalculation";

import useGazAutoRtaDataView from "../../hooks/rtaDataView/useGazAutoRtaDataView";
import LoadingIndicator from "@/components/LoadingIndicator";

type AutoRtaDataViewContainerProps = {
  isLoading: boolean;
  currentTab: number;
};

const AutoRtaDataViewContainer = ({ isLoading }: AutoRtaDataViewContainerProps) => {
  const { elevatedCard } = useThemeStyling();
  const { dataViewInput, dataViewCalculate, updateDatViewInputField, updateDatViewStringVal, loadDataView } = useGazAutoRtaDataView();

  const loadingState = isLoading || loadDataView;

  return (
    <Stack horizontal style={{ height: "100%" }} tokens={{ padding: 20, childrenGap: 20 }}>
      <Stack tokens={{ childrenGap: 20 }}>
        <DataViewInputSetting
          dataViewInput={dataViewInput}
          dataViewCalculate={dataViewCalculate}
          updateDatViewInputField={updateDatViewInputField}
          updateDatViewStringVal={updateDatViewStringVal}
          isLoading={loadingState}
        />
      </Stack>
      <Stack className="PivotWrapper" style={elevatedCard} grow={1}>
        {dataViewCalculate && <DataViewCalculation dataViewCalculate={dataViewCalculate} isLoading={loadingState} />}
      </Stack>
    </Stack>
  );
};

const DataViewContainerWrapper = (props: AutoRtaDataViewContainerProps) => {
  if (props.currentTab !== 0 || props.isLoading) return <LoadingIndicator />;
  return <AutoRtaDataViewContainer {...props} />;
};

export default DataViewContainerWrapper;
