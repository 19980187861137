import { calculateGazAutoRtaAnalysisUrl, validateGazAutoRtaAnalysisUrl } from "@/constants/apiUrl";

import { createPoll } from "@/utils/apiFetcher";
import { AnalysisCalculation, GazAutoRtaAnalysis } from "../Analysis";
import { GazAutoRtaInputGrid } from "../InputGrid";

type AnalysisQuery = {
  data_set_ids: string[];
};

type AnalysisPayload = {
  analysis: GazAutoRtaAnalysis;
  inputs: GazAutoRtaInputGrid;
};

export const pollValidateAnalysisGazAutoRta = (payload: AnalysisPayload, query: AnalysisQuery) => {
  return createPoll<GazAutoRtaAnalysis, AnalysisPayload>({
    path: validateGazAutoRtaAnalysisUrl,
    body: payload,
    queryStringParameters: query,
  });
};
export const pollCalculateAnalysisGazAutoRta = (payload: AnalysisPayload, query: AnalysisQuery) => {
  return createPoll<AnalysisCalculation, AnalysisPayload>({
    path: calculateGazAutoRtaAnalysisUrl,
    body: payload,
    queryStringParameters: query,
  });
};
