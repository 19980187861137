import React from "react";
import { Stack, Text, Checkbox, DetailsList, SelectionMode } from "@fluentui/react";
import { Column, ReactGrid, Row, CellChange } from "@silevis/reactgrid";

// utils
import useThemeStyling from "@/utils/useThemeStyling";
import { formatNumber } from "@/util";

// models
import { ErrorValidationDetail } from "@/models/ErrorInputValidation";
import { GazAutoRtaForecast } from "@/models/gaz/autoRta";

//component
import FossilyticsNumberField from "@/components/fields/FossilyticsNumberField";

// constant
import { parameterTableColumns } from "../../constants";
import LoadingIndicator from "@/components/LoadingIndicator";
import { SummaryCard } from "@/models/Generic";

export type AutoRtaForecastSettingProps = {
  onChangeInput: (payload: { [key: string]: any }) => void;
  forecastInput: GazAutoRtaForecast;
  onChangeTableInput: (changes: CellChange[]) => void;
  errorInputValidation?: ErrorValidationDetail[];
  forecastEventsRow: Row[];
  autoRtaForecastParameter?: SummaryCard[];
  isLoading: boolean;
};

const inputColumns = [
  { columnId: 0, width: 150 },
  { columnId: 1, width: 150 },
] as Column[];

const AutoRtaForecastSetting = ({
  onChangeInput,
  forecastInput,
  onChangeTableInput,
  errorInputValidation,
  forecastEventsRow,
  autoRtaForecastParameter = [],
  isLoading,
}: AutoRtaForecastSettingProps) => {
  const { elevatedCard, theme } = useThemeStyling();

  return (
    <Stack tokens={{ childrenGap: 20 }}>
      <Stack.Item style={elevatedCard}>
        <Text styles={{ root: { color: theme.palette.themePrimary } }}>Forecast Settings</Text>

        <Stack
          tokens={{
            childrenGap: 20,
          }}
        >
          <FossilyticsNumberField
            type="float"
            label="Economic Cutoff"
            suffix="MMscf/D"
            value={forecastInput.economic_cutoff}
            debounceDelay={1000}
            onChange={(v) => {
              onChangeInput({
                economic_cutoff: v,
              });
            }}
            disabled={isLoading}
            errors={errorInputValidation}
          />

          <Checkbox
            label="Smart Fill"
            checked={forecastInput.smart_fill}
            disabled={isLoading}
            onChange={(_, v) => {
              onChangeInput({
                smart_fill: v,
              });
            }}
          />

          <div style={{ position: "relative", height: 250, width: 315 }}>
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                overflowX: "hidden",
                overflowY: "scroll",
              }}
            >
              <ReactGrid columns={inputColumns} rows={forecastEventsRow} enableRangeSelection stickyTopRows={1} onCellsChanged={onChangeTableInput} />
            </div>
          </div>
        </Stack>
      </Stack.Item>
      <Stack.Item grow={1} style={elevatedCard}>
        {isLoading ? (
          <LoadingIndicator />
        ) : (
          <DetailsList
            compact
            selectionMode={SelectionMode.none}
            columns={parameterTableColumns}
            items={autoRtaForecastParameter.map((item: any, i) => {
              return {
                parameter: item.parameter,
                value: formatNumber(Number(Number(item.value).toFixed(2))),
                units: item.unit,
              };
            })}
            className="GazAutoRTAParameterTable"
          />
        )}
      </Stack.Item>
    </Stack>
  );
};

export default AutoRtaForecastSetting;
