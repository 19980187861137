import { ReactNode } from "react";

import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import styled from "@emotion/styled";

type CustomModalProps = {
  isVisible: boolean;
  children: ReactNode;

  primaryButton: ButtonProps;
  secondaryButton?: ButtonProps;
};

type ButtonProps = {
  onClick: () => void;
  label: string;
};

const Container = styled.div`
  background: white;
  width: 500px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding-right: 15px;
  padding-left: 15px;
  border-radius: 10px;

  :focus-visible {
    outline: none;
  }
`;

const CustomModal = ({ isVisible, primaryButton, secondaryButton, children }: CustomModalProps) => {
  return (
    <div>
      <Modal open={isVisible} aria-labelledby="user-inactive-modal">
        <Container>
          {children}

          <div
            style={{
              float: "right",
              marginTop: 20,
              marginBottom: 20,
            }}
          >
            {secondaryButton && <Button onClick={() => secondaryButton.onClick()}>{secondaryButton.label}</Button>}
            <Button
              style={{
                color: "white",
                marginLeft: 20,
              }}
              variant="contained"
              onClick={() => primaryButton.onClick()}
            >
              {primaryButton.label}
            </Button>
          </div>
        </Container>
      </Modal>
    </div>
  );
};

export default CustomModal;
