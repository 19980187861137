import dictionary from "@/constants/dictionary";
import CustomModal from "../Modal";
import { useTimer } from "@/utils/dateTime";
import { useEffect, useMemo, useRef } from "react";

type SessionModalProps = {
  onClickStay: () => void;
  onClickLogout: () => void;
};
const SessionModal = ({ onClickLogout, onClickStay }: SessionModalProps) => {
  // five minutes in ms
  const timeLeft = useTimer(Date.now() + 1000 * 60 * 5);
  const haveStarted = useRef(false);

  useEffect(() => {
    if (haveStarted.current) return;
    if (timeLeft?.minutes !== "0" || timeLeft?.seconds !== "0") {
      haveStarted.current = true;
    }
  }, [timeLeft]);

  useEffect(() => {
    if (timeLeft?.minutes === "0" && timeLeft?.seconds === "0" && haveStarted.current) {
      onClickLogout();
    }
  }, [onClickLogout, timeLeft]);

  const countdown = useMemo(() => {
    const mins = timeLeft?.minutes !== "0" ? `${timeLeft?.minutes} ${dictionary.sessionInactiveModal.minutes}` : "";
    return `${mins} ${timeLeft.seconds} ${dictionary.sessionInactiveModal.seconds}`;
  }, [timeLeft.minutes, timeLeft.seconds]);

  return (
    <CustomModal
      isVisible
      primaryButton={{
        label: dictionary.sessionInactiveModal.stayButton,
        onClick: onClickStay,
      }}
      secondaryButton={{
        label: dictionary.sessionInactiveModal.logoutButton,
        onClick: onClickLogout,
      }}
    >
      <div>
        <h4>{dictionary.sessionInactiveModal.title}</h4>
        <span>{dictionary.sessionInactiveModal.description(countdown)}</span>
      </div>
    </CustomModal>
  );
};

export default SessionModal;
