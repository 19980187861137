import useThemeStyling from "@/utils/useThemeStyling";
import { useMemo } from "react";
import { FossilyticsChartAxis, FossilyticsChartSeries } from "@/components/FossilyticsChart";
import { InputGridCalculation } from "@/models/gaz/autoRta";

const useGazAutoRtaOilPvtCharts = (calculateValue: InputGridCalculation) => {
  const { theme } = useThemeStyling();

  // Bo
  const oilPvtBoXAxes = useMemo<FossilyticsChartAxis[]>(
    () => [
      {
        name: "Pressure (psia)",
        type: "value",
        color: theme.palette.black,
      },
    ],
    [theme]
  );

  const oilPvtBoYAxes = useMemo<FossilyticsChartAxis[]>(
    () => [
      {
        name: "Formation volume factor (rb/stb)",
        type: "value",
        color: theme.palette.black,
        show: true,
      },
    ],
    [theme]
  );

  const oilPvtBoSeries = useMemo<FossilyticsChartSeries[]>(
    () =>
      calculateValue
        ? [
            {
              name: `Oil Bo`,
              type: "line",
              hideSymbol: true,
              color: theme.palette.orangeLighter,
              lineWidth: 2,
              z: 2,
              data: calculateValue.oil_pvt_charts.pressure.map((p, j) => [p, calculateValue.oil_pvt_charts.form_vol_factor[j]]),
            },
          ]
        : [],
    [calculateValue, theme]
  );
  // Mu
  const oilPvtMuXAxes = useMemo<FossilyticsChartAxis[]>(
    () => [
      {
        name: "Pressure (psia)",
        type: "value",
        color: theme.palette.black,
      },
    ],
    [theme]
  );

  const oilPvtMuYAxes = useMemo<FossilyticsChartAxis[]>(
    () => [
      {
        name: "Viscosity (cP)",
        type: "value",
        color: theme.palette.black,
        show: true,
      },
    ],
    [theme]
  );

  const oilPvtMuSeries = useMemo<FossilyticsChartSeries[]>(
    () =>
      calculateValue
        ? [
            {
              name: `Oil Mu`,
              type: "line",
              hideSymbol: true,
              color: theme.palette.orangeLighter,
              lineWidth: 2,
              z: 2,
              data: calculateValue.oil_pvt_charts.pressure.map((p, j) => [p, calculateValue.oil_pvt_charts.viscosity[j]]),
            },
          ]
        : [],
    [calculateValue, theme]
  );

  // po - density
  const oilPvtpoXAxes = useMemo<FossilyticsChartAxis[]>(
    () => [
      {
        name: "Pressure (psia)",
        type: "value",
        color: theme.palette.black,
      },
    ],
    [theme]
  );

  const oilPvtpoYAxes = useMemo<FossilyticsChartAxis[]>(
    () => [
      {
        name: "Density (lbm/ft³)",
        type: "value",
        color: theme.palette.black,
        show: true,
      },
    ],
    [theme]
  );

  const oilPvtpoSeries = useMemo<FossilyticsChartSeries[]>(
    () =>
      calculateValue
        ? [
            {
              name: `Oil po`,
              type: "line",
              hideSymbol: true,
              color: theme.palette.orangeLighter,
              lineWidth: 2,
              z: 2,
              data: calculateValue.oil_pvt_charts.pressure.map((p, j) => [p, calculateValue.oil_pvt_charts.density[j]]),
            },
          ]
        : [],
    [calculateValue, theme]
  );

  //RS
  const oilPvtRsXAxes = useMemo<FossilyticsChartAxis[]>(
    () => [
      {
        name: "Pressure (psia)",
        type: "value",
        color: theme.palette.black,
      },
    ],
    [theme]
  );

  const oilPvtRsYAxes = useMemo<FossilyticsChartAxis[]>(
    () => [
      {
        name: "Solution gas oil ratio (scf/stb)",
        type: "value",
        color: theme.palette.black,
        show: true,
      },
    ],
    [theme]
  );

  const oilPvtRsSeries = useMemo<FossilyticsChartSeries[]>(
    () =>
      calculateValue
        ? [
            {
              name: `Oil Rs`,
              type: "line",
              hideSymbol: true,
              color: theme.palette.orangeLighter,
              lineWidth: 2,
              z: 2,
              data: calculateValue.oil_pvt_charts.pressure.map((p, j) => [p, calculateValue.oil_pvt_charts.solution_gas_oil_ratio[j]]),
            },
          ]
        : [],
    [calculateValue, theme]
  );

  // Sigma - (Formation volume factor)
  const oilPvtSigmaXAxes = useMemo<FossilyticsChartAxis[]>(
    () => [
      {
        name: "Pressure (psia)",
        type: "value",
        color: theme.palette.black,
      },
    ],
    [theme]
  );

  const oilPvtSigmaYAxes = useMemo<FossilyticsChartAxis[]>(
    () => [
      {
        name: "superficial tension (dynes/cm)",
        type: "value",
        color: theme.palette.black,
        show: true,
      },
    ],
    [theme]
  );

  const OilPvtSigmaSeries = useMemo<FossilyticsChartSeries[]>(
    () =>
      calculateValue
        ? [
            {
              name: `Oil sigma`,
              type: "line",
              hideSymbol: true,
              color: theme.palette.orangeLighter,
              lineWidth: 2,
              z: 2,
              data: calculateValue.oil_pvt_charts.pressure.map((p, j) => [p, calculateValue.oil_pvt_charts.superficial_tension[j]]),
            },
          ]
        : [],
    [calculateValue, theme]
  );

  // cg - compressibility (psi-1)
  const oilPvtCoXAxes = useMemo<FossilyticsChartAxis[]>(
    () => [
      {
        name: "Pressure (psia)",
        type: "value",
        color: theme.palette.black,
      },
    ],
    [theme]
  );

  const oilPvtCoYAxes = useMemo<FossilyticsChartAxis[]>(
    () => [
      {
        name: "compressibility (psi-1)",
        type: "value",
        color: theme.palette.black,
        show: true,
      },
    ],
    [theme]
  );

  const oilPvtCoSeries = useMemo<FossilyticsChartSeries[]>(
    () =>
      calculateValue
        ? [
            {
              name: `Oil Cg`,
              type: "line",
              hideSymbol: true,
              color: theme.palette.orangeLighter,
              lineWidth: 2,
              z: 2,
              data: calculateValue.oil_pvt_charts.pressure.map((p, j) => [p, calculateValue.oil_pvt_charts.compressibility[j]]),
            },
          ]
        : [],
    [calculateValue, theme]
  );

  return {
    oilPvtBo: {
      xAxes: oilPvtBoXAxes,
      yAxes: oilPvtBoYAxes,
      series: oilPvtBoSeries,
    },
    oilPvtMu: {
      xAxes: oilPvtMuXAxes,
      yAxes: oilPvtMuYAxes,
      series: oilPvtMuSeries,
    },
    oilPvtpo: {
      xAxes: oilPvtpoXAxes,
      yAxes: oilPvtpoYAxes,
      series: oilPvtpoSeries,
    },
    oilPvtRs: {
      xAxes: oilPvtRsXAxes,
      yAxes: oilPvtRsYAxes,
      series: oilPvtRsSeries,
    },
    oilPvtSigma: {
      xAxes: oilPvtSigmaXAxes,
      yAxes: oilPvtSigmaYAxes,
      series: OilPvtSigmaSeries,
    },
    oilPvtCo: {
      xAxes: oilPvtCoXAxes,
      yAxes: oilPvtCoYAxes,
      series: oilPvtCoSeries,
    },
  };
};
export default useGazAutoRtaOilPvtCharts;
