import { ModuleId } from "@/model";
import { GenericChartResult } from "@/models/Generic";
import { SpadDeclineType } from "@/models/spad/decline";

export const getSubModulePath = (moduleId: ModuleId): SpadDeclineType => (moduleId === ModuleId.SPAD_DECLINE_GAS ? "gas" : "oil");

export const getSharedLineSeriesOpts = () => ({
  type: "line",
  lineWidth: 2,
  hideSymbol: false,
  symbolSize: 0.1,
  z: 1,
});

export const getRatesCumLineSeries = (arp?: GenericChartResult) => ({
  ...getSharedLineSeriesOpts(),
  data: arp ? arp.days.map((_, i) => [arp.cumulative_production[i], arp.rates[i]]) : [],
});

export const getFlowLineSeries = (arp?: GenericChartResult) => {
  let data: (string | number)[][] = arp?.days.map((d, i) => [d, arp?.rates[i]]) ?? [];

  return {
    ...getSharedLineSeriesOpts(),
    data,
  };
};
