import { useMemo } from "react";
import { useTheme } from "@fluentui/react";

import FossilyticsChart, {
  FossilyticsChartAxis,
  FossilyticsChartCustomDataZoom,
  FossilyticsChartLine,
  FossilyticsChartSeries,
} from "@/components/FossilyticsChart";
import { SpadAnalysisOutput } from "@/features/modules/spad/decline";
import { DataSet, ModuleId } from "@/model";
import { useAppStore } from "@/features/app";

interface LogRatePlotsProps {
  isLoading: boolean;
  logFlowSeries: FossilyticsChartSeries[];
  flowLines: FossilyticsChartLine[];
  xAxesState?: boolean;
  initOutput?: SpadAnalysisOutput;
  unit: string;
  updateLineFromChart?: (l: number[][] | [string, number][], i: number, isEnd?: boolean) => void;
  handleXAxesChange?: () => void;
  draggable?: boolean;
  dataSets: DataSet[];
}

export const LogRatePlots = ({
  isLoading,
  logFlowSeries,
  flowLines,
  xAxesState,
  initOutput,
  unit,
  updateLineFromChart,
  handleXAxesChange,
  draggable = true,
  dataSets,
}: LogRatePlotsProps) => {
  const currentModule = useAppStore((state) => state.currentModule);

  const theme = useTheme();
  const xAxes = useMemo<FossilyticsChartAxis[]>(() => {
    let name = "";
    let type = "";
    let min;
    if (!xAxesState) {
      name = "Days";
      type = "value";
      min = 0;
      return [{ name: name, type: type, color: theme.palette.black, min: min }];
    }
    name = "Dates";
    type = "time";
    min = initOutput?.dateTimes[0] ? new Date(initOutput?.dateTimes[0]).getTime() : undefined;
    return [{ name: name, type: type, color: theme.palette.black, min: min }];
  }, [initOutput?.dateTimes, theme.palette.black, xAxesState]);

  const flowYAxesLog = useMemo<FossilyticsChartAxis[]>(
    () => [{ name: `Rates (${unit}/d)`, type: "log", color: theme.palette.black }],
    [theme.palette.black, unit]
  );

  const findLastValueInFlowLinesForlogqgt = useMemo(() => {
    const newLine = logFlowSeries?.filter((line) => line.name === "Economic cutoff")[0].data;
    const logFlowSeriesLastPoint = logFlowSeries?.filter((seriesItem) => seriesItem.name === "Flow rates")[0].data;

    let percentage = 100;
    if (newLine && newLine.length > 0 && logFlowSeriesLastPoint.length > 0) {
      const newLineLastPoint = newLine[1][0] as number;
      const lastNumberInData = logFlowSeriesLastPoint[logFlowSeriesLastPoint.length - 1][0];
      if ((lastNumberInData * 3) / newLineLastPoint >= 1) {
        return;
      }
      if ((lastNumberInData * 3) / newLineLastPoint < 1) {
        percentage = Math.floor(((lastNumberInData * 3) / newLineLastPoint) * 100);
      }
    }
    return percentage;
  }, [logFlowSeries]);

  const logRateFlowCustomZoom = useMemo<FossilyticsChartCustomDataZoom>(() => {
    return {
      customDataZoom: true,
      dataZoomPercentage: findLastValueInFlowLinesForlogqgt as number,
    };
  }, [findLastValueInFlowLinesForlogqgt]);

  const key = `${currentModule}_log_q_t` + dataSets.map((well) => well.id).join(",");
  return (
    <FossilyticsChart
      id={`${currentModule}_log_q_t`}
      key={key}
      isLoading={isLoading}
      xAxes={xAxes}
      yAxes={flowYAxesLog}
      series={logFlowSeries}
      lines={flowLines}
      legends={[
        {
          name: "Flow rates",
          color: currentModule === ModuleId.SPAD_DECLINE_GAS ? theme.palette.red : theme.palette.green,
        },
      ]}
      onLineChange={updateLineFromChart}
      customDataZoom={logRateFlowCustomZoom}
      handleXAxesChange={handleXAxesChange}
      xAxesState={xAxesState}
      draggable={draggable}
    />
  );
};
