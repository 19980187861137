import { Stack, Text, useTheme } from "@fluentui/react";
import Page from "./Page";
import React from "react";
import { Link } from "react-router-dom";

function NotFound() {
  const theme = useTheme();

  return (
    <Page title="Not found">
      <Stack horizontalAlign="center" verticalAlign="center" style={{ height: "100%", padding: 20 }}>
        <Text variant="large" block>
          Sorry, you found a
        </Text>
        <Text variant="xxLarge" block>
          <span style={{ color: theme.palette.themePrimary }}>blank</span> page.
        </Text>
        <br />
        <Text block>
          <Link style={{ color: theme.palette.themePrimary }} to="/">
            Click here to go home and try again.
          </Link>
        </Text>
      </Stack>
    </Page>
  );
}

export default NotFound;
