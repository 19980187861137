import { validateRullon } from "@/constants/apiUrl";

import { fetchApi } from "@/utils/apiFetcher";
import { RulonChangeItem, RulonGroupRunResponse } from "../State";

export const postValidateRulonGroupRun = (projectId: string, payload: RulonChangeItem[]) => {
  return fetchApi<RulonGroupRunResponse, RulonChangeItem[]>({
    path: validateRullon(projectId),
    body: payload,
    type: "post",
  });
};
