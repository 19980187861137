import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTheme } from "@fluentui/react";
import { DateTime } from "luxon";
import _ from "lodash";

import { FossilyticsChartLine, FossilyticsChartLineConstraint, FossilyticsChartSeries } from "@/components/FossilyticsChart";
import Tabs from "@/components/Tabs";

import { ModuleId } from "@/model";
import { useAppStore } from "@/features/app";
import { ModuleSpadDeclineArp, SpadDeclineAnalysis } from "@/features/modules/spad/decline";
import { AnalysisSummary } from "@/features/modules/spad/decline/components/analysis-summary";
import { QtPlots } from "@/features/modules/spad/decline/components/qt-plots";
import { LogQqPlots } from "@/features/modules/spad/decline/components/log-qq-plots";
import { ModuleQqPlots } from "@/features/modules/spad/decline/components/module-qq-plots";
import { ModuleQtPlots } from "@/features/modules/spad/decline/components/module-qt-plots";
import { LogRatePlots } from "@/features/modules/spad/decline/components/log-rate-plots";

import { DeclinePlotsProps } from "./decline-plots.types";

const getSharedLineSeriesOpts = () => ({
  type: "line",
  lineWidth: 2,
  hideSymbol: false,
  symbolSize: 0.1,
  z: 1,
});

const getRatesCumLineSeries = (arp?: ModuleSpadDeclineArp) => ({
  ...getSharedLineSeriesOpts(),
  data: arp ? arp.daysQ.map((d, i) => [arp.cumProds[i], arp.ratesQ[i]]) : [],
});

export const DeclinePlots = ({
  isLoading,
  dataSets,
  unit,
  dataStartDay,
  onDataStartDayChange,
  forecastStartDate,
  forecastEndDate,
  economicCutoff,
  parametersShort,
  parametersLong,
  compareAnalysisName,
  compareEconomicCutoff,
  compareDeclineArps,
  onDeclineArpChange,
  onHandleXAxesStateFromChild,
  xAxesState,
  setCsvData,
  isApiError,
  autoSmartFit,
  analysis,
  finishCalculation,
  setFinishCalculation,
}: DeclinePlotsProps) => {
  const initOutput = analysis?.chartData;

  const arpsShort = useMemo(() => {
    return analysis?.ops?.arps ?? {};
  }, [analysis?.ops?.arps]);

  const arpsLong = useMemo(() => {
    return analysis?.profile?.arps ?? {};
  }, [analysis?.profile?.arps]);

  const currentModule = useAppStore((state) => state.currentModule);

  const firstDate = useMemo(() => {
    if (!initOutput?.dateTimes || !initOutput.days) return undefined;
    const initialDay = initOutput.days[0];
    const initialDate = DateTime.fromISO(initOutput.dateTimes[0]);
    return initialDate.minus({ day: initialDay - 1 });
  }, [initOutput?.dateTimes, initOutput?.days]);

  const forecastStartDay = useMemo<number | string | undefined>(() => {
    if (!forecastStartDate || !firstDate) return undefined;
    if (xAxesState) {
      return DateTime.fromJSDate(forecastStartDate).toString();
    }
    const forecastStartDateTime = DateTime.fromJSDate(forecastStartDate);

    return forecastStartDateTime.diff(firstDate, "days").days;
  }, [firstDate, forecastStartDate, xAxesState]);

  const forecastEndDay = useMemo<number | string | undefined>(() => {
    if (!forecastEndDate || !firstDate) return undefined;
    if (xAxesState) {
      return DateTime.fromJSDate(forecastEndDate).toString();
    }
    const forecastEndDateTime = DateTime.fromJSDate(forecastEndDate);

    return forecastEndDateTime.diff(firstDate, "days").days;
  }, [firstDate, forecastEndDate, xAxesState]);

  const getFlowLineSeries = useCallback(
    (arp?: ModuleSpadDeclineArp) => {
      let data: (string | number)[][] = [];

      if (arp) {
        data = !xAxesState ? arp.days.map((d, i) => [d, arp.rates[i]]) : arp.dateTimes.map((d, i) => [d, arp.rates[i]]);
      }

      return {
        ...getSharedLineSeriesOpts(),
        data,
      };
    },
    [xAxesState]
  );

  const maxDays = useMemo(() => {
    if (!arpsShort.high || !arpsLong.high || !arpsShort.high.dateTimes) return 0;
    if (!xAxesState) {
      return Math.max(arpsShort.high.days[arpsShort.high.days.length - 1], arpsLong.high.days[arpsLong.high.days.length - 1]);
    }
    return new Date(
      Math.max(
        new Date(arpsShort.high.dateTimes[arpsShort.high.dateTimes.length - 1]).getTime(),
        new Date(arpsLong.high.dateTimes[arpsLong.high.dateTimes.length - 1]).getTime()
      )
    )
      .toISOString()
      .slice(0, 19);
  }, [arpsShort.high, arpsLong.high, xAxesState]);

  const theme = useTheme();

  const getScatterItemColor = useCallback(
    (params: any) => {
      let itemColor;

      if ((forecastStartDay && params.data[0] > forecastStartDay) || (dataStartDay && params.data[0] < dataStartDay)) {
        itemColor = theme.palette.neutralSecondary;
      } else {
        itemColor = currentModule === ModuleId.SPAD_DECLINE_GAS ? theme.palette.red : theme.palette.green;
      }

      return itemColor;
    },
    [dataStartDay, forecastStartDay, currentModule, theme.palette.green, theme.palette.neutralSecondary, theme.palette.red]
  );

  const getSecondaryScatterItemColor = useCallback(
    (params: any) => {
      const indexForInitOutputDays = forecastStartDay && initOutput?.days?.findIndex((value) => value > Number(forecastStartDay));

      const valueForLogCummProds = indexForInitOutputDays && initOutput?.cumProds?.[indexForInitOutputDays];

      let itemColor;

      if ((valueForLogCummProds && forecastStartDay && params.data[0] > valueForLogCummProds) || (dataStartDay && params.data[0] < dataStartDay)) {
        itemColor = theme.palette.neutralSecondary;
      } else {
        itemColor = currentModule === ModuleId.SPAD_DECLINE_GAS ? theme.palette.red : theme.palette.green;
      }

      return itemColor;
    },
    [
      dataStartDay,
      forecastStartDay,
      initOutput?.cumProds,
      initOutput?.days,
      currentModule,
      theme.palette.green,
      theme.palette.neutralSecondary,
      theme.palette.red,
    ]
  );

  const flowSeries = useMemo<FossilyticsChartSeries[]>(() => {
    let flowRatesData: (string | number)[][] = [];
    if (initOutput) {
      flowRatesData = xAxesState
        ? initOutput.dateTimes.map((d, i) => [d, initOutput.rates[i]])
        : initOutput.days.map((d, i) => [d, initOutput.rates[i]]);
    }

    return [
      {
        name: "Flow rates",
        type: "scatter",
        color: getScatterItemColor,
        z: 0,
        data: flowRatesData,
      },
      {
        ...getFlowLineSeries(arpsShort.low),
        id: "lc",
        name: "OPS Low case",
        defaultDisabled: true,
        color: theme.palette.purpleLight,
      },
      {
        ...getFlowLineSeries(arpsShort.mid),
        id: "mc",
        name: "OPS Mid case",
        defaultDisabled: true,
        color: theme.palette.purple,
      },
      {
        ...getFlowLineSeries(arpsShort.high),
        id: "hc",
        name: "OPS High case",
        defaultDisabled: true,
        color: theme.palette.purpleDark,
      },
      {
        ...getFlowLineSeries(arpsLong.low),
        id: "lcl",
        name: "Profile Low case",
        color: theme.palette.themePrimary,
      },
      {
        ...getFlowLineSeries(arpsLong.mid),
        id: "mcl",
        name: "Profile Mid case",
        color: theme.palette.blue,
      },
      {
        ...getFlowLineSeries(arpsLong.high),
        id: "hcl",
        name: "Profile High case",
        color: theme.palette.yellow,
      },
      {
        name: "Economic cutoff",
        type: "line",
        lineType: "dashed",
        hideSymbol: true,
        color: theme.palette.red,
        data: [
          [0, economicCutoff],
          [maxDays, economicCutoff],
        ],
      },
      ...(compareAnalysisName && compareEconomicCutoff && compareDeclineArps
        ? [
            {
              ...getFlowLineSeries(compareDeclineArps.low),
              name: `${compareAnalysisName} Low Case`,
              lineWidth: 1,
              color: theme.palette.neutralDark,
            },
            {
              ...getFlowLineSeries(compareDeclineArps.mid),
              name: `${compareAnalysisName} Mid Case`,
              lineWidth: 1,
              color: theme.palette.neutralDark,
            },
            {
              ...getFlowLineSeries(compareDeclineArps.high),
              name: `${compareAnalysisName} High Case`,
              lineWidth: 1,
              color: theme.palette.neutralDark,
            },
            {
              name: `${compareAnalysisName} Economic cutoff`,
              type: "line",
              lineType: "dashed",
              hideSymbol: true,
              color: theme.palette.neutralDark,
              data: compareDeclineArps.high
                ? [
                    [0, compareEconomicCutoff],
                    [compareDeclineArps.high?.days.at(-1), compareEconomicCutoff],
                  ]
                : [],
            },
          ]
        : []),
    ];
  }, [
    getScatterItemColor,
    initOutput,
    xAxesState,
    getFlowLineSeries,
    arpsShort.low,
    arpsShort.mid,
    arpsShort.high,
    theme.palette.purpleLight,
    theme.palette.purple,
    theme.palette.purpleDark,
    theme.palette.themePrimary,
    theme.palette.blue,
    theme.palette.yellow,
    theme.palette.red,
    theme.palette.neutralDark,
    arpsLong.low,
    arpsLong.mid,
    arpsLong.high,
    economicCutoff,
    maxDays,
    compareAnalysisName,
    compareEconomicCutoff,
    compareDeclineArps,
  ]);

  const logFlowSeries = useMemo<FossilyticsChartSeries[]>(() => {
    let data: (string | number)[][] = [];
    if (initOutput) {
      data = xAxesState
        ? initOutput.logDateTimes.map((d, i) => [d, initOutput.logRates[i]])
        : initOutput.logDays.map((d, i) => [d, initOutput.logRates[i]]);
    }

    return [
      {
        name: "Flow rates",
        type: "scatter",
        color: getScatterItemColor,
        z: -1,
        data,
      },
      ...flowSeries.filter((s) => s.name !== "Flow rates"),
    ];
  }, [flowSeries, getScatterItemColor, initOutput, xAxesState]);

  const ratesCumSeries = useMemo<FossilyticsChartSeries[]>(
    () => [
      {
        name: "Flow rates",
        type: "scatter",
        color: getSecondaryScatterItemColor,
        data: initOutput ? initOutput.days.map((_, i) => [initOutput.cumProds[i], initOutput.rates[i]]) : [],
        z: -1,
      },
      {
        ...getRatesCumLineSeries(arpsShort.low),
        id: "lc",
        name: "OPS Low case",
        defaultDisabled: true,
        color: theme.palette.purpleLight,
      },
      {
        ...getRatesCumLineSeries(arpsShort.mid),
        id: "mc",
        name: "OPS Mid case",
        defaultDisabled: true,
        color: theme.palette.purple,
      },
      {
        ...getRatesCumLineSeries(arpsShort.high),
        id: "hc",
        name: "OPS High case",
        defaultDisabled: true,
        color: theme.palette.purpleDark,
      },
      {
        ...getRatesCumLineSeries(arpsLong.low),
        id: "lcl",
        name: "Profile Low case",
        color: theme.palette.themePrimary,
        z: 100,
      },
      {
        ...getRatesCumLineSeries(arpsLong.mid),
        id: "mcl",
        name: "Profile Mid case",
        color: theme.palette.blue,
        z: 100,
      },
      {
        ...getRatesCumLineSeries(arpsLong.high),
        id: "hcl",
        name: "Profile High case",
        color: theme.palette.yellow,
        z: 100,
      },
      {
        name: "Economic cutoff",
        type: "line",
        lineType: "dashed",
        hideSymbol: true,
        color: theme.palette.red,
        z: 1,
        data: arpsLong.high
          ? [
              [0, economicCutoff],
              [arpsLong.high.cumProds[arpsLong.high.cumProds.length - 1] + arpsLong.high.cumStart, economicCutoff],
            ]
          : [],
      },
      ...(compareAnalysisName && compareEconomicCutoff && compareDeclineArps
        ? [
            {
              ...getRatesCumLineSeries(compareDeclineArps.low),
              name: `${compareAnalysisName} Low Case`,
              lineWidth: 1,
              color: theme.palette.neutralDark,
            },
            {
              ...getRatesCumLineSeries(compareDeclineArps.mid),
              name: `${compareAnalysisName} Mid Case`,
              lineWidth: 1,
              color: theme.palette.neutralDark,
            },
            {
              ...getRatesCumLineSeries(compareDeclineArps.high),
              name: `${compareAnalysisName} High Case`,
              lineWidth: 1,
              color: theme.palette.neutralDark,
            },
            {
              name: `${compareAnalysisName} Economic cutoff`,
              type: "line",
              lineType: "dashed",
              hideSymbol: true,
              color: theme.palette.neutralDark,
              z: 0,
              data: compareDeclineArps.high
                ? [
                    [0, economicCutoff],
                    [
                      compareDeclineArps.high.cumProds[compareDeclineArps.high.cumProds.length - 1] + compareDeclineArps.high.cumStart,
                      economicCutoff,
                    ],
                  ]
                : [],
            },
          ]
        : []),
    ],
    [
      getSecondaryScatterItemColor,
      initOutput,
      arpsShort.low,
      arpsShort.mid,
      arpsShort.high,
      theme.palette.purpleLight,
      theme.palette.purple,
      theme.palette.purpleDark,
      theme.palette.themePrimary,
      theme.palette.blue,
      theme.palette.yellow,
      theme.palette.red,
      theme.palette.neutralDark,
      arpsLong.low,
      arpsLong.mid,
      arpsLong.high,
      economicCutoff,
      compareAnalysisName,
      compareEconomicCutoff,
      compareDeclineArps,
    ]
  );

  const [flowLines, setFlowLines] = useState<FossilyticsChartLine[]>(() => [
    {
      id: "llc",
      name: "",
      color: "#fff0",
      handleColor: theme.palette.purpleLight,
      yConstraint: FossilyticsChartLineConstraint.LAST_VALUE,
      line: [],
      linked: "OPS Low case",
    },
    {
      id: "lmc",
      name: "",
      color: "#fff0",
      handleColor: theme.palette.purple,
      yConstraint: FossilyticsChartLineConstraint.LAST_VALUE,
      line: [],
      linked: "OPS Mid case",
    },
    {
      id: "lhc",
      name: "",
      color: "#fff0",
      handleColor: theme.palette.purpleDark,
      yConstraint: FossilyticsChartLineConstraint.LAST_VALUE,
      line: [],
      linked: "OPS High case",
    },
    {
      id: "llcl",
      name: "",
      color: "#fff0",
      handleColor: theme.palette.themePrimary,
      yConstraint: FossilyticsChartLineConstraint.LAST_VALUE,
      line: [],
      linked: "Profile Low case",
    },
    {
      id: "lmcl",
      name: "",
      color: "#fff0",
      handleColor: theme.palette.blue,
      yConstraint: FossilyticsChartLineConstraint.LAST_VALUE,
      line: [],
      linked: "Profile Mid case",
    },
    {
      id: "lhcl",
      name: "",
      color: "#fff0",
      handleColor: theme.palette.yellow,
      yConstraint: FossilyticsChartLineConstraint.LAST_VALUE,
      line: [],
      linked: "Profile High case",
    },
  ]);

  const forecastStartLine = useMemo<FossilyticsChartLine | undefined>(() => {
    if (!forecastStartDay || (forecastStartDay as number) < 0 || forecastStartDate === undefined) return undefined;
    return {
      id: "forecastStartDay",
      name: "Forecast start",
      type: "vertical",
      xValue: xAxesState ? forecastStartDate.toISOString().slice(0, 19) : forecastStartDay,
      lineType: "dashed",
      lineWidth: 1.5,
      color: theme.palette.black,
      controllable: false,
    };
  }, [forecastStartDay, forecastStartDate, xAxesState, theme.palette.black]);

  const forecastEndLine = useMemo<FossilyticsChartLine | undefined>(() => {
    if (!forecastEndDay || (forecastEndDay as number) < 0 || forecastEndDate === undefined) return undefined;
    return {
      id: "forecastEndDay",
      name: "Forecast end",
      type: "vertical",
      xValue: xAxesState ? forecastEndDate.toISOString().slice(0, 19) : forecastEndDay,
      lineType: "dashed",
      lineWidth: 1.5,
      color: theme.palette.black,
      controllable: false,
    };
  }, [forecastEndDay, forecastEndDate, xAxesState, theme.palette.black]);

  const dataStartLine = useMemo<FossilyticsChartLine | undefined>(() => {
    if (dataStartDay === undefined) return undefined;
    const flowRates = logFlowSeries.find((f) => f.name === "Flow rates");
    const fifthFromEnd = flowRates?.data[flowRates?.data.length - 50];
    if (dataStartDay === undefined || fifthFromEnd === undefined) return undefined;

    if (xAxesState) {
      return {
        id: "dataStartDay",
        name: "Data start",
        type: "vertical",
        xValue: dataStartDay,
        lineType: "dashed",
        lineWidth: 1.5,
        color: theme.palette.black,
        xConstraint: FossilyticsChartLineConstraint.VALUE,
        xConstraintValue: [0, forecastStartDay as string],
      };
    }
    return {
      id: "dataStartDay",
      name: "Data start",
      type: "vertical",
      xValue: dataStartDay,
      lineType: "dashed",
      lineWidth: 1.5,
      color: theme.palette.black,
      xConstraint: FossilyticsChartLineConstraint.VALUE,
      xConstraintValue: [0, (fifthFromEnd[0] > forecastStartDay! ? forecastStartDay : fifthFromEnd[0]) as number],
    };
  }, [dataStartDay, forecastStartDay, theme.palette.black, xAxesState, logFlowSeries]);

  const haveInitializeDayLines = useRef({});

  // flow line for days additional lines
  useEffect(() => {
    const dateInfo = {
      dataStartDay,
      dataStartLine,
      forecastStartLine,
      forecastEndLine,
    };
    if (_.isEqual(haveInitializeDayLines.current, dateInfo)) return;
    setFlowLines((previousLines) => {
      const newLs = [...previousLines];

      const forecastIndex = newLs.findIndex((l) => l.id === "forecastStartDay");
      newLs.splice(forecastIndex < 0 ? newLs.length : forecastIndex, forecastIndex >= 0 ? 1 : 0, ...(forecastStartLine ? [forecastStartLine] : []));

      const dataStartIndex = newLs.findIndex((l) => l.id === "dataStartDay");
      newLs.splice(dataStartIndex < 0 ? newLs.length : dataStartIndex, dataStartIndex >= 0 ? 1 : 0, ...(dataStartLine ? [dataStartLine] : []));

      const forecastEndIndex = newLs.findIndex((l) => l.id === "forecastEndDay");
      newLs.splice(
        forecastEndIndex < 0 ? newLs.length : forecastEndIndex,
        forecastEndIndex >= 0 ? 1 : 0,
        ...(forecastEndLine ? [forecastEndLine] : [])
      );

      return newLs;
    });
    haveInitializeDayLines.current = dateInfo;
  }, [dataStartDay, dataStartLine, forecastStartLine, forecastEndLine]);

  const updateLineFromChart = useCallback(
    (l: number[][] | [string, number][], i: number, isEnd?: boolean) => {
      setFlowLines((ls) => {
        if (!ls[i]) return ls;
        let lsNew = [...ls];

        if (lsNew[i].type === "vertical") {
          lsNew[i].xValue = l[0][0];
        } else {
          lsNew[i].line = l as number[][];
        }
        return JSON.parse(JSON.stringify(lsNew));
      });
      if (isEnd) {
        setFinishCalculation(false);

        if (["llc", "lmc", "lhc", "llcl", "lmcl", "lhcl"].includes(flowLines[i].id ?? "")) {
          onDeclineArpChange(l, i);
        } else if (flowLines[i].id === "dataStartDay") {
          if (onDataStartDayChange) {
            onDataStartDayChange(l[0][0]);
          }
        }
      }
    },
    [flowLines, onDataStartDayChange, onDeclineArpChange, setFinishCalculation]
  );

  const getLineCoordinate = useCallback(
    (arps?: ModuleSpadDeclineArp) => {
      let lNew: number[][] | [string, number][] = [];
      if (!arps) return lNew;
      const firstIdx = arps.days.findIndex((d) => d >= 0);
      const lastIdx = arps.days.length - 1;
      if (!xAxesState) {
        lNew = [
          [arps.days[firstIdx], arps.rates[firstIdx]],
          [arps.days[lastIdx], arps.rates[lastIdx]],
        ];
      }
      if (xAxesState) {
        lNew = [
          [arps.dateTimes[firstIdx], arps.rates[firstIdx]],
          [arps.dateTimes[lastIdx], arps.rates[lastIdx]],
          [arps.dateTimes[0], arps.rates[0]],
        ];
      }
      return lNew;
    },
    [xAxesState]
  );

  const updateLineAllArps = useCallback(
    (analysis: SpadDeclineAnalysis | undefined) => {
      if (!analysis?.ops?.arps || !analysis?.profile?.arps) return;
      setFlowLines((ls) => {
        const lsNew = [...ls];

        const coords: { [key: number]: number[][] | [string, number][] } = {
          0: getLineCoordinate(analysis.ops?.arps?.low),
          1: getLineCoordinate(analysis.ops?.arps?.mid),
          2: getLineCoordinate(analysis.ops?.arps?.high),

          3: getLineCoordinate(analysis.profile?.arps?.low),
          4: getLineCoordinate(analysis.profile?.arps?.mid),
          5: getLineCoordinate(analysis.profile?.arps?.high),
        };

        Object.keys(coords).forEach((index: string) => {
          lsNew[Number(index)].line = coords[Number(index)];
        });
        return lsNew;
      });
    },
    [getLineCoordinate]
  );

  const haveInitializeLine = useRef(false);
  const prevSmartFit = useRef(false);

  useEffect(() => {
    if (analysis?.autoUpdateSmartFit && !prevSmartFit.current) {
      updateLineAllArps(analysis);
    }
    prevSmartFit.current = analysis?.autoUpdateSmartFit ?? false;
  }, [analysis, analysis?.autoUpdateSmartFit, updateLineAllArps]);

  useEffect(() => {
    if (haveInitializeLine.current || Object.keys(arpsLong).length === 0 || Object.keys(arpsShort).length === 0) return;
    updateLineAllArps(analysis);
    haveInitializeLine.current = true;
    return () => {
      haveInitializeLine.current = false;
    };
  }, [arpsShort, arpsLong, updateLineAllArps, analysis]);

  const subChar = currentModule === ModuleId.SPAD_DECLINE_GAS ? "g" : "o";

  const handleXAxesChange = useCallback(() => {
    onHandleXAxesStateFromChild();
  }, [onHandleXAxesStateFromChild]);

  const tabList = useMemo(() => {
    return [
      {
        label: (
          <span>
            log(q<sub>{subChar}</sub>) vs t
          </span>
        ),
        content: (
          <LogRatePlots
            xAxesState={xAxesState}
            isLoading={isLoading || !finishCalculation}
            initOutput={initOutput}
            unit={unit}
            flowLines={flowLines}
            updateLineFromChart={updateLineFromChart}
            handleXAxesChange={handleXAxesChange}
            logFlowSeries={logFlowSeries}
            draggable={!autoSmartFit}
            dataSets={dataSets}
          />
        ),
        key: "log(q) vs t",
      },
      {
        label: (
          <span>
            q<sub>{subChar}</sub> vs t
          </span>
        ),
        key: "q vs t",
        content: (
          <ModuleQtPlots
            xAxesState={xAxesState}
            isLoading={isLoading || !finishCalculation}
            initOutput={initOutput}
            unit={unit}
            dataSets={dataSets}
            flowLines={flowLines}
            flowSeries={flowSeries}
            logFlowSeries={logFlowSeries}
            updateLineFromChart={updateLineFromChart}
            handleXAxesChange={handleXAxesChange}
            draggable={!autoSmartFit}
          />
        ),
      },
      {
        label: (
          <span>
            q<sub>{subChar}</sub> vs Q<sub>{subChar}</sub>
          </span>
        ),
        key: "q vs Q",
        content: <ModuleQqPlots isLoading={isLoading || !finishCalculation} dataSets={dataSets} unit={unit} ratesCumSeries={ratesCumSeries} />,
      },
      {
        label: (
          <span>
            log(q<sub>{subChar}</sub>) vs Q<sub>{subChar}</sub>
          </span>
        ),
        key: "log (q) vs Q",
        content: (
          <LogQqPlots
            isLoading={isLoading || !finishCalculation}
            dataSets={dataSets}
            unit={unit}
            initOutput={initOutput}
            ratesCumSeries={ratesCumSeries}
            getSecondaryScatterItemColor={getSecondaryScatterItemColor}
          />
        ),
      },
      {
        label: (
          <span>
            Q<sub>{subChar}</sub> vs t
          </span>
        ),
        key: "Q vs t",
        content: (
          <QtPlots
            xAxesState={xAxesState}
            isLoading={isLoading || !finishCalculation}
            initOutput={initOutput}
            unit={unit}
            arpsLong={arpsLong}
            arpsShort={arpsShort}
            compareAnalysisName={compareAnalysisName}
            compareEconomicCutoff={compareEconomicCutoff}
            compareDeclineArps={compareDeclineArps}
            getScatterItemColor={getScatterItemColor}
            dataSets={dataSets}
            forecastStartLine={forecastStartLine}
            handleXAxesChange={handleXAxesChange}
            getSharedLineSeriesOpts={getSharedLineSeriesOpts}
            draggable={!autoSmartFit}
          />
        ),
      },
      {
        label: <span>Analysis Summary</span>,
        key: "Analysis Summary",
        content: (
          <AnalysisSummary
            initOutput={initOutput}
            arpsLong={arpsLong}
            arpsShort={arpsShort}
            parametersLong={parametersLong}
            parametersShort={parametersShort}
            subChar={subChar}
            unit={unit}
            dataSets={dataSets}
            setCsvData={setCsvData}
            isLoading={isLoading}
            isApiError={isApiError}
          />
        ),
      },
    ];
  }, [
    subChar,
    xAxesState,
    isLoading,
    finishCalculation,
    initOutput,
    unit,
    flowLines,
    updateLineFromChart,
    handleXAxesChange,
    logFlowSeries,
    autoSmartFit,
    dataSets,
    flowSeries,
    ratesCumSeries,
    getSecondaryScatterItemColor,
    arpsLong,
    arpsShort,
    compareAnalysisName,
    compareEconomicCutoff,
    compareDeclineArps,
    getScatterItemColor,
    forecastStartLine,
    parametersLong,
    parametersShort,
    setCsvData,
    isApiError,
  ]);

  return (
    <Tabs
      onClickItem={(index) => {
        if (index !== 5) setCsvData();
      }}
      tabList={tabList}
    />
  );
};
