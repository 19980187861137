import { formatNumber } from "@/util";
import { DetailsList, SelectionMode, Text } from "@fluentui/react";

import useThemeStyling from "@/utils/useThemeStyling";

import LoadingIndicator from "@/components/LoadingIndicator";

import { dataTableColumns } from "../../constants";
import DefinedParameters from "./DefinedParameters";
import ModelComponents from "./ModelComponents";

import useGazAutoRtaInputGridTab, { GazAutoRtaInputGridTabProps } from "../../hooks/rtaInputGrid/useGazAutoRtaInputGridTab";

const AutoRtaInputGridContainer = (props: GazAutoRtaInputGridTabProps) => {
  const {
    inputGridValues,
    inputGridCalculate,
    gasRecombination,
    onCheckboxGasRecombination,
    setGazAutoRTAState,
    updateDefineParameterInputField,
    updateGasPvtInputField,
    updateGasRecombinationInput,
    updateWellboreModelInputField,
    updateOilPvtInputField,
    updateOilPvtDropdownField,
    updateLangmuirInputField,
    updateWaterPvtSelection,
    wellboreModelCheckBox,
    onCheckWellboreModel,
    updateGasPvtSelection,
    loadInputGrid,
  } = useGazAutoRtaInputGridTab();
  const { elevatedCard, theme } = useThemeStyling();
  const loadingIndicator = props.isLoading || loadInputGrid;

  // don't display anything if no inputGridValues
  if (!inputGridValues && loadingIndicator) return <LoadingIndicator />;

  return (
    <div
      style={{
        padding: 20,
        overflow: "scroll",
      }}
    >
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gap: 20,
          marginBottom: 20,
        }}
      >
        <div style={{ ...elevatedCard }}>
          <Text styles={{ root: { color: theme.palette.themePrimary } }}>Defined Parameters</Text>
          {loadingIndicator && !inputGridValues ? (
            <div className="center-flex" style={{ height: 350 }}>
              <LoadingIndicator />
            </div>
          ) : (
            inputGridValues && (
              <DefinedParameters
                isLoading={loadingIndicator}
                value={inputGridValues}
                updateDefineParameterInputField={updateDefineParameterInputField}
              />
            )
          )}
        </div>
        <div style={{ ...elevatedCard, height: "fit-content" }}>
          <Text styles={{ root: { color: theme.palette.themePrimary } }}>Summary</Text>
          {loadingIndicator && !inputGridCalculate ? (
            <div className="center-flex" style={{ height: 350 }}>
              <LoadingIndicator />
            </div>
          ) : (
            inputGridCalculate && (
              <DetailsList
                compact
                selectionMode={SelectionMode.none}
                columns={dataTableColumns}
                items={inputGridCalculate.summary_card.map((item: any, i) => {
                  return {
                    parameter: item.parameter,
                    value: formatNumber(item.value),
                    units: item.unit,
                  };
                })}
                className="GazAutoRTAParameterTable"
              />
            )
          )}
        </div>
      </div>

      <div
        style={{
          ...elevatedCard,
        }}
      >
        <Text styles={{ root: { color: theme.palette.themePrimary } }}>Model Components</Text>
        {loadingIndicator && !inputGridCalculate && !inputGridValues ? (
          <LoadingIndicator />
        ) : (
          inputGridCalculate &&
          inputGridValues && (
            <ModelComponents
              value={inputGridValues}
              calculateValue={inputGridCalculate}
              gasRecombination={gasRecombination}
              onCheckboxGasRecombination={onCheckboxGasRecombination}
              isLoading={loadingIndicator}
              onChange={setGazAutoRTAState}
              updateGasPvtInputField={updateGasPvtInputField}
              updateGasRecombinationInput={updateGasRecombinationInput}
              updateWellboreModelInputField={updateWellboreModelInputField}
              updateOilPvtInputField={updateOilPvtInputField}
              updateOilPvtDropdownField={updateOilPvtDropdownField}
              updateLangmuirInputField={updateLangmuirInputField}
              updateWaterPvtSelection={updateWaterPvtSelection}
              wellboreModelCheckBox={wellboreModelCheckBox}
              onCheckWellboreModel={onCheckWellboreModel}
              updateGasPvtSelection={updateGasPvtSelection}
            />
          )
        )}
      </div>
    </div>
  );
};

const AutoRtaInputGridContainerWrapper = (props: GazAutoRtaInputGridTabProps) => {
  // this force effect hooks to not update if user don't open InputGrid tab
  // since current module logic doesn't have any lazy rendering
  if (props.currentTab !== 1 || props.isLoading) return <LoadingIndicator />;
  return <AutoRtaInputGridContainer {...props} />;
};

export default AutoRtaInputGridContainerWrapper;
