import { useTheme } from "@fluentui/react";

const useThemeStyling = () => {
  const theme = useTheme();
  // TODO: Centralize this style
  const elevatedCard = {
    boxShadow: theme.effects.elevation4,
    padding: 15,
    borderRadius: theme.effects.roundedCorner4,
  } as React.CSSProperties;

  return {
    theme,
    elevatedCard,
  };
};

export default useThemeStyling;
