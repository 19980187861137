import React, { useMemo } from "react";

import { DefaultButton, Dialog, DialogFooter, PrimaryButton, Text } from "@fluentui/react";
import ProjectSettingsDialog from "./ProjectSettingsDialog";

import GroupDialog from "./GroupDialogV2";
import ProjectDialog from "./ProjectDialogV2";
import { modalProps } from "./model";
import { removeDataSetsDialogContentProps } from "../constants";
import { useTreeViewState } from "../hooks/TreeViewContextV2";
import { getIdFromActiveKey } from "../utils";
import { DialogEnum } from "../types";

const DialogSection = () => {
  const {
    isLoading,
    selectedKey,
    copyFromSelected,
    setCopyFromSelected,
    createNewProject,
    deleteProject,
    editExistingProject,

    createNewGroups,
    editExistingGroup,
    selectedGroup,
    setNewGroupsNames,
    newGroupsNames,
    removeDataSets,
    activeDialog,
    setActiveDialog,
    mappedItemKeyTotal,
    deleteGroup,
  } = useTreeViewState();

  const safeSelectedKey = useMemo(() => {
    if (selectedKey) return selectedKey;
    return "";
  }, [selectedKey]);

  const { groupIds, dataSet } = getIdFromActiveKey(safeSelectedKey);
  return (
    <>
      {/* Create project dialog */}
      <ProjectDialog
        selectedKey={safeSelectedKey}
        copyFromSelected={copyFromSelected}
        setCopyFromSelected={setCopyFromSelected}
        isLoading={isLoading}
        createNewProject={createNewProject}
        deleteProject={deleteProject}
        editExistingProject={editExistingProject}
        setActiveDialog={setActiveDialog}
        activeDialog={activeDialog}
      />

      {activeDialog === DialogEnum.PROJECT_SETTING && (
        <ProjectSettingsDialog setActiveDialog={setActiveDialog} isLoading={isLoading} selectedKey={safeSelectedKey} />
      )}

      {/* Create groups dialog */}
      {safeSelectedKey && (
        <GroupDialog
          selectedKey={safeSelectedKey}
          isLoading={isLoading}
          createNewGroups={createNewGroups}
          editExistingGroup={editExistingGroup}
          selectedGroup={selectedGroup}
          setNewGroupsNames={setNewGroupsNames}
          newGroupsNames={newGroupsNames}
          setActiveDialog={setActiveDialog}
          activeDialog={activeDialog}
          deleteGroup={deleteGroup}
        />
      )}

      {/* Remove data sets dialog */}

      <Dialog
        hidden={activeDialog !== DialogEnum.REMOVE_DATASET}
        onDismiss={() => setActiveDialog(undefined)}
        dialogContentProps={removeDataSetsDialogContentProps}
        modalProps={modalProps}
      >
        <Text>
          Removing well&nbsp; "{dataSet.join(", ")}"&nbsp; from group&nbsp; "{selectedKey && mappedItemKeyTotal[groupIds[groupIds.length - 1]]?.name}
          ".
        </Text>

        <DialogFooter>
          <DefaultButton disabled={isLoading} onClick={removeDataSets}>
            Remove
          </DefaultButton>
          <PrimaryButton disabled={isLoading} onClick={() => setActiveDialog(undefined)}>
            Cancel
          </PrimaryButton>
        </DialogFooter>
      </Dialog>
    </>
  );
};

export default DialogSection;
