import { DataSetType } from "@/model";
import { useCallback, useRef, useState } from "react";

const useWellFilter = () => {
  // Filter based on search term
  const [filter, setFilter] = useState<string>();
  const filterButtonRef = useRef<HTMLElement | null>(null);
  const [showFilters, setShowFilters] = useState(false);
  const [filterTypes, setFilterTypes] = useState<DataSetType[]>([DataSetType.GAS_WELL, DataSetType.OIL_WELL]);

  const clearFilters = useCallback(() => {
    setFilter("");
    setFilterTypes([]);
  }, []);

  return {
    clearFilters,
    filter,
    setFilter,
    filterButtonRef,
    showFilters,
    setShowFilters,
    filterTypes,
    setFilterTypes,
  };
};

export default useWellFilter;
