import { Pivot, PivotItem, Stack, useTheme } from "@fluentui/react";
import { useMemo } from "react";

import FossilyticsChart, { FossilyticsChartAxis, FossilyticsChartSeries } from "@/components/FossilyticsChart";

import { ForecastResponse } from "@/models/koldun";

interface ModuleKoldunMcsimCsgOutputViewProps {
  isLoading: boolean;
  forecastOutput: ForecastResponse | undefined;
}

export const ModuleKoldunMcsimCsgOutputView = ({ isLoading, forecastOutput }: ModuleKoldunMcsimCsgOutputViewProps) => {
  const theme = useTheme();
  const pinklight = "#FFB6C1";

  const csgGasProfilesXAxes = useMemo<FossilyticsChartAxis[]>(
    () => [
      {
        name: "Days",
        type: "value",
        color: theme.palette.black,
      },
    ],
    [theme]
  );

  const csgGasProfilesYAxes = useMemo<FossilyticsChartAxis[]>(
    () => [
      {
        name: "Gas Rate (MMscf/d)",
        type: "value",
        color: theme.palette.black,

        show: true,
      },
    ],
    [theme]
  );

  // Multi Gas layer
  const uncertaintyLayersP10: any = forecastOutput ? forecastOutput.uncertainty_cases.p10.CSG_Total_layered : null;
  const uncertaintyLayersP50: any = forecastOutput ? forecastOutput.uncertainty_cases.p50.CSG_Total_layered : null;
  const uncertaintyLayersP90: any = forecastOutput ? forecastOutput.uncertainty_cases.p90.CSG_Total_layered : null;
  const uncertaintyLayersPmean: any = forecastOutput ? forecastOutput.uncertainty_cases.pmean.CSG_Total_layered : null;

  const p10_layers = uncertaintyLayersP10
    ? [
        {
          name: `p10`,
          type: "line",
          hideSymbol: true,
          color: theme.palette.redDark,
          lineWidth: 2,
          z: 2,
          data: uncertaintyLayersP10.total_rates_mmscf_d.map((total_mmscf: any, i: any) => [uncertaintyLayersP10.days[i], total_mmscf]),
        },
      ]
    : [];

  const p50_layers = uncertaintyLayersP50
    ? [
        {
          name: `p50`,
          type: "line",
          hideSymbol: true,
          color: theme.palette.redDark,
          lineWidth: 2,
          z: 2,
          data: uncertaintyLayersP50.total_rates_mmscf_d.map((total_mmscf: any, i: any) => [uncertaintyLayersP50.days[i], total_mmscf]),
        },
      ]
    : [];

  const p90_layers = uncertaintyLayersP90
    ? [
        {
          name: `p90`,
          type: "line",
          hideSymbol: true,
          color: theme.palette.redDark,
          lineWidth: 2,
          z: 2,
          data: uncertaintyLayersP90.total_rates_mmscf_d.map((total_mmscf: any, i: any) => [uncertaintyLayersP90.days[i], total_mmscf]),
        },
      ]
    : [];

  const pmean_layers = uncertaintyLayersPmean
    ? [
        {
          name: `pmean`,
          type: "line",
          hideSymbol: true,
          color: theme.palette.redDark,
          lineWidth: 2,
          z: 2,
          data: uncertaintyLayersPmean.total_rates_mmscf_d.map((total_mmscf: any, i: any) => [uncertaintyLayersPmean.days[i], total_mmscf]),
        },
      ]
    : [];

  const multi_gas_layers = forecastOutput?.all_cases
    ? forecastOutput?.all_cases.map((item, m) => [
        {
          name: `Layer ${m + 1}`,
          type: "line",
          hideSymbol: true,
          color: pinklight,
          lineWidth: 1,
          z: 1,
          data: item.CSG_Total_layered.total_rates_mmscf_d.map((total_mmscf: any, k: any) => [item.CSG_Total_layered.days[k], total_mmscf]),
        },
      ])
    : [];

  const total_layers = [...multi_gas_layers, ...p10_layers, ...p50_layers, ...p90_layers, ...pmean_layers];
  const total_gas_series = total_layers.flat();

  const csgGasProfilesSeries = useMemo<FossilyticsChartSeries[]>(() => total_gas_series as any, [total_gas_series]);

  const csgCumesProfilesXAxes = useMemo<FossilyticsChartAxis[]>(
    () => [
      {
        name: "Days",
        type: "value",
        color: theme.palette.black,
      },
    ],
    [theme]
  );
  const csgCumesProfilesYAxes = useMemo<FossilyticsChartAxis[]>(
    () => [
      {
        name: "Cumulative Gas Production (MMscf)",
        type: "value",
        color: theme.palette.black,
        show: true,
      },
    ],
    [theme]
  );

  const csgWaterProfilesXAxes = useMemo<FossilyticsChartAxis[]>(
    () => [
      {
        name: "Days",
        type: "value",
        color: theme.palette.black,
      },
    ],
    [theme]
  );
  const csgWaterProfilesYAxes = useMemo<FossilyticsChartAxis[]>(
    () => [
      {
        name: "Water Rate (stb/d)",
        type: "value",
        color: theme.palette.black,
        show: true,
      },
    ],
    [theme]
  );

  // Multi WATER layer
  const uncertaintyWaterLayersP10: any = forecastOutput ? forecastOutput.uncertainty_cases.p10.CSG_Total_layered : null;
  const uncertaintyWaterLayersP50: any = forecastOutput ? forecastOutput.uncertainty_cases.p50.CSG_Total_layered : null;
  const uncertaintyWaterLayersP90: any = forecastOutput ? forecastOutput.uncertainty_cases.p90.CSG_Total_layered : null;
  const uncertaintyWaterLayersPmean: any = forecastOutput ? forecastOutput.uncertainty_cases.pmean.CSG_Total_layered : null;

  const p10_w_layers = uncertaintyWaterLayersP10
    ? [
        {
          name: `p10`,
          type: "line",
          hideSymbol: true,
          color: theme.palette.blueDark,
          lineWidth: 2,
          z: 2,
          data: uncertaintyWaterLayersP10.total_rates_w_bbl.map((total_w_bbl: any, i: any) => [uncertaintyWaterLayersP10.days[i], total_w_bbl]),
        },
      ]
    : [];
  const p50_w_layers = uncertaintyWaterLayersP50
    ? [
        {
          name: `p50`,
          type: "line",
          hideSymbol: true,
          color: theme.palette.blueDark,
          lineWidth: 2,
          z: 2,
          data: uncertaintyWaterLayersP50.total_rates_w_bbl.map((total_w_bbl: any, i: any) => [uncertaintyWaterLayersP50.days[i], total_w_bbl]),
        },
      ]
    : [];

  const p90_w_layers = uncertaintyWaterLayersP90
    ? [
        {
          name: `p90`,
          type: "line",
          hideSymbol: true,
          color: theme.palette.blueDark,
          lineWidth: 2,
          z: 2,
          data: uncertaintyWaterLayersP90.total_rates_w_bbl.map((total_w_bbl: any, i: any) => [uncertaintyWaterLayersP90.days[i], total_w_bbl]),
        },
      ]
    : [];

  const pmean_w_layers = uncertaintyWaterLayersPmean
    ? [
        {
          name: `pmean`,
          type: "line",
          hideSymbol: true,
          color: theme.palette.blueDark,
          lineWidth: 2,
          z: 2,
          data: uncertaintyWaterLayersPmean.total_rates_w_bbl.map((total_w_bbl: any, i: any) => [uncertaintyWaterLayersPmean.days[i], total_w_bbl]),
        },
      ]
    : [];

  const multi_water_layers = forecastOutput?.all_cases
    ? forecastOutput?.all_cases.map((item, m) => [
        {
          name: `Layer ${m + 1}`,
          type: "line",
          hideSymbol: true,
          color: theme.palette.blueLight,
          lineWidth: 1,
          z: 1,
          data: item.CSG_Total_layered.total_rates_w_bbl.map((total_w_bbl: any, k: any) => [item.CSG_Total_layered.days[k], total_w_bbl]),
        },
      ])
    : [];

  const total_w_layers = [...multi_water_layers, ...p10_w_layers, ...p50_w_layers, ...p90_w_layers, ...pmean_w_layers];
  const total_w_series = total_w_layers.flat();

  // Multi Cumulative layer
  const uncertaintycumulativeLayersP10: any = forecastOutput ? forecastOutput.uncertainty_cases.p10.CSG_Total_layered : null;

  const uncertaintycumulativeLayersP50: any = forecastOutput ? forecastOutput.uncertainty_cases.p50.CSG_Total_layered : null;

  const uncertaintycumulativeLayersP90: any = forecastOutput ? forecastOutput.uncertainty_cases.p90.CSG_Total_layered : null;

  const uncertaintycumulativeLayersPmean: any = forecastOutput ? forecastOutput.uncertainty_cases.pmean.CSG_Total_layered : null;

  const p10_cumulative_layers = uncertaintycumulativeLayersP10
    ? [
        {
          name: `p10`,
          type: "line",
          hideSymbol: true,
          color: theme.palette.redDark,
          lineWidth: 2,
          z: 2,
          data: uncertaintycumulativeLayersP10.cum_total_rates_mmscf.map((total_cum_mmscf: any, i: any) => [
            uncertaintycumulativeLayersP10.days[i],
            total_cum_mmscf,
          ]),
        },
      ]
    : [];
  const p50_cumulative_layers = uncertaintycumulativeLayersP50
    ? [
        {
          name: `p50`,
          type: "line",
          hideSymbol: true,
          color: theme.palette.redDark,
          lineWidth: 2,
          z: 2,
          data: uncertaintycumulativeLayersP50.cum_total_rates_mmscf.map((total_cum_mmscf: any, i: any) => [
            uncertaintycumulativeLayersP50.days[i],
            total_cum_mmscf,
          ]),
        },
      ]
    : [];

  const p90_cumulative_layers = uncertaintycumulativeLayersP90
    ? [
        {
          name: `p90`,
          type: "line",
          hideSymbol: true,
          color: theme.palette.redDark,
          lineWidth: 2,
          z: 2,
          data: uncertaintycumulativeLayersP90.cum_total_rates_mmscf.map((total_cum_mmscf: any, i: any) => [
            uncertaintycumulativeLayersP90.days[i],
            total_cum_mmscf,
          ]),
        },
      ]
    : [];

  const pmean_cumulative_layers = uncertaintycumulativeLayersPmean
    ? [
        {
          name: `pmean`,
          type: "line",
          hideSymbol: true,
          color: theme.palette.redDark,
          lineWidth: 2,
          z: 2,
          data: uncertaintycumulativeLayersPmean.cum_total_rates_mmscf.map((total_cum_mmscf: any, i: any) => [
            uncertaintycumulativeLayersPmean.days[i],
            total_cum_mmscf,
          ]),
        },
      ]
    : [];

  const multi_cumulative_layers = forecastOutput?.all_cases
    ? forecastOutput?.all_cases.map((item, m) => [
        {
          name: `Layer ${m + 1}`,
          type: "line",
          hideSymbol: true,
          color: pinklight,
          lineWidth: 1,
          z: 1,
          data: item.CSG_Total_layered.cum_total_rates_mmscf.map((total_cum_mmscf: any, k: any) => [item.CSG_Total_layered.days[k], total_cum_mmscf]),
        },
      ])
    : [];

  const total_cum_layers = [
    ...multi_cumulative_layers,
    ...p10_cumulative_layers,
    ...p50_cumulative_layers,
    ...p90_cumulative_layers,
    ...pmean_cumulative_layers,
  ];
  const total_cum_series = total_cum_layers.flat();

  return (
    <>
      <Stack className="PivotWrapper" grow={1}>
        <Pivot styles={{ itemContainer: { flex: "1" } }}>
          <PivotItem headerText="Gas rate">
            <FossilyticsChart
              id="koldun_csg_gas_profiles"
              isLoading={isLoading}
              hideLegend
              xAxes={csgGasProfilesXAxes}
              yAxes={csgGasProfilesYAxes}
              series={csgGasProfilesSeries}
            />
          </PivotItem>

          <PivotItem headerText="Water rate">
            <FossilyticsChart
              id="koldun_csg_water_rate_profiles"
              isLoading={isLoading}
              hideLegend
              xAxes={csgWaterProfilesXAxes}
              yAxes={csgWaterProfilesYAxes}
              series={total_w_series}
            />
          </PivotItem>

          <PivotItem headerText="Cumulative Production">
            <FossilyticsChart
              id="koldun_csg_cumes_profiles"
              isLoading={isLoading}
              hideLegend
              xAxes={csgCumesProfilesXAxes}
              yAxes={csgCumesProfilesYAxes}
              series={total_cum_series}
            />
          </PivotItem>
        </Pivot>
      </Stack>
    </>
  );
};

export default ModuleKoldunMcsimCsgOutputView;
