import { useCallback } from "react";
import { Dropdown, Stack } from "@fluentui/react";
import { UncertainParamDistribution, MonteCarloInputApplication, Output } from "@/models/koldun";
import { THEORETICAL_HELP_LINKS, renderLabelWithHelp } from "@/features/help-link";

import { DistributionOptions, DistributionProps, applicationOptions } from "./model";
import _ from "lodash";
import { getNewUncertainList } from "../../../utils";
import CommonDistribution from "@/modules/koldun/mcsim/csg/csg/Components/ModuleKoldunMcsimCsgInputGridTab/Components/UncertainParamInputs/CommonDistribution";

const GCIDistribution = ({ name, units, onChange, output, setOutput, measureNum, keyField, errorInputValidation }: DistributionProps) => {
  const uncertainKey = "gas_content_scf_ton";
  const value = output.measures[measureNum].measure_layers[0].uncertain_list[uncertainKey];
  const onDistributionChange = useCallback(
    (v: any) => {
      switch (v) {
        case UncertainParamDistribution.FIXED:
          onChange({
            distribution: UncertainParamDistribution.FIXED,
            application: value.application,
            value: 515.86,
          });
          break;
        case UncertainParamDistribution.UNIFORM:
          onChange({
            distribution: UncertainParamDistribution.UNIFORM,
            application: value.application,
            low: 490,
            high: 600,
          });
          break;
        case UncertainParamDistribution.TRIANGULAR:
          onChange({
            distribution: UncertainParamDistribution.TRIANGULAR,
            application: value.application,
            left: 490,
            mode: 515,
            right: 600,
          });
          break;
        case UncertainParamDistribution.LOG_NORMAL:
          onChange({
            distribution: UncertainParamDistribution.LOG_NORMAL,
            application: value.application,
            mean: 515,
            sigma: 20,
          });
          break;
        case UncertainParamDistribution.NORMAL:
          onChange({
            distribution: UncertainParamDistribution.NORMAL,
            application: value.application,
            mean: 515,
            std: 20,
          });
          break;
      }
    },
    [onChange, value.application]
  );

  const handleApplication = useCallback(
    (v: MonteCarloInputApplication) => {
      onChange({
        ...value,
        application: v,
      });
    },
    [onChange, value]
  );

  const handleOutputChange = (layer: number, key: string, value: any) => {
    setOutput((prev: Output | null) => {
      if (!prev) return prev;
      const prevOutput = _.cloneDeep(prev);
      prevOutput.measures[measureNum].measure_layers[layer].uncertain_list = getNewUncertainList(
        prevOutput.measures[measureNum].measure_layers[layer].uncertain_list,
        uncertainKey,
        key,
        value
      );
      return prevOutput;
    });
  };
  return (
    <Stack horizontal tokens={{ childrenGap: 20 }} style={{ marginBottom: "10px" }}>
      <Stack horizontal tokens={{ childrenGap: 20 }} style={{ width: 425 }}>
        <Stack grow={0.5} className="equal-size">
          <Dropdown
            label={`${name} distribution`}
            options={DistributionOptions}
            selectedKey={value.distribution}
            onChange={(_, v) => (v !== undefined ? onDistributionChange(v.key) : null)}
          />
        </Stack>
        <Stack grow={0.5} className="equal-size">
          <Dropdown
            label="Application"
            onRenderLabel={(props) => renderLabelWithHelp(THEORETICAL_HELP_LINKS.isotherm, props)}
            options={applicationOptions}
            selectedKey={value.application}
            onChange={(_, v) => (v !== undefined ? handleApplication(v.key as MonteCarloInputApplication) : null)}
          />
        </Stack>
      </Stack>

      <CommonDistribution
        value={value}
        measureNum={measureNum}
        output={output}
        units={units}
        updateUncertainList={handleOutputChange}
        uncertainKey={uncertainKey}
        keyField={keyField}
        errorInputValidation={errorInputValidation}
      />
    </Stack>
  );
};

export default GCIDistribution;
