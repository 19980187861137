import React, { CSSProperties, useState, useEffect, ReactElement } from "react";
import html2canvas from "html2canvas";
import "./style.css";
import { Button } from "@mui/material";

type ChildProps = {
  onStartCapture: () => void;
};

type Props = {
  onEndCapture: (url: string) => null;
  children: (props: ChildProps) => ReactElement;
};

const ScreenCapture = ({ onEndCapture, children }: Props): ReactElement => {
  const [active, setActive] = useState(false);

  const [windowSize, setWindowSize] = useState({
    windowWidth: 0,
    windowHeight: 0,
  });

  const [xyState, setXYState] = useState({
    startX: 0,
    startY: 0,
    endX: 0,
    endY: 0,
  });

  const [cropState, setCropState] = useState({
    cropPositionTop: 0,
    cropPositionLeft: 0,
    cropWidth: 0,
    cropHeigth: 0,
  });

  const [crossHairs, setCrossHairs] = useState({
    crossHairsTop: 0,
    crossHairsLeft: 0,
  });

  const [isMouseDown, setIsMouseDown] = useState(false);
  const [borderWidth, setBorderWidth] = useState<number | string | CSSProperties>(0);

  const handleWindowResize = () => {
    const windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    const windowHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

    setWindowSize({
      windowWidth,
      windowHeight,
    });
  };

  const handleEscape = (e: any) => {
    if (e.key === "Escape") onCancelCapture();
  };

  useEffect(() => {
    handleWindowResize();
    window.addEventListener("keyup", handleEscape);
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
      window.removeEventListener("keyup", handleEscape);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handStartCapture = () => setActive(true);

  const handleMouseMove = (e: any) => {
    const { windowHeight, windowWidth } = windowSize;
    const { startX, startY } = xyState;

    let cropPositionTop = startY;
    let cropPositionLeft = startX;

    const endX = e.clientX;
    const endY = e.clientY;
    const isStartTop = endY >= startY;
    const isStartBottom = endY <= startY;
    const isStartLeft = endX >= startX;
    const isStartRight = endX <= startX;
    const isStartTopLeft = isStartTop && isStartLeft;
    const isStartTopRight = isStartTop && isStartRight;
    const isStartBottomLeft = isStartBottom && isStartLeft;
    const isStartBottomRight = isStartBottom && isStartRight;

    let newBorderWidth = borderWidth;
    let cropWidth = 0;
    let cropHeigth = 0;

    if (isMouseDown) {
      if (isStartTopLeft) {
        newBorderWidth = `${startY}px ${windowWidth - endX}px ${windowHeight - endY}px ${startX}px`;
        cropWidth = endX - startX;
        cropHeigth = endY - startY;
      }

      if (isStartTopRight) {
        newBorderWidth = `${startY}px ${windowWidth - startX}px ${windowHeight - endY}px ${endX}px`;
        cropWidth = startX - endX;
        cropHeigth = endY - startY;
        cropPositionLeft = endX;
      }

      if (isStartBottomLeft) {
        newBorderWidth = `${endY}px ${windowWidth - endX}px ${windowHeight - startY}px ${startX}px`;
        cropWidth = endX - startX;
        cropHeigth = startY - endY;
        cropPositionTop = endY;
      }

      if (isStartBottomRight) {
        newBorderWidth = `${endY}px ${windowWidth - startX}px ${windowHeight - startY}px ${endX}px`;
        cropWidth = startX - endX;
        cropHeigth = startY - endY;
        cropPositionLeft = endX;
        cropPositionTop = endY;
      }
    }

    cropWidth *= window.devicePixelRatio;
    cropHeigth *= window.devicePixelRatio;
    cropPositionLeft *= window.devicePixelRatio;
    cropPositionTop *= window.devicePixelRatio;

    setCropState({
      cropWidth,
      cropHeigth,
      cropPositionTop: cropPositionTop,
      cropPositionLeft: cropPositionLeft,
    });
    setCrossHairs({
      crossHairsTop: e.clientY,
      crossHairsLeft: e.clientX,
    });
    setBorderWidth(newBorderWidth);
  };

  const handleMouseDown = (e: any) => {
    const startX = e.clientX as number;
    const startY = e.clientY as number;
    setXYState((prev) => ({
      ...prev,
      startX,
      startY,
    }));

    setCropState((prev) => ({
      ...prev,
      cropPositionTop: startY,
      cropPositionLeft: startX,
    }));
    setIsMouseDown(true);
    const { windowHeight, windowWidth } = windowSize;
    setBorderWidth(`${windowWidth}px ${windowHeight}px`);
  };

  const handleClickTakeScreenShot = () => {
    const { cropPositionTop, cropPositionLeft, cropWidth, cropHeigth } = cropState;
    const body = document.querySelector("body");

    if (body) {
      html2canvas(body).then((canvas) => {
        const croppedCanvas = document.createElement("canvas");
        const croppedCanvasContext = croppedCanvas.getContext("2d");

        croppedCanvas.width = cropWidth;
        croppedCanvas.height = cropHeigth;

        if (croppedCanvasContext) {
          croppedCanvasContext.drawImage(canvas, cropPositionLeft, cropPositionTop, cropWidth, cropHeigth, 0, 0, cropWidth, cropHeigth);
        }

        if (croppedCanvas) {
          onEndCapture(croppedCanvas.toDataURL() ?? "");
        }
      });
    }

    setCrossHairs((prev) => ({
      ...prev,
      crossHairsTop: 0,
      crossHairsLeft: 0,
    }));
  };

  const handleMouseUp = () => {
    handleClickTakeScreenShot();
    setActive(false);
    setIsMouseDown(false);
    setBorderWidth(0);
  };

  const onCancelCapture = () => {
    setCrossHairs((prev) => ({
      ...prev,
      crossHairsTop: 0,
      crossHairsLeft: 0,
    }));
    setActive(false);
  };

  const renderChild = (): ReactElement => {
    const props = {
      onStartCapture: handStartCapture,
    };

    if (typeof children === "function") {
      return children(props);
    }

    return children;
  };

  if (!active) {
    return <>{renderChild()}</>;
  }

  return (
    <>
      <div onMouseMove={handleMouseMove} onMouseDown={handleMouseDown} onMouseUp={handleMouseUp}>
        {renderChild()}
        <div className={`overlay ${isMouseDown && "highlighting"}`} style={{ borderWidth: `${borderWidth}` }} />
        <div className="crosshairs" style={{ left: crossHairs.crossHairsLeft + "px", top: crossHairs.crossHairsTop + "px" }} />
      </div>
      {active && (
        <Button
          style={{
            zIndex: 7,
            backgroundColor: "rgb(242, 143, 29)",
            color: "white",
            width: 200,
            marginLeft: "auto",
            marginRight: 20,
            top: -60,
          }}
          id={"cancel-screen-capture"}
          onClick={onCancelCapture}
          variant="contained"
        >
          Cancel
        </Button>
      )}
    </>
  );
};

export default ScreenCapture;
