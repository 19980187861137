import { useCallback, useMemo, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useTheme } from "@mui/material/styles";

import { TahkCsgStateResponse, postCalculateTahkCsgDataview } from "@/models/tahk";
import { TahkCsgDataViewPoints, TahkCsgDataview } from "@/models/tahk/dataview";
import { ErrorValidationDetail } from "@/models/ErrorInputValidation";
import dictionary from "@/constants/dictionary";
import { FossilyticsChartSeries } from "@/components/FossilyticsChart";
import { camelToSnakeCase, formatToScientific } from "@/utils/general";
import { dataTableHeaderStyles } from "../../constants/grid";
import { Row } from "@silevis/reactgrid";
import _ from "lodash";
import { TahkCsgCalculation } from "../../context/TahkCsgContext";

export type TahkCsgDataviewProps = {
  dataview?: TahkCsgDataview;
  isLoading: boolean;
  tabIndex: number;
  analysisIdentity?: { data_set_ids: string[]; group_id: string; project_id: string };
  setTahkCsgState: React.Dispatch<React.SetStateAction<TahkCsgStateResponse | null | undefined>>;
  setTahkCsgCalculation: React.Dispatch<React.SetStateAction<TahkCsgCalculation | null | undefined>>;
  tahkCsgCalculation?: TahkCsgCalculation | null;
};

const seriesKey = ["gasRate", "waterRate", "bottomholePressure", "tubingPressure", "casingPressure", "waterProduction", "gasProduction"];

const tableHeaderKey = [
  "dates",
  "gasRate",
  "waterRate",
  "casingPressure",
  "tubingPressure",
  "bottomholePressure",
  "gasProduction",
  "waterProduction",
];

const safeTahkDictionary: {
  [key: string]: string;
} = dictionary.tahk;

const safeTahkUnitDictionary: {
  [key: string]: string;
} = dictionary.tahkUnits;

export const dataTableHeader = () => {
  return {
    rowId: "header",
    cells: tableHeaderKey.map((key) => {
      return {
        type: "custom",
        text: safeTahkDictionary[key],
        style: dataTableHeaderStyles,
      };
    }),
  };
};

export const dataTableNotation = () => {
  return {
    rowId: "header",
    cells: tableHeaderKey.map((key) => {
      return {
        type: "custom",
        text: safeTahkUnitDictionary[key],
        style: dataTableHeaderStyles,
      };
    }),
  };
};

export const parseDataViewTableRow = (data: TahkCsgDataViewPoints) => {
  return data.dates.map((_, index) => {
    return {
      rowId: index,
      cells: tableHeaderKey.map((key, keyIndex) => {
        const val = data[camelToSnakeCase(key) as keyof TahkCsgDataViewPoints]
          ? data[camelToSnakeCase(key) as keyof TahkCsgDataViewPoints][index]
          : 0;

        let text = keyIndex === 0 ? new Date(val).toLocaleDateString("en-GB") : formatToScientific(val);

        return {
          type: "custom",
          text,
          style: { display: "flex", justifyContent: "center", zIndex: 0 },
        };
      }),
    };
  });
};

const dataTableColumn = tableHeaderKey.map((key) => {
  return {
    columnId: key,
    width: 80,
    justifyContent: "center",
  };
});

const useTahkCsgDataview = ({
  isLoading,
  dataview,
  analysisIdentity,
  tabIndex,
  setTahkCsgState,
  setTahkCsgCalculation,
  tahkCsgCalculation,
}: TahkCsgDataviewProps) => {
  const [errorInputValidation, setErrorInputValidation] = useState<ErrorValidationDetail[]>([]);
  const { palette } = useTheme();

  const {
    isLoading: isLoadingCalculation,
    isFetching,
    data,
  } = useQuery({
    queryKey: ["calculate-dataview-tahk-csg", dataview, analysisIdentity],
    queryFn: async () => {
      if (dataview && analysisIdentity) {
        return postCalculateTahkCsgDataview({
          dataview,
          analysis_identity: analysisIdentity,
        });
      }
    },
    select(data) {
      if (data?.error) {
        // this block is to determine error validation on input
        const responseError: any = data?.error;
        if (responseError && responseError.status === 422 && responseError.data.detail?.length !== 0) {
          // parse error message and data, set to state
          setErrorInputValidation(responseError.data.detail as ErrorValidationDetail[]);
        } else {
          console.log(data?.error);
        }
      } else if (errorInputValidation.length > 0) {
        setErrorInputValidation([]);
      }
      if (data?.data && !_.isEqual(tahkCsgCalculation?.dataview, data?.data)) {
        setTahkCsgCalculation({
          dataview: data?.data,
        });
      }
      return data?.data;
    },
    refetchOnWindowFocus: false,
    enabled: !!dataview && tabIndex === 0 && !isLoading,
    // error handling
    throwOnError(error: any) {
      if (error && error.code === 422 && error.detail?.length !== 0 && !_.isEqual(errorInputValidation, error.detail)) {
        // parse error message and data, set to state
        setErrorInputValidation(error.detail as ErrorValidationDetail[]);
      } else {
        console.log(error);
      }
      return false;
    },
  });

  const onChangeDataviewOption = useCallback(
    (key: string, value: any) => {
      setTahkCsgState((prev) => {
        if (!prev) return prev;

        return {
          ...prev,
          dataview: {
            ...prev.dataview,
            [key]: value,
          },
        };
      });
    },
    [setTahkCsgState]
  );

  const chartXAxes = useMemo(() => {
    return [{ name: dictionary.dataView.date, type: "time", color: palette.grey[900] }];
  }, [palette.grey]);

  const chartYAxes = useMemo(() => {
    return [
      {
        name: `${dictionary.tahk.pressure} ${dictionary.tahkUnits.pressure}`,
        type: "value",
        color: palette.grey[900],
        min: 0,
        position: "left",
        offset: 75,
        nameGap: 30,
      },
      {
        name: `${dictionary.tahk.gasRate} ${dictionary.tahkUnits.gasRate}`,
        type: "value",
        color: palette.error.main,
        min: 0,
        position: "left",
        offset: 0,
        nameGap: 30,
      },
      {
        name: `${dictionary.tahk.waterRate} ${dictionary.tahkUnits.waterRate}`,
        type: "value",
        color: palette.info.main,
        min: 0,
        position: "right",
        offset: 5,
        nameGap: 45,
      },
    ];
  }, [palette.error.main, palette.grey, palette.info.main]);

  const getSeriesColor = useCallback(
    (key: string) => {
      switch (key) {
        case "gasRate":
          return palette.error.main;
        case "waterRate":
          return palette.info.main;

        default:
          return palette.common.black;
      }
    },
    [palette]
  );

  const getYAxisIndex = (key: string) => {
    if (key.toLowerCase().includes("gas")) return 1;
    if (key.toLowerCase().includes("water")) return 2;
    return 0;
  };

  const chartSeries = useMemo(() => {
    if (!data) return [];
    // return all series first because we haven't decide what to show or not
    let pressureEnabled = false;
    const cleanSeries: FossilyticsChartSeries[] = [];
    seriesKey.forEach((key) => {
      if (data.clean_data[camelToSnakeCase(key) as keyof TahkCsgDataViewPoints]) {
        const yAxisIndex = getYAxisIndex(key);
        cleanSeries.push({
          name: `${safeTahkDictionary[key]} ${dictionary.dataView.clean}`,
          type: "line",
          color: getSeriesColor(key),
          data: data.clean_data
            ? data.clean_data.dates.map((d, i) => [d, data.clean_data?.[camelToSnakeCase(key) as keyof TahkCsgDataViewPoints][i]])
            : [],
          defaultDisabled: key.includes("Pressure") && pressureEnabled,
          yAxisIndex,
          hideSymbol: yAxisIndex === 0,
          lineWidth: 2,
        });
        if (key.includes("Pressure")) pressureEnabled = true;
      }
    });

    pressureEnabled = false;
    const rawSeries: FossilyticsChartSeries[] = [];

    seriesKey.forEach((key) => {
      if (data.raw_data[camelToSnakeCase(key) as keyof TahkCsgDataViewPoints]) {
        const yAxisIndex = getYAxisIndex(key);
        rawSeries.push({
          name: `${safeTahkDictionary[key]} ${dictionary.dataView.raw}`,
          type: "line",
          color: getSeriesColor(key),
          data: data.raw_data ? data.raw_data.dates.map((d, i) => [d, data.raw_data?.[camelToSnakeCase(key) as keyof TahkCsgDataViewPoints][i]]) : [],
          defaultDisabled: true,
          hideSymbol: yAxisIndex === 0,
          lineWidth: 2,
          yAxisIndex,
        });
        if (key.includes("Pressure")) pressureEnabled = true;
      }
    });

    return [...cleanSeries, ...rawSeries];
  }, [data, getSeriesColor]);

  const rawDataRows: Row<any>[] = useMemo(() => {
    if (!data) return [];
    return [dataTableHeader(), dataTableNotation(), ...parseDataViewTableRow(data.raw_data)];
  }, [data]);

  const cleanDataRows: Row<any>[] = useMemo(() => {
    if (!data) return [];
    return [dataTableHeader(), dataTableNotation(), ...parseDataViewTableRow(data.clean_data)];
  }, [data]);

  return {
    loadingState: isLoading || isLoadingCalculation || isFetching,
    errorInputValidation,
    onChangeDataviewOption,
    numberOfPoints: data?.number_raw_data_points,
    chartXAxes,
    chartYAxes,
    chartSeries,
    rawDataRows,
    dataTableColumn,
    cleanDataRows,
  };
};

export default useTahkCsgDataview;
