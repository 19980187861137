import React, { createContext, useContext, useState, useMemo, useCallback, useEffect } from "react";
import { useIsFetching, useQuery, useQueryClient } from "@tanstack/react-query";
import _ from "lodash";

import { DataSet, isDataSet, ModuleId, Group, Project } from "@/model";

import { CsvData } from "@/features/app/app.types";
import { spadDeclineDefaultState } from "../constants";

import useInterval from "@/utils/useInterval";

import { ApiError } from "@/models/APIGeneric";
import {
  SpadDeclineCoordinate,
  SpadDeclineState,
  postCalculationSpadDecline,
  postDragSpadDecline,
  postInitializeSpadDecline,
  postValidateSpadDecline,
  SpadDeclineStateScheme,
  SpadDeclineCalculationScheme,
} from "@/models/spad/decline";
import { ExportIntervalEnum, postExportSpadDecline } from "@/models/spad/decline/apiFetcher/export";
import { saveBlob } from "@/util";

type SpadDeclineContextState = {
  hideSidebar: boolean;
  isLoading: boolean;

  dataSets: DataSet[];
  group?: Group;
  project?: Project;
  tabIndex: number;
  setTabIndex: (index: number) => void;

  setCsvData: (csvData?: CsvData[]) => void;
  isApiError?: ApiError;
  setLocalLoading: (val: boolean) => void;

  spadDeclineState?: SpadDeclineState;
  setSpadDeclineState: React.Dispatch<React.SetStateAction<SpadDeclineState | undefined>>;
  currentModule?: ModuleId;
  onChartDrag: (handleBar: SpadDeclineCoordinate) => void;
  onClickCalculate: () => void;
  onClickExport: (interval: ExportIntervalEnum) => void;

  projects?: Project[];
  dataSetList?: DataSet[];
  selectedDataSets?: DataSet | DataSet[];
};

const SpadDeclineContext = createContext<SpadDeclineContextState>(spadDeclineDefaultState);

type SpadDeclineContextProps = {
  children: React.ReactNode;
  selectedDataSets?: DataSet | DataSet[];
  hideSidebar: boolean;
  currentModule?: ModuleId;
  isLoading: boolean;
  group?: Group;
  project?: Project;
  setCsvData: (csvData?: CsvData[]) => void;
  apiError?: ApiError;

  projects?: Project[];
  dataSets?: DataSet[];
  setApiError: (error?: ApiError) => void;
};

const defaultInterval = 30000;

const SpadDeclineProvider = ({
  children,
  selectedDataSets,
  hideSidebar,
  currentModule,
  isLoading,
  project,
  group,
  setCsvData,
  apiError,
  projects,
  dataSets: dataSetList,
  setApiError,
}: Readonly<SpadDeclineContextProps>) => {
  const [tabIndex, setTabIndex] = useState(0);

  const [localLoading, setLocalLoading] = useState(false);

  const [spadDeclineState, setSpadDeclineState] = useState<SpadDeclineState>();
  const [latestDataSets, setLatestDataSets] = useState<string[]>([]);

  const type = currentModule === ModuleId.SPAD_DECLINE_GAS ? "gas" : "oil";
  const dataSets = useMemo(() => {
    if (isDataSet(selectedDataSets)) return [selectedDataSets];
    return selectedDataSets ?? [];
  }, [selectedDataSets]);

  const moduleIdentity = useMemo(() => {
    return {
      data_set_ids: dataSets.map((dataSet) => dataSet.id),
      group_id: group?.id ?? "",
      project_id: project?.id ?? "",
    };
  }, [dataSets, group?.id, project?.id]);

  const spadDeclineType = currentModule === ModuleId.SPAD_DECLINE_GAS ? "gas" : "oil";
  const client = useQueryClient();

  const { isLoading: isLoadingInitialize } = useQuery({
    queryKey: ["initialize-spad-decline", dataSets, moduleIdentity],
    queryFn: async () => {
      return postInitializeSpadDecline(moduleIdentity, spadDeclineType);
    },
    select(data) {
      try {
        if (data?.data && !spadDeclineState) {
          const parsed = SpadDeclineStateScheme.parse(data.data);
          setSpadDeclineState(parsed);
          setLatestDataSets(dataSets.map((dataSet) => dataSet.id));
        }
      } catch (error: any) {
        const parseError = error?.issues.map((issue: any) => issue.message).join(", ");
        if (apiError?.message !== parseError) {
          setApiError({
            message: parseError,
          });
        }
      }
    },
    refetchOnWindowFocus: false,
    enabled: dataSets && dataSets.length > 0 && !!moduleIdentity.group_id && !!moduleIdentity.project_id && !spadDeclineState,
  });

  useEffect(() => {
    if (
      latestDataSets.length > 0 &&
      !_.isEqual(
        latestDataSets,
        dataSets.map((dataSet) => dataSet.id)
      )
    ) {
      setSpadDeclineState(undefined);
      client?.invalidateQueries();
    }
  }, [dataSets, latestDataSets, client]);

  const isFetching = useIsFetching();

  const onClickTab = useCallback(
    (index: number) => {
      setTabIndex(index);
      if (index !== 0) setCsvData();
    },
    [setCsvData]
  );

  // validate every 30 seconds
  useInterval(() => {
    if (spadDeclineState) {
      postValidateSpadDecline(
        { analysis_identity: moduleIdentity, analysis_option: spadDeclineState?.analysis_option, data_option: spadDeclineState?.data_option },
        type
      );
    }
  }, defaultInterval);

  const onClickCalculate = useCallback(async () => {
    if (!spadDeclineState) return;
    try {
      setLocalLoading(true);
      const res = await postCalculationSpadDecline(
        {
          analysis_identity: moduleIdentity,
          analysis_option: spadDeclineState?.analysis_option,
          data_option: spadDeclineState?.data_option,
        },
        type
      );
      if (res.data) {
        const safeParsedRes = SpadDeclineCalculationScheme.parse(res.data);
        setSpadDeclineState((prev) => {
          if (!prev) return prev;
          return {
            ...prev,
            ...safeParsedRes,
          };
        });
      }
    } catch (error: any) {
      console.log(error);
      const parseError = error?.issues.map((issue: any) => issue.message).join(", ");
      if (apiError?.message !== parseError && error?.issues?.length > 0) {
        setApiError({
          message: parseError,
        });
      }
    } finally {
      setLocalLoading(false);
    }
  }, [apiError?.message, moduleIdentity, setApiError, spadDeclineState, type]);

  const onClickExport = useCallback(
    async (interval: ExportIntervalEnum) => {
      if (!spadDeclineState) return;

      try {
        setLocalLoading(true);
        const response = await postExportSpadDecline({
          body: {
            analysis_identity: moduleIdentity,
            analysis_option: spadDeclineState?.analysis_option,
            data_option: spadDeclineState?.data_option,
          },
          type,
          interval,
        });

        if (response.data) {
          const fileName = `${response.headers["content-disposition"].split("filename=")[1]}`;
          saveBlob(response.data, fileName);
        }
      } catch (error) {
        console.log(error);
      }
    },
    [moduleIdentity, spadDeclineState, type]
  );

  const onChartDrag = useCallback(
    async (handlebar: SpadDeclineCoordinate) => {
      if (!spadDeclineState) return;
      try {
        const chartDragResponse = await postDragSpadDecline(
          {
            analysis_identity: moduleIdentity,
            analysis_option: spadDeclineState?.analysis_option,
            data_option: spadDeclineState?.data_option,
            handlebar,
          },
          spadDeclineType
        );
        if (chartDragResponse.data) {
          const safeParsedRes = SpadDeclineCalculationScheme.parse(chartDragResponse.data);
          setSpadDeclineState((prev) => {
            if (!prev) return prev;
            return {
              ...prev,
              ...safeParsedRes,
            };
          });
        }
      } catch (error: any) {
        console.log(error);
        const parseError = error?.issues.map((issue: any) => issue.message).join(", ");
        if (apiError?.message !== parseError && error?.issues?.length > 0) {
          setApiError({
            message: parseError,
          });
        }
      }
    },
    [apiError?.message, moduleIdentity, setApiError, spadDeclineState, spadDeclineType]
  );

  const state = useMemo(() => {
    return {
      isLoading: isLoading || isFetching > 0 || localLoading || isLoadingInitialize,
      hideSidebar,

      dataSets,
      tabIndex,
      setTabIndex: onClickTab,

      setCsvData,
      isApiError: apiError,
      setLocalLoading,
      spadDeclineState,
      setSpadDeclineState,
      currentModule,
      onChartDrag,
      onClickCalculate,
      onClickExport,
      projects,
      dataSetList,
      selectedDataSets,
      project,
      group,
    };
  }, [
    group,
    project,
    isLoading,
    isFetching,
    localLoading,
    isLoadingInitialize,
    hideSidebar,
    dataSets,
    tabIndex,
    onClickTab,
    setCsvData,
    apiError,
    spadDeclineState,
    currentModule,
    onChartDrag,
    onClickCalculate,
    onClickExport,
    projects,
    dataSetList,
    selectedDataSets,
  ]);

  return <SpadDeclineContext.Provider value={state}>{children}</SpadDeclineContext.Provider>;
};

export function useSpadDeclineState() {
  return useContext(SpadDeclineContext);
}

export default SpadDeclineProvider;
