import { Checkbox, Dropdown, IDropdownOption, Stack } from "@fluentui/react";
import React, { useCallback } from "react";
import { ModuleGazPzPvtAbnormalOptions } from "./model";
import { FormationCompressibility, RockType } from "../../../model";
import FossilyticsNumberField from "../../../components/fields/FossilyticsNumberField";

const formationCompressibilityOptions = [
  {
    key: `${FormationCompressibility.NEWMAN}-${RockType.SANDSTONE}`,
    text: "Newman Sandstone",
    data: {
      formation_compressibility_psi1: FormationCompressibility.NEWMAN,
      formation_rock_type: RockType.SANDSTONE,
    },
  },
  {
    key: `${FormationCompressibility.NEWMAN}-${RockType.LIMESTONE}`,
    text: "Newman Limestone",
    data: {
      formation_compressibility_psi1: FormationCompressibility.NEWMAN,
      formation_rock_type: RockType.LIMESTONE,
    },
  },
  {
    key: `${FormationCompressibility.HALL}-${RockType.SANDSTONE}`,
    text: "Hall General",
    data: {
      formation_compressibility_psi1: FormationCompressibility.HALL,
      formation_rock_type: RockType.SANDSTONE,
    },
  },
] as IDropdownOption[];

interface ModuleGazPzAbnormalInputGridProps {
  value: ModuleGazPzPvtAbnormalOptions | undefined;
  onChange: (value: ModuleGazPzPvtAbnormalOptions | undefined) => void;
}

function ModuleGazPzAbnormalInputGrid({ value, onChange }: ModuleGazPzAbnormalInputGridProps) {
  const setDefault = useCallback(() => {
    onChange({
      water_sat_perc: 30,
      formation_compressibility_psi1: FormationCompressibility.NEWMAN,
      formation_rock_type: RockType.SANDSTONE,
    });
  }, [onChange]);

  return (
    <Stack tokens={{ childrenGap: 20 }}>
      <Checkbox label="Abnormally pressured reservoir" checked={!!value} disabled onChange={(_, v) => (v ? setDefault() : onChange(undefined))} />

      {value ? (
        <Stack horizontal tokens={{ childrenGap: 20 }}>
          <Stack grow={1} className="equal-size" tokens={{ childrenGap: 5 }}>
            <FossilyticsNumberField
              label="Water saturation"
              suffix="%"
              min={0}
              max={100}
              value={value.water_sat_perc}
              onChange={(v) =>
                v !== undefined
                  ? onChange({
                      ...value,
                      water_sat_perc: v,
                    })
                  : null
              }
            />
            <FossilyticsNumberField
              label="Water compressibility override"
              suffix="psi⁻¹"
              value={value.water_compressibility_override}
              onChange={(v) =>
                onChange({
                  ...value,
                  water_compressibility_override: v,
                })
              }
            />
          </Stack>

          <Stack grow={1} className="equal-size" tokens={{ childrenGap: 5 }}>
            <FossilyticsNumberField
              label="Porosity"
              suffix="%"
              min={0}
              max={100}
              disabled={!!value.formation_compressibility_override}
              value={value.porosity_perc}
              onChange={(v) =>
                onChange({
                  ...value,
                  porosity_perc: v,
                })
              }
            />
            <Dropdown
              label="Formation compressibility method"
              options={formationCompressibilityOptions}
              disabled={!!value.formation_compressibility_override}
              selectedKey={`${value.formation_compressibility_psi1}-${value.formation_rock_type}`}
              onChange={(_, v) =>
                v !== undefined
                  ? onChange({
                      ...value,
                      ...v.data,
                    })
                  : null
              }
            />
          </Stack>

          <Stack grow={1} className="equal-size" tokens={{ childrenGap: 5 }}>
            <FossilyticsNumberField
              label="Formation compressibility override"
              suffix="psi⁻¹"
              disabled={!!value.porosity_perc}
              value={value.formation_compressibility_override}
              onChange={(v) =>
                onChange({
                  ...value,
                  formation_compressibility_override: v,
                })
              }
            />
          </Stack>
        </Stack>
      ) : undefined}
    </Stack>
  );
}

export default ModuleGazPzAbnormalInputGrid;
