import { ReactElement, JSXElementConstructor, ReactFragment, ReactPortal, useCallback, useState } from "react";
import { DataGrid, GridRowsProp, GridColDef, GridCellModesModel, GridCellParams, GridCellModes, GridRowSelectionModel } from "@mui/x-data-grid";

import "./MultiLayerTable.css";

export interface MultiLayerProps {
  field: string;
  headerName: string;
  renderHeader?: () => ReactElement;
  headerUnits: string;
  width: number;
  type: string;
  valueGetter?: (params: any) => any;

  renderCell?: (
    params: any
  ) =>
    | ReactElement<any, string | JSXElementConstructor<any> | (new (props: any) => React.Component<any, any, any>)>
    | string
    | number
    | boolean
    | null
    | undefined
    | ReactFragment
    | ReactPortal
    | boolean
    | null
    | undefined;
}

export interface MultiLayerTableProps {
  columns: MultiLayerProps[];
  rows: GridRowsProp;
  handleChanges: (e: any) => void;
}

const MultiLayerTable = ({ rows, columns, handleChanges }: MultiLayerTableProps) => {
  const [cellModesModel, setCellModesModel] = useState<GridCellModesModel>({});
  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);
  const customColumns = [
    ...(columns.map((column) => {
      return {
        field: column.field,
        headerName: column.headerName,
        width: column.width,
        type: column.type,
        disableClickEventBubbling: true,
        disableColumnMenu: true,
        disableColumnReorder: true,
        sortable: false,
        headerAlign: "center",
        headerClassName: "header",
        align: "center",
        editable: true,
        valueGetter: column.valueGetter,
        renderCell: column.renderCell,
        renderHeader: () => (
          <div
            style={{
              overflow: "hidden",
              whiteSpace: "normal",
              lineHeight: "20px",
              textAlign: "center",
            }}
          >
            <div style={{ display: "flex", justifyContent: "center" }}>{!!column.renderHeader ? column.renderHeader() : column.headerName}</div>
            <div>{column.headerUnits}</div>
          </div>
        ),
      };
    }) as GridColDef[]),
  ] as GridColDef[];

  const handleCellModesModelChange = useCallback((newModel: any) => {
    setCellModesModel(newModel);
  }, []);

  const handleCellClick = useCallback((params: GridCellParams) => {
    setCellModesModel((prevModel) => {
      return {
        // Revert the mode of the other cells from other rows
        ...Object.keys(prevModel).reduce(
          (acc, id) => ({
            ...acc,
            [id]: Object.keys(prevModel[id]).reduce(
              (acc2, field) => ({
                ...acc2,
                [field]: { mode: GridCellModes.View },
              }),
              {}
            ),
          }),
          {}
        ),
        [params.id]: {
          // Revert the mode of other cells in the same row
          ...Object.keys(prevModel[params.id] || {}).reduce((acc, field) => ({ ...acc, [field]: { mode: GridCellModes.View } }), {}),
          [params.field]: { mode: GridCellModes.Edit },
        },
      };
    });
  }, []);

  return (
    <>
      <DataGrid
        rows={rows}
        columns={customColumns}
        rowHeight={25}
        columnHeaderHeight={80}
        sx={{
          overflow: "hidden",
          fontFamily: `"Baloo 2", sans-serif`,
          border: 0,
          "& .MuiDataGrid-cell": {
            border: "1px solid #e8e8e8",
            borderRight: "1px solid #e8e8e8",
            borderTop: 0,
            borderLeft: "1px solid #e8e8e8",
            // add more css for customization
          },
          "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button": {
            WebkitAppearance: "none",
            margin: 0,
          },
          "input[type=number]": {
            MozAppearance: "textfield",
          },
        }}
        onCellModesModelChange={handleCellModesModelChange}
        processRowUpdate={(e) => {
          handleChanges(e);

          return e;
        }}
        cellModesModel={cellModesModel}
        onCellClick={handleCellClick}
        onProcessRowUpdateError={(e) => {
          console.log(e);
        }}
        hideFooter
        onRowSelectionModelChange={(newRowSelectionModel) => {
          setRowSelectionModel(newRowSelectionModel);
        }}
        rowSelectionModel={rowSelectionModel}
      />
    </>
  );
};

export default MultiLayerTable;
