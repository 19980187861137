import { Stack, useTheme, Text } from "@fluentui/react";
import FossilyticsNumberField from "../../../../../../components/fields/FossilyticsNumberField";
import { ModuleTahkForecastGasOilOptions } from "../../model";
import { useCallback } from "react";

interface HorizontalSelectInputsProps {
  value: ModuleTahkForecastGasOilOptions;
  onChange: React.Dispatch<React.SetStateAction<ModuleTahkForecastGasOilOptions>>;
}

const HorizontalSelectInputs = ({ value, onChange }: HorizontalSelectInputsProps) => {
  const theme = useTheme();
  const itemStyle = {
    boxShadow: theme.effects.elevation4,
    padding: 15,
    borderRadius: theme.effects.roundedCorner4,
  } as React.CSSProperties;

  const handleChange = useCallback(
    (input: { [key: string]: any }) => {
      if (!value) return;
      onChange({
        ...value,
        res_prop: {
          ...value.res_prop,
          ...input,
        },
      });
    },
    [onChange, value]
  );

  return (
    <Stack.Item style={itemStyle}>
      <Text styles={{ root: { color: theme.palette.themePrimary } }}>Areas and Dimensions</Text>
      <Stack horizontal tokens={{ childrenGap: 20 }}>
        <Stack grow={1} className="equal-size" tokens={{ childrenGap: 5 }}>
          <FossilyticsNumberField
            label="Permeability(X-axis)"
            suffix="md"
            value={value.res_prop.permeability_xaxis_md}
            onChange={(v) => (v !== undefined ? handleChange({ permeability_xaxis_md: v }) : null)}
          />
          <FossilyticsNumberField
            label="Permeability(Y-axis)"
            suffix="md"
            value={value.res_prop.permeability_yaxis_md}
            onChange={(v) =>
              v !== undefined
                ? handleChange({
                    permeability_yaxis_md: v,
                  })
                : null
            }
          />
          <FossilyticsNumberField
            label="Permeability(Z-axis)"
            suffix="md"
            value={value.res_prop.permeability_zaxis_md}
            onChange={(v) =>
              v !== undefined
                ? handleChange({
                    permeability_zaxis_md: v,
                  })
                : null
            }
          />
          <FossilyticsNumberField
            label="Horizontal well center position (X-axis)"
            suffix="ft"
            value={value.res_prop.well_position_xaxis_ft}
            onChange={(v) =>
              v !== undefined
                ? handleChange({
                    well_position_xaxis_ft: v,
                  })
                : null
            }
          />
          <FossilyticsNumberField
            label="Horizontal well center position (Y-axis)"
            suffix="ft"
            value={value.res_prop.well_position_yaxis_ft}
            onChange={(v) =>
              v !== undefined
                ? handleChange({
                    well_position_yaxis_ft: v,
                  })
                : null
            }
          />
          <FossilyticsNumberField
            label="Horizontal well center position (Z-axis)"
            suffix="ft"
            value={value.res_prop.well_position_zaxis_ft}
            onChange={(v) =>
              v !== undefined
                ? handleChange({
                    well_position_zaxis_ft: v,
                  })
                : null
            }
          />
        </Stack>
        <Stack grow={1} className="equal-size" tokens={{ childrenGap: 5 }}>
          <FossilyticsNumberField
            label="Reservoir Width (X-axis)"
            suffix="ft"
            value={value.res_prop.res_width_xaxis_ft}
            onChange={(v) =>
              v !== undefined
                ? handleChange({
                    res_width_xaxis_ft: v,
                  })
                : null
            }
          />
          <FossilyticsNumberField
            label="Reservoir Length (Y-axis)"
            suffix="ft"
            value={value.res_prop.res_length_yaxis_ft}
            onChange={(v) =>
              v !== undefined
                ? handleChange({
                    res_length_yaxis_ft: v,
                  })
                : null
            }
          />
          <FossilyticsNumberField
            label="Net Pay"
            suffix="ft"
            value={value.res_prop.res_height_zaxis_ft}
            onChange={(v) =>
              v !== undefined
                ? handleChange({
                    res_height_zaxis_ft: v,
                  })
                : null
            }
          />
          <FossilyticsNumberField
            label="Horizontal Section Length (Y-axis)"
            suffix="ft"
            value={value.res_prop.horizontal_well_length_yaxis_ft}
            onChange={(v) =>
              v !== undefined
                ? handleChange({
                    horizontal_well_length_yaxis_ft: v,
                  })
                : null
            }
          />
        </Stack>
        <Stack grow={1} className="equal-size" tokens={{ childrenGap: 5 }}></Stack>
      </Stack>
    </Stack.Item>
  );
};

export default HorizontalSelectInputs;
