import { CSSProperties, useMemo } from "react";
import { Column, ReactGrid, Row } from "@silevis/reactgrid";
import { useTheme } from "@fluentui/react";

import { getBestNumberFormat } from "../util";
import "./FossilyticsGrid.css";
import LoadingIndicator from "./LoadingIndicator";

export interface FossilyticsGridColumnGroup {
  header: string;
  colspan: number;
}

export interface FossilyticsGridColumn {
  key: string;
  type: "number" | "text" | "header" | "date";
  header: string;
  width?: number;
  editable?: boolean;
}

interface FossilyticsGridProps {
  columnGroups?: FossilyticsGridColumnGroup[];
  columns: FossilyticsGridColumn[];
  data: any[][];
  style?: CSSProperties;
  isLoading?: boolean;
}

const FossilyticsGrid = ({ columnGroups, columns, data, style, isLoading = false }: FossilyticsGridProps) => {
  const theme = useTheme();

  const gridColumns = useMemo<Column[]>(
    () =>
      columns.map((c) => ({
        columnId: c.key,
        width: c.width ? c.width : 75,
      })),
    [columns]
  );

  const gridRows = useMemo<Row[]>(() => {
    const columnGroupRow = columnGroups
      ? ({
          rowId: "groups",
          cells: columnGroups.flatMap((g, colIdx) => [
            {
              type: "header" as "header",
              text: g.header,
              colspan: g.colspan,
              className: colIdx > 0 ? "rg-cell-group-border" : undefined,
            },
            ...(g.colspan > 1
              ? Array.from(Array(g.colspan - 1).keys()).map(() => ({
                  type: "header" as "header",
                  text: "",
                }))
              : []),
          ]),
        } as Row)
      : null;

    const getGroupClassName = (colIdx: number) => {
      const isGroupBorder = columnGroupRow && colIdx > 0 && columnGroupRow.cells[colIdx].className;
      return isGroupBorder ? "rg-cell-group-border" : "";
    };

    return [
      ...(columnGroupRow ? [columnGroupRow] : []),
      {
        rowId: "header",
        cells: columns.map((c, j) => ({
          type: "header",
          text: c.header,
          className: (c.type === "number" ? "rg-header-cell-num" : "") + " " + getGroupClassName(j),
        })),
      },
      ...data.map((d, i) => ({
        rowId: i,
        cells: columns.map((c, j) => ({
          type: c.type as any,
          value: c.type === "number" ? d[j] : undefined,
          format: c.type === "number" ? getBestNumberFormat(d[j]) : undefined,
          text: c.type === "text" || c.type === "header" ? d[j] ?? "" : undefined,
          date: c.type === "date" ? d[j] : undefined,
          nonEditable: !c.editable,
          className: getGroupClassName(j),
          style: {
            background: !c.editable ? theme.palette.neutralLight : undefined,
          },
        })),
      })),
    ];
  }, [theme, columnGroups, columns, data]);

  if (isLoading) return <LoadingIndicator />;
  if (columns.length === 0 || columnGroups?.length === 0 || data.length === 0) return <></>;

  return (
    <div style={style}>
      <ReactGrid columns={gridColumns} rows={gridRows} enableRangeSelection enableColumnSelection stickyTopRows={columnGroups ? 2 : 1} />
    </div>
  );
};

export default FossilyticsGrid;
