import { DialogType, IColumn, IDropdownOption } from "@fluentui/react";
import {
  Field,
  FormationCompressibility,
  RockType,
  WaterCompressibility,
  ZMethod,
  GasViscosityMethod,
  OilDensityMethod,
  WaterViscosityMethod,
  SolGasMethod,
} from "@/model";
import { FossilyticsGridColumn } from "@/components/FossilyticsGrid";

export const pressureTypeOptions = [
  { key: Field.CASING, text: "Casing" },
  { key: Field.TUBING, text: "Tubing" },
] as IDropdownOption[];

export const dataTableColumns = [
  { key: "parameter", name: "Parameter", fieldName: "parameter", isRowHeader: true, minWidth: 180 },
  { key: "value", name: "Value", fieldName: "value", minWidth: 60 },
] as IColumn[];

export const modalProps = {
  isBlocking: false,
};

export const dialogContentProps = {
  type: DialogType.largeHeader,
  title: "P/z Manual Points",
  subText: "Please enter manual points for P/z below. Click close when you are done creating points.",
};
export const pvtSettingStateKey = "fmb_pvt_options";
export const pvtOptionDefaultValue = {
  initial_pressure_psia: 4400,
  formation_temp_of: 240,
  net_pay_ft: 20,
  wellbore_rad_ft: 0.3,
  formation_compressibility_psi1: FormationCompressibility.NEWMAN,
  formation_rock_type: RockType.SANDSTONE,
  water_compressibility_psi1: WaterCompressibility.BEGGS_BRILL,
  z_method: ZMethod.DPR,

  gas_gravity: 1,
  n2_perc: 0,
  co2_perc: 0,
  h2s_perc: 0,
  water_sat_perc: 30,
  porosity_perc: 13,
  gas_viscosity_method: GasViscosityMethod.GONZALES_EAKIN,
};

export const wellboreOptionSettingStateKey = "fmb_wellbore_options";
export const wellboreOptionDefaultValue = {
  include_oil: false,
  oil_api_psia: 30,
  oil_pb_psia: 1000,
  oil_density_method: OilDensityMethod.EOS,
  sol_gas_method: SolGasMethod.VASQUEZ_BEGGS,
  include_water: false,
  water_salinity_ppm: 0,
  water_viscosity_method: WaterViscosityMethod.MCCAIN,
  tubing_diameter_in: 2.4,
  tubing_roughness_in: 0.0006,
  tubing_depth_ft: 8900,
  wellhead_temp_of: 90,
  well_angle_deg: 90,
  num_inc: 10,
};

export const wellboreEnableSettingStateKey = "fmb_wellbore_enabled";
export const fmbSkinSettingStateKey = "fmb_skin";

export const manualPointDefaultValue = Array.from(Array(20).keys()).map(() => [0, 0]);
export const manualPointSettingStateKey = "fmb_manual_points";

export const fmbUserLineSettingStateKey = "fmb_is_user_line";
export const fmbLineSettingStateKey = "fmb_line";

// input grid
export const formationCompressibilityOptions = [
  {
    key: `${FormationCompressibility.NEWMAN}-${RockType.SANDSTONE}`,
    text: "Newman Sandstone",
    data: {
      formation_compressibility_psi1: FormationCompressibility.NEWMAN,
      formation_rock_type: RockType.SANDSTONE,
    },
  },
  {
    key: `${FormationCompressibility.NEWMAN}-${RockType.LIMESTONE}`,
    text: "Newman Limestone",
    data: {
      formation_compressibility_psi1: FormationCompressibility.NEWMAN,
      formation_rock_type: RockType.LIMESTONE,
    },
  },
  {
    key: `${FormationCompressibility.HALL}-${RockType.SANDSTONE}`,
    text: "Hall General",
    data: {
      formation_compressibility_psi1: FormationCompressibility.HALL,
      formation_rock_type: RockType.SANDSTONE,
    },
  },
] as IDropdownOption[];

export const waterCompressibilityOptions = [{ key: WaterCompressibility.BEGGS_BRILL, text: "Beggs/Brill" }] as IDropdownOption[];

export const zMethodOptions = [
  { key: ZMethod.DPR, text: "DPR (default)" },
  { key: ZMethod.BEGGS_BRILL, text: "Beggs/Brill" },
  { key: ZMethod.PAPAY, text: "Papay (simple)" },
  { key: ZMethod.DRANCHUCK_ABU_KASSEM, text: "Dranchuck/Abu-Kassem" },
] as IDropdownOption[];

// wellbore input grid
export const oilDensityMethodOptions = [{ key: OilDensityMethod.EOS, text: "EOS" }] as IDropdownOption[];

export const solGasMethodOptions = [
  { key: SolGasMethod.VASQUEZ_BEGGS, text: "Vasquez-Beggs" },
  { key: SolGasMethod.STANDING, text: "Standing" },
  { key: SolGasMethod.GLASO, text: "Glaso" },
  { key: SolGasMethod.LASATER, text: "Lasater" },
  { key: SolGasMethod.PETROSKY, text: "Petrosky" },
] as IDropdownOption[];

export const wtrViscMethodOptions = [
  { key: WaterViscosityMethod.MCCAIN, text: "McCain" },
  { key: WaterViscosityMethod.LOUISIANA_STATE_UNI, text: "Louisiana State University" },
  { key: WaterViscosityMethod.BRILL_BEGGS, text: "Brill-Beggs" },
] as IDropdownOption[];

// output view
export const outputViewTableColumns = [
  { key: "q", type: "number", header: "q" },
  { key: "Q", type: "number", header: "Q" },
  { key: "Pwf", type: "number", header: "Pwf" },
  { key: "nRate", type: "number", header: "Norm. Rate" },
  { key: "nQ", type: "number", header: "Norm. Q" },
  { key: "Pr", type: "number", header: "Pr" },
  { key: "z", type: "number", header: "z" },
  { key: "Pr/z", type: "number", header: "Pr/z" },
  { key: "mp(Pr*)", type: "number", header: "mp(Pr*)" },
  { key: "Pr*", type: "number", header: "Pr*" },
  { key: "z*", type: "number", header: "z*" },
  { key: "Pr*/z*", type: "number", header: "Pr*/z*" },
  { key: "PI", type: "number", header: "PI" },
] as FossilyticsGridColumn[];
