import React from "react";
import { Stack, Pivot, PivotItem } from "@fluentui/react";
import { Column, Row } from "@silevis/reactgrid";

// component
import ReactGridTable from "@/components/ReactGridTable/ReactGridTable";
import FossilyticsChart, { FossilyticsChartAxis, FossilyticsChartSeries } from "@/components/FossilyticsChart";

// utils
import useThemeStyling from "@/utils/useThemeStyling";

// constant
import dictionary from "@/constants/dictionary";
import LoadingIndicator from "@/components/LoadingIndicator";

export type AutoRtaForecastCalculationProps = {
  isLoading: boolean;
  dataTableRow: Row[];
  forecastXAxes: FossilyticsChartAxis[];
  forecastYAxes: FossilyticsChartAxis[];
  cumulativeGasYAxes: FossilyticsChartAxis[];
  forecastCalcSeries: FossilyticsChartSeries[];
  cumulativeGasChartSeries: FossilyticsChartSeries[];
};

const dataTableColumns = [
  { columnId: "dates", width: 200 },
  { columnId: "cumulative_gas", width: 180 },
  { columnId: "flowing_pressures", width: 180 },
  { columnId: "gas_rates", width: 180 },
] as Column[];

const AutoRtaForecastCalculation = ({
  isLoading,
  dataTableRow,
  forecastXAxes,
  forecastYAxes,
  forecastCalcSeries,
  cumulativeGasChartSeries,
  cumulativeGasYAxes,
}: AutoRtaForecastCalculationProps) => {
  const { theme } = useThemeStyling();

  return (
    <Stack
      style={{
        boxShadow: theme.effects.elevation4,
        padding: 15,
        borderRadius: theme.effects.roundedCorner4,
      }}
      grow={1}
    >
      <Pivot styles={{ itemContainer: { flex: "1" } }}>
        <PivotItem headerText="Chart">
          {forecastCalcSeries.length === 0 || isLoading ? (
            <LoadingIndicator />
          ) : (
            <div style={{ height: 700 }}>
              <FossilyticsChart
                id="auto-rta-forecast-chart"
                isLoading={isLoading}
                xAxes={forecastXAxes}
                yAxes={forecastYAxes}
                series={forecastCalcSeries}
              />
            </div>
          )}
        </PivotItem>
        <PivotItem headerText="Cumulative Gas Chart">
          {cumulativeGasChartSeries.length === 0 || isLoading ? (
            <LoadingIndicator />
          ) : (
            <div style={{ height: 700 }}>
              <FossilyticsChart
                id="auto-rta-forecast-cumulative-gas-chart"
                isLoading={isLoading}
                xAxes={forecastXAxes}
                yAxes={cumulativeGasYAxes}
                series={cumulativeGasChartSeries}
              />
            </div>
          )}
        </PivotItem>
        <PivotItem headerText={dictionary.modelDataTable} style={{ position: "relative", margin: "auto", paddingTop: 20 }}>
          {isLoading ? (
            <LoadingIndicator />
          ) : (
            <ReactGridTable
              viewWidth={"100%"}
              viewHeight={650}
              columns={dataTableColumns}
              rows={dataTableRow}
              enableRangeSelection
              enableColumnSelection
              stickyTopRows={1}
            />
          )}
        </PivotItem>
      </Pivot>
    </Stack>
  );
};

export default AutoRtaForecastCalculation;
