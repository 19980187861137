import { useMemo } from "react";

import useThemeStyling from "@/utils/useThemeStyling";
import { FossilyticsChartAxis, FossilyticsChartSeries } from "@/components/FossilyticsChart";

import { DataViewCalculation, DataViewData } from "@/models/gaz/autoRta";
import { ModuleAutoRTADataViewField, getFieldName } from "../../model";

const useGazAutoRtaDataViewChart = (dataViewCalculate: DataViewCalculation) => {
  const { theme } = useThemeStyling();
  const moduleFields = useMemo<{ [k in keyof DataViewData]?: ModuleAutoRTADataViewField }>(
    () => ({
      gas_rate: {
        name: "Gas rate",
        yAxis: "Gas Rate (MMscf/D)",
        color: theme.palette.red,
      },
      tubing_pressure: {
        name: "Tubing pressure",
        yAxis: "Pressure (psia)",
        color: theme.palette.black,
      },
      casing_pressure: {
        name: "Casing pressure",
        yAxis: "Pressure (psia)",
        color: theme.palette.black,
      },
    }),
    [theme]
  );

  const xAxes = useMemo<FossilyticsChartAxis[]>(() => {
    return [{ name: "Date", type: "time", color: theme.palette.black }];
  }, [theme]);

  const yAxes = useMemo<FossilyticsChartAxis[]>(() => {
    const yAxesNames = Array.from(new Set(Object.values(moduleFields).map((f) => f.yAxis)));
    return [
      ...yAxesNames.map((yAxis) => ({ name: yAxis, type: "value", color: theme.palette.black, min: 0 })),
      { name: "End Date Axis", show: false, type: "value", color: "", min: -8e10, max: +8e10 },
    ];
  }, [theme, moduleFields]);

  const rawSeries = useMemo<FossilyticsChartSeries[]>(() => {
    if (!dataViewCalculate.raw_data) return [];
    return Object.keys(moduleFields)
      .map((v) => v as keyof DataViewData)
      .map((f) => ({
        name: `${getFieldName(f)} (raw data)`,
        type: "scatter",
        color: moduleFields[f]!.color,
        data: dataViewCalculate.raw_data ? dataViewCalculate.raw_data.dates.map((d, i) => [d, dataViewCalculate.raw_data?.[f][i]]) : [],
      }));
  }, [moduleFields, dataViewCalculate.raw_data]);

  const cleanedSeries = useMemo<FossilyticsChartSeries[]>(() => {
    if (!dataViewCalculate.clean_data) return [];
    return Object.keys(moduleFields)
      .map((v) => v as keyof DataViewData)
      .map((f) => ({
        name: `${getFieldName(f)} (clean data)`,
        type: "line",
        color: moduleFields[f]!.color,
        data: dataViewCalculate.clean_data ? dataViewCalculate.clean_data.dates.map((d, i) => [d, dataViewCalculate.clean_data?.[f][i]]) : [],
      }));
  }, [dataViewCalculate.clean_data, moduleFields]);

  const allSeries = useMemo<FossilyticsChartSeries[]>(
    () => [
      ...rawSeries.map((s, i) => ({
        ...s,
        defaultDisabled: true,
        yAxisIndex: yAxes.findIndex((ya) => ya.name === moduleFields[Object.keys(moduleFields)[i] as keyof DataViewData]?.yAxis),
      })),
      ...cleanedSeries.map((s, i) => ({
        ...s,
        defaultDisabled: false,
        yAxisIndex: yAxes.findIndex((ya) => ya.name === moduleFields[Object.keys(moduleFields)[i] as keyof DataViewData]?.yAxis),
      })),
    ],
    [rawSeries, cleanedSeries, yAxes, moduleFields]
  );
  return {
    moduleFields,
    theme,
    xAxes,
    yAxes,
    allSeries,
  };
};

export default useGazAutoRtaDataViewChart;
