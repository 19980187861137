import { CSSProperties } from "react";
import { DialogType, IColumn, IDropdownOption, Icon } from "@fluentui/react";

import { DataSetType } from "@/model";

export const treeViewDefaultState = {
  isLoading: false,
  rootId: "",
  showAll: false,
  setShowAll: () => {},
  setHideProjects: () => {},

  setShowFilters: () => {},
  showFilters: false,
  filterButtonRef: undefined,
  filterTypes: [],
  setFilterTypes: () => {},
  clearFilters: () => {},
  setFilter: () => {},
  isDataSetsLoading: false,
  isProjectEmpty: false,
  dataSetSelection: undefined,
  dataSetItems: [],
  dragDropEvents: undefined,
  projects: [],
  hideProjects: false,
  projectsListWrapper: undefined,
  projectSelection: undefined,
  projectsList: undefined,
  projectItems: [],
  projectGroups: [],
  renderGroupHeader: undefined,
  projectDragDropEvents: undefined,
  projectDragDropHelper: undefined,
  renderGroupCell: undefined,
  showContextMenu: false,
  contextMenuItems: [],
  setShowContextMenu: () => {},
  onDragWellList: () => {},

  refreshDataSets: () => {},
  // dialog props

  copyFromSelected: false,
  setCopyFromSelected: () => {},
  newProjectName: "",
  setNewProjectName: () => {},
  createNewProject: () => {},
  deleteProject: () => {},
  editProjectName: "",
  setEditProjectName: () => {},
  editExistingProject: () => {},

  createNewGroups: () => {},
  editGroupName: "",
  setEditGroupName: () => {},
  editExistingGroup: () => {},
  setNewGroupsNames: () => {},
  newGroupsNames: [],
  refreshProjects: () => {},
  removeDataSets: () => {},
  setActiveDialog: () => {},

  mappedItemKeyTotal: {},
  onDragEnd: () => {},

  onSelectDataSet: () => {},
  onSelectGroup: () => {},
  onSelectProject: () => {},
  setActiveKey: () => {},
  deleteGroup: () => {},
  selectedItems: [],
  selectedDataSet: [],
  setSelectedDataSet: () => {},
  startDrag: false,
  setStartDrag: () => {},
  dataSets: [],
  shiftHeld: false,
  commandControlHeld: false,

  startDraggingWellMove: false,
  setStartDraggingWellMove: () => {},
  onSelectItem: () => {},
  selectedKeys: [],
  setSelectedWellKeys: () => {},
  selectedWellKeys: [],
  onDropWell: () => {},
};

export const removeDataSetsDialogContentProps = {
  type: DialogType.largeHeader,
  title: "Remove Wells",
  subText: "Warning: This operation cannot be undone!",
};

export const iconStyle = {
  fontSize: 0,
  position: "relative",
  top: 3,
} as CSSProperties;

export const columns = [
  {
    key: "type",
    fieldName: "type",
    isIconOnly: true,
    minWidth: 16,
    maxWidth: 16,
    onRender: (item: any) => (item.data?.type ? <Icon iconName={`data-set-${item.data.type}`} style={iconStyle} /> : <></>),
  },
  { key: "name", fieldName: "name", isRowHeader: true },
] as IColumn[];

export const filterTypeOptions = [
  { key: DataSetType.GAS_WELL, text: "Gas well" },
  { key: DataSetType.OIL_WELL, text: "Oil well" },
] as IDropdownOption[];

export const loadingStyle = { pointerEvents: "none", filter: "grayscale(1)", opacity: 0.6 } as React.CSSProperties;
