import React, { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import ReactMarkdown from "react-markdown";
import { shallow } from "zustand/shallow";

import { useAppStore } from "@/features/app";
import { AnnouncementResponse } from "../model";

const Announcement = () => {
  const { getRequest } = useAppStore(
    (state) => ({
      getRequest: state.getRequest,
    }),
    shallow
  );

  const [announcements, setAnnouncements] = useState<AnnouncementResponse[]>([]);

  useEffect(() => {
    getRequest("/announcements")
      .then((res) => setAnnouncements(res))
      .catch((e) => {
        // no-op
      });
  }, [getRequest]);

  return (
    <Box sx={{ position: "relative" }}>
      <Box sx={{ position: "absolute", top: "10vh", left: "3vh", right: "5vh", zIndex: 1 }}>
        {announcements.map((announcement) => (
          <Alert key={announcement.id} style={{ marginTop: "1vh" }} severity={announcement.maintenance_mode ? "error" : "info"}>
            <ReactMarkdown components={{ p: "span" }}>{announcement.content}</ReactMarkdown>
          </Alert>
        ))}
      </Box>
    </Box>
  );
};

export default Announcement;
