import { useMemo } from "react";
import { useTheme } from "@fluentui/react";

import FossilyticsChart, { FossilyticsChartAxis, FossilyticsChartCustomDataZoom, FossilyticsChartSeries } from "@/components/FossilyticsChart";
import { DataSet, ModuleId } from "@/model";
import { useAppStore } from "@/features/app";

interface ModuleQqPlotsProps {
  isLoading: boolean;
  dataSets: DataSet[];
  unit: string;
  ratesCumSeries: FossilyticsChartSeries[];
}

export const ModuleQqPlots = ({ isLoading, dataSets, unit, ratesCumSeries }: ModuleQqPlotsProps) => {
  const theme = useTheme();

  const currentModule = useAppStore((state) => state.currentModule);

  const ratesCumXAxes = useMemo<FossilyticsChartAxis[]>(
    () => [{ name: `Cum. production (${unit})`, type: "value", color: theme.palette.black }],
    [theme.palette.black, unit]
  );

  const ratesCumYAxes = useMemo<FossilyticsChartAxis[]>(
    () => [{ name: `Rates (${unit}/d)`, type: "value", color: theme.palette.black, min: 0 }],
    [theme.palette.black, unit]
  );
  const findLastValueInRateCumSeriesForqQ = useMemo(() => {
    const newLine = ratesCumSeries?.filter((line) => line.name === "Economic cutoff")[0].data;
    const rateCumSeriesLastPoint = ratesCumSeries?.filter((seriesItem) => seriesItem.name === "Flow rates")[0].data;

    let percentage = 100;
    if (newLine && newLine.length > 0 && rateCumSeriesLastPoint.length > 0) {
      const lastNumberInData = newLine[newLine.length - 1][0] as number;
      const flowRatesLastPoint = rateCumSeriesLastPoint[rateCumSeriesLastPoint.length - 1][0];
      if ((flowRatesLastPoint * 3) / lastNumberInData >= 1) {
        return;
      }
      if ((flowRatesLastPoint * 3) / lastNumberInData < 1) {
        percentage = Math.floor(((flowRatesLastPoint * 3) / lastNumberInData) * 100);
      }
    }
    return percentage;
  }, [ratesCumSeries]);

  const qQCustomZoom = useMemo<FossilyticsChartCustomDataZoom>(() => {
    return {
      customDataZoom: true,
      dataZoomPercentage: findLastValueInRateCumSeriesForqQ as number,
    };
  }, [findLastValueInRateCumSeriesForqQ]);

  const key = `${currentModule}_log_q_t` + dataSets.map((well) => well.id).join(",");
  return (
    <FossilyticsChart
      id={`${currentModule}_q_Q`}
      key={key}
      isLoading={isLoading}
      legends={[
        {
          name: "Flow rates",
          color: currentModule === ModuleId.SPAD_DECLINE_GAS ? theme.palette.red : theme.palette.green,
        },
      ]}
      customDataZoom={qQCustomZoom}
      xAxes={ratesCumXAxes}
      yAxes={ratesCumYAxes}
      series={ratesCumSeries}
    />
  );
};
