import React from "react";
import { Icon, Stack } from "@fluentui/react";

import { DataResponse, Field } from "@/model";
import ModuleDataView, { ModuleDataViewField } from "@/modules/ModuleDataView";

type SpadDeclineDataViewProps = {
  isForecastBeforeData: boolean;
  moduleFields: { [k in Field]?: ModuleDataViewField };
  setCleaned: (cleaned: DataResponse | undefined) => void;
  setLoading: (val: boolean) => void;
  isActive: boolean;
};

const SpadDeclineDataView = ({ isForecastBeforeData, moduleFields, setCleaned, isActive, setLoading }: SpadDeclineDataViewProps) => {
  return (
    <>
      {isForecastBeforeData && (
        <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 2, padding: "10px 0 0 20px" }}>
          <Icon iconName="Error" />
          <span>Forecast Start Date is before data!</span>
        </Stack>
      )}
      <ModuleDataView
        setLoading={setLoading}
        isActive={isActive}
        moduleFields={moduleFields}
        showAtmosphericCorrection={false}
        onDataCleaned={setCleaned}
      />
    </>
  );
};

export default SpadDeclineDataView;
