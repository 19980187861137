import React, { useCallback } from "react";
import { Dropdown, Stack } from "@fluentui/react";
import { UncertainParamDistribution, MonteCarloInputApplication, Output } from "@/models/koldun";
import { DistributionOptions, DistributionProps, applicationOptions } from "./model";
import _ from "lodash";
import { getNewUncertainList } from "../../../utils";
import CommonDistribution from "@/modules/koldun/mcsim/csg/csg/Components/ModuleKoldunMcsimCsgInputGridTab/Components/UncertainParamInputs/CommonDistribution";
import { THEORETICAL_HELP_LINKS, renderLabelWithHelp } from "@/features/help-link";
import InputErrorWrapper from "@/components/Error/InputErrorWrapper/InputErrorWrapper";

const VLDistribution = ({ name, units, onChange, output, setOutput, measureNum, keyField, errorInputValidation }: DistributionProps) => {
  const uncertainKey = "langmuir_v";
  const value = output.measures[measureNum].measure_layers[0].uncertain_list[uncertainKey];
  const onDistributionChange = useCallback(
    (v: any) => {
      switch (v) {
        case UncertainParamDistribution.FIXED:
          onChange({
            distribution: UncertainParamDistribution.FIXED,
            application: value.application,
            value: 650,
          });
          break;
        case UncertainParamDistribution.UNIFORM:
          onChange({
            distribution: UncertainParamDistribution.UNIFORM,
            application: value.application,
            low: 320,
            high: 750,
          });
          break;
        case UncertainParamDistribution.TRIANGULAR:
          onChange({
            distribution: UncertainParamDistribution.TRIANGULAR,
            application: value.application,
            left: 320,
            mode: 650,
            right: 750,
          });
          break;
        case UncertainParamDistribution.LOG_NORMAL:
          onChange({
            distribution: UncertainParamDistribution.LOG_NORMAL,
            application: value.application,
            mean: 650,
            sigma: 120,
          });
          break;
        case UncertainParamDistribution.NORMAL:
          onChange({
            distribution: UncertainParamDistribution.NORMAL,
            application: value.application,
            mean: 650,
            std: 120,
          });
          break;
      }
    },
    [onChange, value.application]
  );
  const handleApplication = useCallback(
    (v: MonteCarloInputApplication) => {
      onChange({
        ...value,
        application: v,
      });
    },
    [onChange, value]
  );

  const handleOutputChange = (layer: number, key: string, value: any) => {
    setOutput((prev: Output | null) => {
      if (!prev) return prev;
      const prevOutput = _.cloneDeep(prev);
      prevOutput.measures[measureNum].measure_layers[layer].uncertain_list = getNewUncertainList(
        prevOutput.measures[measureNum].measure_layers[layer].uncertain_list,
        uncertainKey,
        key,
        value
      );
      return prevOutput;
    });
  };

  return (
    <Stack horizontal tokens={{ childrenGap: 20 }} style={{ marginBottom: "10px" }}>
      <Stack horizontal tokens={{ childrenGap: 20 }} style={{ width: 425 }}>
        <Stack grow={0.5} className="equal-size">
          <InputErrorWrapper errors={errorInputValidation} keyField={keyField}>
            {(validationMsg) => {
              return (
                <Dropdown
                  label={`${name} distribution`}
                  onRenderLabel={(props) => renderLabelWithHelp(THEORETICAL_HELP_LINKS.isotherm, props)}
                  options={DistributionOptions}
                  selectedKey={value.distribution}
                  errorMessage={validationMsg}
                  onChange={(_, v) => (v !== undefined ? onDistributionChange(v.key) : null)}
                />
              );
            }}
          </InputErrorWrapper>
        </Stack>
        <Stack grow={0.5} className="equal-size">
          <Dropdown
            label="Application"
            options={applicationOptions}
            selectedKey={value.application}
            onChange={(_, v) => (v !== undefined ? handleApplication(v.key as MonteCarloInputApplication) : null)}
          />
        </Stack>
      </Stack>

      <CommonDistribution
        value={value}
        measureNum={measureNum}
        output={output}
        units={units}
        updateUncertainList={handleOutputChange}
        uncertainKey={uncertainKey}
        keyField={keyField}
        errorInputValidation={errorInputValidation}
      />
    </Stack>
  );
};

export default VLDistribution;
