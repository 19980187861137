import React from "react";
import { Stack } from "@fluentui/react";

import { DeclinePlots } from "../decline-plots";

import useThemeStyling from "@/utils/useThemeStyling";

import SpadDeclineAnalysisParameter from "../SpadDeclineAnalysisParameter";
import { useSpadDeclineState } from "../../hooks/SpadDeclineContext";

const SpadDeclineAnalysis = () => {
  const { elevatedCard } = useThemeStyling();

  const {
    hideSidebar,
    isLoading,
    dataSets,
    unit,
    options,
    forecastEndDate,
    forecastStartDate,
    analysis,
    paramsOps,
    paramsProfile,
    xAxesState,
    handleXAxesStateFromChild,
    onDeclineArpChange,
    compareAnalysis,
    compareDropdown,
    compareName,
    setCsvData,
    isApiError,
    setFinishCalculation,
    finishCalculation,
    group,
    project,
    changeClusterStartDayFromChart,
  } = useSpadDeclineState();

  return (
    <Stack horizontal style={{ height: "100%" }} tokens={{ padding: 20, childrenGap: 20 }}>
      {!hideSidebar && compareDropdown && <SpadDeclineAnalysisParameter compareDropdown={compareDropdown} />}

      <Stack style={elevatedCard} horizontal grow={12}>
        <DeclinePlots
          key={dataSets.map((well) => well.id).join(",") + group?.id + project?.id}
          setCsvData={setCsvData}
          isLoading={isLoading}
          dataSets={dataSets}
          unit={unit}
          economicCutoff={options.economic_cutoff}
          dataStartDay={options?.analysis_start_day}
          onDataStartDayChange={(val: number | string) => changeClusterStartDayFromChart(Math.round(Number(val)))}
          forecastStartDate={forecastStartDate ? new Date(forecastStartDate) : undefined}
          forecastEndDate={forecastEndDate ? new Date(forecastEndDate) : undefined}
          parametersShort={paramsOps}
          parametersLong={paramsProfile}
          onDeclineArpChange={onDeclineArpChange}
          compareAnalysisName={compareName}
          compareEconomicCutoff={compareAnalysis?.economicCutoff}
          compareDeclineArps={compareAnalysis?.arps}
          onHandleXAxesStateFromChild={handleXAxesStateFromChild}
          xAxesState={xAxesState}
          automaticStartDay={options.auto_update_cluster_start_day}
          isApiError={isApiError}
          analysis={analysis}
          setFinishCalculation={setFinishCalculation}
          finishCalculation={finishCalculation}
          autoSmartFit={options.auto_update_smart_fit}
        />
      </Stack>
    </Stack>
  );
};

export default SpadDeclineAnalysis;
