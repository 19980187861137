import { IDropdownOption } from "@fluentui/react";

export const mapEnumToDropdown = (enumType: any) => {
  const res: IDropdownOption[] = [];

  Object.entries(enumType).forEach(([key, value]) => {
    res.push({
      key,
      // split camel case with space
      text: String(value).replace(/([a-z])([A-Z])/g, "$1 $2"),
    });
  });
  return res;
};
export const mapEnumToDropdownReactGrid = (enumType: any) => {
  const res: {
    value: string;
    label: string;
  }[] = [];

  Object.entries(enumType).forEach(([key, value]) => {
    res.push({
      value: key,
      // split camel case with space
      label: String(value).replace(/([a-z])([A-Z])/g, "$1 $2"),
    });
  });
  return res;
};
export const mapArrayToDropdownReactGrid = (array: string[]) => {
  const res: {
    value: string;
    label: string;
  }[] = [];

  array.forEach((key) => {
    res.push({
      value: key,
      label: String(key),
    });
  });
  return res;
};

export const camelToSnakeCase = (str: string) => str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

export const formatToScientific = (val: number | string) => {
  if (isNaN(Number(val)) || !String(val).trim()) return String(val);
  const isExponent = (Number(val) > 9999 || Number(val) < 0.01) && val !== 0;
  const formatted = val === 0 ? String(val) : String(Number(val).toPrecision(3));

  return isExponent ? `${Number(val).toExponential(2)}` : formatted;
};

export const capitalizeFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const getRandomColor = () => {
  let letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};
