import { Stack, Text } from "@fluentui/react";
import { ModuleTahkForecastGasOilOptions, ModuleTahkForecastUncertainParamDistribution } from "../model";

import React, { useCallback, useEffect, useMemo } from "react";
import { CellChange, Column, NumberCell, ReactGrid, Row } from "@silevis/reactgrid";
import FossilyticsNumberField from "../../../../../components/fields/FossilyticsNumberField";
import BaseInputs from "./components/BaseInputs";
import HorizontalSelectInputs from "./components/HorizontalSelectInputs";
import GasOilUncertainParameterInputs from "./components/GasOilUncertainParameterInputs";
import { getLastValidDataIndex } from "../ModuleTahkForecastGasOilContent";
import { TankType } from "../../../../../model";
import { handleKeyDown } from "../../../../../util";
import useThemeStyling from "@/utils/useThemeStyling";
import _ from "lodash";

const columnWidthCss = 250;

const inputsCols = [
  { columnId: 0, width: 125 },
  { columnId: 1, width: 200 },
  { columnId: 2, width: 200 },
  { columnId: 3, width: 200 },
] as Column[];

interface ModuleTahkForecastGasOilInputGridTabProps {
  value: ModuleTahkForecastGasOilOptions | undefined;
  onChange: React.Dispatch<React.SetStateAction<ModuleTahkForecastGasOilOptions>>;
  module: string;
  isLoading: boolean;

  handleInputNotChanged: () => void;
}

function ModuleTahkForecastGasOilInputGridTab({
  value,
  onChange,
  handleInputNotChanged,
  module,
  isLoading,
}: Readonly<ModuleTahkForecastGasOilInputGridTabProps>) {
  useEffect(() => {
    if (!value?.drainage_radius_ft) return;
    onChange({
      ...value,
      ogip_mmscf: { distribution: ModuleTahkForecastUncertainParamDistribution.FIXED, value: 0 },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value?.drainage_radius_ft]);

  // Calculate last valid data index in data table
  const lastValidDataIndex = useMemo(() => {
    return getLastValidDataIndex(value?.inputs, value?.num_days);
  }, [value]);

  const { theme, elevatedCard } = useThemeStyling();

  // Parse data from spreadsheet to input array
  const inputsRows = useMemo(() => {
    if (!value || isLoading) return [];
    const input = _.cloneDeep(value.inputs);
    return [
      {
        rowId: "header",
        cells: [
          { type: "header", text: "Days" },
          { type: "header", text: "Flowing BH pressure (psia)" },
          { type: "header", text: "Skin" },
          {
            type: "header",
            text: "Contract rate(MMscf/d)",
          },
        ],
      },
      ...input.map((p, i) => {
        const background = i === 0 ? theme.palette.neutralLight : i > lastValidDataIndex ? theme.palette.themeLight : undefined;
        return {
          rowId: i,
          cells: [
            {
              type: "number",
              value: p[0] < 0 ? 0 : p[0],
              nonEditable: i === 0,
              hideZero: true,
              style: {
                background,
              },
              handleKeyDown,
            },
            {
              type: "number",
              value: p[1] < 0 ? 0 : p[1],
              hideZero: true,
              handleKeyDown,
            },
            {
              type: "number",
              value: p[2] < 0 ? null : p[2],
              hideZero: false,
              handleKeyDown,
            },
            {
              type: "number",
              value: p[3] < 0 ? 0 : p[3],
              hideZero: true,
              handleKeyDown,
            },
          ],
        };
      }),
    ] as Row[];
  }, [isLoading, lastValidDataIndex, theme.palette.neutralLight, theme.palette.themeLight, value]);

  const handleChanges = useCallback(
    (changes: CellChange[]) => {
      if (value) {
        const newInputs = JSON.parse(JSON.stringify(value.inputs));
        changes.forEach((change) => {
          const numberCell = change.newCell as NumberCell;
          const idx = change.rowId as number;
          const colIdx = change.columnId as number;
          if (isNaN(numberCell.value) || Number(numberCell.value) < 0) newInputs[idx][colIdx] = -1;
          else if (numberCell.value === 0) newInputs[idx][colIdx] = 0;
          else newInputs[idx][colIdx] = numberCell.value;
        });

        onChange({ ...value, inputs: newInputs });
        handleInputNotChanged();
      }
    },
    [handleInputNotChanged, onChange, value]
  );

  if (!value) {
    return <></>;
  }

  return (
    <Stack tokens={{ padding: 20, childrenGap: 20 }}>
      <Stack.Item style={elevatedCard}>
        <Text styles={{ root: { color: theme.palette.themePrimary } }}>Inputs</Text>

        <BaseInputs value={value} onChange={onChange} module={module} />
      </Stack.Item>

      {value.tank_type === TankType.Horizontal ? <HorizontalSelectInputs value={value} onChange={onChange} /> : null}

      <Stack.Item style={elevatedCard}>
        <Text styles={{ root: { color: theme.palette.themePrimary } }}>Uncertain Parameters</Text>

        <Stack tokens={{ childrenGap: 5 }}>
          {module !== "tahk_gas" && (
            <FossilyticsNumberField
              type="int"
              label="Number of simulations"
              suffix="simulations"
              max={200}
              styles={{ root: { width: columnWidthCss } }}
              value={value.num_simulations}
              onChange={(v) =>
                v !== undefined
                  ? onChange({
                      ...value,
                      num_simulations: v,
                    })
                  : null
              }
            />
          )}

          <GasOilUncertainParameterInputs value={value} onChange={onChange} tahkMode />
        </Stack>
      </Stack.Item>

      <Stack style={elevatedCard} tokens={{ childrenGap: 5 }}>
        <Text styles={{ root: { color: theme.palette.themePrimary } }}>Data</Text>

        <Stack horizontal disableShrink>
          <FossilyticsNumberField
            type="int"
            label="Number of days"
            suffix="days"
            styles={{
              root: {
                width: columnWidthCss,
              },
            }}
            value={value.num_days}
            onChange={(v) => {
              const newInputs = [...value.inputs];
              const safeNum = Number(v ?? 0);
              if (newInputs.length > safeNum) {
                // Ensure we don't lose inputted data
                const lastValidIndex = newInputs.reduce((lastValidIndex, row, i) => {
                  if (row.some((v) => v > 0)) return i;
                  return lastValidIndex;
                }, 0);
                newInputs.splice(Math.max(safeNum, lastValidIndex + 1));
              } else if (newInputs.length < safeNum) {
                const a = Array.from(Array(safeNum - newInputs.length).keys());
                newInputs.push(...a.map(() => [0, 0, 0, 0] as [number, number, number, number]));
              }
              onChange({ ...value, inputs: newInputs, num_days: v ?? 0 });
            }}
          />
        </Stack>
        <Text variant="small" styles={{ root: { color: theme.palette.themePrimary } }}>
          Note: Flowing BH pressure & Contract rate should be greater than 0{" "}
        </Text>
        <div style={{ blockSize: 300, overflowY: "auto", marginBlockStart: 5, width: 750 }}>
          <ReactGrid
            columns={inputsCols}
            rows={inputsRows}
            enableRangeSelection
            enableColumnSelection
            enableFullWidthHeader
            stickyTopRows={1}
            onCellsChanged={handleChanges}
          />
        </div>
        <Text variant="tiny">Tip: You can copy and paste cells from a spreadsheet here.</Text>
      </Stack>
    </Stack>
  );
}

export default ModuleTahkForecastGasOilInputGridTab;
