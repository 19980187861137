import React, { useCallback, useMemo, useState } from "react";

import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Tabs from "@/components/Tabs";

import dictionary from "@/constants/dictionary";

import useThemeStyling from "@/utils/useThemeStyling";
import { PromiseResultDetail, ResultState, WellErrorCode, postDataSet } from "@/models/wells";
import helpLinkUrl from "@/constants/helpLinkUrl";
import IndividualItem from "../shared/components/IndividualItem";
import { Container, VisuallyHiddenInput } from "../shared/style";
import { useTreeViewState } from "@/components/TreeView/hooks/TreeViewContextV2";

const ImportWell = () => {
  const { elevatedCard } = useThemeStyling();
  const { refreshDataSets } = useTreeViewState();

  const [files, setFiles] = useState<File[]>([]);
  const [res, setRes] = useState<ResultState<WellErrorCode>>({});
  const [isLoading, setIsLoading] = useState(false);

  const [isOverrideActive, setIsOverrideActive] = useState(false);

  const handleOnChange = (e: any) => {
    setRes([]);
    setFiles(Array.from(e.target.files));
    setIsOverrideActive(false);
  };

  const handleOnSubmit = useCallback(
    async (override: boolean) => {
      try {
        setIsLoading(true);
        if (!override) setRes([]);

        if (files) {
          const overrideFileIndex: number[] = [];
          const promises: Promise<any>[] = [];
          if (override) {
            const overrideFiles = files.filter((_, index) => {
              if (res[index].status === "rejected" && res[index].detail.code === "file_exists") {
                overrideFileIndex.push(index);
                return true;
              }
              return false;
            });
            overrideFiles.forEach((file) => {
              promises.push(postDataSet({ file: file, override }));
            });
          } else {
            files.forEach((file) => {
              promises.push(postDataSet({ file, override }));
            });
          }

          const allRes = await Promise.allSettled(promises);

          const reMappedResult: ResultState<WellErrorCode> = {};
          if (override) {
            setRes((prev) => {
              if (!prev) return prev;
              allRes.forEach((currRes, index) => {
                const safeRes = currRes as {
                  reason: {
                    code: number;
                    detail: PromiseResultDetail<WellErrorCode>;
                  };
                  status: "rejected" | "fulfilled";
                };

                prev[overrideFileIndex[index]] = {
                  code: safeRes.reason?.code,
                  detail: safeRes.reason?.detail,
                  status: safeRes.status,
                };
              });
              return { ...prev };
            });
          } else {
            allRes.forEach((res, index) => {
              const safeRes = res as {
                reason: {
                  code: number;
                  detail: PromiseResultDetail<WellErrorCode>;
                };
                status: "rejected" | "fulfilled";
              };
              reMappedResult[index] = {
                code: safeRes.reason?.code,
                detail: safeRes.reason?.detail,
                status: safeRes.status,
              };
            });
            refreshDataSets();
            setRes(reMappedResult);
          }
        }
      } catch (error: any) {
        console.log("error", error);
      } finally {
        setIsLoading(false);
      }
    },
    [files, refreshDataSets, res]
  );

  const resultAvailable = Object.keys(res).length > 0;

  const tabList = useMemo(() => {
    if (!resultAvailable || !res) return [];
    const successFiles = files
      .map((_, index) => {
        if (res[index].status === "fulfilled") return String(index);
        return null;
      })
      .filter((comp) => !!comp) as string[];
    const overrideFiles = files
      .map((_, index) => {
        if (res[index].status === "rejected" && res[index].detail?.code === "file_exists") return String(index);
        return null;
      })
      .filter((comp) => !!comp) as string[];
    const failedFiles = files
      .map((_, index) => {
        if (res[index].status === "rejected" && res[index].detail?.code !== "file_exists") return String(index);
        return null;
      })
      .filter((comp) => !!comp) as string[];

    const tabs = [];
    if (successFiles.length > 0)
      tabs.push({
        label: <span>{dictionary.wellImport.successFiles}</span>,
        key: "success",
        content: (
          <div className="files-list-container">
            {successFiles.map((fileIndex) => {
              return <IndividualItem key={files[Number(fileIndex)].name} name={files[Number(fileIndex)].name} />;
            })}
          </div>
        ),
      });
    if (failedFiles.length > 0)
      tabs.push({
        label: <span>{dictionary.wellImport.failedTitle}</span>,
        key: "failed",
        content: (
          <div className="files-list-container">
            {failedFiles.map((fileIndex) => {
              return (
                <IndividualItem
                  error={res[Number(fileIndex)].detail?.message}
                  key={files[Number(fileIndex)].name}
                  name={files[Number(fileIndex)].name}
                />
              );
            })}
          </div>
        ),
      });
    if (overrideFiles.length > 0)
      tabs.push({
        label: <span>{dictionary.wellImport.overrideFile}</span>,
        key: "override",
        content: (
          <div className="files-list-container">
            {overrideFiles.map((fileIndex) => {
              return (
                <IndividualItem
                  error={res[Number(fileIndex)].detail?.message}
                  key={files[Number(fileIndex)].name}
                  name={files[Number(fileIndex)].name}
                />
              );
            })}
          </div>
        ),
      });

    return tabs;
  }, [files, res, resultAvailable]);

  const onlyOverrideTabActive = useMemo(() => {
    return tabList.filter((tab) => tab.key === "override").length === 1 && tabList.length === 1;
  }, [tabList]);

  const onlySuccessTabActive = useMemo(() => {
    return tabList.filter((tab) => tab.key === "success").length === 1 && tabList.length === 1;
  }, [tabList]);

  return (
    <Container style={elevatedCard}>
      <h3>
        <a target="_blank" rel="noreferrer" href={helpLinkUrl.wellImport}>
          {dictionary.nav.importWells} <InfoOutlinedIcon fontSize="small" />
        </a>
      </h3>

      <div className="list-container">
        <div>
          {!resultAvailable &&
            files?.map((file) => {
              return <IndividualItem key={file.name} name={file.name} />;
            })}
        </div>

        {resultAvailable && (
          <div>
            <Tabs
              onClickItem={(item) => {
                setIsOverrideActive(tabList[item].key === "override");
              }}
              tabList={tabList}
            />
          </div>
        )}
      </div>
      <div className="button-container">
        {!onlySuccessTabActive && (
          <Button
            disabled={isLoading}
            style={{ color: "white" }}
            component="label"
            role={undefined}
            variant="contained"
            tabIndex={-1}
            startIcon={<CloudUploadIcon />}
          >
            {dictionary.wellImport.choose}(s)
            <VisuallyHiddenInput onChange={handleOnChange} multiple type="file" accept=".csv" />
          </Button>
        )}

        {onlySuccessTabActive ? (
          <Button
            onClick={(e) => {
              e.preventDefault();
              window.location.href = "/modules";
            }}
            style={{ color: "white", marginLeft: 20 }}
            variant="contained"
          >
            {dictionary.wellImport.ok}
          </Button>
        ) : (
          <Button
            onClick={(e) => {
              e.preventDefault();
              handleOnSubmit(isOverrideActive || onlyOverrideTabActive);
            }}
            disabled={files.length === 0 || isLoading || (resultAvailable && !isOverrideActive && !onlyOverrideTabActive)}
            style={{ color: "white", marginLeft: 20 }}
            variant="contained"
          >
            {isOverrideActive || onlyOverrideTabActive ? dictionary.wellImport.overrideFile : dictionary.wellImport.submit}
          </Button>
        )}
      </div>
    </Container>
  );
};

export default ImportWell;
