export const downloadImg = (data: string, fileName: string) => {
  let link = document.createElement("a");

  link.href = data;
  link.download = `${fileName}.jpg`;

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
