import { MonteCarloInputApplication, MonteCarloInputProps, UncertainParamLorenz } from "@/models/koldun";
import { Stack } from "@fluentui/react";
import FossilyticsNumberField from "@/components/fields/FossilyticsNumberField";
import React from "react";

const LorenzInput = ({ units, output, updateUncertainList, measureNum, uncertainKey, keyField, errorInputValidation }: MonteCarloInputProps) => {
  const application = output.measures[measureNum].measure_layers[0].uncertain_list[uncertainKey].application;

  const renderMeasureField = () => (
    <Stack horizontal enableScopedSelectors tokens={{ childrenGap: 20 }} style={{ width: 450 }}>
      <Stack grow={1} className="equal-size">
        <FossilyticsNumberField
          label="Net Coal Thickness"
          suffix={units}
          debounceDelay={1000}
          value={(output.measures[measureNum].measure_layers[0].uncertain_list[uncertainKey] as UncertainParamLorenz).net_thickness}
          onChange={(v) => updateUncertainList(0, "net_thickness", v)}
          keyField={`${keyField}.net_thickness`}
          errors={errorInputValidation}
        />
      </Stack>
      <Stack grow={1} className="equal-size">
        <FossilyticsNumberField
          label="Lorenz Factor"
          suffix="dim"
          debounceDelay={1000}
          value={(output.measures[measureNum].measure_layers[0].uncertain_list[uncertainKey] as UncertainParamLorenz).lorentz_factor}
          onChange={(v) => updateUncertainList(0, "lorentz_factor", v)}
          keyField={`${keyField}.lorentz_factor`}
          errors={errorInputValidation}
        />
      </Stack>
    </Stack>
  );

  return (
    <Stack tokens={{ childrenGap: 20 }}>
      <Stack grow={0.5} tokens={{ childrenGap: 20 }} className="equal-size">
        {/*Lorenz don't have multi layer*/}
        {application === MonteCarloInputApplication.MEASURE ? renderMeasureField() : null}
      </Stack>
    </Stack>
  );
};

export default LorenzInput;
