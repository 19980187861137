import { Stack, useTheme, Text } from "@fluentui/react";
import FossilyticsNumberField from "../../../../../../../../components/fields/FossilyticsNumberField";
import { ModuleKoldunMcsimGasOilOptions, ModuleKoldunMcsimResProp } from "../../../model";

const horizontalSelectFieldsFirstColumn = [
  {
    label: "Permeability(X-axis)",
    suffix: "md",
    valueKey: "permeability_xaxis_md",
  },
  {
    label: "Permeability(Y-axis)",
    suffix: "md",
    valueKey: "permeability_yaxis_md",
  },
  {
    label: "Permeability(Z-axis)",
    suffix: "md",
    valueKey: "permeability_zaxis_md",
  },
  {
    label: "Horizontal well center position (X-axis)",
    suffix: "ft",
    valueKey: "well_position_xaxis_ft",
  },
  {
    label: "Horizontal well center position (Y-axis)",
    suffix: "ft",
    valueKey: "well_position_yaxis_ft",
  },
  {
    label: "Horizontal well center position (Z-axis)",
    suffix: "ft",
    valueKey: "well_position_zaxis_ft",
  },
];

const horizontalSelectFieldsSecondColumn = [
  {
    label: "Reservoir Width (X-axis)",
    suffix: "ft",
    valueKey: "res_width_xaxis_ft",
  },
  {
    label: "Reservoir Length (Y-axis)",
    suffix: "ft",
    valueKey: "res_length_yaxis_ft",
  },
  {
    label: "Net Pay",
    suffix: "ft",
    valueKey: "res_height_zaxis_ft",
  },
  {
    label: "Horizontal Section Length (Y-axis)",
    suffix: "ft",
    valueKey: "horizontal_well_length_yaxis_ft",
  },
];
interface HorizontalSelectInputsProps {
  value: ModuleKoldunMcsimGasOilOptions;
  onChange: React.Dispatch<React.SetStateAction<ModuleKoldunMcsimGasOilOptions>>;
}

const HorizontalSelectInputs = ({ value, onChange }: HorizontalSelectInputsProps) => {
  const theme = useTheme();
  const itemStyle = {
    boxShadow: theme.effects.elevation4,
    padding: 15,
    borderRadius: theme.effects.roundedCorner4,
  } as React.CSSProperties;

  if (!value) return <></>;

  return (
    <Stack.Item style={itemStyle}>
      <Text styles={{ root: { color: theme.palette.themePrimary } }}>Horizontal Select</Text>
      <Stack horizontal tokens={{ childrenGap: 20 }}>
        <Stack grow={1} className="equal-size" tokens={{ childrenGap: 5 }}>
          {horizontalSelectFieldsFirstColumn.map((field) => (
            <FossilyticsNumberField
              key={field.valueKey}
              label={field.label}
              suffix={field.suffix}
              value={value.res_prop[field.valueKey as keyof ModuleKoldunMcsimResProp]}
              onChange={(v) =>
                v !== undefined
                  ? onChange({
                      ...value,
                      res_prop: {
                        ...value.res_prop,
                        [field.valueKey]: v,
                      },
                    })
                  : null
              }
            />
          ))}
        </Stack>
        <Stack grow={1} className="equal-size" tokens={{ childrenGap: 5 }}>
          {horizontalSelectFieldsSecondColumn.map((field) => (
            <FossilyticsNumberField
              key={field.valueKey}
              label={field.label}
              suffix={field.suffix}
              value={value.res_prop[field.valueKey as keyof ModuleKoldunMcsimResProp]}
              onChange={(v) =>
                v !== undefined
                  ? onChange({
                      ...value,
                      res_prop: {
                        ...value.res_prop,
                        [field.valueKey]: v,
                      },
                    })
                  : null
              }
            />
          ))}
        </Stack>
        <Stack grow={1} className="equal-size" tokens={{ childrenGap: 5 }}></Stack>
      </Stack>
    </Stack.Item>
  );
};

export default HorizontalSelectInputs;
