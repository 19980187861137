import React, { useMemo } from "react";
import "./LoadingBlocker.css";

import dictionary from "@/constants/dictionary";

export type LoadingBlockerProps = {
  isVisible: boolean;
  progress: null | number;
  customText?: string;
};

const LoadingBlocker = ({ isVisible, progress, customText }: LoadingBlockerProps) => {
  const loadingText = useMemo(() => {
    return dictionary.loadingProgress[Math.floor(Math.random() * dictionary.loadingProgress.length)];
  }, []);

  const progressDisplay = progress ? `${progress}%` : "";

  if (!isVisible) return <></>;
  return (
    <div className="LoadingBlockerContainer">
      <div className="LoadingBlockerOpacityBackground" />
      <div className="LoadingBlockerTextContainer">
        <h3 data-testid="loadingBlockerText">{customText ? customText : `${loadingText} ${progressDisplay}`}</h3>
      </div>
    </div>
  );
};

export default LoadingBlocker;
