import { useEffect } from "react";

export default function useOutsideClick<T extends HTMLElement>(nodeRef?: React.RefObject<T>, onOutsideClick?: () => void) {
  useEffect(() => {
    const handleDocumentClick = (e: MouseEvent) => {
      const node = nodeRef?.current;
      if (!node) {
        return;
      }
      const clickTarget = e.target as HTMLElement;
      if (node.contains(clickTarget)) {
        return;
      }
      onOutsideClick?.();
    };
    document.body.addEventListener("click", handleDocumentClick, { capture: true });
    return () => document.body.removeEventListener("click", handleDocumentClick, { capture: true });
  }, [nodeRef, onOutsideClick]);
}
