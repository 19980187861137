import React, { useCallback, useEffect, useRef, useState } from "react";

import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";

import styled from "@emotion/styled";

import InputErrorWrapper, { InputErrorWrapperProps } from "../Error/InputErrorWrapper/InputErrorWrapper";
import FieldLabel, { FieldLabelProps } from "./FieldLabel";

type NumberFieldProps = {
  label?: string;
  type?: "int" | "float";
  disabled?: boolean;
  required?: boolean;
  styles?: any;
  min?: number;
  max?: number;
  debounceDelay?: number;
  value?: number | null;
  onChange?: (value: number | undefined) => void;
  customValue?: boolean;
  ariaLabel?: string;
  suffix?: string;
  helpUrl?: string;
} & FieldLabelProps &
  Omit<InputErrorWrapperProps, "children">;

const StyledTextField = styled(TextField)`
  margin-bottom: 8px;

  .MuiFormControl-root .MuiTextField-root {
    margin-top: 8px;
  }

  input {
    padding-top: 6px;
    padding-bottom: 6px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const getNumValue = (rawValue: string | undefined) => {
  let numValue = Number(rawValue);
  let zeroCoerceNumValue = Number(rawValue + "0");

  if (rawValue === "") {
    return NaN;
  } else if (isNaN(numValue) && !isNaN(zeroCoerceNumValue)) {
    // Check for special cases
    return zeroCoerceNumValue;
  } else {
    return numValue;
  }
};

const NumberField = ({
  ariaLabel,
  label,
  suffix,
  type = "float",
  disabled,
  required = false,
  styles,
  min,
  max,
  debounceDelay = 1000,
  value,
  onChange,
  calloutContent,
  errors,
  keyField,
  helpUrl,
}: Readonly<NumberFieldProps>) => {
  const [strValue, setStrValue] = useState<string | undefined>(value ? String(value) : "");
  const [errorMessage, setErrorMessage] = useState<string | undefined>("");

  const ref = useRef<any>(null);

  const debounceTimerRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    setStrValue((prevStrValue) => {
      if (value === undefined) {
        return prevStrValue;
      } else if (value === null) {
        return "";
      } else if (Number(value) !== getNumValue(prevStrValue)) {
        return String(value);
      } else {
        return prevStrValue;
      }
    });
  }, [value, setStrValue]);

  const validateInput = useCallback(
    (newStrValue: string) => {
      let numValue = getNumValue(newStrValue);
      let strVal = newStrValue;

      if (newStrValue === "" && required) {
        return {
          errorMessage: "Required",
        };
      }
      if (!isNaN(numValue)) {
        if (type === "int" && !Number.isInteger(numValue)) {
          numValue = Math.trunc(numValue);
          strVal = String(numValue);
        }

        if (min !== undefined && numValue < min) {
          return {
            errorMessage: `Minimum value is ${min}.`,
          };
        } else if (max !== undefined && numValue > max) {
          return {
            errorMessage: `Maximum value is ${max}.`,
          };
        }
        return {
          strVal,
          emittedValue: numValue,
        };
      }
      return {
        errorMessage: "Input is not a valid number.",
      };
    },
    [max, min, required, type]
  );

  const onChangeWrapper = useCallback(
    (event: any) => {
      let newStrValue = event.target.value;

      setErrorMessage(undefined);

      const validated = validateInput(newStrValue);
      if (validated.errorMessage) {
        setErrorMessage(validated.errorMessage);
      }
      if (onChange) {
        if (debounceDelay) {
          if (debounceTimerRef.current) {
            clearTimeout(debounceTimerRef.current);
          }

          debounceTimerRef.current = setTimeout(() => onChange(validated.emittedValue), debounceDelay);
        } else {
          onChange(validated.emittedValue);
        }
      }

      setStrValue(validated.strVal ?? newStrValue);
    },
    [debounceDelay, onChange, validateInput]
  );

  return (
    <InputErrorWrapper errors={errors} keyField={keyField}>
      {(errorValidation) => (
        <Container>
          <FieldLabel label={label} ariaLabel={ariaLabel} calloutContent={calloutContent} helpUrl={helpUrl} />
          <StyledTextField
            aria-label={label}
            id={label}
            size="small"
            value={strValue}
            required={required}
            sx={{
              marginTop: 0,
            }}
            disabled={disabled}
            ref={ref}
            onChange={(event) => onChangeWrapper(event)}
            InputProps={
              suffix
                ? {
                    endAdornment: <InputAdornment position="end">{suffix}</InputAdornment>,
                  }
                : undefined
            }
            error={!!(errorMessage ?? errorValidation)}
            helperText={errorMessage ?? errorValidation}
            style={styles}
            onWheel={(e: any) => e.target.blur()}
          />
        </Container>
      )}
    </InputErrorWrapper>
  );
};

export default NumberField;
