import React, { createContext, useContext, useState, useMemo, useEffect } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";

import { DataSet, isDataSet } from "@/model";
import { pollInitializeGazAutoRta, GazAutoRta, DataViewCalculation } from "@/models/gaz/autoRta";

import { gazAutoRtaDefaultState } from "../constants";
import { ApiError } from "@/models/APIGeneric";

type GazAutoRtaStateProps = {
  gazAutoRTAState: null | GazAutoRta;
  dataSet?: DataSet;
  setGazAutoRTAState: React.Dispatch<React.SetStateAction<GazAutoRta | null>>;
  setDataViewCalculation: React.Dispatch<React.SetStateAction<DataViewCalculation | undefined>>;
  dataViewCalculation?: DataViewCalculation;
  isLoading: boolean;
  setApiError: (err: ApiError) => void;
};

const GazAutoRtaStateContext = createContext<GazAutoRtaStateProps>(gazAutoRtaDefaultState);

type GazAutoRtaContextProps = {
  children: React.ReactNode;
  selectedDataSets?: DataSet | DataSet[];
  setApiError: (err: ApiError) => void;
};

function GazAutoRtaProvider({ children, selectedDataSets, setApiError }: Readonly<GazAutoRtaContextProps>) {
  const [gazAutoRTA, setGazAutoRTA] = useState<GazAutoRta | null>(null);
  const [dataViewCalculation, setDataViewCalculation] = useState<DataViewCalculation | undefined>();
  const [latestDataSet, setLatestDataSet] = useState("");

  const dataSet = useMemo(() => {
    return isDataSet(selectedDataSets) ? selectedDataSets : undefined;
  }, [selectedDataSets]);
  const client = useQueryClient();
  const { isLoading, isFetching } = useQuery({
    queryKey: ["initialize-gaz-auto-rta", dataSet?.id],
    queryFn: async () => {
      return pollInitializeGazAutoRta({
        queryStringParameters: {
          data_set_ids: dataSet?.id,
        },
      });
    },
    select(data) {
      if (data && data.data && !gazAutoRTA) {
        setGazAutoRTA(data.data);
        setLatestDataSet(dataSet?.id ?? "");
      }
    },
    refetchOnWindowFocus: false,
    enabled: dataSet && !!dataSet?.id,
  });

  useEffect(() => {
    if (latestDataSet && latestDataSet !== dataSet?.id) {
      setGazAutoRTA(null);
      setDataViewCalculation(undefined);
      client?.invalidateQueries();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSet, latestDataSet]);

  const state = useMemo(() => {
    return {
      gazAutoRTAState: gazAutoRTA,
      dataSet,
      setGazAutoRTAState: setGazAutoRTA,
      dataViewCalculation,
      setDataViewCalculation,
      isLoading: isFetching || isLoading,
      setApiError,
    };
  }, [dataSet, dataViewCalculation, gazAutoRTA, isLoading, isFetching, setApiError]);

  return <GazAutoRtaStateContext.Provider value={state}>{children}</GazAutoRtaStateContext.Provider>;
}

export default GazAutoRtaProvider;

export function useGazAutoRtaState() {
  return useContext(GazAutoRtaStateContext);
}
