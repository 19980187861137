import React, { useCallback, useMemo } from "react";
import { GridRenderCellParams } from "@mui/x-data-grid";

import MultiLayerTable, { MultiLayerProps } from "@/components/MultiLayerTable";
import { Output, CSGMeasure, MatrixShrinkageKeys } from "@/models/koldun";
import { ErrorValidationDetail } from "@/models/ErrorInputValidation";

const renderCell = (params: { row: { value: any } }) => {
  const value = params.row.value;
  if (value !== null && value >= 0) {
    return value;
  }
  return <span style={{ color: "red" }}>Invalid</span>;
};

interface MultiLayerShrinkageInputsProps {
  value: Output;
  field: MultiLayerProps[];
  onChange: React.Dispatch<React.SetStateAction<Output | null>>;
  errorInputValidation?: ErrorValidationDetail[];
}

const MultiShrinkageWidth: { [key: string]: number } = {
  none: 0,
  burgoyne: 380,
  "yilmaz-nur": 240,
  "palmer-mansoori": 1020,
};
export const MatrixShrinkageFieldsMap: { [key: string]: MultiLayerProps[] } = {
  none: [],
  burgoyne: [
    {
      field: "id",
      headerName: "Measure",
      width: 80,
      headerUnits: "",
      type: "number",
      renderCell: (params: GridRenderCellParams<CSGMeasure>) => renderCell({ row: { value: params.row.measure_num } }),
    },
    {
      field: "shrinkage_factor_dim",
      headerName: "Shrinkage Factor",
      headerUnits: "(Dim)",
      width: 150,
      type: "number",
      renderCell: (params: GridRenderCellParams<CSGMeasure>) =>
        renderCell({ row: { value: params.row.measure_layers[0].geomechanics.shrinkage_factor_dim } }),
    },
    {
      field: "max_pim",
      headerName: "Max. Productivity Multiplier",
      headerUnits: "(Dim)",
      width: 150,
      type: "number",
      renderCell: (params: GridRenderCellParams<CSGMeasure>) => renderCell({ row: { value: params.row.measure_layers[0].geomechanics.max_pim } }),
    },
  ],
  "yilmaz-nur": [
    {
      field: "id",
      headerName: "Measure",
      width: 80,
      headerUnits: "",
      type: "number",
      renderCell: (params: GridRenderCellParams<CSGMeasure>) => renderCell({ row: { value: params.row.measure_num } }),
    },
    {
      field: "perm_modulus",
      headerName: "Permeability Modulus",
      headerUnits: "(Dim)",
      width: 160,
      type: "number",
      renderCell: (params: GridRenderCellParams<CSGMeasure>) =>
        renderCell({ row: { value: params.row.measure_layers[0].geomechanics.perm_modulus } }),
    },
  ],
  "palmer-mansoori": [
    {
      field: "id",
      headerName: "Measure",
      width: 80,
      headerUnits: "",
      type: "number",
      renderCell: (params: GridRenderCellParams<CSGMeasure>) => renderCell({ row: { value: params.row.measure_num } }),
    },
    {
      field: "poissons_ratio_dim",
      headerName: "Poisson's Ratio",
      headerUnits: "(Dim)",
      width: 150,
      type: "number",
      renderCell: (params: GridRenderCellParams<CSGMeasure>) =>
        renderCell({ row: { value: params.row.measure_layers[0].geomechanics.poissons_ratio_dim } }),
    },
    {
      field: "grain_compres_psi",
      headerName: "Grain Compressibility",
      headerUnits: "1(/psia)",
      width: 160,
      type: "number",
      renderCell: (params: GridRenderCellParams<CSGMeasure>) =>
        renderCell({ row: { value: params.row.measure_layers[0].geomechanics.grain_compres_psi } }),
    },
    {
      field: "shrinkage_factor_dim",
      headerName: "Shrinkage Factor",
      headerUnits: "(Dim)",
      width: 150,
      type: "number",
      renderCell: (params: GridRenderCellParams<CSGMeasure>) =>
        renderCell({ row: { value: params.row.measure_layers[0].geomechanics.shrinkage_factor_dim } }),
    },
    {
      field: "lang_vol_strain",
      headerName: "Langmuir Volume Strain",
      headerUnits: "(Dim)",
      width: 170,
      type: "number",
      renderCell: (params: GridRenderCellParams<CSGMeasure>) =>
        renderCell({ row: { value: params.row.measure_layers[0].geomechanics.lang_vol_strain } }),
    },
    {
      field: "youngs_modulus",
      headerName: "Young's Modulus",
      headerUnits: "(Dim)",
      width: 150,
      type: "number",
      renderCell: (params: GridRenderCellParams<CSGMeasure>) =>
        renderCell({ row: { value: params.row.measure_layers[0].geomechanics.youngs_modulus } }),
    },
    {
      field: "n_exponent",
      headerName: "Shrinkage Exponent",
      headerUnits: "(Dim)",
      width: 160,
      type: "number",
      renderCell: (params: GridRenderCellParams<CSGMeasure>) => renderCell({ row: { value: params.row.measure_layers[0].geomechanics.n_exponent } }),
    },
  ],
};

const MatrixShrinkageTable = ({ onChange, value, field, errorInputValidation }: MultiLayerShrinkageInputsProps) => {
  const rows = value.measures.map((item, index) => ({
    id: index,
    ...item,
  }));

  const handleChanges = useCallback(
    (newValue: any) => {
      onChange((prev) => {
        if (!prev) return prev;
        const prevInputs: Output = { ...prev };

        const key = Object.keys(newValue).find((k) => k !== "id" && k !== "measure_num" && k !== "measure_layers" && k !== "numOfLayer");
        const value = newValue[key as string];

        if (key && Object(prevInputs.measures[newValue.measure_num - 1].measure_layers[0]?.geomechanics)) {
          // weird aws amplify deleting object key for undefined but somehow accept null
          prevInputs.measures[newValue.measure_num - 1].measure_layers[0].geomechanics[key as MatrixShrinkageKeys] = !value ? null : value;
        }
        return { ...prevInputs };
      });
    },
    [onChange]
  );

  const errorMessage = useMemo(() => {
    if (errorInputValidation && errorInputValidation.length > 0) {
      const geoMechanicErrorIndex = errorInputValidation.findIndex((error) => error.loc.includes("geomechanics"));
      if (geoMechanicErrorIndex > -1) return errorInputValidation?.[geoMechanicErrorIndex].msg;
      return null;
    }
    return null;
  }, [errorInputValidation]);

  return (
    <div
      style={{
        position: "relative",
        height: value.measures[0].measure_layers[0].geomechanics.method === "none" ? 50 : 300,
        maxWidth: MultiShrinkageWidth[value.measures[0].measure_layers[0].geomechanics.method],
        top: 20,
        marginBottom: 20,
      }}
    >
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          overflow: "auto",
        }}
      >
        {errorMessage && <span style={{ color: "red" }}>{errorMessage}</span>}

        <MultiLayerTable columns={field} rows={rows} handleChanges={handleChanges} />
      </div>
    </div>
  );
};

export default MatrixShrinkageTable;
