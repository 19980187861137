import { useMemo } from "react";
import { Row } from "@silevis/reactgrid";

import { formatReactGridCell } from "@/util";
import { CustomCell } from "@/components/CustomTable";

import { InputGridCalculation, OilPvtDataTable, GasPvtDataTable, WaterPvtDataTable, LangmuirIsothermDataTable } from "@/models/gaz/autoRta";

import {
  gasPvtDataTableCols,
  gasPvtHeaderUnitMapping,
  waterPvtHeaderUnitMapping,
  waterPvtDataTableCols,
  oilPvtDataTableColumns,
  oilPvtHeaderUnitMapping,
  langmuirIsothermDataTableCols,
  langmuirIsothermHeaderUnitMapping,
  headerDataTableUnitStyles,
  dataTableHeaderStyles,
} from "../../constants";

import "../../ModuleGasAutoRTA.css";

const useGAZAutoRtaPvtDataTable = (calculateValue: InputGridCalculation) => {
  const gasPvtDataTableRows = useMemo<Row[]>(() => {
    if (!calculateValue?.gas_pvt_charts) return [];

    const headerRow: Row<CustomCell> = {
      rowId: "header",
      cells: Object.values(gasPvtHeaderUnitMapping).map(({ name, sub }) => ({
        type: "custom",
        text: name,
        style: dataTableHeaderStyles,
        sub,
      })),
    };

    const unitsRow: Row = {
      rowId: "units",
      cells: Object.values(gasPvtHeaderUnitMapping).map(({ unit }) => ({
        type: "header",
        text: unit,
        style: headerDataTableUnitStyles,
      })),
    };

    return [
      headerRow,
      unitsRow,
      ...calculateValue.gas_pvt_charts.density.map((_, j) => ({
        rowId: j,
        cells: Object.keys(gasPvtHeaderUnitMapping).map((key) =>
          formatReactGridCell(
            key === "compressibility"
              ? calculateValue.gas_pvt_charts[key][j].toExponential(3)
              : calculateValue.gas_pvt_charts[key as keyof GasPvtDataTable][j]
          )
        ),
      })),
    ] as Row[];
  }, [calculateValue]);

  const waterPvtDataTableRows = useMemo<Row[]>(() => {
    if (!calculateValue?.water_pvt_charts) return [];

    const headerRow: Row<CustomCell> = {
      rowId: "header",
      cells: Object.values(waterPvtHeaderUnitMapping).map(({ name, sub }) => ({
        type: "custom",
        text: name,
        style: dataTableHeaderStyles,
        sub,
      })),
    };

    const unitsRow: Row = {
      rowId: "units",
      cells: Object.values(waterPvtHeaderUnitMapping).map(({ unit }) => ({
        type: "header",
        text: unit,
        style: headerDataTableUnitStyles,
      })),
    };

    return [
      headerRow,
      unitsRow,
      ...calculateValue.water_pvt_charts.density.map((_, j) => ({
        rowId: j,
        cells: Object.keys(waterPvtHeaderUnitMapping).map((key) =>
          formatReactGridCell(
            key === "compressibility"
              ? calculateValue.water_pvt_charts[key][j].toExponential(3)
              : calculateValue.water_pvt_charts[key as keyof WaterPvtDataTable][j]
          )
        ),
      })),
    ] as Row[];
  }, [calculateValue]);

  const oilPvtDataTableRows = useMemo<Row[]>(() => {
    if (!calculateValue?.gas_pvt_charts) return [];

    const headerRow: Row<CustomCell> = {
      rowId: "header",
      cells: Object.values(oilPvtHeaderUnitMapping).map(({ name, sub }) => ({
        type: "custom",
        text: name,
        style: dataTableHeaderStyles,
        sub,
      })),
    };

    const unitsRow: Row = {
      rowId: "units",
      cells: Object.values(oilPvtHeaderUnitMapping).map(({ unit }) => ({
        type: "header",
        text: unit,
        style: headerDataTableUnitStyles,
      })),
    };

    return [
      headerRow,
      unitsRow,
      ...calculateValue.oil_pvt_charts.density.map((_, j) => ({
        rowId: j,
        cells: Object.keys(oilPvtHeaderUnitMapping).map((key) =>
          formatReactGridCell(
            key === "compressibility"
              ? calculateValue.oil_pvt_charts[key][j].toExponential(3)
              : calculateValue.oil_pvt_charts[key as keyof OilPvtDataTable][j]
          )
        ),
      })),
    ] as Row[];
  }, [calculateValue]);

  const langmuirIsothermDataTableRows = useMemo<Row[]>(() => {
    if (!calculateValue?.langmuir_charts) return [];

    const headerRow: Row<CustomCell> = {
      rowId: "header",
      cells: Object.values(langmuirIsothermHeaderUnitMapping).map(({ name, sub }) => ({
        type: "custom",
        text: name,
        style: { background: "#fff", display: "flex", justifyContent: "center", fontWeight: "bold", zIndex: 1 },
        sub,
      })),
    };

    const unitsRow: Row = {
      rowId: "units",
      cells: Object.values(langmuirIsothermHeaderUnitMapping).map(({ unit }) => ({
        type: "header",
        text: unit,
        style: headerDataTableUnitStyles,
      })),
    };

    return [
      headerRow,
      unitsRow,
      ...calculateValue.langmuir_charts.gas_content.map((_, j: number) => ({
        rowId: j,
        cells: Object.keys(langmuirIsothermHeaderUnitMapping).map((key) =>
          formatReactGridCell(
            key === "desorption_compressibility"
              ? calculateValue.langmuir_charts[key][j].toExponential(3)
              : calculateValue.langmuir_charts[key as keyof LangmuirIsothermDataTable][j]
          )
        ),
      })),
    ] as Row[];
  }, [calculateValue]);
  return {
    gasPvtDataTableCols,
    gasPvtDataTableRows,
    waterPvtDataTableRows,
    waterPvtDataTableCols,
    oilPvtDataTableRows,
    oilPvtDataTableColumns,
    langmuirIsothermDataTableCols,
    langmuirIsothermDataTableRows,
  };
};

export default useGAZAutoRtaPvtDataTable;
