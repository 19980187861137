import { useState, useEffect, useMemo, useCallback } from "react";
import { CellChange, DropdownCell, Row } from "@silevis/reactgrid";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import _ from "lodash";

import { DataSet, ModuleId, Project, isDataSet } from "@/model";
import { RulonGroupRunResponse, RulonItem, postCalculateRulonGroupRun, postInitializeRulonGroupRun, postValidateRulonGroupRun } from "@/models/rulon";
import dictionary from "@/constants/dictionary";

import { tableCellStyle, tableHeaderStyle } from "@/components/CustomTable";
import { moduleDictionary } from "@/components/Modules/constants";

type UseGroupRunProps = {
  selectedDataSets: DataSet | DataSet[] | undefined;
  project?: Project;
  isLoading: boolean;
  setApiError: (error?: { message: string }) => void;
};

const rulonTableHeader = {
  data_set_id: dictionary.rulon.data_set_id,
  module: dictionary.rulon.module,
  input_status: dictionary.rulon.input_status,
  output_status: dictionary.rulon.output_status,
};

const dropdownOption = [
  {
    value: ModuleId.SPAD_DECLINE_GAS,
    label: moduleDictionary[ModuleId.SPAD_DECLINE_GAS],
  },
  {
    value: ModuleId.SPAD_DECLINE_OIL,
    label: moduleDictionary[ModuleId.SPAD_DECLINE_OIL],
  },
];

const useGroupRun = ({ selectedDataSets, isLoading, project, setApiError }: UseGroupRunProps) => {
  const [rulonState, setRulonState] = useState<RulonGroupRunResponse | null>();
  const [latestDataSets, setLatestDataSets] = useState<string[]>([]);
  const [activeDropdown, setActiveDropdown] = useState<number>(0);

  const [internalLoading, setInternalLoading] = useState(false);
  const [errorState, setErrorState] = useState(false);

  const client = useQueryClient();

  const dataSets = useMemo(() => {
    if (isDataSet(selectedDataSets)) return [selectedDataSets.id];
    return selectedDataSets?.map((dataSet) => dataSet.id) ?? [];
  }, [selectedDataSets]);

  const {
    isLoading: isLoadingInitialize,
    isFetching,
    isError,
  } = useQuery({
    queryKey: ["initialize-rulon-group-run", dataSets, project, rulonState],
    queryFn: async () => {
      setErrorState(false);

      return postInitializeRulonGroupRun(project?.id ?? "", dataSets);
    },
    select(data) {
      if (data?.data) {
        setRulonState(data.data);
        setLatestDataSets(dataSets);
      }
    },
    refetchOnWindowFocus: false,
    enabled: dataSets && dataSets.length > 0 && !!project?.id && !rulonState,
  });

  const loading = isLoadingInitialize || isFetching || isLoading || internalLoading;

  useEffect(() => {
    if (latestDataSets.length > 0 && !_.isEqual(latestDataSets, dataSets)) {
      setRulonState(null);
      client?.invalidateQueries();
    }
  }, [client, dataSets, latestDataSets]);

  const rulonColumns = Object.keys(rulonTableHeader).map((columnId, index) => {
    return { columnId: columnId, width: index === 0 ? 180 : 300 };
  });

  const rulonRows: Row<any>[] = useMemo(() => {
    const header = {
      rowId: "header",
      cells: Object.values(rulonTableHeader).map((header, index) => {
        return {
          type: "custom",
          text: header,
          style: tableHeaderStyle,
        };
      }),
      height: 50,
    };
    if (!rulonState) return [header];
    const modules = _.cloneDeep(rulonState.input_validation);
    const headerKeys = Object.keys(rulonTableHeader);
    return [
      header,
      ...modules.map((layer, rowIndex) => {
        return {
          rowId: rowIndex,
          height: 30,
          cells: [
            ...headerKeys.map((header, index) => {
              let val = layer[header as keyof RulonItem];
              if (index === 1) {
                return {
                  type: "dropdown",
                  selectedValue: val ?? undefined,
                  values: dropdownOption,
                  style: tableCellStyle,
                  isOpen: activeDropdown === rowIndex + 1,
                  nonEditable: loading,
                };
              }

              const textStyle = {
                ...tableCellStyle,
                color: "black",
              };

              // color
              if (header === "input_status") {
                if (val === "valid") textStyle.color = "darkseagreen";
                else textStyle.color = "red";
              }
              // value format
              if (header === "output_status") {
                val = `${new Date(val).toLocaleDateString()} ${new Date(val).toLocaleTimeString()}`;
              }
              return {
                type: "text",
                text: val ?? "-",
                style: textStyle,
                nonEditable: loading || header !== "module",
              };
            }),
          ],
        };
      }),
    ];
  }, [activeDropdown, loading, rulonState]);

  const onChangeCell = useCallback(
    (changes: CellChange[]) => {
      if (!rulonState) return;
      const updatedRows = [...rulonState.input_validation];

      for (const element of changes) {
        const change = element;
        let { rowId, columnId, newCell, previousCell, type } = change as CellChange<any>;
        const prevCell = previousCell as DropdownCell;
        const dropDownNewCell = newCell as DropdownCell;

        rowId = rowId as number;
        columnId = columnId as string;

        if (type === "dropdown") {
          const newDropdown = dropDownNewCell.isOpen ? rowId + 1 : 0;
          if (prevCell.isOpen !== dropDownNewCell.isOpen && newDropdown !== activeDropdown) {
            setActiveDropdown(dropDownNewCell.isOpen ? rowId + 1 : 0);
          } else {
            setActiveDropdown(0);
          }
          if (dropDownNewCell.isOpen) return;
          if (prevCell.selectedValue !== dropDownNewCell.selectedValue) {
            updatedRows[rowId].module = dropDownNewCell.selectedValue ?? "";
          }
        }
      }
      setRulonState((prev) => {
        if (!prev) return prev;
        return {
          ...prev,
          input_validation: updatedRows,
        };
      });
    },
    [activeDropdown, rulonState]
  );

  const onValidateRulon = useCallback(async () => {
    try {
      if (!project || !rulonState?.input_validation) return;
      setErrorState(false);

      setInternalLoading(true);
      const res = await postValidateRulonGroupRun(
        project?.id,
        rulonState?.input_validation.map((item) => ({ data_set_id: item.data_set_id, module: item.module }))
      );
      setRulonState(res.data);
    } catch (error) {
      setErrorState(true);
      console.log(error);
    } finally {
      setInternalLoading(false);
    }
  }, [rulonState, project]);

  const onCalculateRulon = useCallback(async () => {
    try {
      if (!project || !rulonState?.input_validation) return;
      setInternalLoading(true);
      setErrorState(false);

      const res = await postCalculateRulonGroupRun(
        project?.id,
        rulonState?.input_validation.map((item) => ({ data_set_id: item.data_set_id, module: item.module }))
      );
      setRulonState(res.data);
    } catch (error) {
      setErrorState(true);

      console.log(error);
    } finally {
      setInternalLoading(false);
    }
  }, [rulonState, project]);

  useEffect(() => {
    if (isError) {
      setApiError({
        message: dictionary.serverError,
      });
    } else {
      setApiError();
    }
  }, [isError, setApiError]);
  return {
    loading,
    rulonRows,
    rulonColumns,
    onChangeCell,
    onValidateRulon,
    onCalculateRulon,
    rulonState,
    isError: isError || errorState,
  };
};

export default useGroupRun;
