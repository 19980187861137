import { Output, UncertainKeys, UncertainParamDistribution, UncertainParamType } from "@/models/koldun";
import FixedInput from "@/modules/koldun/mcsim/csg/csg/Components/ModuleKoldunMcsimCsgInputGridTab/Components/UncertainParamInputs/FixedInput";
import UniformInput from "@/modules/koldun/mcsim/csg/csg/Components/ModuleKoldunMcsimCsgInputGridTab/Components/UncertainParamInputs/UniformInput";
import TriangularInput from "@/modules/koldun/mcsim/csg/csg/Components/ModuleKoldunMcsimCsgInputGridTab/Components/UncertainParamInputs/TriangularInput";
import LogNormalInput from "@/modules/koldun/mcsim/csg/csg/Components/ModuleKoldunMcsimCsgInputGridTab/Components/UncertainParamInputs/LogNormalInput";
import NormalInput from "@/modules/koldun/mcsim/csg/csg/Components/ModuleKoldunMcsimCsgInputGridTab/Components/UncertainParamInputs/NormalInput";
import React from "react";

import { ErrorValidationDetail } from "@/models/ErrorInputValidation";

interface CommonDistributionProps {
  value: UncertainParamType;
  units: string;
  output: Output;
  updateUncertainList: (layer: number, key: string, value: any) => void;
  measureNum: number;
  uncertainKey: UncertainKeys;
  errorInputValidation?: ErrorValidationDetail[];
  keyField?: string;
}

const CommonDistribution = ({
  value,
  measureNum,
  output,
  units,
  updateUncertainList,
  uncertainKey,
  errorInputValidation,
  keyField,
}: CommonDistributionProps) => {
  return value.distribution === UncertainParamDistribution.FIXED ? (
    <FixedInput
      measureNum={measureNum}
      output={output}
      units={units}
      updateUncertainList={updateUncertainList}
      uncertainKey={uncertainKey}
      keyField={keyField}
      errorInputValidation={errorInputValidation}
    />
  ) : value.distribution === UncertainParamDistribution.UNIFORM ? (
    <UniformInput
      measureNum={measureNum}
      output={output}
      units={units}
      updateUncertainList={updateUncertainList}
      uncertainKey={uncertainKey}
      errorInputValidation={errorInputValidation}
      keyField={keyField}
    />
  ) : value.distribution === UncertainParamDistribution.TRIANGULAR ? (
    <TriangularInput
      measureNum={measureNum}
      output={output}
      units={units}
      updateUncertainList={updateUncertainList}
      uncertainKey={uncertainKey}
      errorInputValidation={errorInputValidation}
      keyField={keyField}
    />
  ) : value.distribution === UncertainParamDistribution.LOG_NORMAL ? (
    <LogNormalInput
      measureNum={measureNum}
      output={output}
      units={units}
      updateUncertainList={updateUncertainList}
      uncertainKey={uncertainKey}
      errorInputValidation={errorInputValidation}
      keyField={keyField}
    />
  ) : value.distribution === UncertainParamDistribution.NORMAL ? (
    <NormalInput
      measureNum={measureNum}
      output={output}
      units={units}
      updateUncertainList={updateUncertainList}
      uncertainKey={uncertainKey}
      errorInputValidation={errorInputValidation}
      keyField={keyField}
    />
  ) : null;
};

export default CommonDistribution;
