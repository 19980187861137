import { useMemo } from "react";
import useThemeStyling from "@/utils/useThemeStyling";
import { InputGridCalculation } from "@/models/gaz/autoRta";

import { FossilyticsChartAxis, FossilyticsChartSeries } from "@/components/FossilyticsChart";

const useGazAutoRtaWaterPvtCharts = (calculateValue: InputGridCalculation) => {
  const { theme } = useThemeStyling();

  // Mu
  const waterPvtMuwXAxes = useMemo<FossilyticsChartAxis[]>(
    () => [
      {
        name: "Pressure (psia)",
        type: "value",
        color: theme.palette.black,
      },
    ],
    [theme.palette.black]
  );

  const waterPvtMuwYAxes = useMemo<FossilyticsChartAxis[]>(
    () => [
      {
        name: "Viscosity (cP)",
        type: "value",
        color: theme.palette.black,
        show: true,
      },
    ],
    [theme.palette.black]
  );

  const waterPvtMuwSeries = useMemo<FossilyticsChartSeries[]>(
    () =>
      calculateValue
        ? [
            {
              name: `Water Mu`,
              type: "line",
              hideSymbol: true,
              color: theme.palette.orangeLighter,
              lineWidth: 2,
              z: 2,
              data: calculateValue.water_pvt_charts.pressure.map((p, j) => [p, calculateValue.water_pvt_charts.viscosity[j]]),
            },
          ]
        : [],
    [calculateValue, theme.palette.orangeLighter]
  );

  // Pw - density
  const waterPvtPwXAxes = useMemo<FossilyticsChartAxis[]>(
    () => [
      {
        name: "Pressure (psia)",
        type: "value",
        color: theme.palette.black,
      },
    ],
    [theme.palette.black]
  );

  const waterPvtPwYAxes = useMemo<FossilyticsChartAxis[]>(
    () => [
      {
        name: "Density (lbm/ft³)",
        type: "value",
        color: theme.palette.black,
        show: true,
      },
    ],
    [theme.palette.black]
  );

  const waterPvtPwSeries = useMemo<FossilyticsChartSeries[]>(
    () =>
      calculateValue
        ? [
            {
              name: `Water Pw`,
              type: "line",
              hideSymbol: true,
              color: theme.palette.orangeLighter,
              lineWidth: 2,
              z: 2,
              data: calculateValue.water_pvt_charts.pressure.map((p, j) => [p, calculateValue.water_pvt_charts.density[j]]),
            },
          ]
        : [],
    [calculateValue, theme.palette.orangeLighter]
  );

  // Bg - (Formation volume factor)
  const waterPvtBwXAxes = useMemo<FossilyticsChartAxis[]>(
    () => [
      {
        name: "Pressure (psia)",
        type: "value",
        color: theme.palette.black,
      },
    ],
    [theme.palette.black]
  );

  const waterPvtBwYAxes = useMemo<FossilyticsChartAxis[]>(
    () => [
      {
        name: "Formation volume factor (rb/stb)",
        type: "value",
        color: theme.palette.black,
        show: true,
      },
    ],
    [theme.palette.black]
  );

  const waterPvtBwSeries = useMemo<FossilyticsChartSeries[]>(
    () =>
      calculateValue
        ? [
            {
              name: `Water Bg`,
              type: "line",
              hideSymbol: true,
              color: theme.palette.orangeLighter,
              lineWidth: 2,
              z: 2,
              data: calculateValue.water_pvt_charts.pressure.map((p, j) => [p, calculateValue.water_pvt_charts.form_vol_factor[j]]),
            },
          ]
        : [],
    [calculateValue, theme.palette.orangeLighter]
  );

  // cg - compressibility (psi-1)
  const waterPvtcwXAxes = useMemo<FossilyticsChartAxis[]>(
    () => [
      {
        name: "Pressure (psia)",
        type: "value",
        color: theme.palette.black,
      },
    ],
    [theme.palette.black]
  );

  const waterPvtcwYAxes = useMemo<FossilyticsChartAxis[]>(
    () => [
      {
        name: "compressibility (psi-1)",
        type: "value",
        color: theme.palette.black,
        show: true,
      },
    ],
    [theme.palette.black]
  );

  const waterPvtcwSeries = useMemo<FossilyticsChartSeries[]>(
    () =>
      calculateValue
        ? [
            {
              name: `Water Cg`,
              type: "line",
              hideSymbol: true,
              color: theme.palette.orangeLighter,
              lineWidth: 2,
              z: 2,
              data: calculateValue.water_pvt_charts.pressure.map((p, j) => [p, calculateValue.water_pvt_charts.compressibility[j]]),
            },
          ]
        : [],
    [calculateValue, theme.palette.orangeLighter]
  );

  return {
    waterPvtMu: {
      xAxes: waterPvtMuwXAxes,
      yAxes: waterPvtMuwYAxes,
      series: waterPvtMuwSeries,
    },
    waterPvtPw: {
      xAxes: waterPvtPwXAxes,
      yAxes: waterPvtPwYAxes,
      series: waterPvtPwSeries,
    },
    waterPvtBw: {
      xAxes: waterPvtBwXAxes,
      yAxes: waterPvtBwYAxes,
      series: waterPvtBwSeries,
    },
    waterPvtcw: {
      xAxes: waterPvtcwXAxes,
      yAxes: waterPvtcwYAxes,
      series: waterPvtcwSeries,
    },
  };
};
export default useGazAutoRtaWaterPvtCharts;
