import FossilyticsFieldBase, { IFossilyticsFieldBaseProps } from "./FossilyticsFieldBase";
import { Dropdown, IDropdownOption } from "@fluentui/react";
import { useCallback } from "react";
import InputErrorWrapper, { InputErrorWrapperProps } from "../Error/InputErrorWrapper/InputErrorWrapper";

interface FossilyticsDropdownProps extends IFossilyticsFieldBaseProps, Omit<InputErrorWrapperProps, "children"> {
  label: string;
  options: IDropdownOption[];
  disabled?: boolean;
  selectedKey: string | number | string[] | number[] | null | undefined;
  onChange: (value: IDropdownOption | undefined) => void;
}

function FossilyticsDropdown({
  label,
  options,
  disabled,
  selectedKey,
  onChange,
  calloutContent,
  helpUrl,
  keyField,
  errors,
  ...rest
}: Readonly<FossilyticsDropdownProps>) {
  const onChangeWrapper = useCallback(
    (_: React.FormEvent<HTMLDivElement>, value?: IDropdownOption) => {
      return onChange(value);
    },
    [onChange]
  );

  if (keyField) {
    return (
      <InputErrorWrapper errors={errors} keyField={keyField}>
        {(errorValidation) => (
          <FossilyticsFieldBase helpUrl={helpUrl} calloutContent={calloutContent}>
            {(onRenderLabel, baseDisabled) => (
              <Dropdown
                errorMessage={errorValidation}
                label={label}
                options={options}
                selectedKey={selectedKey}
                disabled={baseDisabled || disabled}
                onChange={onChangeWrapper}
                onRenderLabel={onRenderLabel}
                {...rest}
              />
            )}
          </FossilyticsFieldBase>
        )}
      </InputErrorWrapper>
    );
  }

  return (
    <FossilyticsFieldBase helpUrl={helpUrl} calloutContent={calloutContent}>
      {(onRenderLabel, baseDisabled) => (
        <Dropdown
          label={label}
          options={options}
          selectedKey={selectedKey}
          disabled={baseDisabled || disabled}
          onChange={onChangeWrapper}
          onRenderLabel={onRenderLabel}
          {...rest}
        />
      )}
    </FossilyticsFieldBase>
  );
}

export default FossilyticsDropdown;
