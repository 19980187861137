export enum ModuleRollupExportInterval {
  DAILY = "daily",
  MONTHLY = "monthly",
  YEARLY = "yearly",
}

export type ModuleRollupValidateResponse = {
  missing_analyses: string[];
  data_set_ids: string[];
  max_days: number[];
};

export type ModuleRollupPlotResponse = {
  arps_plot: ModuleRollupArpsPlot;
  rose_plot: ModuleRollupRosePlot;
};

export type ModuleRollupArpsPlot = {
  high: ModuleRollupLine;
  mid: ModuleRollupLine;
  low: ModuleRollupLine;
};

export type ModuleRollupLine = {
  cumRates: number[];
  days: number[];
  dates: string[];
  rates: number[];
  logRates: number[];
};

export type ModuleRollupRosePlot = {
  xArr: number[];
  yArr: number[];
  idArr: string[];
  linesXArr: number[];
  upperLine: number[];
  lowerLine: number[];
};
