import React, { useCallback } from "react";
import { Dropdown, IDropdownOption, Stack } from "@fluentui/react";
import { UncertainParamDistribution, MonteCarloInputApplication } from "@/models/koldun";
import { DistributionProps, applicationOptions } from "./model";
import KDependenceInput from "@/modules/koldun/mcsim/csg/csg/Components/ModuleKoldunMcsimCsgInputGridTab/Components/UncertainParamInputs/KDependenceInput";
import _ from "lodash";
import { getNewUncertainList } from "../../../utils";
import CommonDistribution from "@/modules/koldun/mcsim/csg/csg/Components/ModuleKoldunMcsimCsgInputGridTab/Components/UncertainParamInputs/CommonDistribution";
import InputErrorWrapper from "@/components/Error/InputErrorWrapper/InputErrorWrapper";

const KdependenceTypeOptions = [
  { key: UncertainParamDistribution.FIXED, text: "Fixed" },
  { key: UncertainParamDistribution.UNIFORM, text: "Uniform" },
  { key: UncertainParamDistribution.TRIANGULAR, text: "Triangular" },
  { key: UncertainParamDistribution.LOG_NORMAL, text: "Log Normal" },
  { key: UncertainParamDistribution.NORMAL, text: "Normal" },
  { key: UncertainParamDistribution.KDEPENDENCE, text: "kdependence" },
] as IDropdownOption[];

const PorosityDistribution = ({ name, units, onChange, output, measureNum, setOutput, keyField, errorInputValidation }: DistributionProps) => {
  const uncertainKey = "porosity_perc";
  const value = output.measures[measureNum].measure_layers[0].uncertain_list[uncertainKey];
  const onDistributionChange = useCallback(
    (v: any) => {
      switch (v) {
        case UncertainParamDistribution.FIXED:
          onChange({
            distribution: UncertainParamDistribution.FIXED,
            application: value.application,
            value: 0.5,
          });
          break;
        case UncertainParamDistribution.UNIFORM:
          onChange({
            distribution: UncertainParamDistribution.UNIFORM,
            application: value.application,
            low: 0.4,
            high: 1.5,
          });
          break;
        case UncertainParamDistribution.TRIANGULAR:
          onChange({
            distribution: UncertainParamDistribution.TRIANGULAR,
            application: value.application,
            left: 0.4,
            mode: 0.7,
            right: 1.5,
          });
          break;
        case UncertainParamDistribution.LOG_NORMAL:
          onChange({
            distribution: UncertainParamDistribution.LOG_NORMAL,
            application: value.application,
            mean: 0.7,
            sigma: 0.02,
          });
          break;
        case UncertainParamDistribution.NORMAL:
          onChange({
            distribution: UncertainParamDistribution.NORMAL,
            application: value.application,
            mean: 0.7,
            std: 0.02,
          });
          break;
        case UncertainParamDistribution.KDEPENDENCE:
          onChange({
            distribution: UncertainParamDistribution.KDEPENDENCE,
            application: value.application,
            min: 0.4,
            max: 1.5,
            perm_at_min_porosity: 0.1,
          });
          break;
      }
    },
    [onChange, value.application]
  );

  const handleApplication = useCallback(
    (v: MonteCarloInputApplication) => {
      onChange({
        ...value,
        application: v,
      });
    },
    [onChange, value]
  );

  const handleOutputChange = (layer: number, key: string, value: any) => {
    setOutput((prev) => {
      if (!prev) return prev;
      const prevOutput = _.cloneDeep(prev);
      prevOutput.measures[measureNum].measure_layers[layer].uncertain_list = getNewUncertainList(
        prevOutput.measures[measureNum].measure_layers[layer].uncertain_list,
        uncertainKey,
        key,
        value
      );
      return prevOutput;
    });
  };

  return (
    <>
      <Stack horizontal tokens={{ childrenGap: 20 }} style={{ marginBottom: "10px" }}>
        <Stack horizontal tokens={{ childrenGap: 20 }} style={{ width: 425 }}>
          <Stack grow={0.5} className="equal-size">
            <InputErrorWrapper errors={errorInputValidation} keyField={keyField}>
              {(validationMsg) => {
                return (
                  <Dropdown
                    label={`${name} distribution`}
                    options={KdependenceTypeOptions}
                    selectedKey={value.distribution}
                    onChange={(_, v) => (v !== undefined ? onDistributionChange(v.key) : null)}
                    errorMessage={validationMsg}
                  />
                );
              }}
            </InputErrorWrapper>
          </Stack>
          <Stack grow={0.5} className="equal-size">
            <Dropdown
              label="Application"
              options={applicationOptions}
              selectedKey={value.application}
              onChange={(_, v) => (v !== undefined ? handleApplication(v.key as MonteCarloInputApplication) : null)}
            />
          </Stack>
        </Stack>

        {value.distribution === UncertainParamDistribution.KDEPENDENCE ? (
          <KDependenceInput
            measureNum={measureNum}
            output={output}
            units={units}
            updateUncertainList={handleOutputChange}
            uncertainKey={"porosity_perc"}
            keyField={keyField}
            errorInputValidation={errorInputValidation}
          />
        ) : (
          <CommonDistribution
            value={value}
            measureNum={measureNum}
            output={output}
            units={units}
            updateUncertainList={handleOutputChange}
            uncertainKey={uncertainKey}
            keyField={keyField}
            errorInputValidation={errorInputValidation}
          />
        )}
      </Stack>
    </>
  );
};

export default PorosityDistribution;
