import { useMemo } from "react";
import { DateTime } from "luxon";

import { ModuleId, Field, DataResponse } from "@/model";

import { getGasDataFields, getOilDataFields } from "../index.utils";
import useThemeStyling from "@/utils/useThemeStyling";

type UseSpadDeclineDataViewProps = {
  currentModule?: ModuleId;
  forecastStartDate?: string;
  cleaned?: DataResponse;
};

const useSpadDeclineDataView = ({ currentModule, cleaned, forecastStartDate }: UseSpadDeclineDataViewProps) => {
  const { theme } = useThemeStyling();

  const moduleFields = currentModule === ModuleId.SPAD_DECLINE_OIL ? getOilDataFields(theme) : getGasDataFields(theme);

  const isForecastBeforeData = useMemo(() => {
    if (forecastStartDate && cleaned) {
      const lastDateStr = cleaned.data[0][cleaned.fields.indexOf(Field.DATE_TIME)];
      return lastDateStr && DateTime.fromISO(forecastStartDate) < DateTime.fromISO(lastDateStr);
    }
    return false;
  }, [cleaned, forecastStartDate]);

  return {
    moduleFields,
    isForecastBeforeData,
  };
};

export default useSpadDeclineDataView;
