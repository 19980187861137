import { ReactGrid } from "@silevis/reactgrid";

import { AnalysisSummaryProps } from "./analysis-summary.types";
import useReserveTable from "./useReserveTable";

import styles from "./analysis-summary.module.css";
import useParameterTable from "./useParameterTable";
import { useEffect } from "react";
import LoadingIndicator from "@/components/LoadingIndicator";

export const AnalysisSummary = ({
  initOutput,
  arpsLong,
  arpsShort,
  parametersLong,
  parametersShort,
  subChar,
  unit,
  dataSets,
  setCsvData,
  isLoading,
  isApiError,
}: AnalysisSummaryProps) => {
  const { reserveTableRow, spadReserveTableColumn, reserveTableCsv } = useReserveTable({
    arpsLong,
    arpsShort,
    subChar,
    unit,
    initOutput,
    dataSets,
  });
  const { parameterTableRows, spadParameterResultTableColumn, parameterTableCsv } = useParameterTable({
    arpsLong,
    arpsShort,
    unit,
    parametersLong,
    parametersShort,
    dataSets,
  });

  useEffect(() => {
    if (reserveTableCsv.data.length > 0 && parameterTableCsv.data.length > 0) {
      setCsvData([reserveTableCsv, parameterTableCsv]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reserveTableCsv, parameterTableCsv]);

  return (
    <div className="h100">
      {!isApiError && (
        <>
          {isLoading || !arpsLong || !arpsShort || !parametersLong || !parametersShort ? (
            <div className="h100 center-flex">
              <LoadingIndicator />
            </div>
          ) : (
            <>
              <div className={styles.analysisTable} data-testid="reserve-table">
                <ReactGrid columns={spadReserveTableColumn} rows={reserveTableRow} enableRangeSelection enableColumnSelection />
              </div>

              <div className={styles.analysisTable} data-testid="parameter-table">
                <ReactGrid columns={spadParameterResultTableColumn} rows={parameterTableRows} enableRangeSelection enableColumnSelection />
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};
