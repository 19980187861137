import { useCallback, useMemo, useState, useEffect } from "react";

import { ProjectDataSet, getProjectGroupDatasetKey, parseProjectGroupDatasetKey } from "@/util";
import DropdownField, { DropdownOption } from "@/components/fields/DropdownField";
import { DataSet, Group, Project } from "@/model";

const getDataSetIdsInGroups = (groups: Group[]) => {
  const datasets: { id: string; groupId: string }[] = [];
  for (let group of groups) {
    if (group.data_set_ids) {
      group.data_set_ids.forEach((id) => {
        datasets.push({
          id,
          groupId: group.id,
        });
      });
    }
    if (group.groups) {
      datasets.push(...getDataSetIdsInGroups(group.groups));
    }
  }
  return datasets.filter((v, i, self) => self.indexOf(v) === i);
};

export type DataSetDropdownProps = {
  setCompareDataSet: (projectDataSet?: ProjectDataSet) => void;
  setCompareName: (name?: string) => void;
  projects?: Project[];
  dataSets?: DataSet[];
  selectedDataSets?: DataSet | DataSet[];
};

export const CompareDropdown = ({ setCompareDataSet, setCompareName, projects, dataSets, selectedDataSets }: DataSetDropdownProps) => {
  const [compareKey, setCompareKey] = useState<string>("none");

  const projectsWithDatasets = useMemo(
    () =>
      projects && dataSets
        ? projects.map((p) => ({
            id: p.id,
            name: p.name,
            datasets: getDataSetIdsInGroups(p.groups).map((dataSet) => ({
              id: dataSet.id,
              groupId: dataSet.groupId,
              name: dataSets.find((d) => d.id === dataSet.id)?.name ?? "Not found",
            })),
          }))
        : [],
    [dataSets, projects]
  );

  const selectOptions = useMemo<DropdownOption[]>(
    () => [
      { key: "none", text: "None" },
      ...projectsWithDatasets
        .filter((p) => p.datasets.length > 0)
        .flatMap((p) => [
          { key: p.id, text: p.name, isHeader: true },
          ...p.datasets.map((dataSet) => ({
            key: getProjectGroupDatasetKey(p.id, dataSet.groupId, dataSet.id.split(",")),
            text: dataSet.name,
            id: dataSet.id,
          })),
        ]),
    ],
    [projectsWithDatasets]
  );

  const onSelectItem = useCallback(
    (option: string) => {
      const key = option == null || typeof option === "number" ? "none" : option;
      setCompareKey(key);

      if (key !== "none") {
        const info = parseProjectGroupDatasetKey(option);
        const project = projectsWithDatasets.find((p) => p.id === info.projectId);
        const projectName = project?.name;
        const datasetsName = project?.datasets
          .filter((d) => info.dataSetIds.includes(d.id))
          .map((d) => d.name)
          .join(", ");
        const compareName = `${projectName} - ${datasetsName}`;
        setCompareName(compareName);
        setCompareDataSet(info);
      } else {
        setCompareName(undefined);
        setCompareDataSet(undefined);
      }
    },
    [projectsWithDatasets, setCompareDataSet, setCompareName]
  );

  useEffect(() => {
    setCompareKey("none");
  }, [selectedDataSets]);

  return <DropdownField label="Compare analyses" options={selectOptions} selectedKey={compareKey} onChange={onSelectItem} />;
};

export default CompareDropdown;
