import { z, object, number, boolean, nativeEnum, coerce, string } from "zod";

import { ModuleIdentity, genericChartResultScheme } from "@/models/Generic";

export type SpadDeclineType = "oil" | "gas";

const spadDeclineDataOptionScheme = object({
  pressure_correction: number().nullable(),
});

type SpadDeclineDataOption = z.infer<typeof spadDeclineDataOptionScheme>;

export enum SelectedForecastRollup {
  Profile = "Profile",
  Operational = "Operational",
}

const ParameterCaseScheme = object({
  initial_rate: coerce.number(),
  arps_exponent: coerce.number(),
  initial_decline_rate: coerce.number(),
  initial_day: coerce.number(),
});

export type ParameterCase = z.infer<typeof ParameterCaseScheme>;

function CaseResultScheme<T extends z.ZodTypeAny>(itemSchema: T) {
  return object({
    low: itemSchema,
    mid: itemSchema,
    high: itemSchema,
  });
}

export type CaseResult<T> = {
  low: T;
  mid: T;
  high: T;
};

const AnalysisOptionScheme = object({
  auto_update_smart_fit: boolean(),
  auto_update_cluster_start_day: boolean(),
  analysis_start_day: coerce.number().int(),
  operational_forecast: CaseResultScheme(ParameterCaseScheme),
  profile_forecast: CaseResultScheme(ParameterCaseScheme),
  economic_cutoff: coerce.number(),
  selected_forecast_rollup: nativeEnum(SelectedForecastRollup),
});

export type AnalysisOption = z.infer<typeof AnalysisOptionScheme>;

const ChartResultSheme = object({
  chart_profiles: genericChartResultScheme,
  decline_parameter_result: object({
    forecast_start_rate: coerce.number(),
    forecast_start_decline_rate: coerce.number(),
  }),
  decline_volume_result: object({
    estimated_ultimate_recovery: coerce.number(),
    remaining_reserve: coerce.number(),
  }),
});

const AnalysisResultScheme = object({
  profile_result: CaseResultScheme(ChartResultSheme),
  operational_result: CaseResultScheme(ChartResultSheme),
});

export type AnalysisResult = z.infer<typeof AnalysisResultScheme>;

const AnalysedDataScheme = object({
  log_chart: genericChartResultScheme,
  cartesian_chart: genericChartResultScheme,
});

export type AnalysedData = z.infer<typeof AnalysedDataScheme>;

// validation API & Calculation API Payload
export type SpadDeclinePayload = {
  analysis_identity: ModuleIdentity;
  data_option: SpadDeclineDataOption;
  analysis_option: AnalysisOption;
};

const CoordinateScheme = object({
  x: coerce.number(),
  y: coerce.number(),
});

const SpadDeclineCoordinateScheme = object({
  identity: coerce.string(),
  coordinates: CoordinateScheme.array().max(2),
});

export type SpadDeclineCoordinate = z.infer<typeof SpadDeclineCoordinateScheme>;

const SpadDeclineHandleBarScheme = object({
  rate_time: SpadDeclineCoordinateScheme.array(),
  rate_cumprod: SpadDeclineCoordinateScheme.array(),
});

export const SpadDeclineCalculationScheme = object({
  analysis_option: AnalysisOptionScheme.required(),
  analysis_result: AnalysisResultScheme.nullable(),
  analysed_data: AnalysedDataScheme.required(),
  chart_handlebars: SpadDeclineHandleBarScheme.required(),
}).strict();

export type SpadDeclineCalculation = z.infer<typeof SpadDeclineCalculationScheme>;

const SpadDataViewItemScheme = object({
  label: string(),
  unit: string().nullable(),
  data: z.union([z.string(), z.number()]).array(),
}).strict();

export const SpadDeclineStateScheme = object({
  data_option: spadDeclineDataOptionScheme,
  forecast_start_day: number(),
  forecast_end_day: number().nullable(),
  // unused
  day_zero_date: string().date(),
  dataview_response: SpadDataViewItemScheme.array(),
})
  .merge(SpadDeclineCalculationScheme)
  .strict();

export type SpadDeclineState = z.infer<typeof SpadDeclineStateScheme>;
