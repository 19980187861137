import { spadDeclineAnalysisExport } from "@/constants/apiUrl";

import { fetchApi } from "@/utils/apiFetcher";
import { SpadDeclineType, SpadDeclinePayload } from "../State";

export enum ExportIntervalEnum {
  daily = "daily",
  monthly = "monthly",
  yearly = "yearly",
}

type ExportSpadDecline = {
  body: SpadDeclinePayload;
  type: SpadDeclineType;
  interval: ExportIntervalEnum;
};

export const postExportSpadDecline = ({ body, interval, type }: ExportSpadDecline) => {
  return fetchApi<Blob, SpadDeclinePayload>({
    path: spadDeclineAnalysisExport(type),
    body,
    type: "post",
    queryStringParameters: {
      interval,
    },
    responseType: "blob",
  });
};
