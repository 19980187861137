import ReactGridTable from "@/components/ReactGridTable/ReactGridTable";
import useTahkCsgWellInput, { TahkCsgWellInputProps } from "../../../hooks/input/charts/useTahkCsgWellInput";
import dictionary from "@/constants/dictionary";
import { calculateHeight } from "@/utils/cellRendering";

const WellInputs = (props: TahkCsgWellInputProps) => {
  const { inputColumns, inputRows, onChangeCells, onChangePowerLawSkin, powerLawColumns, powerLawRows, showPowerLawTable } =
    useTahkCsgWellInput(props);

  return (
    <div
      style={{
        marginTop: 20,
        display: "flex",
      }}
    >
      <div>
        <ReactGridTable
          className="noScrollbarAll"
          innerStyle={{
            display: "flex",
            marginTop: 23,
          }}
          viewWidth={950}
          viewHeight={calculateHeight(inputRows) + 130}
          minHeight={calculateHeight(inputRows) + 50}
          columns={inputColumns}
          rows={inputRows}
          enableRangeSelection
          enableColumnSelection
          stickyTopRows={2}
          onCellsChanged={onChangeCells}
        />
      </div>
      {showPowerLawTable && (
        <div style={{ width: 300, marginLeft: 20 }}>
          <h4>{dictionary.tahk.powerLawSection}</h4>
          <ReactGridTable
            viewWidth={"100%"}
            viewHeight={calculateHeight(powerLawRows) + 50}
            columns={powerLawColumns}
            rows={powerLawRows}
            enableRangeSelection
            enableColumnSelection
            stickyTopRows={2}
            onCellsChanged={onChangePowerLawSkin}
          />
        </div>
      )}
    </div>
  );
};

export default WellInputs;
