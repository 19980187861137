import { useCallback, useEffect, useState } from "react";
import { IDropdownOption } from "@fluentui/react";
import _ from "lodash";

import { useGazAutoRtaState } from "../GazAutoRtaContext";

import {
  GazAutoRtaInputGrid,
  pollCalculateInputGazAutoRta,
  DefinedParameters,
  GasPvtRecombination,
  InputGridCalculation,
  LangmuirIsotherm,
  GasPvt,
  GazAutoRta,
  OilPvt,
  WaterPvt,
  WellboreModel,
} from "@/models/gaz/autoRta";

import { useQuery } from "@tanstack/react-query";

export type GazAutoRtaInputGridTabProps = {
  isLoading: boolean;
  currentTab: number;
};

const useGazAutoRtaInputGridTab = () => {
  const { gazAutoRTAState, setGazAutoRTAState } = useGazAutoRtaState();
  const [inputGridCalculate, setInputGridCalculate] = useState<InputGridCalculation>();
  const [gasRecombination, setGasRecombination] = useState<boolean | undefined>(
    gazAutoRTAState?.inputs.gas_pvt.section_gas_recombination.use_gas_recombination
  );
  const [wellboreModelCheckBox, setWellboreModelCheckBox] = useState<boolean | undefined>(gazAutoRTAState?.inputs.wellbore_model.use_wellbore_model);

  const { refetch, isFetching, isLoading } = useQuery({
    queryKey: ["input-grid-calculation", gazAutoRTAState?.inputs],
    queryFn: async () => {
      if (gazAutoRTAState?.inputs) {
        return pollCalculateInputGazAutoRta({ ...gazAutoRTAState.inputs } as GazAutoRtaInputGrid);
      }
      return {};
    },
    refetchOnWindowFocus: false,
    enabled: false,
  });

  const updateDefineParameterInputField = useCallback(
    (v: number | string | undefined, key: keyof DefinedParameters) => {
      if (v !== undefined) {
        setGazAutoRTAState((prev) => {
          if (!prev) return prev;
          const prevInputs = _.cloneDeep(prev);
          prevInputs.inputs.defined_parameters = {
            ...prevInputs.inputs.defined_parameters,
            [key]: v,
          };
          return { ...prevInputs };
        });
      }
    },
    [setGazAutoRTAState]
  );

  const updateGasPvtInputField = useCallback(
    (v: number | undefined, key: keyof GasPvt) => {
      if (v !== undefined) {
        setGazAutoRTAState((prev) => {
          if (!prev) return prev;
          const prevInputs = _.cloneDeep(prev);
          return {
            ...prevInputs,
            inputs: {
              ...prev.inputs,
              gas_pvt: {
                ...prev.inputs.gas_pvt,
                [key]: v,
              },
            },
          };
        });
      }
    },
    [setGazAutoRTAState]
  );

  const updateGasRecombinationInput = useCallback(
    (v: number | undefined, key: keyof Omit<GasPvtRecombination, "use_gas_recombination">) => {
      if (v === undefined) return;
      setGazAutoRTAState((prev) => {
        if (!prev) return prev;
        const prevInputs: GazAutoRta = _.cloneDeep(prev);
        prevInputs.inputs.gas_pvt.section_gas_recombination[key] = v;
        return { ...prevInputs };
      });
    },
    [setGazAutoRTAState]
  );

  const updateWellboreModelInputField = useCallback(
    (v: number | undefined, key: keyof WellboreModel) => {
      if (v === undefined) return;
      setGazAutoRTAState((prev) => {
        if (!prev) return prev;
        const prevInputs = _.cloneDeep(prev);
        return {
          ...prevInputs,
          inputs: {
            ...prev.inputs,
            wellbore_model: {
              ...prev.inputs.wellbore_model,
              [key]: v,
            },
          },
        };
      });
    },
    [setGazAutoRTAState]
  );

  const updateOilPvtInputField = useCallback(
    (v: number | undefined, key: keyof OilPvt) => {
      if (v === undefined) return;
      setGazAutoRTAState((prev) => {
        if (!prev) return prev;
        const prevInputs = _.cloneDeep(prev);
        return {
          ...prevInputs,
          inputs: {
            ...prev.inputs,
            oil_pvt: {
              ...prev.inputs.oil_pvt,
              [key]: v,
            },
          },
        };
      });
    },
    [setGazAutoRTAState]
  );

  const updateOilPvtDropdownField = useCallback(
    (v: IDropdownOption | undefined, key: keyof OilPvt) => {
      if (v === undefined) return;
      setGazAutoRTAState((prev) => {
        if (!prev) return prev;
        const prevInputs = _.cloneDeep(prev);
        return {
          ...prevInputs,
          inputs: {
            ...prev.inputs,
            oil_pvt: {
              ...prev.inputs.oil_pvt,
              [key]: v.key,
            },
          },
        };
      });
    },
    [setGazAutoRTAState]
  );

  const updateLangmuirInputField = useCallback(
    (v: number | undefined, key: keyof LangmuirIsotherm) => {
      if (v === undefined) return;
      setGazAutoRTAState((prev) => {
        if (!prev) return prev;
        const prevInputs: GazAutoRta = _.cloneDeep(prev);
        prevInputs.inputs.langmuir_isotherm[key as keyof LangmuirIsotherm] = v;
        return { ...prevInputs };
      });
    },
    [setGazAutoRTAState]
  );

  const onCalculateAutoRtaInput = useCallback(async () => {
    if (!gazAutoRTAState?.inputs) return;
    try {
      const response = await refetch();
      if (response.data?.data) setInputGridCalculate(response.data?.data);
    } catch (error) {
      console.log(error);
    }
  }, [gazAutoRTAState?.inputs, refetch]);

  useEffect(() => {
    onCalculateAutoRtaInput();
  }, [onCalculateAutoRtaInput]);

  const onCheckboxGasRecombination = useCallback(
    (v: boolean | undefined) => {
      if (v === undefined) return;
      setGasRecombination(!!v);
      setGazAutoRTAState((prev) => {
        if (!prev) return prev;
        const prevInputs: GazAutoRta = _.cloneDeep(prev);
        prevInputs.inputs.gas_pvt.section_gas_recombination.use_gas_recombination = v;
        return { ...prevInputs };
      });
    },
    [setGazAutoRTAState]
  );

  const updateWaterPvtSelection = useCallback(
    (v: IDropdownOption | undefined, key: keyof WaterPvt) => {
      if (v === undefined) return;
      setGazAutoRTAState((prev) => {
        if (!prev) return prev;
        const prevInputs = _.cloneDeep(prev);
        return {
          ...prevInputs,
          inputs: {
            ...prev.inputs,
            water_pvt: {
              ...prev.inputs.water_pvt,
              [key]: v.key,
            },
          },
        };
      });
    },
    [setGazAutoRTAState]
  );

  const onCheckWellboreModel = useCallback(
    (v: boolean | undefined) => {
      if (v === undefined) return;
      setWellboreModelCheckBox(!!v);
      setGazAutoRTAState((prev) => {
        if (!prev) return prev;
        const prevInputs: GazAutoRta = _.cloneDeep(prev);
        prevInputs.inputs.wellbore_model.use_wellbore_model = v;
        return { ...prevInputs };
      });
    },
    [setGazAutoRTAState]
  );

  const updateGasPvtSelection = useCallback(
    (v: IDropdownOption | undefined, key: keyof GasPvt) => {
      if (v === undefined) return;
      setGazAutoRTAState((prev) => {
        if (!prev) return prev;
        const prevInputs = _.cloneDeep(prev);
        return {
          ...prevInputs,
          inputs: {
            ...prev.inputs,
            gas_pvt: {
              ...prev.inputs.gas_pvt,
              [key]: v.key,
            },
          },
        };
      });
    },
    [setGazAutoRTAState]
  );
  return {
    inputGridValues: gazAutoRTAState?.inputs,
    inputGridCalculate,
    gasRecombination,
    wellboreModelCheckBox,
    onCheckboxGasRecombination,
    setGazAutoRTAState,
    updateDefineParameterInputField,
    updateGasPvtInputField,
    updateGasRecombinationInput,
    updateWellboreModelInputField,
    updateOilPvtInputField,
    updateOilPvtDropdownField,
    updateLangmuirInputField,
    updateWaterPvtSelection,
    onCheckWellboreModel,
    updateGasPvtSelection,
    loadInputGrid: isFetching || isLoading,
  };
};
export default useGazAutoRtaInputGridTab;
