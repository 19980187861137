import { useAppStore } from "@/features/app";
import ModulePage from "@/modules/ModulePage";

import { useState } from "react";
import { shallow } from "zustand/shallow";

import "./ModuleGasAutoRTA.css";

// dictionary
import dictionary from "@/constants/dictionary";

// container
import AutoRtaForecastContainer from "./components/AutoRtaForecast/AutoRtaForecastContainer";
import AutoRtaAnalysisContainer from "./components/Analysis/AutoRtaAnalysisContainer";

import GazAutoRtaProvider, { useGazAutoRtaState } from "./hooks/GazAutoRtaContext";
import AutoRtaInputGridContainer from "./components/inputGrid/AutoRtaInputGridContainer";
import AutoRtaDataViewContainer from "./components/DataView/AutoRtaDataViewContainer";

const ModuleGasAutoRTA = () => {
  const { isLoading, project } = useAppStore(
    (state) => ({
      isLoading: state.isLoading,
      project: state.project,
    }),
    shallow
  );

  const [currentTab, setCurrentTab] = useState<number>(0);
  const { dataSet, isLoading: isLoadingManualState } = useGazAutoRtaState();

  // combined loading state:
  const baseLoading = isLoading || isLoadingManualState;

  return (
    <ModulePage
      title={"GAZ: Gas Auto RTA"}
      tabIndex={currentTab}
      onTabChange={setCurrentTab}
      tabs={
        dataSet && [
          {
            headerText: dictionary.dataView.title,
            itemIcon: "ScatterChart",
            disabled: baseLoading,
            content: <AutoRtaDataViewContainer isLoading={baseLoading} currentTab={currentTab} />,
            canSaveAsImg: true,
          },
          {
            headerText: dictionary.inputGrid,
            itemIcon: "NumberField",
            disabled: baseLoading,
            disableHideSidebar: true,
            content: <AutoRtaInputGridContainer currentTab={currentTab} isLoading={baseLoading} />,
            canSaveAsImg: true,
          },
          {
            headerText: dictionary.analysis,
            itemIcon: "LineChart",
            disabled: baseLoading,
            disableHideSidebar: true,
            content: <AutoRtaAnalysisContainer currentTab={currentTab} isLoading={baseLoading} />,
            canSaveAsImg: true,
          },
          {
            headerText: dictionary.forecast,
            itemIcon: "LineChart",
            disabled: baseLoading,
            disableHideSidebar: true,
            content: <AutoRtaForecastContainer currentTab={currentTab} dataSet={dataSet} project={project} isLoading={baseLoading} />,
            canSaveAsImg: true,
          },
        ]
      }
    />
  );
};

const GazAutoRtaWrapper = () => {
  const { selectedDataSets, setApiError } = useAppStore(
    (state) => ({
      selectedDataSets: state.selectedDataSets,
      setApiError: state.setApiError,
    }),
    shallow
  );
  return (
    <GazAutoRtaProvider setApiError={setApiError} selectedDataSets={selectedDataSets}>
      <ModuleGasAutoRTA />
    </GazAutoRtaProvider>
  );
};
export default GazAutoRtaWrapper;
