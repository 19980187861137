import { Auth, Hub } from "aws-amplify";
import React, { useCallback, useEffect, useState } from "react";
import {
  BaseButton,
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  Icon,
  IDialogContentProps,
  IModalProps,
  Link,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  Spinner,
  Stack,
  Text,
  TextField,
  useTheme,
} from "@fluentui/react";
import { shallow } from "zustand/shallow";

import { TenantConfigs } from "./tenants";
// remove this package later replace with regex
import emailAddresses from "email-addresses";
import { ReactComponent as LogoVert } from "./logo-vert.svg";
import NewPasswordField from "./components/NewPasswordField";
import { useAppStore } from "./features/app";

const newPasswordDialogProps = {
  type: DialogType.largeHeader,
  title: "Set new password",
  subText: "A new password is required for this account. Please enter a new password below.",
} as IDialogContentProps;

const resetPasswordDialogProps = {
  type: DialogType.largeHeader,
  title: "Reset password",
  subText: "A confirmation code has been sent to your verified email address. Please enter the code and a new password below.",
} as IDialogContentProps;

const blockingModalProps = {
  isBlocking: true,
} as IModalProps;

function SignIn() {
  const { tenant, setTenant, user, setUser } = useAppStore(
    (state) => ({
      tenant: state.tenant,
      setTenant: state.setTenant,
      user: state.user,
      setUser: state.setUser,
    }),
    shallow
  );

  const [isLoading, setLoading] = useState(false);
  const [username, setUsername] = useState<string>();
  const [password, setPassword] = useState<string>();
  const [emailValid, setEmailValid] = useState(false);
  const [newPassword, setNewPassword] = useState<string>();
  const [forgotPassword, setForgotPassword] = useState(false);
  const [code, setCode] = useState<string>();
  const [error, setError] = useState<any>();
  const [success, setSuccess] = useState<string>();

  const signIn = useCallback(async () => {
    if (!username) return;

    setLoading(true);
    try {
      const user = await Auth.signIn(username, password);
      if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
        setUser(user);
      }
    } catch (e) {
      console.error(e);
    }
  }, [password, setUser, username]);

  const completeNewPasswordChallenge = useCallback(async () => {
    if (!newPassword) return;
    try {
      await Auth.completeNewPassword(
        user, // the Cognito User Object
        newPassword // the new password
      );
    } catch (e) {
      console.error(e);
      setError(e);
    }
  }, [user, newPassword]);

  const sendPasswordResetCode = useCallback(async () => {
    if (!username) return;

    setLoading(true);
    try {
      await Auth.forgotPassword(username);
      setForgotPassword(true);
    } catch (e) {
      console.error(e);
    }
  }, [username]);

  const completePasswordReset = useCallback(async () => {
    if (!username || !code || !newPassword) return;

    try {
      await Auth.forgotPasswordSubmit(username, code, newPassword);
    } catch (e) {
      console.error(e);
    } finally {
      setSuccess("Password reset! Login now.");
      setPassword(newPassword);
      setLoading(false);
      setForgotPassword(false);
    }
  }, [username, code, newPassword]);

  const getUser = useCallback(async () => {
    setLoading(true);
    try {
      return await Auth.currentAuthenticatedUser();
    } catch {
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    Hub.listen("auth", ({ payload: { event, data } }) => {
      switch (event) {
        case "signIn":
        case "cognitoHostedUI":
          getUser().then((userData) => setUser(userData));
          break;
        case "signOut":
          setUser(null);
          break;
        case "signIn_failure":
        case "cognitoHostedUI_failure":
          setError(data);
          setLoading(false);
          break;
      }
    });

    getUser().then((userData) => setUser(userData));
  }, [getUser, setUser]);

  useEffect(() => {
    const emailInfo: any = emailAddresses.parseOneAddress(username || "");
    if (!emailInfo) {
      setEmailValid(false);
      return;
    }
    setEmailValid(true);

    // Find tenant based on email address
    setTenant(Object.keys(TenantConfigs).find((k) => TenantConfigs[k].domain === emailInfo.domain));
  }, [username, setTenant]);

  const theme = useTheme();
  // TODO: Centralize this style
  const itemStyle = {
    boxShadow: theme.effects.elevation4,
    padding: 15,
    borderRadius: theme.effects.roundedCorner4,
  } as React.CSSProperties;

  const allowFederatedSignIn = emailValid && tenant && TenantConfigs[tenant].idpEnabled;
  const allowNormalSignIn = emailValid && username && password;

  const enterToSignin = (event: React.KeyboardEvent<HTMLSpanElement | BaseButton>) => {
    if (event.key === "Enter") {
      return allowNormalSignIn ? signIn() : undefined;
    }
  };

  return (
    <Stack style={{ height: "100vh" }} verticalAlign="center" horizontalAlign="center">
      <Stack style={itemStyle} tokens={{ childrenGap: 20 }}>
        <Stack horizontalAlign="center">
          <LogoVert />

          <Text style={{ fontSize: "40px", fontWeight: 600, color: theme.palette.themePrimary, marginBottom: "8px" }}>predico</Text>

          <Text variant="xLarge" block>
            Advanced Flow Analytics
          </Text>
        </Stack>

        <div>
          <TextField
            id="sign-in-username"
            disabled={isLoading}
            label="Username"
            type="text"
            value={username}
            onChange={(_, newValue) => setUsername(newValue)}
          />

          <TextField
            disabled={Boolean(allowFederatedSignIn) || isLoading}
            label="Password"
            type="password"
            canRevealPassword
            revealPasswordAriaLabel="Show password"
            onRenderPrefix={() => <Icon iconName="PasswordField" />}
            value={password}
            onChange={(event, newValue) => setPassword(newValue)}
            onKeyDown={(event) => enterToSignin(event)}
            id="sign-in-password"
          />
        </div>

        {/* Forgot password button */}
        <Link disabled={!Boolean(emailValid && username) || isLoading} onClick={sendPasswordResetCode}>
          Forgot password?
        </Link>

        {/* Choose correct sign-in button based on tenant */}
        {allowFederatedSignIn ? (
          <PrimaryButton onClick={() => Auth.federatedSignIn({ customProvider: "IDP" })}>Sign In with {TenantConfigs[tenant].name}</PrimaryButton>
        ) : (
          <PrimaryButton id="sign-in-button" disabled={!allowNormalSignIn || isLoading} onClick={() => (allowNormalSignIn ? signIn() : undefined)}>
            Sign In{tenant ? ` to ${TenantConfigs[tenant].name}` : ""}
          </PrimaryButton>
        )}

        {/* Loading spinner */}
        {isLoading ? <Spinner placeholder="Loading..." /> : undefined}

        {/* New password challenge dialog */}

        <Dialog
          hidden={!user || user.challengeName !== "NEW_PASSWORD_REQUIRED"}
          dialogContentProps={newPasswordDialogProps}
          modalProps={blockingModalProps}
        >
          <NewPasswordField onChange={setNewPassword} />

          <DialogFooter>
            <PrimaryButton disabled={!newPassword} onClick={completeNewPasswordChallenge} text="Update" />
            <DefaultButton
              onClick={() => {
                setUser(undefined);
                setLoading(false);
              }}
              text="Cancel"
            />
          </DialogFooter>
        </Dialog>

        {/* Forgot password dialog */}

        <Dialog hidden={!forgotPassword} dialogContentProps={resetPasswordDialogProps} modalProps={blockingModalProps}>
          <TextField label="Code" onRenderPrefix={() => <Icon iconName="Lock" />} value={code} onChange={(event, newValue) => setCode(newValue)} />

          <NewPasswordField onChange={setNewPassword} />

          <DialogFooter>
            <PrimaryButton disabled={!code || !newPassword} onClick={completePasswordReset} text="Update" />
            <DefaultButton
              onClick={() => {
                setForgotPassword(false);
              }}
              text="Cancel"
            />
          </DialogFooter>
        </Dialog>

        {/* Display error messages */}
        {error ? (
          <MessageBar messageBarType={MessageBarType.error} onDismiss={() => setError(null)} dismissButtonAriaLabel="Dismiss">
            {error.message}
          </MessageBar>
        ) : undefined}

        {/* Display success messages */}
        {success ? (
          <MessageBar messageBarType={MessageBarType.success} onDismiss={() => setSuccess(undefined)} dismissButtonAriaLabel="Dismiss">
            {success}
          </MessageBar>
        ) : undefined}
      </Stack>
    </Stack>
  );
}

export default SignIn;
