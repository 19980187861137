import { useMemo } from "react";
import { Column, Row } from "@silevis/reactgrid";

import { getDateFromString } from "@/util";
import { DataViewCalculation } from "@/models/gaz/autoRta";

import { dataViewRawDataHeaderMapping } from "../../constants";

const useGazAutoRtaDataViewTable = (dataViewCalculate: DataViewCalculation) => {
  const rawDataTableCols = [
    { columnId: "dates", width: 150 },
    { columnId: "gas_rate", width: 120, justifyContent: "center" },
    { columnId: "tubing_pressure", width: 150, justifyContent: "center" },
    { columnId: "casing_pressure", width: 150, justifyContent: "center" },
    { columnId: "gas_production", width: 150, justifyContent: "center" },
  ] as Column[];

  const rawDataTableRows = useMemo<Row[]>(() => {
    if (!dataViewCalculate?.raw_data) return [];

    const headerRow: Row = {
      rowId: "header",
      cells: Object.values(dataViewRawDataHeaderMapping).map(({ name }) => ({
        type: "header",
        text: name,
        style: { background: "#fff", display: "flex", justifyContent: "center", fontWeight: "bold", zIndex: 1 },
      })),
    };

    const unitsRow: Row = {
      rowId: "units",
      cells: Object.values(dataViewRawDataHeaderMapping).map(({ unit }) => ({
        type: "header",
        text: unit,
        style: { background: "#fff", display: "flex", justifyContent: "center", zIndex: 1 },
      })),
    };

    const itemMapping =
      dataViewCalculate.raw_data.casing_pressure && dataViewCalculate.raw_data
        ? dataViewCalculate.raw_data.casing_pressure.map((_, j) => ({
            rowId: j,
            cells: Object.values(dataViewRawDataHeaderMapping).map(({ property }) => ({
              type: property === "dates" ? "date" : "number",
              value: property === "dates" ? undefined : dataViewCalculate.raw_data?.[property]?.[j],
              date:
                property === "dates" && dataViewCalculate.raw_data?.[property]?.[j]
                  ? getDateFromString(dataViewCalculate.raw_data?.[property][j])
                  : undefined,
              nonEditable: true,
              hideZero: false,
              style: { display: "flex", justifyContent: "center", zIndex: 0 },
            })),
          }))
        : [];
    return [headerRow, unitsRow, ...itemMapping] as Row[];
  }, [dataViewCalculate?.raw_data]);

  const cleanDataTableCols = [
    { columnId: "dates", width: 150 },
    { columnId: "gas_rate", width: 120, justifyContent: "center" },
    { columnId: "tubing_pressure", width: 150, justifyContent: "center" },
    { columnId: "casing_pressure", width: 150, justifyContent: "center" },
    { columnId: "gas_production", width: 150, justifyContent: "center" },
  ] as Column[];

  const cleanDataTableRows = useMemo<Row[]>(() => {
    if (!dataViewCalculate?.clean_data) return [];

    const headerRow: Row = {
      rowId: "header",
      cells: Object.values(dataViewRawDataHeaderMapping).map(({ name }) => ({
        type: "header",
        text: name,
        style: { background: "#fff", display: "flex", justifyContent: "center", fontWeight: "bold", zIndex: 1 },
      })),
    };

    const unitsRow: Row = {
      rowId: "units",
      cells: Object.values(dataViewRawDataHeaderMapping).map(({ unit }) => ({
        type: "header",
        text: unit,
        style: { background: "#fff", display: "flex", justifyContent: "center", zIndex: 1 },
      })),
    };

    const itemMapping = dataViewCalculate.clean_data.casing_pressure
      ? dataViewCalculate.clean_data.casing_pressure.map((i: number, j) => ({
          rowId: j,
          cells: Object.values(dataViewRawDataHeaderMapping).map(({ property }) => ({
            type: property === "dates" ? "date" : "number",
            value: property === "dates" ? undefined : dataViewCalculate.clean_data?.[property]?.[j],
            date:
              property === "dates" && dataViewCalculate.clean_data?.[property]?.[j]
                ? getDateFromString(dataViewCalculate.clean_data?.[property]?.[j])
                : undefined,
            nonEditable: true,
            hideZero: false,
            style: { display: "flex", justifyContent: "center", zIndex: 0 },
          })),
        }))
      : [];
    return [headerRow, unitsRow, ...itemMapping] as Row[];
  }, [dataViewCalculate?.clean_data]);

  return {
    rawDataTableCols,
    rawDataTableRows,
    cleanDataTableCols,
    cleanDataTableRows,
  };
};
export default useGazAutoRtaDataViewTable;
