import { IDropdownOption } from "@fluentui/react";
import {
  FormationCompressibility,
  GasViscosityMethod,
  GeomechanicsType,
  RockType,
  TankType,
  WaterViscosityMethod,
  ZMethod,
} from "../../../../../../model";

export const TankTypeOptions = [
  { key: TankType.Vertical, text: "Vertical" },
  // { key: TankType.Horizontal, text: "Horizontal" },
] as IDropdownOption[];

export const formationCompressibilityOptions = [
  {
    key: `${FormationCompressibility.NEWMAN}-${RockType.SANDSTONE}`,
    text: "Newman Sandstone",
    data: {
      form_compressibility_method: FormationCompressibility.NEWMAN,
      rock_type: RockType.SANDSTONE,
    },
  },
  {
    key: `${FormationCompressibility.NEWMAN}-${RockType.LIMESTONE}`,
    text: "Newman Limestone",
    data: {
      form_compressibility_method: FormationCompressibility.NEWMAN,
      rock_type: RockType.LIMESTONE,
    },
  },
  {
    key: `${FormationCompressibility.HALL}-${RockType.SANDSTONE}`,
    text: "Hall General",
    data: {
      form_compressibility_method: FormationCompressibility.HALL,
      rock_type: RockType.SANDSTONE,
    },
  },
] as IDropdownOption[];

export const GeomechanicsOptions = [
  { key: GeomechanicsType.Geo_None, text: "None" },
  { key: GeomechanicsType.Geo_Burgoyne, text: "Burgoyne-Shrivastava" },
  { key: GeomechanicsType.Geo_Yilmaz, text: "Yilmaz-Nur" },
  { key: GeomechanicsType.Geo_Palmer, text: "Palmer-Mansoori" },
] as IDropdownOption[];

export const zMethodOptions = [
  { key: ZMethod.DPR, text: "DPR (default)" },
  { key: ZMethod.BEGGS_BRILL, text: "Beggs/Brill" },
  { key: ZMethod.PAPAY, text: "Papay (simple)" },
  { key: ZMethod.DRANCHUCK_ABU_KASSEM, text: "Dranchuck/Abu-Kassem" },
] as IDropdownOption[];

export const wtrViscMethodOptions = [
  { key: WaterViscosityMethod.MCCAIN, text: "McCain" },
  { key: WaterViscosityMethod.LOUISIANA_STATE_UNI, text: "Louisiana State University" },
  { key: WaterViscosityMethod.BRILL_BEGGS, text: "Brill-Beggs" },
] as IDropdownOption[];

export const gasViscMethodOptions = [
  { key: GasViscosityMethod.GONZALES_EAKIN, text: "Gonzales-Eakin" },
  { key: GasViscosityMethod.LONDONO, text: "Londono" },
  { key: GasViscosityMethod.STANDING, text: "Standing" },
] as IDropdownOption[];
