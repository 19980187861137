import React, { useMemo } from "react";
import { IDropdownOption } from "@fluentui/react";
import Tabs from "@/components/Tabs";

import GasPvtContainer from "./GasPvt";
import WaterPvtContainer from "./WaterPvt";
import LangmuirIsothermContainer from "./LangmuirIsotherm";
import OilPvtContainer from "./OilPvt";
import WellboreModelContainer from "./WellboreModel";

import {
  InputGridCalculation,
  LangmuirIsotherm,
  GazAutoRtaInputGrid,
  GasPvtRecombination,
  GasPvt,
  GazAutoRta,
  OilPvt,
  WellboreModel,
  WaterPvt,
} from "@/models/gaz/autoRta";

export interface ModelComponentsParamProps {
  value: GazAutoRtaInputGrid;
  calculateValue: InputGridCalculation;
  isLoading: boolean;
  gasRecombination: boolean | undefined;
  onCheckboxGasRecombination: (v: boolean | undefined) => void;
  onChange: React.Dispatch<React.SetStateAction<GazAutoRta | null>>;
  updateGasPvtInputField: (v: number | undefined, key: keyof GasPvt) => void;
  updateGasRecombinationInput: (v: number | undefined, key: keyof Omit<GasPvtRecombination, "use_gas_recombination">) => void;
  updateWellboreModelInputField: (v: number | undefined, key: keyof WellboreModel) => void;
  updateOilPvtInputField: (v: number | undefined, key: keyof OilPvt) => void;
  updateOilPvtDropdownField: (v: IDropdownOption | undefined, key: keyof OilPvt) => void;
  updateLangmuirInputField: (v: number | undefined, key: keyof LangmuirIsotherm) => void;
  updateWaterPvtSelection: (v: IDropdownOption | undefined, key: keyof WaterPvt) => void;
  wellboreModelCheckBox: boolean | undefined;
  onCheckWellboreModel: (v: boolean | undefined) => void;
  updateGasPvtSelection: (v: IDropdownOption | undefined, key: keyof GasPvt) => void;
}

const ModelComponents = ({
  value,
  gasRecombination,
  wellboreModelCheckBox,
  onCheckWellboreModel,
  onCheckboxGasRecombination,
  updateWaterPvtSelection,
  calculateValue,
  isLoading,
  onChange,
  updateGasPvtInputField,
  updateGasRecombinationInput,
  updateWellboreModelInputField,
  updateOilPvtInputField,
  updateOilPvtDropdownField,
  updateLangmuirInputField,
  updateGasPvtSelection,
}: ModelComponentsParamProps) => {
  const tabList = useMemo(() => {
    return [
      {
        label: <span>Gas PVT</span>,
        key: "Gas PVT",
        content: (
          <GasPvtContainer
            value={value}
            gasRecombination={gasRecombination}
            onCheckboxGasRecombination={onCheckboxGasRecombination}
            calculateValue={calculateValue}
            isLoading={isLoading}
            updateGasPvtInputField={updateGasPvtInputField}
            updateGasRecombinationInput={updateGasRecombinationInput}
            updateGasPvtSelection={updateGasPvtSelection}
          />
        ),
      },
      {
        key: "Water PVT",
        label: <span>Water PVT</span>,
        content: (
          <WaterPvtContainer
            value={value}
            calculateValue={calculateValue}
            isLoading={isLoading}
            onChange={onChange}
            updateWaterPvtSelection={updateWaterPvtSelection}
          />
        ),
      },
      {
        key: "Oil PVT",
        label: <span>Oil PVT</span>,
        content: (
          <OilPvtContainer
            value={value}
            calculateValue={calculateValue}
            isLoading={isLoading}
            updateOilPvtInputField={updateOilPvtInputField}
            updateOilPvtDropdownField={updateOilPvtDropdownField}
          />
        ),
      },
      {
        key: "Langmuir Isotherm",
        label: <span>Langmuir Isotherm</span>,
        content: (
          <LangmuirIsothermContainer
            value={value}
            calculateValue={calculateValue}
            isLoading={isLoading}
            updateLangmuirInputField={updateLangmuirInputField}
          />
        ),
      },
      {
        key: "Wellbore Model",
        label: <span>Wellbore Model</span>,
        content: (
          <WellboreModelContainer
            value={value}
            updateWellboreModelInputField={updateWellboreModelInputField}
            wellboreModelCheckBox={wellboreModelCheckBox}
            onCheckWellboreModel={onCheckWellboreModel}
            isLoading={isLoading}
          />
        ),
      },
    ];
  }, [
    calculateValue,
    gasRecombination,
    isLoading,
    onChange,
    onCheckWellboreModel,
    onCheckboxGasRecombination,
    updateGasPvtInputField,
    updateGasPvtSelection,
    updateGasRecombinationInput,
    updateLangmuirInputField,
    updateOilPvtDropdownField,
    updateOilPvtInputField,
    updateWaterPvtSelection,
    updateWellboreModelInputField,
    value,
    wellboreModelCheckBox,
  ]);

  return <Tabs tabList={tabList} centered />;
};

export default ModelComponents;
