import FossilyticsNumberField from "@/components/fields/FossilyticsNumberField";
import { Dropdown, IDropdownOption, Stack, Text } from "@fluentui/react";

import useThemeStyling from "@/utils/useThemeStyling";
import LoadingIndicator from "@/components/LoadingIndicator";

import { cleaningSmoothingTypeOptions } from "../../constants";
import { DataViewCalculation, GazAutoRtaDataView } from "@/models/gaz/autoRta";

export type DataViewInputSettingProps = {
  dataViewInput?: GazAutoRtaDataView;
  dataViewCalculate?: DataViewCalculation;
  updateDatViewInputField: (v: number | undefined, key: keyof GazAutoRtaDataView) => void;
  updateDatViewStringVal: (v: IDropdownOption | undefined) => void;
  isLoading: boolean;
};

const DataViewInputSetting = ({
  dataViewInput,
  dataViewCalculate,
  updateDatViewInputField,
  updateDatViewStringVal,
  isLoading,
}: DataViewInputSettingProps) => {
  const { elevatedCard, theme } = useThemeStyling();
  return (
    <Stack style={elevatedCard} grow={1}>
      <Text styles={{ root: { color: theme.palette.themePrimary } }}> Options</Text>
      {isLoading && !dataViewInput && !dataViewCalculate ? (
        <div style={{ width: 233 }}>
          <LoadingIndicator />
        </div>
      ) : (
        <>
          <FossilyticsNumberField
            type="float"
            label="Atmospheric correction"
            suffix="psi"
            value={dataViewInput?.atmospheric_correction}
            debounceDelay={1000}
            onChange={(v) => updateDatViewInputField(v, "atmospheric_correction")}
            disabled={isLoading}
          />
          <Dropdown
            label="Smoothing type"
            options={cleaningSmoothingTypeOptions}
            selectedKey={dataViewInput?.smoothing_type}
            onChange={(_, v) => updateDatViewStringVal(v)}
            disabled={isLoading}
          />
          {dataViewInput?.smoothing_type === "interp" ? (
            <FossilyticsNumberField
              label="Smoothing points"
              type="int"
              debounceDelay={1000}
              value={dataViewInput?.smoothing_points}
              onChange={(v) => updateDatViewInputField(v, "smoothing_points")}
              disabled={isLoading}
            />
          ) : (
            <FossilyticsNumberField
              label="Moving average window"
              type="int"
              debounceDelay={1000}
              value={dataViewInput?.moving_avg_window}
              onChange={(v) => updateDatViewInputField(v, "moving_avg_window")}
              disabled={isLoading}
            />
          )}
          <FossilyticsNumberField label="Number of points" type="int" disabled value={dataViewCalculate?.number_raw_data_points} />
        </>
      )}
    </Stack>
  );
};

export default DataViewInputSetting;
