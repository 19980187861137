import { fetchApi } from "@/utils/apiFetcher";
import { calculateSpadDeclineAnalysis } from "@/constants/apiUrl";
import { CalculateSpadDeclineRequestPayload, CalculateSpadDeclineResponse } from "./Analysis";

type CalculateAnalysisPayload = {
  module: string;
} & CalculateSpadDeclineRequestPayload;

export const calculateAnalysis = ({ module, ...rest }: CalculateAnalysisPayload) => {
  return fetchApi<CalculateSpadDeclineResponse, CalculateSpadDeclineRequestPayload>({
    path: calculateSpadDeclineAnalysis(module),
    body: rest,
    type: "post",
  });
};
