import React, { useCallback } from "react";
import { Dropdown, IDropdownOption, Stack } from "@fluentui/react";
import { UncertainParamDistribution, MonteCarloInputApplication, Output } from "@/models/koldun";
import { DistributionProps, applicationOptions } from "./model";
import _ from "lodash";
import { getNewUncertainList } from "../../../utils";
import FixedInput from "@/modules/koldun/mcsim/csg/csg/Components/ModuleKoldunMcsimCsgInputGridTab/Components/UncertainParamInputs/FixedInput";
import LorenzInput from "@/modules/koldun/mcsim/csg/csg/Components/ModuleKoldunMcsimCsgInputGridTab/Components/UncertainParamInputs/LorenzInput";
import InputErrorWrapper from "@/components/Error/InputErrorWrapper/InputErrorWrapper";

const netPayDistOptions = [
  { key: UncertainParamDistribution.FIXED, text: "Fixed" },
  { key: UncertainParamDistribution.LORENZ, text: "Lorenz" },
] as IDropdownOption[];

const NetPayMcs = ({ name, units, onChange, output, measureNum, setOutput, keyField, errorInputValidation }: DistributionProps) => {
  const uncertainKey = "net_pay_ft";
  const value = output.measures[measureNum].measure_layers[0].uncertain_list[uncertainKey];
  const onDistributionChange = useCallback(
    (v: any) => {
      switch (v) {
        case UncertainParamDistribution.LORENZ:
          onChange({
            distribution: UncertainParamDistribution.LORENZ,
            application: MonteCarloInputApplication.MEASURE, // Lorenz don't have multi layer
            net_thickness: 12,
            lorentz_factor: 0.4,
          });
          break;
        case UncertainParamDistribution.FIXED:
          onChange({
            distribution: UncertainParamDistribution.FIXED,
            application: value.application,
            value: 50,
          });
          break;
      }
    },
    [onChange, value.application]
  );

  const handleApplication = useCallback(
    (v: MonteCarloInputApplication) => {
      onChange({
        ...value,
        application: v,
      });
    },
    [onChange, value]
  );

  const handleOutputChange = (layer: number, key: string, value: any) => {
    setOutput((prev: Output | null) => {
      if (!prev) return prev;
      const prevOutput = _.cloneDeep(prev);
      prevOutput.measures[measureNum].measure_layers[layer].uncertain_list = getNewUncertainList(
        prevOutput.measures[measureNum].measure_layers[layer].uncertain_list,
        uncertainKey,
        key,
        value
      );
      return prevOutput;
    });
  };

  return (
    <Stack horizontal tokens={{ childrenGap: 20 }} style={{ marginBottom: "10px" }}>
      <Stack horizontal tokens={{ childrenGap: 20 }} style={{ width: 425 }}>
        <Stack grow={0.5} className="equal-size">
          <InputErrorWrapper errors={errorInputValidation} keyField={keyField}>
            {(validationMsg) => {
              return (
                <Dropdown
                  label={`${name} distribution`}
                  options={netPayDistOptions}
                  selectedKey={value.distribution}
                  errorMessage={validationMsg}
                  onChange={(_, v) => (v !== undefined ? onDistributionChange(v.key) : null)}
                />
              );
            }}
          </InputErrorWrapper>
        </Stack>
        <Stack grow={0.5} className="equal-size">
          <Dropdown
            label="Application"
            options={applicationOptions}
            selectedKey={value.application}
            disabled={value.distribution === UncertainParamDistribution.LORENZ}
            onChange={(_, v) => (v !== undefined ? handleApplication(v.key as MonteCarloInputApplication) : null)}
          />
        </Stack>
      </Stack>

      {value.distribution === UncertainParamDistribution.LORENZ && (
        <LorenzInput
          measureNum={measureNum}
          output={output}
          units={units}
          updateUncertainList={handleOutputChange}
          uncertainKey={uncertainKey}
          keyField={keyField}
          errorInputValidation={errorInputValidation}
        />
      )}

      {value.distribution === UncertainParamDistribution.FIXED && (
        <FixedInput
          measureNum={measureNum}
          output={output}
          units={units}
          updateUncertainList={handleOutputChange}
          uncertainKey={uncertainKey}
          keyField={keyField}
          errorInputValidation={errorInputValidation}
        />
      )}
    </Stack>
  );
};

export default NetPayMcs;
