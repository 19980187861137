import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Button } from '@mui/material';

interface CSGDialogProps {
  isDialogOpen: boolean;
  handleContinueToForecast: () => void;
  handleCancelBackToInput: () => void;
}

const CSGDialog = ({isDialogOpen, handleContinueToForecast, handleCancelBackToInput}:CSGDialogProps ) => {

  return (
    <Dialog
        open={isDialogOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"The Forecast "}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This forecast may run up to an hour, please let it complete.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <p>Do you wish to continue?</p>
          <Button onClick={handleContinueToForecast}>Yes</Button>
          <Button onClick={handleCancelBackToInput} autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
  );
};

export default CSGDialog;
