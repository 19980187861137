const sessionTimeStampKey = "sessionTimeStamp";

export const safeTimeStampToLS = () => {
  localStorage.setItem(sessionTimeStampKey, String(new Date().getTime()));
};

export const getTimeStampFromLS = () => {
  const item = localStorage.getItem(sessionTimeStampKey);

  if (Number.isNaN(parseInt(item ?? ""))) return null;
  return String(item);
};

// limit is minute
export const isPastLimit = (savedDateTime: Date, limit: number): boolean => {
  const thirtyMinutesInMilliseconds = limit * 60 * 1000; // limit minutes in milliseconds;
  const currentDateTime = new Date();

  return currentDateTime.getTime() - savedDateTime.getTime() > thirtyMinutesInMilliseconds;
};

export const clearSessionFromLS = () => {
  localStorage.clear();
};
