import { useCallback, useMemo } from "react";
import { CellChange, Row } from "@silevis/reactgrid";

import dictionary from "@/constants/dictionary";
import { CaseResult, ParameterCase, SpadDeclineState } from "@/models/spad/decline";

import { camelToSnakeCase, formatToScientific } from "@/utils/general";
import { tableCellStyle, tableCellStyleDisabled, tableHeaderNotationStyle, tableHeaderStyle } from "@/components/CustomTable";

export type UseSpadDeclineParametersProps = {
  disabled: boolean;
  type: "operational_forecast" | "profile_forecast";
  forecast?: CaseResult<ParameterCase> | null;
  setSpadDeclineState: React.Dispatch<React.SetStateAction<SpadDeclineState | undefined>>;
};

const caseKey = ["low", "mid", "high"];
const useSpadDeclineParameters = ({ forecast, setSpadDeclineState, type, disabled }: UseSpadDeclineParametersProps) => {
  const columns = useMemo(() => {
    return [
      ...Object.keys(dictionary.spadDeclineParams).map((columnId, index) => {
        return { columnId: columnId, width: index === 0 ? 50 : 80 };
      }),
    ];
  }, []);

  const rows: Row<any>[] = useMemo(() => {
    const headerNotation = [
      {
        rowId: "header",
        cells: [
          ...columns.map((column) => {
            return {
              type: "custom",
              text: dictionary.spadDeclineParams[column.columnId].label ?? "",
              style: tableHeaderStyle,
              sub: dictionary.spadDeclineParams[column.columnId].sub ?? "",
            };
          }),
        ],
        height: 50,
      },
      {
        rowId: "notation",
        cells: [
          ...columns.map((column) => {
            return {
              type: "header",
              text: dictionary.spadDeclineParams[column.columnId].notation ?? "",
              style: tableHeaderNotationStyle,
              height: 30,
            };
          }),
        ],
      },
    ];
    if (!forecast) return headerNotation;

    const caseTable = caseKey.map((key: string) => {
      return {
        rowId: key,
        height: 30,
        cells: [
          {
            type: "text",
            text: dictionary.spadDecline[key],
            nonEditable: true,
            style: disabled ? tableCellStyleDisabled : tableCellStyle,
          },
          ...Object.keys(dictionary.spadDeclineParams)
            .slice(1)
            .map((paramKey: string) => {
              const val = forecast[key as keyof CaseResult<ParameterCase>][camelToSnakeCase(paramKey) as keyof ParameterCase];
              return {
                type: "text",
                text: formatToScientific(val),
                style: disabled ? tableCellStyleDisabled : tableCellStyle,
                nonEditable: disabled,
              };
            }),
        ],
      };
    });

    return [...headerNotation, ...caseTable];
  }, [columns, disabled, forecast]);

  const onCellsChanged = useCallback(
    (changes: CellChange[]) => {
      if (!forecast) return;
      const updatedForecast: any = { ...forecast };

      for (const element of changes) {
        const change = element;
        let { rowId, columnId, newCell } = change as CellChange<any>;

        rowId = rowId as keyof CaseResult<ParameterCase>; //
        columnId = columnId as keyof ParameterCase;

        updatedForecast[rowId][camelToSnakeCase(columnId)] = newCell.text ?? "0";
      }

      setSpadDeclineState((prev) => {
        if (!prev) return prev;
        return {
          ...prev,
          analysis_option: {
            ...prev.analysis_option,
            [type]: updatedForecast,
          },
        };
      });
    },
    [forecast, setSpadDeclineState, type]
  );
  return { rows, columns, onCellsChanged };
};

export default useSpadDeclineParameters;
