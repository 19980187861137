import { Stack } from "@fluentui/react";
import { MonteCarloInputApplication, MonteCarloInputProps, UncertainParamFixed } from "@/models/koldun";
import FossilyticsNumberField from "@/components/fields/FossilyticsNumberField";

import { generateKeyFieldForLayer } from "../../../../utils";

const FixedInput = ({ units, output, updateUncertainList, measureNum, uncertainKey, keyField, errorInputValidation }: MonteCarloInputProps) => {
  const application = output.measures[measureNum].measure_layers[0].uncertain_list[uncertainKey].application;

  const renderLayerFields = () => {
    return output.measures[measureNum].measure_layers.map((measureLayer, index) => (
      <Stack
        key={`layer-field-${measureLayer.layer_num}`}
        enableScopedSelectors
        horizontal
        disableShrink
        tokens={{ childrenGap: 20 }}
        style={{ width: 280 }}
      >
        {index === 0 ? (
          <span style={{ width: 50, fontWeight: 600, marginTop: 35 }}>Layer {index + 1} </span>
        ) : (
          <span style={{ width: 50, fontWeight: 600, marginTop: 10 }}>Layer {index + 1} </span>
        )}
        <Stack grow={1} className="equal-size">
          <FossilyticsNumberField
            key={measureLayer.layer_num}
            label={index === 0 ? "Fixed" : ""}
            suffix={units}
            debounceDelay={1000}
            value={(measureLayer.uncertain_list[uncertainKey] as UncertainParamFixed).value}
            onChange={(v) => updateUncertainList(index, "value", v)}
            errors={errorInputValidation}
            keyField={generateKeyFieldForLayer(index, keyField)}
          />
        </Stack>
      </Stack>
    ));
  };

  const renderMeasureField = () => (
    <Stack enableScopedSelectors tokens={{ childrenGap: 20 }} style={{ width: 220 }}>
      <FossilyticsNumberField
        label={`Fixed`}
        suffix={units}
        debounceDelay={1000}
        value={(output.measures[measureNum].measure_layers[0].uncertain_list[uncertainKey] as UncertainParamFixed).value}
        onChange={(v) => updateUncertainList(0, "value", v)}
        errors={errorInputValidation}
        keyField={keyField}
      />
    </Stack>
  );

  return (
    <Stack horizontal tokens={{ childrenGap: 20 }}>
      <Stack grow={0.5} tokens={{ childrenGap: 10 }} className="equal-size">
        {application === MonteCarloInputApplication.LAYER ? renderLayerFields() : renderMeasureField()}
      </Stack>
    </Stack>
  );
};

export default FixedInput;
