import { TahkCsgInputLayerCharts, TahkCsgInputState, TahkCsgStateResponse, TahkSelectedFlowPressureTypeEnum } from "@/models/tahk";
import { useCallback } from "react";
import dictionary from "@/constants/dictionary";

export type TahkCsgWellboreInputProps = {
  inputs?: TahkCsgInputState;
  setTahkCsgState: React.Dispatch<React.SetStateAction<TahkCsgStateResponse | null | undefined>>;
  loadingState: boolean;
  layers: TahkCsgInputLayerCharts[];
};

const useTahkCsgWellboreInput = ({ setTahkCsgState }: TahkCsgWellboreInputProps) => {
  const onChangeWellboreInput = useCallback(
    (value: any, key: string) => {
      setTahkCsgState((prev) => {
        if (!prev) return prev;
        const wellBoreInput = {
          ...prev.inputs.wellbore_inputs,
          [key]: value,
        };
        if (key === "selected_flow_pressure_type") {
          const isTubingHead = value === TahkSelectedFlowPressureTypeEnum.TubingHeadPressure;
          if (isTubingHead) {
            wellBoreInput.parameters = {
              minimum_pump_head: 1,
              pump_depth: 1,
              pump_draw_down_rate: 1,
            };
          } else {
            wellBoreInput.parameters = {};
          }
        }

        if (key === "number_of_days" && prev.inputs?.flowing_pressure_table) {
          const newFlowingPressure = [...prev.inputs.flowing_pressure_table];
          const safeNum = Number(value ?? 0);
          if (newFlowingPressure.length > safeNum) {
            // Ensure we don't lose inputted data
            const lastValidIndex = newFlowingPressure.reduce((lastValidIndex, row, i) => {
              if (Object.values(row).some((v) => Number(v) > 0)) return i;
              return lastValidIndex;
            }, 0);
            newFlowingPressure.splice(Math.max(safeNum, lastValidIndex + 1));
          }
        }
        return {
          ...prev,
          inputs: {
            ...prev.inputs,
            wellbore_inputs: wellBoreInput,
          },
        };
      });
    },
    [setTahkCsgState]
  );
  const onChangeWellboreInputParameter = useCallback(
    (value: any, key: string) => {
      setTahkCsgState((prev) => {
        if (!prev) return prev;

        const safeParam = prev.inputs.wellbore_inputs?.parameters ?? {};
        return {
          ...prev,
          inputs: {
            ...prev.inputs,
            wellbore_inputs: {
              ...prev.inputs.wellbore_inputs,
              parameters: {
                ...safeParam,
                [key]: value,
              },
            },
          },
        };
      });
    },
    [setTahkCsgState]
  );

  const flowPressureTypeOptions = [
    {
      key: TahkSelectedFlowPressureTypeEnum.BottomHolePressure,
      text: dictionary.tahk[TahkSelectedFlowPressureTypeEnum.BottomHolePressure],
    },
    {
      key: TahkSelectedFlowPressureTypeEnum.TubingHeadPressure,
      text: dictionary.tahk[TahkSelectedFlowPressureTypeEnum.TubingHeadPressure],
    },
  ];

  return {
    onChangeWellboreInput,
    flowPressureTypeOptions,
    onChangeWellboreInputParameter,
  };
};

export default useTahkCsgWellboreInput;
