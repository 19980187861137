import React, { useState } from "react";
import { ChoiceGroupOption, IChoiceGroupOptionProps, Label, Stack } from "@fluentui/react";
import { worAnalyses } from "./model";

interface ModuleSpadWorAnalysesSelectProps {
  selectedAnalyses: string[];
  onAnalysesUpdate: (analyses: string[]) => void;
}

function ModuleSpadWorAnalysesSelect({ selectedAnalyses, onAnalysesUpdate }: ModuleSpadWorAnalysesSelectProps) {
  const [keyFocused, setKeyFocused] = useState<string>();

  const onFocus = React.useCallback((ev?: React.FocusEvent<HTMLElement>, option?: IChoiceGroupOptionProps) => {
    if (option) {
      setKeyFocused(option.itemKey);
      option.onFocus?.(ev);
    }
  }, []);

  const onBlur = React.useCallback((ev: any, option?: IChoiceGroupOptionProps) => {
    setKeyFocused(undefined);
    option?.onBlur?.(ev);
  }, []);

  const onOptionChange = React.useCallback(
    (optionKey: string) => {
      const i = selectedAnalyses.indexOf(optionKey);
      let value;
      if (i >= 0) {
        value = [...selectedAnalyses.slice(0, i), ...selectedAnalyses.slice(i + 1)];
      } else {
        value = [...selectedAnalyses, optionKey].sort((a, b) => worAnalyses.indexOf(a) - worAnalyses.indexOf(b));
      }
      onAnalysesUpdate(value);
    },
    [onAnalysesUpdate, selectedAnalyses]
  );

  // TODO: Research what these do
  const id = "test";
  const labelId = "test";
  const name = "test";

  return (
    // TODO: What does the role need to be? https://github.com/microsoft/fluentui/blob/905e46db07ada986fd7885531f72a71b7bbaabdc/packages/react/src/components/ChoiceGroup/ChoiceGroup.base.tsx
    <div>
      <Label>Analyses</Label>
      <Stack horizontal>
        {worAnalyses.map((analysis) => {
          return (
            <ChoiceGroupOption
              key={analysis}
              itemKey={analysis}
              text={analysis}
              iconProps={{ iconName: "LineChart" }}
              onBlur={onBlur}
              onFocus={onFocus}
              onClick={() => onOptionChange(analysis)}
              focused={analysis === keyFocused}
              checked={selectedAnalyses ? selectedAnalyses.indexOf(analysis) >= 0 : false}
              disabled={false}
              required={false}
              id={`${id}-${analysis}`}
              labelId={`${labelId}-${analysis}`}
              name={name}
            />
          );
        })}
      </Stack>
    </div>
  );
}

export default ModuleSpadWorAnalysesSelect;
