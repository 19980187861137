import { Dropdown, Stack } from "@fluentui/react";

import { ModuleKoldunMcsimGasOilOptions } from "@/modules/koldun/mcsim/gasoil/ModuleKoldunMcsimGasOilContent/model";
import FossilyticsNumberField from "@/components/fields/FossilyticsNumberField";
import { TankType } from "@/model";

import { TankTypeOptions, formationCompressibilityOptions, gasViscMethodOptions, wtrViscMethodOptions, zMethodOptions } from "./model";

const fossilyticsFields = [
  {
    label: "Gas gravity",
    suffix: "dim",
    valueKey: "gas_gravity",
  },
  {
    label: "N₂",
    suffix: "%",
    min: 0,
    max: 100,
    valueKey: "n2_perc",
  },
  {
    label: "CO₂",
    suffix: "%",
    min: 0,
    max: 100,
    valueKey: "co2_perc",
  },
  {
    label: "H₂S",
    suffix: "%",
    min: 0,
    max: 100,
    valueKey: "h2s_perc",
  },
];

const dropdownFields = [
  {
    label: "Z Method",
    options: zMethodOptions,
    valueKey: "z_method",
  },
  {
    label: "Water viscosity method",
    options: wtrViscMethodOptions,
    valueKey: "water_viscosity_method",
  },
  {
    label: "Gas viscosity method",
    options: gasViscMethodOptions,
    valueKey: "gas_viscosity_method",
  },
];

interface BaseInputsProps {
  value: ModuleKoldunMcsimGasOilOptions;
  onChange: React.Dispatch<React.SetStateAction<ModuleKoldunMcsimGasOilOptions>>;
  module?: string;
}

const BaseInputs = ({ value, onChange, module }: BaseInputsProps) => {
  const getFieldValue = (obj: any, field: any) => {
    if (field.additionalKeys) {
      return `${obj[field.valueKey]}-${obj[field.additionalKeys[0]]}`;
    }
    return obj[field.valueKey];
  };

  const getUpdatedFieldValues = (prev: any, field: any, v: any) => {
    const updatedValues = {
      [field.valueKey]: v.key,
    };

    if (field.additionalKeys) {
      field.additionalKeys.forEach((key: any) => {
        updatedValues[key] = v.data[key];
      });
    }

    return updatedValues;
  };

  return (
    <Stack horizontal tokens={{ childrenGap: 20 }}>
      <Stack grow={1} className="equal-size" tokens={{ childrenGap: 5 }}>
        <Dropdown
          ariaLabel="koldun_well_type"
          label="Well type"
          options={TankTypeOptions}
          selectedKey={value.tank_type}
          onChange={(_, v) =>
            v !== undefined
              ? onChange({
                  ...value,
                  tank_type: v.key as TankType,
                })
              : null
          }
        />
        {module === "koldun_gas" ? null : (
          <FossilyticsNumberField
            label="Area"
            suffix="acre"
            value={value.area_acres}
            onChange={(v) =>
              v !== undefined
                ? onChange({
                    ...value,
                    area_acres: v,
                  })
                : null
            }
          />
        )}
        <FossilyticsNumberField
          label="Wellbore radius"
          suffix="ft"
          value={value.wellbore_radius_ft}
          onChange={(v) =>
            v !== undefined
              ? onChange({
                  ...value,
                  wellbore_radius_ft: v,
                })
              : null
          }
        />
      </Stack>

      <Stack grow={1} className="equal-size" tokens={{ childrenGap: 5 }}>
        {fossilyticsFields.map((field) => (
          <FossilyticsNumberField
            key={field.valueKey}
            label={field.label}
            suffix={field.suffix}
            min={field.min}
            max={field.max}
            value={value[field.valueKey as keyof ModuleKoldunMcsimGasOilOptions] as number}
            onChange={(v) =>
              v !== undefined
                ? onChange({
                    ...value,
                    [field.valueKey]: v,
                  })
                : null
            }
          />
        ))}
      </Stack>

      <Stack grow={1} className="equal-size" tokens={{ childrenGap: 5 }}>
        <Dropdown
          ariaLabel="koldun_formation_compressibility_method"
          label="Formation compressibility method"
          options={formationCompressibilityOptions}
          selectedKey={`${value.form_compressibility_method}-${value.rock_type}`}
          onChange={(_, v) =>
            v !== undefined
              ? onChange({
                  ...value,
                  ...v.data,
                })
              : null
          }
        />

        {dropdownFields.map((field) => (
          <Dropdown
            ariaLabel={`koldun_${field.valueKey}`}
            key={field.valueKey}
            label={field.label}
            options={field.options}
            selectedKey={getFieldValue(value, field)}
            onChange={(_, v) =>
              v !== undefined
                ? onChange({
                    ...value,
                    ...getUpdatedFieldValues(value, field, v),
                  })
                : null
            }
          />
        ))}
        <FossilyticsNumberField
          label="Formation temperature"
          suffix="°F"
          value={value.form_temp_of}
          onChange={(v) =>
            v !== undefined
              ? onChange({
                  ...value,
                  form_temp_of: v,
                })
              : null
          }
        />

        <FossilyticsNumberField
          label="Water Salinity "
          suffix="PPM"
          value={value.salinity_ppm}
          onChange={(v) =>
            v !== undefined
              ? onChange({
                  ...value,
                  salinity_ppm: v,
                })
              : null
          }
        />
      </Stack>
    </Stack>
  );
};

export default BaseInputs;
