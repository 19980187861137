import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import { formatToScientific } from "@/utils/general";

type SummaryTableProps = {
  headers: {
    key: string;
    label: string;
  }[];
  rows: {
    [key: string]: string | number;
  }[];
};

const SummaryTable = ({ headers, rows }: SummaryTableProps) => {
  if (headers.length === 0) return <></>;

  return (
    <TableContainer
      style={{
        marginTop: 10,
      }}
    >
      <Table stickyHeader size="small">
        <TableHead>
          <TableRow sx={{ th: { fontWeight: 600 } }}>
            {headers.map((head, index) => {
              return (
                <TableCell align={index === 0 ? "left" : "center"} key={head.key}>
                  {head.label}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => {
            return (
              <TableRow key={index} sx={{ th: { border: 0 } }}>
                {headers.map((header, index) => {
                  const val = row[header.key];
                  const pattern = /\^(\w)/;
                  const replacement = "<sup>$1</sup>";
                  const result = String(val).replace(pattern, replacement);

                  return (
                    <TableCell key={val} align={index === 0 ? "left" : "center"} component="th" scope="row">
                      {isNaN(Number(val)) || !String(val).trim() ? (
                        // convert string to html
                        <div dangerouslySetInnerHTML={{ __html: result }} />
                      ) : (
                        formatToScientific(row[header.key])
                      )}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default SummaryTable;
