import { ModuleId } from "@/model";
import { HelpLinks } from "./help-link.types";

export const WIKI_BASE_URL = "https://predico.wikipage.io/";

export const MODULE_SPECIFIC_LINKS: ModuleId[] = [ModuleId.KOLDUN_MCSIM_CSG];

export const OPERATIONAL_HELP_LINKS: HelpLinks = {
  index: {
    name: "Operational Manual",
    code: "17760416",
  },
  [ModuleId.KOLDUN_MCSIM_CSG]: {
    name: "KOLDUN: CSG Monte-Carlo",
    code: "54493212",
  },
};

export const THEORETICAL_HELP_LINKS: HelpLinks = {
  index: {
    name: "Theory & Reference Manual",
    code: "17694897",
  },
  pssModels: {
    name: "PSS Models",
    code: "18710617",
  },
  gasDeviationFactor: {
    name: "Gas Deviation Factor",
    code: "17596429",
  },
  gasViscosity: {
    name: "Gas Viscosity",
    code: "17301538",
  },
  gasPvtCorrelations: {
    name: "Gas PVT Correlations",
    code: "17465359",
  },
  csgWellSpacingAndCompletions: {
    name: "CSG Well Spacing & Completions",
    code: "17727581",
  },
  relativePermeability: {
    name: "Relative Permeability",
    code: "17924097",
  },
  isotherm: {
    name: "Isotherm",
    code: "15335425",
  },
  coalDensity: {
    name: "Coal Density",
    code: "23527464",
  },
  cleatPorosity: {
    name: "Cleat Porosity",
    code: "20545648",
  },
  wellboreSkinAndFormationDamage: {
    name: "Wellbore Skin and Formation Damage",
    code: "18481153",
  },
};
