import { useCallback, useMemo, useState } from "react";
import { Pivot, PivotItem, Stack, useTheme } from "@fluentui/react";

import FossilyticsChart, { FossilyticsChartAxis, FossilyticsChartSeries, FossilyticsChartToggle } from "../../components/FossilyticsChart";
import { ModuleId } from "../../model";
import { ModuleRollupPlotResponse } from "./model";
import { useAppStore } from "@/features/app";

interface ModuleRollupPlotsProps {
  moduleId?: ModuleId;
  plotInitData?: ModuleRollupPlotResponse;
  plotLoading: boolean;
}

const ModuleRollupPlots = ({ moduleId, plotInitData, plotLoading }: ModuleRollupPlotsProps) => {
  const group = useAppStore((state) => state.group);
  const subChar = useMemo(() => (moduleId === ModuleId.SPAD_DECLINE_GAS ? "g" : "o"), [moduleId]);

  const [xAxesState, setXAxesState] = useState(false);
  const theme = useTheme();
  const xAxes = useMemo<FossilyticsChartAxis[]>(() => {
    let name = "";
    let type = "";
    if (xAxesState === false) {
      name = "Days";
      type = "value";
    } else {
      name = "Dates";
      type = "time";
    }

    return [{ name: name, type: type, color: theme.palette.black }];
  }, [theme.palette.black, xAxesState]);

  const cumeRateYAxes = useMemo<FossilyticsChartAxis[]>(
    () => [{ name: "Cume Rates", type: "value", color: theme.palette.black }],
    [theme.palette.black]
  );

  const rollupSwitchToggle = {
    showToggle: true,
    title: "Switch Date/Days",
  } as FossilyticsChartToggle;

  const yAxes = useMemo<FossilyticsChartAxis[]>(() => [{ name: "Rates (bb/d)", type: "value", color: theme.palette.black }], [theme.palette.black]);

  const logYAxes = useMemo<FossilyticsChartAxis[]>(() => [{ name: "Rates (bb/d)", type: "log", color: theme.palette.black }], [theme.palette.black]);

  const profileQSeries = useMemo<FossilyticsChartSeries[]>(() => {
    return [
      {
        name: "Profile Low Case",
        type: "line",
        lineWidth: 2,
        hideSymbol: true,
        color: theme.palette.orangeLight,
        data: plotInitData
          ? xAxesState === false
            ? plotInitData.arps_plot.low.days.map((d, i) => [d, plotInitData.arps_plot.low.rates[i]])
            : plotInitData.arps_plot.low.dates.map((d, i) => [d, plotInitData.arps_plot.low.rates[i]])
          : [],
      },
      {
        name: "Profile Mid Case",
        type: "line",
        lineWidth: 2,
        hideSymbol: true,
        color: theme.palette.blueDark,
        data: plotInitData
          ? xAxesState === false
            ? plotInitData.arps_plot.mid.days.map((d, i) => [d, plotInitData.arps_plot.mid.rates[i]])
            : plotInitData.arps_plot.mid.dates.map((d, i) => [d, plotInitData.arps_plot.mid.rates[i]])
          : [],
      },
      {
        name: "Profile High Case",
        type: "line",
        lineWidth: 2,
        hideSymbol: true,
        color: theme.palette.orangeLighter,
        data: plotInitData
          ? xAxesState === false
            ? plotInitData.arps_plot.high.days.map((d, i) => [d, plotInitData.arps_plot.high.rates[i]])
            : plotInitData.arps_plot.high.dates.map((d, i) => [d, plotInitData.arps_plot.high.rates[i]])
          : [],
      },
    ];
  }, [plotInitData, theme, xAxesState]);

  const profileLogSeries = useMemo<FossilyticsChartSeries[]>(() => {
    return [
      {
        name: "Profile Low Case",
        type: "line",
        lineWidth: 2,
        hideSymbol: true,
        color: theme.palette.orangeLight,
        data: plotInitData
          ? xAxesState === false
            ? plotInitData.arps_plot.low.days.map((d, i) => [d, plotInitData.arps_plot.low.rates[i]])
            : plotInitData.arps_plot.low.dates.map((d, i) => [d, plotInitData.arps_plot.low.rates[i]])
          : [],
      },
      {
        name: "Profile Mid Case",
        type: "line",
        lineWidth: 2,
        hideSymbol: true,
        color: theme.palette.blueDark,
        data: plotInitData
          ? xAxesState === false
            ? plotInitData.arps_plot.mid.days.map((d, i) => [d, plotInitData.arps_plot.mid.rates[i]])
            : plotInitData.arps_plot.mid.dates.map((d, i) => [d, plotInitData.arps_plot.mid.rates[i]])
          : [],
      },
      {
        name: "Profile High Case",
        type: "line",
        lineWidth: 2,
        hideSymbol: true,
        color: theme.palette.orangeLighter,
        data: plotInitData
          ? xAxesState === false
            ? plotInitData.arps_plot.high.days.map((d, i) => [d, plotInitData.arps_plot.high.rates[i]])
            : plotInitData.arps_plot.high.dates.map((d, i) => [d, plotInitData.arps_plot.high.rates[i]])
          : [],
      },
    ];
  }, [plotInitData, theme, xAxesState]);

  const profiledateQSeries = useMemo<FossilyticsChartSeries[]>(() => {
    return [
      {
        name: "Profile Low Case",
        type: "line",
        lineWidth: 2,
        hideSymbol: true,
        color: theme.palette.orangeLight,
        data: plotInitData
          ? xAxesState === false
            ? plotInitData.arps_plot.low.days.map((d, i) => [d, plotInitData.arps_plot.low.cumRates[i]])
            : plotInitData.arps_plot.low.dates.map((d, i) => [d, plotInitData.arps_plot.low.cumRates[i]])
          : [],
      },
      {
        name: "Profile Mid Case",
        type: "line",
        lineWidth: 2,
        hideSymbol: true,
        color: theme.palette.blueDark,
        data: plotInitData
          ? xAxesState === false
            ? plotInitData.arps_plot.mid.days.map((d, i) => [d, plotInitData.arps_plot.low.cumRates[i]])
            : plotInitData.arps_plot.mid.dates.map((d, i) => [d, plotInitData.arps_plot.low.cumRates[i]])
          : [],
      },
      {
        name: "Profile High Case",
        type: "line",
        lineWidth: 2,
        hideSymbol: true,
        color: theme.palette.orangeLighter,
        data: plotInitData
          ? xAxesState === false
            ? plotInitData.arps_plot.high.days.map((d, i) => [d, plotInitData.arps_plot.low.cumRates[i]])
            : plotInitData.arps_plot.high.dates.map((d, i) => [d, plotInitData.arps_plot.low.cumRates[i]])
          : [],
      },
    ];
  }, [plotInitData, theme, xAxesState]);

  const twoLineXAxes = useMemo<FossilyticsChartAxis[]>(
    () => [{ name: "Np/2P", type: "value", color: theme.palette.black, min: 0, max: 1, position: "top" }],
    [theme.palette.black]
  );

  const twoLineYAxes = useMemo<FossilyticsChartAxis[]>(
    () => [{ name: "(3P-1P)/2P", type: "value", color: theme.palette.black, min: 0, max: 1.4, inverse: true }],
    [theme.palette.black]
  );
  const twoLineSeries = useMemo<FossilyticsChartSeries[]>(() => {
    return [
      {
        name: "Slope 2",
        type: "line",
        lineWidth: 2,
        hideSymbol: true,
        color: theme.palette.orangeLight,
        data: plotInitData ? plotInitData.rose_plot.linesXArr.map((x, i) => [x, plotInitData.rose_plot.lowerLine[i]]) : [],
      },
      {
        name: "Slope 1",
        type: "line",
        lineWidth: 2,
        hideSymbol: true,
        color: theme.palette.blueDark,
        data: plotInitData ? plotInitData.rose_plot.linesXArr.map((x, i) => [x, plotInitData.rose_plot.upperLine[i]]) : [],
      },
      {
        name: "Wells",
        type: "scatter",
        lineWidth: 2,
        color: theme.palette.orangeLighter,
        data: plotInitData ? plotInitData.rose_plot.xArr.map((x, i) => [x, plotInitData.rose_plot.yArr[i], plotInitData.rose_plot.idArr[i]]) : [],
      },
    ];
  }, [plotInitData, theme]);

  const handleXAxesChange = useCallback(() => {
    setXAxesState((prevXAxesState) => !prevXAxesState);
  }, []);

  return (
    <Stack horizontal className="PivotWrapper" grow={1}>
      <Stack.Item>
        <Pivot styles={{ itemContainer: { flex: "1", height: 593 } }}>
          <PivotItem
            headerButtonProps={{
              onRenderChildren: () => (
                <span>
                  q<sub>{subChar}</sub> vs t
                </span>
              ),
            }}
          >
            <FossilyticsChart
              id={`${moduleId} Rollup`}
              isLoading={plotLoading}
              title={`${group?.name} Rollup`}
              xAxes={xAxes}
              yAxes={yAxes}
              series={profileQSeries}
              switchToggle={rollupSwitchToggle}
              handleXAxesChange={handleXAxesChange}
            />
          </PivotItem>
          <PivotItem
            headerButtonProps={{
              onRenderChildren: () => (
                <span>
                  log(q<sub>{subChar}</sub>) vs t
                </span>
              ),
            }}
          >
            <FossilyticsChart
              id={`${moduleId} Rollup`}
              isLoading={plotLoading}
              title={`${group?.name} Rollup`}
              xAxes={xAxes}
              yAxes={logYAxes}
              series={profileLogSeries}
              switchToggle={rollupSwitchToggle}
              handleXAxesChange={handleXAxesChange}
            />
          </PivotItem>
          <PivotItem
            headerButtonProps={{
              onRenderChildren: () => (
                <span>
                  Q<sub>{subChar}</sub> vs t
                </span>
              ),
            }}
          >
            <FossilyticsChart
              id={`${moduleId} Rollup`}
              isLoading={plotLoading}
              title={`${group?.name} Rollup`}
              xAxes={xAxes}
              yAxes={cumeRateYAxes}
              series={profiledateQSeries}
              switchToggle={rollupSwitchToggle}
              handleXAxesChange={handleXAxesChange}
            />
          </PivotItem>
        </Pivot>
      </Stack.Item>
      <Stack.Item style={{ marginTop: "38px", maxHeight: 598 }}>
        <FossilyticsChart
          id={`${group?.name} Rollup`}
          isLoading={plotLoading}
          title={"Rose Plot"}
          xAxes={twoLineXAxes}
          yAxes={twoLineYAxes}
          series={twoLineSeries}
        />
      </Stack.Item>
    </Stack>
  );
};

export default ModuleRollupPlots;
