import { useEffect, useMemo } from "react";
import Tabs from "@/components/Tabs";
import { useTheme } from "@mui/material/styles";

import { ModuleId } from "@/model";
import FossilyticsChart from "@/components/FossilyticsChart";

import { useSpadDeclineState } from "../../hooks/SpadDeclineContext";
import useLogRate from "../../hooks/charts/useLogRate";
import useSpadDeclineCharts from "../../hooks/charts/useSpadDeclineCharts";
import useLogRateProd from "../../hooks/charts/useLogRateProd";
import useCartesianRateDay from "../../hooks/charts/useCartesianRateDay";
import useCartesianRateProd from "../../hooks/charts/useCartesianRateProd";
import useCartesianProdDay from "../../hooks/charts/useCartesianProdDay";
import dictionary from "@/constants/dictionary";
import ReactGridTable from "@/components/ReactGridTable/ReactGridTable";
import useAnalysisSumary from "../../hooks/charts/useAnalysisSumary";
import { SpadDeclineState } from "@/models/spad/decline";
import { ProjectDataSet } from "@/util";

type SpadDeclineAnalysisChartProps = {
  compareAnalysis?: SpadDeclineState;
  compareName?: string;
  compareDataSet?: ProjectDataSet;
};
const SpadDeclineAnalysisChart = ({ compareAnalysis, compareName, compareDataSet }: SpadDeclineAnalysisChartProps) => {
  const { palette } = useTheme();
  const { project, onChartDrag, setCsvData, setSpadDeclineState, currentModule, isLoading, spadDeclineState, dataSets } = useSpadDeclineState();

  const subChar = currentModule === ModuleId.SPAD_DECLINE_GAS ? "g" : "o";

  const {
    getScatterItemColor,
    logFlowSeries,
    unit,
    draggable,
    updateLineFromChart,
    rateCumProdHandleBar,
    rateTimeHandleBar,
    ratesCumSeries,
    flowSeries,
    dateLineHandleBars,
  } = useSpadDeclineCharts({
    spadDeclineState,
    currentModule,
    onChartDrag,
    setSpadDeclineState,
    compareAnalysis,
    compareName,
  });

  const {
    flowYAxesLog,
    logRateFlowCustomZoom,
    xAxes: logChartXAxes,
  } = useLogRate({
    logFlowSeries,
    unit,
  });

  const { logQgCustomZoom, logRatesCumSeries, ratesCumXAxes, ratesCumYAxesLog } = useLogRateProd({
    unit,
    ratesCumSeries,
    currentModule,
    logChart: spadDeclineState?.analysed_data?.log_chart,
  });

  const {
    flowYAxes,
    qgVstCustomZoom,
    xAxes: useCartesianRateDayXAxes,
  } = useCartesianRateDay({
    unit,
    logFlowSeries,
    flowSeries,
  });

  const {
    qQCustomZoom,
    ratesCumXAxes: cartesianRatesCumXAxes,
    ratesCumYAxes,
  } = useCartesianRateProd({
    ratesCumSeries,
    unit,
  });

  const { QgxAxes, cumProdSeries, cumProdYAxes } = useCartesianProdDay({
    getScatterItemColor,
    unit,
    analysedData: spadDeclineState?.analysed_data,
    forecastAnalysis: spadDeclineState?.analysis_result,
    compareAnalysis,
    compareName,
  });

  const { analysisTable, analysisTableColumn, tableCsv } = useAnalysisSumary({
    unit,
    dataSets: dataSets?.map((data) => data.name),
    analysisResult: spadDeclineState?.analysis_result,
  });
  const {
    analysisTable: compareAnalysisTable,
    analysisTableColumn: compareAnalysisTableColumn,
    tableCsv: compareTableCsv,
  } = useAnalysisSumary({ unit, dataSets: compareDataSet?.dataSetIds, analysisResult: compareAnalysis?.analysis_result });

  useEffect(() => {
    if (tableCsv.data.length > 0) {
      const csvData = [tableCsv];
      if (compareTableCsv.data.length > 0) csvData.push(compareTableCsv);
      setCsvData(csvData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableCsv, compareTableCsv]);

  // tab index
  // 1 chart → log chart rate vs day
  // 2 chart → log rate vs cumprod
  // 3 → cartesian rate vs day
  // 4 → cartesian rate vs cumprod
  // 5 → cartesian cum prod vs day
  const tabList = useMemo(() => {
    return [
      {
        label: (
          <span>
            log(q<sub>{subChar}</sub>) vs t
          </span>
        ),
        content: (
          <div style={{ height: 700 }}>
            <FossilyticsChart
              id={`${currentModule}_log_q_t`}
              isLoading={isLoading}
              xAxes={logChartXAxes}
              yAxes={flowYAxesLog}
              series={logFlowSeries}
              lines={rateTimeHandleBar}
              legends={[
                {
                  name: "Flow rates",
                  color: currentModule === ModuleId.SPAD_DECLINE_GAS ? palette.customColor.red : palette.customColor.green,
                },
              ]}
              onLineChange={updateLineFromChart}
              customDataZoom={logRateFlowCustomZoom}
              draggable={draggable}
            />
          </div>
        ),
        key: "log(q) vs t",
      },
      {
        label: (
          <span>
            log(q<sub>{subChar}</sub>) vs Q<sub>{subChar}</sub>
          </span>
        ),
        key: "log (q) vs Q",
        content: (
          <div style={{ height: 700 }}>
            <FossilyticsChart
              id="spad_dec_log_q_Q"
              isLoading={isLoading}
              legends={[
                {
                  name: "Flow rates",
                  color: currentModule === ModuleId.SPAD_DECLINE_GAS ? palette.customColor.red : palette.customColor.green,
                },
              ]}
              xAxes={ratesCumXAxes}
              yAxes={ratesCumYAxesLog}
              series={logRatesCumSeries}
              customDataZoom={logQgCustomZoom}
              lines={rateCumProdHandleBar}
              onLineChange={updateLineFromChart}
            />
          </div>
        ),
      },
      {
        label: (
          <span>
            q<sub>{subChar}</sub> vs t
          </span>
        ),
        key: "q vs t",
        content: (
          <div style={{ height: 700 }}>
            <FossilyticsChart
              id={`${currentModule}_q_t`}
              isLoading={isLoading}
              xAxes={useCartesianRateDayXAxes}
              yAxes={flowYAxes}
              series={flowSeries}
              legends={[
                {
                  name: "Flow rates",
                  color: currentModule === ModuleId.SPAD_DECLINE_GAS ? palette.customColor.red : palette.customColor.green,
                },
              ]}
              lines={rateTimeHandleBar}
              onLineChange={updateLineFromChart}
              customDataZoom={qgVstCustomZoom}
              draggable={draggable}
            />
          </div>
        ),
      },
      {
        label: (
          <span>
            q<sub>{subChar}</sub> vs Q<sub>{subChar}</sub>
          </span>
        ),
        key: "q vs Q",
        content: (
          <div style={{ height: 700 }}>
            <FossilyticsChart
              id={`${currentModule}_q_Q`}
              isLoading={isLoading}
              legends={[
                {
                  name: "Flow rates",
                  color: currentModule === ModuleId.SPAD_DECLINE_GAS ? palette.customColor.red : palette.customColor.green,
                },
              ]}
              customDataZoom={qQCustomZoom}
              lines={rateCumProdHandleBar}
              onLineChange={updateLineFromChart}
              xAxes={cartesianRatesCumXAxes}
              yAxes={ratesCumYAxes}
              series={ratesCumSeries}
            />
          </div>
        ),
      },
      {
        label: (
          <span>
            Q<sub>{subChar}</sub> vs t
          </span>
        ),
        key: "Q vs t",
        content: (
          <div style={{ height: 700 }}>
            <FossilyticsChart
              legends={[
                {
                  name: "Cum. production",
                  color: currentModule === ModuleId.SPAD_DECLINE_GAS ? palette.customColor.red : palette.customColor.green,
                },
              ]}
              id={`${currentModule}_Q_t`}
              isLoading={isLoading}
              xAxes={QgxAxes}
              yAxes={cumProdYAxes}
              series={cumProdSeries}
              lines={[...dateLineHandleBars]}
              draggable={draggable}
            />
          </div>
        ),
      },
      {
        label: <span>{dictionary.spadDecline.analysisSummary}</span>,
        key: dictionary.spadDecline.analysisSummary,
        content: (
          <div style={{ height: 700, marginTop: 20 }}>
            {compareName && (
              <h3>
                {project?.name} - {dataSets?.map((data) => data.name).join(", ")}
              </h3>
            )}

            <ReactGridTable
              viewWidth={"100%"}
              viewHeight={215}
              columns={analysisTableColumn}
              rows={analysisTable}
              enableRangeSelection
              enableColumnSelection
              stickyTopRows={2}
            />
            {compareName && (
              <>
                <h3>{compareName}</h3>
                <ReactGridTable
                  viewWidth={"100%"}
                  viewHeight={215}
                  columns={compareAnalysisTableColumn}
                  rows={compareAnalysisTable}
                  enableRangeSelection
                  enableColumnSelection
                  stickyTopRows={2}
                />
              </>
            )}
          </div>
        ),
      },
    ];
  }, [
    subChar,
    currentModule,
    isLoading,
    logChartXAxes,
    flowYAxesLog,
    logFlowSeries,
    rateTimeHandleBar,
    palette.customColor.red,
    palette.customColor.green,
    updateLineFromChart,
    logRateFlowCustomZoom,
    draggable,
    ratesCumXAxes,
    ratesCumYAxesLog,
    logRatesCumSeries,
    logQgCustomZoom,
    rateCumProdHandleBar,
    useCartesianRateDayXAxes,
    flowYAxes,
    flowSeries,
    qgVstCustomZoom,
    qQCustomZoom,
    cartesianRatesCumXAxes,
    ratesCumYAxes,
    ratesCumSeries,
    QgxAxes,
    cumProdYAxes,
    cumProdSeries,
    dateLineHandleBars,
    project?.name,
    dataSets,
    analysisTableColumn,
    analysisTable,
    compareName,
    compareAnalysisTableColumn,
    compareAnalysisTable,
  ]);

  return (
    <div>
      <Tabs tabList={tabList} />
    </div>
  );
};

export default SpadDeclineAnalysisChart;
