import { DefaultButton, Dialog, DialogFooter, PrimaryButton, TextField, Text } from "@fluentui/react";
import { useState } from "react";

import TreeViewCreateGroupsInput from "../../TreeViewCreateGroupsInputV2";
import {
  deleteGroupDialogContentProps,
  editGroupDialogContentProps,
  modalProps,
  newGroupDialogContentProps,
  newSubgroupDialogContentProps,
} from "./model";
import { Group } from "../../../model";

import { getIdFromActiveKey } from "../utils";
import { DialogEnum } from "../types";

interface GroupDialogProps {
  isLoading: boolean;
  createNewGroups: (val: string) => void;
  editExistingGroup: (name: string) => void;
  setNewGroupsNames: (names: string[]) => void;
  newGroupsNames: string[];
  activeDialog?: DialogEnum;
  setActiveDialog: (val: DialogEnum | undefined) => void;
  selectedKey: string;
  deleteGroup: () => void;
  selectedGroup?: Group;
}

const GroupDialog = ({
  editExistingGroup,
  setNewGroupsNames,
  createNewGroups,
  newGroupsNames,
  activeDialog,
  setActiveDialog,
  selectedKey,
  isLoading,
  selectedGroup,
  deleteGroup,
}: GroupDialogProps) => {
  const { groupIds } = getIdFromActiveKey(selectedKey);
  const [name, setName] = useState("");

  return (
    <>
      <Dialog
        hidden={activeDialog !== DialogEnum.NEW_GROUP}
        onDismiss={() => {
          setNewGroupsNames([]);
          setActiveDialog(undefined);
        }}
        dialogContentProps={groupIds.length > 0 ? newSubgroupDialogContentProps : newGroupDialogContentProps}
        modalProps={modalProps}
      >
        <TreeViewCreateGroupsInput name={name} setName={setName} parentGroup={selectedGroup} value={newGroupsNames} onChange={setNewGroupsNames} />
        <DialogFooter>
          <PrimaryButton
            disabled={isLoading || (newGroupsNames.length < 1 && name.length < 1)}
            onClick={() => {
              createNewGroups(name);
              setName("");
            }}
            text="Create"
          />
          <DefaultButton
            disabled={isLoading}
            onClick={() => {
              setNewGroupsNames([]);
              setActiveDialog(undefined);
            }}
            text="Close"
          />
        </DialogFooter>
      </Dialog>

      {/* Edit group dialog */}

      <Dialog
        hidden={activeDialog !== DialogEnum.EDIT_GROUP}
        onDismiss={() => setActiveDialog(undefined)}
        dialogContentProps={editGroupDialogContentProps}
        modalProps={modalProps}
      >
        <TextField
          label="New group name"
          placeholder="Enter a group name..."
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              setActiveDialog(undefined);
              editExistingGroup(name);
              setName("");
            }
          }}
          value={name}
          onChange={(_, v) => setName(v ?? "")}
        />
        <Text variant="tiny">Tip: Use the Enter key to save changes.</Text>

        <DialogFooter>
          <PrimaryButton
            disabled={!name}
            onClick={() => {
              setActiveDialog(undefined);
              editExistingGroup(name);
              setName("");
            }}
            text="Save"
          />
          <DefaultButton
            onClick={() => {
              setActiveDialog(undefined);
              setName("");
            }}
            text="Close"
          />
        </DialogFooter>
      </Dialog>

      {/* Delete group dialog */}

      <Dialog
        hidden={activeDialog !== DialogEnum.DELETE_GROUP}
        onDismiss={() => setActiveDialog(undefined)}
        dialogContentProps={deleteGroupDialogContentProps}
        modalProps={modalProps}
      >
        <DialogFooter>
          <DefaultButton disabled={isLoading} onClick={() => deleteGroup()}>
            Delete
          </DefaultButton>
          <PrimaryButton disabled={isLoading} onClick={() => setActiveDialog(undefined)}>
            Cancel
          </PrimaryButton>
        </DialogFooter>
      </Dialog>
    </>
  );
};

export default GroupDialog;
