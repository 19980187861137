import { GenericApiParam, createPoll } from "@/utils/apiFetcher";
import { gazAutoRtaInitialize } from "@/constants/apiUrl";
import { GazAutoRta } from "../State";

type PostInitializeGazAutoRtaPayload = Omit<GenericApiParam, "path">;

type PostInitializeGazAutoRtaQuery = {
  data_set_ids: string[];
  project_id?: string;
  group_id?: string;
};

export const pollInitializeGazAutoRta = ({ queryStringParameters }: PostInitializeGazAutoRtaPayload) => {
  if (!queryStringParameters.data_set_ids) return { data: null };
  return createPoll<GazAutoRta, {}, PostInitializeGazAutoRtaQuery>({
    path: gazAutoRtaInitialize,
    queryStringParameters,
    type: "post",
  });
};
