import { IDropdownOption } from "@fluentui/react";

import { GazAutoRtaInputGrid, InputGridCalculation, GasPvtRecombination, GasPvt } from "@/models/gaz/autoRta";

import "../../ModuleGasAutoRTA.css";
import GasPvtInputField from "./GasPvtInputField";
import GasPvtChart from "./GasPvtChart";

export interface GasPvtParamProps {
  value: GazAutoRtaInputGrid;
  calculateValue: InputGridCalculation;
  isLoading: boolean;
  gasRecombination: boolean | undefined;
  onCheckboxGasRecombination: (v: boolean | undefined) => void;
  updateGasPvtInputField: (v: number | undefined, key: keyof GasPvt) => void;
  updateGasRecombinationInput: (v: number | undefined, key: keyof Omit<GasPvtRecombination, "use_gas_recombination">) => void;
  updateGasPvtSelection: (v: IDropdownOption | undefined, key: keyof GasPvt) => void;
}

const GasPvtContainer = ({
  value,
  calculateValue,
  isLoading,
  gasRecombination,
  onCheckboxGasRecombination,
  updateGasRecombinationInput,
  updateGasPvtSelection,
  updateGasPvtInputField,
}: GasPvtParamProps) => {
  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "1fr 2.8fr",
      }}
    >
      <GasPvtInputField
        value={value}
        updateGasPvtInputField={updateGasPvtInputField}
        gasRecombination={gasRecombination}
        onCheckboxGasRecombination={onCheckboxGasRecombination}
        updateGasRecombinationInput={updateGasRecombinationInput}
        updateGasPvtSelection={updateGasPvtSelection}
        isLoading={isLoading}
      />

      <GasPvtChart calculateValue={calculateValue} isLoading={isLoading} />
    </div>
  );
};

export default GasPvtContainer;
