import React from "react";
import { Stack } from "@fluentui/react";
import { Outlet } from "react-router-dom";

// hooks
import useMultipleTaskRestriction from "./utils/useMultipleTaskRestriction";

// components
import ApiErrorMessageBar from "./components/APIErrorMessageBar";
import ProjectNav from "./components/TreeView/ProjectNav";
import ErrorAlert from "./components/Error/Alert";
import { useTreeViewState } from "./components/TreeView/hooks/TreeViewContextV2";

function Layout() {
  useMultipleTaskRestriction();

  const { errorWarning } = useTreeViewState();
  return (
    <Stack horizontal grow={1} style={{ minHeight: 0 }}>
      <ProjectNav />

      <Stack grow={1}>
        <ErrorAlert message={errorWarning} />
        <ApiErrorMessageBar />
        <Outlet />
      </Stack>
    </Stack>
  );
}

export default Layout;
