import { useTheme } from "@mui/material/styles";
import useThemeStyling from "@/utils/useThemeStyling";

import dictionary from "@/constants/dictionary";
import FossilyticsDropdown from "@/components/fields/FossilyticsDropdown";
import SummaryTable from "@/components/SummaryTable";

import useTahkCsgSummaryCard, { UseTahkCsgSummaryCardProps } from "../../hooks/input/useTahkCsgSummaryCard";
import { summaryCardTableHeader } from "../../constants/grid";
import LoadingIndicator from "@/components/LoadingIndicator";
import { SummaryCardContainer } from "./style";

const SummaryCard = (props: UseTahkCsgSummaryCardProps) => {
  const { palette } = useTheme();
  const { elevatedCard } = useThemeStyling();

  const { layer, setLayer, layerOption, selectedLayerSummary } = useTahkCsgSummaryCard(props);

  return (
    <SummaryCardContainer style={elevatedCard}>
      <h4
        style={{
          color: palette.primary.main,
          marginBottom: 10,
        }}
      >
        {dictionary.tahk.summary}
      </h4>

      {props.isLoading ? (
        <div className="center-flex h100">
          <LoadingIndicator />
        </div>
      ) : (
        <>
          <FossilyticsDropdown
            label={dictionary.tahk.layer}
            options={layerOption}
            disabled={props.isLoading}
            selectedKey={layer}
            onChange={(v) => {
              setLayer(Number(v?.key));
            }}
          />

          <SummaryTable rows={selectedLayerSummary} headers={summaryCardTableHeader} />
        </>
      )}
    </SummaryCardContainer>
  );
};

export default SummaryCard;
