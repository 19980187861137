import { initializeKoldunCsgData } from "@/constants/apiUrl";

import { fetchApi } from "@/utils/apiFetcher";
import { Output, KoldunCsgQuery } from "../State";

export const postInitializeKoldunCsg = (query: KoldunCsgQuery, payload: Output | null) => {
  return fetchApi<Output, Output | null, KoldunCsgQuery>({
    path: initializeKoldunCsgData,
    body: payload ?? null,
    type: "post",
    queryStringParameters: query,
  });
};
