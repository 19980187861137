import { Stack } from "@fluentui/react";

import { MonteCarloInputApplication, MonteCarloInputProps, UncertainParamKDependence } from "@/models/koldun";
import FossilyticsNumberField from "@/components/fields/FossilyticsNumberField";

const KDependenceInput = ({ units, output, updateUncertainList, measureNum, uncertainKey, keyField, errorInputValidation }: MonteCarloInputProps) => {
  const application = output.measures[measureNum].measure_layers[0].uncertain_list[uncertainKey].application;
  const renderLayerFields = () => {
    return output.measures[measureNum].measure_layers.map((measureLayer, index) => {
      return (
        <Stack
          key={`layer-field-${measureLayer.layer_num}`}
          enableScopedSelectors
          horizontal
          disableShrink
          tokens={{ childrenGap: 20 }}
          style={{ width: 740 }}
        >
          {index === 0 ? (
            <span
              style={{
                width: 50,
                fontWeight: 600,
                marginTop: 35,
              }}
            >
              Layer {index + 1}{" "}
            </span>
          ) : (
            <span
              style={{
                width: 50,
                fontWeight: 600,
                marginTop: 8,
              }}
            >
              Layer {index + 1}{" "}
            </span>
          )}
          <Stack grow={1} className="equal-size">
            <FossilyticsNumberField
              label={index === 0 ? `Min` : ""}
              suffix={units}
              debounceDelay={1000}
              value={(output.measures[measureNum].measure_layers[index].uncertain_list[uncertainKey] as UncertainParamKDependence).min}
              onChange={(v) => updateUncertainList(index, "min", v)}
              keyField={`${keyField}.min`}
              errors={errorInputValidation}
            />
          </Stack>
          <Stack grow={1} className="equal-size">
            <FossilyticsNumberField
              label={index === 0 ? `Max` : ""}
              suffix={units}
              debounceDelay={1000}
              value={(output.measures[measureNum].measure_layers[index].uncertain_list[uncertainKey] as UncertainParamKDependence).max}
              onChange={(v) => updateUncertainList(index, "max", v)}
              keyField={`${keyField}.max`}
              errors={errorInputValidation}
            />
          </Stack>
          <Stack grow={1} className="equal-size">
            <FossilyticsNumberField
              label={index === 0 ? `Perm at Min` : ""}
              suffix="md"
              debounceDelay={1000}
              value={
                (output.measures[measureNum].measure_layers[index].uncertain_list[uncertainKey] as UncertainParamKDependence).perm_at_min_porosity
              }
              onChange={(v) => updateUncertainList(index, "perm_at_min_porosity", v)}
              keyField={`${keyField}.perm_at_min_porosity`}
              errors={errorInputValidation}
            />
          </Stack>
        </Stack>
      );
    });
  };

  const renderMeasureField = () => (
    <Stack horizontal enableScopedSelectors tokens={{ childrenGap: 20 }} style={{ width: 680 }}>
      <Stack grow={1} className="equal-size">
        <FossilyticsNumberField
          label={`Min`}
          suffix={units}
          debounceDelay={1000}
          value={(output.measures[measureNum].measure_layers[0].uncertain_list[uncertainKey] as UncertainParamKDependence).min}
          onChange={(v) => updateUncertainList(0, "min", v)}
          keyField={`${keyField}.min`}
          errors={errorInputValidation}
        />
      </Stack>
      <Stack grow={1} className="equal-size">
        <FossilyticsNumberField
          label={`Max`}
          suffix={units}
          debounceDelay={1000}
          value={(output.measures[measureNum].measure_layers[0].uncertain_list[uncertainKey] as UncertainParamKDependence).max}
          onChange={(v) => updateUncertainList(0, "max", v)}
          keyField={`${keyField}.max`}
          errors={errorInputValidation}
        />
      </Stack>
      <Stack grow={1} className="equal-size">
        <FossilyticsNumberField
          label={`Perm at Min`}
          suffix="md"
          debounceDelay={1000}
          value={(output.measures[measureNum].measure_layers[0].uncertain_list[uncertainKey] as UncertainParamKDependence).perm_at_min_porosity}
          onChange={(v) => updateUncertainList(0, "perm_at_min_porosity", v)}
          keyField={`${keyField}.perm_at_min_porosity`}
          errors={errorInputValidation}
        />
      </Stack>
    </Stack>
  );

  return (
    <Stack horizontal tokens={{ childrenGap: 20 }}>
      <Stack grow={0.5} tokens={{ childrenGap: 10 }} className="equal-size">
        {application === MonteCarloInputApplication.LAYER ? renderLayerFields() : renderMeasureField()}
      </Stack>
    </Stack>
  );
};

export default KDependenceInput;
