import React, { useCallback, useMemo } from "react";
import { CellChange, Column, NumberCell, ReactGrid, Row } from "@silevis/reactgrid";
import { IStyleFunctionOrObject, IToggleStyleProps, IToggleStyles, Toggle, Text, useTheme, Stack, Dropdown } from "@fluentui/react";

import FossilyticsNumberField from "../../../../../../../../components/fields/FossilyticsNumberField";
import { handleKeyDown } from "@/util";
import { FlowPressureType } from "@/model";
import { Output, BaseInputsKeys, FlowingPressureOptions } from "@/models/koldun";

import { ErrorValidationDetail } from "@/models/ErrorInputValidation";

const getLastValidDataIndexForMultiFlow = (multiFlowInputs?: [number, number, number][], num_days?: number) => {
  if (multiFlowInputs && num_days != null) {
    for (let i = 0; i < multiFlowInputs.length; i++) {
      if ((i > 0 && multiFlowInputs[i - 1][0] >= multiFlowInputs[i][0]) || multiFlowInputs[i][0] > num_days) {
        return i - 1;
      }
    }
  }
  return 0;
};

const toggleStyle: IStyleFunctionOrObject<IToggleStyleProps, IToggleStyles> = {
  root: { position: "relative", left: 0, top: -5 },
  container: { left: 35, top: 5 },
  pill: {
    background: "white",
    backgroundColor: "rgb(242, 143, 29)",
    color: "black",
    border: "0px solid rgb(39, 39, 39)",
    borderColor: "rgb(39, 39, 39)",
    ":hover": {
      backgroundColor: "rgb(242, 143, 29)",
      borderColor: "black",
    },
    ":hover .ms-Toggle-thumb": {
      backgroundColor: "rgb(242, 242, 242)",
    },

    checked: {
      borderColor: "black",
      border: "1px solid rgb(39, 39, 39)",
    },
  },
  thumb: {
    background: "white",
  },
};

const multiFlowParametersInputsCols = [
  { columnId: 0, width: 125 },
  { columnId: 1, width: 200 },
  { columnId: 2, width: 200 },
] as Column[];

interface KoldunCsgFlowParametersInputsProps {
  value: Output;
  onChange: React.Dispatch<React.SetStateAction<Output | null>>;
  errorInputValidation?: ErrorValidationDetail[];
}

const KoldunCsgFlowParametersInputs = ({ onChange, value, errorInputValidation }: KoldunCsgFlowParametersInputsProps) => {
  const theme = useTheme();
  const baseInput = value.base_inputs;
  const totalRow = baseInput.num_days ?? 0;

  const lastValidDataIndex = useMemo(() => {
    return getLastValidDataIndexForMultiFlow(baseInput.inputs, totalRow);
  }, [baseInput.inputs, totalRow]);

  function getBackground(i: number, lastValidDataIndex: number, theme: any) {
    if (i === 0) {
      return theme.palette.neutralLight;
    } else if (i > lastValidDataIndex) {
      return theme.palette.themeLight;
    } else {
      return undefined;
    }
  }

  const _multiFlowParametersInputsRows: Row[] = useMemo(
    () => [
      {
        rowId: "header",
        cells: [
          { type: "header", text: "Days" },
          { type: "header", text: "Flow Pressure (psia)" },
          {
            type: "header",
            text: baseInput.contract_rate_type_flag === "gas" ? "Gas Contract rate(MMscf/d)" : "Water Contract rate(stb/d)",
          },
        ],
      },
      ...baseInput.inputs.map((p, i) => ({
        rowId: i + 1,
        cells: [
          {
            type: "number" as const,
            value: p[0],
            nonEditable: i === 0,
            hideZero: true,
            style: {
              background: getBackground(i, lastValidDataIndex, theme),
            },
          },
          {
            type: "number" as const,
            value: p[1],
          },
          {
            type: "number" as const,
            value: p[2],
            handleKeyDown: handleKeyDown,
          },
        ],
      })),
    ],
    [baseInput.contract_rate_type_flag, baseInput.inputs, lastValidDataIndex, theme]
  );

  const blankRows = Array.from({ length: totalRow - _multiFlowParametersInputsRows.length }, (_, i) => ({
    key: i,
    rowId: _multiFlowParametersInputsRows.length + i + 1,
    cells: [
      {
        type: "number" as const,
        value: 0,
        style: {
          background: theme.palette.themeLight,
        },
        hideZero: true,
      },
      {
        type: "number" as const,
        value: NaN,
      },
      {
        type: "number" as const,
        value: NaN,
        handleKeyDown: handleKeyDown,
      },
    ],
  }));

  const multiFlowParametersInputsRows: Row[] = useMemo(() => {
    return [..._multiFlowParametersInputsRows, ...blankRows];
  }, [_multiFlowParametersInputsRows, blankRows]);

  const UpdateData = useCallback(
    (v: number | undefined, key: BaseInputsKeys) => {
      onChange((prev) => {
        if (!prev) return prev;
        const prevInputs: Output = { ...prev };
        prevInputs.base_inputs[key] = !v ? null : v;
        return { ...prevInputs };
      });
    },
    [onChange]
  );

  const cloneRows = (originalRows: Row<NumberCell>[]): Row<NumberCell>[] => {
    return [...originalRows];
  };

  const applyChangesToRows = (changes: CellChange[], rows: Row<NumberCell>[]): void => {
    changes.forEach((change) => {
      const { rowId, columnId, newCell } = change as CellChange<NumberCell>;
      const rowIndex = rowId as number;
      const columnIndex = columnId as number;
      rows[rowIndex].cells[columnIndex] = newCell;
    });
  };

  const sortRows = (rows: Row<NumberCell>[]): Row<NumberCell>[] => {
    return [
      ...rows.slice(1).sort((a, b) => {
        const aValue = a.cells[0].value;
        const bValue = b.cells[0].value;

        // If both a and b have the first number as 0, then check the second and third numbers
        if (aValue === 0 && bValue === 0) {
          const aNonZero = a.cells[1].value !== 0 || a.cells[2].value !== 0;
          const bNonZero = b.cells[1].value !== 0 || b.cells[2].value !== 0;

          if (aNonZero && !bNonZero) return -1;
          if (!aNonZero && bNonZero) return 1;
          return 0;
        }

        // If either of the first numbers is 0, place it at the back
        if (aValue === 0) return 1;
        if (bValue === 0) return -1;

        // Sort by the first number in ascending order
        return aValue - bValue;
      }),
    ];
  };

  const filterZeroRows = (rows: Row<NumberCell>[]): Row<NumberCell>[] => {
    const firstAllZeroIndex = rows.findIndex((row) => row.cells.every((cell) => cell.value === 0 || isNaN(cell.value)));
    return firstAllZeroIndex >= 0 ? rows.slice(0, firstAllZeroIndex) : rows;
  };

  const transformToNumbersArray = (rows: Row<NumberCell>[]): [number, number, number][] => {
    return rows.map((row) => {
      const [first, second, third] = row.cells;
      return [first.value, second.value, third.value];
    });
  };

  const filterNumbersArray = (numbersArray: [number, number, number][]): [number, number, number][] => {
    return numbersArray.reduce((result, currentRow, index) => {
      // Skip the first row as there's no previous row to compare with
      if (index === 0) {
        result.push(currentRow);
        return result;
      }
      // Check if there is a number less than 0
      if (currentRow.some((number) => number < 0)) {
        return result;
      }

      const previousRow = numbersArray[index - 1];

      // Check if the first number is the same as in the previous row
      if (currentRow[0] !== previousRow[0] && !isNaN(currentRow[0])) {
        // Check if flow pressure is NaN, if so, use the flow pressure from the previous row
        if (isNaN(currentRow[1])) {
          currentRow[1] = previousRow[1];
        }

        // Check if the second and third numbers are the same as in the previous row
        if (currentRow[1] !== previousRow[1] || currentRow[2] !== previousRow[2]) {
          result.push(currentRow);
        }
      }

      return result;
    }, [] as [number, number, number][]);
  };

  const handleChanges = useCallback(
    (changes: CellChange[]) => {
      const updatedRows = cloneRows(multiFlowParametersInputsRows as Row<NumberCell>[]);
      applyChangesToRows(changes, updatedRows);
      const sortedRows = sortRows(updatedRows);
      const rowsWithoutAllZeros = filterZeroRows(sortedRows);
      const numbersArray = transformToNumbersArray(rowsWithoutAllZeros);
      const filteredNumbersArray = filterNumbersArray(numbersArray);

      onChange((prevModel) => {
        if (prevModel === null) {
          return null;
        }
        return {
          ...prevModel,
          base_inputs: {
            ...prevModel.base_inputs,
            inputs: filteredNumbersArray,
          },
        };
      });
    },
    [multiFlowParametersInputsRows, onChange]
  );

  const switchMode = useCallback(() => {
    if (baseInput.contract_rate_type_flag === "gas") {
      onChange((prev: any) => ({
        ...prev,
        base_inputs: {
          ...prev.base_inputs,
          contract_rate_type_flag: "water",
        },
      }));
    } else {
      onChange((prev: any) => ({
        ...prev,
        base_inputs: {
          ...prev.base_inputs,
          contract_rate_type_flag: "gas",
        },
      }));
    }
  }, [baseInput.contract_rate_type_flag, onChange]);

  return (
    <Stack horizontal tokens={{ childrenGap: 20 }}>
      <Stack grow={1} className="equal-size" tokens={{ childrenGap: 5 }}>
        <div style={{ blockSize: 500, overflowY: "auto", marginBlockStart: 5, width: 540 }}>
          <Text variant="small" styles={{ root: { color: theme.palette.themePrimary } }}>
            Note: Contract rate should be empty or greater than 0{" "}
          </Text>
          <ReactGrid
            columns={multiFlowParametersInputsCols}
            rows={multiFlowParametersInputsRows}
            enableRangeSelection
            stickyTopRows={1}
            onCellsChanged={handleChanges}
          />
        </div>
        <Text variant="tiny">Tip: You can copy and paste cells from a spreadsheet here.</Text>
      </Stack>
      <Stack grow={1} className="equal-size" tokens={{ childrenGap: 5 }}>
        <Toggle label="Contract Rate Mode" checked={baseInput.contract_rate_type_flag === "gas"} styles={toggleStyle} onChange={switchMode} />
        <FossilyticsNumberField
          type="int"
          label="Number of simulation"
          value={value.num_simulations}
          onChange={(v) =>
            v !== undefined
              ? onChange((prev: any) => ({
                  ...prev,
                  num_simulations: v,
                }))
              : null
          }
          keyField={"num_simulations"}
          errors={errorInputValidation}
          debounceDelay={1000}
        />
        <FossilyticsNumberField
          type="int"
          label="Number of days"
          suffix="days"
          value={baseInput.num_days}
          onChange={(v) => UpdateData(v, "num_days")}
          keyField={"num_days"}
          errors={errorInputValidation}
          debounceDelay={1000}
        />
        <Dropdown
          label="Flow Pressure Type"
          options={FlowingPressureOptions}
          selectedKey={baseInput.flowing_press_flag}
          onChange={(_, v) =>
            v !== undefined
              ? onChange((prev: any) => ({
                  ...prev,
                  base_inputs: {
                    ...prev.base_inputs,
                    flowing_press_flag: v.key as FlowPressureType,
                  },
                }))
              : null
          }
        />
        <FossilyticsNumberField
          type="float"
          label="Water Gradient"
          suffix="psi/ft"
          value={baseInput.well_water_grad}
          onChange={(v) => UpdateData(v, "well_water_grad")}
          keyField={"base_inputs.well_water_grad"}
          errors={errorInputValidation}
          debounceDelay={1000}
        />
        <FossilyticsNumberField
          type="int"
          label="Pump Depth"
          suffix="ft"
          value={baseInput.pump_depth_ft}
          onChange={(v) => UpdateData(v, "pump_depth_ft")}
          keyField={"base_inputs.pump_depth_ft"}
          errors={errorInputValidation}
          debounceDelay={1000}
        />
        <FossilyticsNumberField
          type="int"
          label="Min Pump Head"
          suffix="ft"
          value={baseInput.min_pump_head_ft}
          onChange={(v) => UpdateData(v, "min_pump_head_ft")}
          keyField={"base_inputs.min_pump_head_ft"}
          errors={errorInputValidation}
          debounceDelay={1000}
        />
        <FossilyticsNumberField
          type="int"
          label="Draw down rate"
          suffix="ft/month"
          value={baseInput.ddown_rate_ft_month}
          onChange={(v) => UpdateData(v, "ddown_rate_ft_month")}
          keyField={"base_inputs.ddown_rate_ft_month"}
          errors={errorInputValidation}
          debounceDelay={1000}
        />
      </Stack>
      <Stack grow={1} className="equal-size" tokens={{ childrenGap: 5 }}></Stack>
    </Stack>
  );
};

export default KoldunCsgFlowParametersInputs;
