import { useMemo } from "react";
import { useTheme } from "@fluentui/react";

import { Field } from "@/model";

import { ModuleDataViewField } from "../../../ModuleDataView";

const useModuleData = () => {
  const theme = useTheme();
  const moduleFields = useMemo<{ [k in Field]?: ModuleDataViewField }>(
    () => ({
      [Field.WATER_RATE]: {
        name: "Water rate",
        yAxis: "Water Rate (bbl/day)",
        color: theme.palette.blue,
      },
      [Field.OIL_RATE]: {
        name: "Oil rate",
        yAxis: "Oil Rate (bbl/day)",
        color: theme.palette.green,
      },
    }),
    [theme]
  );
  return { moduleFields };
};

export default useModuleData;
