import React from "react";

// mui component
import { useTheme } from "@mui/material/styles";

import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";

// icon
import StorageIcon from "@mui/icons-material/Storage";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";

import WebIcon from "@mui/icons-material/Web";

// custom hooks
import { useTreeViewState } from "./hooks/TreeViewContextV2";

import { Container, ExpandableContainer, IconContainer, LightTooltip, SectionHeader } from "./style";
import DialogSection from "./Components/DialogSectionV2";

import DnDProjectTree from "./Components/DndProjectTree";
import DataSetList from "./Components/DataSetList";

const ProjectNav = () => {
  const { palette } = useTheme();

  const {
    rootId,
    isDataSetsLoading,
    isProjectEmpty,
    setActiveDialog,

    hideProjects,
    mappedItemKeyTotal,
    onDragEnd,

    selectedItems,
    showAll,
    setShowAll,
    setHideProjects,
    dataSets,
    onSelectItem,
    selectedKeys,
    onDragWellList,
  } = useTreeViewState();

  const renderEmptyProject = () => {
    if (!isProjectEmpty)
      return (
        <div className="spinner-container">
          <CircularProgress size={20} color="primary" />
        </div>
      );
    return <Typography variant="subtitle2">Start by creating new Projects and Groups</Typography>;
  };

  return (
    <Container id={rootId}>
      <div className="header-container">
        {/* well list */}
        <div className={`nav-section well-${showAll ? "expanded" : "collapsed"}`}>
          <SectionHeader>
            <IconContainer
              onClick={(e) => {
                e.preventDefault();
                setShowAll((prev) => !prev);
                setHideProjects(false);
              }}
              bgColor={showAll ? "white" : palette.primary.main}
            >
              <LightTooltip title={`${showAll ? "Hide" : "Show"} all wells`}>
                {showAll ? <MenuOpenIcon sx={{ color: palette.primary.main }} /> : <StorageIcon sx={{ color: "white" }} />}
              </LightTooltip>
            </IconContainer>
            <ExpandableContainer showAll={showAll}>
              <span>All wells</span>
            </ExpandableContainer>
          </SectionHeader>
          <ExpandableContainer maxWidth={250} showAll={showAll}>
            {isDataSetsLoading ? (
              <div className="spinner-container">
                <CircularProgress size={20} color="primary" />
              </div>
            ) : (
              <DataSetList />
            )}
          </ExpandableContainer>
        </div>
        <div className={`nav-section nav-${!hideProjects ? "expanded" : "collapsed"}`}>
          <SectionHeader>
            <IconContainer
              onClick={(e) => {
                e.preventDefault();
                setShowAll(false);
                setHideProjects((prev) => !prev);
              }}
              bgColor={!hideProjects ? "white" : palette.primary.main}
            >
              <LightTooltip title={`${!hideProjects ? "Hide" : "Show"} projects`}>
                {!hideProjects ? <MenuOpenIcon sx={{ color: palette.primary.main }} /> : <WebIcon sx={{ color: "white" }} />}
              </LightTooltip>
            </IconContainer>
            <ExpandableContainer showAll={!hideProjects}>
              <span>Projects</span>
            </ExpandableContainer>
          </SectionHeader>
          <ExpandableContainer showAll={!hideProjects}>
            <div className="list-container project-list">
              {selectedItems.length === 0 ? (
                <>{renderEmptyProject()}</>
              ) : (
                <DnDProjectTree
                  onDragWellList={onDragWellList}
                  onDragEnd={onDragEnd}
                  onSelectItem={onSelectItem}
                  selectedKeys={selectedKeys}
                  setActiveDialog={setActiveDialog}
                  dataSets={dataSets}
                  projects={selectedItems}
                  mappedItemKeyTotal={mappedItemKeyTotal}
                />
              )}
            </div>
          </ExpandableContainer>
        </div>
      </div>
      <DialogSection />
    </Container>
  );
};
export default ProjectNav;
