import { createTheme } from "@fluentui/react";

export const getFluentuiTheme = () =>
  createTheme({
    defaultFontStyle: {
      fontFamily: '"Baloo 2", sans-serif',
    },
    palette: {
      themePrimary: "#f28f1d",
      themeLighterAlt: "#fefaf6",
      themeLighter: "#fdecd9",
      themeLight: "#fbdcb9",
      themeTertiary: "#f7ba75",
      themeSecondary: "#f49c37",
      themeDarkAlt: "#da801a",
      themeDark: "#b86d16",
      themeDarker: "#885010",
      neutralLighterAlt: "#faf9f8",
      neutralLighter: "#f3f2f1",
      neutralLight: "#edebe9",
      neutralQuaternaryAlt: "#e1dfdd",
      neutralQuaternary: "#d0d0d0",
      neutralTertiaryAlt: "#c8c6c4",
      neutralTertiary: "#c2c2c2",
      neutralSecondary: "#858585",
      neutralSecondaryAlt: "#858585",
      neutralPrimaryAlt: "#4b4b4b",
      neutralPrimary: "#333333",
      neutralDark: "#272727",
      black: "#1d1d1d",
      white: "#ffffff",
      blue: "#044B7F",
      blueLight: "#0676C6",
      blueDark: "#022F4F",
      green: "#5FAD56",
      greenLight: "#87c180",
      greenDark: "#386832",
      red: "#C5283D",
      redDark: "#931d2d",
    },
    components: {
      VerticalDivider: { styles: { divider: { backgroundColor: "#f3f2f1" } } },
    },
  });
