import { FossilyticsChartSeries } from "../../../components/FossilyticsChart";

export type ModuleSpadWorOptions = {
  analysis: string;
  is_log: boolean;
  target: number;
};

export type ModuleSpadWorPlot = number[][];
export type ModuleSpadWorPlots = {
  [analysis: string]: ModuleSpadWorPlot;
};

export type ModuleSpadForecastLines = {
  [analysis: string]: RateResponse;
};

export type ModuleSpadWorResult = {
  line_low: number[][];
  line_mid: number[][];
  line_high: number[][];
  eur: [number, number, number];
  remaining_recoverable: [number, number, number];
  target: number;
  rates: RateResponse;
};

export type ModuleSpadForecastLinesSeries = {
  name: string;
  forecastLineSeriesItem: FossilyticsChartSeries[];
};

export type ModuleSpadWorResults = {
  [analysis: string]: ModuleSpadWorResult;
};

export type RateResponse = {
  forecast_low: number[][];
  forecast_mid: number[][];
  forecast_high: number[][];
};
export const worAnalyses = ["WOR", "1/fw", "fo"];

export interface ModuleSpadWorSlope {
  m: number;
  y0: number;
  x0: number;
  eur: number;
  rec: number;
}

export function createBlankWorSlope(): ModuleSpadWorSlope {
  return { m: 0, x0: 0, y0: 0, eur: 0, rec: 0 };
}

export function calculateWorSlope(
  data: number[][],
  line: number[][],
  axisType: string,
  target: number
): ModuleSpadWorSlope {
  const slope = createBlankWorSlope();
  slope.m = (line[1][1] - line[0][1]) / (line[1][0] - line[0][0]);
  slope.y0 = line[0][1] - slope.m * line[0][0];
  slope.x0 = -slope.y0 / slope.m;

  if (target !== undefined) {
    slope.eur =
      axisType === "log"
        ? line[1][0] +
          ((Math.log10(target) - Math.log10(line[1][1])) * (line[1][0] - line[0][0])) /
            (Math.log10(line[1][1]) - Math.log10(line[1][0]))
        : line[1][0] + (target - line[1][1]) / slope.m;
  }

  slope.rec = slope.eur - Math.max(...data.map((d) => d[0]));

  return slope;
}
