import { DataSet, DataSetType, Group, Project } from "@/model";
import { TreeNode } from "./Components/DndProjectTree/helper";

export type IItem = {
  name: string;
  data?: any;
  key?: string | number;
};

export enum DialogEnum {
  PROJECT_SETTING = "PROJECT_SETTING",
  EDIT_PROJECT = "EDIT_PROJECT",
  DELETE_PROJECT = "DELETE_PROJECT",
  NEW_GROUP = "NEW_GROUP",
  EDIT_GROUP = "EDIT_GROUP",
  DELETE_GROUP = "DELETE_GROUP",
  REMOVE_DATASET = "REMOVE_DATASET",
  NEW_PROJECT = "NEW_PROJECT",
}

export type FlatenGroupProject = {
  [projectOrGroupId: string]: {
    total: number;
    ids?: string[];
    name: string;
  };
};

export type TreeViewStateV2 = {
  isLoading: boolean;
  selectedKey?: string;
  rootId: string;
  showAll: boolean;
  setShowAll: React.Dispatch<React.SetStateAction<boolean>>;
  setHideProjects: React.Dispatch<React.SetStateAction<boolean>>;
  setShowFilters: (val: boolean) => void;
  showFilters: boolean;
  filterTypes: DataSetType[];
  setFilterTypes: React.Dispatch<React.SetStateAction<DataSetType[]>>;
  clearFilters: () => void;
  filter?: string;
  setFilter: (type: string) => void;
  isDataSetsLoading: boolean;
  isProjectEmpty: boolean;
  dataSetItems: IItem[];
  projects?: Project[];
  hideProjects: boolean;

  // dialog additional state:
  copyFromSelected: boolean;
  setCopyFromSelected: (val: boolean) => void;
  createNewProject: (val: string) => void;
  deleteProject: () => void;
  editExistingProject: (val: string) => void;

  createNewGroups: (val: string) => void;
  editExistingGroup: (val: string) => void;
  selectedGroup?: Group;
  setNewGroupsNames: (val: string[]) => void;
  newGroupsNames: string[];
  refreshProjects: () => Promise<Project[] | undefined> | void;
  removeDataSets: () => void;

  activeDialog?: DialogEnum;
  setActiveDialog: (val: DialogEnum | undefined) => void;

  // project nav:
  onDragEnd: (result: any) => Promise<void> | void;

  selectedItems: Project[];
  setActiveKey: (key: string) => void;
  mappedItemKeyTotal: FlatenGroupProject;
  deleteGroup: () => void;
  selectedDataSet: string[];
  setSelectedDataSet: (payload: string[]) => void;
  refreshDataSets: () => void;

  dataSets?: DataSet[];
  shiftHeld: boolean;
  commandControlHeld: boolean;
  errorWarning?: string;

  onSelectItem: (payload: SelectItemProp) => void;
  selectedKeys: string[];
  onDragWellList: (payload: any) => void;
  onDropWell: () => void;
};

export type SelectItemProp = {
  key: string; // only for data set because each group can have same id
  id: string;
  groupId?: string[];
  prevSelection?: boolean;
  dataSetId?: string;
  type: "project" | "group" | "dataset";
  item: TreeNode;
  isDrag?: boolean;
};
