import React from "react";
import { IDropdownOption } from "@fluentui/react";
import { FormationCompressibility, GasViscosityMethod, GeomechanicsType, RockType, TankType, WaterViscosityMethod, ZMethod } from "@/model";
import { Output, UncertainParamDistribution, UncertainParamType, MonteCarloInputApplication } from "@/models/koldun";
import { ErrorValidationDetail } from "@/models/ErrorInputValidation";

export interface MatrixShrinkage {
  shrinkage_factor_dim: number;
  max_pim: number;
  perm_modulus: number;
  poissons_ratio_dim: number;
  grain_compres_psi: number;
  axial_modulus_psi: number;
  lang_vol_strain: number;
  n_exponent: number;
}

export interface FieldMapping {
  label: string;
  suffix: string;
  min: number;
  valueKey: keyof MatrixShrinkage;
  onChangeKey: keyof MatrixShrinkage;
}

export const formationCompressibilityOptions = [
  {
    key: `${FormationCompressibility.NEWMAN}-${RockType.SANDSTONE}`,
    text: "Newman Sandstone",
    data: {
      form_compressibility_method: FormationCompressibility.NEWMAN,
      rock_type: RockType.SANDSTONE,
    },
  },
  {
    key: `${FormationCompressibility.NEWMAN}-${RockType.LIMESTONE}`,
    text: "Newman Limestone",
    data: {
      form_compressibility_method: FormationCompressibility.NEWMAN,
      rock_type: RockType.LIMESTONE,
    },
  },
  {
    key: `${FormationCompressibility.HALL}-${RockType.SANDSTONE}`,
    text: "Hall General",
    data: {
      form_compressibility_method: FormationCompressibility.HALL,
      rock_type: RockType.SANDSTONE,
    },
  },
] as IDropdownOption[];

export const GeomechanicsOptions = [
  { key: GeomechanicsType.Geo_None, text: "None" },
  { key: GeomechanicsType.Geo_Burgoyne, text: "Burgoyne-Shrivastava" },
  { key: GeomechanicsType.Geo_Yilmaz, text: "Yilmaz-Nur" },
  { key: GeomechanicsType.Geo_Palmer, text: "Palmer-Mansoori" },
] as IDropdownOption[];
export const TankTypeOptions = [
  { key: TankType.Vertical, text: "Vertical" },
  { key: TankType.Horizontal, text: "Horizontal", disabled: true },
] as IDropdownOption[];

export const zMethodOptions = [
  { key: ZMethod.DPR, text: "DPR (default)" },
  { key: ZMethod.BEGGS_BRILL, text: "Beggs/Brill" },
  { key: ZMethod.PAPAY, text: "Papay (simple)" },
  { key: ZMethod.DRANCHUCK_ABU_KASSEM, text: "Dranchuck/Abu-Kassem" },
] as IDropdownOption[];

export const wtrViscMethodOptions = [
  { key: WaterViscosityMethod.MCCAIN, text: "McCain" },
  { key: WaterViscosityMethod.LOUISIANA_STATE_UNI, text: "Louisiana State University" },
  { key: WaterViscosityMethod.BRILL_BEGGS, text: "Brill-Beggs" },
] as IDropdownOption[];

export const gasViscMethodOptions = [
  { key: GasViscosityMethod.GONZALES_EAKIN, text: "Gonzales-Eakin" },
  { key: GasViscosityMethod.LONDONO, text: "Londono" },
  { key: GasViscosityMethod.STANDING, text: "Standing" },
] as IDropdownOption[];

export const koldunCsgOGIPParamMappingField = [
  {
    name: "Permeability",
    units: "md",
    valueKey: "permeability_md",
  },
  {
    name: "Net pay",
    units: "ft",
    valueKey: "net_pay_ft",
  },
  {
    name: "PL",
    units: "ft",
    valueKey: "langmuir_p",
  },
  {
    name: "Sw",
    units: "ft",
    valueKey: "water_sat_perc",
  },
  {
    name: "Pi",
    units: "psia",
    valueKey: "initial_pressure_psia",
  },
  {
    name: "Porosity",
    units: "%",
    valueKey: "porosity_perc",
  },
  {
    name: "OGIP",
    units: "scf/ton",
    valueKey: "ogip_mmscf",
  },
  {
    name: "Under Saturation",
    units: "psia",
    valueKey: "under_saturation_perc",
  },
  {
    name: "GCI",
    units: "",
    valueKey: "gas_content_scf_ton",
  },
];

export const koldunCsgVolumInputsParamMappingField = [
  {
    name: "Permeability",
    units: "md",
    valueKey: "permeability_md",
  },
  {
    name: "Net pay",
    units: "ft",
    valueKey: "net_pay_ft",
  },
  {
    name: "PL",
    units: "ft",
    valueKey: "langmuir_p",
  },
  {
    name: "VL",
    units: "ft",
    valueKey: "langmuir_v",
  },
  {
    name: "Desorption Pressure",
    units: "",
    valueKey: "desorbtion_press",
  },
  {
    name: "Coal density",
    units: "",
    valueKey: "coal_density",
  },
  {
    name: "Sw",
    units: "ft",
    valueKey: "water_sat_perc",
  },
  {
    name: "Pi",
    units: "psia",
    valueKey: "initial_pressure_psia",
  },
  {
    name: "Porosity",
    units: "%",
    valueKey: "porosity_perc",
  },
];

export const koldunCsgRelPermParameterMappingField = [
  {
    name: "krg_max_csg",
    label: "KrgMax",
    suffix: "md",
  },
  {
    name: "krw_max_csg",
    label: "KrwMax",
    suffix: "md",
  },
  {
    name: "ng",
    label: "ng",
    suffix: "Dim",
  },
  {
    name: "nw",
    label: "nw",
    suffix: "Dim",
  },
  {
    name: "connate_gas_saturation",
    label: "Connate gas saturation",
    suffix: "frac",
  },
  {
    name: "connate_water_saturation",
    label: "Connate water saturation",
    suffix: "frac",
  },
];

export const DistributionOptions = [
  { key: UncertainParamDistribution.FIXED, text: "Fixed" },
  { key: UncertainParamDistribution.UNIFORM, text: "Uniform" },
  { key: UncertainParamDistribution.TRIANGULAR, text: "Triangular" },
  { key: UncertainParamDistribution.LOG_NORMAL, text: "Log Normal" },
  { key: UncertainParamDistribution.NORMAL, text: "Normal" },
] as IDropdownOption[];

export const applicationOptions = [
  { key: MonteCarloInputApplication.MEASURE, text: "Per Measure" },
  { key: MonteCarloInputApplication.LAYER, text: "Per Layer" },
] as IDropdownOption[];

export interface DistributionProps {
  name: string;
  units: string;
  onChange: (value: UncertainParamType) => void;
  output: Output;
  setOutput: React.Dispatch<React.SetStateAction<Output | null>>;
  measureNum: number;
  keyField?: string;
  errorInputValidation?: ErrorValidationDetail[];
}
