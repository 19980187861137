import { validateTahkCsgInput } from "@/constants/apiUrl";

import { createPoll } from "@/utils/apiFetcher";
import { TahkCsgInputResponse } from "../input";
import { TahkCsgDataOption, TahkCsgValidationState } from "../State";

type ValidateInputPayload = {
  options: TahkCsgValidationState;
} & TahkCsgDataOption;

type InputApiState = {
  inputs: TahkCsgValidationState;
  calculation: TahkCsgInputResponse;
};

export const pollValidateTahkCsgInput = (payload: ValidateInputPayload) => {
  return createPoll<InputApiState, ValidateInputPayload>({
    path: validateTahkCsgInput,
    body: payload,
    type: "post",
  });
};
