import React, { useEffect, useState } from "react";
import { shallow } from "zustand/shallow";
import { DetailsList, IColumn, SelectionMode, Stack, Text, useTheme } from "@fluentui/react";

import ModulePage from "../../ModulePage";
import { formatNumber } from "@/util";

import { ModuleGazPzInit, ModuleGazPzPvt, ModuleGazPzPvtOptions, ModuleGazPzUser } from "./model";
import ModuleGazPzInputGrid from "./ModuleGazPzInputGrid";
import { DataSet, isDataSet, ZMethod } from "@/model";
import ModuleGazPzOutputView from "./ModuleGazPzOutputView";
import ModuleGazPzDataGrid from "./ModuleGazPzDataGrid";
import ModuleGazPzAbnormalInputGrid from "./ModuleGazPzAbnormalInputGrid";
import FossilyticsNumberField from "@/components/fields/FossilyticsNumberField";
import { useSettingState } from "@/SettingsState";
import { useAppStore } from "@/features/app";

import "./ModuleGazPz.css";

const dataTableColumns = [
  { key: "parameter", name: "Parameter", fieldName: "parameter", isRowHeader: true, minWidth: 220 },
  { key: "value", name: "Value", fieldName: "value", minWidth: 60 },
] as IColumn[];

function ModuleGazPz() {
  const { selectedDataSets, isLoading, hideSidebar, postRequest } = useAppStore(
    (state) => ({
      selectedDataSets: state.selectedDataSets,
      isLoading: state.isLoading,
      hideSidebar: state.hideSidebar,
      postRequest: state.postRequest,
    }),
    shallow
  );

  const [dataSet, setDataSet] = useState<DataSet>();

  useEffect(() => {
    setDataSet(isDataSet(selectedDataSets) ? selectedDataSets : undefined);
  }, [selectedDataSets]);

  const [pvtOptions, setPvtOptions] = useSettingState<ModuleGazPzPvtOptions>("gaz_pz_pvt_opts", false, {
    default: {
      initial_pressure_psia: undefined,
      formation_temp_of: 240,
      z_method: ZMethod.DPR,

      gas_gravity: 1,
      n2_perc: 0,
      co2_perc: 0,
      h2s_perc: 0,
    },
  });
  const [pvt, setPvt] = useState<ModuleGazPzPvt>();
  const [pz, setPz] = useState<ModuleGazPzInit>();
  const [ogip, setOgip] = useState<number>();
  const [bestFitOgip, setBestFitOgip] = useState<number>();
  const [pzUser, setPzUser] = useState<ModuleGazPzUser>();

  const [dataPoints, setDataPoints] = useSettingState<number[][]>("gaz_pz_data_points", false, {
    default: Array.from(Array(200).keys()).map(() => [0, 0]),
  });
  const [lastValidDataIndex, setLastValidDataIndex] = useState(0);

  // Calculate last valid data index in data points list
  useEffect(() => {
    for (let i = 0; i < dataPoints.length; i++) {
      if (i > 0 && (!dataPoints[i][0] || dataPoints[i][0] <= 0 || !dataPoints[i][1])) {
        setLastValidDataIndex(i - 1);
        break;
      }
    }
  }, [dataPoints]);

  useEffect(() => {
    if (!dataSet || !pvtOptions || pvtOptions.initial_pressure_psia === undefined) return;

    setPvt(undefined);

    // If using abnormally pressured reservoir model, ensure we have at least porosity or form comp override
    if (pvtOptions.abnormal && !(pvtOptions.abnormal.porosity_perc || pvtOptions.abnormal.formation_compressibility_override)) return;

    (async () => {
      try {
        const response = await postRequest(
          "/modules/gaz/pz/calculate_pvt",
          {
            data_set_id: dataSet.id,
          },
          pvtOptions
        );
        setPvt(response);
      } catch (error) {
        console.error(error);
      }
    })();
  }, [dataSet, pvtOptions, postRequest]);

  // Set first data points row from inputs
  useEffect(() => {
    const newPoints = dataPoints ? [...dataPoints] : [];
    newPoints[0] = [0, pvtOptions?.initial_pressure_psia ? pvtOptions.initial_pressure_psia : 0];

    setDataPoints(newPoints);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pvtOptions, setDataPoints]);

  useEffect(() => {
    if (!dataSet || !pvtOptions || !pvt || lastValidDataIndex < 1) return;

    setPz(undefined);
    setOgip(undefined);
    setPzUser(undefined);
    (async () => {
      try {
        const response = await postRequest(
          "/modules/gaz/pz/calculate_pz_init",
          {
            data_set_id: dataSet.id,
          },
          {
            pvt_options: pvtOptions,
            pvt: pvt,
            cum_prods: dataPoints?.filter((_, i) => i <= lastValidDataIndex).map((v) => v[0]),
            pressures: dataPoints?.filter((_, i) => i <= lastValidDataIndex).map((v) => v[1]),
          }
        );
        setPz(response);

        const initOgip = Math.floor(response.best_fit[1][0]);
        setOgip(initOgip);
        setBestFitOgip(initOgip);
      } catch (error) {
        console.error(error);
      }
    })();
  }, [dataSet, pvtOptions, pvt, dataPoints, lastValidDataIndex, postRequest]);

  useEffect(() => {
    if (!dataSet || !pvtOptions || !pvt || !pz || !ogip) return;

    // Do not use model if we are not looking at an abnormally pressured reservoir
    if (!pvtOptions.abnormal) {
      setPzUser({
        pzs: [],
        line: [
          [0, pz.best_fit[0][1]],
          [ogip, 0],
        ],
      });
      return;
    }

    setPzUser(undefined);
    (async () => {
      try {
        const response = await postRequest(
          "/modules/gaz/pz/calculate_pz_user",
          {
            data_set_id: dataSet.id,
          },
          {
            pvt_options: pvtOptions,
            pvt: pvt,
            cum_prods: dataPoints?.filter((_, i) => i <= lastValidDataIndex).map((v) => v[0]),
            ogip,
          }
        );
        setPzUser(response);
      } catch (error) {
        console.error(error);
      }
    })();
  }, [dataSet, pvtOptions, pvt, pz, dataPoints, lastValidDataIndex, ogip, postRequest]);

  const theme = useTheme();
  // TODO: Centralize this
  const itemStyle = {
    boxShadow: theme.effects.elevation4,
    padding: 15,
    borderRadius: theme.effects.roundedCorner4,
  } as React.CSSProperties;

  return (
    <ModulePage
      title={"GAZ: Static P/z"}
      tabs={
        dataSet && [
          {
            headerText: "Input Grid",
            itemIcon: "NumberField",
            disabled: false,
            disableHideSidebar: true,
            content: (
              <Stack horizontal tokens={{ padding: 20, childrenGap: 20 }} verticalAlign="start">
                <Stack grow={1} tokens={{ childrenGap: 20 }}>
                  <Stack.Item style={itemStyle}>
                    <Text styles={{ root: { color: theme.palette.themePrimary } }}>Inputs</Text>

                    <ModuleGazPzInputGrid value={pvtOptions} onChange={setPvtOptions} />
                  </Stack.Item>

                  <Stack.Item style={itemStyle}>
                    <ModuleGazPzAbnormalInputGrid
                      value={pvtOptions?.abnormal}
                      onChange={(v) =>
                        setPvtOptions({
                          ...pvtOptions,
                          abnormal: v,
                        })
                      }
                    />
                  </Stack.Item>
                </Stack>

                <Stack.Item style={itemStyle}>
                  <DetailsList
                    compact
                    selectionMode={SelectionMode.none}
                    columns={dataTableColumns}
                    items={[
                      { parameter: "Initial Pressure (psia)", value: formatNumber(pvtOptions?.initial_pressure_psia) },
                      { parameter: "Gas Z-factor (Dim)", value: formatNumber(pvt?.gas_z_factor) },
                      { parameter: "Viscosity (cp)", value: formatNumber(pvt?.viscosity) },
                      { parameter: "Gas Bg (scf/ft³)", value: formatNumber(pvt?.gas_bg) },
                      { parameter: "Gas Compressibility (psi⁻¹)", value: formatNumber(pvt?.gas_comp) },
                      {
                        parameter: "Formation Compressibility (psi⁻¹)",
                        value: pvtOptions?.abnormal ? formatNumber(pvt?.form_comp) : "N/A",
                      },
                      {
                        parameter: `${pvtOptions?.abnormal?.water_compressibility_override ? "User" : "Model"} Water Compressibility (psi⁻¹)`,
                        value: formatNumber(pvt?.water_comp),
                      },
                    ]}
                  />
                </Stack.Item>
              </Stack>
            ),
          },
          {
            headerText: "P/z Output",
            itemIcon: "LineChart",
            disabled: !pvt || !pvtOptions || pvtOptions.initial_pressure_psia === undefined,
            canSaveAsImg: true,
            content: (
              <Stack horizontal style={{ height: "100%" }} tokens={{ padding: 20, childrenGap: 20 }}>
                {!hideSidebar && (
                  <Stack tokens={{ childrenGap: 20 }}>
                    <Stack style={itemStyle} tokens={{ childrenGap: 5 }} grow={1}>
                      <Text styles={{ root: { color: theme.palette.themePrimary } }}>P/z Data</Text>

                      <Stack.Item grow={1} className="ModuleGazPzGrid">
                        <div className="wrap">
                          <ModuleGazPzDataGrid
                            dataPoints={dataPoints ?? []}
                            onDataPointsChange={setDataPoints}
                            lastValidIndex={lastValidDataIndex}
                            pz={pz}
                          />
                        </div>
                      </Stack.Item>
                      <Text variant="tiny">Tip: You can copy and paste cells from a spreadsheet here.</Text>
                    </Stack>

                    <Stack style={itemStyle} tokens={{ childrenGap: 5 }}>
                      <Text styles={{ root: { color: theme.palette.themePrimary } }}>P/z Parameters</Text>

                      <FossilyticsNumberField label="Best fit OGIP" suffix="MMscf" disabled value={bestFitOgip} />
                      <FossilyticsNumberField label="Manual Fit" suffix="MMscf" value={ogip} onChange={(v) => setOgip(v)} />
                    </Stack>
                  </Stack>
                )}

                <Stack style={itemStyle} grow={1}>
                  <ModuleGazPzOutputView isLoading={isLoading} dataPoints={dataPoints ?? []} pz={pz} pzUser={pzUser} />
                </Stack>
              </Stack>
            ),
          },
        ]
      }
    />
  );
}

export default ModuleGazPz;
