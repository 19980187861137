import FossilyticsChart, { FossilyticsChartAxis, FossilyticsChartSeries } from "../../../components/FossilyticsChart";
import { useTheme } from "@fluentui/react";
import { useMemo } from "react";
import { ModuleGazPzInit, ModuleGazPzUser } from "./model";

interface ModuleGazPzOutputViewProps {
  isLoading: boolean;
  dataPoints: number[][];
  pz: ModuleGazPzInit | undefined;
  pzUser: ModuleGazPzUser | undefined;
}

function ModuleGazPzOutputView({ isLoading, dataPoints, pz, pzUser }: Readonly<ModuleGazPzOutputViewProps>) {
  const theme = useTheme();

  const xAxes = useMemo<FossilyticsChartAxis[]>(() => [{ name: "Cum. Production (MMscf)", type: "value", color: theme.palette.black }], [theme]);
  const yAxes = useMemo<FossilyticsChartAxis[]>(() => [{ name: "P/z", type: "value", color: theme.palette.black, min: 0 }], [theme]);
  const series = useMemo<FossilyticsChartSeries[]>(
    () => [
      {
        name: "Raw data",
        type: "scatter",
        color: theme.palette.red,
        data: pz ? pz.pzs.map((v, i) => [dataPoints[i][0], v]) : [],
      },
      {
        name: "Best fit",
        type: "line",
        hideSymbol: true,
        color: theme.palette.blue,
        data: pz ? pz.best_fit : [],
      },
      {
        id: "m",
        name: "Manual Fit",
        type: "line",
        hideSymbol: true,
        color: theme.palette.red,
        data: pzUser ? pzUser.line : [],
      },
    ],
    [theme, dataPoints, pz, pzUser]
  );

  return <FossilyticsChart id="gaz_pz_output" isLoading={isLoading} xAxes={xAxes} yAxes={yAxes} series={series} />;
}

export default ModuleGazPzOutputView;
