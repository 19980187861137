import React, { useCallback, useId, useMemo, useState } from "react";
import { Callout, DirectionalHint, getIconClassName, IRenderFunction, Stack, useTheme } from "@fluentui/react";
import styled from "@emotion/styled";
import { useAppStore } from "@/features/app";

const StyledLink = styled.a`
  color: black;
  text-decoration: none;

  display: flex;
  align-items: center;

  label {
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    padding: 5px 0px;
  }

  &:hover {
    span {
      color: #f28f1d;
    }
    label {
      color: #f28f1d;
    }
  }
`;

export interface IFossilyticsFieldBaseProps {
  label?: string;
  suffix?: string;
  disabled?: boolean;
  required?: boolean;
  styles?: any;
  calloutContent?: string | React.ReactNode;
  helpUrl?: string;
}

interface FossilyticsFieldBaseProps extends IFossilyticsFieldBaseProps {
  children: (onRenderLabel: IRenderFunction<any> | undefined, disabled: boolean) => React.ReactNode;
}

function FossilyticsFieldBase({ calloutContent, children, disabled, helpUrl }: Readonly<FossilyticsFieldBaseProps>) {
  const theme = useTheme();
  const isLoading = useAppStore((state) => state.isLoading);

  const calloutIconId = useId();
  const calloutTarget = "#" + calloutIconId.replaceAll(":", "\\:");
  const [isCalloutVisible, setIsCalloutVisible] = useState(false);

  const _disabled = useMemo(() => Boolean(isLoading || disabled), [isLoading, disabled]);

  const onRenderLabel = useCallback<IRenderFunction<any>>(
    (props, defaultRender) => {
      if (calloutContent) {
        return (
          <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 5 }}>
            {defaultRender && defaultRender(props)}
            <span
              id={calloutIconId}
              className={getIconClassName("info")}
              style={!_disabled ? { color: theme.palette.themePrimary, cursor: "help" } : { opacity: 0.5 }}
              onMouseEnter={() => !_disabled && setIsCalloutVisible(true)}
              onMouseLeave={() => !_disabled && setIsCalloutVisible(false)}
            />
          </Stack>
        );
      }
      if (helpUrl) {
        return (
          <StyledLink target="_blank" rel="noreferrer" href={helpUrl}>
            <label>{props.label}</label>
            <span className={getIconClassName("info")} style={{ marginLeft: 5 }} />
          </StyledLink>
        );
      }
      return <>{defaultRender && defaultRender(props)}</>;
    },
    [calloutContent, helpUrl, calloutIconId, _disabled, theme.palette.themePrimary]
  );

  return (
    <div>
      {children(onRenderLabel, _disabled)}

      {isCalloutVisible && calloutContent && (
        <Callout role="dialog" target={calloutTarget} directionalHint={DirectionalHint.rightCenter} gapSpace={4}>
          <div style={{ padding: 10 }}>{calloutContent}</div>
        </Callout>
      )}
    </div>
  );
}

export default FossilyticsFieldBase;
