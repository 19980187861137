import React, { CSSProperties, useRef } from "react";
import { ReactGrid, ReactGridProps } from "@silevis/reactgrid";
import { CustomCellTemplate } from "../CustomTable";

type ReactGridTableProps = {
  viewHeight?: number | string;
  minHeight?: number | string;
  viewWidth?: number | string;
  innerStyle?: CSSProperties;
  className?: string;
} & ReactGridProps;

const ReactGridTable = (props: ReactGridTableProps) => {
  const elRef = useRef<any>();

  return (
    <div
      className={`${props.className} noScrollbar`}
      style={{ position: "relative", height: props.viewHeight ?? 250, width: props.viewWidth ?? 315, minHeight: props.minHeight, zIndex: 99 }}
    >
      <div
        className={`${props.className} noScrollbar`}
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          overflowX: "scroll",
          overflowY: "scroll",
          ...props.innerStyle,
        }}
      >
        <ReactGrid ref={(ref) => (elRef.current = ref)} customCellTemplates={{ custom: new CustomCellTemplate() }} {...props} />
      </div>
    </div>
  );
};

export default ReactGridTable;
