import { fetchApi, GenericApiParam } from "@/utils/apiFetcher";
import { savedSpadDeclineAnalysis } from "@/constants/apiUrl";
import { GetSavedAnalysisQuery, SavedAnalysisResponse } from "./Analysis";

type GetSavedAnalysisPayload = {
  module: string;
} & Omit<GenericApiParam, "path">;

export const getSavedAnalysis = ({ module, queryStringParameters }: GetSavedAnalysisPayload) => {
  return fetchApi<SavedAnalysisResponse, null, GetSavedAnalysisQuery>({
    path: savedSpadDeclineAnalysis(module),
    queryStringParameters,
  });
};
