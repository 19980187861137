import { Checkbox, DefaultButton, Dialog, DialogFooter, PrimaryButton, Stack, TextField, Text } from "@fluentui/react";
import { deleteProjectDialogContentProps, editProjectDialogContentProps, modalProps, newProjectDialogContentProps } from "./model";
import { DialogEnum } from "../types";
import { useState } from "react";

interface ProjectDialogProps {
  selectedKey: string | undefined;
  copyFromSelected: boolean;
  setCopyFromSelected: (copy: boolean) => void;
  isLoading: boolean;
  createNewProject: (name: string) => Promise<void> | void;
  deleteProject: () => Promise<void> | void;
  editExistingProject: (name: string) => Promise<void> | void;

  activeDialog?: DialogEnum;
  setActiveDialog: (val: DialogEnum | undefined) => void;
}

const ProjectDialog = ({
  selectedKey,
  copyFromSelected,
  setCopyFromSelected,
  isLoading,
  createNewProject,
  deleteProject,
  editExistingProject,
  activeDialog,
  setActiveDialog,
}: ProjectDialogProps) => {
  const [name, setName] = useState("");

  return (
    <>
      <Dialog
        hidden={activeDialog !== DialogEnum.NEW_PROJECT}
        onDismiss={() => setActiveDialog(undefined)}
        dialogContentProps={newProjectDialogContentProps}
        modalProps={modalProps}
      >
        <Stack tokens={{ childrenGap: 15 }}>
          <TextField
            label="New project name"
            required
            placeholder="Enter a project name..."
            disabled={isLoading}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                createNewProject(name);
                setName("");
              }
            }}
            value={name}
            onChange={(_, v) => setName(v ?? "")}
          />
          {selectedKey && (
            <Checkbox
              label="Copy selected project"
              disabled={isLoading}
              checked={copyFromSelected}
              onChange={(_, v) => v !== undefined && setCopyFromSelected(v)}
            />
          )}
        </Stack>

        <DialogFooter>
          <PrimaryButton
            disabled={isLoading || !name}
            onClick={() => {
              createNewProject(name);
              setName("");
            }}
            text="Create"
          />
          <DefaultButton
            disabled={isLoading}
            onClick={() => {
              setActiveDialog(undefined);
              setName("");
            }}
            text="Close"
          />
        </DialogFooter>
      </Dialog>

      <Dialog
        hidden={activeDialog !== DialogEnum.EDIT_PROJECT}
        onDismiss={() => setActiveDialog(undefined)}
        dialogContentProps={editProjectDialogContentProps}
        modalProps={modalProps}
      >
        <TextField
          label="New project name"
          placeholder="Enter a project name..."
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              editExistingProject(name);
              setName("");
            }
          }}
          value={name}
          onChange={(_, v) => setName(v ?? "")}
        />
        <Text variant="tiny">Tip: Use the Enter key to save changes.</Text>

        <DialogFooter>
          <PrimaryButton
            disabled={!name}
            onClick={() => {
              editExistingProject(name);
              setName("");
            }}
            text="Save"
          />
          <DefaultButton onClick={() => setActiveDialog(undefined)} text="Close" />
        </DialogFooter>
      </Dialog>

      <Dialog
        hidden={activeDialog !== DialogEnum.DELETE_PROJECT}
        onDismiss={() => setActiveDialog(undefined)}
        dialogContentProps={deleteProjectDialogContentProps}
        modalProps={modalProps}
      >
        <DialogFooter>
          <DefaultButton disabled={isLoading} onClick={deleteProject}>
            Delete
          </DefaultButton>
          <PrimaryButton disabled={isLoading} onClick={() => setActiveDialog(undefined)}>
            Cancel
          </PrimaryButton>
        </DialogFooter>
      </Dialog>
    </>
  );
};

export default ProjectDialog;
