import { useEffect, useMemo, useRef, useState } from "react";
import { useQuery } from "@tanstack/react-query";

import { TahkCsgInputResponse, TahkCsgInputState, TahkCsgStateResponse, pollValidateTahkCsgInput } from "@/models/tahk";

import { ErrorValidationDetail } from "@/models/ErrorInputValidation";
import _ from "lodash";
import { ModuleIdentity } from "@/models/Generic";

type TahkCsgInputProps = {
  tabIndex: number;
  isLoading: boolean;
  setTahkCsgState: React.Dispatch<React.SetStateAction<TahkCsgStateResponse | null | undefined>>;
  setApiError: ({ message }: { message: string }) => void;
  tahkCsgState?: TahkCsgStateResponse | null;
  analysisIdentity?: ModuleIdentity;
};

const useTahkCsgInput = ({ tabIndex, isLoading, setTahkCsgState, setApiError, tahkCsgState, analysisIdentity }: TahkCsgInputProps) => {
  const [errorInputValidation, setErrorInputValidation] = useState<ErrorValidationDetail[]>([]);
  const [isUpdatedFromBE, setIsUpdatedFromBE] = useState(false);

  const lastInputRef = useRef<TahkCsgInputState>();
  const [calculationResult, setCalculationResult] = useState<TahkCsgInputResponse | null>();

  useEffect(() => {
    if (tahkCsgState?.inputs && !_.isEqual(tahkCsgState.inputs, lastInputRef.current)) {
      setErrorInputValidation([]);
      lastInputRef.current = { ...tahkCsgState?.inputs };
    }
  }, [tahkCsgState?.inputs]);

  const inputs = useMemo(() => {
    if (tahkCsgState?.inputs) {
      return tahkCsgState.inputs;
    }
    return undefined;
  }, [tahkCsgState?.inputs]);

  const analysis = useMemo(() => {
    return tahkCsgState?.analysis;
  }, [tahkCsgState?.analysis]);

  const { isLoading: isLoadingValidation, isFetching: isFetchingValidation } = useQuery({
    queryKey: ["validate-input-tahk-csg", inputs, analysis, analysisIdentity],
    queryFn: async () => {
      if (inputs && analysis && analysisIdentity && tahkCsgState?.dataview) {
        // we can return immediately last state and not fetch to api
        if (isUpdatedFromBE) {
          setIsUpdatedFromBE(false);
          return {
            data: {
              inputs: {
                input_options: inputs,
                analysis_options: analysis,
              },
              calculation: calculationResult,
            },
          };
        }
        return pollValidateTahkCsgInput({
          options: {
            input_options: inputs,
            analysis_options: analysis,
          },
          data_options: {
            analysis_identity: analysisIdentity,
            dataview: tahkCsgState?.dataview,
          },
        });
      }
      return {
        data: {
          inputs: {
            input_options: inputs,
            analysis_options: analysis,
          },
          calculation: calculationResult,
        },
      };
    },
    select(data: any) {
      if (data?.error) {
        // this block is to determine error validation on input
        const responseError: any = data?.error;
        if (responseError && responseError.code === 422 && responseError.detail?.length !== 0) {
          // parse error message and data, set to state
          setErrorInputValidation(responseError.detail as ErrorValidationDetail[]);
          setApiError({
            message: responseError.data.detail?.[0].msg ?? "",
          });
        }
        return null;
      } else if (errorInputValidation.length > 0) {
        setErrorInputValidation([]);
      }

      if (data?.data) {
        const inputData = data.data.inputs;
        const inputEqual = _.isEqual(inputs, inputData.input_options);
        const analysisEqual = _.isEqual(analysis, inputData.analysis_options);

        if (!analysisEqual || !inputEqual) {
          setTahkCsgState((prev) => {
            if (!prev) return prev;
            const newState = { ...prev };
            if (!analysisEqual) {
              newState.analysis = inputData.analysis_options ?? prev?.analysis;
            }
            if (!inputEqual) {
              newState.inputs = inputData.input_options ?? prev?.inputs;
            }
            return newState;
          });
          if (!inputEqual) setIsUpdatedFromBE(true);
        }
        if (!_.isEqual(data?.data.calculation, calculationResult)) setCalculationResult(data?.data.calculation);
      }
      return data?.data;
    },
    refetchOnWindowFocus: false,
    enabled: !!inputs && tabIndex === 1 && !!analysis && !!analysisIdentity && !!tahkCsgState?.dataview,
    // error handling
    throwOnError(error: any) {
      if (error && error.code === 422 && error.detail?.length !== 0 && !_.isEqual(errorInputValidation, error.detail)) {
        // parse error message and data, set to state
        setErrorInputValidation(error.detail as ErrorValidationDetail[]);
      } else {
        console.log(error);
      }
      return false;
    },
  });

  const loadingState = useMemo(() => {
    return isLoading || isFetchingValidation || isLoadingValidation;
  }, [isFetchingValidation, isLoading, isLoadingValidation]);

  const tahkCsgInputCalculation = useMemo(() => {
    return calculationResult;
  }, [calculationResult]);

  const layerError = useMemo(() => {
    if (errorInputValidation.length === 0) return [];
    return errorInputValidation.map((err) => err.msg);
  }, [errorInputValidation]);

  return {
    loadingState,
    errorInputValidation,
    tahkCsgInputCalculation,
    layerError,
  };
};

export default useTahkCsgInput;
