import { useEffect, useState } from "react";
import { DefaultButton, Dialog, DialogFooter, PrimaryButton } from "@fluentui/react";

import { modalProps, projectSettingsDialogContentProps } from "@/components/TreeView/Components/model";
import FossilyticsDateField from "@/components/fields/FossilyticsDateField";
import { useManualSettingState, useSettings } from "@/SettingsState";
import { convertDateToUtcTimeZoneIsoString } from "@/utils/dateTime";
import { DialogEnum } from "../types";
import { getIdFromActiveKey } from "../utils";
import { postSaveProjectSetting } from "@/models/settings";

interface ProjectSettingsDialogProps {
  setActiveDialog: (show: DialogEnum | undefined) => void;
  isLoading: boolean;
  selectedKey?: string;
}

const ProjectSettingsDialog = ({ setActiveDialog, isLoading, selectedKey }: ProjectSettingsDialogProps) => {
  const { projectId } = getIdFromActiveKey(selectedKey ?? "");

  const { setSetting } = useSettings();
  const [forecastStartDate] = useManualSettingState<string>({ key: "forecast_start_date", global: true, opts: {}, projectId });
  const [forecastEndDate] = useManualSettingState<string>({ key: "forecast_end_date", global: true, opts: {}, projectId });
  const [editForecastDate, setEditForecastDate] = useState(forecastStartDate);
  const [editForecastEndDate, setEditForecastEndDate] = useState(forecastEndDate);

  useEffect(() => {
    setEditForecastDate(forecastStartDate);
    setEditForecastEndDate(forecastEndDate);
  }, [forecastStartDate, forecastEndDate]);

  const handleStartDateChange = (v: Date | undefined) => {
    setEditForecastDate(convertDateToUtcTimeZoneIsoString(v));
  };

  const handleEndDateChange = (v: Date | undefined) => {
    setEditForecastEndDate(convertDateToUtcTimeZoneIsoString(v));
  };

  const handleSave = async () => {
    try {
      await postSaveProjectSetting({
        project_id: projectId,
        data: {
          forecast_start_date: editForecastDate ?? null,
          forecast_end_date: editForecastEndDate ?? null,
        },
      });
      setActiveDialog(undefined);
      setSetting({ projectId, key: "forecast_start_date", value: editForecastDate ? new Date(editForecastDate) : "" });
      setSetting({ projectId, key: "forecast_end_date", value: editForecastEndDate ? new Date(editForecastEndDate) : "" });
    } catch (error) {
      console.error("Error occurred while saving:", error);
    }
  };

  const onCancelDialog = () => {
    setActiveDialog(undefined);
    setEditForecastDate(forecastStartDate);
    setEditForecastEndDate(forecastEndDate);
  };

  return (
    <Dialog hidden={false} onDismiss={onCancelDialog} dialogContentProps={projectSettingsDialogContentProps} modalProps={modalProps}>
      <FossilyticsDateField
        label="Forecast start date"
        value={editForecastDate ? new Date(editForecastDate) : undefined}
        onChange={handleStartDateChange}
        maxDate={editForecastEndDate ? new Date(editForecastEndDate) : undefined}
      />

      <FossilyticsDateField
        label="Forecast end date"
        value={editForecastEndDate ? new Date(editForecastEndDate) : undefined}
        minDate={editForecastDate ? new Date(editForecastDate) : undefined}
        onChange={handleEndDateChange}
      />

      <DialogFooter>
        <PrimaryButton disabled={isLoading} onClick={() => void handleSave()}>
          Save
        </PrimaryButton>
        <DefaultButton disabled={isLoading} onClick={onCancelDialog}>
          Cancel
        </DefaultButton>
      </DialogFooter>
    </Dialog>
  );
};

export default ProjectSettingsDialog;
