import { IButtonProps } from "@fluentui/react";
import { EffectCallback, useCallback, useEffect, useState } from "react";
import _ from "lodash";
import { DateTime } from "luxon";

export const disabledHeaderButtonProps = {
  disabled: true,
  "aria-disabled": true,
  className: "is-disabled",
  styles: {
    root: { pointerEvents: "none", opacity: 0.5 },
  },
} as IButtonProps;

export function transpose(matrix: any[][]) {
  return matrix[0].map((col, i) => matrix.map((row) => row[i]));
}

/**
 * Retrieve the array key corresponding to the largest element in the array.
 *
 * @param {Array.<number>} array Input array
 * @return {number} Index of array element with the largest value
 */
export function argMax(array: number[]) {
  return array.map((x, i) => [x, i]).reduce((r, a) => (a[0] > r[0] ? a : r))[1];
}

/**
 * Retrieve the array key corresponding to the smallest element in the array.
 *
 * @param {Array.<number>} array Input array
 * @return {number} Index of array element with the smallest value
 */
export function argMin(array: number[]) {
  return array.map((x, i) => [x, i]).reduce((r, a) => (a[0] < r[0] ? a : r))[1];
}

export function mean(array: number[]): number {
  return array.reduce((a, b) => a + b, 0) / array.length;
}

const maximumSignificantDigits = 4;
const maximumFractionDigits = 3;
const numberFormat = new Intl.NumberFormat(navigator.language, { maximumSignificantDigits });
const numberFormatExp = new Intl.NumberFormat(navigator.language, { maximumFractionDigits, notation: "scientific" });

export function getBestNumberFormat(n?: number): Intl.NumberFormat {
  if (n === undefined || numberFormat.format(n).length < 7) return numberFormat;
  return numberFormatExp;
}

export function formatNumber(n?: number): string {
  if (n === undefined) return "";
  return getBestNumberFormat(n).format(n);
}

export function formatNewNumber(n?: number): string {
  if (n === undefined || n === null) return "N/A";
  return getBestNumberFormat(n).format(n);
}
export function formatParseNumber(n?: number): number {
  // TODO: Make this work for international locales
  return parseFloat(formatNumber(n).replace(",", ""));
}

export function formatDecimalTwo(n: number) {
  return (Math.round(n * 100) / 100).toFixed(2);
}

const a = document.createElement("a");
document.body.appendChild(a);
a.style.display = "none";

export function saveBlob(blob: Blob, fileName: string) {
  const url = window.URL.createObjectURL(blob);
  a.href = url;
  a.download = fileName;
  a.click();
  window.URL.revokeObjectURL(url);
}

// React

export const DEBOUNCE_DELAY = 1000; // ms

export const useDebouncedEffect = (callback: EffectCallback, dependencyArr: any[], timeout = 2000, options = { trailing: true, leading: false }) => {
  const { leading, trailing } = options;
  // the source of truth will be _dependencyRef.current  always
  const [_dependency, _setDependency] = useState(dependencyArr);

  // making use of second approach here we discussed above
  const makeChangeToDependency = useCallback(
    (dependency: any[]) => {
      _.debounce(() => _setDependency(dependency), timeout, { trailing, leading });
    },
    [trailing, leading, timeout]
  );

  useEffect(() => {
    if (dependencyArr) {
      makeChangeToDependency(dependencyArr);
    }
  }, [dependencyArr, makeChangeToDependency]);

  useEffect(callback, [..._dependency, callback]);
};

export function useDebounce<T>(value: T, delay: number): T {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    const timeHandler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(timeHandler);
    };
  }, [value, delay]);
  return debouncedValue;
}

export interface ProjectDataSet {
  projectId: string;
  dataSetIds: string[];
  groupId?: string;
}
export const getProjectDatasetKey = (projectId: string, datasetIds: string[]) =>
  `${projectId}-${datasetIds.sort((firstItem, secondItem) => firstItem.localeCompare(secondItem)).join("|")}`;

export const getProjectGroupDatasetKey = (projectId: string, groupId: string, datasetIds: string[]) => {
  const sortedDatasetIds = [...datasetIds].sort((firstItem, secondItem) => firstItem.localeCompare(secondItem));
  return `${projectId};${groupId};${sortedDatasetIds.join("|")}`;
};

export const parseProjectDatasetKey = (key: string): ProjectDataSet => {
  const split = key.split("-");
  const projectId = split.shift() ?? "";
  const dataSetIds = split.join("-").split(",");
  return { projectId, dataSetIds };
};
export const parseProjectGroupDatasetKey = (key: string): ProjectDataSet => {
  const split = key.split(";");
  const projectId = split.shift() ?? "";
  const groupId = split.shift() ?? "";
  const dataSetIds = split.join(";").split("|");
  return { projectId, groupId, dataSetIds };
};

export const deepEqual = function (x: any, y: any) {
  if (x === y) {
    return true;
  } else if (typeof x == "object" && x != null && typeof y == "object" && y != null) {
    if (Object.keys(x).length !== Object.keys(y).length) return false;

    for (const prop in x) {
      if (y.hasOwnProperty(prop)) {
        if (!deepEqual(x[prop], y[prop])) return false;
      } else {
        return false;
      }
    }

    return true;
  } else {
    return false;
  }
};

export const dateStringDatesMatch = (a?: string, b?: string) => {
  if (a == null && b == null) {
    return true;
  } else if (a == null || b == null) {
    return false;
  }

  const dateTimeA = DateTime.fromISO(a);
  const dateTimeB = DateTime.fromISO(b);
  return dateTimeA.hasSame(dateTimeB, "day");
};

export const randomColor = (i: number) => {
  const r = () => Math.floor(256 * Math.random());
  const rgb = [r(), r(), r()];
  return `rgb(${rgb[0] - 50}, ${rgb[1] - 50}, ${rgb[2] - 50})`;
};

export const handleKeyDown = (event: KeyboardEvent): any => {
  if (event.key === "0") {
    event.preventDefault();
  }
};

export const getDateFromString = (str: string) => {
  const dateObj = new Date(str);
  return dateObj;
};

export const formatReactGridCell = (value: any) => {
  if (typeof value === "number") {
    return {
      type: "number",
      value: value,
      nonEditable: true,
      hideZero: false,
      style: { display: "flex", justifyContent: "center", zIndex: 0 },
    };
  } else if (typeof value === "string") {
    return {
      type: "text",
      text: value,
      style: { display: "flex", justifyContent: "center", zIndex: 0 },
    };
  } else {
    return {
      type: "text",
      text: "",
    };
  }
};
