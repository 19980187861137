import { FossilyticsChartAxis, FossilyticsChartSeries } from "@/components/FossilyticsChart";
import dictionary from "@/constants/dictionary";
import { getRandomColor } from "@/utils/general";
import { useTheme } from "@mui/material/styles";
import { useMemo } from "react";

type UseChartProps = {
  xAxis: Axis;
  yAxis: Axis;
  layers: Layer[];
  chartType: string;
};

export type Layer = {
  [chartType: string]: {
    [key: string]: number[] | any;
  };
};

type Axis = {
  // key to access data set
  key: string;
  // label to show in chart
  labelKey: string;
  color?: string;
};

const safeDictionary = dictionary.tahk as { [key: string]: string };
const safeDictionaryUnit = dictionary.tahkUnits as { [key: string]: string };

const useLayeredChart = ({ xAxis, yAxis, layers = [], chartType }: UseChartProps) => {
  const { palette } = useTheme();

  const parsedXAxis = useMemo<FossilyticsChartAxis[]>(
    () => [
      {
        name: `${safeDictionary[xAxis.labelKey]} ${safeDictionaryUnit[xAxis.labelKey]}`,
        type: "value",
        color: xAxis.color ?? palette.text.primary,
      },
    ],
    [palette.text.primary, xAxis.color, xAxis.labelKey]
  );

  const parsedYAxis = useMemo<FossilyticsChartAxis[]>(
    () => [
      {
        name: `${safeDictionary[yAxis.labelKey]} ${safeDictionaryUnit[yAxis.labelKey]}`,
        type: "value",
        color: yAxis.color ?? palette.text.primary,
      },
    ],
    [palette.text.primary, yAxis.color, yAxis.labelKey]
  );

  const series = useMemo<FossilyticsChartSeries[]>(() => {
    return layers.map((layer, index) => {
      return {
        name: `${dictionary.tahk.layer} ${index + 1}`,
        type: "line",
        color: getRandomColor(),
        z: index + 1,
        data: layer[chartType]
          ? layer[chartType][String(xAxis.key)].map((x: number, y: string | number) => [x, layer[chartType][String(yAxis.key)][y]])
          : [],
        hideSymbol: true,
        lineWidth: 1,
      };
    });
  }, [layers, xAxis.key, yAxis.key, chartType]);

  return { xAxis: parsedXAxis, yAxis: parsedYAxis, series };
};

export default useLayeredChart;
