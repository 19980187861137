import CustomTable from "@/components/CustomTable";
import useSpadDeclineParameters, { UseSpadDeclineParametersProps } from "../../hooks/useSpadDeclineParameters";

const ParameterCase = (props: UseSpadDeclineParametersProps) => {
  const { columns, onCellsChanged, rows } = useSpadDeclineParameters(props);
  return (
    <div style={{ height: 200, marginTop: 5, flexDirection: "column", width: "100%", position: "relative" }} className="center-flex">
      <CustomTable
        style={{
          display: "flex",
          width: "100%",
          position: "absolute",
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          overflowX: "scroll",
          overflowY: "scroll",
        }}
        columns={columns}
        rows={rows}
        enableRangeSelection
        enableColumnSelection
        stickyTopRows={2}
        onCellsChanged={onCellsChanged}
        isLoading={false}
      />
    </div>
  );
};

export default ParameterCase;
