import React, { useMemo } from "react";
import { Dropdown, IDropdownOption } from "@fluentui/react";

import FossilyticsNumberField from "@/components/fields/FossilyticsNumberField";
import Tabs from "@/components/Tabs";
import CustomDataTable from "@/components/CustomTable";
import FossilyticsChart from "@/components/FossilyticsChart";

import useGAZAutoRtaPvtDataTable from "../../hooks/rtaInputGrid/useGAZAutoRtaPvtDataTable";
import useGazAutoRtaWaterPvtCharts from "../../hooks/rtaInputGrid/useGazAutoRtaWaterPvtCharts";

import { waterCompressibilityOptions, waterViscMethodOptions } from "../../constants";

import "../../ModuleGasAutoRTA.css";

import { InputGridCalculation, GazAutoRtaInputGrid, GazAutoRta, WaterPvt } from "@/models/gaz/autoRta";

interface WaterPvtParamProps {
  value: GazAutoRtaInputGrid;
  calculateValue: InputGridCalculation;
  isLoading: boolean;
  onChange: React.Dispatch<React.SetStateAction<GazAutoRta | null>>;
  updateWaterPvtSelection: (v: IDropdownOption | undefined, key: keyof WaterPvt) => void;
}

const WaterPvtContainer = ({ value, calculateValue, isLoading, onChange, updateWaterPvtSelection }: WaterPvtParamProps) => {
  const { waterPvtMu, waterPvtPw, waterPvtBw, waterPvtcw } = useGazAutoRtaWaterPvtCharts(calculateValue);
  const { waterPvtDataTableRows, waterPvtDataTableCols } = useGAZAutoRtaPvtDataTable(calculateValue);

  const tabList = useMemo(() => {
    return [
      {
        label: (
          <span>
            μ<sub>{"w"}</sub>
          </span>
        ),
        key: "μw",
        content: (
          <div className="chartWrapper">
            <FossilyticsChart
              id="water_pvt_mw"
              isLoading={isLoading}
              xAxes={waterPvtMu.xAxes}
              yAxes={waterPvtMu.yAxes}
              series={waterPvtMu.series}
              hideLegend
            />
          </div>
        ),
      },
      {
        label: (
          <span>
            p<sub>{"w"}</sub>
          </span>
        ),
        key: "pw",
        content: (
          <div className="chartWrapper">
            <FossilyticsChart
              id="water_pvt_pw"
              isLoading={isLoading}
              xAxes={waterPvtPw.xAxes}
              yAxes={waterPvtPw.yAxes}
              series={waterPvtPw.series}
              hideLegend
            />
          </div>
        ),
      },
      {
        label: (
          <span>
            B<sub>{"w"}</sub>
          </span>
        ),
        key: "Bw",
        content: (
          <div className="chartWrapper">
            <FossilyticsChart
              id="water_pvt_bw"
              isLoading={false}
              xAxes={waterPvtBw.xAxes}
              yAxes={waterPvtBw.yAxes}
              series={waterPvtBw.series}
              hideLegend
            />
          </div>
        ),
      },
      {
        label: (
          <span>
            c<sub>{"w"}</sub>
          </span>
        ),
        key: "cw",
        content: (
          <div className="chartWrapper">
            <FossilyticsChart
              id="water_pvt_cw"
              isLoading={false}
              xAxes={waterPvtcw.xAxes}
              yAxes={waterPvtcw.yAxes}
              series={waterPvtcw.series}
              hideLegend
            />
          </div>
        ),
      },
      {
        label: <span>Data table</span>,
        key: "Data table",
        content: (
          <div className="chartWrapper">
            <CustomDataTable rows={waterPvtDataTableRows} columns={waterPvtDataTableCols} isLoading={isLoading} />
          </div>
        ),
      },
    ];
  }, [
    isLoading,
    waterPvtBw.series,
    waterPvtBw.xAxes,
    waterPvtBw.yAxes,
    waterPvtDataTableCols,
    waterPvtDataTableRows,
    waterPvtMu.series,
    waterPvtMu.xAxes,
    waterPvtMu.yAxes,
    waterPvtPw.series,
    waterPvtPw.xAxes,
    waterPvtPw.yAxes,
    waterPvtcw.series,
    waterPvtcw.xAxes,
    waterPvtcw.yAxes,
  ]);

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "1fr 2.8fr",
      }}
    >
      <div
        style={{
          minWidth: 180,
          maxWidth: 297,
        }}
      >
        <FossilyticsNumberField
          label="Salinity"
          suffix="ppm"
          debounceDelay={1000}
          value={value.water_pvt.salinity}
          disabled={isLoading}
          onChange={(v) => {
            if (v !== undefined) {
              onChange((prev) =>
                prev
                  ? {
                      ...prev,
                      inputs: {
                        ...prev.inputs,
                        water_pvt: {
                          ...prev.inputs.water_pvt,
                          salinity: v,
                        },
                      },
                    }
                  : null
              );
            }
          }}
        />
        <Dropdown
          label="Water viscosity correlation"
          options={waterViscMethodOptions}
          selectedKey={value.water_pvt.selected_water_viscosity_correlation}
          disabled={isLoading}
          onChange={(_, v) => updateWaterPvtSelection(v, "selected_water_viscosity_correlation")}
        />
        <Dropdown
          label="Water compressibility"
          options={waterCompressibilityOptions}
          selectedKey={value.water_pvt.selected_water_compressibility_correlation}
          disabled={isLoading}
          onChange={(_, v) => updateWaterPvtSelection(v, "selected_water_compressibility_correlation")}
        />
      </div>
      <Tabs tabList={tabList} centered />
    </div>
  );
};

export default WaterPvtContainer;
