import { useCallback, useEffect, useMemo, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useTheme } from "@mui/material/styles";

import { TahkCsgStateResponse, pollCalculateTahkCsgForecast, ForecastEvent, TahkAnalysisCurveModel, TahkAnalysisCurveData } from "@/models/tahk";
import { ModuleIdentity } from "@/models/Generic";

import { ErrorValidationDetail } from "@/models/ErrorInputValidation";
import { cumulativeSeriesKey, dataSeriesKey, flowingPressureForecastHeader, seriesKey } from "../../constants/grid";
import { camelToSnakeCase, formatToScientific } from "@/utils/general";
import { CellChange, DateCell, NumberCell, Row } from "@silevis/reactgrid";
import dictionary from "@/constants/dictionary";
import { FossilyticsChartSeries } from "@/components/FossilyticsChart";
import { dataTableNotation, getDataTableHeader } from "../../utils";
import _ from "lodash";
import { CsvData } from "@/features/app/app.types";
import { transformRowToString } from "@/utils/csvProcessing";
import { convertDateToUtcTimeZoneIsoString } from "@/utils/dateTime";

export type TahkCsgForecastProps = {
  tahkCsgState?: TahkCsgStateResponse | null;
  tabIndex: number;
  isLoading: boolean;
  setTahkCsgState: React.Dispatch<React.SetStateAction<TahkCsgStateResponse | null | undefined>>;
  analysisIdentity?: ModuleIdentity;
  setCsvData: (csvData?: CsvData[]) => void;
};

const safeTahkDictionary: {
  [key: string]: string;
} = dictionary.tahk;

const useTahkCsgForecast = ({ setCsvData, tahkCsgState, isLoading, tabIndex, setTahkCsgState, analysisIdentity }: TahkCsgForecastProps) => {
  const { palette } = useTheme();

  const [errorInputValidation, setErrorInputValidation] = useState<ErrorValidationDetail[]>([]);
  const [selectedDataTableLayer, setSelectedDataTableLayer] = useState(0);

  const inputs = tahkCsgState?.inputs;

  // make sure the whole flowign table is filled before calculating
  const canCalculateForecast = useMemo(() => {
    if (!tahkCsgState?.forecast) return false;
    let status = tahkCsgState.forecast.length > 0;
    for (let index = 0; index < tahkCsgState?.forecast.length; index++) {
      const item = tahkCsgState?.forecast[index];
      if (Object.values(item).includes(0)) {
        status = false;
        break;
      }
    }

    return status;
  }, [tahkCsgState?.forecast]);

  const {
    isLoading: isLoadingCalculation,
    isFetching: isFetchingCalculation,
    data,
  } = useQuery({
    queryKey: ["calculate-forecast-tahk-csg", inputs, tahkCsgState?.dataview, analysisIdentity, tahkCsgState?.forecast],
    queryFn: async () => {
      if (inputs && tahkCsgState.dataview && analysisIdentity) {
        return pollCalculateTahkCsgForecast({
          data_options: {
            analysis_identity: analysisIdentity,
            dataview: tahkCsgState.dataview,
          },
          options: {
            inputs,
            flowing_schedule: tahkCsgState.forecast,
          },
        });
      }
    },
    select(data) {
      if (data?.error) {
        // this block is to determine error validation on input
        const responseError: any = data?.error;
        if (responseError && responseError.status === 422 && responseError.data.detail?.length !== 0) {
          // parse error message and data, set to state
          setErrorInputValidation(responseError.data.detail as ErrorValidationDetail[]);
        } else {
          console.log(data?.error);
        }
      } else if (errorInputValidation.length > 0) {
        setErrorInputValidation([]);
      }

      return data?.data;
    },
    refetchOnWindowFocus: false,
    enabled: !!inputs && tabIndex === 3 && !!analysisIdentity && !!tahkCsgState.dataview && !!canCalculateForecast,
    // error handling
    throwOnError(error: any) {
      if (error && error.code === 422 && error.detail?.length !== 0 && !_.isEqual(errorInputValidation, error.detail)) {
        // parse error message and data, set to state
        setErrorInputValidation(error.detail as ErrorValidationDetail[]);
      } else {
        console.log(error);
      }
      return false;
    },
  });

  const dataTableLayerOption = useMemo(() => {
    return Array.from(Array(data?.data_table?.length).keys()).map((_, index) => {
      return {
        key: index,
        text: `${dictionary.tahk.layer} ${index + 1}`,
      };
    });
  }, [data?.data_table?.length]);

  const flowingPressureCol = Object.keys(flowingPressureForecastHeader).map((columnId, index) => {
    return { columnId: camelToSnakeCase(columnId), width: index === 0 ? 70 : 130 };
  });

  const flowingPressureRow: Row<any>[] = useMemo(() => {
    if (!inputs || !tahkCsgState.forecast) return [];
    const concatedRow = [
      ...tahkCsgState.forecast,
      ...Array.from(Array(100).keys()).map(() => ({
        date: 0,
        pressure: 0,
        rate: 0,
      })),
    ];

    return [
      {
        rowId: "header",
        cells: Object.values(flowingPressureForecastHeader).map((header) => {
          return {
            type: "header",
            text: header,
          };
        }),
        height: 50,
      },
      ...concatedRow.map((row, index) => {
        const background = index === 0 ? "#edebe9" : palette.primary.light;

        return {
          rowId: index,
          cells: [
            {
              type: "date",
              date: row.date ? new Date(row.date) : undefined,
              style: {
                background,
              },
              hideZero: true,
            },
            {
              type: "number",
              value: row.pressure,
              hideZero: true,
            },
            {
              type: "number",
              value: row.rate,
              hideZero: true,
            },
          ],
        };
      }),
    ];
  }, [inputs, palette.primary.light, tahkCsgState?.forecast]);

  const handleFlowingPressureCellChange = useCallback(
    (changes: CellChange[]) => {
      if (!inputs) return;
      const newFlowingPressure = [...tahkCsgState.forecast] as ForecastEvent[];

      changes.forEach((change) => {
        const numberCell = change.newCell as NumberCell;
        const idx = change.rowId as number;
        const colIdx = change.columnId as keyof ForecastEvent;

        if (!newFlowingPressure[idx]) {
          newFlowingPressure[idx] = {
            date: undefined,
            pressure: 0,
            rate: 0,
          };
        }
        if (colIdx === "date") {
          const dateCell = change.newCell as DateCell;
          newFlowingPressure[idx][colIdx] = convertDateToUtcTimeZoneIsoString(new Date(dateCell.date ?? 0));
        } else if (isNaN(numberCell.value) || Number(numberCell.value) < 0) newFlowingPressure[idx][colIdx] = -1;
        else if (numberCell.value === 0) newFlowingPressure[idx][colIdx] = 0;
        else newFlowingPressure[idx][colIdx] = numberCell.value;
      });

      setTahkCsgState((prev) => {
        if (!prev) return prev;

        return {
          ...prev,
          forecast: newFlowingPressure,
        };
      });
    },
    [inputs, setTahkCsgState, tahkCsgState?.forecast]
  );

  const chartXAxes = useMemo(() => {
    return [{ name: dictionary.tahk.days, type: "time", color: palette.grey[900] }];
  }, [palette.grey]);

  const chartYAxes = useMemo(() => {
    return [
      {
        name: `${dictionary.tahk.pressure} ${dictionary.tahkUnits.pressure}`,
        type: "value",
        color: palette.grey[900],
        min: 0,
        position: "left",
        offset: 80,
        nameGap: 30,
      },
      {
        name: `${dictionary.tahk.gasRate} ${dictionary.tahkUnits.gasRate}`,
        type: "value",
        color: palette.error.main,
        min: 0,
        position: "left",
        offset: 0,
        nameGap: 30,
      },
      {
        name: `${dictionary.tahk.waterRate} ${dictionary.tahkUnits.waterRate}`,
        type: "value",
        color: palette.info.main,
        min: 0,
        position: "right",
        offset: 0,
        nameGap: 50,
      },
    ];
  }, [palette.error.main, palette.grey, palette.info.main]);

  const cumulativeChartYAxes = useMemo(() => {
    return [
      {
        name: `${dictionary.tahk.gasRate} ${dictionary.tahkUnits.gasRate}`,
        type: "value",
        color: palette.error.main,
        min: 0,
        position: "left",
        offset: 0,
        nameGap: 30,
      },
      {
        name: `${dictionary.tahk.waterRate} ${dictionary.tahkUnits.waterRate}`,
        type: "value",
        color: palette.info.main,
        min: 0,
        position: "right",
        offset: 0,
        nameGap: 50,
      },
    ];
  }, [palette.error.main, palette.info.main]);

  const getSeriesColor = useCallback(
    (key: string) => {
      if (key.toLowerCase().includes("gas")) return palette.error.main;
      if (key.toLowerCase().includes("water")) return palette.info.main;

      return palette.common.black;
    },
    [palette]
  );

  const getYAxisIndex = (key: string) => {
    if (key.toLowerCase().includes("gas")) return 1;
    if (key.toLowerCase().includes("water")) return 2;
    return 0;
  };

  const series = useMemo(() => {
    if (!data) return [];
    const curveModel: FossilyticsChartSeries[] = [];
    seriesKey.forEach((key) => {
      if (data.tahk_analysis_curve_model[camelToSnakeCase(key) as keyof TahkAnalysisCurveModel]) {
        curveModel.push({
          name: `${dictionary.tahk.model} ${safeTahkDictionary[key]}`,
          type: "line",
          color: getSeriesColor(key),
          data: data.tahk_analysis_curve_model?.dates
            ? data.tahk_analysis_curve_model?.dates?.map((d, i) => [
                d,
                data.tahk_analysis_curve_model?.[camelToSnakeCase(key) as keyof TahkAnalysisCurveModel]?.[i] ?? 0,
              ])
            : [],
          yAxisIndex: getYAxisIndex(key),
          hideSymbol: true,
          lineWidth: 2,
        });
      }
    });
    const curveData: FossilyticsChartSeries[] = [];
    dataSeriesKey.forEach((key) => {
      if (data.tahk_analysis_curve_data[camelToSnakeCase(key) as keyof TahkAnalysisCurveData]) {
        curveModel.push({
          name: `${dictionary.tahk.data} ${safeTahkDictionary[key]}`,
          type: "line",
          color: getSeriesColor(key),
          data: data.tahk_analysis_curve_data?.dates
            ? data.tahk_analysis_curve_data?.dates?.map((d, i) => [
                d,
                data.tahk_analysis_curve_data?.[camelToSnakeCase(key) as keyof TahkAnalysisCurveData]?.[i] ?? 0,
              ])
            : [],
          yAxisIndex: getYAxisIndex(key),
        });
      }
    });
    return [...curveModel, ...curveData];
  }, [data, getSeriesColor]);

  const cumulativeSeries = useMemo(() => {
    if (!data) return [];
    const curveModel: FossilyticsChartSeries[] = [];
    cumulativeSeriesKey.forEach((key) => {
      if (data.tahk_analysis_curve_model[camelToSnakeCase(key) as keyof TahkAnalysisCurveModel]) {
        curveModel.push({
          name: `${dictionary.tahk.model} ${safeTahkDictionary[key]}`,
          type: "line",
          color: getSeriesColor(key),
          data: data.tahk_analysis_curve_model?.dates
            ? data.tahk_analysis_curve_model?.dates?.map((d, i) => [
                d,
                data.tahk_analysis_curve_model?.[camelToSnakeCase(key) as keyof TahkAnalysisCurveModel]?.[i] ?? 0,
              ])
            : [],
          yAxisIndex: getYAxisIndex(key) - 1,
          hideSymbol: true,
          lineWidth: 2,
        });
      }
    });
    const curveData: FossilyticsChartSeries[] = [];
    cumulativeSeriesKey.forEach((key) => {
      if (data.tahk_analysis_curve_data[camelToSnakeCase(key) as keyof TahkAnalysisCurveData]) {
        curveModel.push({
          name: `${dictionary.tahk.data} ${safeTahkDictionary[key]}`,
          type: "line",
          color: getSeriesColor(key),
          data: data.tahk_analysis_curve_data?.dates
            ? data.tahk_analysis_curve_data?.dates?.map((d, i) => [
                d,
                data.tahk_analysis_curve_data?.[camelToSnakeCase(key) as keyof TahkAnalysisCurveData]?.[i] ?? 0,
              ])
            : [],
          yAxisIndex: getYAxisIndex(key) - 1,
        });
      }
    });
    return [...curveModel, ...curveData];
  }, [data, getSeriesColor]);

  const dataTableRows: Row<any>[] = useMemo(() => {
    if (!data) return [];
    return [
      getDataTableHeader(dictionary.tahkDataTable),
      dataTableNotation(),
      ...data.data_table[selectedDataTableLayer].cumulative_gas_production.map((_, index) => {
        const safeParam = data.data_table[selectedDataTableLayer] as { [key: string]: any };
        return {
          rowId: index,
          cells: Object.keys(dictionary.tahkDataTable).map((key) => {
            const val = safeParam?.[camelToSnakeCase(key)][index] ?? "-";
            return {
              type: "text",
              text: formatToScientific(val),
              style: { display: "flex", justifyContent: "center", zIndex: 0 },
            };
          }),
        };
      }),
    ];
  }, [data, selectedDataTableLayer]);

  const flowingInputError = useMemo(() => {
    if (errorInputValidation.length > 0) {
      const flowingErr = errorInputValidation.find((err) => err.loc.includes("flowing_schedule"));

      if (flowingErr) return flowingErr.msg;
    }
    return "";
  }, [errorInputValidation]);

  const loadingStateAll = isLoading || isLoadingCalculation || isFetchingCalculation;
  const transformDataTableToCsv = useCallback(() => {
    if (!analysisIdentity) return;
    const { data_set_ids } = analysisIdentity;

    const tableCsv = {
      fileName: `${data_set_ids.join(", ")} Analysis Tahk Table.csv`,
      data: transformRowToString(dataTableRows),
      isTableCsv: true,
    };
    setCsvData([tableCsv]);
  }, [analysisIdentity, dataTableRows, setCsvData]);

  useEffect(() => {
    if (analysisIdentity && !loadingStateAll && dataTableRows.length > 0) {
      transformDataTableToCsv();
    }
  }, [analysisIdentity, dataTableRows.length, loadingStateAll, transformDataTableToCsv]);

  return {
    loadingState: loadingStateAll,
    handleFlowingPressureCellChange,
    flowingPressureRow,
    flowingPressureCol,
    chartYAxes,
    chartXAxes,
    series,
    dataTableLayerOption,
    selectedDataTableLayer,
    setSelectedDataTableLayer,
    dataTableRows,
    cumulativeSeries,
    cumulativeChartYAxes,
    data,
    flowingInputError,
  };
};

export default useTahkCsgForecast;
