import { DataSet } from "@/model";
import { spadParameterResultTableColumn, tableHeaderNotationStyle, tableHeaderStyle, tableRowStyle, tableTitleHeaderStyle } from "../../constants";
import { ModuleSpadDeclineArps, ModuleSpadDeclineCaseParams } from "@/features/modules/spad/decline";

interface ParameterTableProps {
  arpsShort: ModuleSpadDeclineArps;
  arpsLong: ModuleSpadDeclineArps;
  parametersShort?: ModuleSpadDeclineCaseParams;
  parametersLong?: ModuleSpadDeclineCaseParams;
  unit: string;
  dataSets: DataSet[];
}

const useParameterTable = ({ arpsLong, arpsShort, parametersLong, parametersShort, unit, dataSets }: ParameterTableProps) => {
  const tableRows = [
    [
      "OPS Low",
      parametersShort?.low?.qi,
      parametersShort?.low?.m,
      parametersShort?.low?.b,
      arpsShort?.low?.forecastStartRate,
      arpsShort?.low?.forecastStartDeclineRate,
    ],
    [
      "OPS Mid",
      parametersShort?.mid?.qi,
      parametersShort?.mid?.m,
      parametersShort?.mid?.b,
      arpsShort?.mid?.forecastStartRate,
      arpsShort?.mid?.forecastStartDeclineRate,
    ],
    [
      "OPS High",
      parametersShort?.high?.qi,
      parametersShort?.high?.m,
      parametersShort?.high?.b,
      arpsShort?.high?.forecastStartRate,
      arpsShort?.high?.forecastStartDeclineRate,
    ],
    [
      "Profile Low",
      parametersLong?.low?.qi,
      parametersLong?.low?.m,
      parametersLong?.low?.b,
      arpsLong?.low?.forecastStartRate,
      arpsLong?.low?.forecastStartDeclineRate,
    ],
    [
      "Profile Mid",
      parametersLong?.mid?.qi,
      parametersLong?.mid?.m,
      parametersLong?.mid?.b,
      arpsLong?.mid?.forecastStartRate,
      arpsLong?.mid?.forecastStartDeclineRate,
    ],
    [
      "Profile High",
      parametersLong?.high?.qi,
      parametersLong?.high?.m,
      parametersLong?.high?.b,
      arpsLong?.high?.forecastStartRate,
      arpsLong?.high?.forecastStartDeclineRate,
    ],
  ];

  const tableHeader = [
    {
      text: "Case",
      type: "text",
      style: tableHeaderStyle,
    },
    { text: `Initial rate`, type: "text", style: tableHeaderStyle },
    { text: `Initial Decline rate `, type: "text", style: tableHeaderStyle },
    { text: `Arps exponent`, type: "text", style: tableHeaderStyle },
    { text: `Forecast start rate `, type: "text", style: tableHeaderStyle },
    { text: `Forecast start Decline rate `, type: "text", style: tableHeaderStyle },
  ];
  const tableHeaderNotation = [
    {
      text: "",
      type: "text",
      style: tableHeaderNotationStyle,
    },
    { text: `${unit}/D`, type: "text", style: tableHeaderNotationStyle },
    { text: `1/D`, type: "text", style: tableHeaderNotationStyle },
    { text: ``, type: "text", style: tableHeaderNotationStyle },
    { text: `${unit}/D`, type: "text", style: tableHeaderNotationStyle },
    { text: `1/D`, type: "text", style: tableHeaderNotationStyle },
  ];

  const parameterTableRows = [
    {
      rowId: "table-name",
      cells: [{ type: "header", colspan: 6, text: "Parameter Result Table", style: tableTitleHeaderStyle }],
    },
    {
      rowId: "header",
      cells: tableHeader,
    },
    {
      rowId: "notation",
      cells: tableHeaderNotation,
    },
    ...tableRows.map((row, indexRow) => {
      return {
        rowId: indexRow,
        cells: row.map((cell, cellIndex) => {
          if (cellIndex === 0)
            return {
              type: "text",
              text: cell,
              nonEditable: true,
              style: tableRowStyle,
            };
          const isExponent = Number(cell) > 1000 || Number(cell) < 0.01;

          return {
            type: "text",
            text: isExponent ? `${Number(cell).toExponential(2)} ` : String(Number(cell).toPrecision(3)),
            nonEditable: true,
            style: tableRowStyle,
          };
        }),
      };
    }),
  ];

  const parameterTableCsv = {
    fileName: `${dataSets.map((data) => data.name).join(", ")} Parameter Table.csv`,
    data: [
      //header
      tableHeader
        .map((header, index) => {
          return `${header.text} ${tableHeaderNotation[index].text}`;
        })
        .join(","),
      // row:
      ...tableRows.map((row) => row.join(",")),
    ].join("\n"),
    isTableCsv: true,
  };
  return {
    parameterTableRows,
    spadParameterResultTableColumn,
    parameterTableCsv,
  };
};

export default useParameterTable;
