import { useEffect, useCallback, useState } from "react";
import { shallow } from "zustand/shallow";
import { Stack } from "@fluentui/react";
import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";

import { useAppStore } from "./features/app";

import Layout from "./Layout";
import Modules from "./components/Modules";
import ModuleSpadWor from "./modules/spad/wor/ModuleSpadWor";
import ModuleGazFmb from "./modules/gaz/fmb/ModuleGazFmb";
import ModuleGazPz from "./modules/gaz/pz/ModuleGazPz";
import ModuleTahkForecastGas from "./modules/tahk/forecast/gasoil/ModuleTahkForecastGas";
import ModuleKoldunMcsimGas from "./modules/koldun/mcsim/gasoil/ModuleKoldunMcsimGasOilContent/ModuleKoldunMcsimGas";
import ModuleGildroHydrate from "./modules/gildro/hydrate/ModuleGildroHydrate";
import NotFound from "./NotFound";
import ModuleTahkForecastOil from "./modules/tahk/forecast/gasoil/ModuleTahkForecastOil";
import ModuleTahkCsg from "./pages/modules/tahk/forecast/csg";
import ModuleRulonGroupRun from "./pages/modules/rulon/groupRun";

import ModuleKoldunMcsimCsg from "./modules/koldun/mcsim/csg/csg/ModuleKoldunMcsimCsg";
import Header from "./components/Header";
import ModuleRollup from "./modules/rollup/ModuleRollup";
import ModuleKoldunMcsimOil from "./modules/koldun/mcsim/gasoil/ModuleKoldunMcsimGasOilContent/ModuleKoldunMcsimOil";

import { SettingsPage } from "@/pages/settings";
import { ModuleSpadDeclineOilPage } from "@/pages/modules/spad/decline/oil";
import { ModuleSpadDeclineGasPage } from "@/pages/modules/spad/decline/gas";
import { ModuleSpadDeclineOilPage as ModuleSpadDeclineOilPageV2 } from "@/pages/modules/spad/declineV2/oil";
import { ModuleSpadDeclineGasPage as ModuleSpadDeclineGasPageV2 } from "@/pages/modules/spad/declineV2/gas";
import ModuleGasAutoRTA from "./modules/gaz/gasautoRTA/ModuleGasAutoRTA";
import ImportWellsPage from "@/features/import/wells";
import ImportProjectsPage from "@/features/import/projects";

// components
import LoadingBlocker from "./components/LoadingBlocker/LoadingBlocker";

// route url
import routeUrlMap from "./constants/routeUrl";
import { clearSessionFromLS, getTimeStampFromLS, isPastLimit, safeTimeStampToLS } from "./utils/session";
import { fetchApi } from "./utils/apiFetcher";
import { logoutApi } from "./constants/apiUrl";
import SessionModal from "./components/SessionModal";

const Router = () => {
  const {
    selectedDataSets,
    cancelRequests,
    isLoadingBlocker,
    progress,
    buildNumber,
    setBuildNumber,
    getRequest,
    setCurrentModule,
    loadingBlockerText,
    setCanSaveAsImg,
    getBuildNumberText,
    setTenant,
    user,
    setUser,
  } = useAppStore(
    (state) => ({
      selectedDataSets: state.selectedDataSets,
      isLoadingBlocker: state.isLoadingBlocker,
      progress: state.progress,
      cancelRequests: state.cancelRequests,
      getBuildNumberText: state.getBuildNumberText,

      buildNumber: state.buildNumber,
      setBuildNumber: state.setBuildNumber,
      getRequest: state.getRequest,
      setCurrentModule: state.setCurrentModule,
      loadingBlockerText: state.loadingBlockerText,
      setTenant: state.setTenant,
      setCanSaveAsImg: state.setCanSaveAsImg,
      user: state.user,
      setUser: state.setUser,
    }),
    shallow
  );
  const navigate = useNavigate();

  const [modalVisible, setModalVisible] = useState(false);

  // Cancel requests on navigation
  const location = useLocation();

  useEffect(() => {
    return () => selectedDataSets && cancelRequests();
  }, [selectedDataSets, location, cancelRequests]);

  const loadBuildNumber = useCallback(async () => {
    try {
      const numberRes = await getRequest("/build_details");

      if (numberRes) {
        setBuildNumber(numberRes.build_number);
      }
    } catch (_) {
      // no -op
    }
  }, [getRequest, setBuildNumber]);

  // Load Build Number
  useEffect(() => {
    if (!buildNumber) {
      loadBuildNumber();
    }
  }, [buildNumber, loadBuildNumber]);

  useEffect(() => {
    const splittedCurrLocation = location.pathname.split("/");
    // Navigate back to modules if selected data sets is not available
    if (!selectedDataSets && splittedCurrLocation[1] === "modules" && splittedCurrLocation.length > 2 && !location.pathname.includes("/v2")) {
      navigate("..");
      setCurrentModule();
    }

    if (splittedCurrLocation[1] === "settings") setCanSaveAsImg(false);
  }, [location.pathname, navigate, selectedDataSets, setCanSaveAsImg, setCurrentModule]);

  const signOut = useCallback(async () => {
    try {
      await fetchApi({ path: logoutApi, type: "del" });
      await Auth.signOut({ global: true });
    } catch (e) {
      console.error(e);
    } finally {
      setTenant(undefined);
      setUser(undefined);
      clearSessionFromLS();
      window.location.href = "/";
    }
  }, [setTenant, setUser]);

  const onClickStay = useCallback(() => {
    if (getTimeStampFromLS() && user) {
      setModalVisible(false);
      safeTimeStampToLS();
    } else {
      clearSessionFromLS();
      window.location.href = "/";
    }
  }, [user]);

  const onClickLogout = useCallback(() => {
    setModalVisible(false);
    signOut();
  }, [signOut]);

  useEffect(() => {
    // time out every 3 minutes to check latest timestamp
    setInterval(() => {
      const lastTimeStamp = getTimeStampFromLS();
      if (lastTimeStamp) {
        const isPast = isPastLimit(new Date(Number(lastTimeStamp)), 25);
        if (isPast) {
          setModalVisible(true);
        }
      } else {
        signOut();
      }
    }, 1000 * 60 * 3);
  });

  const buildNumberText = getBuildNumberText();

  return (
    <Stack style={{ height: "100vh" }} id="inner-body">
      <LoadingBlocker isVisible={isLoadingBlocker} progress={progress} customText={loadingBlockerText} />
      {modalVisible && <SessionModal onClickLogout={onClickLogout} onClickStay={onClickStay} />}

      <Header />

      <Routes>
        <Route path="/">
          <Route index element={<Navigate to="modules" replace />} />

          <Route path={routeUrlMap.settings} element={<SettingsPage buildNumberText={buildNumberText} />} />
          <Route path={routeUrlMap.importWells} element={<ImportWellsPage />} />
          <Route path={routeUrlMap.importProjects} element={<ImportProjectsPage />} />

          <Route path={routeUrlMap.modules} element={<Layout />}>
            <Route index element={<Modules />} />

            <Route path={routeUrlMap.gaz_fmb} element={<ModuleGazFmb />} />
            <Route path={routeUrlMap.gaz_pz} element={<ModuleGazPz />} />
            <Route path={routeUrlMap.gaz_autorta} element={<ModuleGasAutoRTA />} />

            <Route path={routeUrlMap.spad_decline_gas} element={<ModuleSpadDeclineGasPage />} />
            <Route path={routeUrlMap.spad_decline_oil} element={<ModuleSpadDeclineOilPage />} />

            <Route path={`${routeUrlMap.spad_decline_gas}/v2`} element={<ModuleSpadDeclineGasPageV2 />} />
            <Route path={`${routeUrlMap.spad_decline_oil}/v2`} element={<ModuleSpadDeclineOilPageV2 />} />
            <Route path={routeUrlMap.spad_wor} element={<ModuleSpadWor />} />

            <Route path={routeUrlMap.tahk_forecast_gas} element={<ModuleTahkForecastGas />} />
            <Route path={routeUrlMap.tahk_forecast_oil} element={<ModuleTahkForecastOil />} />
            <Route path={routeUrlMap.tahk_forecast_csg} element={<ModuleTahkCsg />} />
            <Route path={routeUrlMap.koldun_mcsim_gas} element={<ModuleKoldunMcsimGas />} />
            <Route path={routeUrlMap.koldun_mcsim_oil} element={<ModuleKoldunMcsimOil />} />
            <Route path={routeUrlMap.koldun_mcsim_csg} element={<ModuleKoldunMcsimCsg />} />
            <Route path={routeUrlMap.gildro_hydrate} element={<ModuleGildroHydrate />} />
            <Route path={routeUrlMap.rollup} element={<ModuleRollup />} />
            <Route path={routeUrlMap.rulon_group_run} element={<ModuleRulonGroupRun />} />
          </Route>

          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </Stack>
  );
};

export default Router;
