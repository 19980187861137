import React from "react";

import LoadingIndicator from "@/components/LoadingIndicator";

import { useSpadDeclineState } from "../../hooks/SpadDeclineContext";
import { Container } from "./style";
import useThemeStyling from "@/utils/useThemeStyling";
import SpadDeclineAnalysisParameter from "../SpadDeclineAnalysisParameter";
import SpadDeclineAnalysisChart from "../SpadDeclineAnalysisChart";
import useCompareSpadAnalysis from "../../hooks/useCompareSpadAnalysis";

const SpadDeclineAnalysis = () => {
  const {
    isLoading,
    spadDeclineState,
    setSpadDeclineState,
    onClickCalculate,
    onClickExport,
    currentModule,
    projects,
    dataSetList,
    selectedDataSets,
  } = useSpadDeclineState();
  const { elevatedCard } = useThemeStyling();

  const { compareAnalysis, compareName, setCompareName, setCompareDataSet } = useCompareSpadAnalysis({
    currentModule,
  });

  return (
    <Container>
      <div style={elevatedCard}>
        <SpadDeclineAnalysisParameter
          isLoading={isLoading}
          onClickCalculate={onClickCalculate}
          spadDeclineState={spadDeclineState}
          setSpadDeclineState={setSpadDeclineState}
          onClickExport={onClickExport}
          setCompareDataSet={setCompareDataSet}
          setCompareName={setCompareName}
          dataSets={dataSetList}
          projects={projects}
          selectedDataSets={selectedDataSets}
        />
      </div>
      <div style={elevatedCard}>
        <SpadDeclineAnalysisChart compareAnalysis={compareAnalysis} compareName={compareName} />
      </div>
      {isLoading && <LoadingIndicator />}
    </Container>
  );
};

export default SpadDeclineAnalysis;
