import React from "react";
import { Stack, useTheme } from "@fluentui/react";

import ModulePage from "@/modules/ModulePage";
import ModuleKoldunMcsimCsgInputGridTab from "./Components/ModuleKoldunMcsimCsgInputGridTab/ModuleKoldunMcsimCsgInputGridTab";
import ModuleKoldunMcsimCsgOutputView from "./Components/ModuleKoldunMcsimCsgOutputView/ModuleKoldunMcsimCsgOutputView";
import CSGDialog from "@/modules/koldun/mcsim/csg/csg/Components/ModuleKoldunMcsimCsgInputGridTab/Components/CSGDialog";

import useKoldunCsg from "./hooks/useKoldunCsg";

interface ModuleKoldunMcsimCsgContentProps {
  title: string;
  currentTab: number;
  setCurrentTab: (index: number) => void;
}

const ModuleKoldunMcsimCsgContent = ({ title, currentTab, setCurrentTab }: ModuleKoldunMcsimCsgContentProps) => {
  const theme = useTheme();

  const {
    isLoading,
    handleTab,
    safeSelectedDataSet,
    mcsOutput,
    errorInputValidation,
    isDialogOpen,
    handleContinueToForecast,
    handleCancelBackToInput,
    forecastOutput,
    isContinuePolling,
    setMcsOutput,
  } = useKoldunCsg({
    setCurrentTab,
    currentTab,
  });

  return (
    <ModulePage
      title={title}
      tabIndex={currentTab}
      onTabChange={handleTab}
      tabs={
        safeSelectedDataSet && [
          {
            headerText: "Input",
            itemIcon: "NumberField",
            disabled: isLoading,
            disableHideSidebar: true,
            content: mcsOutput ? (
              <ModuleKoldunMcsimCsgInputGridTab errorInputValidation={errorInputValidation} value={mcsOutput} onChange={setMcsOutput} />
            ) : (
              <></>
            ),
          },
          {
            headerText: "Forecast",
            itemIcon: "LineChart",
            disabled: false,
            disableHideSidebar: true,
            canSaveAsImg: true,
            content: (
              <>
                <CSGDialog
                  isDialogOpen={isDialogOpen && currentTab === 1 && !isContinuePolling}
                  handleContinueToForecast={handleContinueToForecast}
                  handleCancelBackToInput={handleCancelBackToInput}
                />

                <Stack horizontal style={{ height: "100%" }} tokens={{ padding: 20, childrenGap: 20 }}>
                  <Stack
                    style={{
                      boxShadow: theme.effects.elevation4,
                      padding: 15,
                      borderRadius: theme.effects.roundedCorner4,
                    }}
                    grow={1}
                  >
                    <ModuleKoldunMcsimCsgOutputView isLoading={isLoading} forecastOutput={forecastOutput} />
                  </Stack>
                </Stack>
              </>
            ),
          },
        ]
      }
    />
  );
};

export default ModuleKoldunMcsimCsgContent;
