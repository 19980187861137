import React, { useCallback } from "react";
import DataSetRadioItem from "./DataSetRadioItem";

import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { useTreeViewState } from "../hooks/TreeViewContextV2";

import { DataSetType } from "@/model";
import Tree from "rc-tree";

const DataSetList = () => {
  const {
    commandControlHeld,
    dataSetItems,
    setFilter,
    setFilterTypes,
    filter,
    selectedDataSet,
    setSelectedDataSet,
    shiftHeld,
    setActiveDialog,
    onDropWell,
  } = useTreeViewState();
  const height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

  const onClickDataSetWellList = React.useCallback(
    (index: number, id: string, indexOnSelected: number, isChecked: boolean) => {
      // find index of the latest element
      const latestElIndex =
        selectedDataSet.length > 0
          ? dataSetItems.findIndex(
              (item) => item.name === selectedDataSet[selectedDataSet.length - 1] || item.data.id === selectedDataSet[selectedDataSet.length - 1]
            )
          : 0;
      const startIndex = index > latestElIndex ? latestElIndex : index;
      const elTotal = index > latestElIndex ? index - latestElIndex : latestElIndex - index;
      const newSelectedArray = dataSetItems.slice(startIndex, elTotal + startIndex + 1);

      let newSelectedDataSet = [...selectedDataSet];
      if (isChecked) {
        newSelectedDataSet.splice(indexOnSelected, 1);
      } else if (shiftHeld && newSelectedDataSet.length > 0 && selectedDataSet.length === 1) {
        newSelectedDataSet = newSelectedArray.map((items) => items.data.id);
      } else if (commandControlHeld) {
        newSelectedDataSet.push(id);
      } else {
        newSelectedDataSet = [id];
      }

      setSelectedDataSet(newSelectedDataSet);
    },
    [commandControlHeld, dataSetItems, selectedDataSet, setSelectedDataSet, shiftHeld]
  );

  const renderDatasetItem = useCallback(
    (item: any) => {
      const indexOnSelected = selectedDataSet.indexOf(item.id);
      const isChecked = indexOnSelected > -1;
      return (
        <DataSetRadioItem
          key={item.key}
          onClick={() => {
            onClickDataSetWellList(item.index, item.id, indexOnSelected, isChecked);
          }}
          dataSet={item.title}
          currDataSet={item.dataSet}
          setActiveDialog={setActiveDialog}
          checked={isChecked}
          className={"noselect"}
        />
      );
    },
    [onClickDataSetWellList, selectedDataSet, setActiveDialog]
  );

  return (
    <div className="well-container">
      <div
        style={{
          marginBottom: 5,
        }}
      >
        <FormControlLabel
          control={
            <Checkbox
              onChange={(e) => {
                setFilterTypes((prev) => {
                  if (!prev) return prev;
                  if (e.target.checked) {
                    return [...prev, DataSetType.GAS_WELL];
                  }
                  return prev.filter((type) => type !== DataSetType.GAS_WELL);
                });
              }}
              defaultChecked
              size="small"
              id="gas-well-selection-well-list"
            />
          }
          label="Gas Well"
        />
        <FormControlLabel
          control={
            <Checkbox
              id="oil-well-selection-well-list"
              onChange={(e) => {
                setFilterTypes((prev) => {
                  if (!prev) return prev;
                  if (e.target.checked) {
                    return [...prev, DataSetType.OIL_WELL];
                  }
                  return prev.filter((type) => type !== DataSetType.OIL_WELL);
                });
              }}
              defaultChecked
              size="small"
            />
          }
          label="Oil Well"
        />
      </div>
      <TextField
        value={filter}
        onChange={(e) => setFilter(e.target.value ?? "")}
        style={{ width: "90%" }}
        size="small"
        label="Search..."
        variant="outlined"
      />
      <Tree
        onDragStart={(info) => {
          const item: any = info.node;
          const indexOnSelected = selectedDataSet.indexOf(item.id);
          const isChecked = indexOnSelected > -1;
          onClickDataSetWellList(item.index, item.id, indexOnSelected, isChecked);
        }}
        onDragEnd={() => {
          onDropWell();
        }}
        draggable
        virtual
        height={height - 220}
        itemHeight={40}
        className="well-list noScrollbar"
        // @ts-ignore
        treeData={dataSetItems?.map((well, index) => {
          return {
            id: well.key,
            key: well.key,
            title: well.name,
            dataSet: well,
            source: "well-list",
            index,
          };
        })}
        titleRender={(item) => renderDatasetItem(item)}
      />
    </div>
  );
};

export default DataSetList;
