import { spadDeclineAnalysisInitialize } from "@/constants/apiUrl";

import { fetchApi } from "@/utils/apiFetcher";
import { SpadDeclineState, SpadDeclineType } from "../State";
import { ModuleIdentity } from "@/models/Generic";

export const postInitializeSpadDecline = (body: ModuleIdentity, type: SpadDeclineType) => {
  return fetchApi<SpadDeclineState, ModuleIdentity>({
    path: spadDeclineAnalysisInitialize(type),
    body,
    type: "post",
  });
};
