import { Pivot, PivotItem, Spinner, Stack, useTheme } from "@fluentui/react";
import React, { useMemo } from "react";
import FossilyticsChart, { FossilyticsChartAxis, FossilyticsChartSeries } from "../../../components/FossilyticsChart";
import { ModuleGildroHydrateOutput } from "./model";
import FossilyticsGrid, { FossilyticsGridColumn } from "../../../components/FossilyticsGrid";
import { transpose } from "../../../util";
import { DataSet } from "../../../model";

const dataTableColumns = [
  { key: "depths_ft", type: "number", header: "Depth (ft)" },
  { key: "pressures_psia", type: "number", header: "Pressure (psia)", width: 110 },
  { key: "temperatures_of", type: "number", header: "Temperature (°F)", width: 110 },
] as FossilyticsGridColumn[];

interface ModuleGildroHydrateOutputViewProps {
  isLoading: boolean;
  dataSet: DataSet;
  output: ModuleGildroHydrateOutput | undefined;
}

function ModuleGildroHydrateOutputView({ isLoading, dataSet, output }: ModuleGildroHydrateOutputViewProps) {
  const theme = useTheme();

  const pressAxes = useMemo<FossilyticsChartAxis[]>(() => [{ name: "Pressure (psia)", type: "value", color: theme.palette.black, min: 0 }], [theme]);
  const pressSeries = useMemo<FossilyticsChartSeries[]>(
    () =>
      output
        ? output.cases.map((c, i) => ({
            id: `p${i}`,
            name: "",
            type: "line",
            hideSymbol: true,
            color: theme.palette.themePrimary,
            data: c.pressures_psia.map((p, i) => [p, output.depths_ft[i]]),
            markPointData: c.indices.map((i) => [c.pressures_psia[i], output.depths_ft[i]]),
          }))
        : [],
    [theme, output]
  );

  const tempAxes = useMemo<FossilyticsChartAxis[]>(() => [{ name: "Temperature (°F)", type: "value", color: theme.palette.black, min: 0 }], [theme]);
  const tempSeries = useMemo<FossilyticsChartSeries[]>(
    () =>
      output
        ? output.cases.map((c, i) => ({
            id: `t${i}`,
            name: "",
            type: "line",
            hideSymbol: true,
            color: theme.palette.themePrimary,
            data: c.temps_of.map((t, i) => [t, output.depths_ft[i]]),
          }))
        : [],
    [theme, output]
  );

  const yAxes = useMemo<FossilyticsChartAxis[]>(() => {
    return [{ name: "Depth (ft)", type: "value", color: theme.palette.black, min: 0, inverse: true }];
  }, [theme]);

  return (
    <Stack className="PivotWrapper" grow={1}>
      <Pivot styles={{ itemContainer: { flex: "1" } }}>
        <PivotItem headerText="Pressure">
          <FossilyticsChart id="gildro_hydrate_pressure" isLoading={isLoading} xAxes={pressAxes} yAxes={yAxes} series={pressSeries} />
        </PivotItem>
        <PivotItem headerText="Temperature">
          <FossilyticsChart id="gildro_hydrate_temp" isLoading={isLoading} xAxes={tempAxes} yAxes={yAxes} series={tempSeries} />
        </PivotItem>
        <PivotItem headerText="Data table" style={{ position: "relative" }}>
          {isLoading ? (
            <Spinner
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
              label="Loading analysis..."
            />
          ) : (
            <Stack className="PivotWrapper" style={{ position: "absolute", top: 0, left: 0, bottom: 0, right: 0 }}>
              <Pivot styles={{ itemContainer: { flex: "1" } }} overflowBehavior="menu" overflowAriaLabel="More cases">
                {output?.cases.map((c, i) => (
                  <PivotItem key={i} headerText={`Case ${i + 1}`} style={{ position: "relative" }}>
                    <FossilyticsGrid
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        overflowY: "auto",
                      }}
                      columns={dataTableColumns}
                      data={transpose([output.depths_ft, c.pressures_psia, c.temps_of])}
                    />
                  </PivotItem>
                ))}
              </Pivot>
            </Stack>
          )}
        </PivotItem>
      </Pivot>
    </Stack>
  );
}

export default ModuleGildroHydrateOutputView;
