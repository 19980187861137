import React from "react";
import { shallow } from "zustand/shallow";

import Button from "@mui/material/Button";

import { useAppStore } from "@/features/app";
import { calculateHeight } from "@/utils/cellRendering";
import useThemeStyling from "@/utils/useThemeStyling";

import ReactGridTable from "@/components/ReactGridTable/ReactGridTable";
import LoadingIndicator from "@/components/LoadingIndicator";
import useGroupRun from "./hooks/useGroupRun";
import dictionary from "@/constants/dictionary";

const ModuleRulonGroupRun = () => {
  const { selectedDataSets, isLoading, project, setApiError } = useAppStore(
    (state) => ({
      selectedDataSets: state.selectedDataSets,
      isLoading: state.isLoading,
      project: state.project,
      setApiError: state.setApiError,
    }),
    shallow
  );

  const { loading, rulonRows, rulonColumns, onChangeCell, onValidateRulon, onCalculateRulon, rulonState } = useGroupRun({
    project,
    isLoading,
    selectedDataSets,
    setApiError,
  });

  const { elevatedCard } = useThemeStyling();

  return (
    <div
      style={{
        padding: 10,
      }}
    >
      {loading && <LoadingIndicator />}

      <div style={{ ...elevatedCard, width: 1080, margin: "auto" }}>
        <ReactGridTable
          className="noScrollbarAll"
          innerStyle={{
            display: "flex",
            marginTop: 23,
          }}
          viewWidth={"100%"}
          viewHeight={calculateHeight(rulonRows) + 130}
          minHeight={calculateHeight(rulonRows) + 50}
          columns={rulonColumns}
          rows={rulonRows}
          enableRangeSelection
          enableColumnSelection
          stickyTopRows={2}
          onCellsChanged={onChangeCell}
        />
        <Button
          onClick={(e) => {
            e.preventDefault();
            onValidateRulon();
          }}
          style={{ color: "white", width: "100%" }}
          variant="contained"
          disabled={loading || !rulonState}
        >
          {dictionary.rulon.validateButton}
        </Button>
        <Button
          onClick={(e) => {
            e.preventDefault();
            onCalculateRulon();
          }}
          style={{ color: "white", marginTop: 20, width: "100%" }}
          variant="contained"
          disabled={loading || !rulonState}
        >
          {dictionary.rulon.groupRun}
        </Button>
      </div>
    </div>
  );
};

export default ModuleRulonGroupRun;
