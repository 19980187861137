import "@fontsource/baloo-2";
import "@silevis/reactgrid/styles.css";
import "./index.css";

import { createRoot } from "react-dom/client";
import { initializeIcons, ThemeProvider as FluentuiThemeProvider } from "@fluentui/react";
import { BrowserRouter } from "react-router-dom";
import { Amplify, Auth } from "aws-amplify";
import { StyledEngineProvider, ThemeProvider as MuiThemeProvider } from "@mui/material";

import reportWebVitals from "./reportWebVitals";
import { getFluentuiTheme } from "@/features/themes/fluentui-theme";
import { getMuiTheme } from "./features/themes/mui-theme";

import App from "./App";
import ErrorBoundaryWrapper from "./components/ErrorBoundary";
import { APIName } from "@/constants/apiUrl";

// Initialize Fluent UI icons
initializeIcons();

// Setup for Fluentui Theme
const fluentuiTheme = getFluentuiTheme();

Amplify.configure({
  API: {
    endpoints: [
      {
        name: APIName,
        endpoint: process.env.REACT_APP_API_URL,
        custom_header: async () => {
          return { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` };
        },
      },
    ],
  },
});

// Setup for MUI Theme
const muiTheme = getMuiTheme();

// Create React app
const container = document.getElementById("root")!;
const root = createRoot(container);
root.render(
  // TODO: Add React.StrictMode when Fluent UI receives update for v18!
  <StyledEngineProvider injectFirst>
    <MuiThemeProvider theme={muiTheme}>
      <FluentuiThemeProvider theme={fluentuiTheme}>
        <ErrorBoundaryWrapper>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </ErrorBoundaryWrapper>
      </FluentuiThemeProvider>
    </MuiThemeProvider>
  </StyledEngineProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
