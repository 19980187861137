import CustomTabs from "@/components/Tabs";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { CaseInputs } from "../case-inputs";
import { ModuleSpadDeclineCaseParams } from "../../index.types";
import _ from "lodash";
import { getAnalysisParameter } from "../../index.utils";
import useOutsideClick from "@/utils/useOutsideClick";

type CaseContainerProps = {
  disabled: boolean;
  params?: ModuleSpadDeclineCaseParams;
  onChange: (params?: ModuleSpadDeclineCaseParams) => void;
  unit: string;
};

const CaseContainer = ({ disabled, onChange, unit, params }: CaseContainerProps) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const haveChange = useRef(false);

  const [localCaseState, setLocalCaseState] = useState(params);

  useEffect(() => {
    haveChange.current = false;

    if (!_.isEqual(params, localCaseState) && params && Object.keys(params).length > 0) {
      setLocalCaseState(params);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  // since we don't use lazy load, when switching between data set the latest state is still retained
  // we want to clear by using logic below
  // TODO in future, clear all state on dataset/ project / group changes
  useEffect(() => {
    if (!params && localCaseState && Object.keys(localCaseState).length === 4) setLocalCaseState({});
  }, [localCaseState, params]);

  const checkTriggerApi = (newState: ModuleSpadDeclineCaseParams) => {
    const validParam = getAnalysisParameter(newState);
    return Object.keys(validParam).length === 3;
  };

  useEffect(() => {
    if (localCaseState && checkTriggerApi(localCaseState) && haveChange.current) {
      onChange(localCaseState);
    }
  }, [localCaseState, onChange]);

  // incase user click somewhere else safe the state
  const handleOutsideClick = useCallback(() => {
    onChange(localCaseState);
  }, [localCaseState, onChange]);

  useOutsideClick(containerRef, handleOutsideClick);

  const tabList = useMemo(() => {
    return [
      {
        label: <span>Low case</span>,
        key: "Low case",
        content: (
          <div>
            <CaseInputs
              disabled={disabled}
              unit={unit}
              key={"low-case" + params?.cumProd}
              params={localCaseState?.low}
              onChange={(param) => {
                haveChange.current = true;
                // since spad decline have a lot of stage changes etc
                // When api return error and all state is blank:
                // - use local state per params low / mid / high
                // - and set it to higher state when user change between tab

                // the cons of above implementation is we can't trigger api changes,
                // to keep that functionality, add conditioning to check if cumprod value is available on params
                // if it is available it means it is complete state, then we can trigger api based on the changes safely
                setLocalCaseState((prev) => {
                  const safePrev = prev ?? {};

                  return {
                    ...safePrev,
                    low: { ...safePrev?.low, ...param },
                  };
                });

                const newState = {
                  ...localCaseState,
                  low: { ...localCaseState?.low, ...param },
                };
                const isValid = checkTriggerApi(newState);
                if (params?.cumProd || isValid) {
                  onChange(newState);
                }
              }}
            />
          </div>
        ),
      },
      {
        label: <span>Mid case</span>,
        key: "Mid case",
        content: (
          <div>
            <CaseInputs
              key={"mid-case" + params?.cumProd}
              disabled={disabled}
              unit={unit}
              params={localCaseState?.mid}
              onChange={(param) => {
                haveChange.current = true;
                setLocalCaseState((prev) => {
                  const safePrev = prev ?? {};

                  return {
                    ...safePrev,
                    mid: { ...safePrev?.mid, ...param },
                  };
                });

                const newState = {
                  ...localCaseState,
                  mid: { ...localCaseState?.mid, ...param },
                };

                const isValid = checkTriggerApi(newState);

                if (params?.cumProd || isValid) {
                  onChange(newState);
                }
              }}
            />
          </div>
        ),
      },
      {
        label: <span>High case</span>,
        key: "High case",
        content: (
          <div>
            <CaseInputs
              disabled={disabled}
              unit={unit}
              params={localCaseState?.high}
              key={"high-case" + params?.cumProd}
              onChange={(param) => {
                haveChange.current = true;
                setLocalCaseState((prev) => {
                  const safePrev = prev ?? {};

                  return {
                    ...safePrev,
                    high: { ...safePrev?.high, ...param },
                  };
                });
                const newState = {
                  ...localCaseState,
                  high: { ...localCaseState?.high, ...param },
                };

                const isValid = checkTriggerApi(newState);

                if (params?.cumProd || isValid) {
                  onChange(newState);
                }
              }}
            />
          </div>
        ),
      },
    ];
  }, [disabled, localCaseState, onChange, params?.cumProd, unit]);

  return (
    <div
      ref={(ref) => {
        containerRef.current = ref as any as HTMLDivElement;
      }}
    >
      <CustomTabs
        onClickItem={(_) => {
          onChange(localCaseState);
        }}
        centered
        tabList={tabList}
      />
    </div>
  );
};

export default CaseContainer;
