import React, { useMemo, useState } from "react";
import { useTheme } from "@mui/material/styles";

import useThemeStyling from "@/utils/useThemeStyling";
import dictionary from "@/constants/dictionary";

import useTahkCsgForecast from "../../hooks/forecast/useTahkCsgForecast";
import { useTahkCsgState } from "../../context/TahkCsgContext";

import { Container } from "./style";
import { CellChange, Column, ReactGrid, Row } from "@silevis/reactgrid";
import Tabs from "@/components/Tabs";
import LoadingIndicator from "@/components/LoadingIndicator";
import FossilyticsChart, { FossilyticsChartAxis, FossilyticsChartSeries } from "@/components/FossilyticsChart";
import FossilyticsDropdown from "@/components/fields/FossilyticsDropdown";
import CustomTable from "@/components/CustomTable";
import { dataTableColumn } from "../../constants/grid";
import { IDropdownOption } from "@fluentui/react";

type ForecastContainerProps = {
  handleFlowingPressureCellChange: (changes: CellChange[]) => void;
  flowingPressureRow: Row<any>[];
  flowingPressureCol: Column[];
  loadingState: boolean;
  chartYAxes: FossilyticsChartAxis[];
  chartXAxes: FossilyticsChartAxis[];
  series: FossilyticsChartSeries[];
  dataTableLayerOption: IDropdownOption[];
  selectedDataTableLayer: string | number | string[] | number[] | null | undefined;
  setSelectedDataTableLayer: (key: number) => void;
  dataTableRows: Row[];
  cumulativeSeries: FossilyticsChartSeries[];
  cumulativeChartYAxes: FossilyticsChartAxis[];
  data: boolean;
  isTest?: boolean;
  flowingInputError: string;
};

export const ForecastContainer = ({
  handleFlowingPressureCellChange,
  flowingPressureRow,
  flowingPressureCol,
  loadingState,
  chartYAxes,
  chartXAxes,
  series,
  dataTableLayerOption,
  selectedDataTableLayer,
  setSelectedDataTableLayer,
  dataTableRows,
  cumulativeSeries,
  cumulativeChartYAxes,
  data,
  isTest,
  flowingInputError,
}: ForecastContainerProps) => {
  const [activeTab, setActiveTab] = useState(0);
  const { elevatedCard } = useThemeStyling();
  const { palette } = useTheme();

  const tabList = useMemo(() => {
    return [
      {
        label: <span>{dictionary.dataView.combinedRates}</span>,
        key: dictionary.dataView.combinedRates,
        content:
          loadingState || series.length === 0 || !data || isTest ? (
            <div className="center-flex h100">
              <LoadingIndicator />
            </div>
          ) : (
            <div
              style={{
                height: 600,
              }}
              className="chartWrapper"
            >
              <FossilyticsChart
                key={"forecast_rates_tahk_csg"}
                id="forecast_rates_tahk_csg"
                isLoading={loadingState}
                xAxes={chartXAxes}
                yAxes={chartYAxes}
                series={series}
              />
            </div>
          ),
      },
      {
        label: <span>{dictionary.dataView.cumulativeChart}</span>,
        key: dictionary.dataView.cumulativeChart,
        content:
          loadingState || activeTab !== 1 || cumulativeSeries.length === 0 || !data || isTest ? (
            <div className="center-flex h100">
              <LoadingIndicator />
            </div>
          ) : (
            <div
              style={{
                height: 600,
              }}
              className="chartWrapper"
            >
              <FossilyticsChart
                key={"forecast_cumulative_chart_tahk_csg"}
                id="forecast_cumulative_chart_tahk_csg"
                isLoading={loadingState}
                xAxes={chartXAxes}
                yAxes={cumulativeChartYAxes}
                series={cumulativeSeries}
              />
            </div>
          ),
      },
      {
        label: <span>{dictionary.dataTable}</span>,
        key: dictionary.dataTable,
        content: loadingState ? (
          <div className="center-flex h100">
            <LoadingIndicator />
          </div>
        ) : (
          <div>
            <FossilyticsDropdown
              label={dictionary.tahk.layer}
              options={dataTableLayerOption}
              disabled={loadingState}
              selectedKey={selectedDataTableLayer}
              onChange={(v) => {
                setSelectedDataTableLayer(Number(v?.key));
              }}
            />

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                position: "relative",
                overflowX: "scroll",
                height: 900,
              }}
            >
              <CustomTable
                style={{
                  display: "flex",
                  justifyContent: "center",
                  position: "absolute",
                }}
                rows={dataTableRows}
                columns={dataTableColumn}
                isLoading={loadingState}
              />
            </div>
          </div>
        ),
      },
    ];
  }, [
    activeTab,
    chartXAxes,
    chartYAxes,
    cumulativeChartYAxes,
    cumulativeSeries,
    data,
    dataTableLayerOption,
    dataTableRows,
    isTest,
    loadingState,
    selectedDataTableLayer,
    series,
    setSelectedDataTableLayer,
  ]);

  return (
    <Container>
      <div style={elevatedCard}>
        <h4
          style={{
            color: palette.primary.main,
          }}
        >
          {dictionary.tahk.forecastFlowSchedule}
        </h4>

        <span style={{ color: "red" }}>{flowingInputError}</span>
        <div style={{ blockSize: 600, overflowY: "auto", marginBlockStart: 5, width: 350, marginTop: 15 }}>
          <ReactGrid
            columns={flowingPressureCol}
            rows={flowingPressureRow}
            enableRangeSelection
            enableColumnSelection
            enableFullWidthHeader
            stickyTopRows={1}
            onCellsChanged={handleFlowingPressureCellChange}
          />
        </div>
      </div>

      <div style={elevatedCard}>
        {!loadingState && !data && !flowingInputError ? (
          <div>{dictionary.tahk.forecastFlowingSchedule}</div>
        ) : (
          <Tabs id="forecast-tab-list" onClickItem={(index) => setActiveTab(index)} tabList={tabList} />
        )}
      </div>
    </Container>
  );
};

const ForecastWrapper = () => {
  const { setCsvData, tabIndex, isLoading, tahkCsgState, setTahkCsgState, analysisIdentity } = useTahkCsgState();

  const {
    handleFlowingPressureCellChange,
    flowingPressureRow,
    flowingPressureCol,
    loadingState,
    chartYAxes,
    chartXAxes,
    series,
    dataTableLayerOption,
    selectedDataTableLayer,
    setSelectedDataTableLayer,
    dataTableRows,
    cumulativeSeries,
    cumulativeChartYAxes,
    data,
    flowingInputError,
  } = useTahkCsgForecast({
    isLoading,
    tabIndex,
    tahkCsgState,
    setTahkCsgState,
    analysisIdentity,
    setCsvData,
  });

  return (
    <ForecastContainer
      {...{
        handleFlowingPressureCellChange,
        flowingPressureRow,
        flowingPressureCol,
        loadingState,
        chartYAxes,
        chartXAxes,
        series,
        dataTableLayerOption,
        selectedDataTableLayer,
        setSelectedDataTableLayer,
        dataTableRows,
        cumulativeSeries,
        cumulativeChartYAxes,
        data: !!data,
        flowingInputError,
      }}
    />
  );
};
export default ForecastWrapper;
