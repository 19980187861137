import { useEffect, useState } from "react";
import { PrimaryButton, Text, useTheme } from "@fluentui/react";
import { shallow } from "zustand/shallow";

import FossilyticsNumberField from "@/components/fields/FossilyticsNumberField";
import { UserSettings, UserSettingSpadDeclineCurve } from "@/features/settings";
import { useAppStore } from "@/features/app";

import { DEFAULT_MAX_ARPS_EXPONENT, DEFAULT_MIN_ARPS_EXPONENT } from "./spad-preferences.configs";

export const SpadPreferences = () => {
  const { getRequest, putRequest } = useAppStore(
    (state) => ({
      getRequest: state.getRequest,
      putRequest: state.putRequest,
    }),
    shallow
  );

  const [spadDeclineCurve, setSpadDeclineCurve] = useState<UserSettingSpadDeclineCurve>({
    min_arps_exponent: DEFAULT_MIN_ARPS_EXPONENT,
    max_arps_exponent: DEFAULT_MAX_ARPS_EXPONENT,
  });
  const [minErrorMessage, setMinErrorMessage] = useState<string | undefined>(undefined);
  const [maxErrorMessage, setMaxErrorMessage] = useState<string | undefined>(undefined);
  const theme = useTheme();

  useEffect(() => {
    getRequest("/user_settings")
      .then((response: UserSettings) => {
        if (response) {
          updateUserSettings(response.data.modules.spad.decline_curve);
        }
      })
      .catch((error) => console.log(error));
  }, [getRequest]);

  useEffect(() => {
    if (spadDeclineCurve.min_arps_exponent >= spadDeclineCurve.max_arps_exponent) {
      setMinErrorMessage("Minimum value should be less than Maximum value");
      setMaxErrorMessage("Maximum value should be greater than Minimum value");
    } else {
      setMinErrorMessage("");
      setMaxErrorMessage("");
    }
  }, [spadDeclineCurve]);

  const handleMin = (numValue: number | undefined) => {
    if (numValue === undefined) {
      return;
    }
    setSpadDeclineCurve((prevState) => ({ ...prevState, min_arps_exponent: numValue }));
  };

  const handleMax = (numValue: number | undefined) => {
    if (numValue === undefined) {
      return;
    }
    setSpadDeclineCurve((prevState) => ({ ...prevState, max_arps_exponent: numValue }));
  };

  const updateUserSettings = (data: UserSettingSpadDeclineCurve) => {
    setSpadDeclineCurve(data);
  };

  const saveData = () => {
    putRequest(
      "/user_settings",
      {},
      {
        modules: {
          spad: {
            decline_curve: {
              min_arps_exponent: spadDeclineCurve.min_arps_exponent,
              max_arps_exponent: spadDeclineCurve.max_arps_exponent,
            },
          },
        },
      }
    )
      .then((response: UserSettings) => {
        updateUserSettings(response.data.modules.spad.decline_curve);
      })
      .catch((error) => console.error(error));
  };

  return (
    <>
      <FossilyticsNumberField
        label="Minimum Arps Exponent"
        value={spadDeclineCurve.min_arps_exponent}
        min={0}
        max={1}
        onChange={handleMin}
      />
      <Text role="alert" variant="small" styles={{ root: { color: theme.palette.red } }}>
        {minErrorMessage}
      </Text>

      <FossilyticsNumberField
        label="Maximum Arps Exponent"
        value={spadDeclineCurve.max_arps_exponent}
        min={0}
        max={1}
        onChange={handleMax}
      />
      <Text role="alert" variant="small" styles={{ root: { color: theme.palette.red } }}>
        {maxErrorMessage}
      </Text>

      <br />

      <PrimaryButton onClick={saveData}>Save</PrimaryButton>
    </>
  );
};
