import React, { useCallback, useEffect, useState, useMemo, useLayoutEffect, useRef } from "react";
import { shallow } from "zustand/shallow";
import { useQuery } from "@tanstack/react-query";
import _ from "lodash";

import { DataSet } from "@/model";
import { useAppStore } from "@/features/app";
import { Output, ForecastResponse, postInitializeKoldunCsg, KoldunCsgQuery } from "@/models/koldun";

import { ErrorValidationDetail } from "@/models/ErrorInputValidation";
import { constructPreparedMscOutput, generateForecastCsvData } from "../utils";
import { cancelPolling } from "@/utils/apiFetcher";
import { pollKoldunCsgCalculation } from "@/constants/apiUrl";

type UseKoldunCsgProps = {
  setCurrentTab: (index: number) => void;
  currentTab: number;
};

const useKoldunCsg = ({ setCurrentTab, currentTab }: UseKoldunCsgProps) => {
  const {
    project,
    selectedDataSets,
    currentModule,
    isLoading,
    group,
    currentUserTask,
    isLoadingBlocker,
    setCsvData,
    setLoadingBlocker,
    continuePollRequest,
    cancelPoll,
    setProgress,
    pollRequest,
  } = useAppStore(
    (state) => ({
      project: state.project,
      selectedDataSets: state.selectedDataSets,
      currentModule: state.currentModule,
      isLoading: state.isLoading,
      group: state.group,
      currentUserTask: state.currentUserTask,
      isLoadingBlocker: state.isLoadingBlocker,
      setCsvData: state.setCsvData,
      setLoadingBlocker: state.setLoadingBlocker,
      continuePollRequest: state.continuePollRequest,
      cancelPoll: state.cancelPolling,
      setProgress: state.setProgress,
      pollRequest: state.pollRequest,
    }),
    shallow
  );

  // replace local data set
  const safeSelectedDataSet: DataSet = useMemo(() => {
    return selectedDataSets as DataSet;
  }, [selectedDataSets]);

  const [mcsOutput, setMcsOutput] = useState<Output | null>(null);
  const [lastValidMcsOutput, setLastValidMcsOutput] = useState<Output | null>(null);
  const [lastValidInitializePayload, setLastValidInitializePayload] = useState<KoldunCsgQuery | null>(null);

  const [forecastOutput, setForecastOutput] = useState<ForecastResponse>();
  const [preparedMcsOutput, setPreparedMcsOutput] = useState<Output | null>(null);
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);

  const [totalCalculation, setTotalCalculation] = useState<number>(1);

  const [isContinuePolling, setIsContinuePolling] = useState(false);

  const [errorInputValidation, setErrorInputValidation] = useState<ErrorValidationDetail[]>([]);

  const initializeOutputPayload = useMemo(() => {
    return {
      project_id: project?.id ?? "",
      data_set_ids: [safeSelectedDataSet?.id],
      group_id: group?.id ?? "",
    };
  }, [project?.id, safeSelectedDataSet?.id, group?.id]);
  const lastCalculationPayload = useRef<any>(null);

  const enableValidateCsgApi = useMemo(() => {
    return !!project?.id && !!safeSelectedDataSet?.id && currentModule && !currentUserTask && !!group?.id;
  }, [currentModule, currentUserTask, group?.id, project?.id, safeSelectedDataSet?.id]);

  // validation api
  const { isLoading: isLoadingValidation, isFetching } = useQuery({
    queryKey: ["validate-koldun-csg", initializeOutputPayload, mcsOutput, currentUserTask, enableValidateCsgApi],
    queryFn: async () => {
      // skip first update of msc output;
      setErrorInputValidation([]);
      const payloadEqual = _.isEqual(initializeOutputPayload, lastValidInitializePayload);
      if ((!mcsOutput && !lastValidMcsOutput) || !_.isEqual(mcsOutput, lastValidMcsOutput) || !payloadEqual) {
        setLastValidInitializePayload(initializeOutputPayload);
        return postInitializeKoldunCsg(initializeOutputPayload, payloadEqual ? mcsOutput : null);
      }

      return {
        data: mcsOutput,
        error: null,
      };
    },
    select(data) {
      if (data?.error) {
        console.log(data?.error);
      } else if (errorInputValidation.length > 0) {
        setErrorInputValidation([]);
      }

      if (data?.data && !_.isEqual(data.data, mcsOutput)) {
        setMcsOutput({ ...data?.data });
        setLastValidMcsOutput({ ..._.cloneDeep(data?.data) });
      }
      return data?.data;
    },
    refetchOnWindowFocus: false,
    enabled: enableValidateCsgApi,
    staleTime: 0,
    gcTime: 0,
    // error handling
    throwOnError(error: any) {
      if (error && error.code === 422 && error.detail?.length !== 0 && !_.isEqual(errorInputValidation, error.detail)) {
        // parse error message and data, set to state
        setErrorInputValidation(error.detail as ErrorValidationDetail[]);
      } else {
        console.log(error);
      }
      return false;
    },
  });

  const handleContinueToForecast = () => {
    setIsDialogOpen(false);
  };

  const handleCancelBackToInput = () => {
    setCurrentTab(0);
  };

  const pollCalculation = useCallback(() => {
    if (mcsOutput) {
      const data = constructPreparedMscOutput({ ...mcsOutput });
      const mcsOutputEqual = _.isEqual(data, preparedMcsOutput);
      if (!mcsOutputEqual) {
        setProgress(null);
        setIsContinuePolling(false);
        setLoadingBlocker(true);
        lastCalculationPayload.current = initializeOutputPayload;
        pollRequest(pollKoldunCsgCalculation, initializeOutputPayload, data, true, "post", true)
          .then((response) => {
            const resData = response.task_result;
            setLoadingBlocker(false);
            setCsvData([
              {
                ...generateForecastCsvData({
                  forecastOutput: resData,
                  project,
                  selectedDataSets: selectedDataSets as DataSet,
                }),
              },
            ]);
            setForecastOutput(resData);
            setPreparedMcsOutput(_.cloneDeep(data));
          })
          .catch((error) => {
            console.error(error);
            setLoadingBlocker(false);
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initializeOutputPayload, mcsOutput, preparedMcsOutput, project, selectedDataSets]);

  const handleTab = useCallback(
    async (index: number) => {
      setCurrentTab(index);

      if (totalCalculation > 3000) {
        setIsDialogOpen(true);
      } else {
        setIsDialogOpen(false);
      }
      if (index === 1) {
        pollCalculation();
      }
    },
    [setCurrentTab, totalCalculation, pollCalculation]
  );

  const initializePage = useCallback(async () => {
    // on first load, check if there's current user task data first
    // and if none proceed to check saved config
    if (currentUserTask) {
      let data: Output = { ...currentUserTask.calculate_input };
      const preparedData = constructPreparedMscOutput({ ...data });
      setPreparedMcsOutput(_.cloneDeep(preparedData));
      setLastValidMcsOutput(_.cloneDeep(data));
      setMcsOutput(data);

      setIsContinuePolling(true);
      setCurrentTab(1);
      // don't make new poll request if
      // user go back to that forecast tab in the same tab
      // case: user create task -> user go to
      if (!isLoadingBlocker) {
        continuePollRequest({
          url: pollKoldunCsgCalculation,
          taskId: currentUserTask.task_id,
          isBlocking: true,
        })
          .then((response) => {
            setLoadingBlocker(false);
            setCsvData([
              {
                ...generateForecastCsvData({
                  forecastOutput: response,
                  project,
                  selectedDataSets: selectedDataSets as DataSet,
                }),
              },
            ]);
            setForecastOutput(response);
          })
          .catch((error) => {
            console.error(error);
            setLoadingBlocker(false);
          });
      }
    }
  }, [continuePollRequest, currentUserTask, isLoadingBlocker, project, selectedDataSets, setCsvData, setCurrentTab, setLoadingBlocker]);

  // this only on the first load when mcsOutput is empty
  // this function is to initialize the page if allDataSetValues is available
  useEffect(() => {
    if (!mcsOutput) {
      initializePage();
    }
  }, [initializePage, mcsOutput, initializeOutputPayload]);

  useEffect(() => {
    if (isContinuePolling && currentTab === 0 && isLoadingBlocker) {
      setCurrentTab(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isContinuePolling, currentTab, isLoadingBlocker]);

  useEffect(() => {
    if (currentTab === 1 && mcsOutput && !_.isEqual(lastCalculationPayload.current, initializeOutputPayload)) {
      pollCalculation();
    }
  }, [pollCalculation, currentTab, initializeOutputPayload, mcsOutput]);

  useEffect(() => {
    if (mcsOutput === null) return;
    let layersArray = mcsOutput.measure_layers;
    let maxLayerVal: number = Math.max(...layersArray);
    let totalNumMeasure = mcsOutput.num_of_measures;
    let totalSimNumber = mcsOutput.num_simulations;
    const simulationCalculation = totalNumMeasure * maxLayerVal * totalSimNumber;
    setTotalCalculation(simulationCalculation);
  }, [mcsOutput]);

  const cleanUp = useCallback(() => {
    cancelPolling();
    cancelPoll();
    setMcsOutput(null);
    setLastValidMcsOutput(null);
  }, [cancelPoll]);

  // clean up function to cancel
  useLayoutEffect(() => {
    window.onbeforeunload = () => {
      cleanUp();
    };
    return () => {
      cleanUp();
    };
  }, [cleanUp]);

  return {
    isLoading: isLoading || isLoadingValidation || isFetching,
    handleTab,
    safeSelectedDataSet,
    mcsOutput,
    errorInputValidation,
    isDialogOpen,
    handleContinueToForecast,
    handleCancelBackToInput,
    forecastOutput,
    isContinuePolling,
    setMcsOutput,
  };
};

export default useKoldunCsg;
