import { API } from "aws-amplify";
import { GenericApiResponse, GenericApiParam, CustomError } from "./type";
import { APIName } from "@/constants/apiUrl";
import { safeTimeStampToLS } from "@/utils/session";

let intervalTime = 500;

const timeoutRefs = new Set<NodeJS.Timer>();

type PollResponse<Response> = {
  progress?: string;
} & GenericApiResponse<Response>;

type PollParam<Request, QueryParams> = {
  withTaskInfo?: boolean;
} & GenericApiParam<Request, QueryParams>;

export const createPoll = async <Response, Request, QueryParams = any>({
  path,
  body,
  config,
  queryStringParameters,
  responseType,
  withTaskInfo,
}: PollParam<Request, QueryParams>): Promise<PollResponse<Response>> => {
  try {
    safeTimeStampToLS();
    const {
      data: { task_id },
    } = await API.post(APIName, path + "/task_start", {
      queryStringParameters,
      body,
      response: true,
      responseType,
      ...config,
    });
    return new Promise((resolve, reject) => {
      const poll = async () => {
        try {
          const { data } = await API.get(APIName, path + "/task_poll", {
            queryStringParameters: { task_id },
            response: true,
          });
          if (data.task_status === "SUCCESS") {
            resolve({
              data: withTaskInfo ? data : data.task_result,
            });
            return;
          } else if (data.task_status === "FAILURE") {
            reject(new Error(data));
            return;
          } else if (data.task_status === "PENDING" || data.task_status === "STARTED") {
            // When progress value is given (for long-term api)
            if (data.task_info?.progress) {
              resolve({ progress: data.task_info.progress });
            }

            let nextIntervalTime = intervalTime * 2;
            if (nextIntervalTime > 10000) {
              // Max interval time is 10 seconds
              nextIntervalTime = 10000;
            }

            const timeoutId = setTimeout(() => {
              poll();
              timeoutRefs.delete(timeoutId);
            }, intervalTime);

            timeoutRefs.add(timeoutId);
            intervalTime = nextIntervalTime;
          }
        } catch (error: any) {
          if (error.response)
            reject(
              new CustomError({
                code: error.response.data?.code ?? error.response.status,
                detail: error.response.data?.detail,
                message: error.response.data.message,
                severity: error.response.data.severity,
              })
            );

          // if something went wrong need to send this as default for displaying error
          reject(
            new CustomError({
              code: 0,
              message: "",
              severity: "error",
            })
          );
          return;
        }
      };

      poll();
    });
  } catch (err: any) {
    // indicate the error comes from BE
    if (err.response)
      throw new CustomError({
        code: err.response.data?.code ?? err.response.status,
        detail: err.response.data?.detail,
        message: err.response.data.message,
        severity: err.response.data.severity,
      });

    // if something went wrong need to send this as default for displaying error
    throw new CustomError({
      code: 0,
      message: "",
      severity: "error",
    });
  }
};

export const cancelPolling = () => {
  timeoutRefs.forEach((timeoutId: NodeJS.Timer) => {
    clearTimeout(timeoutId);
  });

  timeoutRefs.clear();
};

type ContinuePollParam = {
  path: string;
  taskId: string;
  withTaskInfo?: boolean;
};

// don't use this helper for rollup, as it doesn't support that logic yet
// will have to do some refactoring on rollup module as well to make that adjustment
export const continuePollRequest = <Response>({ path, taskId, withTaskInfo }: ContinuePollParam): Promise<PollResponse<Response>> => {
  let intervalTime = 500;
  safeTimeStampToLS();
  return new Promise((resolve, reject) => {
    const poll = async () => {
      try {
        const { data } = await API.get("afa", path + "/task_poll", {
          queryStringParameters: { task_id: taskId },
          response: true,
        });
        if (data.task_status === "SUCCESS") {
          resolve({
            data: withTaskInfo ? data : data.task_result,
          });
          return;
        } else if (data.task_status === "FAILURE") {
          reject(new Error(data));
          return;
        } else if (data.task_status === "PENDING" || data.task_status === "STARTED") {
          // When progress value is given (for long-term api)
          // if (data.task_info?.progress) {
          //   resolve({ progress: data.task_info.progress });
          // }

          let nextIntervalTime = intervalTime * 2;
          if (nextIntervalTime > 10000) {
            // Max interval time is 10 seconds
            nextIntervalTime = 10000;
          }

          const timeoutId = setTimeout(() => {
            poll();
            timeoutRefs.delete(timeoutId);
          }, intervalTime);

          timeoutRefs.add(timeoutId);
          intervalTime = nextIntervalTime;
        }
      } catch (error: any) {
        if (error.response)
          reject(
            new CustomError({
              code: error.response.data?.code ?? error.response.status,
              detail: error.response.data?.detail,
              message: error.response.data.message,
              severity: error.response.data.severity,
            })
          );

        // if something went wrong need to send this as default for displaying error
        reject(
          new CustomError({
            code: 0,
            message: "",
            severity: "error",
          })
        );
        return;
      }
    };

    poll();
  });
};
