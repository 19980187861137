import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import { Auth } from "aws-amplify";
import { shallow } from "zustand/shallow";
import html2canvas from "html2canvas";
import * as htmlToImage from "html-to-image";

import { useAppStore } from "@/features/app";

import { MODULE_SPECIFIC_LINKS, OPERATIONAL_HELP_LINKS, openHelpLink } from "@/features/help-link";
import { TenantConfigs } from "@/tenants";
import routeUrlMap from "@/constants/routeUrl";
import { downloadCsv } from "@/utils/csvProcessing";

// hooks
import useThemeStyling from "@/utils/useThemeStyling";
import useModuleList from "../Modules/hooks/useModuleList";
import { useTreeViewState } from "../TreeView/hooks/TreeViewContextV2";
import { DialogEnum } from "../TreeView/types";
import { useSettings } from "@/SettingsState";
import { DataSet, ModuleId } from "@/model";
import { downloadImg } from "@/utils/imageProcessing";
import { CsvData } from "@/features/app/app.types";
import { getIdFromActiveKey } from "../TreeView/utils";
import dictionary from "@/constants/dictionary";
import { logoutApi } from "@/constants/apiUrl";
import { fetchApi } from "@/utils/apiFetcher";

// data structure of menu dropdown
// if only have 1 section dont display section title
export type MenuSection = {
  section?: string;
  items: {
    id?: string;
    label: string;
    onClickMenu: () => void;
    disabled?: boolean;
    csvData?: CsvData[];
  }[];
  hideSectionTitle?: boolean;
};

const handleDownloadImg = async (fileName: string) => {
  const element = document.getElementById("moduleBaseSection");
  if (element) {
    htmlToImage.toJpeg(element, { quality: 0.95, skipAutoScale: true, backgroundColor: "white" }).then(function (url) {
      downloadImg(url, fileName);
    });
  }
};

const useNavMenu = () => {
  const {
    user,
    tenant,
    getBuildNumberText,
    setTenant,
    currentModule,
    setCurrentModule,
    canSaveAsImg,
    isLoading,
    selectedDataSets,
    group,
    csvData,
    project,
    selectedKey,
    setGroup,
    setProject,
    setSelectedDataSets,
    setSelectedKey,
    setCsvData,
  } = useAppStore(
    (state) => ({
      getBuildNumberText: state.getBuildNumberText,
      user: state.user,
      tenant: state.tenant,
      setTenant: state.setTenant,
      currentModule: state.currentModule,
      setCurrentModule: state.setCurrentModule,
      canSaveAsImg: state.canSaveAsImg,
      isLoading: state.isLoading,
      selectedDataSets: state.selectedDataSets,
      group: state.group,
      csvData: state.csvData,
      project: state.project,
      selectedKey: state.selectedKey,
      setProject: state.setProject,
      setGroup: state.setGroup,
      setSelectedDataSets: state.setSelectedDataSets,
      setSelectedKey: state.setSelectedKey,
      setCsvData: state.setCsvData,
    }),
    shallow
  );
  const { modules } = useModuleList();
  const { setActiveDialog } = useTreeViewState();
  const { dataSet, groupIds } = getIdFromActiveKey(selectedKey ?? "");

  const { saveSetting, saveSingleSetting } = useSettings();

  const isSpadDecline = window.location.pathname.includes("spad/decline");
  const noChart = !window.location.pathname.includes("/modules/");
  const isKoldunCsg = window.location.pathname.includes("/koldun/mcsim/csg");

  const signOut = useCallback(async () => {
    try {
      await fetchApi({ path: logoutApi, type: "del" });
      await Auth.signOut({ global: true });
    } catch (e) {
      console.error(e);
    } finally {
      localStorage.clear();
      setTenant(undefined);
    }
  }, [setTenant]);

  const { theme } = useThemeStyling();
  const navigate = useNavigate();

  const resetProject = useCallback(() => {
    setGroup();
    setProject();
    setSelectedDataSets();
    setSelectedKey();
  }, [setGroup, setProject, setSelectedDataSets, setSelectedKey]);

  const handleScreenCapture = (captured: string) => {
    downloadImg(captured, "AFA-custom-capture");
    return null;
  };

  const isAutoSave = isKoldunCsg || isSpadDecline;

  const fileMenuList: MenuSection[] = useMemo(() => {
    const saveChartImageUtil = {
      label: "JPEG",
      onClickMenu: () => {
        const imgTitle = `${currentModule} - ${
          (selectedDataSets as DataSet[]) instanceof Array ? group?.name : selectedDataSets && (selectedDataSets as DataSet).name
        } `;
        handleDownloadImg(imgTitle);
      },
      disabled: !canSaveAsImg || isLoading || noChart,
    };

    const downloadChartCsv = {
      id: "export-csv",
      label: "CSV",
      onClickMenu: () => {
        csvData?.forEach((data) => {
          downloadCsv(data.data, data.fileName);
        });
      },
      disabled: !isKoldunCsg || !csvData || noChart,

      csvData,
    };

    const screenCaptureEntireScreen = {
      id: "screen-capture-entire",
      label: "From Entire Screen...",
      onClickMenu: () => {
        const body = document.getElementById("inner-body");

        if (body) {
          const filename = `AFA-Screenshot-${new Date().toLocaleDateString()}`;
          const windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
          const windowHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

          html2canvas(body, {
            width: windowWidth,
            height: windowHeight,
          }).then((canvas: any) => {
            const url = canvas.toDataURL("image/jpeg", 0.5);
            downloadImg(url, filename);
          });
        }
      },
    };

    const saveTableAsCsv = {
      id: "export-csv",
      label: "CSV",
      onClickMenu: () => {
        csvData?.forEach((data) => {
          downloadCsv(data.data, data.fileName);
        });
      },
      disabled: !csvData || noChart,
      csvData,
    };

    const importWells = {
      label: dictionary.nav.wells,
      onClickMenu: () => {
        navigate(`/${routeUrlMap.importWells}`);
      },
    };

    const importProjectStructure = {
      label: dictionary.nav.projects,
      onClickMenu: () => {
        navigate(`/${routeUrlMap.importProjects}`);
      },
    };

    return [
      {
        section: dictionary.nav.import,
        hideSectionTitle: false,
        items: [importWells, importProjectStructure],
      },
      {
        section: "Save",
        hideSectionTitle: true,
        items: isAutoSave
          ? [
              {
                label: "This module is auto saved",
                onClickMenu: () => {},
                disabled: isAutoSave,
              },
            ]
          : [
              {
                label: "Save Current Well",
                onClickMenu: () => {
                  saveSingleSetting(project?.id ?? "", group?.id ?? "", dataSet);
                },
                disabled: isAutoSave || !selectedKey,
              },
              {
                label: "Save All Projects",
                onClickMenu: () => {
                  saveSetting();
                },
                disabled: isAutoSave,
              },
            ],
      },
      {
        section: "Save Chart As",
        items: [saveChartImageUtil, downloadChartCsv],
      },
      {
        section: "Save Table As",
        items: [saveTableAsCsv],
      },
      {
        section: "Take Screenshot",
        items: [screenCaptureEntireScreen],
      },
    ];
  }, [
    canSaveAsImg,
    isLoading,
    noChart,
    isKoldunCsg,
    csvData,
    isAutoSave,
    selectedKey,
    currentModule,
    selectedDataSets,
    group?.name,
    group?.id,
    navigate,
    saveSingleSetting,
    project?.id,
    dataSet,
    saveSetting,
  ]);

  const modulesMenuList: MenuSection[] = useMemo(() => {
    if (modules.length === 0)
      return [
        {
          section: "Choose a well or group to get started.",
          items: [],
        },
      ];

    const mappedModuleList = _.flatten(modules).map((section) => {
      return {
        section: section.section,
        items: section.items.map((item) => {
          return {
            label: item.label,
            onClickMenu: () => {
              navigate(`/${routeUrlMap.modules}/${item.route}`);
              setCurrentModule(item.key);
              setCsvData();
            },
            disabled: item.disabled,
          };
        }),
      };
    });

    const gridList = [
      {
        section: "Grid",
        hideSectionTitle: true,
        items: [
          {
            label: "Grid",
            onClickMenu: () => {
              navigate(`/${routeUrlMap.modules}`);
              resetProject();
            },
          },
        ],
      },
      ...mappedModuleList,
    ];

    // this will be remove after spad decline dev done
    // only avail in local and dev env
    if (window.location.href.includes("dev") || window.location.href.includes("localhost")) {
      gridList[0].items = gridList[0].items.concat([
        {
          label: "Spad Decline Gas V2",
          onClickMenu: () => {
            navigate(`/${routeUrlMap.modules}/${routeUrlMap.spad_decline_gas}/v2`);
            setCurrentModule(ModuleId.SPAD_DECLINE_GAS);
            setCsvData();
          },
          disabled: false,
        },
        {
          label: "Spad Decline Oil V2",
          onClickMenu: () => {
            navigate(`/${routeUrlMap.modules}/${routeUrlMap.spad_decline_oil}/v2`);
            setCurrentModule(ModuleId.SPAD_DECLINE_OIL);
            setCsvData();
          },
          disabled: false,
        },
      ]);
    }

    return gridList;
  }, [modules, navigate, resetProject, setCsvData, setCurrentModule]);

  const primaryMenuList: MenuSection[] = [
    {
      section: "predico",
      hideSectionTitle: true,
      items: [
        {
          label: "About",
          onClickMenu: () => navigate(`/${routeUrlMap.settings}`),
        },
        {
          label: "Term of Service",
          onClickMenu: () => navigate(`/${routeUrlMap.settings}?tab=3`),
        },
        {
          label: "Settings",
          onClickMenu: () => navigate(`/${routeUrlMap.settings}?tab=1`),
        },
        {
          label: "Quit AFA",
          onClickMenu: () => {
            signOut();
          },
        },
      ],
    },
  ];

  const projectMenuList: MenuSection[] = useMemo(() => {
    const isSelectedGroup = groupIds.length > 0;
    return [
      {
        section: "Project",
        hideSectionTitle: true,
        items: [
          {
            label: "New Project",
            onClickMenu: () => {
              setActiveDialog(DialogEnum.NEW_PROJECT);
            },
          },
          {
            label: "Project Settings",
            disabled: !project,
            onClickMenu: () => {
              setActiveDialog(DialogEnum.PROJECT_SETTING);
            },
          },
          {
            label: "Rename Project",
            disabled: !project,
            onClickMenu: () => {
              setActiveDialog(DialogEnum.EDIT_PROJECT);
            },
          },
          {
            label: "Delete Project",
            disabled: !project,
            onClickMenu: () => {
              setActiveDialog(DialogEnum.DELETE_PROJECT);
            },
          },
          {
            label: "Remove Current Well",
            onClickMenu: () => {
              setActiveDialog(DialogEnum.REMOVE_DATASET);
            },
            disabled: dataSet.length !== 1,
          },
        ],
      },
      {
        section: "Group",
        items: [
          {
            label: "New Group",
            onClickMenu: () => {
              setActiveDialog(DialogEnum.NEW_GROUP);
            },
            disabled: !project || (group?.data_set_ids && group.data_set_ids.length > 0),
          },
          {
            label: "Rename Group",
            onClickMenu: () => {
              setActiveDialog(DialogEnum.EDIT_GROUP);
            },
            disabled: !isSelectedGroup,
          },
          {
            label: "Delete Group",
            onClickMenu: () => {
              setActiveDialog(DialogEnum.DELETE_GROUP);
            },
            disabled: !isSelectedGroup,
          },
        ],
      },
    ];
  }, [dataSet.length, group?.data_set_ids, groupIds.length, project, setActiveDialog]);

  const buildNumberText = getBuildNumberText();

  const helpMenuList = useMemo(() => {
    return [
      {
        section: "Help",
        hideSectionTitle: true,
        items: [
          {
            label: "User Manual",
            onClickMenu: () => {
              if (currentModule && MODULE_SPECIFIC_LINKS.includes(currentModule)) {
                openHelpLink(OPERATIONAL_HELP_LINKS[currentModule]);
              } else {
                openHelpLink(OPERATIONAL_HELP_LINKS.index);
              }
            },
          },

          {
            label: "Contact Support",
            onClickMenu: () => {
              const mailtoLink = `mailto:support@predico.com.au?subject=Version ${buildNumberText}`;
              window.open(mailtoLink, "_blank")?.focus();
            },
          },
          {
            label: "Release Notes",
            onClickMenu: () => navigate(`/${routeUrlMap.settings}?tab=2`),
          },
        ],
      },
    ];
  }, [buildNumberText, currentModule, navigate]);

  return {
    theme,
    fileMenuList,
    modulesMenuList,
    primaryMenuList,
    projectMenuList,
    helpMenuList,
    buildNumberText,
    email: user.signInUserSession.idToken.payload.email ?? "",
    tenant: tenant ? TenantConfigs[tenant].name : "Predico",
    handleScreenCapture,
    resetProject,
  };
};

export default useNavMenu;
