import React from "react";
import { Stack, Spinner, SpinnerSize } from "@fluentui/react";

const LoadingSpinner = () => {
  return (
    <Stack style={{ height: "100%", justifyContent: "center" }}>
      <Spinner label="loading..." ariaLive="assertive" labelPosition="right" size={SpinnerSize.large} />
    </Stack>
  );
};

export default LoadingSpinner;
