import { fetchApi } from "@/utils/apiFetcher";
import { saveLegacyWellSettings } from "@/constants/apiUrl";
import { WellSettingLegacyProject } from "../State";

export const postSaveLegacyWellSettings = (projects: WellSettingLegacyProject[]) => {
  return fetchApi<string, WellSettingLegacyProject[]>({
    path: saveLegacyWellSettings,
    type: "post",
    body: projects,
  });
};
