import { PvtTableHeaderMap } from "@/models/Generic";
import { DataViewData } from "@/models/gaz/autoRta";
import { Column } from "@silevis/reactgrid";

export const gazAutoRtaForecastInputDefaultValue = {
  smart_fill: true,
  economic_cutoff: 0,
  forecast_events: [],
};

const headerStyle = {
  backgroundColor: "white",
  fontWeight: "bold",
  display: "flex",
  justifyContent: "center",
};

export const dataTableCentered = {
  background: "#fff",
  display: "flex",
  justifyContent: "center",
  zIndex: 1,
};

export const gazAutoRtaForecastEventHeader = {
  rowId: "header",
  cells: [
    {
      type: "header",
      text: "Date",
      style: headerStyle,
    },
    {
      type: "header",
      text: "Flowing Pressure (psia)",
      style: headerStyle,
    },
  ],
};
export const gazAutoRtaForecastDataTableHeader = {
  rowId: "header",
  cells: [
    {
      type: "header",
      text: "Date",
      style: headerStyle,
    },
    {
      type: "header",
      text: "Flowing Pressure",
      style: headerStyle,
    },
    {
      type: "header",
      text: "Gas Produced",
      style: headerStyle,
    },
    {
      type: "header",
      text: "Gas Rate",
      style: headerStyle,
    },
  ],
};
export const gazAutoRtaForecastDataTableHeaderUnits = {
  rowId: "units",
  cells: [
    {
      type: "header",
      text: "",
      style: dataTableCentered,
    },
    {
      type: "header",
      text: "(psia)",
      style: dataTableCentered,
    },
    {
      type: "header",
      text: "(MMscf)",
      style: dataTableCentered,
    },
    {
      type: "header",
      text: "(MMscf/D)",
      style: dataTableCentered,
    },
  ],
};

export const parameterTableColumns = [
  { key: "parameter", name: "Parameter", fieldName: "parameter", isRowHeader: true, minWidth: 140 },
  { key: "value", name: "Value", fieldName: "value", minWidth: 100 },
  { key: "units", name: "Units", fieldName: "units", minWidth: 80 },
];

export const oilPvtDataTableColumns = [
  { columnId: "pressure", width: 80 },
  { columnId: "viscosity", width: 80 },
  { columnId: "density", width: 80 },
  { columnId: "form_vol_factor", width: 80 },
  { columnId: "compressibility", width: 100 },
  { columnId: "solution_gas_oil_ratio", width: 80 },
  { columnId: "superficial_tension", width: 80 },
] as Column[];

export const waterPvtHeaderUnitMapping: { [key: string]: PvtTableHeaderMap } = {
  pressure: { name: "Pressure", unit: "(psia)" },
  viscosity: { name: "μ", unit: "cP", sub: "w" },
  density: { name: `p`, unit: "lbm/ft³", sub: "w" },
  form_vol_factor: { name: "B", unit: "(rb/stb)", sub: "w" },
  compressibility: { name: "c", unit: "(psi⁻¹)", sub: "w" },
};

export const analysisInputDefaultValue = {
  smart_fit: true,
  transient_flow_model: {
    section_transient_model_type: {
      selected_transient_model: "",
      transient_model_radial: {
        drainage_radius: 0,
      },
      transient_model_linear: {
        drainage_length: 0,
        drainage_width: 0,
      },
      transient_model_composite_radial: {
        drainage_radius: 0,
        inner_region_radius: 0,
        inner_region_permeability: 0,
      },
    },
    selected_boundary_condition: "",
    skin: 0,
    permeability: 0,
    section_dual_porosity: {
      selected_dual_porosity_model: "",
      storage_capacity_ratio: 0,
      interpososity_flow_parameter: 0,
    },
  },
};

export const normalizedPressureXaxisOptions = [
  { key: "wellbore_radius_normalized_time", text: "tDrw" },
  { key: "area_normalized_time", text: "tDA" },
  { key: "decline_time", text: "tDd" },
];

export const transientAnalysisModel = [
  { key: "Radial", text: "Radial" },
  { key: "Linear", text: "Linear" },
  { key: "RadialComposite", text: "Composite Radial" },
];

export const selectedBoundaryConditionOptions = [
  { key: "NoFlow", text: "No Flow Boundary" },
  { key: "InfiniteActing", text: "Infinite Acting" },
  { key: "ConstantPressure", text: "Constant Pressure" },
];

export const dualPorosityModelOptions = [
  { key: "NoModel", text: "No Model" },
  { key: "WarrenRoot", text: "Warren Root" },
  { key: "BelloSlab", text: "Bello Slab" },
];

export const analysisDataColumns = [
  { columnId: "wellbore_radius_normalized_time" },
  { columnId: "area_normalized_time" },
  { columnId: "decline_time" },
  { columnId: "beta_derivative" },
  { columnId: "bourdet_derivative" },
  { columnId: "chow_pressure_group" },
  { columnId: "normalized_pressure" },
  { columnId: "normalized_pressure_integral" },
  { columnId: "normalized_pressure_integral_derivative" },
  { columnId: "decline_rate" },
  { columnId: "decline_rate_integral" },
  { columnId: "decline_rate_integral_derivative" },
  { columnId: "normalized_rate" },
] as Column[];

export const analysisTableHeader: Record<string, string> = {
  wellbore_radius_normalized_time: "tDrw",
  area_normalized_time: "tDA",
  decline_time: "tDd",
  beta_derivative: "pβd",
  bourdet_derivative: "pD'",
  chow_pressure_group: "CPG",
  normalized_pressure: "pD",
  normalized_pressure_integral: "pDi",
  normalized_pressure_integral_derivative: "pDid",
  decline_rate: "qDd",
  decline_rate_integral: "qDdi",
  decline_rate_integral_derivative: "qDdid",
  normalized_rate: "qD",
};

export const dataTableHeaderStyles = {
  background: "#fff",
  display: "flex",
  justifyContent: "center",
  fontWeight: "bold",
  zIndex: 1,
};

export const gazAutoRtaDefaultState = {
  gazAutoRTAState: null,
  setGazAutoRTAState: () => {},
  setDataViewCalculation: () => {},
  isLoading: false,
  setApiError: () => {},
};

export const gasPvtDataTableCols = [
  { columnId: "pressure", width: 80 },
  { columnId: "compressibility_factor", width: 80, justifyContent: "center" },
  { columnId: "viscosity", width: 80, justifyContent: "center" },
  { columnId: "density", width: 80, justifyContent: "center" },
  { columnId: "form_vol_factor", width: 80, justifyContent: "center" },
  { columnId: "compressibility", width: 80, justifyContent: "center" },
] as Column[];

export const gasPvtHeaderUnitMapping: { [key: string]: PvtTableHeaderMap } = {
  pressure: { name: "Pressure", unit: "(psia)" },
  compressibility_factor: { name: "Z", unit: "(Dim)" },
  viscosity: { name: "μ", unit: "cP", sub: "g" },
  density: { name: "p", unit: "lbm/ft³", sub: "g" },
  form_vol_factor: { name: "B", unit: "(ft³/scf)", sub: "g" },
  compressibility: { name: "c", unit: "(psi⁻¹)", sub: "g" },
};

export const waterPvtDataTableCols = [
  { columnId: "pressure", width: 80 },
  { columnId: "compressibility", width: 80, justifyContent: "center" },
  { columnId: "density", width: 80, justifyContent: "center" },
  { columnId: "form_vol_factor", width: 80, justifyContent: "center" },
  { columnId: "viscosity", width: 80, justifyContent: "center" },
] as Column[];

export const oilPvtHeaderUnitMapping: { [key: string]: PvtTableHeaderMap } = {
  pressure: { name: "Pressure", unit: "(psia)" },
  viscosity: { name: "μ", unit: "(cP)", sub: "o" },
  density: { name: "p", unit: "(lbm/ft³)", sub: "o" },
  form_vol_factor: { name: "B", unit: "(rb/stb)", sub: "o" },
  compressibility: { name: "c", unit: "(psi⁻¹)", sub: "o" },
  solution_gas_oil_ratio: { name: "R", unit: "(scf/stb)", sub: "s" },
  superficial_tension: { name: "σ", unit: "(dynes/cm)", sub: "o" },
};

export const langmuirIsothermHeaderUnitMapping: { [key: string]: PvtTableHeaderMap } = {
  pressure: { name: "Pressure", unit: "(psia)" },
  gas_content: { name: "gas content", unit: "(scf/ton)" },
  desorption_compressibility: { name: "c", unit: "(psi⁻¹)", sub: "d" },
};

export const langmuirIsothermDataTableCols = [
  { columnId: "pressure", width: 120 },
  { columnId: "gas_content", width: 120, justifyContent: "center" },
  { columnId: "desorption_compressibility", width: 120, justifyContent: "center" },
] as Column[];

export const headerDataTableUnitStyles = { background: "#fff", display: "flex", justifyContent: "center", zIndex: 1 };

export const waterCompressibilityOptions = [
  { key: "McCain", text: "McCain" },
  { key: "BeggsBrill", text: "Beggs & Brill" },
];

export const dataTableColumns = [
  { key: "parameter", name: "Parameter", fieldName: "parameter", isRowHeader: true, minWidth: 220 },
  { key: "value", name: "Value", fieldName: "value", minWidth: 80 },
  { key: "units", name: "Units", fieldName: "units", minWidth: 80 },
];

export const formationCompressibilityOptions = [
  {
    key: "NewmanSandstone",
    text: "Newman Sandstone",
  },
  {
    key: "NewmanLimestone",
    text: "Newman Limestone",
  },
  {
    key: "Hall",
    text: "Hall",
  },
  {
    key: "Constant",
    text: "Constant",
  },
];

export const oilDensityCorrelationOptions = [
  { key: "BlackOil", text: "Black Oil" },
  { key: "StandingKatz", text: "Standing Katz" },
];

export const oilViscosityCorrelationOptions = [{ key: "VasquezBeggs", text: "Vasquez/Beggs" }];

export const solutionGasOilRatioCorrelationOptions = [
  { key: "VasquezBeggs", text: "Vasquez/Beggs" },
  { key: "Standing", text: "Standing" },
  { key: "Glaso", text: "Glaso" },
  { key: "Lasater", text: "Lasater" },
  { key: "Petrosky", text: "Petrosky" },
];

export const cleaningSmoothingTypeOptions = [
  { key: "interp", text: "Interpolation" },
  { key: "movavg", text: "Moving average" },
];

export const zMethodOptions = [
  { key: "DranchukPurvisRobinson", text: "Dranchuk Purvis & Robinson" },
  { key: "BeggsBrill", text: "Beggs & Brill" },
  { key: "Papay", text: "Papay" },
  { key: "DranchukAbouKassem", text: "Dranchuk & Abou-Kassem" },
];

export const gasViscMethodOptions = [
  { key: "GonzalesEakin", text: "Gonzales & Eakin" },
  { key: "Londono", text: "Londono" },
  { key: "Standing", text: "Standing" },
];

export const waterViscMethodOptions = [
  { key: "McCain", text: "McCain" },
  { key: "LouisianaStateUniversity", text: "Louisiana State University" },
  { key: "BeggsBrill", text: "Beggs & Brill" },
];

export const dataViewRawDataHeaderMapping: Record<string, { property: keyof DataViewData; name: string; unit: string }> = {
  dates: { property: "dates", name: "Dates", unit: "" },
  gas_rate: { property: "gas_rate", name: "Gas rate", unit: "(MMscf/D)" },
  casing_pressure: { property: "casing_pressure", name: "Casing pressure", unit: "(psia)" },
  tubing_pressure: { property: "tubing_pressure", name: "Tubing Pressure", unit: "(psia)" },
  gas_production: { property: "gas_production", name: "Gas Produced", unit: "(MMscf)" },
};
