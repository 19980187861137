import { createPoll } from "@/utils/apiFetcher";
import { gazAutoRtaDataView } from "@/constants/apiUrl";
import { DataViewCalculation, GazAutoRtaDataView } from "../DataView";

type CalculateDataViewQuery = {
  data_set_ids: string[];
};
export const pollCalculateDataViewGazAutoRta = (payload: GazAutoRtaDataView, query: CalculateDataViewQuery) => {
  return createPoll<DataViewCalculation, GazAutoRtaDataView>({
    path: gazAutoRtaDataView,
    body: payload,
    queryStringParameters: query,
  });
};
