import React from "react";
import { shallow } from "zustand/shallow";

import ModulePage from "@/modules/ModulePage";
import { ModuleSpadDeclineProps } from "@/features/modules/spad/decline";

import { useAppStore } from "@/features/app";

import SpadDeclineProvider, { useSpadDeclineState } from "./hooks/SpadDeclineContext";

import SpadDeclineDataView from "./components/SpadDeclineDataView";
import SpadDeclineAnalysis from "./components/SpadDeclineAnalysisContainer";

const ModuleSpadDeclineContent = ({ title }: ModuleSpadDeclineProps) => {
  const { isLoading, isForecastBeforeData, moduleFields, setCleaned, cleaned, dataSets, setTabIndex, tabIndex, setLocalLoading } =
    useSpadDeclineState();

  return (
    <ModulePage
      title={title}
      onTabChange={setTabIndex}
      tabIndex={tabIndex}
      tabs={
        dataSets && [
          {
            headerText: "Data View",
            itemIcon: "ScatterChart",
            disabled: isLoading,
            content: (
              <SpadDeclineDataView
                isActive={tabIndex === 0}
                setCleaned={setCleaned}
                isForecastBeforeData={isForecastBeforeData}
                moduleFields={moduleFields}
                setLoading={setLocalLoading}
              />
            ),
            canSaveAsImg: true,
          },
          {
            headerText: "Analysis",
            itemIcon: "LineChart",
            disabled: isLoading || !cleaned || isForecastBeforeData,
            content: tabIndex === 1 ? <SpadDeclineAnalysis /> : <></>,
            canSaveAsImg: true,
          },
        ]
      }
    />
  );
};

export const ModuleSpadDecline = ({ title }: ModuleSpadDeclineProps) => {
  const { selectedDataSets, currentModule, hideSidebar, project, postRequest, isLoading, group, setCsvData, apiError } = useAppStore(
    (state) => ({
      selectedDataSets: state.selectedDataSets,
      hideSidebar: state.hideSidebar,
      currentModule: state.currentModule,
      project: state.project,
      isLoading: state.isLoading,
      postRequest: state.postRequest,
      group: state.group,
      setCsvData: state.setCsvData,
      apiError: state.apiError,
    }),
    shallow
  );

  return (
    <SpadDeclineProvider
      isLoading={isLoading}
      postRequest={postRequest}
      hideSidebar={hideSidebar}
      currentModule={currentModule}
      selectedDataSets={selectedDataSets}
      group={group}
      project={project}
      setCsvData={setCsvData}
      apiError={apiError}
    >
      <ModuleSpadDeclineContent title={title} />
    </SpadDeclineProvider>
  );
};
