import { createTheme } from "@mui/material";

type CustomColor = {
  accent: string;
  black: string;
  blackTranslucent40: string;
  blue: string;
  blueDark: string;
  blueLight: string;
  blueMid: string;
  green: string;
  greenDark: string;
  greenLight: string;
  magenta: string;
  magentaDark: string;
  magentaLight: string;
  neutralDark: string;
  neutralLight: string;
  neutralLighter: string;
  neutralLighterAlt: string;
  neutralPrimary: string;
  neutralPrimaryAlt: string;
  neutralQuaternary: string;
  neutralQuaternaryAlt: string;
  neutralSecondary: string;
  neutralSecondaryAlt: string;
  neutralTertiary: string;
  neutralTertiaryAlt: string;
  orange: string;
  orangeLight: string;
  orangeLighter: string;
  purple: string;
  purpleDark: string;
  purpleLight: string;
  red: string;
  redDark: string;
  teal: string;
  tealDark: string;
  tealLight: string;
  themeDark: string;
  themeDarkAlt: string;
  themeDarker: string;
  themeLight: string;
  themeLighter: string;
  themeLighterAlt: string;
  themePrimary: string;
  themeSecondary: string;
  themeTertiary: string;
  white: string;
  whiteTranslucent40: string;
  yellow: string;
  yellowDark: string;
  yellowLight: string;
};
declare module "@mui/material/styles" {
  interface Palette {
    customColor: CustomColor;
  }
  interface PaletteOptions {
    customColor: CustomColor;
  }
}
export const getMuiTheme = () =>
  createTheme({
    typography: {
      fontFamily: ['"Baloo 2"', "sans-serif"].join(","),
    },

    palette: {
      primary: {
        main: "#f28f1d",
        light: "#fbdcb9",
        dark: "#b86d16",
      },
      secondary: {
        main: "#931d2d",
      },
      success: {
        main: "#5FAD56",
      },

      customColor: {
        accent: "#f28f1d",
        black: "#1d1d1d",
        blackTranslucent40: "rgba(0,0,0,.4)",
        blue: "#044B7F",
        blueDark: "#022F4F",
        blueLight: "#0676C6",
        blueMid: "#00188f",
        green: "#5FAD56",
        greenDark: "#386832",
        greenLight: "#87c180",
        magenta: "#b4009e",
        magentaDark: "#5c005c",
        magentaLight: "#e3008c",
        neutralDark: "#272727",
        neutralLight: "#edebe9",
        neutralLighter: "#f3f2f1",
        neutralLighterAlt: "#faf9f8",
        neutralPrimary: "#333333",
        neutralPrimaryAlt: "#4b4b4b",
        neutralQuaternary: "#d0d0d0",
        neutralQuaternaryAlt: "#e1dfdd",
        neutralSecondary: "#858585",
        neutralSecondaryAlt: "#858585",
        neutralTertiary: "#c2c2c2",
        neutralTertiaryAlt: "#c8c6c4",
        orange: "#d83b01",
        orangeLight: "#ea4300",
        orangeLighter: "#ff8c00",
        purple: "#5c2d91",
        purpleDark: "#32145a",
        purpleLight: "#b4a0ff",
        red: "#C5283D",
        redDark: "#931d2d",
        teal: "#008272",
        tealDark: "#004b50",
        tealLight: "#00b294",
        themeDark: "#b86d16",
        themeDarkAlt: "#da801a",
        themeDarker: "#885010",
        themeLight: "#fbdcb9",
        themeLighter: "#fdecd9",
        themeLighterAlt: "#fefaf6",
        themePrimary: "#f28f1d",
        themeSecondary: "#f49c37",
        themeTertiary: "#f7ba75",
        white: "#ffffff",
        whiteTranslucent40: "rgba(255,255,255,.4)",
        yellow: "#ffb900",
        yellowDark: "#d29200",
        yellowLight: "#fff100",
      },
    },
  });
