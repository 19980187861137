import { ReactElement } from "react";
import { Text, Pivot, PivotItem, Checkbox, PrimaryButton } from "@fluentui/react";
import FossilyticsNumberField from "@/components/fields/FossilyticsNumberField";

import useThemeStyling from "@/utils/useThemeStyling";
import { getAnalysisParameter } from "../../index.utils";
import { useSpadDeclineState } from "../../hooks/SpadDeclineContext";
import CaseContainer from "./CaseContainer";

type SpadDeclineAnalysisParameterProps = {
  compareDropdown: ReactElement;
};

const SpadDeclineAnalysisParameter = ({ compareDropdown }: SpadDeclineAnalysisParameterProps) => {
  const { elevatedCard, theme } = useThemeStyling();
  const {
    downloadExport,
    unit,
    options,
    onChangeOption,
    paramsOps,
    paramsProfile,
    setParamsOps,
    setParamsProfile,
    selectedDeclineTypePivotKey,
    setSelectedDeclineTypePivotKey,
    isLoading,
    analysis,
    setIsFromBeFlag,
    errorInputValidation,
    cleaned,
    finishCalculation,
    isApiError,
    dataSets,
  } = useSpadDeclineState();

  const ifOpsEmpty = getAnalysisParameter(paramsOps);
  const ifProfileEmpty = getAnalysisParameter(paramsOps);

  const isNotComplete =
    (Object.keys(ifProfileEmpty).length === 0 || Object.keys(ifOpsEmpty).length === 0) &&
    !isLoading &&
    !!cleaned &&
    finishCalculation &&
    String(isApiError?.code).includes("6");

  return (
    <div style={{ display: "grid", gap: 15 }}>
      <div style={elevatedCard}>
        <Text styles={{ root: { color: theme.palette.themePrimary } }}>Cluster Analysis</Text>
        <Checkbox
          label="Auto update cluster start day"
          styles={{ text: { color: theme.palette.themePrimary }, root: { margin: "10px 0" } }}
          checked={options.auto_update_cluster_start_day}
          onChange={(_, v) => {
            onChangeOption(v ?? false, "auto_update_cluster_start_day");
          }}
          disabled={isLoading}
        />
        <FossilyticsNumberField
          type="int"
          label="Cluster start day"
          debounceDelay={1000}
          value={options.analysis_start_day}
          onChange={(v) => {
            onChangeOption(v, "analysis_start_day");
          }}
          errors={errorInputValidation}
          keyField="analysis_option.analysis_start_day"
          disabled={options.auto_update_cluster_start_day || isLoading}
        />
      </div>

      <div
        style={{
          ...elevatedCard,
          borderColor: "red",
          borderWidth: isNotComplete ? 1 : 0,
          borderStyle: "solid",
        }}
      >
        <Text styles={{ root: { color: theme.palette.themePrimary } }}>Decline parameters</Text>

        <Checkbox
          label="Auto update Smart-Fit"
          styles={{ text: { color: theme.palette.themePrimary }, root: { margin: "10px 0" } }}
          checked={options.auto_update_smart_fit}
          onChange={(_, v) => {
            onChangeOption(v ?? false, "auto_update_smart_fit");
          }}
          disabled={isLoading}
        />
        {isNotComplete && (
          <div
            style={{
              color: "red",
            }}
          >
            Please fill all input field to proceed
          </div>
        )}
        <Pivot
          selectedKey={selectedDeclineTypePivotKey}
          onLinkClick={(item) => setSelectedDeclineTypePivotKey((item?.props.itemKey as any) ?? "ops")}
        >
          <PivotItem itemKey="ops" headerText="OPS Forecast">
            <CaseContainer
              disabled={options.auto_update_smart_fit}
              onChange={(param) => {
                setParamsOps(param);
                setIsFromBeFlag();
              }}
              unit={unit}
              params={paramsOps}
              key={dataSets.join(", ")}
            />
          </PivotItem>

          <PivotItem itemKey="profile" headerText="Profile Forecast">
            <CaseContainer
              disabled={options.auto_update_smart_fit}
              onChange={(param) => {
                setParamsProfile(param);
                setIsFromBeFlag();
              }}
              unit={unit}
              params={paramsProfile}
              key={dataSets.join(", ")}
            />
          </PivotItem>
        </Pivot>
      </div>

      <div style={elevatedCard}>
        <Text styles={{ root: { color: theme.palette.themePrimary } }}>Forecast parameters</Text>
        <FossilyticsNumberField
          label="Economic cutoff"
          suffix={`${unit}/d`}
          debounceDelay={1000}
          value={options?.economic_cutoff}
          onChange={(v) => {
            return v !== undefined ? onChangeOption(v, "economic_cutoff") : v;
          }}
          disabled={isLoading}
          errors={errorInputValidation}
          keyField="analysis_option.economic_cutoff"
        />
        <FossilyticsNumberField
          label="Recovery factor"
          suffix="%"
          debounceDelay={1000}
          value={options?.recovery_factor}
          onChange={(v) => {
            return v !== undefined ? onChangeOption(v, "recovery_factor") : v;
          }}
          disabled={isLoading}
          keyField="analysis_option.recovery_factor"
          errors={errorInputValidation}
        />
        <div
          style={{
            marginTop: 15,
          }}
        >
          <PrimaryButton
            disabled={isLoading || !analysis?.profile?.arps?.low || !analysis?.profile?.arps?.mid || !analysis?.profile?.arps?.high}
            split
            style={{ width: 211 }}
            menuProps={{
              items: [
                {
                  key: "daily",
                  text: "Export daily",
                  onClick: () => {
                    downloadExport("daily");
                  },
                },
                {
                  key: "yearly",
                  text: "Export yearly",
                  onClick: () => {
                    downloadExport("yearly");
                  },
                },
              ],
            }}
            onClick={() => {
              downloadExport("monthly");
            }}
          >
            Export monthly
          </PrimaryButton>
        </div>
      </div>
      <div style={elevatedCard}>{compareDropdown}</div>
    </div>
  );
};

export default SpadDeclineAnalysisParameter;
