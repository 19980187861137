import FossilyticsNumberField from "@/components/fields/FossilyticsNumberField";
import { Dropdown, Stack } from "@fluentui/react";

import { formationCompressibilityOptions } from "../../constants";
import { DefinedParameters, GazAutoRtaInputGrid } from "@/models/gaz/autoRta";

export interface DefinedParamProps {
  value: GazAutoRtaInputGrid;
  updateDefineParameterInputField: (v: number | string | undefined, key: keyof DefinedParameters) => void;
  isLoading: boolean;
}

const DefinedParameter = ({ value, updateDefineParameterInputField, isLoading }: DefinedParamProps) => {
  return (
    <Stack horizontal tokens={{ childrenGap: 20 }}>
      <Stack grow={1} className="equal-size" tokens={{ childrenGap: 5 }}>
        <FossilyticsNumberField
          label="Initial pressure"
          suffix="psia"
          debounceDelay={1000}
          value={value.defined_parameters.initial_pressure}
          onChange={(v) => updateDefineParameterInputField(v, "initial_pressure")}
          disabled={isLoading}
        />
        <FossilyticsNumberField
          label="Formation temperature"
          suffix="°F"
          debounceDelay={1000}
          value={value.defined_parameters.formation_temperature}
          onChange={(v) => updateDefineParameterInputField(v, "formation_temperature")}
          disabled={isLoading}
        />
        <FossilyticsNumberField
          label="Water Saturation"
          suffix="%"
          min={0}
          max={100}
          debounceDelay={1000}
          value={value.defined_parameters.initial_water_saturation}
          onChange={(v) => updateDefineParameterInputField(v, "initial_water_saturation")}
          disabled={isLoading}
        />
        <FossilyticsNumberField
          label="Porosity"
          suffix="%"
          debounceDelay={1000}
          value={value.defined_parameters.porosity}
          onChange={(v) => updateDefineParameterInputField(v, "porosity")}
          disabled={isLoading}
        />
      </Stack>
      <Stack grow={1} className="equal-size" tokens={{ childrenGap: 5 }}>
        <FossilyticsNumberField
          label="Wellbore radius"
          suffix="ft"
          debounceDelay={1000}
          value={value.defined_parameters.wellbore_radius}
          onChange={(v) => updateDefineParameterInputField(v, "wellbore_radius")}
          disabled={isLoading}
        />
        <FossilyticsNumberField
          label="Net pay"
          suffix="ft"
          debounceDelay={1000}
          value={value.defined_parameters.net_pay}
          onChange={(v) => updateDefineParameterInputField(v, "net_pay")}
          disabled={isLoading}
        />
        <Dropdown
          label="Formation compressibility (psi⁻¹)"
          options={formationCompressibilityOptions}
          selectedKey={value.defined_parameters.selected_formation_compressibility_correlation}
          onChange={(_, v) => updateDefineParameterInputField(v?.key, "selected_formation_compressibility_correlation")}
          disabled={isLoading}
        />
        {value.defined_parameters.selected_formation_compressibility_correlation === "Constant" && (
          <FossilyticsNumberField
            label=""
            suffix="1/psia"
            debounceDelay={1000}
            value={value.defined_parameters.formation_compressibility}
            onChange={(v) => updateDefineParameterInputField(v, "formation_compressibility")}
            disabled={isLoading}
          />
        )}
      </Stack>
    </Stack>
  );
};

export default DefinedParameter;
