import React, { PropsWithChildren } from "react";
import { Stack, Text } from "@fluentui/react";
import { ErrorBoundary } from "react-error-boundary";

const ErrorBoundaryWrapper = ({ children }: PropsWithChildren) => {
  const handleError = () => {
    // currently no error reporting
  };

  const defaultError = `Please contact support@predico.com.au for further assistance`;

  return (
    <ErrorBoundary
      FallbackComponent={(props) => {
        return (
          <Stack horizontalAlign="center" verticalAlign="center" style={{ height: "100%", padding: 20 }}>
            <Text variant="large" block>
              Something went
            </Text>
            <Text variant="xxLarge" block>
              wrong.
            </Text>
            <br />
            <Text block>{defaultError}</Text>
          </Stack>
        );
      }}
      onError={handleError}
    >
      {children}
    </ErrorBoundary>
  );
};

export default ErrorBoundaryWrapper;
