import { useMemo, useState } from "react";

import { TahkCsgInputLayerCharts } from "@/models/tahk";
import dictionary from "@/constants/dictionary";

export type UseTahkCsgSummaryCardProps = {
  layers?: TahkCsgInputLayerCharts[];
  isLoading: boolean;
};

const useTahkCsgSummaryCard = ({ layers = [] }: UseTahkCsgSummaryCardProps) => {
  const [layer, setLayer] = useState(0);

  const layerOption = useMemo(() => {
    return Array.from(Array(layers?.length).keys()).map((_, index) => {
      return {
        key: index,
        text: `${dictionary.tahk.layer} ${index + 1}`,
      };
    });
  }, [layers?.length]);

  const selectedLayerSummary = useMemo(() => {
    return layers[layer]?.summary_card ?? [];
  }, [layer, layers]);

  return { layer, setLayer, layerOption, selectedLayerSummary };
};

export default useTahkCsgSummaryCard;
