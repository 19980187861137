import { useState, useCallback, useMemo, useEffect } from "react";
import { CellChange, NumberCell, Row } from "@silevis/reactgrid";
import _ from "lodash";
import { useQuery } from "@tanstack/react-query";

import { FossilyticsChartAxis, FossilyticsChartSeries } from "@/components/FossilyticsChart";

// constant
import dictionary from "@/constants/dictionary";
import {
  gazAutoRtaForecastEventHeader,
  gazAutoRtaForecastDataTableHeader,
  gazAutoRtaForecastDataTableHeaderUnits,
  dataTableCentered,
} from "../constants";

// model
import { DataSet, Project } from "@/model";
import { ErrorValidationDetail } from "@/models/ErrorInputValidation";
import { SummaryCard } from "@/models/Generic";
import { ApiError } from "@/models/APIGeneric";

import {
  ForecastEvent,
  GazAutoRta,
  pollCalculateForecastGazAutoRta,
  pollValidateForecastGazAutoRta,
  AutoRtaForecastCalculationChartData,
} from "@/models/gaz/autoRta";

// utils
import { handleKeyDown } from "@/util";
import useThemeStyling from "@/utils/useThemeStyling";

import { useGazAutoRtaState } from "./GazAutoRtaContext";
import { convertDateToUtcTimeZoneIsoString } from "@/utils/dateTime";

export type GazAutoRtaForecastProps = {
  dataSet: DataSet;
  currentTab: number;
  project?: Project;
  isLoading: boolean;
};

const useGazAutoRtaForecast = ({ dataSet, project }: GazAutoRtaForecastProps) => {
  const { gazAutoRTAState, setGazAutoRTAState, dataViewCalculation, setApiError } = useGazAutoRtaState();

  const [autoRtaForecastCalculation, setAutoRtaForecastCalculation] = useState<AutoRtaForecastCalculationChartData>();
  const [autoRtaForecastParameter, setAutoRtaForecastParameter] = useState<SummaryCard[]>();
  const [errorInputValidation, setErrorInputValidation] = useState<ErrorValidationDetail[]>([]);
  const [lastValidState, setLastValidState] = useState<GazAutoRta>();
  const [loadForecast, setLoadForecast] = useState(false);

  const {
    refetch: validateToApi,
    isFetching,
    isLoading,
  } = useQuery({
    queryKey: ["auto-rta-forecast-validation", gazAutoRTAState],
    queryFn: async () => {
      if (gazAutoRTAState) {
        return pollValidateForecastGazAutoRta(gazAutoRTAState, [dataSet.id]);
      }
      return {};
    },
    refetchOnWindowFocus: false,
    enabled: false,
  });
  const { theme } = useThemeStyling();

  // forecase input and setting part
  const forecastEventsRow = useMemo(() => {
    if (!gazAutoRTAState?.forecast?.forecast_events) return [];
    const constantInputsRows = [
      gazAutoRtaForecastEventHeader,
      ...(gazAutoRTAState?.forecast?.forecast_events.map((event, i) => ({
        rowId: i + 1,
        cells: [
          {
            type: "text",
            text: event.date ? `${new Intl.DateTimeFormat("en-US").format(new Date(event.date))}` : "",
            nonEditable: gazAutoRTAState?.forecast.smart_fill || isLoading || isFetching,
            style: {
              backgroundColor: gazAutoRTAState?.forecast.smart_fill ? "rgba(128, 128, 128, 0.1)" : "white",
              cursor: gazAutoRTAState?.forecast.smart_fill ? "not-allowed" : "auto",
            },
          },
          {
            type: "number",
            value: event.flowing_pressure,
            nonEditable: gazAutoRTAState?.forecast.smart_fill || isLoading || isFetching,
            style: {
              backgroundColor: gazAutoRTAState?.forecast.smart_fill ? "rgba(128, 128, 128, 0.1)" : "white",
              cursor: gazAutoRTAState?.forecast.smart_fill ? "not-allowed" : "auto",
            },
          },
        ],
      })) ?? []),
    ] as Row[];

    const blankRows: Row[] = Array.from({ length: 500 }, (_, i) => ({
      key: i,
      rowId: constantInputsRows.length + i,
      cells: [
        {
          type: "text" as const,
          text: "",
          nonEditable: gazAutoRTAState?.forecast.smart_fill || isLoading,
          style: { backgroundColor: gazAutoRTAState?.forecast.smart_fill ? "rgba(128, 128, 128, 0.2)" : "white" },
        },
        {
          type: "number" as const,
          value: NaN,
          handleKeyDown,
          nonEditable: gazAutoRTAState?.forecast.smart_fill || isLoading,
          style: { backgroundColor: gazAutoRTAState?.forecast.smart_fill ? "rgba(128, 128, 128, 0.2)" : "white" },
        },
      ],
    })) as Row[];

    return [...constantInputsRows, ...blankRows];
  }, [gazAutoRTAState?.forecast?.forecast_events, gazAutoRTAState?.forecast.smart_fill, isFetching, isLoading]);

  const onChangeForecastInput = (updatedInput: { [key: string]: any }) => {
    setGazAutoRTAState((prev) => {
      if (!prev) return prev;
      return {
        ...prev,
        forecast: {
          ...prev.forecast,
          ...updatedInput,
        },
      };
    });
  };

  const onChangeForecastInputTable = useCallback(
    (changes: CellChange[]) => {
      // Clone the original data to avoid mutation
      const updatedRows = [...forecastEventsRow];

      // Apply the changes to the cloned data
      changes.forEach((change) => {
        let { rowId, columnId, newCell } = change as CellChange<NumberCell>;
        rowId = rowId as number;
        columnId = columnId as number;
        updatedRows[rowId].cells[columnId] = newCell;
      });

      const newForecastEvents = updatedRows
        .slice(1)
        .map((row: { cells: Array<any> }): { date?: string; flowing_pressure?: number } => {
          // typing issue from react grid
          return {
            date: row.cells[0].text ? convertDateToUtcTimeZoneIsoString(new Date(row.cells[0].text)) : undefined,
            flowing_pressure: row.cells[1]?.value,
          };
        })
        .filter((event) => event.date || event.flowing_pressure) as ForecastEvent[];

      setGazAutoRTAState((prev) => {
        if (!prev) return prev;
        const newForecast = prev.forecast;

        newForecast.forecast_events = _.cloneDeep(newForecastEvents);
        return {
          ...prev,
          forecast: {
            ...prev.forecast,
            ...newForecast,
          },
        };
      });
    },
    [forecastEventsRow, setGazAutoRTAState]
  );

  const onCalculateAutoRtaForecast = useCallback(
    async (latestInputState: GazAutoRta) => {
      if (!project) return;
      try {
        setLoadForecast(true);
        const res = await pollCalculateForecastGazAutoRta(latestInputState, project.id);
        if (res.data) {
          const calculateRes = res.data;
          setAutoRtaForecastCalculation(calculateRes?.data);
          setAutoRtaForecastParameter(calculateRes?.summary_card);
        }
      } catch (error) {
        setApiError(error as ApiError);
        console.log(error);
      } finally {
        setLoadForecast(false);
      }
    },
    [project, setApiError]
  );

  const validateForecastInput = useCallback(async () => {
    try {
      setErrorInputValidation([]);
      if (!_.isEqual(gazAutoRTAState, lastValidState) && gazAutoRTAState) {
        const res = await validateToApi();
        if (res.data?.data) {
          const response = res.data.data;
          const newState = _.cloneDeep(gazAutoRTAState);
          newState.forecast = _.cloneDeep(response);

          if (!_.isEqual(_.cloneDeep(response), _.cloneDeep(lastValidState)) && response?.forecast_events) {
            setGazAutoRTAState(newState);
            setLastValidState(newState);
          }
          onCalculateAutoRtaForecast(newState);
        }
      }
    } catch (error: any) {
      // this block is to determine error validation on input
      const responseError: any = error.response;
      if (responseError && responseError.status === 422 && responseError.data.detail?.length !== 0) {
        // parse error message and data, set to state
        setErrorInputValidation(responseError.data.detail as ErrorValidationDetail[]);
      } else {
        console.error(error);
      }
    }
  }, [gazAutoRTAState, lastValidState, onCalculateAutoRtaForecast, setGazAutoRTAState, validateToApi]);

  useEffect(() => {
    validateForecastInput();
  }, [gazAutoRTAState?.forecast, validateForecastInput]);

  const forecastCalculationDataTableRow = useMemo(() => {
    const totalRow = autoRtaForecastCalculation?.dates.length ?? 0;

    const constantInputsRows = [
      gazAutoRtaForecastDataTableHeader,
      gazAutoRtaForecastDataTableHeaderUnits,
      ...(Array.from({ length: totalRow }, (_, i) => {
        const safeAutoRtaForecastCalc = autoRtaForecastCalculation ?? {
          cumulative_gas: [],
          dates: [],
          flowing_pressures: [],
          gas_rates: [],
        };
        return {
          key: i,
          rowId: i + 1,
          cells: [
            {
              type: "text",
              text: safeAutoRtaForecastCalc.dates[i] ? `${new Intl.DateTimeFormat("en-US").format(new Date(safeAutoRtaForecastCalc.dates[i]))}` : "",
              nonEditable: true,
              style: dataTableCentered,
            },
            {
              type: "number",
              value: safeAutoRtaForecastCalc.flowing_pressures[i] ?? 0,
              nonEditable: true,
              style: dataTableCentered,
            },
            {
              type: "number",
              value: safeAutoRtaForecastCalc.cumulative_gas[i] ?? 0,
              nonEditable: true,
              style: dataTableCentered,
            },
            {
              type: "number",
              value: safeAutoRtaForecastCalc.gas_rates[i] ?? 0,
              nonEditable: true,
              style: dataTableCentered,
            },
          ],
        };
      }) as Row[]),
    ] as Row[];
    return constantInputsRows;
  }, [autoRtaForecastCalculation]);

  const forecastXAxes = useMemo<FossilyticsChartAxis[]>(() => [{ name: "Date", type: "time", color: theme.palette.black }], [theme.palette.black]);
  const forecastYAxes = useMemo<FossilyticsChartAxis[]>(
    () => [
      { name: dictionary.gas.gasRatesYAxis, type: "value", color: theme.palette.red },
      { name: dictionary.gas.pressureYAxis, type: "value", color: theme.palette.blue },
    ],
    [theme.palette.blue, theme.palette.red]
  );

  const dataViewCleanedSeries = useMemo<FossilyticsChartSeries[]>(() => {
    if (dataViewCalculation?.clean_data) {
      return [
        {
          name: dictionary.gas.gasRateCleaned,
          type: "scatter",
          color: theme.palette.red,
          yAxisIndex: 0,
          hideSymbol: true,
          data: dataViewCalculation?.clean_data?.dates.map((x, i) => [x, dataViewCalculation?.clean_data?.gas_rate[i]]) ?? [],
        },
        {
          name: dictionary.gas.casingPressure,
          type: "scatter",
          color: theme.palette.blue,
          yAxisIndex: 1,
          hideSymbol: true,
          data: dataViewCalculation?.clean_data?.dates.map((x, i) => [x, dataViewCalculation?.clean_data?.casing_pressure[i]]) ?? [],
        },
        {
          name: dictionary.gas.tubingPressure,
          type: "scatter",
          color: theme.palette.blueLight,
          yAxisIndex: 1,
          hideSymbol: true,
          data: dataViewCalculation?.clean_data?.dates.map((x, i) => [x, dataViewCalculation?.clean_data?.tubing_pressure[i]]) ?? [],
        },
      ];
    }
    return [];
  }, [dataViewCalculation, theme.palette.blue, theme.palette.blueLight, theme.palette.red]);

  const forecastCalcSeries = useMemo<FossilyticsChartSeries[]>(() => {
    if (autoRtaForecastCalculation)
      return [
        {
          name: dictionary.gas.gasRates,
          type: "line",
          color: theme.palette.red,
          yAxisIndex: 0,
          data: autoRtaForecastCalculation?.dates.map((x, i) => [x, autoRtaForecastCalculation.gas_rates[i]]) ?? [],
          hideSymbol: true,
          lineWidth: 2,
        },
        {
          name: dictionary.gas.pressure,
          type: "line",
          color: theme.palette.blue,
          yAxisIndex: 1,
          data: autoRtaForecastCalculation?.dates.map((x, i) => [x, autoRtaForecastCalculation.flowing_pressures[i]]) ?? [],
          hideSymbol: true,
          lineWidth: 2,
        },
      ];
    return [];
  }, [autoRtaForecastCalculation, theme.palette.blue, theme.palette.red]);

  const allSeries = useMemo<FossilyticsChartSeries[]>(() => {
    return [...forecastCalcSeries, ...dataViewCleanedSeries];
  }, [dataViewCleanedSeries, forecastCalcSeries]);

  const cumulativeGasChartSeries = useMemo<FossilyticsChartSeries[]>(() => {
    if (dataViewCalculation && autoRtaForecastCalculation)
      return [
        {
          name: dictionary.gas.gasProduces,
          type: "scatter",
          color: theme.palette.red,
          yAxisIndex: 0,
          hideSymbol: true,
          data: dataViewCalculation?.clean_data?.dates.map((x, i) => [x, dataViewCalculation?.clean_data?.gas_production[i]]) ?? [],
        },
        {
          name: dictionary.gas.gasCumulative,
          type: "line",
          color: theme.palette.red,
          yAxisIndex: 0,
          data: autoRtaForecastCalculation?.dates.map((x, i) => [x, autoRtaForecastCalculation.cumulative_gas[i]]) ?? [],
          hideSymbol: true,
          lineWidth: 3,
        },
      ];
    return [];
  }, [autoRtaForecastCalculation, dataViewCalculation, theme.palette.red]);

  const cumulativeGasYAxes = useMemo<FossilyticsChartAxis[]>(() => {
    return [{ name: dictionary.gas.gasRatesYAxisNoDate, type: "value", color: theme.palette.red }];
  }, [theme.palette.red]);

  return {
    forecastInputState: gazAutoRTAState?.forecast,
    errorInputValidation,
    onChangeForecastInput,
    onChangeForecastInputTable,
    forecastEventsRow,
    forecastCalculationDataTableRow,
    forecastXAxes,
    forecastYAxes,
    forecastCalcSeries: allSeries,
    autoRtaForecastParameter,
    cumulativeGasChartSeries,
    cumulativeGasYAxes,
    loadForecast: loadForecast || isFetching || isLoading,
  };
};

export default useGazAutoRtaForecast;
