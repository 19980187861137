import { useCallback, useMemo } from "react";
import { useTheme } from "@fluentui/react";

import FossilyticsChart, { FossilyticsChartAxis, FossilyticsChartLine, FossilyticsChartSeries } from "@/components/FossilyticsChart";

import { ModuleSpadDeclineArp, ModuleSpadDeclineArps, SpadAnalysisOutput } from "@/features/modules/spad/decline";
import { DataSet } from "@/model";
import { useAppStore } from "@/features/app";

interface QtPlotsProps {
  xAxesState?: boolean;
  isLoading: boolean;
  initOutput?: SpadAnalysisOutput;
  unit: string;
  arpsShort: ModuleSpadDeclineArps;
  arpsLong: ModuleSpadDeclineArps;
  compareAnalysisName?: string;
  compareEconomicCutoff?: number;
  compareDeclineArps?: ModuleSpadDeclineArps;
  getScatterItemColor: (params: any) => string;
  dataSets: DataSet[];
  forecastStartLine?: FossilyticsChartLine;
  handleXAxesChange?: () => void;
  getSharedLineSeriesOpts: () => any;
  draggable?: boolean;
}

export const QtPlots = ({
  xAxesState,
  isLoading,
  initOutput,
  unit,
  getScatterItemColor,
  arpsShort,
  arpsLong,
  compareAnalysisName,
  compareEconomicCutoff,
  compareDeclineArps,
  dataSets,
  forecastStartLine,
  handleXAxesChange,
  getSharedLineSeriesOpts,
  draggable = true,
}: QtPlotsProps) => {
  const theme = useTheme();

  const currentModule = useAppStore((state) => state.currentModule);

  const getCumProdLineSeries = useCallback(
    (arp?: ModuleSpadDeclineArp) => {
      let newData: unknown[] = [];

      if (arp) {
        newData = xAxesState ? arp.dateTimesQ.map((d, i) => [d, arp.cumProds[i]]) : arp.daysQPlot.map((d, i) => [d, arp.cumProds[i]]);
      }
      return {
        ...getSharedLineSeriesOpts(),
        data: newData,
      };
    },
    [getSharedLineSeriesOpts, xAxesState]
  );

  const QgxAxes = useMemo<FossilyticsChartAxis[]>(() => {
    let name = "";
    let type = "";
    let min;
    if (!xAxesState) {
      name = "Days";
      type = "value";
      min = 0;
      return [{ name: name, type: type, color: theme.palette.black, min: min }];
    }
    name = "Dates";
    type = "time";
    min = initOutput?.dateTimes[0] ? new Date(initOutput?.dateTimes[0]).getTime() : undefined;
    return [{ name: name, type: type, color: theme.palette.black, min: min }];
  }, [initOutput?.dateTimes, theme.palette.black, xAxesState]);

  const cumProdYAxes = useMemo<FossilyticsChartAxis[]>(
    () => [{ name: `Cum. production (${unit})`, type: "value", color: theme.palette.black, min: 0 }],
    [theme.palette.black, unit]
  );

  const cumProdSeries = useMemo<FossilyticsChartSeries[]>(() => {
    let cumProdData: unknown[] = [];

    if (initOutput) {
      cumProdData = xAxesState
        ? initOutput.dateTimes.map((d, i) => [d, initOutput.cumProds[i]])
        : initOutput.days.map((d, i) => [d, initOutput.cumProds[i]]);
    }

    return [
      {
        name: "Cum. production",
        type: "scatter",
        color: getScatterItemColor,
        data: cumProdData,
      },
      {
        ...getCumProdLineSeries(arpsShort.low),
        id: "lc",
        name: "OPS Low case",
        defaultDisabled: true,
        color: theme.palette.purpleLight,
      },
      {
        ...getCumProdLineSeries(arpsShort.mid),
        id: "mc",
        name: "OPS Mid case",
        defaultDisabled: true,
        color: theme.palette.purple,
      },
      {
        ...getCumProdLineSeries(arpsShort.high),
        id: "hc",
        name: "OPS High case",
        defaultDisabled: true,
        color: theme.palette.purpleDark,
      },
      {
        ...getCumProdLineSeries(arpsLong.low),
        id: "lcl",
        name: "Profile Low case",
        color: theme.palette.themePrimary,
      },
      {
        ...getCumProdLineSeries(arpsLong.mid),
        id: "mcl",
        name: "Profile Mid case",
        color: theme.palette.blue,
      },
      {
        ...getCumProdLineSeries(arpsLong.high),
        id: "hcl",
        name: "Profile High case",
        color: theme.palette.yellow,
      },
      ...(compareAnalysisName && compareEconomicCutoff && compareDeclineArps
        ? [
            {
              ...getCumProdLineSeries(compareDeclineArps.low),
              name: `${compareAnalysisName} Low Case`,
              lineWidth: 1,
              color: theme.palette.neutralDark,
            },
            {
              ...getCumProdLineSeries(compareDeclineArps.mid),
              name: `${compareAnalysisName} Mid Case`,
              lineWidth: 1,
              color: theme.palette.neutralDark,
            },
            {
              ...getCumProdLineSeries(compareDeclineArps.high),
              name: `${compareAnalysisName} High Case`,
              lineWidth: 1,
              color: theme.palette.neutralDark,
            },
          ]
        : []),
    ];
  }, [
    getScatterItemColor,
    initOutput,
    xAxesState,
    getCumProdLineSeries,
    arpsShort.low,
    arpsShort.mid,
    arpsShort.high,
    theme.palette.purpleLight,
    theme.palette.purple,
    theme.palette.purpleDark,
    theme.palette.themePrimary,
    theme.palette.blue,
    theme.palette.yellow,
    theme.palette.neutralDark,
    arpsLong.low,
    arpsLong.mid,
    arpsLong.high,
    compareAnalysisName,
    compareEconomicCutoff,
    compareDeclineArps,
  ]);

  const key = `${currentModule}_log_q_t` + dataSets.map((well) => well.id).join(",");
  return (
    <FossilyticsChart
      id={`${currentModule}_Q_t`}
      key={key}
      isLoading={isLoading}
      xAxes={QgxAxes}
      yAxes={cumProdYAxes}
      series={cumProdSeries}
      lines={forecastStartLine && [forecastStartLine]}
      handleXAxesChange={handleXAxesChange}
      xAxesState={xAxesState}
      draggable={draggable}
    />
  );
};
