import { Pivot, PivotItem, Spinner, Stack, useTheme } from "@fluentui/react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { DataSet } from "../../../model";
import { ModuleGazFmbInitOutput, NewModuleGazFmbParamOutput } from "./model";
import { transpose } from "../../../util";
import FossilyticsChart, {
  FossilyticsChartAxis,
  FossilyticsChartLegendData,
  FossilyticsChartLine,
  FossilyticsChartLineConstraint,
  FossilyticsChartSeries,
} from "../../../components/FossilyticsChart";
import FossilyticsGrid, { FossilyticsGridColumn } from "../../../components/FossilyticsGrid";

interface ModuleGazFmbOutputViewProps {
  isLoading: boolean;
  dataSet: DataSet;
  fmb?: ModuleGazFmbInitOutput;
  param?: NewModuleGazFmbParamOutput;
  line?: number[][];
  onLineChange?: (line: number[][] | [string, number][]) => void;
  manualPoints: number[][];
  isUserLine: boolean;
}

const dataTableColumns = [
  //  TODO
  // {key: 'd', type: 'date', header: 'Date'},
  { key: "q", type: "number", header: "q" },
  { key: "Q", type: "number", header: "Q" },
  { key: "Pwf", type: "number", header: "Pwf" },
  { key: "nRate", type: "number", header: "Norm. Rate" },
  { key: "nQ", type: "number", header: "Norm. Q" },
  { key: "Pr", type: "number", header: "Pr" },
  { key: "z", type: "number", header: "z" },
  { key: "Pr/z", type: "number", header: "Pr/z" },
  { key: "mp(Pr*)", type: "number", header: "mp(Pr*)" },
  { key: "Pr*", type: "number", header: "Pr*" },
  { key: "z*", type: "number", header: "z*" },
  { key: "Pr*/z*", type: "number", header: "Pr*/z*" },
  { key: "PI", type: "number", header: "PI" },
] as FossilyticsGridColumn[];

function ModuleGazFmbOutputView({ isLoading, fmb, line, param, onLineChange, manualPoints, isUserLine }: ModuleGazFmbOutputViewProps) {
  const [dataTableRows, setDataTableRows] = useState<number[][]>([]);

  useEffect(() => {
    const result: any[] = [];
    if (fmb) {
      const count = fmb.diagnostic["Q"].length;
      for (let i = 0; i < count; i++) {
        result.push([
          fmb.diagnostic["q"][i],
          fmb.diagnostic["Q"][i],
          fmb.diagnostic["Pwf"][i],
          fmb.diagnostic["Norm. Rate"][i],
          fmb.diagnostic["Norm. Q"][i],
          fmb.diagnostic["Pr"][i],
          fmb.diagnostic["z"][i],
          fmb.diagnostic["Pronz"][i],
          fmb.diagnostic_fpz["mp(Pr_star)"][i],
          fmb.diagnostic_fpz["Pr_star"][i],
          fmb.diagnostic_fpz["z_star"][i],
          fmb.diagnostic_fpz["PronZ_star"][i],
          fmb.diagnostic["PI"][i],
        ]);
      }
    }
    setDataTableRows(result);
  }, [fmb]);

  const theme = useTheme();

  const piSeries = useMemo<FossilyticsChartSeries[]>(() => {
    if (!fmb) return [];
    const dataLength = fmb.diagnostic["Q"].length;
    const piData = Array.from(Array(dataLength).keys()).map((i) => [fmb.diagnostic["Q"][i], fmb.diagnostic["PI"][i]]);

    return [
      {
        name: "Productivity Index",
        type: "scatter",
        color: theme.palette.blue,
        data: piData,
      },
      {
        name: "Average Productivity Index(Mid)",
        type: "line",
        color: theme.palette.red,
        lineType: "dashed",
        hideSymbol: true,
        data: param
          ? [
              [0, param.results[1].pi_dim],
              [Math.max(...piData.map((p) => p[0])), param.results[1].pi_dim],
            ]
          : [],
      },
    ];
  }, [theme, fmb, param]);

  const fmbSeries = useMemo<FossilyticsChartSeries[]>(() => {
    if (!fmb) return [];
    const dataLength = fmb.diagnostic["q"].length;

    const series: FossilyticsChartSeries[] = [
      {
        name: "Flowing Material Balance",
        type: "scatter",
        color: theme.palette.red,
        data: Array.from(Array(dataLength - fmb.pts_length).keys()).map((i) => [fmb.diagnostic["Norm. Q"][i], fmb.diagnostic["Norm. Rate"][i]]),
      },
      {
        name: "Latest linear trend",
        type: "scatter",
        color: theme.palette.black,
        data: Array.from(Array(fmb.pts_length).keys())
          .map((i) => dataLength - i)
          .map((i) => [fmb.diagnostic["Norm. Q"][i], fmb.diagnostic["Norm. Rate"][i]]),
      },
      ...(!isUserLine
        ? [
            {
              name: "FMB Low case",
              type: "line",
              hideSymbol: true,
              color: theme.palette.themePrimary,
              data: transpose(fmb.uncertainty[0]),
            },
            {
              name: "FMB High case",
              type: "line",
              hideSymbol: true,
              color: theme.palette.yellow,
              data: transpose(fmb.uncertainty[2]),
            },
          ]
        : []),
    ];

    if (piSeries) {
      series.push(...piSeries.map((s) => ({ ...s, defaultDisabled: true })));
    }

    return series;
  }, [fmb, isUserLine, piSeries, theme.palette.black, theme.palette.red, theme.palette.themePrimary, theme.palette.yellow]);

  const fmbLines = useMemo<FossilyticsChartLine[]>(() => {
    if (!fmb || !line) return [];
    return [
      {
        name: "FMB Mid case",
        color: theme.palette.red,
        line: line,
        xMin: Math.max(...fmb.diagnostic["Norm. Q"]),
        xConstraint: FossilyticsChartLineConstraint.AXIS,
        yConstraint: FossilyticsChartLineConstraint.AXIS,
      },
    ];
  }, [theme, fmb, line]);

  const pronzSeries = useMemo<FossilyticsChartSeries[]>(() => {
    if (!fmb) return [];
    const dataLength = fmb.diagnostic["Q"].length;

    return [
      {
        name: "Model P/z",
        type: "scatter",
        color: theme.palette.red,
        data: Array.from(Array(dataLength).keys()).map((i) => [fmb.diagnostic["Q"][i], fmb.diagnostic["Pronz"][i]]),
      },
      {
        name: "Flowing P*/z*",
        type: "scatter",
        color: theme.palette.green,
        data: Array.from(Array(dataLength).keys()).map((i) => [fmb.diagnostic["Q"][i], fmb.diagnostic_fpz["PronZ_star"][i]]),
      },
      {
        name: "Static P/z",
        type: "scatter",
        symbolSize: 12,
        color: theme.palette.black,
        data: manualPoints,
      },
      {
        name: "FMB P/z Best Fit",
        type: "line",
        hideSymbol: true,
        color: theme.palette.green,
        data: [[0, fmb.diagnostic["Pronz"][0]], line ? line[1] : [fmb.ogip, 0]],
      },
    ];
  }, [theme, fmb, manualPoints, line]);

  const isSeriesDisabledInCombined = useCallback((s: FossilyticsChartSeries) => {
    switch (s.name) {
      case "FMB Low case":
      case "FMB High case":
      case "Model P/z":
      case "Productivity Index":
      case "Average Productivity Index":
        return true;
      default:
        return false;
    }
  }, []);

  const allSeries = useMemo<FossilyticsChartSeries[]>(() => {
    return [
      ...fmbSeries.reduce((arr, s, i, series) => {
        let data = s.data;
        // Merge FMB data and latest linear trend for combined chart only
        if (s.name === "Flowing Material Balance") {
          const latestLinearTrend = series.find((s) => s.name === "Latest linear trend");
          if (latestLinearTrend) {
            data = [...data, ...latestLinearTrend.data];
          }
        } else if (s.name === "Latest linear trend") {
          return arr;
        }

        arr.push({ ...s, data, defaultDisabled: isSeriesDisabledInCombined(s), yAxisIndex: 0 });
        return arr;
      }, [] as FossilyticsChartSeries[]),
      ...fmbLines.map<FossilyticsChartSeries>((l) => ({
        ...l,
        type: "line",
        hideSymbol: true,
        data: l.line ? l.line : [],
        yAxisIndex: 0,
      })),
      ...pronzSeries.map((s) => ({ ...s, defaultDisabled: isSeriesDisabledInCombined(s), yAxisIndex: 1 })),
    ];
  }, [fmbSeries, fmbLines, pronzSeries, isSeriesDisabledInCombined]);

  const fmbLegends = useMemo<FossilyticsChartLegendData[]>(() => {
    const legends: FossilyticsChartLegendData[] = fmbSeries.map((s) => ({ name: s.name }));
    const lowIndex = legends.findIndex((l) => l.name === "FMB Low case");
    legends.splice(lowIndex + 1, 0, { name: "FMB Mid case" });
    return legends;
  }, [fmbSeries]);

  const allLegends = useMemo<FossilyticsChartLegendData[]>(() => {
    const legends: FossilyticsChartLegendData[] = allSeries.map((s) => ({ name: s.name } as FossilyticsChartLegendData));
    const lowIndex = legends.findIndex((l) => l.name === "FMB Low case");
    const midIndex = legends.findIndex((l) => l.name === "FMB Mid case");
    legends.splice(lowIndex + 1, 0, ...(legends[midIndex] ? [legends[midIndex]] : []));
    legends.splice(midIndex + 1, 1);
    return legends;
  }, [allSeries]);

  const xAxes = useMemo<FossilyticsChartAxis[]>(() => {
    return [{ name: "Normalized Cumulative Production (MMscf)", type: "value", color: theme.palette.black, min: 0 }];
  }, [theme]);

  const piXAxes = useMemo<FossilyticsChartAxis[]>(() => {
    const max = piSeries.length === 1 ? Math.max(...piSeries[0].data.map((d) => d[0])) * 2.5 : undefined;
    return [{ ...xAxes[0], max }];
  }, [xAxes, piSeries]);

  const yAxes = useMemo<FossilyticsChartAxis[]>(
    () => [
      { name: "Normalized rate (MMscf/d/(psia²/cp))", type: "value", color: theme.palette.black, min: 0 },
      { name: "P/z (psia)", type: "value", color: theme.palette.black, min: 0 },
    ],
    [theme]
  );
  const piYAxes = useMemo<FossilyticsChartAxis[]>(() => {
    const max = piSeries.length === 1 ? Math.max(...piSeries[0].data.map((d) => d[1])) * 1.5 : undefined;
    return [{ ...yAxes[0], max }];
  }, [yAxes, piSeries]);

  return (
    <Stack className="PivotWrapper" grow={1}>
      <Pivot styles={{ itemContainer: { flex: "1" } }}>
        <PivotItem headerText="FMB">
          <FossilyticsChart
            id="gaz_fmb_fmb"
            isLoading={isUserLine ? false : isLoading}
            xAxes={xAxes}
            yAxes={[yAxes[0]]}
            series={fmbSeries}
            lines={fmbLines}
            onLineChange={onLineChange}
            legends={fmbLegends}
          />
        </PivotItem>
        <PivotItem headerText="P/z">
          <FossilyticsChart id="gaz_fmb_pz" isLoading={isLoading} xAxes={xAxes} yAxes={[yAxes[1]]} series={pronzSeries} />
        </PivotItem>
        <PivotItem headerText="PI">
          <FossilyticsChart id="gaz_fmb_pi" isLoading={isLoading} xAxes={piXAxes} yAxes={piYAxes} series={piSeries} />
        </PivotItem>
        <PivotItem headerText="Combined chart">
          <FossilyticsChart id="gaz_fmb_combined" isLoading={isLoading} xAxes={xAxes} yAxes={yAxes} series={allSeries} legends={allLegends} />
        </PivotItem>
        <PivotItem headerText="Data table" style={{ position: "relative" }}>
          {isLoading ? (
            <Spinner
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%",
              }}
              label="Loading FMB..."
            />
          ) : (
            <FossilyticsGrid
              style={{ position: "absolute", top: 0, left: 0, bottom: 0, right: 0, overflowY: "auto" }}
              columns={dataTableColumns}
              data={dataTableRows}
            />
          )}
        </PivotItem>
      </Pivot>
    </Stack>
  );
}

export default ModuleGazFmbOutputView;
