import { Row } from "@silevis/reactgrid";

export const downloadCsv = (data: string, fileName: string) => {
  const csvContent = `data:text/csv;charset=utf-8,${data}`;
  let link = document.createElement("a");

  link.href = csvContent;
  link.download = fileName;

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const transformRowToString = (dataTable: Row<any>[]) => {
  return dataTable.reduce((prev: string, curr) => {
    prev += `${curr.cells.map((cell) => cell.text).join(", ")}\n`;
    return prev;
  }, "");
};
