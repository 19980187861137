import { useState } from "react";
import { useQuery } from "@tanstack/react-query";

import { ProjectDataSet } from "@/util";
import { ModuleId } from "@/model";
import { SpadDeclineStateScheme, postInitializeSpadDecline } from "@/models/spad/decline";
import { getSubModulePath } from "../index.utils";

type UseCompareProps = {
  currentModule?: ModuleId;
};

const useCompareSpadAnalysis = ({ currentModule }: UseCompareProps) => {
  const [compareName, setCompareName] = useState<string>();
  const [compareDataSet, setCompareDataSet] = useState<ProjectDataSet>();

  const { data, isError } = useQuery({
    queryKey: ["initialize-spad-decline-compare", compareDataSet],
    queryFn: async () => {
      return postInitializeSpadDecline(
        {
          data_set_ids: compareDataSet?.dataSetIds ?? [],
          group_id: compareDataSet?.groupId ?? "",
          project_id: compareDataSet?.projectId ?? "",
        },
        getSubModulePath(currentModule!)
      );
    },
    select(data) {
      if (data?.data) {
        const parsedState = SpadDeclineStateScheme.safeParse(data.data);
        if (!parsedState.success) {
          console.error(parsedState);
        }

        return data?.data;
      }
    },
    refetchOnWindowFocus: false,
    enabled: !!(compareDataSet && currentModule),
  });

  return {
    compareAnalysis: !isError && data ? data : undefined,
    compareName,
    setCompareName,
    setCompareDataSet,
    compareDataSet,
  };
};

export default useCompareSpadAnalysis;
