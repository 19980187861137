import { useEffect } from "react";
import { Amplify } from "aws-amplify";
import { shallow } from "zustand/shallow";
import { QueryClient, QueryClientProvider, QueryCache } from "@tanstack/react-query";

import { useAppStore } from "@/features/app";
import Router from "./Router";
import SignIn from "./SignIn";
import { TenantConfigs } from "./tenants";
import { SettingsProvider } from "./SettingsState";
import { CustomError } from "./utils/apiFetcher";
import TreeViewProvider from "./components/TreeView/hooks/TreeViewContextV2";
import ErrorBoundaryWrapper from "./components/ErrorBoundary";

const App = () => {
  const { user, tenant, title, setApiError, setLoadingBlocker } = useAppStore(
    (state) => ({
      user: state.user,
      tenant: state.tenant,
      title: state.title,
      setApiError: state.setApiError,
      setLoadingBlocker: state.setLoadingBlocker,
    }),
    shallow
  );

  // Configure Amplify authentication
  useEffect(() => {
    let region = process.env.REACT_APP_AMPLIFY_REGION;

    let idpEnabled = false;
    let idpDomain = undefined;
    let userPoolId = process.env.REACT_APP_AMPLIFY_USER_POOL_ID;
    let userPoolWebClientId = process.env.REACT_APP_AMPLIFY_APP_CLIENT_ID;

    if (tenant) {
      idpEnabled = TenantConfigs[tenant].idpEnabled;
      idpDomain = TenantConfigs[tenant].idpDomain;
      userPoolId = TenantConfigs[tenant].userPoolId;
      userPoolWebClientId = TenantConfigs[tenant].clientId;
    }

    Amplify.configure({
      Auth: {
        region,
        userPoolId,
        userPoolWebClientId,
        oauth: idpEnabled
          ? {
              domain: idpDomain,
              scope: ["email", "openid"],
              redirectSignIn: window.location.origin,
              redirectSignOut: window.location.origin,
              responseType: "code",
            }
          : undefined,
      },
    });
  }, [tenant]);

  useEffect(() => {
    document.title = title ? `${title} - Predico` : "Predico";
  }, [title]);

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
        retry: false,
        staleTime: 0,
        gcTime: 0,
      },
    },
    queryCache: new QueryCache({
      onError(error) {
        setLoadingBlocker(false);
        const customErr = error as CustomError;
        setApiError({
          code: customErr.code,
          detail: customErr.detail,
          message: customErr.message,
          severity: customErr.severity,
        });
        setLoadingBlocker(false);
      },
      onSuccess(data: any) {
        setApiError(undefined);
        if (data?.data) setLoadingBlocker(false);
      },
    }),
  });

  return (
    <ErrorBoundaryWrapper>
      <QueryClientProvider client={queryClient}>
        {!user || user.challengeName ? (
          <SignIn />
        ) : (
          <SettingsProvider>
            <TreeViewProvider>
              <Router />
            </TreeViewProvider>
          </SettingsProvider>
        )}
      </QueryClientProvider>
    </ErrorBoundaryWrapper>
  );
};

export default App;
