import styled from "@emotion/styled";

export const Container = styled.div`
  padding: 20px;
  overflow: scroll;

  display: grid;
  grid-template-columns: 420px 1fr;
  grid-gap: 20px;
  height: 95%;

  h4 {
    margin: 0px;
  }

  .checkbox-container {
    margin-top: 20px;
    span {
      padding-left: 0px;
    }
  }

  .slider-container {
    font-weight: 600;
    margin-top: 5px;
    display: grid;
    grid-template-columns: 0.3fr 0.9fr 2fr 0.3fr 1fr;
    grid-gap: 5px;
    align-items: center;
  }

  .right-container {
    display: flex;
    flex-direction: column;

    .final-param-table-title {
      margin-top: 20px;
      margin-bottom: 20px;
      font-weight: 600;
    }
  }
`;

export const InputLayout = styled.div`
  .input-row {
    margin-top: 20px;
    display: grid;
    grid-template-columns: 1.8fr 1fr 1fr 1fr 0.5fr;
    grid-gap: 5px;
    align-items: center;

    .boundary-label {
      font-weight: 600;
    }
    .boundary-unit {
      text-align: center;
    }
  }

  .input-row-header {
    span {
      color: #f28f1d;
    }
  }
`;
