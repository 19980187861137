import React from "react";
import { Dropdown, Pivot, PivotItem, Stack } from "@fluentui/react";

import { GasViscosityMethod, TankType, WaterViscosityMethod, ZMethod } from "@/model";
import FossilyticsNumberField from "../../../../../../../../components/fields/FossilyticsNumberField";
import { TankTypeOptions, gasViscMethodOptions, wtrViscMethodOptions, zMethodOptions } from "./model";
import { Output } from "@/models/koldun";
import { THEORETICAL_HELP_LINKS, renderLabelWithHelp } from "@/features/help-link";

import { ErrorValidationDetail } from "@/models/ErrorInputValidation";

interface CsgBaseInputsProps {
  value: Output;
  onChange: React.Dispatch<React.SetStateAction<Output | null>>;
  errorInputValidation?: ErrorValidationDetail[];
}

const CsgBaseInputs = ({ value, onChange, errorInputValidation }: CsgBaseInputsProps) => {
  const baseInput = value.base_inputs;

  return (
    <Pivot
      styles={{
        link: {
          padding: 0,
          marginRight: 20,
        },
      }}
      defaultSelectedKey={"csgBaseInputs"}
    >
      <PivotItem
        headerText="Base Inputs"
        headerButtonProps={{
          "data-order": 1,
        }}
        itemKey={"csgBaseInputs"}
      >
        <Stack horizontal tokens={{ childrenGap: 20 }}>
          <Stack grow={1} className="equal-size" tokens={{ childrenGap: 5 }}>
            <Dropdown
              ariaLabel="koldun_csg_tank_type_dropdown"
              label="Tank type"
              options={TankTypeOptions}
              selectedKey={baseInput.tank_type}
              onRenderLabel={(props) => renderLabelWithHelp(THEORETICAL_HELP_LINKS.pssModels, props)}
              onChange={(_, v) => {
                if (v !== undefined) {
                  onChange((prev) => {
                    if (prev === null) {
                      return null;
                    }

                    return {
                      ...prev,
                      tank_type: v.key as TankType,
                    };
                  });
                }
              }}
            />

            <FossilyticsNumberField
              label="Wellbore radius"
              suffix="ft"
              debounceDelay={1000}
              value={baseInput.wellbore_radius_ft}
              onChange={(v) => {
                if (v !== undefined) {
                  onChange((prev) =>
                    prev
                      ? {
                          ...prev,
                          base_inputs: {
                            ...prev.base_inputs,
                            wellbore_radius_ft: v,
                          },
                        }
                      : null
                  );
                }
              }}
              keyField={"base_inputs.wellbore_radius_ft"}
              errors={errorInputValidation}
            />
            <FossilyticsNumberField
              label="Formation compressibility "
              suffix="1/psia"
              min={0}
              max={100}
              debounceDelay={1000}
              value={baseInput.formation_compressibility}
              onChange={(v) => {
                if (v !== undefined) {
                  onChange((prev) =>
                    prev
                      ? {
                          ...prev,
                          base_inputs: {
                            ...prev.base_inputs,
                            formation_compressibility: v,
                          },
                        }
                      : null
                  );
                }
              }}
              keyField={"base_inputs.formation_compressibility"}
              errors={errorInputValidation}
            />
            <FossilyticsNumberField
              label="Number of Coal Measures"
              min={1}
              max={10}
              debounceDelay={1000}
              value={value.num_of_measures}
              onChange={(v) => {
                if (v !== undefined) {
                  onChange((prev) => (prev ? { ...prev, num_of_measures: v } : null));
                }
              }}
              keyField={"num_of_measures"}
              errors={errorInputValidation}
            />
          </Stack>

          <Stack grow={1} className="equal-size" tokens={{ childrenGap: 5 }}>
            <Dropdown
              ariaLabel="koldun_csg_z_method_dropdown"
              label="Z Method"
              options={zMethodOptions}
              selectedKey={baseInput.z_correlation}
              onRenderLabel={(props) => renderLabelWithHelp(THEORETICAL_HELP_LINKS.gasDeviationFactor, props)}
              onChange={(_, v) => {
                if (v !== undefined) {
                  onChange((prev) => {
                    if (prev === null) {
                      return null;
                    }

                    return {
                      ...prev,
                      base_inputs: {
                        ...prev.base_inputs,
                        z_correlation: v.key as ZMethod,
                      },
                    };
                  });
                }
              }}
            />
            <Dropdown
              ariaLabel="koldun_csg_water_viscosity_method_dropdown"
              label="Water viscosity method"
              options={wtrViscMethodOptions}
              selectedKey={baseInput.water_viscosity_method}
              onChange={(_, v) => {
                if (v !== undefined) {
                  onChange((prev) => {
                    if (prev === null) {
                      return null;
                    }

                    return {
                      ...prev,
                      base_inputs: {
                        ...prev.base_inputs,
                        water_viscosity_method: v.key as WaterViscosityMethod,
                      },
                    };
                  });
                }
              }}
            />
            <Dropdown
              ariaLabel="koldun_csg_gas_viscosity_method_dropdown"
              label="Gas viscosity method"
              options={gasViscMethodOptions}
              selectedKey={baseInput.gas_viscosity_method}
              onRenderLabel={(props) => renderLabelWithHelp(THEORETICAL_HELP_LINKS.gasViscosity, props)}
              onChange={(_, v) => {
                if (v !== undefined) {
                  onChange((prev) => {
                    if (prev === null) {
                      return null;
                    }

                    return {
                      ...prev,
                      base_inputs: {
                        ...prev.base_inputs,
                        gas_viscosity_method: v.key as GasViscosityMethod,
                      },
                    };
                  });
                }
              }}
            />
          </Stack>
        </Stack>
      </PivotItem>
    </Pivot>
  );
};

export default CsgBaseInputs;
