import React, { useCallback, useEffect, useState } from "react";
import { useTheme } from "@fluentui/react";
import { CellChange, Column, NumberCell, ReactGrid, Row } from "@silevis/reactgrid";

import { ModuleGildroHydrateCase, ModuleGildroHydrateOutput } from "./model";

interface ModuleGildroHydrateDataGridProps {
  onCasesChange: (value: ModuleGildroHydrateCase[]) => void;
  output: ModuleGildroHydrateOutput | undefined;
}

const ModuleGildroHydrateDataGrid = ({ onCasesChange, output }: ModuleGildroHydrateDataGridProps) => {
  const [dataPoints, setDataPoints] = useState<number[][]>(Array.from(Array(50).keys()).map(() => [0, 0, 0, 0]));
  const [lastValidIndex, setLastValidIndex] = useState(0);

  // Calculate last valid data index in data points list
  useEffect(() => {
    let newLastValidIndex = 0;
    for (let i = 0; i < dataPoints.length; i++) {
      if (i > 0 && (!dataPoints[i].some((x) => x > 0) || dataPoints[i][3] === 0)) {
        newLastValidIndex = i - 1;
        break;
      }
    }
    setLastValidIndex(newLastValidIndex);

    if (newLastValidIndex > 0) {
      onCasesChange(
        dataPoints
          .filter((_, i) => i <= newLastValidIndex)
          .map((p) => ({
            gas_rate: p[0],
            oil_rate: p[1],
            wtr_rate: p[2],
            wellhead_pressure_psia: p[3],
          }))
      );
    } else {
      onCasesChange([]);
    }
  }, [dataPoints, onCasesChange]);

  const theme = useTheme();

  // Parse data from spreadsheet to points array
  const dataPointsCols = [
    { columnId: "qg", width: 75 },
    { columnId: "qo", width: 75 },
    { columnId: "qw", width: 75 },
    { columnId: "pwh", width: 75 },
    { columnId: "pbh", width: 75 },
  ] as Column[];

  const dataPointsRows = [
    {
      rowId: "header",
      cells: [
        { type: "header", text: "Gas rate" },
        { type: "header", text: "Oil rate" },
        { type: "header", text: "Water rate" },
        { type: "header", text: "WH press." },
        { type: "header", text: "BH press." },
      ],
    },
    ...dataPoints.map((p, i) => ({
      rowId: i,
      cells: [
        {
          type: "number",
          value: p[0],
          hideZero: i > lastValidIndex,
        },
        {
          type: "number",
          value: p[1],
          hideZero: i > lastValidIndex,
        },
        {
          type: "number",
          value: p[2],
          hideZero: i > lastValidIndex,
        },
        {
          type: "number",
          value: p[3],
          hideZero: i > lastValidIndex,
        },
        {
          type: "number",
          value:
            output && i < output.cases.length
              ? output.cases[i].pressures_psia[output.cases[i].pressures_psia.length - 1]
              : undefined,
          hideZero: i > lastValidIndex,
          nonEditable: true,
          style: { background: theme.palette.neutralLight },
        },
      ],
    })),
  ] as Row[];

  const handleChanges = useCallback((changes: CellChange[]) => {
    setDataPoints((oldPoints) => {
      const newPoints = [...oldPoints];
      changes.forEach((change) => {
        const fieldIdx = ["qg", "qo", "qw", "pwh", "pbh"].indexOf(change.columnId as string);
        if (fieldIdx < 0) return;

        const numberCell = change.newCell as NumberCell;
        const pointIdx = change.rowId as number;
        newPoints[pointIdx][fieldIdx] = numberCell.value || 0;
      });
      return newPoints;
    });
  }, []);

  return (
    <ReactGrid
      columns={dataPointsCols}
      rows={dataPointsRows}
      enableRangeSelection
      stickyTopRows={1}
      onCellsChanged={handleChanges}
    />
  );
};

export default ModuleGildroHydrateDataGrid;
