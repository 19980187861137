import { createPoll } from "@/utils/apiFetcher";
import { gazAutoRtaInputCalculate } from "@/constants/apiUrl";
import { GazAutoRtaInputGrid, InputGridCalculation } from "../InputGrid";

export const pollCalculateInputGazAutoRta = (payload: GazAutoRtaInputGrid) => {
  return createPoll<InputGridCalculation, GazAutoRtaInputGrid>({
    path: gazAutoRtaInputCalculate,
    body: payload,
  });
};
