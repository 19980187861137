import { PvtChart, SummaryCard } from "../Generic";
import { ForecastEvent } from "./forecast";

export type TahkCsgInputResponse = {
  layers: TahkCsgInputLayerCharts[];
};

export type TahkCsgInputLayerCharts = {
  gas_pvt_charts: TahkCsgGasPvtChart;
  water_pvt_charts: TahkCsgWaterPvtChart;
  langmuir_charts: TahkCsgLangmuirChart;
  rel_perm_charts: TahkCsgRelPermChart;
  summary_card: SummaryCard[];
};

type TahkCsgGasPvtChart = {
  compressibility_factor: number[];
} & PvtChart;

type TahkCsgWaterPvtChart = PvtChart;

type TahkCsgLangmuirChart = {
  pressure: number[];
  gas_content: number[];
  desorption_compressibility: number[];
};

type TahkCsgRelPermChart = {
  water_saturation: number[];
  gas_relative_permeability: number[];
  water_relative_permeability: number[];
};

// Request
export enum TahkCsgSelectedGeometryEnum {
  SpecifyArea = "SpecifyArea",
  SpecifyDimension = "SpecifyDimension",
}

type TahkCsgSelectedGeometry = TahkCsgSelectedGeometryEnum.SpecifyArea | TahkCsgSelectedGeometryEnum.SpecifyDimension;

export type TahkCsgInputState = {
  number_of_layers: number;
  selected_geometry: TahkCsgSelectedGeometry;
  layers: TahkCsgInputLayer[];
  wellbore_inputs: TahkCsgWellboreInput;
  flowing_pressure_table: ForecastEvent[];
  power_law_skin: TahkCsgPowerLawSkin;
};

// WELLBORE
type TahkCsgWellboreInput = {
  contract_rate_mode: TahkContractRateEnum.GasContractRate | TahkContractRateEnum.WaterContractRate;
  number_of_days: number;
  selected_flow_pressure_type: TahkSelectedFlowPressureTypeEnum.BottomHolePressure | TahkSelectedFlowPressureTypeEnum.TubingHeadPressure;
  parameters: TahkCsgWellboreParameter;
};

export enum TahkContractRateEnum {
  WaterContractRate = "WaterContractRate",
  GasContractRate = "GasContractRate",
}

export enum TahkSelectedFlowPressureTypeEnum {
  TubingHeadPressure = "TubingHeadPressure",
  BottomHolePressure = "BottomHolePressure",
}

type TahkCsgWellboreParameter = {
  pump_depth?: number;
  minimum_pump_head?: number;
  pump_draw_down_rate?: number;
};

// POWER LAW
type TahkCsgPowerLawSkin = {
  permeability_a: number | null;
  permeability_b: number | null;
  skin_a: number | null;
  skin_b: number | null;
};

// Tahk csg input layer

export enum FormationCompressibilityCorrelationEnum {
  NewmanSandstone = "NewmanSandstone",
  NewmanLimestone = "NewmanLimestone",
  Hall = "Hall",
  Constant = "Constant",
  DobryninLaurent = "DobryninLaurent",
  DobryninWang = "DobryninWang",
}

type TahkCsgReservoirParam = {
  initial_pressure: number;
  formation_temperature: number;
  net_pay: number;
  layer_depth: number;
  porosity: number;
  selected_formation_compressibility_correlation:
    | FormationCompressibilityCorrelationEnum.Constant
    | FormationCompressibilityCorrelationEnum.DobryninLaurent
    | FormationCompressibilityCorrelationEnum.DobryninWang
    | FormationCompressibilityCorrelationEnum.NewmanSandstone
    | FormationCompressibilityCorrelationEnum.Hall
    | FormationCompressibilityCorrelationEnum.NewmanLimestone;
  formation_compressibility: number;
  initial_water_saturation: number;
  permeability_x_axis: number;
  permeability_y_axis: number;
  permeability_z_axis: number;
};

type TahkCsgReservoirParamArea = {
  reservoir_area: number;
} & TahkCsgReservoirParam;

type TahkCsgReservoirParamDimension = {
  reservoir_width: number;
  reservoir_length: number;
} & TahkCsgReservoirParam;

// GAS PVT
export enum TahkCsgZCorrelationEnum {
  DranchukPurvisRobinson = "DranchukPurvisRobinson",
  BeggsBrill = "BeggsBrill",
  Papay = "Papay",
  DranchukAbouKassem = "DranchukAbouKassem",
}
export enum TahkCsgGasViscosityCorrelationEnum {
  GonzalesEakin = "GonzalesEakin",
  Londono = "Londono",
  Standing = "Standing",
}

type TahkCsgGasPvt = {
  carbon_dioxide: number;
  hydrogen_sulphide: number;
  nitrogen: number;
  selected_gas_viscosity_correlation:
    | TahkCsgGasViscosityCorrelationEnum.GonzalesEakin
    | TahkCsgGasViscosityCorrelationEnum.Londono
    | TahkCsgGasViscosityCorrelationEnum.Standing;
  selected_z_correlation:
    | TahkCsgZCorrelationEnum.BeggsBrill
    | TahkCsgZCorrelationEnum.DranchukAbouKassem
    | TahkCsgZCorrelationEnum.DranchukPurvisRobinson
    | TahkCsgZCorrelationEnum.Papay;
  specific_gravity: number;
};

// WATER PVT
export enum TahkCsgWaterViscosityEnum {
  BeggsBrill = "BeggsBrill",
  McCain = "McCain",
  LouisianaStateUniversity = "LouisianaStateUniversity",
}
export enum TahkCsgWaterCompressibilityEnum {
  BeggsBrill = "BeggsBrill",
  McCain = "McCain",
}

type TahkCsgWaterPvt = {
  salinity: number;
  selected_water_compressibility_correlation: TahkCsgWaterCompressibilityEnum.BeggsBrill | TahkCsgWaterCompressibilityEnum.McCain;
  selected_water_viscosity_correlation:
    | TahkCsgWaterViscosityEnum.BeggsBrill
    | TahkCsgWaterViscosityEnum.McCain
    | TahkCsgWaterViscosityEnum.LouisianaStateUniversity;
};

// LANGMUIR ISOTHERM
export enum TahkCsgLangmuirIsothermEnum {
  Ogip = "Ogip",
  Volumetric = "Volumetric",
}

type TahkCsgLangmuirIsothermVolumetric = {
  desorption_pressure: number;
  langmuir_pressure: number;
  langmuir_volume: number;
  rock_density: number;
};

type TahkCsgLangmuirIsothermOgip = {
  under_saturation: number;
  initial_gas_content: number;
  langmuir_pressure: number;
  ogip: number;
};

//    RELATIVE PERMEABILITY
export enum TahkCsgRelativePermeabilityCorrelationEnum {
  Burdine = "Burdine",
  ModifiedBurdine = "ModifiedBurdine",
  Corey = "Corey",
}

export type TahkCsgPermeability = {
  parameter: TahkCsgPermeabilityCorey | TahkCsgPermeabilityBurdine | TahkCsgPermeabilityModifiedBurdine;
  selected_relative_permeability_correlation:
    | TahkCsgRelativePermeabilityCorrelationEnum.Burdine
    | TahkCsgRelativePermeabilityCorrelationEnum.ModifiedBurdine
    | TahkCsgRelativePermeabilityCorrelationEnum.Corey;
};

type TahkCsgPermeabilityCorey = {
  krg_max: number;
  connate_water_saturation: number;
  residual_gas_saturation: number;
  krw_max: number;
  ng: number;
  nw: number;
};

type TahkCsgPermeabilityBurdine = {
  krg_max: number;
  connate_water_saturation: number;
  lamb: number;
};

type TahkCsgPermeabilityModifiedBurdine = {
  krg_max: number;
  connate_water_saturation: number;
  residual_gas_saturation: number;
  lamb: number;
  n: number;
};

// Well input
export enum TahkCsgWellInputType {
  Vertical = "Vertical",
  VerticalFractured = "VerticalFractured",
  Horizontal = "Horizontal",
}

export type TahkCsgWellInputBase = {
  wellbore_radius: number;
  selected_well_type: TahkCsgWellInputType.Horizontal | TahkCsgWellInputType.VerticalFractured | TahkCsgWellInputType.Vertical;
  well_parameters: TahkCsgVerticalWellParameter | TahkCsgVerticalFracturedWellParameter | TahkCsgHorizontalWellParameter;
};
type TahkCsgWellInputSpecifyWellPosition = {
  well_position_xaxis: number;
  well_position_yaxis: number;
  well_position_zaxis: number;
} & TahkCsgWellInputBase;

export enum TahkCsgWellParameterSkinModelEnum {
  Constant = "Constant",
  PowerLaw = "PowerLaw",
}
type TahkCsgVerticalWellParameter = {
  selected_skin_model: TahkCsgWellParameterSkinModelEnum.Constant | TahkCsgWellParameterSkinModelEnum.PowerLaw;
  skin: number;
};

type TahkCsgVerticalFracturedWellParameter = {
  fracture_half_length: number;
  fracture_conductivity: number;
  selected_skin_model: TahkCsgWellParameterSkinModelEnum.Constant | TahkCsgWellParameterSkinModelEnum.PowerLaw;
  skin: number;
};

type TahkCsgHorizontalWellParameter = {
  horizontal_length: number;
  selected_skin_model: TahkCsgWellParameterSkinModelEnum.Constant | TahkCsgWellParameterSkinModelEnum.PowerLaw;
  skin: number;
};

// Matrix shrinkage

export enum TahkCsgGeomechanicModelEnum {
  NoModel = "NoModel",
  PalmerMansoori = "PalmerMansoori",
  YilmazNur = "YilmazNur",
  BurgoyneShrivastava = "BurgoyneShrivastava",
}

type TahkCsgMatrixShrinkage = {
  selected_geomechanic_model:
    | TahkCsgGeomechanicModelEnum.BurgoyneShrivastava
    | TahkCsgGeomechanicModelEnum.YilmazNur
    | TahkCsgGeomechanicModelEnum.NoModel
    | TahkCsgGeomechanicModelEnum.PalmerMansoori;
  parameter?: TahkCsgMatrixShrinkagePalmerMansoori | TahkCsgMatrixShrinkageYilmazNur | TahkCsgMatrixShrinkageBurgoyneShrivastava;
};

type TahkCsgMatrixShrinkagePalmerMansoori = {
  poissons_ratio: number;
  grain_compressibility: number;
  langmuir_volume_strain: number;
  young_module: number;
  shrinkage_exponent: number;
  shrinkage_factor: number;
};

type TahkCsgMatrixShrinkageYilmazNur = {
  permeability_modulus: number;
};

type TahkCsgMatrixShrinkageBurgoyneShrivastava = {
  shrinkage_factor: number;
  maximum_productivity_multiplier: number;
};

export type TahkCsgInputLayer = {
  reservoir_parameters: TahkCsgReservoirParamArea | TahkCsgReservoirParamDimension;
  gas_pvt: TahkCsgGasPvt;
  water_pvt: TahkCsgWaterPvt;
  selected_langmuir_inputs: TahkCsgLangmuirIsothermEnum.Ogip | TahkCsgLangmuirIsothermEnum.Volumetric;
  langmuir_isotherm: TahkCsgLangmuirIsothermVolumetric | TahkCsgLangmuirIsothermOgip;
  relative_permeability: TahkCsgPermeability;
  well_inputs: TahkCsgWellInputBase | TahkCsgWellInputSpecifyWellPosition;
  matrix_shrinkage: TahkCsgMatrixShrinkage;
};
