import React, { createContext, useContext, useState, useMemo, useCallback, ReactElement } from "react";
import { useIsFetching } from "@tanstack/react-query";

import { DataResponse, DataSet, isDataSet, ModuleId, Field, Group, Project } from "@/model";
import { ModuleDataViewField } from "@/modules/ModuleDataView";
import { ModuleSpadDeclineAnalysis } from "@/features/modules/spad/decline";

import { CsvData, PostRequest } from "@/features/app/app.types";
import { spadDeclineDefaultState } from "../constants";

import { useSettingState } from "@/SettingsState";
import useSpadDeclineDataView from "./useSpadDeclineDataView";
import useSpadDeclineAnalysis from "./useSpadDeclineAnalysisV2";
import useCompareSpadAnalysis from "./useCompareSpadAnalysis";

import { SpadAnalysisInputOptions, SpadDeclineAnalysis, ModuleSpadDeclineCaseParams, DeclineType } from "../index.types";
import { ApiError } from "@/models/APIGeneric";
import { ErrorValidationDetail } from "@/models/ErrorInputValidation";

type SpadDeclineState = {
  hideSidebar: boolean;
  isLoading: boolean;
  moduleFields: { [k in Field]?: ModuleDataViewField };
  isForecastBeforeData: boolean;
  setCleaned: (cleanedData: DataResponse | undefined) => void;
  setIsFromBeFlag: () => void;
  cleaned?: DataResponse;

  dataSets: DataSet[];
  group?: Group;
  project?: Project;
  tabIndex: number;
  setTabIndex: (index: number) => void;
  unit: string;
  options: SpadAnalysisInputOptions;
  forecastStartDate?: string;
  forecastEndDate?: string;
  analysis?: SpadDeclineAnalysis;
  onChangeOption: (val: any, key: keyof SpadAnalysisInputOptions) => void;
  paramsOps?: ModuleSpadDeclineCaseParams;
  paramsProfile?: ModuleSpadDeclineCaseParams;
  xAxesState: boolean;
  handleXAxesStateFromChild: () => void;
  onDeclineArpChange: (l: number[][] | [string, number][], i: number) => void;
  downloadExport: (freq: "daily" | "monthly" | "yearly") => void;
  setParamsOps: React.Dispatch<React.SetStateAction<ModuleSpadDeclineCaseParams | undefined>>;
  setParamsProfile: React.Dispatch<React.SetStateAction<ModuleSpadDeclineCaseParams | undefined>>;
  selectedDeclineTypePivotKey: DeclineType;
  setSelectedDeclineTypePivotKey: React.Dispatch<React.SetStateAction<"ops" | "profile">>;

  compareAnalysis?: ModuleSpadDeclineAnalysis;
  compareName?: string;
  compareDropdown?: ReactElement;
  setCsvData: (csvData?: CsvData[]) => void;
  isApiError?: ApiError;
  errorInputValidation: ErrorValidationDetail[];
  setLocalLoading: (val: boolean) => void;
  setFinishCalculation: (val: boolean) => void;
  finishCalculation: boolean;

  changeClusterStartDayFromChart: (val: any) => void;
};

const SpadDeclineContext = createContext<SpadDeclineState>(spadDeclineDefaultState);

type SpadDeclineContextProps = {
  children: React.ReactNode;
  selectedDataSets?: DataSet | DataSet[];
  hideSidebar: boolean;
  currentModule?: ModuleId;
  postRequest: PostRequest;
  isLoading: boolean;
  group?: Group;
  project?: Project;
  setCsvData: (csvData?: CsvData[]) => void;
  apiError?: ApiError;
};

const SpadDeclineProvider = ({
  children,
  selectedDataSets,
  hideSidebar,
  currentModule,
  isLoading,
  postRequest,
  project,
  group,
  setCsvData,
  apiError,
}: Readonly<SpadDeclineContextProps>) => {
  const { compareDropdown, compareAnalysis, compareName } = useCompareSpadAnalysis({ currentModule });

  const [tabIndex, setTabIndex] = useState(0);

  const [cleaned, setCleaned] = useState<DataResponse>();
  const [forecastStartDate] = useSettingState<string | undefined>("forecast_start_date", true);
  const [forecastEndDate] = useSettingState<string | undefined>("forecast_end_date", true);
  const [localLoading, setLocalLoading] = useState(false);

  const dataSets = useMemo(() => {
    if (isDataSet(selectedDataSets)) return [selectedDataSets];
    return selectedDataSets ?? [];
  }, [selectedDataSets]);

  const { isForecastBeforeData, moduleFields } = useSpadDeclineDataView({
    currentModule,
    cleaned,
    forecastStartDate,
  });

  const isFetching = useIsFetching();

  const onClickTab = useCallback(
    (index: number) => {
      setTabIndex(index);
      if (index !== 0) setCsvData();
    },
    [setCsvData]
  );

  const {
    unit,
    options,
    analysis,
    onChangeOption,
    paramsOps,
    paramsProfile,
    xAxesState,
    handleXAxesStateFromChild,
    onDeclineArpChange,
    downloadExport,
    selectedDeclineTypePivotKey,
    setSelectedDeclineTypePivotKey,
    setParamsOps,
    setParamsProfile,
    cleanUpPageState,
    setIsFromBeFlag,
    isFetching: isLoadingCalculation,
    errorInputValidation,
    finishCalculation,
    changeClusterStartDayFromChart,
    setFinishCalculation,
  } = useSpadDeclineAnalysis({
    postRequest,
    dataSets,
    group,
    project,
    forecastEndDate,
    forecastStartDate,
    tabIndex,
    currentModule,
    cleaned,
    setCleaned,
  });

  const onCleanData = useCallback(
    (cleanedData?: DataResponse) => {
      setCleaned(cleanedData);
      cleanUpPageState();
    },
    [cleanUpPageState]
  );

  const state = useMemo(() => {
    return {
      isLoading: isLoading || isFetching > 0 || isLoadingCalculation || localLoading,
      hideSidebar,
      isForecastBeforeData,
      moduleFields,
      setCleaned: onCleanData,
      cleaned,
      dataSets,
      tabIndex,
      setTabIndex: onClickTab,
      unit,
      options,
      forecastEndDate,
      forecastStartDate,
      analysis,
      onChangeOption,
      paramsOps,
      paramsProfile,
      xAxesState,
      handleXAxesStateFromChild,
      onDeclineArpChange,
      downloadExport,
      selectedDeclineTypePivotKey,
      setSelectedDeclineTypePivotKey,
      setParamsOps,
      setParamsProfile,
      compareDropdown,
      compareAnalysis,
      compareName,
      setIsFromBeFlag,
      setCsvData,
      isApiError: apiError,
      errorInputValidation,
      setLocalLoading,
      finishCalculation,
      setFinishCalculation,
      changeClusterStartDayFromChart,
    };
  }, [
    changeClusterStartDayFromChart,
    isLoading,
    isFetching,
    isLoadingCalculation,
    localLoading,
    hideSidebar,
    isForecastBeforeData,
    moduleFields,
    onCleanData,
    cleaned,
    dataSets,
    tabIndex,
    onClickTab,
    unit,
    options,
    forecastEndDate,
    forecastStartDate,
    analysis,
    onChangeOption,
    paramsOps,
    paramsProfile,
    xAxesState,
    handleXAxesStateFromChild,
    onDeclineArpChange,
    downloadExport,
    selectedDeclineTypePivotKey,
    setSelectedDeclineTypePivotKey,
    setParamsOps,
    setParamsProfile,
    compareDropdown,
    compareAnalysis,
    compareName,
    setIsFromBeFlag,
    setCsvData,
    apiError,
    errorInputValidation,
    finishCalculation,
    setFinishCalculation,
  ]);

  return <SpadDeclineContext.Provider value={state}>{children}</SpadDeclineContext.Provider>;
};

export function useSpadDeclineState() {
  return useContext(SpadDeclineContext);
}

export default SpadDeclineProvider;
