import { Stack } from "@fluentui/react";

import FossilyticsNumberField from "@/components/fields/FossilyticsNumberField";
import { CaseInputsProps } from "./case-inputs.types";

export const CaseInputs = ({ params, onChange, unit, disabled }: CaseInputsProps) => {
  return (
    <Stack tokens={{ childrenGap: 5 }}>
      <FossilyticsNumberField
        label="Initial rate"
        suffix={`${unit}/d`}
        debounceDelay={1000}
        disabled={disabled}
        key={params?.qi}
        value={params?.qi ? parseFloat(params.qi.toFixed(2)) : undefined}
        onChange={(qi) => {
          onChange({ qi });
        }}
      />

      <FossilyticsNumberField
        label="Arps exponent"
        debounceDelay={1000}
        disabled={disabled}
        value={params?.b ? parseFloat(params.b.toFixed(2)) : params?.b}
        key={params?.b}
        onChange={(b) => {
          onChange({ b });
        }}
      />

      <FossilyticsNumberField
        label="Decline rate"
        suffix="1/days"
        debounceDelay={1000}
        disabled={disabled}
        key={params?.m}
        value={params?.m ? parseFloat(params.m.toFixed(6)) : params?.m}
        onChange={(m) => {
          onChange({ m });
        }}
      />
      <FossilyticsNumberField
        label="Initial day"
        debounceDelay={1000}
        disabled={disabled}
        value={params?.x}
        key={params?.x}
        onChange={(x) => {
          onChange({ x });
        }}
      />
    </Stack>
  );
};
