import { useState, useMemo } from "react";
import { IDropdownOption } from "@fluentui/react";
import { FossilyticsChartAxis, FossilyticsChartSeries } from "@/components/FossilyticsChart";

import useThemeStyling from "@/utils/useThemeStyling";

import { ModuleAutoRTAYaxisField, DimensionlessPressureFieldName } from "../../model";

import { AnalysisCalculation, DimensionlessPressureArraysModel, DimensionlessTimeArrayModel } from "@/models/gaz/autoRta";

type NormalizedPressureProps = {
  analysisCalculation: AnalysisCalculation;
};

const useGazAutoRtaAnalysisNormalizedPressure = ({ analysisCalculation }: NormalizedPressureProps) => {
  const { theme } = useThemeStyling();
  const [selectedXaxis, setSelectedXaxis] = useState<IDropdownOption>({ key: "wellbore_radius_normalized_time", text: "tDrw" });

  const xAxes = useMemo<FossilyticsChartAxis[]>(
    () => [
      {
        name: selectedXaxis.text,
        type: "log",
        color: theme.palette.black,
      },
    ],
    [theme, selectedXaxis]
  );

  const yAxes = useMemo<FossilyticsChartAxis[]>(
    () => [
      {
        name: "pD",
        type: "log",
        color: theme.palette.black,
        show: true,
      },
    ],
    [theme]
  );

  const moduleDimensionlessPressureField = useMemo<{ [k in keyof DimensionlessPressureArraysModel]?: ModuleAutoRTAYaxisField }>(
    () => ({
      normalized_pressure: {
        name: "pD",
        yAxis: "pD",
        color: theme.palette.red,
        defaultDisabled: false,
      },
      bourdet_derivative: {
        name: "pD'",
        yAxis: "pD'",
        color: theme.palette.blueMid,
        defaultDisabled: false,
      },
      normalized_pressure_integral: {
        name: "pDi",
        yAxis: "pDi",
        color: theme.palette.green,
        defaultDisabled: true,
      },
      normalized_pressure_integral_derivative: {
        name: "pDid",
        yAxis: "pDid",
        color: theme.palette.orangeLighter,
        defaultDisabled: true,
      },
      beta_derivative: {
        name: "pβd",
        yAxis: "pβd",
        color: theme.palette.yellow,
        defaultDisabled: true,
      },
      chow_pressure_group: {
        name: "CPG",
        yAxis: "CPG",
        color: theme.palette.purple,
        defaultDisabled: true,
      },
    }),
    [theme]
  );

  const dotPressureSeries = useMemo<FossilyticsChartSeries[]>(
    () =>
      Object.keys(moduleDimensionlessPressureField)
        .map((v) => v as keyof DimensionlessPressureArraysModel)
        .map((f) => ({
          name: `${DimensionlessPressureFieldName(f)}`,
          type: "scatter",
          symbolSize: 5,
          lineWidth: 4,
          color: moduleDimensionlessPressureField[f]!.color,
          data: analysisCalculation.transient_analysis_curves_data.dimensionless_time_arrays
            ? analysisCalculation.transient_analysis_curves_data.dimensionless_time_arrays[
                selectedXaxis.key as keyof DimensionlessTimeArrayModel
              ].map((d, i) => [d, analysisCalculation.transient_analysis_curves_data.dimensionless_pressure_arrays[f][i]])
            : [],
          defaultDisabled: moduleDimensionlessPressureField[f]?.defaultDisabled,
        })),
    [moduleDimensionlessPressureField, analysisCalculation, selectedXaxis]
  );

  const linePressureSeries = useMemo<FossilyticsChartSeries[]>(
    () =>
      Object.keys(moduleDimensionlessPressureField)
        .map((v) => v as keyof DimensionlessPressureArraysModel)
        .map((f) => ({
          name: `${DimensionlessPressureFieldName(f)} Line`,
          type: "line",
          lineWidth: 2,
          symbolSize: 0.1,
          color: moduleDimensionlessPressureField[f]!.color,
          data: analysisCalculation.transient_analysis_curves_model.dimensionless_time_arrays
            ? analysisCalculation.transient_analysis_curves_model.dimensionless_time_arrays[
                selectedXaxis.key as keyof DimensionlessTimeArrayModel
              ].map((d, i) => [d, analysisCalculation.transient_analysis_curves_model.dimensionless_pressure_arrays[f][i]])
            : [],
          defaultDisabled: moduleDimensionlessPressureField[f]?.defaultDisabled,
        })),
    [moduleDimensionlessPressureField, analysisCalculation, selectedXaxis]
  );

  const logPessureFlowSeries = useMemo<FossilyticsChartSeries[]>(
    () => [
      ...dotPressureSeries.map((dotItems) => ({
        ...dotItems,
      })),
      ...linePressureSeries.map((lineItems) => ({
        ...lineItems,
      })),
    ],
    [dotPressureSeries, linePressureSeries]
  );

  return {
    id: "normalized_pressure",
    xAxes,
    yAxes,
    series: logPessureFlowSeries,
    onChangeDropdownOption: (val: IDropdownOption) => setSelectedXaxis(val),
    selectedOption: selectedXaxis,
  };
};

export default useGazAutoRtaAnalysisNormalizedPressure;
