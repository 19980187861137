import React from "react";
import ScreenCapture from "@/utils/screenCapture";
import Avatar from "@mui/material/Avatar";
import { Link } from "react-router-dom";
import styled from "@emotion/styled";

import { ReactComponent as Logo } from "../../logo.svg";

import useNavMenu from "./useNavMenu";
import NavMenu from "./NavMenu";

const HeaderContainer = styled.header((props: any) => ({
  boxShadow: props.boxShadow,
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  paddingRight: 10,
  svg: {
    width: 116,
    height: 48,
  },
}));

const NavContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 8px;
  cursor: pointer;
`;

const ProfileContainer = styled.div`
  display: flex;
  flex-direction: row;
  color: rgb(133, 133, 133);
  align-items: center;

  .profile-detail {
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    font-size: 12px;
    margin-right: 20px;
  }
  .email-text {
    color: black;
    font-size: 14px;
  }
`;

const Header = () => {
  const {
    theme,
    fileMenuList,
    modulesMenuList,
    primaryMenuList,
    projectMenuList,
    helpMenuList,
    buildNumberText,
    email,
    tenant,
    handleScreenCapture,
    resetProject,
  } = useNavMenu();

  return (
    <ScreenCapture onEndCapture={handleScreenCapture}>
      {({ onStartCapture }) => {
        return (
          <HeaderContainer className="App-header" boxShadow={theme.effects.elevation8}>
            <NavContainer>
              <NavMenu
                title="Predico"
                customTitle={
                  <Link onClick={resetProject} to="/">
                    <Logo style={{ marginTop: 11 }} aria-label="Predico" />
                  </Link>
                }
                menuList={primaryMenuList}
                resetProject={resetProject}
              />
              <NavMenu
                customFunc={{
                  id: "screen-capture",
                  onClick: () => {
                    onStartCapture();
                  },
                }}
                title="File"
                menuList={fileMenuList}
              />
              <NavMenu title="Modules" menuList={modulesMenuList} />
              <NavMenu title="Project" menuList={projectMenuList} />
              <NavMenu title="Help" menuList={helpMenuList} />
            </NavContainer>

            <ProfileContainer>
              <div className="profile-detail">
                <span className="email-text">{email}</span>
                <span>{tenant ?? "Predico"}</span>
                <span>Version: {buildNumberText}</span>
              </div>
              <Avatar>{email.slice(0, 1).toUpperCase()}</Avatar>
            </ProfileContainer>
          </HeaderContainer>
        );
      }}
    </ScreenCapture>
  );
};

export default Header;
