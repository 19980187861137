import { object, number, string, z } from "zod";

const PvtChartScheme = object({
  pressure: number().array(),
  viscosity: number().array(),
  density: number().array(),
  form_vol_factor: number().array(),
  compressibility: number().array(),
});

export type PvtChart = z.infer<typeof PvtChartScheme>;

const SummaryCardScheme = object({
  parameter: string(),
  value: number(),
  unit: string(),
});

export type SummaryCard = z.infer<typeof SummaryCardScheme>;

const PvtTableHeaderMapScheme = object({
  name: string(),
  unit: string(),
  sub: string().optional(),
});

export type PvtTableHeaderMap = z.infer<typeof PvtTableHeaderMapScheme>;

export enum SmoothingTypeEnum {
  "interp" = "interp",
  "movavg" = "movavg",
}

// currently only used in thak csg
// we disable moving avergae
export const smoothingTypeDropdown = [
  {
    key: SmoothingTypeEnum.interp,
    text: "Interpolation",
  },
  // {
  //   key: SmoothingTypeEnum.movavg,
  //   text: "Moving average",
  // },
];

const ModuleIdentityScheme = object({
  data_set_ids: string().array(),
  project_id: string(),
  group_id: string(),
});

export type ModuleIdentity = z.infer<typeof ModuleIdentityScheme>;

const TableItemSchema = object({
  text: string(),
  sub: string().optional(),
  link: string().optional(),
  notation: string().optional(),
  suffix: string().optional(),
  width: number().int().optional(),
});

const TableHeaderScheme = z.record(z.string(), TableItemSchema);

export type TableHeader = z.infer<typeof TableHeaderScheme>;

export const genericChartResultScheme = object({
  rates: number().array(),
  cumulative_production: number().array(),
  days: number().array(),
});

export type GenericChartResult = z.infer<typeof genericChartResultScheme>;
