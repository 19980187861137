import React, { createContext, useContext, useState, useMemo, useEffect, useCallback } from "react";
import _ from "lodash";
import { useQuery, useQueryClient } from "@tanstack/react-query";

import { defaultTahkCsgState } from "../constants";
import { DataSet, Group, Project, isDataSet } from "@/model";
import { postInitializeTahkCsg, TahkCsgDataviewResponse, TahkCsgStateResponse } from "@/models/tahk";
import { ModuleIdentity } from "@/models/Generic";
import { CsvData } from "@/features/app/app.types";

export type TahkCsgCalculation = {
  dataview?: TahkCsgDataviewResponse;
};

type TahkCsgState = {
  tabIndex: number;
  onChangeTab: (index: number) => void;
  dataSets: string[];
  isLoading: boolean;
  tahkCsgState?: TahkCsgStateResponse | null;
  setTahkCsgState: React.Dispatch<React.SetStateAction<TahkCsgStateResponse | null | undefined>>;
  analysisIdentity?: ModuleIdentity;
  setApiError: (error: { message: string }) => void;
  setCsvData: (csvData?: CsvData[]) => void;
  tahkCsgCalculation?: TahkCsgCalculation | null;
  setTahkCsgCalculation: React.Dispatch<React.SetStateAction<TahkCsgCalculation | null | undefined>>;
};

type TahkCsgProviderProps = {
  children: React.ReactNode;
  selectedDataSets: DataSet | DataSet[] | undefined;
  isLoading: boolean;
  group?: Group;
  project?: Project;
  setApiError: (error: { message: string }) => void;
  setCsvData: (csvData?: CsvData[]) => void;
};

const TahkCsgContext = createContext<TahkCsgState>(defaultTahkCsgState);

const TahkCsgProvider = ({ setCsvData, children, selectedDataSets, isLoading, group, project, setApiError }: Readonly<TahkCsgProviderProps>) => {
  const [tabIndex, setTabIndex] = useState(0);

  const [tahkCsgState, setTahkCsgState] = useState<TahkCsgStateResponse | null>();
  const [tahkCsgCalculation, setTahkCsgCalculation] = useState<TahkCsgCalculation | null>();
  const [latestDataSets, setLatestDataSets] = useState<string[]>([]);

  const dataSets = useMemo(() => {
    if (isDataSet(selectedDataSets)) return [selectedDataSets.id];
    return selectedDataSets?.map((dataSet) => dataSet.id) ?? [];
  }, [selectedDataSets]);

  const analysisIdentity = useMemo(() => {
    return {
      data_set_ids: dataSets,
      group_id: group?.id ?? "",
      project_id: project?.id ?? "",
    };
  }, [dataSets, group?.id, project?.id]);

  const client = useQueryClient();

  const { isLoading: isLoadingInitialize, isFetching } = useQuery({
    queryKey: ["initialize-tahk-csg", dataSets, analysisIdentity],
    queryFn: async () => {
      return postInitializeTahkCsg(analysisIdentity);
    },
    select(data) {
      if (data?.data && !tahkCsgState) {
        setTahkCsgState(data.data);
        setLatestDataSets(dataSets);
      }
    },
    refetchOnWindowFocus: false,
    enabled: dataSets && dataSets.length > 0 && !!analysisIdentity.group_id && !!analysisIdentity.project_id,
  });

  useEffect(() => {
    if (latestDataSets.length > 0 && _.isEqual(latestDataSets, dataSets)) {
      setTahkCsgState(null);
      client?.invalidateQueries();
    }
  }, [client, dataSets, latestDataSets]);

  const onChangeTab = useCallback(
    (index: number) => {
      setTabIndex(index);
      setCsvData();
    },
    [setCsvData]
  );

  const state = useMemo(() => {
    return {
      tabIndex,
      onChangeTab,
      dataSets,
      isLoading: isLoading || isLoadingInitialize || isFetching,
      tahkCsgState,
      setTahkCsgState,
      analysisIdentity,
      setApiError,
      setCsvData,
      tahkCsgCalculation,
      setTahkCsgCalculation,
    };
  }, [
    tabIndex,
    onChangeTab,
    dataSets,
    isLoading,
    isLoadingInitialize,
    isFetching,
    tahkCsgState,
    analysisIdentity,
    setApiError,
    setCsvData,
    tahkCsgCalculation,
  ]);

  return <TahkCsgContext.Provider value={state}>{children}</TahkCsgContext.Provider>;
};

export function useTahkCsgState() {
  return useContext(TahkCsgContext);
}

export default TahkCsgProvider;
