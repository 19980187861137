import { calculateHeight } from "@/utils/cellRendering";
import useGeomechanis, { TahkCsgGeomechanicsProps } from "../../../hooks/input/charts/useGeomechanis";
import CustomTable from "@/components/CustomTable";

const Geomechanics = (props: TahkCsgGeomechanicsProps) => {
  const { columns, rows, onCellsChanged } = useGeomechanis(props);

  return (
    <div style={{ display: "flex", height: calculateHeight(rows) + 160 }}>
      <div style={{ marginTop: 20, flexDirection: "column", width: "100%", position: "relative" }} className="center-flex">
        <CustomTable
          style={{
            display: "flex",
            width: "100%",
            position: "absolute",
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            overflowX: "scroll",
            overflowY: "scroll",
            height: calculateHeight(rows) + 150,
          }}
          columns={columns}
          rows={rows}
          isLoading={props.loadingState}
          enableRangeSelection
          enableColumnSelection
          stickyTopRows={2}
          onCellsChanged={onCellsChanged}
        />
      </div>
    </div>
  );
};

export default Geomechanics;
