import { fetchApi } from "@/utils/apiFetcher";
import { saveProjectSetting } from "@/constants/apiUrl";
import { SaveProjectSettingPayload } from "../State";

export const postSaveProjectSetting = (payload: SaveProjectSettingPayload) => {
  return fetchApi<string, SaveProjectSettingPayload>({
    path: saveProjectSetting,
    type: "post",
    body: payload,
  });
};
