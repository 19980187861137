import { useCallback, useMemo } from "react";
import { useTheme } from "@mui/material/styles";

import { FossilyticsChartLine, FossilyticsChartSeries } from "@/components/FossilyticsChart";
import { SpadDeclineCoordinate, SpadDeclineState } from "@/models/spad/decline";
import { ModuleId } from "@/model";
import { getFlowLineSeries, getRatesCumLineSeries } from "../../index.utils";

type UseSpadDeclineChartsProps = {
  currentModule?: ModuleId;
  spadDeclineState?: SpadDeclineState;
  onChartDrag: (handleBar: SpadDeclineCoordinate) => void;
  setSpadDeclineState: React.Dispatch<React.SetStateAction<SpadDeclineState | undefined>>;
  compareAnalysis?: SpadDeclineState;
  compareName?: string;
};

const useSpadDeclineCharts = ({
  onChartDrag,
  currentModule,
  spadDeclineState,
  setSpadDeclineState,
  compareAnalysis,
  compareName,
}: UseSpadDeclineChartsProps) => {
  const { palette } = useTheme();
  const unit = currentModule === ModuleId.SPAD_DECLINE_GAS ? "MMscf" : "bbl";

  const forecastAnalysis = spadDeclineState?.analysis_result;
  const dataStartDay = spadDeclineState?.analysis_option?.analysis_start_day;

  const cartesianChart = spadDeclineState?.analysed_data.cartesian_chart;

  const forecastStartDate = spadDeclineState?.forecast_start_day;
  const forecastEndDate = spadDeclineState?.forecast_end_day;

  const getScatterItemColor = useCallback(
    (params: any) => {
      let itemColor;
      const forecastStartDay = forecastStartDate;

      if ((forecastStartDay && params.data[0] > forecastStartDay) || (dataStartDay && params.data[0] < dataStartDay)) {
        itemColor = palette.grey[600];
      } else {
        itemColor = currentModule === ModuleId.SPAD_DECLINE_GAS ? palette.error.main : palette.success.main;
      }

      return itemColor;
    },
    [forecastStartDate, dataStartDay, palette.grey, palette.error.main, palette.success.main, currentModule]
  );

  const getSecondaryScatterItemColor = useCallback(
    (params: any) => {
      const forecastStartDay = forecastStartDate;

      const indexForInitOutputDays = forecastStartDay && cartesianChart?.days?.findIndex((value) => value > Number(forecastStartDay));

      const valueForLogCummProds = indexForInitOutputDays && cartesianChart?.cumulative_production?.[indexForInitOutputDays];

      let itemColor;

      if ((valueForLogCummProds && forecastStartDay && params.data[0] > valueForLogCummProds) || (dataStartDay && params.data[0] < dataStartDay)) {
        itemColor = palette.customColor.neutralSecondary;
      } else {
        itemColor = currentModule === ModuleId.SPAD_DECLINE_GAS ? palette.customColor.red : palette.customColor.green;
      }

      return itemColor;
    },
    [
      forecastStartDate,
      cartesianChart?.days,
      cartesianChart?.cumulative_production,
      dataStartDay,
      palette.customColor.neutralSecondary,
      palette.customColor.red,
      palette.customColor.green,
      currentModule,
    ]
  );

  const maxDays = useMemo(() => {
    if (!spadDeclineState?.analysis_result) return 0;
    return Math.max(
      spadDeclineState?.analysis_result.operational_result.high.chart_profiles.days[
        spadDeclineState?.analysis_result.operational_result.high.chart_profiles.days.length - 1
      ],
      spadDeclineState?.analysis_result.profile_result.high.chart_profiles.days[
        spadDeclineState?.analysis_result.profile_result.high.chart_profiles.days.length - 1
      ]
    );
  }, [spadDeclineState?.analysis_result]);

  const flowSeries = useMemo<FossilyticsChartSeries[]>(() => {
    if (!cartesianChart || !forecastAnalysis) return [];
    let flowRatesData: (string | number)[][] = cartesianChart.days.map((d, i) => [d, cartesianChart.rates[i]]);

    const maxDayCompare = compareAnalysis?.analysis_result
      ? Math.max(
          compareAnalysis?.analysis_result.operational_result.high.chart_profiles.days[
            compareAnalysis?.analysis_result.operational_result.high.chart_profiles.days.length - 1
          ],
          compareAnalysis?.analysis_result.profile_result.high.chart_profiles.days[
            compareAnalysis?.analysis_result.profile_result.high.chart_profiles.days.length - 1
          ]
        )
      : null;
    return [
      {
        name: "Flow rates",
        type: "scatter",
        color: getScatterItemColor,
        z: 0,
        data: flowRatesData,
      },
      {
        ...getFlowLineSeries(forecastAnalysis?.operational_result.low.chart_profiles),
        id: "lc",
        name: "OPS Low Case",
        color: palette.customColor.purpleLight,
        defaultDisabled: true,
      },
      {
        ...getFlowLineSeries(forecastAnalysis.operational_result.mid.chart_profiles),
        id: "mc",
        name: "OPS Mid Case",
        color: palette.customColor.purple,
        defaultDisabled: true,
      },
      {
        ...getFlowLineSeries(forecastAnalysis.operational_result.high.chart_profiles),
        id: "hc",
        name: "OPS High Case",
        color: palette.customColor.purpleDark,
        defaultDisabled: true,
      },
      {
        ...getFlowLineSeries(forecastAnalysis.profile_result.low.chart_profiles),
        id: "lcl",
        name: "Profile Low Case",
        color: palette.customColor.themePrimary,
      },
      {
        ...getFlowLineSeries(forecastAnalysis.profile_result.mid.chart_profiles),
        id: "mcl",
        name: "Profile Mid Case",
        color: palette.customColor.blue,
      },
      {
        ...getFlowLineSeries(forecastAnalysis.profile_result.high.chart_profiles),
        id: "hcl",
        name: "Profile High Case",
        color: palette.customColor.yellow,
      },
      {
        name: "Economic cutoff",
        type: "line",
        lineType: "dashed",
        hideSymbol: true,
        color: palette.customColor.red,
        data: [
          [0, spadDeclineState?.analysis_option.economic_cutoff],
          [maxDays, spadDeclineState?.analysis_option.economic_cutoff],
        ],
      },
      ...(compareName && compareAnalysis?.analysis_result
        ? [
            {
              ...getFlowLineSeries(compareAnalysis.analysis_result.operational_result.low.chart_profiles),
              name: `${compareName} OPS Low Case`,
              lineWidth: 1,
              color: palette.customColor.neutralDark,
              defaultDisabled: true,
            },
            {
              ...getFlowLineSeries(compareAnalysis.analysis_result.operational_result.mid.chart_profiles),
              name: `${compareName} OPS Mid Case`,
              lineWidth: 1,
              color: palette.customColor.neutralDark,
              defaultDisabled: true,
            },
            {
              ...getFlowLineSeries(compareAnalysis.analysis_result.operational_result.high.chart_profiles),
              name: `${compareName} OPS High Case`,
              lineWidth: 1,
              color: palette.customColor.neutralDark,
              defaultDisabled: true,
            },
            {
              ...getFlowLineSeries(compareAnalysis.analysis_result.profile_result.low.chart_profiles),
              name: `${compareName} Profile Low Case`,
              lineWidth: 1,
              color: palette.customColor.blueLight,
            },
            {
              ...getFlowLineSeries(compareAnalysis.analysis_result.profile_result.mid.chart_profiles),
              name: `${compareName} Profile Mid Case`,
              lineWidth: 1,
              color: palette.customColor.blueLight,
            },
            {
              ...getFlowLineSeries(compareAnalysis.analysis_result.profile_result.high.chart_profiles),
              name: `${compareName} Profile High Case`,
              lineWidth: 1,
              color: palette.customColor.blueLight,
            },
            {
              name: `${compareName} Economic cutoff`,
              type: "line",
              lineType: "dashed",
              hideSymbol: true,
              color: palette.customColor.neutralDark,
              data: maxDayCompare
                ? [
                    [0, compareAnalysis?.analysis_option?.economic_cutoff],
                    [maxDayCompare, compareAnalysis?.analysis_option?.economic_cutoff],
                  ]
                : [],
            },
          ]
        : []),
    ];
  }, [
    cartesianChart,
    compareAnalysis?.analysis_option?.economic_cutoff,
    compareAnalysis?.analysis_result,
    compareName,
    forecastAnalysis,
    getScatterItemColor,
    maxDays,
    palette.customColor.blue,
    palette.customColor.neutralDark,
    palette.customColor.blueLight,
    palette.customColor.purple,
    palette.customColor.purpleDark,
    palette.customColor.purpleLight,
    palette.customColor.red,
    palette.customColor.themePrimary,
    palette.customColor.yellow,
    spadDeclineState?.analysis_option.economic_cutoff,
  ]);

  const logFlowSeries = useMemo<FossilyticsChartSeries[]>(() => {
    if (!spadDeclineState?.analysed_data.log_chart) return [];

    let data: (string | number)[][] = spadDeclineState?.analysed_data.log_chart.days.map((d, i) => [
      d,
      spadDeclineState?.analysed_data.log_chart.rates[i],
    ]);

    return [
      {
        name: "Flow rates",
        type: "scatter",
        color: getScatterItemColor,
        z: -1,
        data,
      },
      ...flowSeries.filter((s) => s.name !== "Flow rates"),
    ];
  }, [flowSeries, getScatterItemColor, spadDeclineState?.analysed_data.log_chart]);

  const ratesCumSeries = useMemo<FossilyticsChartSeries[]>(() => {
    if (!spadDeclineState?.analysis_result) return [];
    const profileHighForecast = spadDeclineState?.analysis_result?.profile_result.high.chart_profiles;
    const compareProfileHighForecast = compareAnalysis ? compareAnalysis?.analysis_result?.profile_result.high.chart_profiles : null;
    return [
      {
        name: "Flow rates",
        type: "scatter",
        color: getSecondaryScatterItemColor,
        data: cartesianChart ? cartesianChart.days.map((_, i) => [cartesianChart.cumulative_production[i], cartesianChart.rates[i]]) : [],
        z: -1,
      },
      {
        ...getRatesCumLineSeries(forecastAnalysis?.operational_result.low.chart_profiles),
        id: "lc",
        name: "OPS Low Case",
        color: palette.customColor.purpleLight,
        defaultDisabled: true,
      },
      {
        ...getRatesCumLineSeries(forecastAnalysis?.operational_result.mid.chart_profiles),
        id: "mc",
        name: "OPS Mid Case",
        color: palette.customColor.purple,
        defaultDisabled: true,
      },
      {
        ...getRatesCumLineSeries(forecastAnalysis?.operational_result.high.chart_profiles),
        id: "hc",
        name: "OPS High Case",
        color: palette.customColor.purpleDark,
        defaultDisabled: true,
      },
      {
        ...getRatesCumLineSeries(forecastAnalysis?.profile_result.low.chart_profiles),
        id: "lcl",
        name: "Profile Low Case",
        color: palette.customColor.themePrimary,
        z: 100,
      },
      {
        ...getRatesCumLineSeries(forecastAnalysis?.profile_result.mid.chart_profiles),
        id: "mcl",
        name: "Profile Mid Case",
        color: palette.customColor.blue,
        z: 100,
      },
      {
        ...getRatesCumLineSeries(forecastAnalysis?.profile_result.high.chart_profiles),
        id: "hcl",
        name: "Profile High Case",
        color: palette.customColor.yellow,
        z: 100,
      },
      {
        name: "Economic cutoff",
        type: "line",
        lineType: "dashed",
        hideSymbol: true,
        color: palette.customColor.red,
        z: 1,
        data: profileHighForecast
          ? [
              [0, spadDeclineState?.analysis_option.economic_cutoff],
              [profileHighForecast.days[profileHighForecast.days.length - 1], spadDeclineState?.analysis_option.economic_cutoff],
            ]
          : [],
      },
      ...(compareName && compareAnalysis?.analysis_result
        ? [
            {
              ...getRatesCumLineSeries(compareAnalysis.analysis_result.operational_result.low.chart_profiles),
              name: `${compareName} OPS Low Case`,
              lineWidth: 1,
              color: palette.customColor.neutralDark,
              defaultDisabled: true,
            },
            {
              ...getRatesCumLineSeries(compareAnalysis.analysis_result.operational_result.mid.chart_profiles),
              name: `${compareName} OPS Mid Case`,
              lineWidth: 1,
              color: palette.customColor.neutralDark,
              defaultDisabled: true,
            },
            {
              ...getRatesCumLineSeries(compareAnalysis.analysis_result.operational_result.high.chart_profiles),
              name: `${compareName} OPS High Case`,
              lineWidth: 1,
              color: palette.customColor.neutralDark,
              defaultDisabled: true,
            },
            {
              ...getRatesCumLineSeries(compareAnalysis.analysis_result.profile_result.low.chart_profiles),
              name: `${compareName} Profile Low Case`,
              lineWidth: 1,
              color: palette.customColor.blueLight,
            },
            {
              ...getRatesCumLineSeries(compareAnalysis.analysis_result.profile_result.mid.chart_profiles),
              name: `${compareName} Profile Mid Case`,
              lineWidth: 1,
              color: palette.customColor.blueLight,
            },
            {
              ...getRatesCumLineSeries(compareAnalysis.analysis_result.profile_result.high.chart_profiles),
              name: `${compareName} Profile High Case`,
              lineWidth: 1,
              color: palette.customColor.blueLight,
            },
            {
              name: `${compareName} Economic cutoff`,
              type: "line",
              lineType: "dashed",
              hideSymbol: true,
              color: palette.customColor.neutralDark,
              z: 0,
              data: compareProfileHighForecast
                ? [
                    [0, compareAnalysis?.analysis_option?.economic_cutoff],
                    [compareProfileHighForecast.days[compareProfileHighForecast.days.length - 1], compareAnalysis?.analysis_option?.economic_cutoff],
                  ]
                : [],
            },
          ]
        : []),
    ];
  }, [
    cartesianChart,
    compareAnalysis,
    compareName,
    forecastAnalysis?.operational_result.high.chart_profiles,
    forecastAnalysis?.operational_result.low.chart_profiles,
    forecastAnalysis?.operational_result.mid.chart_profiles,
    forecastAnalysis?.profile_result.high.chart_profiles,
    forecastAnalysis?.profile_result.low.chart_profiles,
    forecastAnalysis?.profile_result.mid.chart_profiles,
    getSecondaryScatterItemColor,
    palette.customColor.blue,
    palette.customColor.neutralDark,
    palette.customColor.blueLight,
    palette.customColor.purple,
    palette.customColor.purpleDark,
    palette.customColor.purpleLight,
    palette.customColor.red,
    palette.customColor.themePrimary,
    palette.customColor.yellow,
    spadDeclineState?.analysis_option.economic_cutoff,
    spadDeclineState?.analysis_result,
  ]);

  const forecastStartLine = useMemo<FossilyticsChartLine | undefined>(() => {
    if (forecastStartDate === undefined) return undefined;
    return {
      id: "forecastStartDay",
      name: "Forecast start",
      type: "vertical",
      xValue: forecastStartDate,
      lineType: "dashed",
      lineWidth: 1.5,
      color: palette.customColor.black,
      controllable: false,
    };
  }, [forecastStartDate, palette.customColor.black]);

  const forecastEndLine = useMemo<FossilyticsChartLine | undefined>(() => {
    if (!forecastEndDate) return undefined;
    return {
      id: "forecastEndDay",
      name: "Forecast end",
      type: "vertical",
      xValue: forecastEndDate,
      lineType: "dashed",
      lineWidth: 1.5,
      color: palette.customColor.black,
      controllable: false,
    };
  }, [forecastEndDate, palette.customColor.black]);

  const dataStartLine = useMemo<FossilyticsChartLine | undefined>(() => {
    if (dataStartDay === undefined) return undefined;
    const flowRates = logFlowSeries.find((f) => f.name === "Flow rates");
    const fifthFromEnd = flowRates?.data[flowRates?.data.length - 50];
    if (dataStartDay === undefined || fifthFromEnd === undefined) return undefined;

    const forecastStartDay = forecastStartDate;
    return {
      id: "dataStartDay",
      name: "Data start",
      type: "vertical",
      xValue: dataStartDay,
      lineType: "dashed",
      lineWidth: 1.5,
      color: palette.customColor.black,
      xConstraintValue: [0, forecastStartDay],
    };
  }, [dataStartDay, forecastStartDate, logFlowSeries, palette.customColor.black]);

  const lineBaseColor = useMemo(() => {
    return [
      {
        id: "operational,low",
        name: "",
        color: "#fff0",
        handleColor: palette.customColor.purpleLight,
        line: [],
        linked: "OPS Low Case",
      },
      {
        id: "operational,mid",
        name: "",
        color: "#fff0",
        handleColor: palette.customColor.purple,
        line: [],
        linked: "OPS Mid Case",
      },
      {
        id: "operational,high",
        name: "",
        color: "#fff0",
        handleColor: palette.customColor.purpleDark,
        line: [],
        linked: "OPS High Case",
      },
      {
        id: "profile,low",
        name: "",
        color: "#fff0",
        handleColor: palette.customColor.themePrimary,
        line: [],
        linked: "Profile Low Case",
      },
      {
        id: "profile,mid",
        name: "",
        color: "#fff0",
        handleColor: palette.customColor.blue,
        line: [],
        linked: "Profile Mid Case",
      },
      {
        id: "profile,high",
        name: "",
        color: "#fff0",
        handleColor: palette.customColor.yellow,
        line: [],
        linked: "Profile High Case",
      },
    ];
  }, [
    palette.customColor.blue,
    palette.customColor.purple,
    palette.customColor.purpleDark,
    palette.customColor.purpleLight,
    palette.customColor.themePrimary,
    palette.customColor.yellow,
  ]);

  const dateLineHandleBars = useMemo(() => {
    const lines = [];
    if (forecastStartLine) lines.push(forecastStartLine);
    if (forecastEndLine) lines.push(forecastEndLine);
    if (dataStartLine) lines.push(dataStartLine);

    return lines;
  }, [dataStartLine, forecastEndLine, forecastStartLine]);

  const parseHandleBarWithColor = useCallback(
    (handleBar?: SpadDeclineCoordinate[]) => {
      if (!handleBar) return [];
      return [
        ...handleBar.reduce((res, handle) => {
          const lineBaseId = handle.identity.split(",").slice(1).join(",");
          const baseColor = lineBaseColor.filter((base) => base.id === lineBaseId)[0];
          if (baseColor) {
            res.push({
              ...baseColor,
              line: [
                [handle.coordinates[0].x, handle.coordinates[0].y],
                [handle.coordinates[1].x, handle.coordinates[1].y],
              ],
              key: handle.identity,
            });
          }
          return res;
        }, [] as FossilyticsChartLine[]),
      ];
    },
    [lineBaseColor]
  );

  const rateCumProdHandleBar = useMemo(() => {
    return parseHandleBarWithColor(spadDeclineState?.chart_handlebars?.rate_cumprod);
  }, [parseHandleBarWithColor, spadDeclineState?.chart_handlebars?.rate_cumprod]);

  const rateTimeHandleBar = useMemo(() => {
    return [...parseHandleBarWithColor(spadDeclineState?.chart_handlebars?.rate_time), ...dateLineHandleBars];
  }, [dateLineHandleBars, parseHandleBarWithColor, spadDeclineState?.chart_handlebars?.rate_time]);

  const updateLineFromChart = useCallback(
    (l: number[][] | [string, number][], _i: number, _isEnd?: boolean, line?: FossilyticsChartLine) => {
      if (line?.id === "dataStartDay") {
        setSpadDeclineState((prev) => {
          if (!prev) return prev;
          return {
            ...prev,
            analysis_option: {
              ...prev.analysis_option,
              analysis_start_day: Math.round(Number(l[0][0])),
            },
          };
        });
      } else {
        onChartDrag({
          coordinates: [
            {
              x: Number(l[0][0]),
              y: Number(l[0][1]),
            },
            {
              x: Number(l[1][0]),
              y: Number(l[1][1]),
            },
          ],
          identity: line?.key ?? "",
        });
      }
    },
    [onChartDrag, setSpadDeclineState]
  );

  const draggable = spadDeclineState?.analysis_option.auto_update_cluster_start_day;

  return {
    logFlowSeries,
    ratesCumSeries,
    updateLineFromChart,
    unit,
    draggable,
    getSecondaryScatterItemColor,
    flowSeries,
    getScatterItemColor,
    forecastStartLine,
    rateCumProdHandleBar,
    rateTimeHandleBar,
    dateLineHandleBars,
  };
};

export default useSpadDeclineCharts;
