import { createContext, useContext, useState, useMemo, useCallback, useEffect, ReactNode } from "react";
import { shallow } from "zustand/shallow";
import _ from "lodash";

import { DataSet, Project } from "@/model";
import { SettingStateV2, getWellsSettings } from "@/models/settings";

import { useAppStore } from "@/features/app";
import { treeViewDefaultState } from "../constants";

import { useSettings } from "@/SettingsState";

import { IItem, TreeViewStateV2 } from "../types";

// hooks
import useDialogSection from "./useDialogSectionV2";
import useWellFilter from "./useWellFilter";
import useProjectNav from "./useProjectNav";
import { getIdFromActiveKey } from "../utils";
import { useNavigate } from "react-router-dom";

const TreeViewContext = createContext<TreeViewStateV2>(treeViewDefaultState);

type TreeViewContextProps = {
  children: ReactNode;
};

const TreeViewProvider = ({ children }: Readonly<TreeViewContextProps>) => {
  const {
    isLoading,
    getRequest,
    projects,
    setProjects,
    setProject,
    setGroup,
    dataSets,
    setDataSets,
    setSelectedDataSets,
    currentUserTask,
    setSelectedKey,
    selectedKey,
    user,
    group,
    currentModule,
    deleteRequest,
  } = useAppStore(
    (state) => ({
      isLoading: state.isLoading,
      getRequest: state.getRequest,
      projects: state.projects,
      setProjects: state.setProjects,
      setProject: state.setProject,
      setGroup: state.setGroup,
      dataSets: state.dataSets,
      setDataSets: state.setDataSets,
      setSelectedDataSets: state.setSelectedDataSets,
      currentUserTask: state.currentUserTask,
      setSelectedKey: state.setSelectedKey,
      selectedKey: state.selectedKey,
      user: state.user,
      group: state.group,
      deleteRequest: state.deleteRequest,
      currentModule: state.currentModule,
    }),
    shallow
  );

  const { setSettings } = useSettings();

  const rootId = "tree-view-context";

  const [isDataSetsLoading, setIsDataSetsLoading] = useState(true);
  const [dataSetItems, setDataSetItems] = useState<IItem[]>([]);
  const [isProjectEmpty, setIsProjectEmpty] = useState(false);

  const [errorWarning, setErrorWarning] = useState<string | undefined>();
  const { clearFilters, filter, setFilter, filterButtonRef, showFilters, setShowFilters, filterTypes, setFilterTypes } = useWellFilter();

  // Load data sets and projects from API
  const refreshProjects = useCallback(
    async (delayStateChange: boolean = false) => {
      if (!user) return;
      try {
        const projectsResponse: Project[] = await getRequest("/projects");
        if (delayStateChange) return projectsResponse;
        setProjects(projectsResponse);

        const response = await getWellsSettings();
        const saveProject: SettingStateV2 = {};

        if (response.data) {
          Object.keys(_.cloneDeep(response.data)).forEach((projectId) => {
            if (response.data?.[projectId]) {
              const currProject = _.cloneDeep(response.data[projectId]);
              saveProject[projectId] = {
                ...currProject,
                project_setting: currProject.project_setting ?? {
                  forecast_end_date: "",
                  forecast_start_date: "",
                },
              };
            }
          });
          setSettings(saveProject);
        }
      } catch (_) {
        // no-op
      }
    },
    [getRequest, setProjects, setSettings, user]
  );

  const refreshDataSets = useCallback(
    async (delayStateChange: boolean = false) => {
      if (!user) return;

      setIsDataSetsLoading(true);
      try {
        const dataSetsResponse: DataSet[] = await getRequest("/loaders/get_data_sets");
        if (delayStateChange) return dataSetsResponse;
        setDataSets(dataSetsResponse);
      } catch (_) {
        // no-op
      } finally {
        setIsDataSetsLoading(false);
      }
    },
    [getRequest, setDataSets, user]
  );
  const navigate = useNavigate();

  const {
    hideProjects,
    mappedItemKeyTotal,
    onDragEnd,
    setHideProjects,
    setShowAll,
    showAll,
    selectedItems,
    setActiveKey,
    activeKey,
    selectedDataSet,
    setSelectedDataSet,

    shiftHeld,
    commandControlHeld,

    selectedKeys,
    onSelectItem,
    onDragWellList,
    onDropWell,
    setSelectedKeys,
  } = useProjectNav({
    projects,
    refreshProjects,
    setProject,
    setGroup,
    setSelectedDataSets,
    setSelectedKey,
    dataSets,
    selectedKey,
    navigate,
    setErrorWarning,
    currentModule,
  });

  const {
    activeDialog,
    setActiveDialog,

    copyFromSelected,
    setCopyFromSelected,
    createNewProject,
    editExistingProject,
    deleteProject,
    removeDataSets,
    createNewGroups,
    editExistingGroup,
    newGroupsNames,
    setNewGroupsNames,
  } = useDialogSection({
    refreshProjects,
    activeKey,
    setActiveKey,
  });

  useEffect(() => {
    (async () => {
      let newDataSets, newProjects;
      if (!user) return;
      if (!dataSets) newDataSets = await refreshDataSets(true);
      if (!projects) newProjects = await refreshProjects(true);
      // Update state at the same time to prevent rerender
      if (newDataSets) setDataSets(newDataSets);
      if (newProjects) {
        setProjects(newProjects);
        setIsProjectEmpty(newProjects.length === 0);
      }
      setIsDataSetsLoading(false);
    })();
  }, [dataSets, refreshDataSets, setDataSets, projects, refreshProjects, setProjects, user]);

  useEffect(() => {
    let newItems = dataSets?.map((d) => ({ key: d.id, name: d.name, data: d })) ?? [];
    if (filter) newItems = newItems.filter((i) => i.name.toLowerCase().indexOf(filter.trim().toLowerCase()) >= 0);
    newItems = newItems.filter((i) => filterTypes.indexOf(i.data.type) >= 0);
    setDataSetItems(newItems);
  }, [filter, filterTypes, dataSets]);

  // this hooks is to update local state here if we got update from current user task
  useEffect(() => {
    if (projects && currentUserTask) {
      const mapSelectedKeys = currentUserTask.data_set_ids.map((dataSetId) => {
        return `${dataSetId};${currentUserTask.group_id}`;
      });
      // set selected items according to userTask
      setSelectedKeys(mapSelectedKeys);
    }
  }, [currentUserTask, projects, setSelectedKeys]);

  const deleteGroup = useCallback(async () => {
    // Close the dialog
    setActiveDialog(undefined);

    // Get project and group ID
    if (!activeKey) return;
    const { projectId, groupIds } = getIdFromActiveKey(activeKey);

    const groupId = groupIds[groupIds.length - 1];

    try {
      await deleteRequest(`/projects/${projectId}/groups/${groupId}`);
      await refreshProjects();
    } catch (e) {
      console.error(e);
    }
  }, [activeKey, deleteRequest, refreshProjects, setActiveDialog]);

  const state = useMemo(() => {
    return {
      isLoading,
      selectedKey,
      rootId,
      showAll,
      setShowAll,
      setHideProjects,

      setShowFilters,
      showFilters,
      filterButtonRef,
      filterTypes,
      setFilterTypes,
      clearFilters,
      filter,
      setFilter,
      isDataSetsLoading,
      dataSetItems,
      projects,
      onDragWellList,

      // project nav:
      hideProjects,
      mappedItemKeyTotal,
      onDragEnd,
      selectedItems,
      setActiveKey,
      isProjectEmpty,

      // dialog props
      copyFromSelected,
      setCopyFromSelected,

      createNewProject,
      deleteProject,
      editExistingProject,

      createNewGroups,
      editExistingGroup,
      setNewGroupsNames,
      newGroupsNames,
      refreshProjects,
      removeDataSets,

      activeDialog,
      setActiveDialog,
      selectedGroup: group,
      deleteGroup,
      selectedDataSet,
      setSelectedDataSet,
      refreshDataSets,
      dataSets,
      shiftHeld,
      commandControlHeld,
      errorWarning,

      selectedKeys,
      onSelectItem,
      onDropWell,
    };
  }, [
    onDragWellList,
    onDropWell,
    group,
    isLoading,
    selectedKey,
    showAll,
    setShowAll,
    setHideProjects,
    setShowFilters,
    showFilters,
    filterButtonRef,
    filterTypes,
    setFilterTypes,
    clearFilters,
    filter,
    setFilter,
    isProjectEmpty,
    isDataSetsLoading,
    dataSetItems,
    projects,
    hideProjects,
    mappedItemKeyTotal,
    onDragEnd,
    selectedItems,
    copyFromSelected,
    setCopyFromSelected,
    createNewProject,
    deleteProject,
    editExistingProject,
    createNewGroups,
    editExistingGroup,
    setNewGroupsNames,
    newGroupsNames,
    refreshProjects,
    removeDataSets,
    activeDialog,
    setActiveDialog,
    setActiveKey,
    deleteGroup,
    selectedDataSet,
    setSelectedDataSet,
    refreshDataSets,
    dataSets,
    shiftHeld,
    commandControlHeld,
    errorWarning,
    selectedKeys,
    onSelectItem,
  ]);

  return <TreeViewContext.Provider value={state}>{children}</TreeViewContext.Provider>;
};

export function useTreeViewState() {
  return useContext(TreeViewContext);
}

export default TreeViewProvider;
