import { useMemo } from "react";
import { useTheme } from "@fluentui/react";

import FossilyticsChart, { FossilyticsChartAxis, FossilyticsChartCustomDataZoom, FossilyticsChartSeries } from "@/components/FossilyticsChart";
import { SpadAnalysisOutput } from "@/features/modules/spad/decline";
import { DataSet, ModuleId } from "@/model";
import { useAppStore } from "@/features/app";

interface LogQqPlotsProps {
  isLoading: boolean;
  dataSets: DataSet[];
  unit: string;
  initOutput?: SpadAnalysisOutput;
  ratesCumSeries: FossilyticsChartSeries[];
  getSecondaryScatterItemColor: (params: any) => string;
}

export const LogQqPlots = ({ isLoading, dataSets, unit, initOutput, ratesCumSeries, getSecondaryScatterItemColor }: LogQqPlotsProps) => {
  const theme = useTheme();

  const currentModule = useAppStore((state) => state.currentModule);

  const ratesCumXAxes = useMemo<FossilyticsChartAxis[]>(
    () => [{ name: `Cum. production (${unit})`, type: "value", color: theme.palette.black }],
    [theme.palette.black, unit]
  );

  const ratesCumYAxesLog = useMemo<FossilyticsChartAxis[]>(
    () => [{ name: `Rates (${unit}/d)`, type: "log", color: theme.palette.black }],
    [theme.palette.black, unit]
  );

  const logRatesCumSeries = useMemo<FossilyticsChartSeries[]>(
    () => [
      {
        name: "Flow rates",
        type: "scatter",
        color: getSecondaryScatterItemColor,
        data: initOutput ? initOutput.logCumProds.map((c, i) => [c, initOutput.logRates[i]]) : [],
      },
      ...ratesCumSeries.filter((s) => s.name !== "Flow rates"),
    ],
    [getSecondaryScatterItemColor, initOutput, ratesCumSeries]
  );

  const findLastValueInLogRatesCumSeriesForQg = useMemo(() => {
    const newLine = ratesCumSeries?.filter((line) => line.name === "Economic cutoff")[0].data;
    const rateCumSeriesLastPoint = ratesCumSeries?.filter((seriesItem) => seriesItem.name === "Flow rates")[0].data;

    let percentage = 100;

    if (newLine && newLine.length > 0 && rateCumSeriesLastPoint.length > 0) {
      const lastNumberInData = newLine[newLine.length - 1][0] as number;
      const flowRatesLastPoint = rateCumSeriesLastPoint[rateCumSeriesLastPoint.length - 1][0];

      if ((flowRatesLastPoint * 3) / lastNumberInData >= 1) {
        return;
      }

      if ((flowRatesLastPoint * 3) / lastNumberInData < 1) {
        percentage = Math.floor(((flowRatesLastPoint * 3) / lastNumberInData) * 100);
      }
    }
    return percentage;
  }, [ratesCumSeries]);

  const logQgCustomZoom = useMemo<FossilyticsChartCustomDataZoom>(() => {
    return {
      customDataZoom: true,
      dataZoomPercentage: findLastValueInLogRatesCumSeriesForQg as number,
    };
  }, [findLastValueInLogRatesCumSeriesForQg]);
  const key = `${currentModule}_log_q_t` + dataSets.map((well) => well.id).join(",");
  return (
    <FossilyticsChart
      id="spad_dec_log_q_Q"
      key={key}
      isLoading={isLoading}
      legends={[
        {
          name: "Flow rates",
          color: currentModule === ModuleId.SPAD_DECLINE_GAS ? theme.palette.red : theme.palette.green,
        },
      ]}
      xAxes={ratesCumXAxes}
      yAxes={ratesCumYAxesLog}
      series={logRatesCumSeries}
      customDataZoom={logQgCustomZoom}
    />
  );
};
