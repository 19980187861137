import { SetStateAction, useEffect, useState } from "react";
import { Icon, Stack, Text } from "@fluentui/react";
import { Grid, Tabs, Tab } from "@mui/material";

import Page from "@/Page";
import { ReleaseDetailsList } from "@/features/settings/release-details-list";
import { SpadPreferences } from "@/features/settings/spad-preferences";
import { SettingsTabs } from "@/features/settings";

import styles from "./index.module.css";
import useThemeStyling from "@/utils/useThemeStyling";

export const SettingsPage = ({ buildNumberText }: { buildNumberText: string | null }) => {
  const { theme, elevatedCard } = useThemeStyling();
  const [tabIndex, setTabIndex] = useState(0);

  const handleTabChange = (_event: any, newTabIndex: SetStateAction<number>) => {
    setTabIndex(newTabIndex);
  };

  useEffect(() => {
    const queryTab = window.location.href.split("?");
    if (queryTab?.[1]) {
      setTabIndex(Number(queryTab[1].split("=")[1]));
    }
  }, []);

  return (
    <Page title="Settings">
      <Stack>
        <Tabs className={styles.tabs} value={tabIndex} onChange={handleTabChange} aria-label="icon position tabs example">
          <Tab
            className={`${styles.tab} ${tabIndex === 0 && styles.selectedTab}`}
            icon={<Icon iconName="Info" className={styles.tabIcon} />}
            iconPosition="start"
            label="About"
          />
          <Tab
            className={`${styles.tab} ${tabIndex === 1 && styles.selectedTab}`}
            icon={<Icon iconName="Settings" className={styles.tabIcon} />}
            iconPosition="start"
            label="Preferences"
          />

          <Tab
            className={`${styles.tab} ${tabIndex === 2 && styles.selectedTab}`}
            icon={<Icon iconName="Dictionary" className={styles.tabIcon} />}
            iconPosition="start"
            label="Release Notes"
          />

          <Tab
            className={`${styles.tab} ${tabIndex === 3 && styles.selectedTab}`}
            icon={<Icon iconName="TextDocument" className={styles.tabIcon} />}
            iconPosition="start"
            label="Terms of Service"
          />
        </Tabs>
      </Stack>

      <Stack>
        {tabIndex === SettingsTabs.About && (
          <div
            style={{
              padding: "2em",
            }}
          >
            <div style={elevatedCard}>
              <Text>
                <h2 style={{ margin: 0 }}>AFA - Advanced Flow Analytics</h2>
              </Text>
              <Text>
                Version: {buildNumberText}
                <br />
                Email: support@predico.com.au
                <br />
                <br />
                autoRTA and AFA are Trade Marked and <br /> Copyright 2024 Predico Pty Ltd. All rights reserved.
              </Text>
            </div>
          </div>
        )}
        {tabIndex === SettingsTabs.Preferences && (
          <div
            style={{
              padding: 20,
            }}
          >
            <div style={elevatedCard}>
              <Text styles={{ root: { color: theme.palette.themePrimary, fontSize: "16px" } }}>SPAD</Text>
              <Grid container>
                <Grid item xs={10} sm={5} md={4} lg={3} xl={2}>
                  <SpadPreferences />
                </Grid>
              </Grid>
            </div>
          </div>
        )}

        {tabIndex === SettingsTabs.ReleaseNotes && (
          <div>
            <ReleaseDetailsList />
          </div>
        )}

        {tabIndex === SettingsTabs.TermsOfService && (
          <div>
            <iframe title="Predico Terms" src="https://www.predico.com.au/terms" name="myiFrame" height="800px" width="100%"></iframe>
          </div>
        )}
      </Stack>
    </Page>
  );
};
