import { Column, ReactGrid, Row } from "@silevis/reactgrid";
import LoadingSpinner from "@/components/LoadingSpinner";

export type AutoRtaAnalysisTableProps = {
  columns: Column[];
  rows: Row[];
  isLoading: boolean;
};

const AutoRtaAnalysisTable = ({ columns, rows, isLoading }: AutoRtaAnalysisTableProps) => {
  if (isLoading) return <LoadingSpinner />;

  return (
    <div
      style={{
        position: "absolute",
        top: 20,
        left: 5,
        bottom: 0,
        right: 0,
        overflow: "scroll",
      }}
    >
      <ReactGrid columns={columns} rows={rows} enableRangeSelection enableColumnSelection />
    </div>
  );
};

export default AutoRtaAnalysisTable;
