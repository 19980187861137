import { fetchApi } from "@/utils/apiFetcher";
import { moveGroup } from "@/constants/apiUrl";

type Location = {
  groupId: string | null;
  projectId: string;
};
export const postMoveGroup = (source: Location, destination: Location) => {
  const { groupId, projectId } = source;
  return fetchApi({
    path: moveGroup(projectId, groupId ?? ""),
    type: "post",
    body: {
      project_id: destination.projectId,
      group_id: destination.groupId,
    },
  });
};
