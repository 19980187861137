import { PropsWithChildren, useEffect } from "react";

import { useAppStore } from "./features/app";

interface PageProps {
  title: string;
}

function Page({ title, children }: PropsWithChildren<PageProps>) {
  const setTitle = useAppStore((state) => state.setTitle);

  useEffect(() => setTitle(title), [setTitle, title]);

  return <>{children}</>;
}

export default Page;
