import { getProjectDatasetKey, parseProjectDatasetKey, ProjectDataSet } from "../util";
import { useEffect, useMemo, useState } from "react";
import { shallow } from "zustand/shallow";
import { Dropdown, DropdownMenuItemType, IDropdownOption } from "@fluentui/react";

import { Group } from "../model";
import { useAppStore } from "@/features/app";

const getDataSetIdsInGroups = (groups: Group[]) => {
  const datasets: string[] = [];
  for (let group of groups) {
    if (group.data_set_ids) {
      datasets.push(...group.data_set_ids);
    }
    if (group.groups) {
      datasets.push(...getDataSetIdsInGroups(group.groups));
    }
  }
  return datasets.filter((v, i, self) => self.indexOf(v) === i);
};

interface DataSetDropdownProps {
  setCompareDataSet: (projectDataSet: ProjectDataSet) => void;
  setCompareName: (name: string) => void;
}

export const DataSetDropdown = ({ setCompareDataSet, setCompareName }: DataSetDropdownProps) => {
  const { projects, dataSets, selectedDataSets } = useAppStore(
    (state) => ({
      projects: state.projects,
      dataSets: state.dataSets,
      selectedDataSets: state.selectedDataSets,
    }),
    shallow
  );

  const projectsWithDatasets = useMemo(
    () =>
      projects && dataSets
        ? projects.map((p) => ({
            id: p.id,
            name: p.name,
            datasets: getDataSetIdsInGroups(p.groups).map((id) => ({
              id,
              name: dataSets.find((d) => d.id === id)?.name ?? "Not found",
            })),
          }))
        : [],
    [dataSets, projects]
  );

  const [compareKey, setCompareKey] = useState<string>("none");

  const selectOptions = useMemo<IDropdownOption[]>(
    () => [
      { key: "none", text: "None" },
      ...projectsWithDatasets
        .filter((p) => p.datasets.length > 0)
        .flatMap((p) => [
          { key: p.id, text: p.name, itemType: DropdownMenuItemType.Header },
          ...p.datasets.map((d) => ({ key: getProjectDatasetKey(p.id, d.id.split(",")), text: d.name })),
        ]),
    ],
    [projectsWithDatasets]
  );

  const compareName = useMemo(() => {
    if (compareKey && compareKey !== "none") {
      const info = parseProjectDatasetKey(compareKey);
      const project = projectsWithDatasets.find((p) => p.id === info.projectId);
      const projectName = project?.name;
      const datasetsName = project?.datasets
        .filter((d) => info.dataSetIds.includes(d.id))
        .map((d) => d.name)
        .join(", ");
      return `${projectName} - ${datasetsName}`;
    }
    return "";
  }, [compareKey, projectsWithDatasets]);

  useEffect(() => {
    if (compareKey && compareKey !== "none") {
      setCompareDataSet(parseProjectDatasetKey(compareKey));
    }
  }, [compareKey, setCompareDataSet]);

  useEffect(() => {
    setCompareName(compareName);
  }, [compareName, setCompareName]);

  useEffect(() => {
    setCompareKey("none");
  }, [selectedDataSets]);

  return (
    <Dropdown
      label="Compare analyses"
      options={selectOptions}
      selectedKey={compareKey}
      onChange={(_, option) => setCompareKey(option == null || typeof option.key === "number" ? "none" : option.key)}
    />
  );
};
