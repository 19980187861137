import styled from "@emotion/styled";

export const Container = styled.div`
  margin: 20px;
  overflow: scroll;

  h4 {
    margin: 0px;
  }

  .toggle-btn {
    position: absolute;
    top: -0px;
    right: -15px;
    background-color: white;
  }
  .toggle-btn:hover {
    background-color: white;
  }
`;

export const SummaryCardContainer = styled.div`
  position: relative;
`;

export const UpperSection = styled.div<{
  expanded: boolean;
}>`
  display: grid;
  grid-template-columns: 3fr ${(props) => (props.expanded ? "0" : "1.3fr")};
  grid-gap: 20px;
  margin-bottom: 20px;
`;
