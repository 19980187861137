import { Checkbox, DetailsList, Dropdown, SelectionMode, Stack, Text } from "@fluentui/react";

import FossilyticsNumberField from "@/components/fields/FossilyticsNumberField";
import LoadingIndicator from "@/components/LoadingIndicator";

import useThemeStyling from "@/utils/useThemeStyling";
import { formatNumber } from "@/util";

import { ErrorValidationDetail } from "@/models/ErrorInputValidation";
import { GazAutoRtaAnalysis } from "@/models/gaz/autoRta";
import { SummaryCard } from "@/models/Generic";

import { dualPorosityModelOptions, selectedBoundaryConditionOptions, transientAnalysisModel, dataTableColumns } from "../../constants";

export type AnalysisSettingProps = {
  onChangeInput: (value: any, firstKey: string, secKey?: string, thirdKey?: string, fourthKey?: string) => void;
  analysisInput: GazAutoRtaAnalysis;
  isLoading: boolean;
  errorInputValidation?: ErrorValidationDetail[];
  analysisSummaryCardParameter?: SummaryCard[];
};

const AnalysisSetting = ({
  onChangeInput,
  analysisInput,
  isLoading,
  analysisSummaryCardParameter = [],
  errorInputValidation,
}: AnalysisSettingProps) => {
  const { elevatedCard, theme } = useThemeStyling();

  const disabledInput = analysisInput.smart_fit || isLoading;
  const renderDrainageOption = () => {
    switch (analysisInput.transient_flow_model.section_transient_model_type.selected_transient_model) {
      case "Radial":
        return (
          <FossilyticsNumberField
            label="Drainage Radius"
            suffix={`ft`}
            debounceDelay={800}
            disabled={disabledInput}
            value={analysisInput.transient_flow_model.section_transient_model_type.transient_model_radial.drainage_radius}
            onChange={(v) => {
              onChangeInput(v, "transient_flow_model", "section_transient_model_type", "transient_model_radial", "drainage_radius");
            }}
            errors={errorInputValidation}
          />
        );
      case "Linear":
        return (
          <>
            <FossilyticsNumberField
              label="Drainage Length"
              suffix={`ft`}
              debounceDelay={800}
              disabled={disabledInput}
              value={analysisInput.transient_flow_model.section_transient_model_type.transient_model_linear.drainage_length}
              onChange={(v) => {
                onChangeInput(v, "transient_flow_model", "section_transient_model_type", "transient_model_linear", "drainage_length");
              }}
            />
            <FossilyticsNumberField
              label="Drainage Width"
              suffix={`ft`}
              debounceDelay={800}
              value={analysisInput.transient_flow_model.section_transient_model_type.transient_model_linear.drainage_width}
              disabled={disabledInput}
              onChange={(v) => {
                onChangeInput(v, "transient_flow_model", "section_transient_model_type", "transient_model_linear", "drainage_width");
              }}
            />
          </>
        );

      default:
        return (
          <>
            <FossilyticsNumberField
              label="Drainage Radius"
              suffix={`ft`}
              debounceDelay={1000}
              value={analysisInput.transient_flow_model.section_transient_model_type.transient_model_composite_radial.drainage_radius}
              disabled={disabledInput}
              onChange={(v) => {
                onChangeInput(v, "transient_flow_model", "section_transient_model_type", "transient_model_composite_radial", "drainage_radius");
              }}
            />

            <FossilyticsNumberField
              label="Inner region Radius"
              suffix={`ft`}
              debounceDelay={1000}
              disabled={disabledInput}
              value={analysisInput.transient_flow_model.section_transient_model_type.transient_model_composite_radial.inner_region_radius}
              onChange={(v) => {
                onChangeInput(v, "transient_flow_model", "section_transient_model_type", "transient_model_composite_radial", "inner_region_radius");
              }}
            />

            <FossilyticsNumberField
              label="Inner region Permeability"
              suffix={`ft`}
              debounceDelay={1000}
              disabled={disabledInput}
              value={analysisInput.transient_flow_model.section_transient_model_type.transient_model_composite_radial.inner_region_permeability}
              onChange={(v) => {
                onChangeInput(
                  v,
                  "transient_flow_model",
                  "section_transient_model_type",
                  "transient_model_composite_radial",
                  "inner_region_permeability"
                );
              }}
            />
          </>
        );
    }
  };

  return (
    <Stack tokens={{ childrenGap: 20 }}>
      <Stack style={elevatedCard} grow={1}>
        <Text styles={{ root: { color: theme.palette.themePrimary } }}>Transient Analysis</Text>
        <Checkbox
          label="Smart Fit"
          styles={{ text: { color: theme.palette.themePrimary }, root: { margin: "10px 0" } }}
          checked={analysisInput.smart_fit}
          disabled={isLoading}
          onChange={(_, v) => {
            onChangeInput(v, "smart_fit");
          }}
        />
        <Dropdown
          label="Model"
          options={transientAnalysisModel}
          selectedKey={analysisInput.transient_flow_model.section_transient_model_type.selected_transient_model}
          disabled={disabledInput}
          onChange={(_, v) => {
            onChangeInput(v?.key, "transient_flow_model", "section_transient_model_type", "selected_transient_model");
          }}
        />

        {renderDrainageOption()}

        <Dropdown
          label="Boundary Condition"
          options={selectedBoundaryConditionOptions}
          selectedKey={analysisInput.transient_flow_model.selected_boundary_condition}
          disabled={disabledInput}
          onChange={(_, v) => {
            onChangeInput(v?.key, "transient_flow_model", "selected_boundary_condition");
          }}
        />

        <FossilyticsNumberField
          label="Skin"
          suffix="Dim"
          debounceDelay={1000}
          disabled={disabledInput}
          value={analysisInput.transient_flow_model.skin}
          onChange={(v) => {
            onChangeInput(v, "transient_flow_model", "skin");
          }}
        />

        <FossilyticsNumberField
          label="Permeability"
          suffix="mD"
          debounceDelay={1000}
          value={analysisInput.transient_flow_model.permeability}
          disabled={disabledInput}
          onChange={(v) => {
            onChangeInput(v, "transient_flow_model", "permeability");
          }}
        />

        <Dropdown
          label="Dual Porosity Model"
          options={dualPorosityModelOptions}
          selectedKey={analysisInput.transient_flow_model.section_dual_porosity.selected_dual_porosity_model}
          disabled={disabledInput}
          onChange={(_, v) => {
            onChangeInput(v?.key, "transient_flow_model", "section_dual_porosity", "selected_dual_porosity_model");
          }}
        />
        {analysisInput.transient_flow_model.section_dual_porosity.selected_dual_porosity_model !== "NoModel" && (
          <>
            <FossilyticsNumberField
              label="Storage Capacity Ratio"
              suffix="dim"
              debounceDelay={1000}
              disabled={disabledInput}
              value={analysisInput.transient_flow_model.section_dual_porosity.storage_capacity_ratio}
              onChange={(v) => {
                onChangeInput(v, "transient_flow_model", "section_dual_porosity", "storage_capacity_ratio");
              }}
            />

            <FossilyticsNumberField
              label="Inter-Porosity Flow Parameter"
              suffix="Dim"
              debounceDelay={1000}
              disabled={disabledInput}
              value={analysisInput.transient_flow_model.section_dual_porosity.interpososity_flow_parameter}
              onChange={(v) => {
                onChangeInput(v, "transient_flow_model", "section_dual_porosity", "interpososity_flow_parameter");
              }}
            />
          </>
        )}

        <Stack style={{ marginTop: 30, minWidth: 400 }}>
          <Text styles={{ root: { color: theme.palette.themePrimary } }}>Summary</Text>
          {isLoading ? (
            <LoadingIndicator />
          ) : (
            <DetailsList
              compact
              selectionMode={SelectionMode.none}
              columns={dataTableColumns}
              items={analysisSummaryCardParameter.map((item: any, i) => {
                return {
                  parameter: item.parameter,
                  value: formatNumber(item.value),
                  units: item.unit,
                };
              })}
            />
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default AnalysisSetting;
