const spacecraftDom = "https://predico.wikipage.io/c/";

const tahk: { [key: string]: string } = {
  wellboreInput: `${spacecraftDom}61636676/tubing+head+pressures+model`,
  geomechanics: `${spacecraftDom}15761533/Geomechanics`,
  isotherm: `${spacecraftDom}15335425/Isotherm`,
  formationCompressibility: `${spacecraftDom}58228737/Rock+Formation+Compressibility`,
  porosity: `${spacecraftDom}55574540/Porosity`,
  netPay: `${spacecraftDom}59244551/Net+Pay`,
  permeability: `${spacecraftDom}17694839/CSG+Permeability+and+Permeability+Anisotropy`,
  gasPvt: `${spacecraftDom}17465359/Gas+PVT+Correlations`,
  permeabilityXAxis: `${spacecraftDom}17924097/Relative+Permeability`,
  permeabilityYAxis: `${spacecraftDom}17924097/Relative+Permeability`,
  permeabilityZAxis: `${spacecraftDom}17924097/Relative+Permeability`,
  coalDensity: `${spacecraftDom}23527464/Coal+Density`,
  underSaturation: `${spacecraftDom}20840566/Undersaturation+Dewatering`,

  specificGravity: `${spacecraftDom}127860763/Gas+Specific+Gravity`,
  selectedZCorrelation: `${spacecraftDom}17596429/Gas+Deviation+Factor`,
  selectedGasViscosityCorrelation: `${spacecraftDom}17301538/Gas+Viscosity`,
  langmuirPressure: `${spacecraftDom}15335425/Isotherm`,
  langmuirVolume: `${spacecraftDom}15335425/Isotherm`,
  rockDensity: `${spacecraftDom}23527464/Coal+Density`,
  populationSize: `${spacecraftDom}166461453/Population+Size`,
  maxIterationNumber: `${spacecraftDom}166297612/Maximum+Iteration+Number  `,
};

const helpLinkUrl = {
  tahk,
  wellImport: `${spacecraftDom}130383874/import+wells`,
  projectImport: `${spacecraftDom}158203953/import+projects`,
  errorCode: `${spacecraftDom}93716494/error+messages#`,
};

export default helpLinkUrl;
