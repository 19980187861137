import { useCallback, useMemo, useState } from "react";

import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Button from "@mui/material/Button";
import { Menu, MenuItem } from "@mui/material";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import NumberField from "@/components/fields/NumberField";
import Tabs from "@/components/Tabs";

import dictionary from "@/constants/dictionary";

import { Container } from "./style";
import { SelectedForecastRollup, SpadDeclineState } from "@/models/spad/decline";
import ParameterCase from "./ParameterCase";
import DropdownField from "@/components/fields/DropdownField";
import { mapEnumToDropdown } from "@/utils/general";
import { ExportIntervalEnum } from "@/models/spad/decline/apiFetcher/export";
import CompareDropdown, { DataSetDropdownProps } from "./CompareDropdown";

type SpadDeclineAnalysisParameterProps = {
  spadDeclineState?: SpadDeclineState;
  setSpadDeclineState: React.Dispatch<React.SetStateAction<SpadDeclineState | undefined>>;
  onClickCalculate: () => void;
  isLoading: boolean;
  onClickExport: (interval: ExportIntervalEnum) => void;
} & DataSetDropdownProps;

const exportOption = [ExportIntervalEnum.daily, ExportIntervalEnum.monthly, ExportIntervalEnum.yearly];
const SpadDeclineAnalysisParameter = ({
  isLoading,
  spadDeclineState,
  setSpadDeclineState,
  onClickCalculate,
  onClickExport,
  setCompareDataSet,
  setCompareName,
  dataSets,
  projects,
  selectedDataSets,
}: SpadDeclineAnalysisParameterProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);

  const onChangeParameter = useCallback(
    (key: string, value: any) => {
      setSpadDeclineState((prev) => {
        if (!prev) return prev;

        return {
          ...prev,
          analysis_option: {
            ...prev.analysis_option,
            [key]: value,
          },
        };
      });
    },
    [setSpadDeclineState]
  );

  const tabList = useMemo(() => {
    return [
      {
        label: <span>{dictionary.spadDecline.opsForecast}</span>,
        key: "opsForecast",
        content: (
          <div>
            <ParameterCase
              disabled={spadDeclineState?.analysis_option.auto_update_smart_fit ?? false}
              setSpadDeclineState={setSpadDeclineState}
              type="operational_forecast"
              forecast={spadDeclineState?.analysis_option.operational_forecast}
            />
          </div>
        ),
      },
      {
        label: <span>{dictionary.spadDecline.profileForecast}</span>,
        key: "profileForecast",
        content: (
          <div>
            <ParameterCase
              disabled={spadDeclineState?.analysis_option.auto_update_smart_fit ?? false}
              setSpadDeclineState={setSpadDeclineState}
              type="profile_forecast"
              forecast={spadDeclineState?.analysis_option.profile_forecast}
            />
          </div>
        ),
      },
    ];
  }, [
    setSpadDeclineState,
    spadDeclineState?.analysis_option.auto_update_smart_fit,
    spadDeclineState?.analysis_option.operational_forecast,
    spadDeclineState?.analysis_option.profile_forecast,
  ]);

  return (
    <Container>
      <h3 className="primaryColor">{dictionary.spadDecline.declineParameters}</h3>

      <FormControlLabel
        control={
          spadDeclineState?.analysis_option ? (
            <Checkbox
              onChange={(e) => {
                onChangeParameter("auto_update_smart_fit", e.target.checked);
              }}
              checked={spadDeclineState?.analysis_option.auto_update_smart_fit}
              size="small"
            />
          ) : (
            <></>
          )
        }
        label={dictionary.spadDecline.autoUpdateSmartFit}
      />
      <FormControlLabel
        control={
          spadDeclineState?.analysis_option ? (
            <Checkbox
              onChange={(e) => {
                onChangeParameter("auto_update_cluster_start_day", e.target.checked);
              }}
              checked={spadDeclineState?.analysis_option.auto_update_cluster_start_day}
              size="small"
            />
          ) : (
            <></>
          )
        }
        label={dictionary.spadDecline.autoUpdateClusterStartDay}
      />
      <NumberField
        type="int"
        disabled={spadDeclineState?.analysis_option.auto_update_cluster_start_day}
        value={spadDeclineState?.analysis_option.analysis_start_day}
        label={dictionary.spadDecline.clusterStartDay}
        debounceDelay={1000}
        onChange={(v) => {
          onChangeParameter("analysis_start_day", v);
        }}
      />
      <Tabs centered tabList={tabList} />
      <NumberField
        type="float"
        label={dictionary.spadDecline.economicCutoff}
        debounceDelay={1000}
        suffix={dictionary.spadDecline.economicCutoffNotation}
        onChange={(v) => {
          onChangeParameter("economic_cutoff", v);
        }}
        value={spadDeclineState?.analysis_option.economic_cutoff}
      />

      <div>
        <Button
          style={{
            color: "white",
            width: "100%",
            fontWeight: "bold",
            marginBottom: 15,
            marginTop: 20,
          }}
          disabled={isLoading}
          variant="contained"
          onClick={() => {
            onClickCalculate();
          }}
        >
          {dictionary.spadDecline.applyUpdates}
        </Button>
        <Button
          style={{
            color: "white",
            width: "100%",
            fontWeight: "bold",
          }}
          variant="contained"
          onClick={(event) => {
            setAnchorEl(event.currentTarget);
          }}
        >
          <div style={{ marginLeft: "auto", paddingLeft: 23 }}>{dictionary.spadDecline.export}</div>
          <KeyboardArrowDownIcon style={{ marginLeft: "auto" }} />
        </Button>
        <div>
          <Menu anchorEl={anchorEl} open={openMenu} onClose={() => setAnchorEl(null)}>
            {exportOption.map((option: ExportIntervalEnum) => {
              return (
                <MenuItem
                  onClick={() => {
                    setAnchorEl(null);
                    onClickExport(option);
                  }}
                  key={dictionary.spadDecline[option]}
                >
                  <div>{dictionary.spadDecline[option]}</div>
                </MenuItem>
              );
            })}
          </Menu>
        </div>
      </div>

      {spadDeclineState?.analysis_option?.selected_forecast_rollup && (
        <DropdownField
          label={dictionary.spadDecline.forecastForRollup}
          options={mapEnumToDropdown(SelectedForecastRollup)}
          selectedKey={spadDeclineState?.analysis_option?.selected_forecast_rollup}
          onChange={(val) => onChangeParameter("selected_forecast_rollup", val)}
        />
      )}

      <CompareDropdown
        setCompareName={setCompareName}
        projects={projects}
        dataSets={dataSets}
        selectedDataSets={selectedDataSets}
        setCompareDataSet={setCompareDataSet}
      />
    </Container>
  );
};

export default SpadDeclineAnalysisParameter;
