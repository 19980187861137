import { useEffect, useState } from "react";

import { useAppStore } from "@/features/app";
import { ModuleKoldunMcsimOutput } from "../model";
import { getLastValidDataIndex } from "../ModuleKoldunMcsimGasOilContent";

export const useOutput = (dataSet: any, options: any, currentTab: any) => {
  const [output, setOutput] = useState<ModuleKoldunMcsimOutput>();
  const pollRequest = useAppStore((state) => state.pollRequest);

  useEffect(() => {
    if (!dataSet || !options || currentTab !== 1) return;

    setOutput(undefined);
    (async () => {
      try {
        const lastValidInputIndex = getLastValidDataIndex(options.inputs, options.num_days);
        const response = await pollRequest(
          "/modules/koldun/mcsim/calculate",
          {
            data_set_id: dataSet.id,
          },
          {
            ...options,
            inputs: options.inputs.slice(0, lastValidInputIndex + 1),
          }
        );

        setOutput(response);
      } catch (error) {
        console.error(error);
      }
    })();
  }, [dataSet, currentTab, pollRequest, options]);
  return { output };
};
